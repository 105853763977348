export default {
  app: {
    event: {
      annotation: 'Interní anotace',
      areasOfInterest: 'Zaměření události',
      barrierFree: 'Bezbariérový přístup',
      barrierFreeShort: 'Bezbariérový přístup',
      capacity: 'Kapacita události',
      chooseAreasOfInterest: 'Vyberte oblasti zaměření',
      chooseGroup: 'Vyberte skupinu, ve které chcete událost vytvořit',
      content: 'Popis události',
      daysOfWeek: 'Opakovat v těchto dnech',
      date: 'Datum',
      dateEnd: 'Konec',
      dateRangeEnd: 'Opakovat do',
      dateRangeStart: 'Opakovat od',
      dateStart: 'Začátek',
      dateWhen: 'Kdy?',
      deleteConfirmation: 'Opravdu si přejete smazat událost?',
      dogsAllowed: 'Vstup se psy',
      end: 'Konec události',
      enterTitle: 'Zadejte název události',
      entireDay: 'Celý den',
      events: 'Události',
      futurePlan: 'Další termíny',
      locationIsSameAsGroup: 'Místo konání je v místě skupiny',
      link: 'Odkaz na stránku události',
      mapTitle: 'Místo konání události',
      newEvent: 'Nová událost',
      noEvents: 'Žádné události',
      other: 'Ostatní',
      person: 'Žádná osoba | 1 osoba | {count} osoby | {count} osob',
      place: 'Adresa události',
      price: 'Vstupní cena',
      private: 'Soukromá',
      privateEvent: 'Soukromá událost',
      privateHint: 'Událost uvidí pouze členové skupiny',
      public: 'Veřejná',
      publicEvent: 'Veřejná událost',
      publicHint: 'Událost uvidí všichni uživatelé aplikace',
      since: 'Začíná:',
      showFuturePlan: 'Zobrazit seznam termínů',
      soonest: 'Nejbližší události',
      start: 'Začátek události',
      toCalendar: 'Do kalendáře',
      time: 'Čas',
      timeEnd: 'Konec',
      timeStart: 'Začátek',
      timeWhen: 'V kolik?',
      title: 'Název události',
      until: 'Končí:',
      visibility: 'Viditelnost',
    },
  },
};
