import { Component, Vue } from 'vue-property-decorator';

function isVueComponent(data: any): data is Vue {
  return data && typeof data === 'object' && typeof data.$el !== 'undefined';
}

@Component
export default class ScrollToRefMixin extends Vue {
  protected scrollToRef(ref: Vue | HTMLElement, offset: number = 0) {
    if (!window || !document) {
      return;
    }

    const el: Element = isVueComponent(ref) ? ref.$el : ref;

    const documentOffset =
      document.documentElement.scrollTop || document.body.scrollTop;
    const rect = el.getBoundingClientRect();
    const top = documentOffset + rect.top + offset;

    window.scrollTo({
      top: top < 0 ? 0 : top,
      behavior: 'smooth',
    });
  }
}
