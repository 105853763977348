import {
  CzYsP14KcPlaceApiDtoPlaceDetailObject,
  CzYsP14KcRuianApiDtoRuianAddressObject,
} from '~/app/core/apiClient/api';
import { RuianLocationItem } from '~/utils/ruian/index';

type Input =
  | CzYsP14KcRuianApiDtoRuianAddressObject
  | CzYsP14KcPlaceApiDtoPlaceDetailObject;

function isRuianAddress(
  data: any
): data is CzYsP14KcRuianApiDtoRuianAddressObject {
  return typeof data.streetName !== 'undefined';
}

function isPlaceAddress(
  data: any
): data is CzYsP14KcPlaceApiDtoPlaceDetailObject {
  return typeof data.name !== 'undefined';
}

export default function(item: Input): RuianLocationItem {
  let address = item.address || '';
  let name = '';

  if (isRuianAddress(item)) {
    if (!address && item.streetName) {
      address = item.streetName;
    }
  } else if (isPlaceAddress(item)) {
    name = item.name || '';
  }

  return {
    address,
    ruianId: item.ruianId || null,
    lat: item.latitude || null,
    lng: item.longitude || null,
    name,
  };
}
