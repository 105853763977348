import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import GroupDetailModule from '~/app/core/store/modules/GroupDetailModule';
import GroupModule from '~/app/core/store/modules/GroupModule';
import { GroupImage } from '~/components/molecules';
import { GroupImageContext } from '~/components/molecules/groupImage/GroupImage';
import { GroupItem, Kind, Visibility } from '~/utils/group';
import { VueComponent } from '~/utils/vue-component';

import style from './GroupHead.scss';
import { TranslateResult } from 'vue-i18n';

const rootClass = 'ys-group-head';

@Component({
  style,
})
export default class GroupHead extends VueComponent<{}> {
  protected get groupModule() {
    return getModule(GroupDetailModule, this.$store);
  }
  @Prop({ type: Boolean, default: false })
  public editable!: boolean;

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  protected get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  protected get id() {
    return this.groupModule.id;
  }

  protected get groupItem(): GroupItem | null {
    for (const group of this.groupStore.groupCache) {
      if (group.id === this.groupModule.id) {
        return group;
      }
    }

    return null;
  }

  protected get translateKey(): string {
    return this.groupModule.kind.toLowerCase();
  }

  protected get visibility(): TranslateResult {
    switch (this.groupModule.visibility) {
      case Visibility.OFFICIAL:
        return this.$t(`app.${this.translateKey}.officialLabel`);
      case Visibility.PRIVATE:
        return this.$t(`app.${this.translateKey}.privateLabel`);
      default:
        return this.$t(`app.${this.translateKey}.publicLabel`);
    }
  }

  public render() {
    if (!this.groupItem) {
      return;
    }

    return (
      <GroupImage group={this.groupItem} context={GroupImageContext.HEAD}>
        <v-row
          class={`${rootClass}__overlay pa-4 fill-height flex-grow-1 d-flex flex-column white--text no-gutters`}
        >
          <v-spacer />
          <div class={`${rootClass}__visibility text-center`}>
            {this.visibility}
            <h1 class={`${rootClass}__title`}>{this.groupModule.name}</h1>
          </div>
        </v-row>
      </GroupImage>
    );
  }
}
