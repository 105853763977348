import createComment from './createComment';
import { User } from '~/utils/users';
import { PostItem } from '~/utils/post';
import { EventItem } from '~/utils/event';
import { SurveyItem } from '~/utils/survey';
import { GroupItem } from '~/utils/group';

enum CommentType {
  EVENT = 'EVENT',
  GROUP = 'COMMUNITY',
  POST = 'POST',
  SURVEY = 'SURVEY',
}

type CommentParentId =
  | PostItem['id']
  | EventItem['id']
  | SurveyItem['id']
  | GroupItem['id'];

interface CommentState {
  reporting: boolean;
  reported: boolean;
}

interface CommentItem {
  id: string;
  content: string;
  date: string | null;
  deleted: boolean;
  state: CommentState;
  toxic: boolean;
  type: CommentType;
  user: User | null;
}

interface CommentsPagingInfo {
  lastPage: boolean;
  page: number;
  size: number;
}

function defaultCommentsPagingInfo(): CommentsPagingInfo {
  return {
    lastPage: false,
    page: 0,
    size: 10,
  };
}

const commentsFragment = '#comments';

export {
  CommentItem,
  CommentParentId,
  CommentType,
  CommentsPagingInfo,
  commentsFragment,
  createComment,
  defaultCommentsPagingInfo,
};
