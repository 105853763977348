import { Component, Prop, Ref } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { GeoPosition, loadGoogleMaps, MapsApi } from '~/utils/googleMaps';

import style from './MapWidget.scss';
import { CloseButton, ShowMapButton } from '~/components/atoms';

const rootClass = 'ys-map-widget';

interface MapWidgetInterface {
  position: GeoPosition;
}

@Component({
  style,
})
export default class MapWidget extends VueComponent<MapWidgetInterface>
  implements MapWidgetInterface {
  @Prop({ required: true })
  public position!: GeoPosition;

  @Ref()
  protected readonly mapsApiBlock?: HTMLElement;

  protected isModalOpen: boolean = false;

  protected mapsApi: MapsApi = {
    failed: false,
    loaded: false,
    loading: false,
    map: null,
  };

  public beforeMount() {
    if (this.position) {
      this.mapsApi.loading = true;
      loadGoogleMaps()
        .then(() => {
          this.mapsApi.loaded = true;
          this.initializeMap();
        })
        .catch((err) => {
          this.mapsApi.failed = true;
        })
        .finally(() => {
          this.mapsApi.loading = false;
        });
    }
  }

  public mounted() {
    this.initializeMap();
  }

  public beforeUpdate() {
    this.destroyMap();
  }

  public updated() {
    this.initializeMap();
  }

  public beforeDestroy() {
    this.destroyMap();
  }

  public render() {
    return (
      <div class={rootClass}>
        <ShowMapButton
          onShowMap={() => {
            this.isModalOpen = true;
          }}
        />

        <v-dialog
          content-class={`${rootClass}__dialog`}
          v-model={this.isModalOpen}
          width='800'
        >
          <v-card>
            <v-card-title
              class='headline grey lighten-2'
              style='position:relative;'
            >
              {this.$slots.title}
              <v-spacer />
              <CloseButton
                onClose={() => {
                  this.isModalOpen = false;
                }}
              />
            </v-card-title>

            <v-card-text class='pt-4'>{this.renderMap()}</v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color='secondary'
                onClick={() => {
                  this.isModalOpen = false;
                }}
              >
                {this.$t('app.common.close')}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    );
  }

  protected renderMap() {
    if (this.mapsApi.loaded) {
      return (
        <div
          class={`${rootClass}__map google-map`}
          slot='image'
          ref='mapsApiBlock'
        />
      );
    }

    if (this.position && this.mapsApi.loading) {
      return <v-skeleton-loader slot='image' type='image' />;
    }
  }

  protected initializeMap() {
    if (
      !this.position ||
      !this.mapsApi.loaded ||
      this.mapsApi.map ||
      !this.mapsApiBlock
    ) {
      return;
    }

    this.mapsApi.map = new google.maps.Map(this.mapsApiBlock, {
      center: {
        lat: this.position.latitude,
        lng: this.position.longitude,
      },
      zoom: 17,
    });

    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        this.position.latitude,
        this.position.longitude
      ),
    });
    marker.setMap(this.mapsApi.map);
  }

  protected destroyMap() {
    if (this.mapsApi.map) {
      this.mapsApi.map.unbindAll();
      this.mapsApi.map = null;
    }
  }
}
