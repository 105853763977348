import {
  CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject,
  CzYsP14KcEventApiDtoEventInstanceDetailObject,
} from '~/app/core/apiClient/api';
import {
  EventDateRange,
  EventTimeRange,
} from '~/components/molecules/eventDates/EventDates';
import { GeoLocation } from '~/utils/location';
import { format, parse } from '~/utils/date-fns';
import { RFC3339Format } from '~/utils/dateTime';

import getEventImageUrl from './getEventImageUrl';
import { FutureEvent } from '.';

import FlagsEnum = CzYsP14KcEventApiDtoEventInstanceDetailObject.FlagsEnum;
import VisibilityEnum = CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject.VisibilityEnum;
import { imageVersion } from '~/utils/media';

export interface EventDetailItem {
  address: string;
  barrierFree: boolean;
  capacity?: number;
  commentCount: number;
  content: string;
  futurePlan: FutureEvent[];
  times: EventTimeRange;
  dates: EventDateRange;
  dogsAllowed: boolean;
  editable: boolean;
  groupId: string;
  groupName: string;
  id: string;
  isPublic: boolean;
  imageUrl: string;
  liked: boolean;
  likes: number;
  link: string | null;
  location: GeoLocation | null;
  price: string;
  areasOfInterestIds: string[];
  title: string;
}

export default function createEventDetail(
  data: CzYsP14KcEventApiDtoEventInstanceDetailObject
): EventDetailItem {
  const timeStartParts = data.timeStart ? data.timeStart.split(':') : [];
  const timeEndParts = data.timeEnd ? data.timeEnd.split(':') : [];
  const futurePlan: FutureEvent[] = [];

  if (data.futurePlan) {
    data.futurePlan.forEach((plan) => {
      const start = parse(plan.date + 'T' + plan.start);
      const end = parse(plan.date + 'T' + plan.end);
      if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        return;
      }
      futurePlan.push({
        start: format(start, RFC3339Format),
        end: format(end, RFC3339Format),
      });
    });
  }

  let dateStart = data.dateStart ? parse(data.dateStart) : new Date();
  let dateEnd: Date | null = null;

  if (isNaN(dateStart.getTime())) {
    dateStart = new Date();
  }

  if (data.dateEnd) {
    dateEnd = parse(data.dateEnd);
    if (isNaN(dateEnd.getTime())) {
      dateEnd = null;
    }
  }

  return {
    address: data.place ? data.place.address || '' : '',
    barrierFree: data.flags
      ? data.flags.includes(FlagsEnum.BARRIERFREE)
      : false,
    capacity: data.capacity,
    commentCount: data.commentCount || 0,
    content: data.content || '',
    futurePlan,
    times: {
      end:
        timeEndParts.length > 0
          ? `${timeEndParts[0]}:${timeEndParts[1]}`
          : '00:00:00',
      start:
        timeStartParts.length > 0
          ? `${timeStartParts[0]}:${timeStartParts[1]}`
          : '00:00:00',
    },
    dates: {
      from: format(dateStart, RFC3339Format),
      to: dateEnd ? format(dateEnd, RFC3339Format) : null,
    },
    dogsAllowed: data.flags
      ? data.flags.includes(FlagsEnum.DOGSALLOWED)
      : false,
    editable: data.user ? data.user.editable || false : false,
    groupId: data.community ? data.community.id || '' : '',
    groupName: data.community ? data.community.name || '' : '',
    id: data.id || '',
    imageUrl: data.id ? getEventImageUrl(data.id, imageVersion()) : '',
    isPublic: data.visibility === VisibilityEnum.PUBLIC,
    liked: data.user ? data.user.liked || false : false,
    likes: data.likeCount || 0,
    link: data.link || null,
    location: {
      name: data.place?.name || '',
      address: data.place?.address || '',
      ruianId: data.place?.ruianId || null,
      lat: data.place?.latitude || null,
      lng: data.place?.longitude || null,
    },
    price: data.price || '',
    areasOfInterestIds: data.areasOfInterest || [],
    title: data.title || '',
  };
}
