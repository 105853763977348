import { Component, Prop, Ref } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './Image.scss';
import { LocaleMessage } from 'vue-i18n';

export interface ImageInterface {
  absolute?: boolean;
  alt: string | LocaleMessage;
  height?: number;
  onLoad?: () => void;
  sizes?: string;
  src: string;
  srcset?: string;
  title?: string;
  width?: number;
}

const rootClass = 'ys-image';

@Component({
  style,
})
export default class ImageComponent extends VueComponent<ImageInterface>
  implements ImageInterface {
  @Prop({ default: false, type: Boolean })
  public absolute!: boolean;

  @Prop({ required: true })
  public alt!: string | LocaleMessage;
  public srcset?: string;

  @Prop({ type: String })
  public sizes?: string;

  @Prop({ required: true, type: String })
  public src!: string;

  @Prop({ type: String })
  @Prop({ type: String })
  public title?: string;

  @Ref('imageElement')
  public readonly imageElement!: HTMLImageElement;

  protected loaded: boolean = false;

  public render() {
    const classes = [rootClass];
    if (!this.loaded) {
      classes.push(`${rootClass}--initializing`);
    }

    return (
      <img
        alt={this.alt}
        class={rootClass}
        onLoad={this.load}
        ref='imageElement'
        sizes={this.sizes ? this.sizes : undefined}
        src={this.src}
        srcset={this.srcset ? this.srcset : undefined}
        style={this.absolute ? 'position:absolute;' : ''}
        title={this.title}
      />
    );
  }

  protected load() {
    this.loaded = true;
    this.$emit('load');
  }
}
