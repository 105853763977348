import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import Firebase from 'firebase';
import { FirebaseUi } from '~/components/atoms';
import firebaseui from 'firebaseui';

const rootClass: string = 'ys-socials-login';

const authOptions: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: Firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      fullLabel: 'Google',
    },
    {
      provider: Firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      fullLabel: 'Facebook',
    },
  ],
  callbacks: {
    // return false to avoid redirect after sign-in
    signInSuccessWithAuthResult: () => false,
  },
};

@Component
export default class ExternalLogin extends VueComponent<{}> {
  public render() {
    return (
      <div class={rootClass}>
        <FirebaseUi options={authOptions} />
      </div>
    );
  }
}
