export default {
  app: {
    error: {
      group: {
        areasAreRequired: 'Vyberte alespoň jedno zaměření skupiny.',
        conflict: 'Skupina s tímto názvem již existuje..',
        couldNotReload: 'Nepodařilo se načíst nová data skupiny.',
        create: {
          generic: 'Nastala chyba při vytváření skupiny.',
          forbidden: 'Nemáte oprávnění pro vytváření skupiny.',
        },
        descriptionIsRequired: 'Vyplňte prosím popis skupiny',
        fixLocation: 'Prosím opravte místo skupiny',
        locationHasNameOnly:
          'Pokud je zadaný název místa, musíte vybrat i adresu nebo bod v mapě.',
        missingImage: 'Vyberte prosím fotografii.',
        missingLocation: 'Vyberte prosím místo skupiny.',
        nameIsRequired: 'Vyplňte prosím název skupiny',
        notCreated: 'Nebylo možné přidat skupinu.',
        notFound: 'Skupina nebyla nalezena',
        notUpdated: 'Nebylo možné upravit skupinu.',
        update: {
          generic: 'Nastala chyba při aktualizaci skupiny.',
          forbidden: 'Nemáte oprávnění pro úpravu skupiny.',
        },
      },
    },
  },
};
