import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { rootClass } from '~/components/atoms/icons/Icon';

@Component
export default class CloseIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class={`${rootClass}--common ${rootClass}--close`}>
        <svg
          viewBox='0 0 32 32'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            d='M16 15.275l9.293-9.293 1.414 1.414-9.293 9.293 9.293 9.293-1.414 1.414L16 18.103l-9.293 9.293-1.414-1.414 9.293-9.293-9.293-9.293 1.414-1.414L16 15.275z'
            fill='currentColor'
          />
        </svg>
      </i>
    );
  }
}
