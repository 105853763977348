import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';

import CommunitiesLocalization from './communities';
import GroupLocalization from './group';
import ProjectLocalization from './project';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  CommunitiesLocalization,
  GroupLocalization,
  ProjectLocalization,
]) as LocaleMessages;

export default mergedLocalizations;
