import { createDefaultDaySelection, DaySelection, weekDays } from './';

export default function(daysOfWeek: boolean[]): DaySelection {
  const daySelection = createDefaultDaySelection();

  weekDays.forEach((value, index) => {
    if (daysOfWeek[index]) {
      daySelection[value] = true;
    }
  });

  return daySelection;
}
