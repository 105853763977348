import { RouteConfig } from 'vue-router';

import SurveyDetail from '~/views/SurveyDetail';
import parameters from './parameters';

const config: RouteConfig = {
  component: SurveyDetail,
  path: `survey/${parameters.guidPath}`,
};

export default config;
