import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { rootClass } from '~/components/atoms/icons/Icon';

@Component
export default class LanguageIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class={`${rootClass}--common ${rootClass}--close`}>
        <svg
          viewBox='0 0 32 32'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            d='M16 1c8.28 0 15 6.72 15 15 0 8.28-6.72 15-15 15-8.28 0-15-6.72-15-15C1 7.72 7.72 1 16 1zm4.83 21h-9.66c.47 1.98 1.17 3.69 2.05 4.98.81 1.18 1.7 2.02 2.78 2.02 1.08 0 1.97-.84 2.78-2.02.88-1.29 1.58-3 2.05-4.98zM4.47 22c1.51 2.9 4.09 5.16 7.21 6.26-1.11-1.57-2-3.73-2.56-6.26H4.47zm23.06 0h-4.65c-.56 2.53-1.45 4.69-2.56 6.26 3.12-1.1 5.7-3.36 7.21-6.26zm.84-10h-5.14c.18 1.27.27 2.62.27 4 0 1.39-.09 2.73-.27 4h5.14c.41-1.26.63-2.6.63-4s-.22-2.74-.63-4zM3.63 12C3.22 13.26 3 14.6 3 16s.22 2.74.63 4h5.14c-.18-1.27-.27-2.61-.27-4 0-1.38.09-2.73.27-4H3.63zm17.58 0H10.79a27.08 27.08 0 00-.29 4c0 1.39.1 2.73.29 4h10.42c.19-1.27.29-2.61.29-4 0-1.39-.1-2.73-.29-4zm-.89-8.26c1.11 1.57 2 3.73 2.56 6.26h4.65c-1.51-2.9-4.09-5.16-7.21-6.26zm-8.64 0C8.56 4.84 5.98 7.1 4.47 10h4.65c.56-2.53 1.45-4.69 2.56-6.26zM20.83 10c-.47-1.98-1.17-3.69-2.05-4.98C17.97 3.84 17.08 3 16 3c-1.08 0-1.97.84-2.78 2.02-.88 1.29-1.58 3-2.05 4.98h9.66z'
            fill='currentColor'
          />
        </svg>
      </i>
    );
  }
}
