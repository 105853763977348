import { Component, Vue } from 'vue-property-decorator';
import { Error, Forbidden, NotFound } from '~/components/templates/common';

@Component
export default class RequestErrorComponent extends Vue {
  private requestErrorComponentError: boolean = false;

  private requestErrorComponentFound: boolean = true;

  private requestErrorComponentForbidden: boolean = false;

  protected get requestErrorComponent(): any | void {
    if (!this.requestErrorComponentFound) {
      return <NotFound />;
    } else if (this.requestErrorComponentForbidden) {
      return <Forbidden />;
    } else if (this.requestErrorComponentError) {
      return <Error />;
    }
  }

  public requestErrorResetStatus() {
    this.requestErrorComponentError = false;
    this.requestErrorComponentFound = true;
    this.requestErrorComponentForbidden = false;
  }

  public requestErrorHandler(err: any) {
    if (!err.status) {
      return;
    }

    if (err.status === 404) {
      this.requestErrorComponentFound = false;
    } else if (err.status === 403) {
      this.requestErrorComponentForbidden = true;
    } else {
      this.requestErrorComponentError = true;
    }
  }
}
