import { Component, Prop } from 'vue-property-decorator';
import { link, LinkTarget } from '~/utils/http';
import { VueComponent } from '~/utils/vue-component';

import style from './Link.scss';
import { RawLocation } from 'vue-router';
import { isLocation } from '~/utils/http/router';

export interface LinkInterface {
  target?: LinkTarget;
  title?: string;
  to: RawLocation;
}

const rootClass = 'ys-link';

@Component({
  style,
})
export default class Link extends VueComponent<LinkInterface>
  implements LinkInterface {
  @Prop({ type: String })
  public target?: LinkTarget;

  @Prop({ type: String })
  public title?: string;

  @Prop({
    default: '',
    validator(value: any): boolean {
      return typeof value === 'string' || isLocation(value);
    },
  })
  public to!: RawLocation;

  protected get href(): string | undefined {
    if (!isLocation(this.to) && !this.isInternal) {
      return this.to;
    }

    return undefined;
  }

  protected get isInternal(): boolean {
    if (isLocation(this.to)) {
      return true;
    }

    return link.isInternal(this.to, this.$store);
  }

  protected get linkTarget(): string | undefined {
    if (this.target && this.target.trim() !== '') {
      return this.target;
    }
    return this.isInternal ? undefined : LinkTarget.BLANK;
  }

  protected get toRoute(): string | RawLocation | undefined {
    if (isLocation(this.to)) {
      return this.to;
    }

    if (this.isInternal) {
      return link.createInternal(this.to, this.$i18n.locale);
    }

    return undefined;
  }

  public render() {
    if (this.isInternal) {
      return (
        <router-link
          class={rootClass}
          to={this.toRoute}
          target={this.linkTarget}
          title={this.title}
        >
          {this.$slots.default}
        </router-link>
      );
    } else {
      return (
        <a
          class={rootClass}
          href={this.href}
          target={this.linkTarget}
          title={this.title}
          rel={
            this.linkTarget === LinkTarget.BLANK
              ? 'noopener nofollow'
              : undefined
          }
        >
          {this.$slots.default}
        </a>
      );
    }
  }
}
