import Firebase from 'firebase';
import appEnv from '~/app/core/appEnv';

if (!Firebase.apps.length) {
  Firebase.initializeApp({
    apiKey: appEnv.FIREBASE_API_KEY,
    authDomain: `${appEnv.FIREBASE_PROJECT_ID}.firebaseapp.com`,
    projectId: appEnv.FIREBASE_PROJECT_ID,
  });
}
