import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator';
import BaseButton from '~/components/atoms/baseButton/BaseButton';
import { isHTMLInput } from '~/utils/typeguards';
import { componentOffset } from '~/utils/spacing';

export type ShareEvent = void;

export interface ShareButtonInterface {
  uri: string;
  active?: boolean;
  onShare?: (data: ShareEvent) => void;
}

interface VuetifyTextField extends Vue {
  $el: HTMLElement;
}

@Component
export default class ShareButton extends BaseButton<ShareButtonInterface> {
  @Prop({ default: true, type: Boolean })
  public active!: boolean;

  @Prop({ required: true, type: String })
  public uri!: string;

  protected dialogOpen: boolean = false;

  @Ref('inputWrapper')
  protected readonly inputWrapper?: VuetifyTextField;

  public render() {
    if (!this.active) {
      return;
    }

    return (
      <v-btn onClick={this.openDialog} {...this.baseAttributes} text>
        <v-icon left>mdi-share</v-icon>
        {this.$slots.default || this.$t('app.common.share')}
        <v-dialog v-model={this.dialogOpen} max-width={600}>
          <v-card>
            <v-card-title>{this.$t('app.common.share')}</v-card-title>
            <v-card-text class={`pt-${componentOffset}`}>
              <v-text-field
                class='v-input--with-buttons v-input--no-details'
                value={this.uri}
                filled
                rounded
                ref='inputWrapper'
                readonly
              >
                <v-btn
                  class='v-btn--appended'
                  slot='append'
                  x-large
                  rounded
                  color='secondary'
                  elevation={0}
                  onClick={this.copyToClipboard}
                >
                  <v-icon left>mdi-content-copy</v-icon>
                  {this.$t('app.share.copy')}
                </v-btn>
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                onClick={() => {
                  this.dialogOpen = false;
                }}
              >
                {this.$t('app.common.close')}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-btn>
    );
  }

  protected getInput(): HTMLInputElement | null {
    if (!this.inputWrapper) {
      return null;
    }
    const input = this.inputWrapper.$el.querySelector('input');
    if (isHTMLInput(input)) {
      return input;
    } else {
      return null;
    }
  }

  protected openDialog() {
    this.dialogOpen = true;
    this.$nextTick().then(() => {
      const input = this.getInput();
      if (input) {
        input.select();
      }
    });
  }

  protected copyToClipboard() {
    const input = this.getInput();
    if (input) {
      input.select();
      document.execCommand('copy');
      this.emitShare();
    }
    this.dialogOpen = false;
  }

  @Emit('share')
  public emitShare(): ShareEvent {
    return;
  }
}
