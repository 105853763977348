import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseButton from '~/components/atoms/baseButton/BaseButton';

export enum FormActionIcon {
  LEFT,
  RIGHT,
}
export interface FormActionButtonInterface {
  color?: string;
  icon?: FormActionIcon;
}

@Component
export default class FormActionButton
  extends BaseButton<FormActionButtonInterface>
  implements FormActionButtonInterface {
  @Prop({ default: 'primary' })
  public color!: string;

  @Prop()
  public icon?: FormActionIcon;

  public render() {
    return (
      <v-btn
        color={this.color}
        text
        onClick={this.emitClick}
        {...this.baseAttributes}
      >
        {this.icon === FormActionIcon.LEFT && (
          <v-icon left>mdi-chevron-left</v-icon>
        )}
        {this.$slots.default}
        {this.icon === FormActionIcon.RIGHT && (
          <v-icon right>mdi-chevron-right</v-icon>
        )}
      </v-btn>
    );
  }

  @Emit('click')
  protected emitClick() {
    return;
  }
}
