import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import UserModule from '~/app/core/store/modules/UserModule';

import { Loading } from '~/components/atoms';
import { Footer, Header, Sidebar } from '~/components/organisms';
import { Login } from '~/components/templates';
import FooterPush from '~/components/atoms/footerPush/FooterPush';
import SpeedDial from '~/components/templates/speedDial/SpeedDial';
import MutationModals from '~/components/templates/mutationModals/MutationModals';

@Component
export default class App extends Vue {
  public get userStore(): UserModule {
    return getModule(UserModule, this.$store);
  }

  public mounted() {
    this.userStore.initAuth();
  }

  public render() {
    return (
      <v-app id='app'>
        <Header />
        <v-main>
          {(() => {
            if (!this.userStore.isAuthReady) {
              return <Loading />;
            }

            if (this.$route.meta.public) {
              return (
                <v-container class='pa-0 fill-height align-start' fluid>
                  <router-view />
                  <FooterPush />
                </v-container>
              );
            }

            if (this.userStore.user) {
              return (
                <v-container class='pa-0 fill-height align-start' fluid>
                  <Sidebar />
                  <router-view />
                  <SpeedDial />
                  <MutationModals />
                  <FooterPush />
                </v-container>
              );
            } else {
              return <Login />;
            }
          })()}
          <Footer />
        </v-main>
      </v-app>
    );
  }
}
