import { Component, Prop } from 'vue-property-decorator';

import { CzYsP14KcPostApiDtoPostObject } from '~/app/core/apiClient/api';
import { DateSticker } from '~/components/atoms';
import { PostImage } from '~/components/molecules';
import { PostImageContext } from '~/components/molecules/postImage/PostImage';
import { DetailGallery } from '~/components/organisms';
import { parse } from '~/utils/date-fns';
import { PostItem } from '~/utils/post';
import { greyForegroundColor } from '~/utils/theme/colors';
import { VueComponent } from '~/utils/vue-component';

import style from './PostHead.scss';

import VisibilityEnum = CzYsP14KcPostApiDtoPostObject.VisibilityEnum;

const rootClass = 'ys-post-head';

interface PostHeadInterface {
  post: PostItem;
}

const previewSize = 4;

@Component({
  style,
})
export default class PostHead extends VueComponent<PostHeadInterface>
  implements PostHeadInterface {
  @Prop({ required: true })
  public post!: PostItem;

  protected selectedImageIndex: number = 0;

  protected galleryOpen: boolean = false;

  protected get date(): Date {
    return parse(this.post.created);
  }

  protected get galleryPreview() {
    if (this.post.images.length > previewSize) {
      return this.post.images.slice(0, previewSize - 1);
    }

    return this.post.images.slice(0, previewSize);
  }

  protected get visibility() {
    if (this.post.visibility === VisibilityEnum.PUBLIC) {
      return this.$t('app.post.public');
    }

    return this.$t('app.post.private');
  }

  protected get galleryImages() {
    return [this.post.image.original].concat(this.post.images);
  }

  public render() {
    return (
      <div class={rootClass}>
        {this.post.images.length > 0
          ? this.renderWithImages()
          : this.renderMainImage()}
        <DetailGallery
          active={this.galleryOpen}
          forceSlide={this.selectedImageIndex}
          images={this.galleryImages}
          onCloseDialog={this.closeGallery}
        />
      </div>
    );
  }

  protected renderWithImages() {
    return [
      <v-row>
        <v-col cols='12' class='pt-0'>
          {this.renderMainImage()}
        </v-col>
      </v-row>,
      this.renderNextImages(),
    ];
  }

  protected renderNextImages() {
    return (
      <v-row justify='center'>
        {this.galleryPreview.map((image, index) => {
          return (
            <v-col cols='3' md='2'>
              <v-img
                onClick={() => {
                  this.openGallery(index + 1);
                }}
                key={index}
                src={image}
                aspect-ratio={16 / 9}
              />
            </v-col>
          );
        })}

        {this.galleryPreview.length < this.post.images.length && (
          <v-col cols='3' md='2'>
            <v-responsive
              class={`${rootClass}__more-images d-flex align-center justify-center text-center ${greyForegroundColor}`}
              onClick={() => {
                this.openGallery(previewSize);
              }}
              aspect-ratio={16 / 9}
            >
              <span class='font-weight-bold'>
                +{this.post.images.length - this.galleryPreview.length}
              </span>
            </v-responsive>
          </v-col>
        )}
      </v-row>
    );
  }

  protected renderMainImage() {
    return (
      <PostImage
        post={this.post}
        class={`white--text`}
        context={PostImageContext.HEAD}
        onClick={() => {
          this.openGallery(0);
        }}
      >
        <v-row class={`${rootClass}__overlay fill-height`}>
          <v-col class='fill-height d-flex flex-column' cols='12'>
            <div class='d-flex px-3'>
              <DateSticker
                date={this.date}
                class={`${rootClass}__date-sticker`}
                showTime={true}
              />
            </div>
            <v-spacer />
            <div class={`${rootClass}__visibility text-center`}>
              {this.visibility}
            </div>
            <h1 class={`${rootClass}__title text-center`}>{this.post.title}</h1>
          </v-col>
        </v-row>
      </PostImage>
    );
  }

  protected openGallery(index: number) {
    this.selectedImageIndex = index;
    this.galleryOpen = true;
  }

  protected closeGallery() {
    this.galleryOpen = false;
  }
}
