export default {
  app: {
    post: {
      areasOfInterest: 'Zaměření příspěvku',
      content: 'Obsah',
      private: 'Soukromý článek',
      privateHint: 'Článek uvidí pouze členové skupiny',
      public: 'Veřejný článek',
      publicHint: 'Článek uvidí všichni uživatelé aplikace',
      readMore: 'Přečíst více',
      source: {
        FACEBOOK: 'Facebook',
        INSTAGRAM: 'Instagram',
        SELF: 'Příspěvek',
        WORDPRESS: 'WEB MČ',
      },
      title: 'Titulek',
      visibility: 'Viditelnost',
    },
  },
};
