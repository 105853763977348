export default {
  app: {
    event: {
      timeInput: {
        group: {
          title: 'Termín události',
        },
        project: {
          title: 'Datum události',
        },
        singleDay: 'Jednodenní',
        multiDay: 'Vícedenní',
        repeating: 'Opakovaná',
        date: 'Datum',
        time: 'Čas',
        start: 'Začátek',
        end: 'Konec',
        repeatFrom: 'Opakovat od',
        repeatUntil: 'Opakovat do',
        repeatDays: 'Opakovat v těchto dnech',
        eventTime: 'Čas události',
      },
    },
  },
};
