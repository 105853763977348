export default {
  app: {
    communities: {
      acceptMembership: 'Přijmout členství',
      create: 'Založit skupinu nebo projekt',
      createSubtitle: 'Vytvořte vlastní skupinu či projekt',
      becomeMember: 'Stát se členem',
      cancelMembership: 'Zrušit členství',
      cancelPendingMembership: 'Zrušit žádost o členství',
      chooseCommunityStep: 'Vyberte skupinu nebo projekt',
      find: 'Najít další',
      findTitle: 'Najít skupiny a projekty',
      join: 'Připojit',
      kind: 'Typ',
      member: 'Žádný člen | 1 člen | {count} členové | {count} členů',
      noGroups: 'Žádné skupiny nebo projekty',
      rejectMembership: 'Odmítnout členství',
      requestMembership: 'Požádat o přístup',
      requestMembershipFailed: 'Žádost o členství se nepodařilo zpracovat.',
      requestMembershipSent: 'Žádost o členství byla zaslána majiteli skupiny.',
      withPermissions: 'Skupiny a projekty, které spravuji',
      withoutPermissions: 'Skupiny a projekty, kde jsem členem',
    },
  },
};
