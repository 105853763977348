import createPostItem from './createPostItem';
import { ImageItem } from '~/utils/media';
import { CzYsP14KcPostApiDtoPostObject } from '~/app/core/apiClient/api';
import VisibilityEnum = CzYsP14KcPostApiDtoPostObject.VisibilityEnum;
import { FeedType } from '~/utils/feed';
import { AreaOfInterestItem } from '~/utils/areasOfInterest';

const icons = {
  create: 'mdi-message-text',
};

interface PostState {
  liking: boolean;
  loading: boolean;
  reporting: boolean;
}

enum PostSource {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  SELF = 'SELF',
  WORDPRESS = 'WORDPRESS',
}

interface PostItem {
  areasOfInterest: AreaOfInterestItem[];
  comments: number;
  content: string;
  contentOriginal: string;
  created: string;
  editable: boolean;
  excerpt: string;
  group: {
    id: string;
    name: string;
  };
  id: string;
  image: {
    thumbnail: ImageItem;
    small: ImageItem;
    original: ImageItem;
  };
  images: ImageItem[];
  liked: boolean;
  likes: number;
  reported: boolean;
  source: PostSource;
  state: PostState;
  title: string;
  type: FeedType;
  visibility: VisibilityEnum;
}

function isPostItem(data: any): data is PostItem {
  if (!data || typeof data !== 'object') {
    return false;
  }

  return data.type === FeedType.POST;
}

export { createPostItem, icons, isPostItem, PostItem, PostSource };
