export default {
  app: {
    forgottenDialog: {
      headline: 'Zapomenuté heslo',
      text:
        'Obnovení svého hesla jednoduše zvládnete za pomoci Vašeho mobilního telefonu v naší chytré aplikaci.',
      actionText: 'Aplikaci stáhnete zde:',
    },
  },
};
