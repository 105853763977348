import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VueComponent } from '~/utils/vue-component';

import style from './Header.scss';
import UserModule from '~/app/core/store/modules/UserModule';
import { ProfileModal } from '~/components/templates';
import { Link } from '~/components/molecules';
import logo from '~/assets/logo.png';
import { getLocalizedLocation } from '~/app/core/router';
import homepageRoute from '~/app/core/router/routes/homepage';

const rootClass = 'ys-header';

export const headerHeight = 120;

@Component({
  style,
})
export default class Header extends VueComponent<{}> {
  protected editingProfile: boolean = false;
  protected loading: boolean = false;

  public get userStore() {
    return getModule(UserModule, this.$store);
  }

  public get user() {
    return this.userStore.user;
  }

  public render() {
    return (
      <v-app-bar
        class={rootClass}
        app
        dark
        clipped-left
        height={`${headerHeight}px`}
        color='primary'
      >
        <div
          class={`${rootClass}__content mx-auto d-flex align-center flex-grow-1 justify-space-between`}
        >
          <v-toolbar-title>
            <Link to={getLocalizedLocation(homepageRoute, this.$router, {})}>
              <v-img
                class={`${rootClass}__logo`}
                src={logo}
                alt={this.$t('app.common.brand')}
              />
            </Link>
          </v-toolbar-title>
          <div class={`${rootClass}__title d-none d-sm-block`}>
            <h1>{this.$t('app.common.appName')}</h1>
          </div>
          {this.user && [
            <v-menu
              offset-y
              scopedSlots={{
                activator: (scope: any) => {
                  return (
                    <v-toolbar-items
                      class={`${rootClass}__user`}
                      {...{
                        on: scope.on,
                      }}
                    >
                      <div class='d-none d-md-flex align-center'>
                        <span class='title'>{this.user!.displayName}</span>
                      </div>
                      <v-avatar class={`${rootClass}__avatar ml-3`}>
                        <v-img
                          src={this.user!.photoURL}
                          alt={this.$t('app.profile.photoAlt')}
                          cover
                          aspect-ratio={1}
                        />
                      </v-avatar>
                    </v-toolbar-items>
                  );
                },
              }}
            >
              <v-list>
                <v-list-item
                  onClick={() => {
                    this.editingProfile = true;
                  }}
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {this.$t('app.common.userProfile')}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item onClick={this.logout}>
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {this.$t('app.login.logout')}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>,
            <ProfileModal
              active={this.editingProfile}
              onCloseDialog={() => {
                this.editingProfile = false;
              }}
            />,
          ]}
        </div>
      </v-app-bar>
    );
  }

  protected logout() {
    this.loading = true;
    this.userStore.logout().finally(() => {
      this.loading = false;
    });
  }
}
