import format from 'date-fns/format';
import { dateFnsLocalizations, defaultLocale } from '~/app/localization';
import { defaultDateFormat, defaultDateTimeFormat } from '~/utils/dateTime';

export default function(
  date: Date,
  dateFormat: string = defaultDateFormat,
  locale: string = defaultLocale
) {
  return format(date, dateFormat, {
    locale: dateFnsLocalizations[locale],
  });
}

export function timeFormat(
  date: Date,
  dateFormat: string = defaultDateTimeFormat,
  locale: string = defaultLocale
) {
  return format(date, dateFormat, {
    locale: dateFnsLocalizations[locale],
  });
}
