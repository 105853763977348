import { Module, Action, Mutation } from 'vuex-module-decorators';
import AbstractModule from './AbstractModule';
import Firebase from 'firebase';
import {
  CzYsP14KcUserApiDtoUserObject,
  CzYsP14KcUserApiDtoUserUpdateObject,
} from '~/app/core/apiClient/api';
import { parse } from '~/utils/date-fns';
import { getUserImageUrl } from '~/utils/user';
import { isPromise } from '~/utils/typeguards';

export interface LoginWithEmailData {
  email: string;
  password: string;
}

function isUserObject(data: any): data is CzYsP14KcUserApiDtoUserObject {
  // All interface properties are optional
  return typeof data === 'object';
}

function isUserUpdateObject(
  data: any
): data is CzYsP14KcUserApiDtoUserUpdateObject {
  // All interface properties are optional
  return typeof data === 'object';
}

export interface UserInfo {
  displayName: string;
  phoneNumber: string;
  photoURL: string;
  areasOfInterest: string[];
}

export interface User extends UserInfo {
  email: string;
  id: string;
  refreshToken: string;
}

interface UserDataCommit {
  user: CzYsP14KcUserApiDtoUserObject | CzYsP14KcUserApiDtoUserUpdateObject;
}

function createUserFromFirebase(data: Firebase.User): User {
  return {
    displayName: data.displayName || data.email || '',
    email: data.email || '',
    id: '',
    phoneNumber: data.phoneNumber || '',
    photoURL: '',
    refreshToken: data.refreshToken,
    areasOfInterest: [],
  };
}

const refreshTokenTimeout = 5 * 60 * 1000;

@Module({
  name: 'UserModule',
  stateFactory: true,
  namespaced: true,
})
export default class UserModule extends AbstractModule {
  public user: User | null = null;
  public accessToken: string | null = null;
  protected auth: Firebase.auth.Auth | null = null;
  protected userPromise: Promise<UserDataCommit> | null = null;
  public isNotRegistered: boolean = false;

  protected tokenExpiration: Date | null = null;

  protected tokenIdResultPromise: Promise<void> | null = null;

  get firebaseAuth() {
    return this.auth;
  }

  get isAuthReady() {
    return !!this.auth;
  }

  @Action({ rawError: true })
  public loginWithEmail(data: LoginWithEmailData): Promise<boolean> {
    this.initAuth();
    if (!this.auth) {
      return Promise.reject(new Error('Auth does not exist'));
    }

    this.setNotRegisteredFlag(false);

    return this.auth
      .signInWithEmailAndPassword(data.email, data.password)
      .then(() => true);
  }

  @Action({ rawError: true })
  public logout() {
    this.initAuth();
    if (!this.auth) {
      return Promise.reject(new Error('Auth does not exist'));
    }
    return this.auth
      .signOut()
      .then(() => this.setUser(null))
      .then(() => this.setUserPromise(null))
      .then(() => true);
  }

  @Action({ rawError: true })
  public initAuth() {
    return new Promise((resolve, reject) => {
      if (!this.auth) {
        const auth = Firebase.auth();
        auth.onAuthStateChanged((firebaseUser) => {
          this.setNotRegisteredFlag(false);

          if (!firebaseUser) {
            this.setUser(null);
            this.setAuth(auth);
            resolve();
          } else {
            const idToken = (refresh: boolean): Promise<void> => {
              return firebaseUser.getIdTokenResult(refresh).then((res) => {
                const expiration = parse(res.expirationTime);
                if (!isNaN(expiration.getTime())) {
                  this.setTokenExpiration(expiration);
                  const timeout = expiration.getTime() - new Date().getTime();
                  // Set timeout only if the remaining time is less than 5 minutes
                  if (timeout > refreshTokenTimeout) {
                    setTimeout(() => {
                      idToken(true);
                    }, Math.round((timeout / 4) * 3));
                  } else if (!refresh) {
                    return idToken(true);
                  }
                }

                this.setAccessToken(res.token);

                return Promise.resolve();
              });
            };

            idToken(false).then(() => {
              // TODO: better handling of app readiness
              this.getUserData()
                .then((user) => {
                  this.setUser(createUserFromFirebase(firebaseUser));
                  this.setNotRegisteredFlag(false);
                  this.setAuth(auth);
                })
                .catch((err) => {
                  // Auth must be set before logout to prevent recursion
                  this.setAuth(auth);

                  if (err.status === 404) {
                    // User does not have account yet
                    return this.logout().then(() => {
                      this.setNotRegisteredFlag(true);
                    });
                  }
                })
                .finally(() => {
                  resolve();
                });
            });
          }
        });
      } else {
        resolve();
      }
    });
  }

  @Action({ rawError: true })
  public refreshUserToken(): Promise<void> {
    if (!this.auth || !this.auth.currentUser) {
      return Promise.reject('No authentication available');
    }

    if (this.tokenExpiration) {
      const timeout = this.tokenExpiration.getTime() - new Date().getTime();
      // Check if the token is still valid
      if (timeout > refreshTokenTimeout) {
        return Promise.resolve();
      }
    }

    if (this.tokenIdResultPromise) {
      return this.tokenIdResultPromise;
    }

    const promise = this.auth.currentUser.getIdTokenResult(true).then((res) => {
      this.setAccessToken(res.token);
      this.setTokenIdResultPromise(null);

      const expiration = parse(res.expirationTime);
      if (!isNaN(expiration.getTime())) {
        this.setTokenExpiration(expiration);
      }
    });

    this.setTokenIdResultPromise(promise);

    return promise;
  }

  @Mutation
  protected setTokenIdResultPromise(promise: Promise<void> | null) {
    this.tokenIdResultPromise = promise;
  }

  @Action({ commit: 'patchUser', rawError: true })
  public getUserData(): Promise<UserDataCommit> {
    if (isPromise(this.userPromise)) {
      return this.userPromise;
    }
    const promise = this.$api.users.getMe().then((res) => {
      return { user: res };
    });
    this.setUserPromise(promise);
    return promise;
  }

  @Mutation
  protected setUser(user: User | null) {
    this.user = user;
  }

  @Mutation
  protected setUserPromise(userData: Promise<UserDataCommit> | null) {
    this.userPromise = userData;
  }

  @Mutation
  protected setAuth(auth: Firebase.auth.Auth) {
    this.auth = auth;
  }

  @Mutation
  protected setAccessToken(token: string) {
    this.accessToken = token;
  }

  @Mutation
  protected setTokenExpiration(expiration: Date) {
    this.tokenExpiration = expiration;
  }

  @Mutation
  public patchUser(data: UserDataCommit) {
    if (this.user) {
      this.user.displayName = data.user.displayName || this.user.displayName;
      this.user.phoneNumber = data.user.phone || this.user.phoneNumber;
      this.user.areasOfInterest =
        data.user.areasOfInterest || this.user.areasOfInterest;
      if (isUserObject(data.user)) {
        if (data.user.id) {
          this.user.id = data.user.id;
          this.user.photoURL = getUserImageUrl(data.user.id);
        }
      }
      if (isUserUpdateObject(data.user)) {
        if (data.user.avatarUrl) {
          this.user.photoURL = data.user.avatarUrl;
        }
      }
    }
  }

  @Mutation
  public setNotRegisteredFlag(value: boolean) {
    this.isNotRegistered = value;
  }
}
