import { Component, Ref, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VueComponentMixin } from '~/utils/vue-component';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import HeadManagement from '~/mixins/HeadManagement';
import RequestErrorComponent from '~/mixins/RequestErrorComponent';

import { NotFound } from '~/components/templates/common';
import { greyBackgroundColor } from '~/utils/theme/colors';
import { PostItem } from '~/utils/post';
import PostsModule from '~/app/core/store/modules/PostsModule';
import { DetailToolbar, PostBody, PostHead } from '~/components/organisms';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import {
  AreasOfInterests,
  BottomToolbar,
  ErrorSnack,
  GroupCard,
  SuccessSnack,
} from '~/components/molecules';
import { getLocalizedLocation } from '~/app/core/router';
import postDetailRoute from '~/app/core/router/routes/postDetail';
import { commentsFragment, CommentType } from '~/utils/comments';
import { CommentTypeBlock, EditPostModal } from '~/components/templates';
import createSeoTags, { TYPE } from '~/utils/views/createSeoTags';
import { routes } from '~/app/core/router/routes';
import getPostImageUrl from '~/utils/post/getPostImageUrl';
import { PostKindLabel } from '~/components/atoms';
import {
  ShareButtonProps,
  ToolbarButtonProps,
} from '~/components/molecules/toolbarSubmenu/ToolbarSubmenu';
import UserModule from '~/app/core/store/modules/UserModule';
import { createShareUri } from '~/utils/http/links';
import { imageVersion } from '~/utils/media';
import { headerHeight } from '~/components/organisms/header/Header';
import ScrollToRefMixin from '~/mixins/ScrollToRefMixin';
import { GroupItem } from '~/utils/group';
import GroupModule from '~/app/core/store/modules/GroupModule';

interface PostSnacks {
  copied: boolean;
  reported: boolean;
  reportFailed: boolean;
}

@Component
export default class PostDetail extends VueComponentMixin<
  {},
  RequestErrorComponent & PrefetchComponent & ScrollToRefMixin
>(Prefetch, HeadManagement, RequestErrorComponent, ScrollToRefMixin) {
  @Ref('commentBlock')
  protected readonly commentBlock?: Vue;

  protected editingPost: boolean = false;

  protected error: boolean = false;

  protected found: boolean = true;

  protected forbidden: boolean = false;

  protected scrollCommentsAfterLoad: boolean = false;

  public title() {
    if (this.loadingPrefetchData) {
      return '...';
    }

    return this.post ? this.post.title : this.$t('app.error.post.notFound');
  }

  public headTags(): string {
    if (!this.post) {
      return '';
    }

    return createSeoTags(
      this.post.title,
      this.post.content,
      this.$router.resolve(
        getLocalizedLocation(routes.postPreview, this.$router, {
          guid: this.postId,
        })
      ).href,
      TYPE.ARTICLE,
      getPostImageUrl(this.postId, imageVersion())
    );
  }

  public get postId() {
    return this.$route.params.guid;
  }

  protected get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  protected get postsStore() {
    return getModule(PostsModule, this.$store);
  }

  protected get userStore() {
    return getModule(UserModule, this.$store);
  }

  protected get post(): PostItem | null {
    return this.postsStore.currentPost;
  }

  protected get preview() {
    return !!this.$route.meta.public;
  }

  protected postActions: PostSnacks = {
    copied: false,
    reported: false,
    reportFailed: false,
  };

  protected get groupItem(): GroupItem | null {
    if (!this.post) {
      return null;
    }

    for (const group of this.groupStore.groupCache) {
      if (group.id === this.post.group.id) {
        return group;
      }
    }

    return null;
  }

  protected get editButton(): ToolbarButtonProps | undefined {
    if (!this.post || !this.post.editable || this.preview) {
      return;
    }

    return {
      disabled: false,
      loading: false,
    };
  }

  protected get reportButton(): ToolbarButtonProps | undefined {
    if (!this.userStore.user) {
      return;
    }

    return {
      disabled: this.postActions.reported,
      loading: this.post ? this.post.state.reporting : false,
    };
  }

  protected get shareButton(): ShareButtonProps | undefined {
    return {
      disabled: false,
      loading: false,
      uri: createShareUri(
        this.$router.resolve(
          getLocalizedLocation(routes.postPreview, this.$router, {
            guid: this.postId,
          })
        ).href
      ),
    };
  }

  public prefetch() {
    this.requestErrorResetStatus();

    if (this.$ssrContext) {
      return Promise.resolve();
    }

    this.scrollCommentsAfterLoad = true;

    return this.areasOfInterestStore.loadData().then(() => {
      return this.postsStore
        .get({
          id: this.postId,
          areasOfInterest: this.areasOfInterestStore.interests,
        })
        .then((post) => {
          this.postsStore.setCurrentPost(post);
        })
        .catch((err) => {
          if (err.status && err.status === 401 && this.$route.meta.public) {
            return this.$router.push(
              getLocalizedLocation(postDetailRoute, this.$router, {
                guid: this.postId,
              })
            );
          }
          this.requestErrorHandler(err);
        });
    });
  }

  public render() {
    if (this.loadingPrefetchData) {
      return <v-progress-linear indeterminate />;
    } else if (this.requestErrorComponent) {
      return this.requestErrorComponent;
    } else if (this.post === null) {
      return <NotFound />;
    }

    return (
      <v-container key={`unique-id-post-detail-${this.postId}`}>
        <v-card class={`${greyBackgroundColor} pb-0 pa-0`} flat>
          <PostHead post={this.post} />
          <v-card-text>
            <DetailToolbar
              edit={this.editButton}
              report={this.reportButton}
              share={this.shareButton}
              onEdit={() => {
                this.editingPost = true;
              }}
              onReport={this.reportPost}
              onShare={() => {
                this.postActions.copied = true;
              }}
            >
              {this.groupItem && (
                <GroupCard group={this.groupItem} slot='left' />
              )}
              <PostKindLabel slot='right' post={this.post} class='mx-1' />
            </DetailToolbar>
            {!this.preview && this.groupItem && (
              <EditPostModal
                active={this.editingPost}
                groupId={this.groupItem.id}
                postId={this.postId}
                onCloseDialog={() => (this.editingPost = false)}
              />
            )}
            <PostBody post={this.post} />
          </v-card-text>
          <v-divider />
          <v-container>
            <AreasOfInterests items={this.post.areasOfInterest} />
          </v-container>
          <BottomToolbar
            commentsCount={this.post.comments}
            liked={this.post.liked}
            likes={this.post.likes}
            loadingLike={this.post.state.liking}
            onLike={this.like}
            onUnlike={this.unlike}
            onShowComments={this.scrollToComments}
          />
        </v-card>

        <CommentTypeBlock
          onCommentsLoaded={() => {
            if (
              !this.scrollCommentsAfterLoad ||
              this.$route.hash !== commentsFragment
            ) {
              return;
            }

            this.scrollToComments();
          }}
          parentId={this.postId}
          ref='commentBlock'
          type={CommentType.POST}
        />

        <ErrorSnack v-model={this.postActions.reportFailed}>
          {this.$t('app.error.post.reportFailed')}
        </ErrorSnack>
        <SuccessSnack v-model={this.postActions.reported}>
          {this.$t('app.posts.reported')}
        </SuccessSnack>
        <SuccessSnack v-model={this.postActions.copied}>
          {this.$t('app.share.success')}
        </SuccessSnack>
      </v-container>
    );
  }

  protected reportPost() {
    if (!this.post) {
      return;
    }

    this.postsStore
      .report({ id: this.post.id })
      .then(() => {
        this.postActions.reported = true;
      })
      .catch((_) => {
        this.postActions.reportFailed = true;
      });
  }

  protected like() {
    this.postsStore
      .like({
        id: this.postId,
      })
      .catch(() => {
        // TODO: implement user error
      });
  }

  protected unlike() {
    this.postsStore
      .unlike({
        id: this.postId,
      })
      .catch(() => {
        // TODO: implement user error
      });
  }

  protected scrollToComments() {
    if (!this.commentBlock) {
      return;
    }

    this.scrollCommentsAfterLoad = false;

    this.scrollToRef(this.commentBlock, -headerHeight);
  }
}
