import { Component, Emit, Prop, Ref } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { acceptedImageMimes } from '~/utils/media';

export interface ImageSelectionEvent {
  images: string[];
  firstImage: string | null;
}

interface ImageSelectionInterface {
  absolute?: boolean;
  color?: string;
  icon?: string;
  multiple?: boolean;
  textButton?: boolean;
  onSelected?: (data: ImageSelectionEvent) => any;
}

@Component
export default class ImageSelection extends VueComponent<
  ImageSelectionInterface
> {
  @Prop({ default: false })
  public absolute?: boolean;

  @Prop()
  public color?: string;

  @Prop()
  public icon?: string;

  @Prop({ default: false })
  public multiple!: boolean;

  @Prop({ default: false })
  public textButton!: boolean;

  @Ref('fileInput')
  protected readonly fileInput!: HTMLInputElement;

  protected images: string[] = [];

  protected get right() {
    return this.absolute ? true : undefined;
  }

  protected get top() {
    return this.absolute ? true : undefined;
  }

  protected get buttonColor() {
    if (this.color) {
      return this.color;
    }

    if (this.textButton) {
      return 'secondary';
    }
  }

  public render() {
    return (
      <v-btn
        onClick={this.openDialog}
        text={this.textButton}
        color={this.buttonColor}
        rounded
        absolute={this.absolute}
        top={this.top}
        right={this.right}
      >
        <v-icon left>{this.icon || 'mdi-image-auto-adjust'}</v-icon>
        {this.$slots.default}
        <input
          class='d-none'
          accept={acceptedImageMimes.join(',')}
          type='file'
          ref='fileInput'
          onChange={this.change}
          multiple={this.multiple}
        />
      </v-btn>
    );
  }

  @Emit('selected')
  protected emitSelected(): ImageSelectionEvent {
    return {
      images: this.images,
      firstImage: this.images[0] || null,
    };
  }

  protected openDialog() {
    this.fileInput.click();
  }

  protected change() {
    if (this.fileInput.files) {
      const promises: Promise<any>[] = [];

      this.images = [];

      for (const file of this.fileInput.files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const promise = new Promise((resolve, reject) => {
          reader.onloadend = (e) => {
            if (e.target && typeof e.target.result === 'string') {
              this.images.push(e.target.result);
              resolve();
            } else {
              reject();
            }
          };
        });

        promises.push(promise);

        if (!this.multiple) {
          break;
        }
      }

      this.fileInput.value = '';

      Promise.all(promises).then(this.emitSelected);
    }
  }
}
