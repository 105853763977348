import { Component, Ref, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VueComponentMixin } from '~/utils/vue-component';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import HeadManagement from '~/mixins/HeadManagement';
import EventDetailModule from '~/app/core/store/modules/EventDetailModule';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';

import { DetailToolbar, EventBody, EventHead } from '~/components/organisms';
import { format, parse } from '~/utils/date-fns';
import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';
import { greyBackgroundColor } from '~/utils/theme/colors';
import { commentsFragment, CommentType } from '~/utils/comments';
import { CommentTypeBlock, EditEventModal } from '~/components/templates';
import RequestErrorComponent from '~/mixins/RequestErrorComponent';
import eventDetailRoute from '~/app/core/router/routes/eventDetail';
import { routeParameterDateFormat } from '~/app/core/router/routes/parameters/date';
import createSeoTags, { TYPE } from '~/utils/views/createSeoTags';
import getEventImageUrl from '~/utils/event/getEventImageUrl';
import {
  DeleteButtonProps,
  ShareButtonProps,
  ToolbarButtonProps,
} from '~/components/molecules/toolbarSubmenu/ToolbarSubmenu';
import {
  AreasOfInterests,
  BottomToolbar,
  ErrorSnack,
  GroupCard,
  SuccessSnack,
} from '~/components/molecules';
import UserModule from '~/app/core/store/modules/UserModule';
import { GeoPosition } from '~/utils/googleMaps';
import { createShareUri } from '~/utils/http/links';
import { imageVersion } from '~/utils/media';
import { headerHeight } from '~/components/organisms/header/Header';
import ScrollToRefMixin from '~/mixins/ScrollToRefMixin';
import { GroupItem } from '~/utils/group';
import GroupModule from '~/app/core/store/modules/GroupModule';

interface EventSnacks {
  copied: boolean;
  deleted: boolean;
  deleteFailed: boolean;
  reported: boolean;
  reportFailed: boolean;
}

@Component
export default class EventDetail extends VueComponentMixin<
  {},
  RequestErrorComponent & PrefetchComponent & ScrollToRefMixin
>(Prefetch, HeadManagement, RequestErrorComponent, ScrollToRefMixin) {
  @Ref('commentBlock')
  protected readonly commentBlock?: Vue;

  protected editingEvent: boolean = false;

  protected reportingEvent: boolean = false;

  protected eventActions: EventSnacks = {
    copied: false,
    deleted: false,
    deleteFailed: false,
    reported: false,
    reportFailed: false,
  };

  protected scrollCommentsAfterLoad: boolean = false;

  public title() {
    if (this.loadingPrefetchData) {
      return '...';
    }

    return this.eventStore.id
      ? this.eventStore.title
      : this.$t('app.error.event.notFound');
  }

  public headTags(): string {
    return createSeoTags(
      this.eventStore.title,
      this.eventStore.content,
      this.$router.resolve(
        getLocalizedLocation(routes.eventPreview, this.$router, {
          guid: this.eventId,
        })
      ).href,
      TYPE.ARTICLE,
      getEventImageUrl(this.eventId, imageVersion())
    );
  }

  public get eventId() {
    return this.$route.params.guid;
  }

  public get eventDate() {
    return parse(this.$route.params.date);
  }

  protected get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  protected get eventStore() {
    return getModule(EventDetailModule, this.$store);
  }

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  protected get userStore() {
    return getModule(UserModule, this.$store);
  }

  protected get preview() {
    return !!this.$route.meta.public;
  }

  protected get groupItem(): GroupItem | null {
    for (const group of this.groupStore.groupCache) {
      if (group.id === this.eventStore.groupId) {
        return group;
      }
    }

    return null;
  }

  protected get deleteButton(): DeleteButtonProps | undefined {
    if (!this.eventStore.editable || this.preview) {
      return;
    }

    return {
      disabled: this.eventStore.beingLiked,
      loading: this.eventStore.beingDeleted,
      confirmationTitle: this.$t('app.event.deleteConfirmation'),
    };
  }

  protected get editButton(): ToolbarButtonProps | undefined {
    if (!this.eventStore.editable || this.preview) {
      return;
    }

    return {
      disabled: this.eventStore.beingDeleted,
      loading: false,
    };
  }

  protected get position(): GeoPosition | null {
    if (!this.eventStore.event || !this.eventStore.event.location) {
      return null;
    }

    if (
      !this.eventStore.event.location.lat ||
      !this.eventStore.event.location.lng
    ) {
      return null;
    }

    return {
      latitude: this.eventStore.event.location.lat,
      longitude: this.eventStore.event.location.lng,
    };
  }

  protected get reportButton(): ToolbarButtonProps | undefined {
    if (!this.userStore.user) {
      return;
    }

    return {
      disabled: this.eventStore.beingDeleted || this.eventActions.reported,
      loading: this.reportingEvent,
    };
  }

  protected get shareButton(): ShareButtonProps | undefined {
    return {
      disabled: false,
      loading: false,
      uri: createShareUri(
        this.$router.resolve(
          getLocalizedLocation(routes.eventPreview, this.$router, {
            date: this.$route.params.date,
            guid: this.eventId,
          })
        ).href
      ),
    };
  }

  public prefetch() {
    if (this.$ssrContext) {
      return Promise.resolve();
    }

    this.scrollCommentsAfterLoad = true;

    return this.eventStore
      .loadData({
        eventId: this.eventId,
        date: this.eventDate,
      })
      .catch((err) => {
        if (this.$route.meta.public && err.status && err.status === 401) {
          return this.$router.push(
            getLocalizedLocation(eventDetailRoute, this.$router, {
              guid: this.eventId,
              date: format(this.eventDate, routeParameterDateFormat),
            })
          );
        }
        this.requestErrorHandler(err);
      });
  }

  public render() {
    if (this.loadingPrefetchData) {
      return <v-progress-linear indeterminate />;
    } else if (this.requestErrorComponent) {
      return this.requestErrorComponent;
    }

    return (
      <v-container key={`unique-id-event-detail-${this.eventId}`}>
        <v-card class={`${greyBackgroundColor} pb-0 pa-0`} flat>
          <EventHead
            date={this.eventDate}
            eventId={this.eventStore.id}
            isPublic={this.eventStore.isPublic}
            title={this.eventStore.title}
          />

          <v-container>
            <DetailToolbar
              delete={this.deleteButton}
              edit={this.editButton}
              report={this.reportButton}
              share={this.shareButton}
              onReport={this.reportEvent}
              onDelete={this.handleDelete}
              onEdit={() => {
                this.editingEvent = true;
              }}
              onShare={() => {
                this.eventActions.copied = true;
              }}
            >
              {this.groupItem && (
                <GroupCard group={this.groupItem} slot='left' />
              )}
            </DetailToolbar>
            {!this.preview && (
              <EditEventModal
                eventId={this.eventStore.id}
                groupId={this.eventStore.groupId}
                groupName={this.eventStore.groupName}
                active={this.editingEvent}
                instanceDate={this.eventDate}
                onCloseDialog={() => (this.editingEvent = false)}
              />
            )}
            <EventBody
              address={this.eventStore.address}
              barrierFree={this.eventStore.barrierFree}
              capacity={this.eventStore.capacity}
              content={this.eventStore.content}
              date={this.eventStore.dates}
              dogsAllowed={this.eventStore.dogsAllowed}
              eventDate={this.eventDate}
              futurePlan={this.eventStore.futurePlan}
              link={this.eventStore.link}
              place={this.eventStore.place}
              position={this.position}
              price={this.eventStore.price}
              time={this.eventStore.times}
            />
          </v-container>
          <v-divider />
          <v-container>
            <AreasOfInterests items={this.eventStore.areasOfInterest} />
          </v-container>
          <BottomToolbar
            commentsCount={this.eventStore.event?.commentCount}
            liked={this.eventStore.liked}
            likes={this.eventStore.event?.likes}
            loadingLike={this.eventStore.beingLiked}
            onLike={this.likeEvent}
            onUnlike={this.unlikeEvent}
            onShowComments={this.scrollToComments}
          />
        </v-card>

        <CommentTypeBlock
          onCommentsLoaded={() => {
            if (
              !this.scrollCommentsAfterLoad ||
              this.$route.hash !== commentsFragment
            ) {
              return;
            }

            this.scrollToComments();
          }}
          parentId={this.eventStore.id}
          type={CommentType.EVENT}
          ref='commentBlock'
        />
        <div class='ys-inner-space' />

        <ErrorSnack v-model={this.eventActions.deleteFailed}>
          {this.$t('app.error.event.deleteFailed')}
        </ErrorSnack>
        <ErrorSnack v-model={this.eventActions.reportFailed}>
          {this.$t('app.error.event.reportFailed')}
        </ErrorSnack>
        <SuccessSnack v-model={this.eventActions.deleted}>
          {this.$t('app.events.deleted')}
        </SuccessSnack>
        <SuccessSnack v-model={this.eventActions.reported}>
          {this.$t('app.events.reported')}
        </SuccessSnack>
        <SuccessSnack v-model={this.eventActions.copied}>
          {this.$t('app.share.success')}
        </SuccessSnack>
      </v-container>
    );
  }

  protected likeEvent() {
    this.eventStore.likeEvent().catch(() => {
      // TODO: implement user error
    });
  }

  protected unlikeEvent() {
    this.eventStore.unlikeEvent().catch(() => {
      // TODO: implement user error
    });
  }

  protected handleDelete() {
    this.eventStore
      .deleteEvent()
      .then((data) => {
        this.eventActions.deleted = true;
        // Wait for the user to notice the message before sending them away
        setTimeout(() => {
          this.$router.push(
            getLocalizedLocation(routes.groupDetail, this.$router, {
              guid: data.originalGroupId,
            })
          );
        }, 2000);
      })
      .catch(() => {
        this.eventActions.deleteFailed = true;
      });
  }

  protected reportEvent() {
    this.reportingEvent = true;
    this.$api.events
      .toxicEvent(this.eventId)
      .then(() => {
        this.eventActions.reported = true;
      })
      .catch((_) => {
        this.eventActions.reportFailed = true;
      })
      .finally(() => {
        this.reportingEvent = false;
      });
  }

  protected scrollToComments() {
    if (!this.commentBlock) {
      return;
    }

    this.scrollCommentsAfterLoad = false;

    this.scrollToRef(this.commentBlock, -headerHeight);
  }
}
