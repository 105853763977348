import { RouteConfig } from 'vue-router';

import EventDetail from '~/views/EventDetail';
import parameters from './parameters';

const config: RouteConfig = {
  component: EventDetail,
  path: `public/event/${parameters.guidPath}/${parameters.datePath}`,
  meta: {
    public: true,
  },
};

export default config;
