import { Component, Prop } from 'vue-property-decorator';

import { Content } from '~/components/atoms';
import { HtmlRenderer } from '~/components/templates';
import { PostItem, PostSource } from '~/utils/post';
import { VueComponent } from '~/utils/vue-component';

interface PostBodyInterface {
  post: PostItem;
}

@Component
export default class PostBody extends VueComponent<PostBodyInterface>
  implements PostBodyInterface {
  @Prop({ required: true })
  public post!: PostItem;

  public render() {
    return (
      <v-row>
        <v-col cols='12'>
          <HtmlRenderer
            key={'post-body-content-' + this.post.content}
            content={this.post.content}
          />
        </v-col>
      </v-row>
    );
  }
}
