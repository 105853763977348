import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import { isApiFetchResponse } from '~/app/core/apiClient/api';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import {
  EventDateRange,
  EventTimeRange,
} from '~/components/molecules/eventDates/EventDates';
import { AreaOfInterestItem } from '~/utils/areasOfInterest';
import { format } from '~/utils/date-fns';
import { RFC3339Format } from '~/utils/dateTime';
import { createEventItem, FutureEvent } from '~/utils/event';
import createEventDetail, {
  EventDetailItem,
} from '~/utils/event/createEventDetail';
import { GeoPosition } from '~/utils/googleMaps';
import { GeoLocation } from '~/utils/location';
import EventsModule from '~/app/core/store/modules/EventsModule';
import GroupModule from '~/app/core/store/modules/GroupModule';
import { Kind } from '~/utils/group';

enum EventLikeApiResponse {
  LIKED = 'Liked already',
  UNLIKED = 'Not liked',
}

interface StoreDataCommit {
  data: EventDetailItem;
  allAreas: AreaOfInterestItem[];
}

type SetLikedCommit = boolean;

type DeleteCommit = {
  originalGroupId: string;
};

interface EventDetailPayload {
  date: Date;
  eventId: string;
}

interface EventStatus {
  beingLiked: boolean;
  beingDeleted: boolean;
}

@Module({
  name: 'EventDetailModule',
  stateFactory: true,
  namespaced: true,
})
export default class EventDetailModule extends AbstractModule {
  public event: EventDetailItem | null = null;

  public address: string = '';
  public areasOfInterest: AreaOfInterestItem[] = [];
  public areasOfInterestIds: string[] = [];
  public barrierFree: boolean = false;
  public capacity: number | null = null;
  public content: string = '';
  public dates: EventDateRange = {
    from: format(new Date(), RFC3339Format),
    to: null,
  };
  public dogsAllowed: boolean = false;
  public editable: boolean = false;
  public futurePlan: FutureEvent[] = [];
  public groupId: string = '';
  public groupName: string = '';
  public id: string = '';
  public imageUrl: string = '';
  public isPublic: boolean = false;
  public location: GeoLocation = {
    ruianId: null,
    address: '',
    lat: null,
    lng: null,
    name: '',
  };
  public liked: boolean = false;
  public link: string | null = null;
  public place: string = '';
  public position: GeoPosition | null = null;
  public price: string = '';
  public times: EventTimeRange = {
    end: '00:00:00',
    start: '00:00:00',
  };
  public title: string = '';

  protected status: EventStatus = {
    beingDeleted: false,
    beingLiked: false,
  };

  public get beingDeleted() {
    return this.status.beingDeleted;
  }

  public get beingLiked() {
    return this.status.beingLiked;
  }

  @Action({ commit: 'storeData', rawError: true })
  public loadData(payload: EventDetailPayload): Promise<StoreDataCommit> {
    return getModule(AreasOfInterestModule, this.store)
      .loadData()
      .then((areas) => {
        return this.$api.events
          .getEventInstanceDetail(
            payload.eventId,
            format(payload.date, 'YYYY-MM-DD')
          )
          .then((res) => {
            const item = createEventItem(res, areas.allItems);
            if (item !== null) {
              getModule(EventsModule, this.store).cacheEvents([item]);
            }

            const data = createEventDetail(res);

            return getModule(GroupModule, this.store)
              .load({ groupId: data.groupId })
              .then((group) => {
                if (group[0].kind === Kind.PROJECT) {
                  data.times = {
                    start: null,
                    end: null,
                  };
                }
                return {
                  data,
                  allAreas: areas.allItems,
                };
              });
          });
      });
  }

  @Action({ rawError: true })
  public deleteEvent(): Promise<DeleteCommit> {
    this.setBeingDeleted(true);
    const originalGroupId = this.groupId;
    return this.$api.events
      .deleteEvent(this.id)
      .then(() => {
        return {
          originalGroupId,
        };
      })
      .finally(() => {
        this.setBeingDeleted(false);
      });
  }

  @Action({ commit: 'setLiked', rawError: true })
  public likeEvent(): Promise<SetLikedCommit> {
    this.setbeingLiked(true);
    return this.$api.events
      .likeEvent(this.id)
      .then(() => {
        return true;
      })
      .catch((err) => {
        if (isApiFetchResponse(err)) {
          return err.json().then((response) => {
            if (
              response.message &&
              response.message === EventLikeApiResponse.UNLIKED
            ) {
              return false;
            }
            throw new Error();
          });
        }

        throw new Error();
      })
      .finally(() => {
        this.setbeingLiked(false);
      });
  }

  @Action({ commit: 'setLiked', rawError: true })
  public unlikeEvent(): Promise<SetLikedCommit> {
    this.setbeingLiked(true);
    return this.$api.events
      .unlikeEvent(this.id)
      .then(() => {
        return false;
      })
      .catch((err) => {
        if (isApiFetchResponse(err)) {
          return err.json().then((response) => {
            if (
              response.message &&
              response.message === EventLikeApiResponse.LIKED
            ) {
              return false;
            }
            throw new Error();
          });
        }

        throw new Error();
      })
      .finally(() => {
        this.setbeingLiked(false);
      });
  }

  @Mutation
  protected setBeingDeleted(state: boolean) {
    this.status.beingDeleted = state;
  }

  @Mutation
  protected setbeingLiked(state: boolean) {
    this.status.beingLiked = state;
  }

  @Mutation
  public storeData(data: StoreDataCommit) {
    this.event = data.data;

    this.address = data.data.address;
    this.barrierFree = data.data.barrierFree;
    this.capacity = data.data.capacity || null;
    this.content = data.data.content;
    this.futurePlan = data.data.futurePlan;
    this.times = data.data.times;
    this.dates = data.data.dates;
    this.dogsAllowed = data.data.dogsAllowed;
    this.editable = data.data.editable;
    this.groupId = data.data.groupId;
    this.groupName = data.data.groupName;
    this.id = data.data.id;
    this.imageUrl = data.data.imageUrl;
    this.isPublic = data.data.isPublic;
    this.liked = data.data.liked;
    this.link = data.data.link;
    this.place = data.data.location ? data.data.location.name : '';
    this.price = data.data.price;
    this.areasOfInterestIds = data.data.areasOfInterestIds;
    this.title = data.data.title;

    this.status = {
      beingDeleted: false,
      beingLiked: false,
    };

    this.areasOfInterest = [];
    this.areasOfInterestIds.map((id) => {
      for (const item of data.allAreas) {
        if (id === item.id) {
          this.areasOfInterest.push(item);
        }
      }
    });
  }

  @Mutation
  public setLiked(data: SetLikedCommit) {
    this.liked = data;
    if (this.event) {
      this.event.likes = data ? this.event.likes + 1 : this.event.likes - 1;
    }
  }
}
