import { Component, Emit } from 'vue-property-decorator';
import BaseButton from '~/components/atoms/baseButton/BaseButton';
import { SpreadAttributes } from '~/utils/vue-component';

type ShowAllEvent = void;

interface ShowAllButtonInterface {
  onShowAll?: (data: ShowAllEvent) => void;
}

@Component
export default class ShowAllButton extends BaseButton<ShowAllButtonInterface> {
  protected get attributes(): SpreadAttributes {
    return {
      attrs: {
        color: 'secondary',
        rounded: true,
        text: true,
        ...this.baseAttributes.attrs,
      },
    };
  }

  public render() {
    return (
      <v-btn {...this.attributes} onClick={this.emitShowAll}>
        {this.$slots.default || this.$t('app.common.showAll')}
        {this.$slots.icon || <v-icon right>mdi-chevron-right</v-icon>}
      </v-btn>
    );
  }

  @Emit('showAll')
  protected emitShowAll(): ShowAllEvent {
    return;
  }
}
