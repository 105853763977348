import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import Comment, { ReportComment } from '~/components/molecules/comment/Comment';
import { CommentItem } from '~/utils/comments';
import { LoadMoreButton } from '~/components/atoms';
import { listItemOffset } from '~/utils/spacing';

type LoadMoreEvent = void;

interface CommentListInterface {
  comments: CommentItem[];
  initializing?: boolean;
  loading?: boolean;
  showLoadButton?: boolean;
  onLoadMore?: (data: LoadMoreEvent) => any;
  onReport?: (data: ReportComment) => any;
}

@Component
export default class CommentList extends VueComponent<CommentListInterface>
  implements CommentListInterface {
  @Prop({ required: true })
  public comments!: CommentItem[];

  @Prop({ default: false, type: Boolean })
  public initializing!: boolean;

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  @Prop({ default: false, type: Boolean })
  public showLoadButton!: boolean;

  public render() {
    if (!this.initializing && this.comments.length < 1) {
      return;
    }

    return (
      <v-skeleton-loader
        type='article'
        loading={this.initializing}
        transition='scale-transition'
      >
        <div>
          <v-row>
            <v-col>
              {this.comments.map((comment) => {
                return (
                  <Comment
                    class={`mb-${listItemOffset}`}
                    key={`comment-list-${comment.id}`}
                    comment={comment}
                    onReport={this.emitReport}
                  />
                );
              })}
            </v-col>
          </v-row>
          {this.showLoadButton && (
            <v-row no-gutters justify='center'>
              <LoadMoreButton
                loading={this.loading}
                onLoadMore={this.emitLoadMore}
              />
            </v-row>
          )}
        </div>
      </v-skeleton-loader>
    );
  }

  @Emit('loadMore')
  protected emitLoadMore(): LoadMoreEvent {
    return;
  }

  @Emit('report')
  protected emitReport(data: ReportComment): ReportComment {
    return data;
  }
}
