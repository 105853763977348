export default {
  app: {
    error: {
      post: {
        areasAreRequired: 'Vyberte alespoň jedno zaměření příspěvku.',
        contentIsRequired: 'Napiště prosím obsah příspěvku.',
        create: {
          generic: 'Nastala chyba při vytváření příspěvku.',
          forbidden: 'Nemáte oprávnění pro vytváření příspěvku.',
        },
        noGroupsToCreatePost:
          'Nejste správcem žádné skupiny pro vytváření příspěvku.',
        notCreated: 'Nebylo možné přidat příspěvek.',
        notFound: 'Příspěvek nebyl nalezen.',
        notUpdated: 'Nebylo možné upravit příspěvek.',
        reportFailed: 'Nepodařilo se nahlásit příspěvek.',
        titleIsRequired: 'Vyplňtě prosím název příspěvku.',
        update: {
          generic: 'Nastala chyba při aktualizaci příspěvku.',
          forbidden: 'Nemáte oprávnění pro úpravu příspěvku.',
        },
      },
    },
  },
};
