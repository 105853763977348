import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class Submenu extends VueComponent<{}> {
  public render() {
    return (
      <v-menu
        bottom
        left
        offset-y
        close-on-content-click={false}
        scopedSlots={{
          activator: (scope: any) => {
            return (
              <v-btn icon {...{ on: scope.on }}>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            );
          },
        }}
      >
        <v-list>{this.$slots.default}</v-list>
      </v-menu>
    );
  }
}
