import { CzYsP14KcCommunityApiDtoCommunityListDetailObject } from '~/app/core/apiClient/api';
import { GroupItem } from '~/utils/group/index';
import MembershipTypeEnum = CzYsP14KcCommunityApiDtoCommunityListDetailObject.MembershipTypeEnum;

export default function(group: GroupItem): boolean {
  return (
    group.membershipType === MembershipTypeEnum.DEPUTY ||
    group.membershipType === MembershipTypeEnum.OWNER
  );
}
