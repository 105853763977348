import { Component, Emit, Prop } from 'vue-property-decorator';

import BaseButton from '~/components/atoms/baseButton/BaseButton';
import { icons } from '~/utils/event';
import { SpreadAttributes } from '~/utils/vue-component';

type CreateEvent = undefined;

export interface CreateEventButtonInterface {
  active?: boolean;
  onCreate?: (data: CreateEvent) => void;
}

@Component
export default class CreateEventButton extends BaseButton<
  CreateEventButtonInterface
> {
  @Prop({ default: true, type: Boolean })
  public active!: boolean;

  protected get attributes(): SpreadAttributes {
    return {
      attrs: {
        dark: true,
        rounded: true,
        ...this.baseAttributes.attrs,
      },
    };
  }

  public render() {
    if (!this.active) {
      return;
    }

    return (
      <v-btn color='secondary' onClick={this.emitCreate} {...this.attributes}>
        <v-icon left>{icons.create}</v-icon>
        {this.$slots.default || this.$t('app.events.create')}
      </v-btn>
    );
  }

  @Emit('create')
  protected emitCreate(): CreateEvent {
    return;
  }
}
