import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import ErrorPage from '~/components/templates/errorPage/ErrorPage';
import picture from '~/assets/images/error/404.png';

@Component
export default class NotFound extends VueComponent<{}> {
  public render() {
    return (
      <ErrorPage
        httpCode={404}
        picture={picture}
        title={this.$t('app.error.view.notFound')}
      >
        <p class='mb-1'>{this.$t('app.error.view.notFoundParagraph1')}</p>
        <p class='mb-1'>{this.$t('app.error.view.notFoundParagraph2')}</p>
        <p class='mb-1'>{this.$t('app.error.view.notFoundParagraph3')}</p>
      </ErrorPage>
    );
  }
}
