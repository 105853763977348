import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VueComponentMixin } from '~/utils/vue-component';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import HeadManagement from '~/mixins/HeadManagement';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import { GroupTiles, RequestMembershipDialog } from '~/components/organisms';
import { greyBackgroundColor } from '~/utils/theme/colors';
import { GroupItem, Visibility } from '~/utils/group';
import createGroupItem from '~/utils/group/createGroupItem';
import { itemIsNotNull } from '~/utils/typeguards';
import GroupModule from '~/app/core/store/modules/GroupModule';
import { AreasOfInterests } from '~/components/molecules';
import { JoinButton } from '~/components/atoms';
import { createAreaOfInterestSelectItems } from '~/utils/areasOfInterest';
import { JoinStatus } from '~/components/atoms/joinButton/JoinButton';
import { CzYsP14KcCommunityApiDtoCommunityDetailObject } from '~/app/core/apiClient/api';
import MembershipTypeEnum = CzYsP14KcCommunityApiDtoCommunityDetailObject.MembershipTypeEnum;
import { componentOffset } from '~/utils/spacing';

@Component
export default class FindGroups extends VueComponentMixin<
  {},
  PrefetchComponent
>(Prefetch, HeadManagement) {
  protected selectedInterestsNames: string[] = [];

  protected undiscoveredGroupIds: GroupItem['id'][] = [];

  protected joiningPrivateGroup: GroupItem | null = null;

  protected get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  protected get undiscoveredGroups(): GroupItem[] {
    return this.undiscoveredGroupIds
      .map((id) => {
        // TODO: Horrible performance, thanks vuex
        for (const group of this.groupStore.groupCache) {
          if (group.id === id) {
            return group;
          }
        }

        return null;
      })
      .filter(itemIsNotNull);
  }

  protected get areasOfInterestSelectItems() {
    return createAreaOfInterestSelectItems(
      this.areasOfInterestStore.categorisedInterests,
      true
    );
  }

  public title() {
    return this.$t('app.communities.findTitle');
  }

  public prefetch() {
    if (this.$ssrContext) {
      return Promise.resolve();
    }

    this.undiscoveredGroupIds = [];

    return this.areasOfInterestStore.loadData().then(() => {
      return this.$api.communities.undiscoveredCommunities().then((res) => {
        if (res.content) {
          const groups = res.content
            .map((item) =>
              createGroupItem(item, this.areasOfInterestStore.interests)
            )
            .filter(itemIsNotNull);
          this.groupStore.cacheGroups(groups);
          groups.forEach((group) => {
            this.undiscoveredGroupIds.push(group.id);
          });
        }
      });
    });
  }

  protected creatingGroup: boolean = false;
  protected searchPhrase: string = '';

  public render() {
    return (
      <v-container class='py-6'>
        <v-card class={`px-5 ${greyBackgroundColor}`}>
          <v-card-text>
            <h1 class='mt-1'>{this.$t('app.communities.findTitle')}</h1>
            <v-row>
              <v-col cols='12' md='6'>
                <v-text-field
                  class='v-input--no-details'
                  v-model={this.searchPhrase}
                  label={this.$t('app.common.find')}
                  background-color='white'
                  clearable
                  prepend-inner-icon='mdi-magnify'
                  rounded
                  outlined
                />
              </v-col>
              <v-col cols='12' md='6'>
                <v-autocomplete
                  autocomplete='off'
                  class='v-input--no-details'
                  v-model={this.selectedInterestsNames}
                  label={this.$t('app.group.areasOfInterest')}
                  items={this.areasOfInterestSelectItems}
                  background-color='white'
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  rounded
                  outlined
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <GroupTiles
          items={this.undiscoveredGroups.filter(this.itemsFilter)}
          class='mt-5'
          scopedSlots={{
            tile: (item) => {
              let joinStatus: JoinStatus;
              switch (item.membershipType) {
                case MembershipTypeEnum.DEPUTY:
                case MembershipTypeEnum.MEMBER:
                case MembershipTypeEnum.OWNER:
                  joinStatus = JoinStatus.JOINED;
                  break;
                case MembershipTypeEnum.INVITEE:
                  joinStatus = JoinStatus.INVITED;
                  break;
                case MembershipTypeEnum.APPLICANT:
                  joinStatus = JoinStatus.PENDING;
                  break;
                default:
                  joinStatus = JoinStatus.NONE;
              }
              return [
                <AreasOfInterests
                  items={item.areasOfInterest}
                  class='mt-2'
                  small
                />,
                <v-spacer />,
                <div class='mt-2'>
                  <JoinButton
                    loading={item.state.joining}
                    onJoin={() => this.join(item)}
                    onLeave={() => this.leave(item)}
                    status={joinStatus}
                  />
                  {joinStatus === JoinStatus.INVITED && (
                    <div>
                      <JoinButton
                        active={true}
                        class={`mt-${componentOffset}`}
                        loading={item.state.joining}
                        onLeave={() => this.leave(item)}
                        status={JoinStatus.REJECT_INVITATION}
                      />
                    </div>
                  )}
                </div>,
              ];
            },
          }}
        />
        <RequestMembershipDialog
          active={!!this.joiningPrivateGroup}
          group={this.joiningPrivateGroup}
          onCloseDialog={() => {
            this.joiningPrivateGroup = null;
          }}
        />
      </v-container>
    );
  }

  protected itemsFilter(item: GroupItem) {
    let phraseMatch = true;
    if (
      this.searchPhrase.length &&
      !item.name.toLowerCase().includes(this.searchPhrase.toLowerCase())
    ) {
      phraseMatch = false;
    }

    let interestsMatch = true;
    if (this.selectedInterestsNames.length) {
      interestsMatch = false;
      item.areasOfInterest.map((tileInterest) => {
        for (const selectedInterest of this.selectedInterestsNames) {
          if (selectedInterest === tileInterest.title) {
            interestsMatch = true;
          }
        }
      });
    }

    return phraseMatch && interestsMatch;
  }

  protected join(item: GroupItem) {
    if (item.visibility === Visibility.PRIVATE) {
      this.joiningPrivateGroup = item;
      return;
    }

    return this.groupStore.joinGroup(item.id);
  }

  protected leave(item: GroupItem) {
    this.groupStore.leaveGroup(item.id);
  }
}
