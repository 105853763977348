import { RouteConfig } from 'vue-router';

import parameters from './parameters';
import PostDetail from '~/views/PostDetail';

const config: RouteConfig = {
  component: PostDetail,
  path: `public/post/${parameters.guidPath}`,
  meta: {
    public: true,
  },
};

export default config;
