import { Component, Prop } from 'vue-property-decorator';

import { VueComponent } from '~/utils/vue-component';
import { SurveyItem } from '~/utils/survey';
import { format, parse } from '~/utils/date-fns';
import { dateTimeFormatWithDays } from '~/utils/dateTime';

interface SurveyTimeInterface {
  survey: SurveyItem;
}

@Component
export default class SurveyTime extends VueComponent<SurveyTimeInterface>
  implements SurveyTimeInterface {
  @Prop({ required: true })
  public survey!: SurveyItem;

  protected get date(): Date | null {
    const date = parse(this.survey.end);
    if (isNaN(date.getTime())) {
      return null;
    }

    return date;
  }

  protected get formattedDate(): string {
    if (!this.date) {
      return '';
    }

    return format(this.date, dateTimeFormatWithDays);
  }

  protected get voted(): string | null {
    if (!this.survey.voted) {
      return null;
    }

    const date = parse(this.survey.voted.date);
    if (isNaN(date.getTime())) {
      return null;
    }

    return format(date, dateTimeFormatWithDays);
  }

  protected get voteIsDirty(): boolean {
    return !!(this.survey.voted && this.survey.voted.isDirty);
  }

  protected get finished(): boolean {
    return !(this.date && this.date.getTime() > new Date().getTime());
  }

  public render() {
    if (!this.date) {
      return;
    }

    return (
      <v-row>
        {!this.voteIsDirty && this.voted && (
          <v-col class='flex-grow-0'>
            <div class='caption text-no-wrap'>
              {this.$t('app.survey.votedAt')}
            </div>
            <span class='subtitle-2 text-no-wrap'>{this.voted}</span>
          </v-col>
        )}
        <v-col>
          <div class='caption text-no-wrap'>
            <v-icon small left>
              mdi-calendar
            </v-icon>
            {(() => {
              if (this.finished) {
                return this.$t('app.survey.ended');
              }

              return this.voted
                ? this.$t('app.survey.endsAt')
                : this.$t('app.survey.voteUntil');
            })()}
          </div>
          <span class='subtitle-2 text-no-wrap'>{this.formattedDate}</span>
        </v-col>
      </v-row>
    );
  }
}
