import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { getModule } from 'vuex-module-decorators';
import GroupDetailModule from '~/app/core/store/modules/GroupDetailModule';
import { AreasOfInterests } from '~/components/molecules';
import { Content } from '~/components/atoms';
import { MapWidget } from '~/components/organisms';

import style from './GroupBody.scss';
import { isProjectItem } from '~/utils/group';

const rootClass = 'ys-group-body';

@Component({ style })
export default class GroupBody extends VueComponent<{}> {
  protected get groupDetailModule() {
    return getModule(GroupDetailModule, this.$store);
  }

  protected get position() {
    if (
      this.groupDetailModule.location.lat &&
      this.groupDetailModule.location.lng
    ) {
      return {
        latitude: this.groupDetailModule.location.lat,
        longitude: this.groupDetailModule.location.lng,
      };
    }
    return null;
  }

  public render() {
    return (
      <v-container class={rootClass}>
        <v-row>
          <v-col cols='12' md='8'>
            <Content text={this.groupDetailModule.description} />
          </v-col>
          <v-col cols='12' md='4'>
            <v-banner class={`${rootClass}__meta`}>
              <h4>{this.groupDetailModule.location.name}</h4>
              <div>{this.groupDetailModule.location.address}</div>
              {this.position && (
                <MapWidget position={this.position} class='py-2'>
                  <span slot='title'>
                    {this.$t(
                      `app.${this.groupDetailModule.kind.toLowerCase()}.mapTitle`
                    )}
                  </span>
                </MapWidget>
              )}
            </v-banner>
            {this.renderProjectFields()}
          </v-col>
        </v-row>

        <v-divider />

        <AreasOfInterests
          class='mt-3'
          items={this.groupDetailModule.areasOfInterest}
        />
      </v-container>
    );
  }

  protected renderProjectFields() {
    if (!isProjectItem(this.groupDetailModule.group)) {
      return;
    }

    return (
      <table class={`${rootClass}__parameters mt-3`}>
        <tr>
          <th>{this.$t('app.project.state.label')}:</th>
          <td>
            {this.$t(
              `app.project.state.enumMap.${this.groupDetailModule.group.projectState}`
            )}
          </td>
        </tr>
        <tr>
          <th>{this.$t('app.project.priority.label')}:</th>
          <td>
            {this.$t(
              `app.project.priority.enumMap.${this.groupDetailModule.group.projectPriority}`
            )}
          </td>
        </tr>
      </table>
    );
  }
}
