import { CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject } from '~/app/core/apiClient/api';

/**
 * Maps provided area IDs to area objects in the second parameter
 *
 * @param ids
 * @param areas
 */
export function mapAreasOfInterest(
  ids: AreaOfInterestItem['id'][],
  areas: AreaOfInterestItem[]
): AreaOfInterestItem[] {
  const interests: AreaOfInterestItem[] = [];

  ids.forEach((id) => {
    for (const area of areas) {
      if (area.id === id) {
        interests.push({ ...area });
      }
    }
  });

  return interests;
}

export interface AreaOfInterestItem {
  category: string;
  id: string;
  title: string;
}

export function createAreaOfInterestFromApi(
  data: CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject
): AreaOfInterestItem {
  return {
    category: data.category,
    id: data.id,
    title: data.name,
  };
}

export interface AreaOfInterestCategorisedItem {
  category: string;
  interests: AreaOfInterestItem[];
}

export function createAreaOfInterestCategorisedItems(
  data: CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject[]
): AreaOfInterestCategorisedItem[] {
  const items: AreaOfInterestCategorisedItem[] = [];
  const categorizedItems: { [key: string]: AreaOfInterestItem[] } = {};

  data
    .sort((a, b) => {
      return a.category.localeCompare(b.category);
    })
    .forEach((areaOfInterest) => {
      if (!categorizedItems[areaOfInterest.category]) {
        categorizedItems[areaOfInterest.category] = [];
      }
      categorizedItems[areaOfInterest.category].push(
        createAreaOfInterestFromApi(areaOfInterest)
      );
    });

  for (const category in categorizedItems) {
    if (categorizedItems.hasOwnProperty(category)) {
      items.push({
        category,
        interests: categorizedItems[category],
      });
    }
  }

  return items;
}

interface VuetifySelectItem {
  text: string | number | object;
  value: string | number | object;
  disabled?: boolean;
}

interface VuetifySelectHeaderItem {
  header: string;
}

interface VuetifySelectDividerItem {
  divider: boolean;
}

export function createAreaOfInterestSelectItems(
  data: AreaOfInterestCategorisedItem[],
  titleIsValue?: boolean
) {
  const items: (
    | VuetifySelectItem
    | VuetifySelectHeaderItem
    | VuetifySelectDividerItem
  )[] = [];
  data.forEach((item) => {
    items.push({ header: item.category });
    item.interests.forEach((interest) => {
      items.push({
        text: interest.title,
        value: titleIsValue ? interest.title : interest.id,
      });
    });
  });

  return items;
}
