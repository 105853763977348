export default {
  app: {
    error: {
      event: {
        areasAreRequired: 'Vyberte alespoň jedno zaměření události.',
        chooseDateSelection: 'Musíte vybrat termín události.',
        chooseLocation: 'Musíte vybrat místo události.',
        contentIsRequired: 'Vyplňte prosím popis události',
        couldNotDetermineStart:
          'Ze zadaných dat se nepodařilo zjistit začátek události.',
        couldNotReload: 'Nepodařilo se načíst nová data události.',
        create: {
          generic: 'Nastala chyba při vytváření události.',
          forbidden: 'Nemáte oprávnění pro vytváření události',
        },
        deleteFailed: 'Nepodařilo se smazat událost.',
        endBeforeStart: 'Čas konce je před časem začátku.',
        fixLocation: 'Prosím opravte místo události',
        locationIsRequired: 'Vyplňtě prosím místo události.',
        locationHasNameOnly:
          'Pokud je zadaný název místa, musíte vybrat i adresu nebo bod v mapě.',
        mustStartInFuture: 'Událost musí začínat v budoucnosti.',
        notCreated: 'Nebylo možné přidat událost.',
        notFound: 'Událost nebyla nalezena',
        notUpdated: 'Nebylo možné upravit událost.',
        noGroupsToCreateEvent:
          'Nejste správcem žádné skupiny pro vytváření událostí.',
        reportFailed: 'Nepodařilo se nahlásit událost',
        titleIsRequired: 'Vyplňte prosím název události',
        update: {
          generic: 'Nastala chyba při aktualizaci události.',
          forbidden: 'Nemáte oprávnění pro úpravu události',
        },
      },
    },
  },
};
