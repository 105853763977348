import { Component, Emit } from 'vue-property-decorator';

import BaseButton from '~/components/atoms/baseButton/BaseButton';
import { icons } from '~/utils/group';
import { SpreadAttributes } from '~/utils/vue-component';

type CreateEvent = void;

export interface CreateGroupButtonInterface {
  onCreate?: (data: CreateEvent) => void;
}

@Component
export default class CreateGroupButton extends BaseButton<
  CreateGroupButtonInterface
> {
  protected get attributes(): SpreadAttributes {
    return {
      attrs: {
        dark: true,
        rounded: true,
        ...this.baseAttributes.attrs,
      },
    };
  }

  public render() {
    return (
      <v-btn color='secondary' onClick={this.emitCreate} {...this.attributes}>
        <v-icon left>{icons.create}</v-icon>
        {this.$slots.default || this.$t('app.communities.create')}
      </v-btn>
    );
  }

  @Emit('create')
  protected emitCreate(): CreateEvent {
    return;
  }
}
