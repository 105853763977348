import { Component, Emit } from 'vue-property-decorator';
import BaseButton from '~/components/atoms/baseButton/BaseButton';

type CloseEvent = undefined;

interface CloseButtonInterface {
  onClose?: (data: CloseEvent) => any;
}

@Component
export default class CloseButton extends BaseButton<CloseButtonInterface> {
  public render() {
    return (
      <v-btn icon onClick={this.emitClose} {...this.baseAttributes}>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    );
  }

  @Emit('close')
  protected emitClose(): CloseEvent {
    return;
  }
}
