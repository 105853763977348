import { Component, Prop } from 'vue-property-decorator';

import { groupIcon } from '~/utils/group';
import { VueComponent } from '~/utils/vue-component';

export interface SelectGroupItemInterface {
  name: string;
  onSelected?: () => void;
}

@Component
export default class SelectGroupItem
  extends VueComponent<SelectGroupItemInterface>
  implements SelectGroupItemInterface {
  @Prop({ type: String, required: true })
  public name!: string;

  public render() {
    return (
      <v-banner two-line>
        <v-avatar slot='icon' size='40'>
          <v-icon>{groupIcon}</v-icon>
        </v-avatar>
        <v-row>
          <v-col>
            <h3>{this.name}</h3>
          </v-col>
          <v-col cols='auto'>
            <v-btn
              color='secondary'
              rounded
              onClick={() => {
                this.$emit('selected');
              }}
            >
              {this.$t('app.common.choose')}
            </v-btn>
          </v-col>
        </v-row>
      </v-banner>
    );
  }
}
