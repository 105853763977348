import { RouteConfig } from 'vue-router';

import PostDetail from '~/views/PostDetail';
import parameters from './parameters';

const config: RouteConfig = {
  component: PostDetail,
  path: `post/${parameters.guidPath}`,
};

export default config;
