import { CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity } from '~/app/core/apiClient/api';

export default function isEventInstanceGroupItem(
  data: any
): data is CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.id === 'string' &&
    typeof data.name === 'string' &&
    typeof data.badge === 'boolean' &&
    typeof data.color === 'string'
  );
}
