import Vue from 'vue';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import {
  CzYsP14KcEventApiDtoEventCreationObject,
  CzYsP14KcEventApiDtoEventUpdateObject,
  isApiFetchResponse,
} from '../../apiClient/api';
import { format } from '~/utils/date-fns';
import {
  createEventInstanceItem,
  createEventItem,
  EventInstanceItem,
  EventItem,
} from '~/utils/event';
import { GroupItem } from '~/utils/group';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import { itemIsNotNull } from '~/utils/typeguards';
import GroupModule from '~/app/core/store/modules/GroupModule';

export interface EventAuthor {
  id: number;
  name: string;
}

interface GetInput {
  id: EventItem['id'];
}

interface GetInstanceInput {
  id: EventInstanceItem['id'];
  date: Date;
}

interface CreateInput {
  data: CzYsP14KcEventApiDtoEventCreationObject;
  groupId: GroupItem['id'];
}

interface UpdateInput {
  data: CzYsP14KcEventApiDtoEventUpdateObject;
  eventId: EventItem['id'];
}

interface LikeInput {
  id: EventInstanceItem['id'];
}

interface UnlikeInput {
  id: EventInstanceItem['id'];
}

interface ReportEventInput {
  id: EventInstanceItem['id'];
}

type GetEventCommit = EventItem;

type GetEventInstanceCommit = EventInstanceItem;

type DeleteEventCommit = EventItem['id'];

type DeleteEventInstanceCommit = EventInstanceItem['id'];

type CacheEventsCommit = EventItem[];

type CacheEventInstancesCommit = EventInstanceItem[];

interface SetEventAuthorsCommit {
  authors: EventAuthor[];
}

interface LikeInput {
  id: EventInstanceItem['id'];
}

interface UnlikeInput {
  id: EventInstanceItem['id'];
}

interface LikingCommit {
  id: EventInstanceItem['id'];
  liking: boolean;
}

interface ReportingCommit {
  id: EventInstanceItem['id'];
  reporting: boolean;
}

interface LikeStatusCommit {
  id: EventInstanceItem['id'];
  state: boolean;
  changeCount: boolean;
}

interface ReportStatusCommit {
  id: EventInstanceItem['id'];
  state: boolean;
}

export interface EventRequestObject {
  dateFrom?: string;
  dateTo?: string;
  wpAuthor?: number;
  text?: string;
  flags?: string[];
  interest?: string[];
  page?: number;
}

export enum EventFlags {
  BARRIER_FREE = 'BARRIER_FREE',
  DOGS_ALLOWED = 'DOGS_ALLOWED',
}

enum EventLikeApiResponse {
  LIKED = 'Liked already',
  UNLIKED = 'Not liked',
}

/**
 * Retrieve index key for an array of events
 * @param id
 * @param items
 */
function getItemCacheIndex(id: string, items: EventItem[]): number | null {
  for (let key = 0; key < items.length; key++) {
    if (items[key].id === id) {
      return key;
    }
  }

  return null;
}

function createCacheKey(id: string, date: string | null) {
  return `${id}-${date || 'all'}`;
}

/**
 * Retrieve index key for an array of events
 * @param id
 * @param date
 * @param items
 */
function getItemInstanceCacheIndexes(
  id: string,
  date: string | null,
  items: EventInstanceItem[]
): number[] {
  const cacheKey = createCacheKey(id, date);
  const keys = [];

  for (let key = 0; key < items.length; key++) {
    const eventCacheKey = createCacheKey(
      items[key].id,
      date ? items[key].date : null
    );
    if (eventCacheKey === cacheKey) {
      if (date) {
        return [key];
      }
      keys.push(key);
    }
  }

  return keys;
}

/**
 * Parse error message from API and return true if the error matches the expected message
 *
 * @param err
 * @param successMessage
 */
function parseErrorResponse(err: any, successMessage: string): Promise<true> {
  if (isApiFetchResponse(err)) {
    return err.json().then((response) => {
      if (response.message && response.message === successMessage) {
        return true;
      }
      throw err;
    });
  }
  throw err;
}

@Module({
  name: 'EventsModule',
  stateFactory: true,
  namespaced: true,
})
export default class EventsModule extends AbstractModule {
  public cache: EventItem[] = [];

  public instanceCache: EventInstanceItem[] = [];

  protected eventAuthors: EventAuthor[] = [];

  protected eventRequestData: EventRequestObject = {};

  protected lastPage: boolean = true;

  protected pageNumber: number = 0;

  public loading: boolean = false;

  public get authors() {
    return this.eventAuthors;
  }

  public get requestData() {
    return this.eventRequestData;
  }

  public get isLastPage() {
    return this.lastPage;
  }

  @Action({ commit: 'setEventAuthors', rawError: true })
  public loadEventAuthors(): Promise<SetEventAuthorsCommit> {
    return this.$api.wpAuthors.findWordpressAuthors().then((response) => {
      return {
        authors: response,
      };
    });
  }

  @Action({ commit: 'cacheEventInstances', rawError: true })
  public loadNextEvents(): Promise<CacheEventInstancesCommit> {
    this.setLoading(true);
    return getModule(AreasOfInterestModule, this.store)
      .loadData()
      .then((areas) => {
        return this.$api.events
          .listAllEvents(
            this.eventRequestData.dateFrom,
            this.eventRequestData.dateTo,
            this.eventRequestData.wpAuthor,
            this.eventRequestData.text,
            this.eventRequestData.flags,
            this.eventRequestData.interest,
            this.pageNumber + 1
          )
          .then((result) => {
            if (!result.content) {
              return [];
            }

            const items = result.content
              .map((event) => createEventInstanceItem(event, areas.allItems))
              .filter(itemIsNotNull);
            const groupIds = items
              .map((item) => item.group)
              .filter(itemIsNotNull)
              .map((group) => group.id);

            return getModule(GroupModule, this.store)
              .loadUncachedIds({ requestedIds: groupIds })
              .then(() => {
                this.setLastPage(
                  typeof result.last !== 'undefined' ? result.last : true
                );
                this.setPageNumber(this.pageNumber + 1);
                return items;
              });
          });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action({ commit: 'cacheEventInstances', rawError: true })
  public getEvents(
    requestData: EventRequestObject
  ): Promise<CacheEventInstancesCommit> {
    this.setLoading(true);
    return this.$api.events
      .listAllEvents(
        requestData.dateFrom,
        requestData.dateTo,
        requestData.wpAuthor,
        requestData.text,
        requestData.flags,
        requestData.interest
      )
      .then((result) => {
        return getModule(AreasOfInterestModule, this.store)
          .loadData()
          .then((areas) => {
            this.setLastPage(
              typeof result.last !== 'undefined' ? result.last : true
            );
            this.setPageNumber(0);
            this.setEventRequestData(requestData);

            return (
              result.content
                ?.map((event) => createEventInstanceItem(event, areas.allItems))
                .filter(itemIsNotNull) || []
            );
          });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action({ commit: 'setEvent', rawError: true })
  public get(data: GetInput): Promise<GetEventCommit> {
    return getModule(AreasOfInterestModule, this.store)
      .loadData()
      .then((areas) => {
        return this.$api.events.getEventDetail(data.id).then((result) => {
          const event = createEventItem(result, areas.allItems);

          if (event === null) {
            throw new Error('Event could not be created');
          }

          return event;
        });
      });
  }

  @Action({ commit: 'setEventInstance', rawError: true })
  public getInstance(data: GetInstanceInput): Promise<GetEventInstanceCommit> {
    return getModule(AreasOfInterestModule, this.store)
      .loadData()
      .then((areas) => {
        return this.$api.events
          .getEventInstanceDetail(data.id, format(data.date, 'YYYY-MM-DD'))
          .then((result) => {
            const event = createEventInstanceItem(result, areas.allItems);

            if (event === null) {
              throw new Error('Event instance could not be created');
            }

            return event;
          });
      });
  }

  @Action({ commit: 'setEvent', rawError: true })
  public create(input: CreateInput): Promise<GetEventCommit> {
    return this.$api.communities
      .createEvent(input.data, input.groupId)
      .then((result) => {
        return this.get({
          id: result,
        });
      });
  }

  @Action({ commit: 'setEvent', rawError: true })
  public update(input: UpdateInput): Promise<GetEventCommit> {
    return this.$api.events.updateEvent(input.data, input.eventId).then(() => {
      return this.get({ id: input.eventId });
    });
  }

  @Action({ commit: 'setLiked', rawError: true })
  public like(data: LikeInput): Promise<LikeStatusCommit> {
    this.setLiking({ id: data.id, liking: true });
    const successfulResult: LikeStatusCommit = {
      id: data.id,
      state: true,
      changeCount: false,
    };

    return (
      this.$api.events
        .likeEvent(data.id)
        .then((_) => {
          successfulResult.changeCount = true;
        })
        // Check if the event was already liked
        .catch((err) => parseErrorResponse(err, EventLikeApiResponse.LIKED))
        .then(() => successfulResult)
        .finally(() => {
          this.setLiking({ id: data.id, liking: false });
        })
    );
  }

  @Action({ commit: 'setLiked', rawError: true })
  public unlike(data: UnlikeInput): Promise<LikeStatusCommit> {
    this.setLiking({ id: data.id, liking: true });

    const successfulResult: LikeStatusCommit = {
      id: data.id,
      state: false,
      changeCount: false,
    };
    return (
      this.$api.events
        .unlikeEvent(data.id)
        .then((_) => {
          successfulResult.changeCount = true;
        })
        // Check if the event was already unliked
        .catch((err) => parseErrorResponse(err, EventLikeApiResponse.UNLIKED))
        .then(() => successfulResult)
        .finally(() => {
          this.setLiking({ id: data.id, liking: false });
        })
    );
  }

  @Action({ commit: 'setReported', rawError: true })
  public report(data: ReportEventInput): Promise<ReportStatusCommit> {
    this.setReporting({ id: data.id, reporting: true });
    return this.$api.events
      .toxicEvent(data.id)
      .then((_) => {
        return {
          id: data.id,
          state: true,
        };
      })
      .finally(() => {
        this.setReporting({ id: data.id, reporting: false });
      });
  }

  @Mutation
  public setEvent(data: GetEventCommit) {
    const key = getItemCacheIndex(data.id, this.cache);
    if (key !== null) {
      Vue.set(this.cache, key, data);
    } else {
      this.cache.push(data);
    }
  }

  @Mutation
  public unsetEvent(data: DeleteEventCommit) {
    const key = getItemCacheIndex(data, this.cache);
    if (key !== null) {
      Vue.delete(this.cache, key);
    }
  }

  @Mutation
  public setEventInstance(data: GetEventInstanceCommit) {
    const keys = getItemInstanceCacheIndexes(
      data.id,
      data.date,
      this.instanceCache
    );
    if (keys.length > 0) {
      keys.forEach((key) => {
        Vue.set(this.instanceCache, key, data);
      });
    } else {
      this.instanceCache.push(data);
    }
  }

  @Mutation
  public unsetEventInstance(data: DeleteEventInstanceCommit) {
    const keys = getItemInstanceCacheIndexes(data, null, this.instanceCache);
    if (keys.length > 0) {
      keys.forEach((key) => {
        Vue.delete(this.instanceCache, key);
      });
    }
  }

  @Mutation
  protected setEventAuthors(data: SetEventAuthorsCommit) {
    this.eventAuthors = data.authors;
  }

  @Mutation
  protected setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  protected setLastPage(data: boolean) {
    this.lastPage = data;
  }

  @Mutation
  protected setPageNumber(data: number) {
    this.pageNumber = data;
  }

  @Mutation
  protected setEventRequestData(data: EventRequestObject) {
    this.eventRequestData = data;
  }

  @Mutation
  public cacheEvents(items: CacheEventsCommit) {
    items.forEach((item) => {
      const key = getItemCacheIndex(item.id, this.cache);
      if (key !== null) {
        Vue.set(this.cache, key, item);
      } else {
        this.cache.push(item);
      }
    });
  }

  @Mutation
  public cacheEventInstances(items: CacheEventInstancesCommit) {
    items.forEach((item) => {
      const keys = getItemInstanceCacheIndexes(
        item.id,
        item.date,
        this.instanceCache
      );
      if (keys.length > 0) {
        keys.forEach((key) => {
          Vue.set(this.instanceCache, key, item);
        });
      } else {
        this.instanceCache.push(item);
      }
    });
  }

  @Mutation
  protected setLiking(data: LikingCommit) {
    const keys = getItemInstanceCacheIndexes(data.id, null, this.instanceCache);
    if (keys.length > 0) {
      keys.forEach((key) => {
        this.instanceCache[key].state.liking = data.liking;
        Vue.set(this.instanceCache, key, this.instanceCache[key]);
      });
    }
  }

  @Mutation
  protected setLiked(data: LikeStatusCommit) {
    const keys = getItemInstanceCacheIndexes(data.id, null, this.instanceCache);
    if (keys.length > 0) {
      keys.forEach((key) => {
        this.instanceCache[key].liked = data.state;
        if (data.changeCount) {
          let likeCount = this.instanceCache[key].likes;
          if (likeCount !== null) {
            likeCount += data.state ? 1 : -1;
            this.instanceCache[key].likes = likeCount;
          }
        }
        Vue.set(this.instanceCache, key, this.instanceCache[key]);
      });
    }
  }

  @Mutation
  protected setReporting(data: ReportingCommit) {
    const keys = getItemInstanceCacheIndexes(data.id, null, this.instanceCache);
    if (keys.length > 0) {
      keys.forEach((key) => {
        this.instanceCache[key].state.reporting = data.reporting;
        Vue.set(this.instanceCache, key, this.instanceCache[key]);
      });
    }
  }

  @Mutation
  protected setReported(data: ReportStatusCommit) {
    const keys = getItemInstanceCacheIndexes(data.id, null, this.instanceCache);
    if (keys.length > 0) {
      keys.forEach((key) => {
        this.instanceCache[key].reported = data.state;
        Vue.set(this.instanceCache, key, this.instanceCache[key]);
      });
    }
  }
}
