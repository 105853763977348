export default {
  app: {
    error: {
      survey: {
        areasAreRequired: 'Vyberte alespoň jedno zaměření ankety.',
        contentIsRequired: 'Napiště prosím obsah ankety.',
        create: {
          generic: 'Nastala chyba při vytváření ankety.',
          forbidden: 'Nemáte oprávnění pro vytváření ankety',
        },
        expired: 'Již není možné hlasovat. Anketa byla ukončena.',
        expiryMustBeInFuture: 'Datum vypršení ankety musí být v budoucnosti.',
        expiryRequired: 'Prosím vyplňte datum platnosti ankety.',
        noGroupsToCreateSurvey:
          'Nejste správcem žádné skupiny pro vytváření anket.',
        notCreated: 'Nebylo možné přidat anketu.',
        notFound: 'Anketa nebyla nalezena',
        optionCannotBeEmpty: 'Prosím vyplňte volbu',
        reportFailed: 'Nepodařilo se nahlásit anketu',
        titleIsRequired: 'Vyplňtě prosím název ankety.',
        voteFailed: 'Nepodařilo se hlasovat pro anketu',
        update: {
          generic: 'Nastala chyba při aktualizaci ankety.',
          forbidden: 'Nemáte oprávnění pro úpravu ankety',
        },
      },
    },
  },
};
