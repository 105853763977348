import { ImageItem } from './';
import { CzYsP14KcPostApiDtoPostObject } from '~/app/core/apiClient/api';
import { getAttachedImageUrl } from '~/utils/post/getPostImageUrl';

export function createPostAttachedImages(
  postItemData: CzYsP14KcPostApiDtoPostObject,
  mediaVersion: string
): ImageItem[] {
  const attachedImages: ImageItem[] = [];

  for (const imageId of postItemData.imageIds) {
    // Don't include main image
    if (imageId !== postItemData.id) {
      attachedImages.push({
        id: imageId,
        src: getAttachedImageUrl(
          `post/${postItemData.id}/${imageId}`,
          mediaVersion
        ),
      });
    }
  }

  return attachedImages;
}
