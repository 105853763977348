import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { format, parse } from '~/utils/date-fns';
import { dateTimeFormatWithDays } from '~/utils/dateTime';
import { ButtonSize, ReportButton } from '~/components/atoms';
import { CommentItem } from '~/utils/comments';
import Submenu from '~/components/molecules/submenu/Submenu';
import { getUserImageUrl } from '~/utils/user';
import { componentOffset } from '~/utils/spacing';
import { UserImage } from '~/components/molecules';
import { UserImageContext } from '~/components/molecules/userImage/UserImage';
import { TranslateResult } from 'vue-i18n';
import { greyForegroundColor } from '~/utils/theme/colors';

const rootClass = 'ys-comment';

export interface ReportComment {
  id: CommentItem['id'];
}

interface CommentInterface {
  comment: CommentItem;
  onReport?: (data: ReportComment) => any;
}

@Component
export default class Comment extends VueComponent<CommentInterface>
  implements CommentInterface {
  @Prop({ required: true })
  public comment!: CommentItem;

  protected get content(): string | TranslateResult {
    if (this.comment.toxic) {
      return this.$t('app.comment.removed');
    }

    if (this.comment.deleted) {
      return this.$t('app.comment.deleted');
    }

    return this.comment.content;
  }

  protected get isRemoved(): boolean {
    return this.comment.deleted || this.comment.toxic;
  }

  protected get parsedDate(): Date | undefined {
    if (!this.comment.date) {
      return;
    }

    const parsedDate = parse(this.comment.date);

    if (isNaN(parsedDate.getTime())) {
      return;
    }

    return parsedDate;
  }

  public render() {
    return (
      <v-card class={rootClass} disabled={this.isRemoved}>
        {!this.isRemoved && (
          <v-card-title>
            {this.renderUser()}
            <v-spacer />
            <Submenu>
              <v-list-item>
                <v-list-item-title>
                  <ReportButton
                    reported={this.comment.state.reported}
                    loading={this.comment.state.reporting}
                    onReport={this.emitReport}
                    size={ButtonSize.SMALL}
                  />
                </v-list-item-title>
              </v-list-item>
            </Submenu>
          </v-card-title>
        )}
        <v-card-text>
          <div class={`text--primary${this.isRemoved ? ' font-italic' : ''}`}>
            {this.content}
          </div>
          {this.parsedDate && (
            <time>{format(this.parsedDate, dateTimeFormatWithDays)}</time>
          )}
        </v-card-text>
      </v-card>
    );
  }

  protected renderUser() {
    if (!this.comment.user) {
      return null;
    }

    return [
      <v-avatar class='mr-3'>
        <UserImage context={UserImageContext.AVATAR} user={this.comment.user} />
      </v-avatar>,
      <span>{this.comment.user.name}</span>,
    ];
  }

  @Emit('report')
  protected emitReport(): ReportComment {
    return {
      id: this.comment.id,
    };
  }
}
