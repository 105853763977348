import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import StatusCode from '~/utils/http/StatusCode';
import { HomepageButton } from '~/components/atoms';
import { TranslateResult } from 'vue-i18n';

interface ErrorPageInterface {
  httpCode: number;
  picture?: string;
  title: string | TranslateResult;
}

@Component
export default class ErrorPage extends VueComponent<ErrorPageInterface>
  implements ErrorPageInterface {
  @Prop({ type: Number, required: true })
  public httpCode!: number;

  @Prop({ type: String })
  public picture?: string;

  @Prop({ required: true })
  public title!: string | TranslateResult;

  protected get fullUrl() {
    if (!window) {
      return;
    }
    return window.location.href;
  }

  public render() {
    return (
      <v-container class='text-center pt-8'>
        <v-container>
          <h1 class='mb-2'>
            <span class='text-uppercase'>{this.title}</span>{' '}
            <span class='title font-weight-regular'>
              ({this.$t('app.error.view.error')} {this.httpCode})
            </span>
          </h1>

          <div class='mb-4 font-italic' style='font-size:1.2rem;'>
            {this.fullUrl}
          </div>

          <div class='mb-6'>{this.$slots.default}</div>

          <HomepageButton class='mb-8' />
        </v-container>

        {this.picture && (
          <v-container>
            <v-img
              src={this.picture}
              class='mx-auto'
              max-width='400px'
              max-height='320px'
              contain
            />
          </v-container>
        )}

        <StatusCode code={this.httpCode} />
      </v-container>
    );
  }
}
