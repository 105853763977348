import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';

import Event from './event';
import Form from './form';
import Generic from './generic';
import Group from './group';
import Post from './post';
import Survey from './survey';
import View from './view';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  Event,
  Form,
  Generic,
  Group,
  Post,
  Survey,
  View,
]) as LocaleMessages;

export default mergedLocalizations;
