import { VueComponent } from '~/utils/vue-component';

import common from './common';
import flags from './flags';
import Icon from './Icon';
import { BaseIcon } from './BaseIcon';

const icons = {
  common,
  flags,
};

const mappedIcons: {
  common: { [key in keyof typeof icons.common]: typeof VueComponent };
  flags: { [key in keyof typeof icons.flags]: typeof VueComponent };
} = {
  common: { ...common },
  flags: { ...flags },
};

for (const category in mappedIcons) {
  if (!mappedIcons.hasOwnProperty(category)) {
    continue;
  }

  const categoryIcons = (mappedIcons as { [key: string]: any })[category];

  for (const iconKey in categoryIcons) {
    if (!categoryIcons.hasOwnProperty(iconKey)) {
      continue;
    }

    (mappedIcons as { [key: string]: any })[category][iconKey] = Icon.extend({
      props: {
        name: {
          default: () => {
            return [category, iconKey];
          },
        },
      },
    });
  }
}

export { icons };

export default (mappedIcons as unknown) as {
  common: { [key in keyof typeof icons.common]: typeof BaseIcon };
  flags: { [key in keyof typeof icons.flags]: typeof BaseIcon };
};
