import { Component, Watch } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import { getModule } from 'vuex-module-decorators';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import EventsModule, {
  EventRequestObject,
  EventFlags,
} from '~/app/core/store/modules/EventsModule';
import { PrefetchComponent, Prefetch } from '~/mixins/prefetch';
import { greyBackgroundColor } from '~/utils/theme/colors';
import { dateFormatWithDays } from '~/utils/dateTime';
import { format, parse } from '~/utils/date-fns';
import { EventSearchFormChips } from '~/components/organisms';
import { createAreaOfInterestSelectItems } from '~/utils/areasOfInterest';

interface EventSearchFormInterface {
  onSubmit?: (data: EventRequestObject) => void;
}

export interface EventSearchFormData {
  author?: number;
  barrierFree: boolean;
  dateFrom: string;
  dateTo: string;
  dogsAllowed: boolean;
  interests: string[];
  searchText: string;
}

@Component
export default class EventsSearchForm extends VueComponentMixin<
  EventSearchFormInterface,
  PrefetchComponent
>(Prefetch) {
  protected formData: EventSearchFormData = {
    barrierFree: false,
    dateFrom: '',
    dateTo: '',
    dogsAllowed: false,
    interests: [],
    searchText: '',
  };

  protected searchInput: string = '';

  protected showDateFromPicker: boolean = false;

  protected showDateToPicker: boolean = false;

  protected searchFieldThrottle?: NodeJS.Timeout;

  public get eventStore() {
    return getModule(EventsModule, this.$store);
  }

  public get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  public get authors() {
    return this.eventStore.authors;
  }

  public get interests() {
    return this.areasOfInterestStore.interests;
  }

  protected get areasOfInterestSelectItems() {
    return createAreaOfInterestSelectItems(
      this.areasOfInterestStore.categorisedInterests
    );
  }

  protected get formattedFrom(): string {
    if (!this.formData.dateFrom) {
      return '';
    }

    const date = parse(this.formData.dateFrom);
    if (isNaN(date.getTime())) {
      return '';
    }

    return format(date, dateFormatWithDays);
  }

  protected get formattedTo(): string {
    if (!this.formData.dateTo) {
      return '';
    }

    const date = parse(this.formData.dateTo);
    if (isNaN(date.getTime())) {
      return '';
    }

    return format(date, dateFormatWithDays);
  }

  @Watch('formData', { deep: true })
  public submitForm() {
    this.$emit('submit', this.getRequestObject());
  }

  @Watch('searchInput')
  public setSearchTextValue() {
    if (this.searchFieldThrottle) {
      clearTimeout(this.searchFieldThrottle);
    }

    this.searchFieldThrottle = setTimeout(() => {
      this.formData.searchText = this.searchInput;
    }, 500);
  }

  public prefetch() {
    const promises: Promise<any>[] = [];
    if (this.authors.length < 1) {
      promises.push(this.eventStore.loadEventAuthors());
    }
    if (this.interests.length < 1) {
      promises.push(this.areasOfInterestStore.loadData());
    }

    return Promise.all(promises);
  }

  public render() {
    return (
      <div>
        <v-card
          class={`px-4 pb-4 pt-1 ${greyBackgroundColor}`}
          tile
          loading={this.eventStore.loading}
        >
          <v-form>
            <v-row>
              <v-col cols='12' md='6' class='pb-0'>
                <v-text-field
                  class='v-input--no-details'
                  v-model={this.searchInput}
                  label={this.$t('app.eventsSearchForm.searchLabel')}
                  prepend-inner-icon='mdi-magnify'
                  clearable
                  background-color='white'
                  rounded
                  outlined
                />
              </v-col>
              <v-col cols='12' md='3' class='pb-0'>
                <v-menu
                  offset-y
                  v-model={this.showDateFromPicker}
                  min-width='290px'
                  close-on-content-click={false}
                  scopedSlots={{
                    activator: ({ on }: { on: any }) => {
                      return (
                        <div
                          {...{
                            on,
                          }}
                        >
                          <v-text-field
                            class='v-input--no-details'
                            append-icon='mdi-calendar'
                            value={this.formattedFrom}
                            label={this.$t(
                              'app.eventsSearchForm.dateFromLabel'
                            )}
                            readonly
                            clearable
                            outlined
                            rounded
                            background-color='white'
                            {...{
                              on: {
                                'click:clear': () => {
                                  this.formData.dateFrom = '';
                                },
                              },
                            }}
                          />
                        </div>
                      );
                    },
                  }}
                >
                  <v-date-picker
                    class='v-input--no-details'
                    v-model={this.formData.dateFrom}
                    min={new Date().toISOString()}
                    max={this.formData.dateTo}
                    no-title
                    scrollable
                  />
                </v-menu>
              </v-col>
              <v-col cols='12' md='3' class='pb-0'>
                <v-menu
                  offset-y
                  v-model={this.showDateToPicker}
                  min-width='290px'
                  close-on-content-click={false}
                  scopedSlots={{
                    activator: ({ on }: { on: any; attrs: any }) => {
                      return (
                        <div
                          {...{
                            on,
                          }}
                        >
                          <v-text-field
                            class='v-input--no-details'
                            append-icon='mdi-calendar'
                            value={this.formattedTo}
                            label={this.$t('app.eventsSearchForm.dateToLabel')}
                            readonly
                            clearable
                            outlined
                            rounded
                            background-color='white'
                            {...{
                              on: {
                                'click:clear': () => {
                                  this.formData.dateTo = '';
                                },
                              },
                            }}
                          />
                        </div>
                      );
                    },
                  }}
                >
                  <v-date-picker
                    v-model={this.formData.dateTo}
                    min={this.formData.dateFrom || new Date().toISOString()}
                    no-title
                    scrollable
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols='12' class='pb-0'>
                <v-select
                  class='v-input--no-details'
                  v-model={this.formData.interests}
                  label={this.$t('app.eventsSearchForm.interestsLabel')}
                  items={this.areasOfInterestSelectItems}
                  multiple
                  clearable
                  outlined
                  rounded
                  background-color='white'
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols='12' md='6' class='pb-0'>
                <v-select
                  class='v-input--no-details'
                  v-model={this.formData.author}
                  label={this.$t('app.eventsSearchForm.authorLabel')}
                  items={this.authors}
                  item-text='name'
                  item-value='id'
                  clearable
                  outlined
                  rounded
                  background-color='white'
                />
              </v-col>
              <v-col cols='12' md='3' class='pb-0'>
                <v-checkbox
                  class='v-input--no-details'
                  prepend-icon='mdi-dog-side'
                  label={this.$t('app.eventsSearchForm.dogsAllowedLabel')}
                  v-model={this.formData.dogsAllowed}
                />
              </v-col>
              <v-col cols='12' md='3' class='pb-0'>
                <v-checkbox
                  class='v-input--no-details'
                  prepend-icon='mdi-wheelchair-accessibility'
                  label={this.$t('app.eventsSearchForm.barrierFreeLabel')}
                  v-model={this.formData.barrierFree}
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card>

        <EventSearchFormChips
          authors={this.authors}
          formData={this.formData}
          interests={this.interests}
          onRemoveSearchText={() => {
            this.searchInput = '';
            this.formData.searchText = '';
          }}
          onRemoveAuthor={() => {
            this.formData.author = undefined;
          }}
          onRemoveBarrierFree={() => {
            this.formData.barrierFree = false;
          }}
          onRemoveDogsAllowed={() => {
            this.formData.dogsAllowed = false;
          }}
          onRemoveDates={() => {
            this.formData.dateFrom = '';
            this.formData.dateTo = '';
          }}
          onRemoveInterest={({ value }) => {
            const index = this.formData.interests.indexOf(value);
            if (index > -1) {
              this.formData.interests.splice(index, 1);
            }
          }}
        />
      </div>
    );
  }

  protected getRequestObject(): EventRequestObject {
    const flags: string[] = [];
    if (this.formData.barrierFree) {
      flags.push(EventFlags.BARRIER_FREE);
    }
    if (this.formData.dogsAllowed) {
      flags.push(EventFlags.DOGS_ALLOWED);
    }
    return {
      dateFrom: this.formData.dateFrom || undefined,
      dateTo: this.formData.dateTo || undefined,
      flags: flags.length > 0 ? flags : undefined,
      text: this.formData.searchText || undefined,
      wpAuthor: this.formData.author || undefined,
      interest:
        this.formData.interests.length > 0
          ? this.formData.interests
          : undefined,
    };
  }
}
