import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './SurveyHead.scss';
import { DateSticker } from '~/components/atoms';
import { SurveyImage } from '~/components/molecules';
import { parse } from '~/utils/date-fns';
import { SurveyItem } from '~/utils/survey';
import { CzYsP14KcSurveyApiDtoSurveyObject } from '~/app/core/apiClient/api';
import VisibilityEnum = CzYsP14KcSurveyApiDtoSurveyObject.VisibilityEnum;
import { SurveyImageContext } from '~/components/molecules/surveyImage/SurveyImage';

const rootClass = 'ys-survey-head';

interface SurveyHeadInterface {
  survey: SurveyItem;
}

@Component({
  style,
})
export default class SurveyHead extends VueComponent<SurveyHeadInterface>
  implements SurveyHeadInterface {
  @Prop({ required: true })
  public survey!: SurveyItem;

  protected get date(): Date {
    return parse(this.survey.created);
  }

  protected get visibility() {
    if (this.survey.visibility === VisibilityEnum.PUBLIC) {
      return this.$t('app.survey.public');
    }

    return this.$t('app.survey.private');
  }

  public render() {
    return (
      <SurveyImage
        survey={this.survey}
        class={`${rootClass} white--text`}
        context={SurveyImageContext.HEAD}
      >
        <v-row class={`${rootClass}__overlay fill-height`}>
          <v-col class='fill-height d-flex flex-column' cols='12'>
            <div class='d-flex px-3'>
              <DateSticker
                date={this.date}
                class={`${rootClass}__date-sticker`}
                showTime={true}
              />
            </div>
            <v-spacer />
            <div class='text-center'>
              <div class={`${rootClass}__visibility`}>{this.visibility}</div>
              <h1 class={`${rootClass}__title`}>{this.survey.title}</h1>
            </div>
          </v-col>
        </v-row>
      </SurveyImage>
    );
  }
}
