export default {
  app: {
    profile: {
      areasOfInterest: 'Oblasti zájmu',
      changePhoto: 'Změnit fotografii',
      displayedName: 'Zobrazované jméno',
      emailCaption: 'Uživatelská e-mailová adresa nelze měnit',
      phone: 'Telefon',
      photoAlt: 'Profilová fotografie',
    },
  },
};
