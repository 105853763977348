import { Action, getModule, Module } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import {
  CzYsP14KcEventApiDtoEventCreationObject,
  CzYsP14KcEventApiDtoEventUpdateObject,
} from '~/app/core/apiClient/api';
import EventsModule from '~/app/core/store/modules/EventsModule';
import {
  createDefaultErrorFields as createDefaultDateSelectorErrors,
  ErrorFields,
  ErrorFields as DateErrorFields,
  InputMode,
} from '~/components/organisms/eventDateSelector/EventDateSelector';
import { FormValues } from '~/components/templates/editEventForm/EditEventForm';
import { format, parse } from '~/utils/date-fns';
import { EventItem } from '~/utils/event';
import mapDaysOfWeekToArray from '~/utils/event/mapDaysOfWeekToArray';
import { GroupItem, Kind } from '~/utils/group';
import { isLocationValid } from '~/utils/location';
import GroupModule from '~/app/core/store/modules/GroupModule';
import FlagsEnum = CzYsP14KcEventApiDtoEventCreationObject.FlagsEnum;

type TranslationKey = string;

export interface ValidationCommit {
  api: TranslationKey[];
  content: TranslationKey[];
  dateSelection: {
    errors: DateErrorFields;
    messages: TranslationKey[];
  };
  location: TranslationKey[];
  title: TranslationKey[];
}

interface CreateInput {
  data: FormValues;
  groupId: GroupItem['id'];
}

interface UpdateInput {
  data: FormValues;
  eventId: EventItem['id'];
  groupId: GroupItem['id'];
}

interface ValidationInput {
  values: FormValues;
  group: GroupItem | null;
}

export function isDateSelectionValid(
  dateInputErrors: DateErrorFields
): boolean {
  for (const key in dateInputErrors) {
    if (dateInputErrors.hasOwnProperty(key) && dateInputErrors[key]) {
      return false;
    }
  }

  return true;
}

export function validationHasErrors(validation: ValidationCommit): boolean {
  return (
    validation.api.length > 0 ||
    validation.content.length > 0 ||
    validation.location.length > 0 ||
    validation.title.length > 0 ||
    validation.dateSelection.messages.length > 0 ||
    !isDateSelectionValid(validation.dateSelection.errors)
  );
}

function getFlagsArray(values: FormValues): FlagsEnum[] {
  const flags: FlagsEnum[] = [];

  if (values.flags.barrierFree) {
    flags.push(FlagsEnum.BARRIERFREE);
  }
  if (values.flags.dogsAllowed) {
    flags.push(FlagsEnum.DOGSALLOWED);
  }

  return flags;
}

function sanitizeLink(link: string): string | null {
  if (link.trim() === '') {
    return null;
  }

  return link && link.indexOf('http') !== 0 ? `http://${link}` : link;
}

@Module({
  name: 'EventMutationModule',
  stateFactory: true,
  namespaced: true,
})
export default class EventMutationModule extends AbstractModule {
  /**
   * @throws ValidationCommit
   * @param input
   */
  @Action({ rawError: true })
  public create(input: CreateInput): Promise<EventItem> {
    let group: GroupItem | null = null;
    for (const groupItem of getModule(GroupModule, this.store).groupCache) {
      if (groupItem.id === input.groupId) {
        group = groupItem;
        break;
      }
    }

    return this.validate({ values: input.data, group }).then((validation) => {
      if (validationHasErrors(validation)) {
        throw validation;
      }

      const daysOfWeek = mapDaysOfWeekToArray(input.data.date.daysOfWeek);
      const start = parse(input.data.date.start);
      if (isNaN(start.getTime())) {
        validation.dateSelection.errors.start = true;
        throw validation;
      }

      const end = input.data.date.end ? parse(input.data.date.end) : start;
      if (isNaN(end.getTime())) {
        validation.dateSelection.errors.end = true;
        throw validation;
      }

      const body: CzYsP14KcEventApiDtoEventCreationObject = {
        areasOfInterest: input.data.areasOfInterest,
        content: input.data.content,
        dateEnd: format(end, 'YYYY-MM-DD'),
        dateStart: format(start, 'YYYY-MM-DD'),
        flags: getFlagsArray(input.data),
        place: {
          address: input.data.location.address,
          latitude: input.data.location.lat || undefined,
          longitude: input.data.location.lng || undefined,
          name: input.data.location.name,
          ruianId: input.data.location.ruianId || undefined,
        },
        timeEnd: input.data.date.timeEnd || '00:00:00',
        timeStart: input.data.date.timeStart || '00:00:00',
        title: input.data.title,
        visibility: input.data.visibility,
      };

      if (input.data.annotation.trim() !== '') {
        body.annotation = input.data.annotation.trim();
      }

      if (input.data.capacity && input.data.capacity > 0) {
        body.capacity = input.data.capacity;
      }

      if (daysOfWeek.filter(Boolean).length > 0) {
        body.daysOfWeek = daysOfWeek;
      }

      if (input.data.image) {
        body.image = input.data.image;
      }

      if (input.data.link && input.data.link.trim() !== '') {
        body.link = sanitizeLink(input.data.link) || '-';
      }

      if (input.data.price && input.data.price.trim() !== '') {
        body.price = input.data.price.trim();
      }

      return this.$api.communities
        .createEvent(body, input.groupId)
        .catch((err) => {
          return this.$api
            .handleApiErrorMessages(err, {
              forbidden: 'app.error.event.create.forbidden',
              generic: 'app.error.event.create.generic',
            })
            .then((message) => {
              validation.api.push(message.toString());
              throw validation;
            });
        })
        .then((result) => {
          return getModule(EventsModule, this.store)
            .get({ id: result })
            .catch(() => {
              validation.api.push('app.error.event.couldNotReload');
              throw validation;
            });
        });
    });
  }

  /**
   * @throws ValidationCommit
   * @param input
   */
  @Action({ rawError: true })
  public update(input: UpdateInput) {
    let group: GroupItem | null = null;
    for (const groupItem of getModule(GroupModule, this.store).groupCache) {
      if (groupItem.id === input.groupId) {
        group = groupItem;
        break;
      }
    }

    return this.validate({ values: input.data, group }).then((validation) => {
      if (validationHasErrors(validation)) {
        throw validation;
      }

      const daysOfWeek = mapDaysOfWeekToArray(input.data.date.daysOfWeek);
      const start = parse(input.data.date.start);
      if (isNaN(start.getTime())) {
        validation.dateSelection.errors.start = true;
        throw validation;
      }

      const end = input.data.date.end ? parse(input.data.date.end) : start;
      if (isNaN(end.getTime())) {
        validation.dateSelection.errors.end = true;
        throw validation;
      }

      let annotation: string | null = null;
      if (input.data.annotation.trim() !== '') {
        annotation = input.data.annotation.trim();
      }

      const body: CzYsP14KcEventApiDtoEventUpdateObject = {
        areasOfInterest: input.data.areasOfInterest,
        annotation,
        content: input.data.content,
        dateEnd: format(end, 'YYYY-MM-DD'),
        dateStart: format(start, 'YYYY-MM-DD'),
        flags: getFlagsArray(input.data),
        place: {
          address: input.data.location.address,
          latitude: input.data.location.lat || undefined,
          longitude: input.data.location.lng || undefined,
          name: input.data.location.name,
          ruianId: input.data.location.ruianId || undefined,
        },
        timeEnd: input.data.date.timeEnd || '00:00:00',
        timeStart: input.data.date.timeStart || '00:00:00',
        title: input.data.title,
        visibility: input.data.visibility,
      };

      if (typeof input.data.capacity !== 'undefined') {
        if (input.data.capacity === null || input.data.capacity === 0) {
          body.capacity = null;
        } else if (input.data.capacity > 0) {
          body.capacity = input.data.capacity;
        }
      }

      if (daysOfWeek.filter(Boolean).length > 0) {
        body.daysOfWeek = daysOfWeek;
      }

      if (input.data.image) {
        body.image = input.data.image;
      }

      if (
        typeof input.data.link !== 'undefined' &&
        input.data.link.trim() !== ''
      ) {
        body.link = sanitizeLink(input.data.link);
      }

      if (typeof input.data.price !== 'undefined') {
        body.price = input.data.price;
      }

      return this.$api.events
        .updateEvent(body, input.eventId)
        .catch((err) => {
          return this.$api
            .handleApiErrorMessages(err, {
              forbidden: 'app.error.event.update.forbidden',
              generic: 'app.error.event.update.generic',
            })
            .then((message) => {
              validation.api.push(message.toString());
              throw validation;
            });
        })
        .then(() => {
          return getModule(EventsModule, this.store)
            .get({ id: input.eventId })
            .catch(() => {
              validation.api.push('app.error.event.couldNotReload');
              throw validation;
            });
        });
    });
  }

  @Action({ rawError: true })
  public validateDateInput(input: ValidationInput): Promise<ErrorFields> {
    const { values } = input;
    // Reset fields
    const dateInputErrors = createDefaultDateSelectorErrors();

    if (!values.date.timeStart) {
      dateInputErrors.timeStart = true;
    }

    if (!values.date.timeEnd) {
      dateInputErrors.timeEnd = true;
    }

    if (!values.date.start) {
      dateInputErrors.start = true;
    }

    if (
      values.date.inputMode === InputMode.DATE_ONLY &&
      input.group &&
      input.group.kind === Kind.PROJECT
    ) {
      return Promise.resolve(dateInputErrors);
    }

    if (values.date.start) {
      if (
        values.date.timeStart &&
        input.group &&
        input.group.kind === Kind.PROJECT
      ) {
        const start = parse(`${values.date.start} ${values.date.timeStart}`);
        if (!start.getTime() || start.getTime() <= Date.now()) {
          dateInputErrors.isInPast = true;
        }
      }

      if (values.date.inputMode === InputMode.SINGLEDAY) {
        if (values.date.timeStart && values.date.timeEnd) {
          const start = parse(`${values.date.start} ${values.date.timeStart}`);
          const end = parse(`${values.date.start} ${values.date.timeEnd}`);
          if (start > end) {
            dateInputErrors.endBeforeStart = true;
          }
        }
      } else if (values.date.inputMode === InputMode.MULTIDAY) {
        if (values.date.end) {
          const start = parse(`${values.date.start} 00:00`);
          const end = parse(`${values.date.end} 00:00`);
          if (start > end) {
            dateInputErrors.endBeforeStart = true;
          }
        }
      }
    }

    if (
      values.date.inputMode === InputMode.SINGLEDAY ||
      values.date.inputMode === InputMode.DATE_ONLY
    ) {
      return Promise.resolve(dateInputErrors);
    }

    if (values.date.inputMode === InputMode.REPEATING) {
      let atLeastOneSelected = false;
      for (const key in values.date.daysOfWeek) {
        if (
          values.date.daysOfWeek.hasOwnProperty(key) &&
          values.date.daysOfWeek[key]
        ) {
          atLeastOneSelected = true;
          break;
        }
      }
      if (!atLeastOneSelected) {
        dateInputErrors.daySelection = true;
      }
    }

    if (!values.date.end) {
      dateInputErrors.end = true;
    }

    return Promise.resolve(dateInputErrors);
  }

  @Action({ rawError: true })
  protected validate(input: ValidationInput): Promise<ValidationCommit> {
    const { values } = input;
    const validation: ValidationCommit = {
      api: [],
      content: [],
      dateSelection: {
        errors: createDefaultDateSelectorErrors(),
        messages: [],
      },
      location: [],
      title: [],
    };

    if (values.location.lat === null || values.location.lng === null) {
      validation.location.push('app.error.event.chooseLocation');
    }

    if (values.title.trim() === '') {
      validation.title.push('app.error.event.titleIsRequired');
    }

    if (values.content.trim() === '') {
      validation.content.push('app.error.event.contentIsRequired');
    }

    if (!isLocationValid(values.location)) {
      if (values.location.name) {
        validation.location.push('app.error.event.locationHasNameOnly');
      } else {
        validation.location.push('app.error.event.locationIsRequired');
      }
    }

    return this.validateDateInput(input).then((dateSelectionErrors) => {
      validation.dateSelection.errors = dateSelectionErrors;

      if (!isDateSelectionValid(validation.dateSelection.errors)) {
        validation.dateSelection.messages.push(
          'app.error.event.chooseDateSelection'
        );
      } else {
        const start = parse(values.date.start);
        if (isNaN(start.getTime())) {
          validation.dateSelection.messages.push(
            'app.error.event.couldNotDetermineStart'
          );
        } else {
          const startWithTime = parse(
            format(start, 'YYYY-MM-DD') + 'T' + values.date.timeStart
          );

          if (isNaN(startWithTime.getTime())) {
            validation.dateSelection.messages.push(
              'app.error.event.couldNotDetermineStart'
            );
          } else {
            // Allow at least one extra minute for the event to start
            if (startWithTime.getTime() <= new Date().getTime() + 60) {
              if (input.group && input.group.kind === Kind.GROUP) {
                // Only GROUP kind must start in future
                validation.dateSelection.messages.push(
                  'app.error.event.mustStartInFuture'
                );
              }
            }
            if (
              values.date.timeStart &&
              values.date.timeEnd &&
              values.date.timeStart > values.date.timeEnd
            ) {
              validation.dateSelection.messages.push(
                'app.error.event.endBeforeStart'
              );
            }
          }
        }
      }

      return validation;
    });
  }
}
