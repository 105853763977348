import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';

import Comments from './comments';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  Comments,
]) as LocaleMessages;

export default mergedLocalizations;
