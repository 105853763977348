import { SurveyItem, VoteOption, VoteState } from './';
import {
  CzYsP14KcFeedApiDtoSurveyItemObject,
  CzYsP14KcFeedApiDtoSurveyItemObjectUser,
  CzYsP14KcSurveyApiDtoSurveyObject,
  CzYsP14KcSurveyApiDtoSurveyObjectUser,
  CzYsP14KcSurveyApiDtoSurveyOptionObject,
} from '~/app/core/apiClient/api';
import {
  AreaOfInterestItem,
  mapAreasOfInterest,
} from '~/utils/areasOfInterest';
import { format, parse } from '~/utils/date-fns';
import { FeedType } from '~/utils/feed';
import { ImageSize, imageVersion } from '~/utils/media';
import getSurveyImageUrl from '~/utils/survey/getSurveyImageUrl';

type Input =
  | CzYsP14KcSurveyApiDtoSurveyObject
  | CzYsP14KcFeedApiDtoSurveyItemObject;
import VisibilityEnum = CzYsP14KcSurveyApiDtoSurveyObject.VisibilityEnum;

function createOption(
  option: CzYsP14KcSurveyApiDtoSurveyOptionObject
): VoteOption {
  return {
    beingVoted: false,
    count: option.voteCount,
    id: option.id || null,
    name: option.name,
    percentage: 0,
  };
}

// TODO: Really bad typeguard, but sufficient for the below logic, definitions are messed up
function isSurveyObjectRatherThanSurveyItemObject(
  data: any
): data is CzYsP14KcSurveyApiDtoSurveyObject {
  return (
    data && typeof data === 'object' && typeof data.visibility !== 'undefined'
  );
}

function isSurveyObjectUser(
  data: any
): data is CzYsP14KcSurveyApiDtoSurveyObjectUser {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.alreadyVoted === 'boolean' &&
    typeof data.liked === 'boolean'
  );
}

function isSurveyItemObjectUser(
  data: any
): data is CzYsP14KcFeedApiDtoSurveyItemObjectUser {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.voted === 'boolean' &&
    typeof data.liked === 'boolean'
  );
}

function createVoteState(
  state?:
    | CzYsP14KcSurveyApiDtoSurveyObjectUser
    | CzYsP14KcFeedApiDtoSurveyItemObjectUser
): VoteState | null {
  if (isSurveyObjectUser(state)) {
    if (!state.alreadyVoted || !state.userVotedOption) {
      return null;
    }

    return {
      date: state.voteDate || format(new Date(), 'YYYY-MM-DD'),
      option: state.userVotedOption,
      isDirty: false,
    };
  }

  if (isSurveyItemObjectUser(state)) {
    if (!state.voted) {
      return null;
    }

    return {
      date: format(new Date(), 'YYYY-MM-DD'),
      option: '',
      isDirty: true,
    };
  }

  return null;
}

export default function(data: Input, areas: AreaOfInterestItem[]): SurveyItem {
  const contentArray = data.content
    ? data.content.replace(/<[^>]+>/g, ' ').split(/\s+/)
    : [];
  let excerpt = contentArray.slice(0, 50).join(' ');
  if (contentArray.length > 50) {
    excerpt += '...';
  }

  const options = data.options.map(createOption);
  let totalVotes = 0;
  options.forEach((voteOption) => {
    totalVotes += voteOption.count;
  });

  const endOfVoting = parse(data.to).getTime();

  const voted = createVoteState(data.user);

  let areasOfInterest: AreaOfInterestItem[] = [];
  let visibility: VisibilityEnum = VisibilityEnum.PUBLIC;

  if (isSurveyObjectRatherThanSurveyItemObject(data)) {
    if (data.areasOfInterest) {
      areasOfInterest = mapAreasOfInterest(data.areasOfInterest, areas);
    }

    visibility = data.visibility;
  }

  const mediaVersion = imageVersion();

  return {
    areasOfInterest,
    comments: data.commentCount || 0,
    content: data.content || '',
    created: data.created || format(new Date()),
    end: data.to || format(new Date()),
    excerpt,
    group: {
      id: data.community.id || '',
      name: data.community.name || '',
    },
    id: data.id,
    image: {
      thumbnail: {
        src: getSurveyImageUrl(data.id, mediaVersion, ImageSize.THUMBNAIL),
      },
      small: {
        src: getSurveyImageUrl(data.id, mediaVersion, ImageSize.SMALL),
      },
      original: {
        src: getSurveyImageUrl(data.id, mediaVersion, ImageSize.ORIGINAL),
      },
    },
    liked: data.user?.liked || false,
    likes: data.likeCount || 0,
    options: options.map((item) => {
      if (item.count !== 0) {
        item.percentage = 100 / (totalVotes / item.count);
      }
      return item;
    }),
    // TODO: Missing from API
    reported: false,
    state: {
      active:
        !(isNaN(endOfVoting) || new Date().getTime() > endOfVoting) &&
        voted === null,
      liking: false,
      loading: false,
      reporting: false,
      voting: false,
    },
    title: data.title || '',
    type: FeedType.SURVEY,
    visibility,
    voted,
  };
}
