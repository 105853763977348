import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import SurveysModule, {
  SurveyError,
} from '~/app/core/store/modules/SurveysModule';
import { Content } from '~/components/atoms';
import { ErrorSnack, SurveyOptions, SurveyTime } from '~/components/molecules';
import { SelectEvent } from '~/components/molecules/surveyOptions/SurveyOptions';
import { componentOffset } from '~/utils/spacing';
import { SurveyItem } from '~/utils/survey';
import { VueComponent } from '~/utils/vue-component';
import UserModule from '~/app/core/store/modules/UserModule';

interface SurveyBodyInterface {
  survey: SurveyItem;
}

interface SurveySnacks {
  expired: boolean;
  voteFailed: boolean;
}

@Component
export default class SurveyBody extends VueComponent<SurveyBodyInterface>
  implements SurveyBodyInterface {
  @Prop({ required: true })
  public survey!: SurveyItem;

  protected surveySnacks: SurveySnacks = {
    expired: false,
    voteFailed: false,
  };

  protected get surveysStore() {
    return getModule(SurveysModule, this.$store);
  }

  protected get userStore() {
    return getModule(UserModule, this.$store);
  }

  public render() {
    return (
      <v-row>
        <v-col cols='12'>
          <Content text={this.survey.content} />
        </v-col>
        <v-col cols='12'>
          <SurveyTime survey={this.survey} class={`mb-${componentOffset}`} />
          <SurveyOptions
            active={
              this.survey.state.active &&
              !this.survey.state.voting &&
              !!this.userStore.user
            }
            class={`mb-${componentOffset}`}
            options={this.survey.options}
            onSelected={this.selected}
          />
        </v-col>
        <ErrorSnack v-model={this.surveySnacks.expired}>
          {this.$t('app.error.survey.expired')}
        </ErrorSnack>
        <ErrorSnack v-model={this.surveySnacks.voteFailed}>
          {this.$t('app.error.survey.voteFailed')}
        </ErrorSnack>
      </v-row>
    );
  }

  protected selected(data: SelectEvent) {
    if (!data.optionId) {
      return;
    }

    this.surveysStore
      .vote({
        id: this.survey.id,
        optionId: data.optionId,
      })
      .catch((err) => {
        if (err.message && err.message === SurveyError.TIME_EXPIRED) {
          this.surveySnacks.expired = true;
        } else {
          this.surveySnacks.voteFailed = true;
        }
      });
  }
}
