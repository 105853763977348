import { Component, Prop } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import VDialogCloseHandler, {
  VDialogCloseHandlerInterface,
} from '~/mixins/VDialogCloseHandler';
import { EditPostForm } from '~/components/templates';
import { PostItem } from '~/utils/post';
import { GroupItem } from '~/utils/group';

interface EditPostModalInterface {
  postId?: PostItem['id'] | null;
  groupId?: GroupItem['id'] | null;
}

@Component
export default class EditPostModal
  extends VueComponentMixin<
    EditPostModalInterface,
    VDialogCloseHandlerInterface
  >(VDialogCloseHandler)
  implements EditPostModalInterface {
  @Prop({ default: null })
  public postId!: PostItem['id'] | null;

  @Prop({ default: null })
  public groupId!: GroupItem['id'] | null;

  public created() {
    if (this.setPersistent) {
      this.setPersistent();
    }
  }

  public render() {
    return (
      <v-dialog max-width='900' scrollable {...this.vDialogAttributes}>
        <EditPostForm
          postId={this.postId}
          groupId={this.groupId}
          onCancel={this.close}
        />
      </v-dialog>
    );
  }

  protected close() {
    if (this.vDialogClose) {
      this.vDialogClose();
    }
  }
}
