import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './ImageUploadPlaceholder.scss';
import photoBackground from '~/assets/images/photo_background.svg';

const rootClass = 'ys-image-upload';

@Component({
  style,
})
export default class ImageUploadPlaceholder extends VueComponent<{}> {
  public render() {
    return (
      <div class={rootClass}>
        <img src={photoBackground} alt='' />
      </div>
    );
  }
}
