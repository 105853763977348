import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseButton from '~/components/atoms/baseButton/BaseButton';
import { SpreadAttributes } from '~/utils/vue-component';

export type JoinEvent = void;

export type LeaveEvent = void;

export enum JoinStatus {
  INVITED,
  JOINED,
  NONE,
  PENDING,
  REJECT_INVITATION,
}

export interface JoinButtonInterface {
  active?: boolean;
  disabled?: boolean;
  loading: boolean;
  status: JoinStatus;
  text?: boolean;
  onJoin?: (event: JoinEvent) => void;
  onLeave?: (event: LeaveEvent) => void;
}

@Component
export default class JoinButton extends BaseButton<JoinButtonInterface>
  implements JoinButtonInterface {
  @Prop({ default: true, type: Boolean })
  public active!: boolean;

  @Prop({ required: false })
  public status!: JoinStatus;

  @Prop({ default: false, type: Boolean })
  public text!: boolean;

  protected get label() {
    switch (this.status) {
      case JoinStatus.JOINED:
        return this.$t('app.communities.cancelMembership');
      case JoinStatus.INVITED:
        return this.$t('app.communities.acceptMembership');
      case JoinStatus.PENDING:
        return this.$t('app.communities.cancelPendingMembership');
      case JoinStatus.REJECT_INVITATION:
        return this.$t('app.communities.rejectMembership');
      default:
        return this.$t('app.communities.becomeMember');
    }
  }

  public render() {
    if (!this.active) {
      return;
    }

    return (
      <v-btn
        color='secondary'
        disabled={this.disabled}
        loading={this.loading}
        onClick={this.buttonClickHandler}
        rounded
        text={this.text}
        {...this.baseAttributes}
      >
        {this.$slots.default || this.label}
      </v-btn>
    );
  }

  protected buttonClickHandler() {
    if (this.status === JoinStatus.NONE || this.status === JoinStatus.INVITED) {
      this.emitJoin();
    } else {
      this.emitLeave();
    }
  }

  @Emit('join')
  protected emitJoin(): JoinEvent {
    return;
  }

  @Emit('leave')
  protected emitLeave(): LeaveEvent {
    return;
  }
}
