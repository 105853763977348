import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import ErrorPage from '~/components/templates/errorPage/ErrorPage';
import picture from '~/assets/images/error/403.png';

@Component
export default class Forbidden extends VueComponent<{}> {
  public render() {
    return (
      <ErrorPage
        httpCode={403}
        picture={picture}
        title={this.$t('app.error.view.forbidden')}
      >
        <p>{this.$t('app.error.view.forbiddenParagraph')}</p>
      </ErrorPage>
    );
  }
}
