import { config } from 'vuex-module-decorators';
import AbstractModule from './AbstractModule';
import AreasOfInterestModule from './AreasOfInterestModule';
import CommentsModule from './CommentsModule';
import DomainModule from './DomainModule';
import EventDetailModule from './EventDetailModule';
import EventMutationModule from './EventMutationModule';
import EventsModule from './EventsModule';
import EventFeedModule from './EventFeedModule';
import GroupDetailModule from './GroupDetailModule';
import GroupModule from './GroupModule';
import GroupMutationModule from './GroupMutationModule';
import LocationPickerModule from './LocationPickerModule';
import MutationModalsModule from './MutationModalsModule';
import PostListModule from './PostListModule';
import PostsModule from './PostsModule';
import SurveysModule from './SurveysModule';
import UserModule from './UserModule';

config.rawError = true;

const modules: { [s: string]: typeof AbstractModule } = {
  AreasOfInterestModule,
  CommentsModule,
  DomainModule,
  EventDetailModule,
  EventMutationModule,
  EventsModule,
  EventFeedModule,
  GroupDetailModule,
  GroupModule,
  GroupMutationModule,
  LocationPickerModule,
  MutationModalsModule,
  PostListModule,
  PostsModule,
  SurveysModule,
  UserModule,
};

export { modules };
