import { Component, Prop } from 'vue-property-decorator';

import { parse, format } from '~/utils/date-fns';
import { defaultDateFormat } from '~/utils/dateTime';
import { greyTextColor } from '~/utils/theme/colors';
import { VueComponent } from '~/utils/vue-component';

const rootClass = 'ys-event-dates';

export interface EventTimeRange {
  end: string | null;
  start: string | null;
}

export interface EventDateRange {
  from: string;
  to: string | null;
}

interface EventTimeInterface {
  date: EventDateRange;
  time: EventTimeRange;
}

@Component
export default class EventDates extends VueComponent<EventTimeInterface>
  implements EventTimeInterface {
  @Prop({ required: true })
  public date!: EventDateRange;

  @Prop({ required: true })
  public time!: EventTimeRange;

  protected get endDate(): Date | void {
    if (!this.date.to) {
      return;
    }

    return parse(this.date.to);
  }

  protected get startDate(): Date {
    return parse(this.date.from);
  }

  protected get showEndDate() {
    if (this.date.from === this.date.to) {
      return false;
    }

    return this.date.to !== null;
  }

  protected get showTime() {
    return this.time.start || this.time.end;
  }

  public render() {
    return (
      <div class={rootClass}>
        <v-row no-gutters align='center'>
          <v-avatar>
            <v-icon>mdi-calendar</v-icon>
          </v-avatar>
          <div>
            <span class='text-capitalize'>
              {format(this.startDate, `dddd ${defaultDateFormat}`)}
            </span>
            {this.endDate && this.showEndDate && (
              <span class='text-capitalize'>
                {' '}
                - {format(this.endDate, `dddd ${defaultDateFormat}`)}
              </span>
            )}
          </div>
        </v-row>
        {this.showTime && (
          <v-row no-gutters align='center'>
            <v-avatar>
              <v-icon>mdi-clock-outline</v-icon>
            </v-avatar>
            <div>
              {this.time.start && (
                <span>
                  <span class={greyTextColor}>
                    {this.$t('app.event.since')}
                  </span>{' '}
                  {this.time.start}
                </span>
              )}
              {this.time.end && (
                <span class='ml-3'>
                  <span class={greyTextColor}>
                    {this.$t('app.event.until')}
                  </span>{' '}
                  {this.time.end}
                </span>
              )}
            </div>
          </v-row>
        )}
      </div>
    );
  }
}
