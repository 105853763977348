import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import AbstractModule from './AbstractModule';
import { itemIsNotNull } from '~/utils/typeguards';
import {
  createEventInstanceItem,
  EventInstanceIdentification,
  EventInstanceItem,
} from '~/utils/event';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import GroupModule from '~/app/core/store/modules/GroupModule';
import { Kind } from '~/utils/group';

const defaultPageSize = 10;

type SetEventsCommit = EventInstanceItem[];

@Module({
  name: 'EventFeedModule',
  stateFactory: true,
  namespaced: true,
})
export default class EventFeedModule extends AbstractModule {
  public lastPage: boolean = true;

  public loading: boolean = false;

  public eventInstanceIdentifications: EventInstanceIdentification[] = [];

  protected pageNumber: number = 0;

  @Action({ rawError: true })
  public getItems(groupId?: string): Promise<SetEventsCommit> {
    this.setLoading(true);
    let promise;
    if (groupId) {
      const groupModule = getModule(GroupModule, this.store);
      promise = groupModule
        .loadUncachedIds({ requestedIds: [groupId] })
        .then(() => {
          let dateFrom: string | undefined;
          for (const groupItem of groupModule.groupCache) {
            if (groupItem.id === groupId) {
              if (groupItem.kind === Kind.PROJECT) {
                dateFrom = '1970-01-01';
              }
              break;
            }
          }

          return this.$api.communities.listEventsForCommunity(
            groupId,
            dateFrom,
            undefined,
            undefined,
            undefined,
            undefined,
            this.pageNumber,
            defaultPageSize
          );
        });
    } else {
      promise = this.$api.communities.listEventsForCommunities(
        undefined,
        this.pageNumber,
        defaultPageSize
      );
    }
    return promise
      .then((result) => {
        return getModule(AreasOfInterestModule, this.store)
          .loadData()
          .then((areas) => {
            const items = result.content
              ? result.content
                  .map((item) => {
                    return createEventInstanceItem(item, areas.allItems);
                  })
                  .filter(itemIsNotNull)
              : [];

            const groupIds = items
              .map((item) => item.group)
              .filter(itemIsNotNull)
              .map((group) => group.id);

            return getModule(GroupModule, this.store)
              .loadUncachedIds({ requestedIds: groupIds })
              .then(() => {
                if (this.pageNumber === 0) {
                  this.setItems(items);
                } else {
                  this.concatItems(items);
                }
                this.setLastPage(result.last !== false);
                this.setPageNumber(this.pageNumber + 1);
                return items;
              });
          });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  public setItems(data: SetEventsCommit) {
    this.eventInstanceIdentifications = data.map((event) => {
      return {
        id: event.id,
        date: event.date,
      };
    });
  }

  @Mutation
  public concatItems(data: SetEventsCommit) {
    this.eventInstanceIdentifications = this.eventInstanceIdentifications.concat(
      data.map((event) => {
        return {
          id: event.id,
          date: event.date,
        };
      })
    );
  }

  @Mutation
  protected setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  protected setLastPage(data: boolean) {
    this.lastPage = data;
  }

  @Mutation
  public setPageNumber(data: number) {
    this.pageNumber = data;
  }
}
