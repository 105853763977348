import { PostItem } from '~/utils/post';
import { SurveyItem } from '~/utils/survey';

type FeedItem = PostItem | SurveyItem;

enum FeedType {
  POST = 'POST',
  SURVEY = 'SURVEY',
}

export { FeedItem, FeedType };
