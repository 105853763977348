import createDaysOfWeek from './createDaysOfWeek';
import createDefaultDaySelection from './createDefaultDaySelection';
import createEventInstanceItem from './createEventInstanceItem';
import createEventItem from './createEventItem';
import findFirstInstanceDate from './findFirstInstanceDate';
import { GeoLocation } from '~/utils/location';
import { CzYsP14KcEventApiDtoEventDetailObject } from '~/app/core/apiClient/api';
import { AreaOfInterestItem } from '~/utils/areasOfInterest';
import { ImageItem } from '~/utils/media';
import { GroupItem } from '~/utils/group';

const icons = {
  create: 'mdi-calendar-text',
};

interface FutureEvent {
  start: string;
  end: string;
}

interface EventState {
  liking: boolean;
  loading: boolean;
  reporting: boolean;
}

interface EventInstanceItem {
  areasOfInterest: AreaOfInterestItem[];
  comments: number | null;
  content: string;
  date: string;
  group: GroupItem | null;
  id: string;
  image: {
    thumbnail: ImageItem;
    small: ImageItem;
    original: ImageItem;
  };
  liked: boolean;
  likes: number | null;
  perex: string;
  place: GeoLocation;
  reported: boolean;
  state: EventState;
  timeEnd: string | null;
  timeStart: string | null;
  title: string;
}

interface EventInstanceIdentification {
  id: EventInstanceItem['id'];
  date: EventInstanceItem['date'];
}

interface EventItem {
  annotation: string;
  areasOfInterest: AreaOfInterestItem[];
  capacity: number | null;
  content: string;
  dateEnd: string;
  dateStart: string;
  daySelection: DaySelection;
  flags: {
    barrierFree: boolean;
    dogsAllowed: boolean;
  };
  id: string;
  image: {
    thumbnail: ImageItem;
    small: ImageItem;
    original: ImageItem;
  };
  link: string;
  location: GeoLocation;
  price: string | null;
  timeEnd: string;
  timeStart: string;
  title: string;
  visibility: CzYsP14KcEventApiDtoEventDetailObject.VisibilityEnum;
}

interface DaySelection {
  [key: string]: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export {
  createDaysOfWeek,
  createDefaultDaySelection,
  createEventInstanceItem,
  createEventItem,
  DaySelection,
  EventInstanceIdentification,
  EventInstanceItem,
  EventItem,
  findFirstInstanceDate,
  FutureEvent,
  icons,
  weekDays,
};
