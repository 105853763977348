import { RouteConfig } from 'vue-router';

import GroupDetail from '~/views/GroupDetail';
import parameters from './parameters';

const config: RouteConfig = {
  component: GroupDetail,
  path: `group/${parameters.guidPath}`,
};

export default config;
