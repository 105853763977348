import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

export enum PillColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface RemoveEvent {
  name: string;
  value: string;
}

interface FilterChipInterface {
  label: string;
  value?: string;
  visible?: boolean;
  color?: PillColor;
  onRemove?: (data: RemoveEvent) => any;
}

@Component
export default class FilterChip extends VueComponent<FilterChipInterface>
  implements FilterChipInterface {
  @Prop({ required: true, type: String })
  public label!: string;

  @Prop({ type: String })
  public value?: string;

  @Prop({ type: Boolean, default: true })
  public visible!: boolean;

  @Prop({ default: PillColor.PRIMARY, type: String })
  public color!: PillColor;

  public render() {
    return (
      <v-chip
        active={this.visible}
        color={this.color}
        class='mt-2 mr-1'
        close
        {...{
          on: {
            'click:close': this.emitRemove,
          },
        }}
      >
        {this.label}
      </v-chip>
    );
  }

  @Emit('remove')
  protected emitRemove(): RemoveEvent {
    return {
      name: this.label,
      value: this.value || '',
    };
  }
}
