import { RouteConfig } from 'vue-router';

import NotFound from '~/components/templates/common/NotFound';

const config: RouteConfig = {
  component: NotFound,
  path: 'unko.wn',
  alias: ['okay', 'not.okay'],
};

export default config;
