import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import style from './Footer.scss';
import euLogo from '~/assets/EU_logo.jpg';

const rootClass = 'ys-footer';

@Component({
  style,
})
export default class Footer extends VueComponent<{}> {
  public render() {
    return (
      <v-footer class={`${rootClass} pa-0`} color='white' absolute>
        <v-container fluid class='pa-0'>
          <v-row class='pa-1' no-gutters justify='center' justify-md='end'>
            <v-img
              class='flex flex-shrink-0 flex-grow-0'
              src={euLogo}
              alt={this.$t('app.footer.euLogo')}
              width={'400px'}
              max-width={'100%'}
              contain
            />
          </v-row>
          <v-row class={`${rootClass}__bottom`} dark>
            <v-col cols='12' class='pa-0'>
              <v-sheet class='text-center' width='100%' dark height='100'>
                <v-row class='fill-height' align='center' justify='center'>
                  &copy; {new Date().getFullYear()}{' '}
                  {this.$t('app.footer.copyright')}
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    );
  }
}
