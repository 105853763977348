import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';

import Event from './event';
import Events from './events';
import EventsSearchForm from './eventsSearchForm';
import EventListItem from './eventListItem';
import EventTimeInput from './eventTimeInput';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  Event,
  Events,
  EventsSearchForm,
  EventListItem,
  EventTimeInput,
]) as LocaleMessages;

export default mergedLocalizations;
