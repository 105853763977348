import { Component, Prop } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';

import { PrefetchComponent, Prefetch } from '~/mixins/prefetch';
import { greyBackgroundColor } from '~/utils/theme/colors';
import { GroupItem } from '~/utils/group';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import { GroupTiles } from '~/components/organisms';

interface GroupListInterface {
  items: GroupItem[];
  title?: TranslateResult | string;
}

@Component
export default class GroupList
  extends VueComponentMixin<GroupListInterface, PrefetchComponent>(Prefetch)
  implements GroupListInterface {
  @Prop({ required: true })
  public items!: GroupItem[];

  @Prop({ type: String, default: '' })
  public title!: string;

  public render() {
    return (
      <v-card class={`ys-group-list ${greyBackgroundColor}`}>
        <v-card-title>
          <h2>{this.title}</h2>
          <v-spacer />
          {this.$slots.actions}
        </v-card-title>
        <v-card-text>
          <GroupTiles dense={true} items={this.items} />
          {!this.items.length && <p>{this.$t('app.communities.noGroups')}</p>}
        </v-card-text>
        {(this.$slots.bottomLeftActions || this.$slots.bottomRightActions) && (
          <v-card-actions>
            {this.$slots.bottomLeftActions}
            <v-spacer />
            {this.$slots.bottomRightActions}
          </v-card-actions>
        )}
      </v-card>
    );
  }
}
