import { Locale } from 'vue-i18n';
import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import DomainModule from '~/app/core/store/modules/DomainModule';

export enum Target {
  BLANK = '_blank',
  SELF = '_self',
  TOP = '_top',
  PARENT = '_parent',
}

export function isTargetEnum(data: any): data is Target {
  for (const value in Target) {
    if (Target.hasOwnProperty(value)) {
      if (data === (Target as any)[value]) {
        return true;
      }
    }
  }

  return false;
}

const internalCache: { [key: string]: string } = {};

const stripDomainPrefix = new RegExp('https?://[^/]+/+', 'i');

export function createInternal(url: string, locale: Locale): string {
  if (!internalCache[url]) {
    // Trim slash from the start of the url
    let normalizedUrl = url.indexOf('/') === 0 ? url.substr(1) : url;
    if (normalizedUrl.indexOf('http') === 0) {
      normalizedUrl = normalizedUrl.replace(stripDomainPrefix, '');
      internalCache[url] = `/${normalizedUrl}`;
    } else {
      internalCache[url] = `/${locale}/${normalizedUrl}`;
    }
  }

  return internalCache[url];
}

const externalCache: { [key: string]: string } = {};

export function createExternal(url: string) {
  if (!externalCache[url]) {
    const regex = /(http(?:s)?\:\/\/|mailto\:|tel\:)/gi;
    const matches = regex.exec(url);
    externalCache[url] = matches && matches[1] ? url : `https://${url}`;
  }

  return externalCache[url];
}

const urlCache: { [key: string]: boolean } = {};

export function isInternal(link: string, store: Store<any>): boolean {
  // Strip whitespace
  let url = link.trim();

  if (!urlCache[url]) {
    // If it's absolute, then it's definitely internal
    if (url.indexOf('/') === 0) {
      urlCache[url] = true;
    } else {
      const domainModule = getModule(DomainModule, store);

      if (!domainModule.canonicalHostname) {
        // If we cannot determine the domain of the current request, then treat all the links as external
        urlCache[url] = false;
      } else {
        // Strip schema
        if (url.indexOf('https://') === 0) {
          url = url.substr(8);
        } else if (url.indexOf('http://') === 0) {
          url = url.substr(7);
        }

        // Strip www
        if (url.indexOf('www.') === 0) {
          url = url.substr(4);
        }

        // Check if the hostname without www matches the beginning of the url without www
        urlCache[url] = url.indexOf(domainModule.canonicalHostname) === 0;
      }
    }
  }

  return urlCache[url];
}

const shareCache: { [key: string]: string } = {};

export function createShareUri(url: string) {
  if (!window) {
    return;
  }
  if (!shareCache[url]) {
    shareCache[url] = createExternal(
      `${window.location.origin.replace(/\/$/, '')}${
        url.indexOf('/') === 0 ? url : `/${url}`
      }`
    );
  }

  return shareCache[url];
}
