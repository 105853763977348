import deepmerge from 'deepmerge';
import Vue from 'vue';
import VueI18n, { I18nOptions, LocaleMessages } from 'vue-i18n';
import { defaultLocale } from '~/app/localization';
import {
  choiceIndex as csChoiceIndex,
  default as i18n_cs,
} from '~/app/localization/cs';

Vue.use(VueI18n);

const localizations: LocaleMessages[] = [
  {
    cs: i18n_cs,
  },
];

const mergedLocalizations: LocaleMessages = deepmerge.all(
  localizations
) as LocaleMessages;

const i18nOptions: I18nOptions = {
  locale: defaultLocale,
  messages: mergedLocalizations,
  pluralizationRules: {
    cs: csChoiceIndex,
  },
};

export default function createI18n() {
  return new VueI18n(i18nOptions);
}
