import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { CzYsP14KcCommunityApiDtoCommunityDetailObject } from '~/app/core/apiClient/api';
import GroupModule from '~/app/core/store/modules/GroupModule';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import GroupDetailModule from '~/app/core/store/modules/GroupDetailModule';
import {
  ButtonSize,
  CreateEventButton,
  CreatePostButton,
  CreateSurveyButton,
  JoinButton,
} from '~/components/atoms';
import { JoinStatus } from '~/components/atoms/joinButton/JoinButton';
import { RequestMembershipDialog } from '~/components/organisms';
import {
  EditEventModal,
  EditPostModal,
  EditSurveyModal,
} from '~/components/templates';
import {
  GroupItem,
  userCanCreateEvent,
  userCanCreatePost,
  userCanCreateSurvey,
  Visibility,
} from '~/utils/group';
import { VueComponent } from '~/utils/vue-component';
import MembershipTypeEnum = CzYsP14KcCommunityApiDtoCommunityDetailObject.MembershipTypeEnum;

interface Snacks {
  membershipRequestFailed: boolean;
  requestedMembership: boolean;
}
@Component
export default class GroupActionButtons extends VueComponent<{}> {
  protected creatingEvent: boolean = false;

  protected creatingPost: boolean = false;

  protected creatingSurvey: boolean = false;

  protected joiningPrivateGroup: GroupItem | null = null;

  protected snacks: Snacks = {
    membershipRequestFailed: false,
    requestedMembership: false,
  };

  protected get dataStore() {
    return getModule(GroupDetailModule, this.$store);
  }

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  protected get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  protected get id() {
    return this.dataStore.id;
  }

  protected get joinStatus(): JoinStatus {
    if (!this.groupItem) {
      return JoinStatus.NONE;
    }

    switch (this.groupItem.membershipType) {
      case MembershipTypeEnum.DEPUTY:
      case MembershipTypeEnum.MEMBER:
      case MembershipTypeEnum.OWNER:
        return JoinStatus.JOINED;
      case MembershipTypeEnum.APPLICANT:
        return JoinStatus.PENDING;
      case MembershipTypeEnum.INVITEE:
        return JoinStatus.INVITED;
      default:
        return JoinStatus.NONE;
    }
  }

  protected get groupItem(): GroupItem | null {
    for (const group of this.groupStore.groupCache) {
      if (group.id === this.dataStore.id) {
        return {
          ...group,
          visibility: this.dataStore.visibility || Visibility.PUBLIC,
        };
      }
    }
    return null;
  }

  public render() {
    if (!this.groupItem) {
      return;
    }

    return (
      <div>
        <CreatePostButton
          class='mx-1'
          active={userCanCreatePost(this.groupItem)}
          onCreate={() => {
            this.creatingPost = true;
          }}
          size={ButtonSize.SMALL}
        />

        <CreateSurveyButton
          class='mx-1'
          active={userCanCreateSurvey(this.groupItem)}
          onCreate={() => {
            this.creatingSurvey = true;
          }}
          size={ButtonSize.SMALL}
        />

        <CreateEventButton
          class='mx-1'
          active={userCanCreateEvent(this.groupItem)}
          onCreate={() => {
            this.creatingEvent = true;
          }}
          size={ButtonSize.SMALL}
        />

        <JoinButton
          active={this.groupItem.membershipType !== MembershipTypeEnum.OWNER}
          class='mx-1'
          loading={this.groupItem.state.joining}
          onJoin={this.join}
          onLeave={this.leave}
          status={this.joinStatus}
        />

        {this.joinStatus === JoinStatus.INVITED && (
          <JoinButton
            active={true}
            class='mx-1'
            loading={this.groupItem.state.joining}
            onLeave={this.leave}
            status={JoinStatus.REJECT_INVITATION}
          />
        )}

        <EditEventModal
          active={this.creatingEvent}
          groupId={this.groupItem.id}
          groupName={this.groupItem.name}
          onCloseDialog={() => (this.creatingEvent = false)}
        />
        <EditPostModal
          active={this.creatingPost}
          groupId={this.groupItem.id}
          onCloseDialog={() => (this.creatingPost = false)}
        />
        <EditSurveyModal
          active={this.creatingSurvey}
          groupId={this.groupItem.id}
          onCloseDialog={() => (this.creatingSurvey = false)}
        />
        <RequestMembershipDialog
          active={!!this.joiningPrivateGroup}
          group={this.joiningPrivateGroup}
          onCloseDialog={() => {
            this.joiningPrivateGroup = null;
          }}
        />
      </div>
    );
  }

  protected join() {
    if (!this.groupItem) {
      return;
    }

    if (this.groupItem.visibility === Visibility.PRIVATE) {
      this.joiningPrivateGroup = this.groupItem;
      return;
    }
    return this.groupStore.joinGroup(this.groupItem.id);
  }

  protected leave() {
    if (!this.groupItem) {
      return;
    }

    this.groupStore.leaveGroup(this.groupItem.id);
  }
}
