import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';

import Posts from './posts';
import Post from './post';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  Post,
  Posts,
]) as LocaleMessages;

export default mergedLocalizations;
