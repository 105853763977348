import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

export interface VDialogCloseHandlerInterface {
  active: boolean;
  onCloseDialog?: () => void;
  setPersistent?: () => void;
  vDialogClose?: () => void;
  vDialogAttributes?: object;
}

@Component
export default class VDialogCloseHandler
  extends VueComponent<VDialogCloseHandler>
  implements VDialogCloseHandlerInterface {
  @Prop({ type: Boolean, default: false })
  public active!: boolean;

  protected persistent: boolean = false;

  public get vDialogAttributes() {
    const attributes: { [key: string]: any } = {
      attrs: {
        'no-click-animation': !this.persistent,
        persistent: true,
        value: this.active,
      },
      on: {},
    };

    if (!this.persistent) {
      attributes.on['click:outside'] = this.vDialogClose;
    }

    return attributes;
  }

  public vDialogClose() {
    this.$emit('closeDialog');
  }

  public setPersistent() {
    this.persistent = true;
  }
}
