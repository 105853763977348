export default {
  app: {
    posts: {
      basicDataStep: 'Základní informace',
      buttonSubtitle: 'Napište libovolný text',
      chooseGroup: 'Vyberte skupinu, pro kterou chcete vytvořit příspěvek',
      create: 'Přidat příspěvek',
      metaDataStep: 'Zaměření příspěvku',
      noPosts: 'Žádné příspěvky nebyly nalezené',
      reported: 'Příspěvek byl nahlášen',
      update: 'Upravit příspěvek',
      updatePhoto: 'Upravit fotografii',
    },
  },
};
