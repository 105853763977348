import { Component, Prop, Watch } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './EventHead.scss';
import { DateSticker } from '~/components/atoms';
import { EventImage } from '~/components/molecules';
import { EventItem } from '~/utils/event';
import { format } from '~/utils/date-fns';
import { EventImageContext } from '~/components/molecules/eventImage/EventImage';
import { createEmptyEventItem } from '~/utils/event/createEventItem';
import getEventImageUrl from '~/utils/event/getEventImageUrl';
import { ImageSize, imageVersion } from '~/utils/media';
import { getModule } from 'vuex-module-decorators';
import EventsModule from '~/app/core/store/modules/EventsModule';

const rootClass = 'ys-event-head';

interface EventHeadInterface {
  date: Date;
  eventId: string;
  isPublic: boolean;
  title?: string;
}

@Component({
  style,
})
export default class EventHead extends VueComponent<EventHeadInterface>
  implements EventHeadInterface {
  @Prop({ type: Date, required: true })
  public date!: Date;

  @Prop({ type: String })
  public eventId!: string;

  @Prop({ type: Boolean, required: true })
  public isPublic!: boolean;

  @Prop({ type: String })
  public title?: string;

  protected get eventModule() {
    return getModule(EventsModule, this.$store);
  }

  protected get eventItem(): EventItem | null {
    for (const eventItem of this.eventModule.cache) {
      if (eventItem.id === this.eventId) {
        return eventItem;
      }
    }

    return null;
  }

  public render() {
    if (!this.eventItem) {
      return;
    }

    return (
      <EventImage
        event={this.eventItem}
        class={`white--text`}
        context={EventImageContext.HEAD}
      >
        <v-row class={`${rootClass}__overlay fill-height`}>
          <v-col class='fill-height d-flex flex-column' cols='12'>
            <div class='d-flex px-3'>
              <DateSticker
                date={this.date}
                class={`${rootClass}__date-sticker`}
              />
            </div>
            <v-spacer />
            <div class={`${rootClass}__membership text-center`}>
              {this.$t(
                this.isPublic
                  ? 'app.event.publicEvent'
                  : 'app.event.privateEvent'
              )}
            </div>
            <h1 class={`${rootClass}__title text-center`}>{this.title}</h1>
          </v-col>
        </v-row>
      </EventImage>
    );
  }
}
