import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { getLocalizedLocation } from '~/app/core/router';
import EventDetailRoute from '~/app/core/router/routes/eventDetail';

import style from './EventBanner.scss';
import { EventImage, EventTimes, Link } from '~/components/molecules';
import { format, parse } from '~/utils/date-fns';
import { createEventInstanceItem, EventInstanceItem } from '~/utils/event';
import { routes } from '~/app/core/router/routes';
import { routeParameterDateFormat } from '~/app/core/router/routes/parameters/date';
import { EventImageContext } from '~/components/molecules/eventImage/EventImage';
import { Kind } from '~/utils/group';

const rootClass = 'ys-event-banner';

export interface EventBannerInterface {
  date: string;
  id: string;
  place?: string;
  title: string;
  timeStart: string;
  timeEnd: string;
}

@Component({
  style,
})
export default class EventBanner extends VueComponent<EventBannerInterface>
  implements EventBannerInterface {
  @Prop({ type: String, required: true })
  public date!: string;

  @Prop({ type: String, required: true })
  public id!: string;

  @Prop({ type: String, required: true })
  public timeStart!: string;

  @Prop({ type: String, required: true })
  public timeEnd!: string;

  @Prop({ type: String, required: true })
  public title!: string;

  @Prop({ type: String })
  public place?: string;

  protected get parsedDate(): Date {
    return parse(this.date);
  }

  protected get eventItem(): EventInstanceItem | null {
    return createEventInstanceItem(
      {
        dateStart: this.date,
        id: this.id,
        title: this.title,
      },
      []
    );
  }

  protected get route() {
    if (!this.eventItem) {
      return;
    }

    if (this.$route.meta.public) {
      return getLocalizedLocation(routes.eventPreview, this.$router, {
        guid: this.eventItem.id,
        date: format(this.parsedDate, routeParameterDateFormat),
      });
    }

    return getLocalizedLocation(EventDetailRoute, this.$router, {
      guid: this.id,
      date: format(this.parsedDate, routeParameterDateFormat),
    });
  }

  public render() {
    if (!this.eventItem || !this.route) {
      return;
    }

    return (
      <Link class={rootClass} to={this.route}>
        <v-banner class={`${rootClass}__banner`}>
          <v-avatar slot='icon' size='40'>
            <EventImage
              event={this.eventItem}
              context={EventImageContext.AVATAR}
            />
          </v-avatar>
          <div class={`${rootClass}__date overline`}>
            {format(this.parsedDate)}
          </div>
          <h4>{this.title}</h4>
          {this.eventItem.group?.kind !== Kind.PROJECT && (
            <EventTimes
              end={this.timeEnd}
              icon={false}
              start={this.timeStart}
            />
          )}

          <div class='grey--text mt-1'>{this.place || ''}</div>
        </v-banner>
      </Link>
    );
  }
}
