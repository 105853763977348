export default {
  app: {
    project: {
      basicDataStep: 'Základní informace',
      areasOfInterest: 'Oblasti zájmu projektu',
      chooseAreasOfInterest: 'Vyberte oblasti zájmu',
      createProject: 'Založit projekt',
      description: 'Popis projektu',
      editProject: 'Upravit projekt',
      joinPrivateHint:
        'Tento projekt je soukromý. Jeho příspěvky uvidíte teprve poté, co budete členem.',
      kind: 'Projekt',
      manageProject: 'Spravovat projekt',
      mapTitle: 'Místo projektu',
      metaDataStep: 'Zaměření projektu',
      myProjects: 'Moje projekty',
      name: 'Název projektu',
      officialLabel: 'Oficiální projekt',
      place: 'Místo projektu',
      priority: {
        label: 'Priorita',
        enumMap: {
          A: 'A',
          B: 'B',
          C: 'C',
        },
      },
      private: 'Soukromý',
      privateLabel: 'Soukromý projekt',
      privateHint: 'Soukromé příspěvky, žádosti o členství',
      projectChat: 'Diskuze k projektu',
      projectName: 'Název',
      projects: 'Projekty',
      public: 'Veřejný',
      publicLabel: 'Veřejný projekt',
      publicHint: 'Veřejné příspěvky, kdokoliv se může přidat',
      state: {
        label: 'Stav',
        enumMap: {
          INTENT: 'Záměr',
          PROJECT: 'Projekt',
          PROGRESS: 'Realizace',
          FINALIZED: 'Realizováno',
          CANCELED: 'Zrušeno',
        },
      },
      visibility: 'Viditelnost projektu',
      title: 'Projekty',
      update: 'Upravit projekt',
    },
  },
};
