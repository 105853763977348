import { RouteConfig } from 'vue-router';

import Events from '~/views/Groups';

const config: RouteConfig = {
  component: Events,
  path: 'groups',
};

export default config;
