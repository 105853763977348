import getGroupImageUrl from './getGroupImageUrl';
import isProjectItem from './isProjectItem';
import userCanCreateEvent from './userCanCreateEvent';
import userCanCreatePost from './userCanCreatePost';
import userCanCreateSurvey from './userCanCreateSurvey';
import userCanEditGroup from './userCanEditGroup';

import { CzYsP14KcCommunityApiDtoCommunityDetailObject } from '~/app/core/apiClient/api';
import { AreaOfInterestItem } from '~/utils/areasOfInterest';
import { GeoLocation } from '~/utils/location';
import { ImageItem } from '~/utils/media';

import MembershipTypeEnum = CzYsP14KcCommunityApiDtoCommunityDetailObject.MembershipTypeEnum;
import isEnumGenerator from '~/utils/typeguards/isEnumGenerator';

enum Visibility {
  OFFICIAL = 'OFFICIAL',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

enum Kind {
  GROUP = 'GROUP',
  PROJECT = 'PROJECT',
}

const icons = {
  create: 'mdi-account-multiple-plus',
};

interface GroupState {
  editable: boolean;
  joining: boolean;
  loading: boolean;
}

interface GroupItem {
  areasOfInterest: AreaOfInterestItem[];
  description: string;
  id: string;
  image: {
    thumbnail: ImageItem;
    small: ImageItem;
    original: ImageItem;
  };
  kind: Kind;
  location: GeoLocation;
  members: number | null;
  membershipType: MembershipTypeEnum | null;
  name: string;
  state: GroupState;
  visibility: Visibility;
}

enum ProjectPriority {
  A = 'A',
  B = 'B',
  C = 'C',
}

const isProjectPriorityEnum = isEnumGenerator(ProjectPriority);

enum ProjectState {
  INTENT = 'INTENT',
  PROJECT = 'PROJECT',
  PROGRESS = 'PROGRESS',
  FINALIZED = 'FINALIZED',
  CANCELED = 'CANCELED',
}

const isProjectStateEnum = isEnumGenerator(ProjectState);

interface ProjectItem extends GroupItem {
  projectPriority: ProjectPriority;
  projectState: ProjectState;
}

type CommunityItem = GroupItem | ProjectItem;

const groupIcon = 'mdi-account-multiple';

const MCP14_GROUP_ID = 'd73cba88-3dd9-436b-af9b-f01103d4e11f';

export {
  CommunityItem,
  getGroupImageUrl,
  groupIcon,
  GroupItem,
  icons,
  isProjectItem,
  isProjectPriorityEnum,
  isProjectStateEnum,
  Kind,
  MCP14_GROUP_ID,
  ProjectItem,
  ProjectPriority,
  ProjectState,
  userCanCreateEvent,
  userCanCreatePost,
  userCanCreateSurvey,
  userCanEditGroup,
  Visibility,
};
