import { Module, Action, Mutation } from 'vuex-module-decorators';
import AbstractModule from './AbstractModule';
import { isPromise } from '~/utils/typeguards';
import {
  AreaOfInterestCategorisedItem,
  createAreaOfInterestFromApi,
  AreaOfInterestItem,
} from '~/utils/areasOfInterest';

interface StoreDataCommit {
  allItems: AreaOfInterestItem[];
}

interface LoadDataInput {
  forceReload: boolean;
}

@Module({
  name: 'AreasOfInterestModule',
  stateFactory: true,
  namespaced: true,
})
export default class AreasOfInterestModule extends AbstractModule {
  public interests: AreaOfInterestItem[] = [];

  public get categorisedInterests(): AreaOfInterestCategorisedItem[] {
    const items: AreaOfInterestCategorisedItem[] = [];
    const categorisedItems: { [key: string]: AreaOfInterestItem[] } = {};

    [...this.interests]
      .sort((a, b) => {
        return a.category.localeCompare(b.category);
      })
      .forEach((areaOfInterest) => {
        if (!categorisedItems[areaOfInterest.category]) {
          categorisedItems[areaOfInterest.category] = [];
        }
        categorisedItems[areaOfInterest.category].push(areaOfInterest);
      });

    for (const category in categorisedItems) {
      if (categorisedItems.hasOwnProperty(category)) {
        items.push({
          category,
          interests: categorisedItems[category],
        });
      }
    }

    return items;
  }

  protected loadingPromise: Promise<StoreDataCommit> | null = null;

  @Action({ commit: 'storeData', rawError: true })
  loadData(data?: LoadDataInput): Promise<StoreDataCommit> {
    if (isPromise(this.loadingPromise) && (!data || !data.forceReload)) {
      return this.loadingPromise;
    }

    const promise = this.$api.areasOfInterest
      .getAreaOfInterests()
      .then((res) => {
        return {
          allItems: res.map((item) => {
            return createAreaOfInterestFromApi(item);
          }),
        };
      });

    this.setLoadingPromise(promise);
    return promise;
  }

  @Action({ rawError: true })
  public getTitle(id: string): string | null {
    for (const interest of this.interests) {
      if (interest.id === id) {
        return interest.title || null;
      }
    }
    return null;
  }

  @Mutation
  protected storeData(data: StoreDataCommit) {
    this.interests = data.allItems;
  }

  @Mutation
  protected setLoadingPromise(promise: Promise<StoreDataCommit>) {
    this.loadingPromise = promise;
  }
}
