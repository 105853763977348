import { TranslateResult } from 'vue-i18n';
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { VuetifyTypes } from '~/@types/vuetify-types';
import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import GroupDetailModule from '~/app/core/store/modules/GroupDetailModule';
import GroupModule from '~/app/core/store/modules/GroupModule';
import GroupMutationModule, {
  ValidationCommit,
} from '~/app/core/store/modules/GroupMutationModule';
import { CloseButton, FormActionButton } from '~/components/atoms';
import { FormActionIcon } from '~/components/atoms/formActionButton/FormActionButton';
import {
  ErrorSnack,
  ImageUploadPlaceholder,
  SelectPlaceField,
} from '~/components/molecules';
import { ImageSelection } from '~/components/organisms';
import { ImageSelectionEvent } from '~/components/organisms/imageSelection/ImageSelection';
import { SelectPlace } from '~/components/templates';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { createAreaOfInterestSelectItems } from '~/utils/areasOfInterest';
import {
  CommunityItem,
  GroupItem,
  isProjectItem,
  Kind,
  ProjectPriority,
  ProjectState,
  Visibility,
} from '~/utils/group';
import getGroupImageUrl from '~/utils/group/getGroupImageUrl';
import { createGeoLocation } from '~/utils/googleMaps';
import {
  GeoLocation,
  isLocationEmpty,
  isLocationValid,
} from '~/utils/location';
import { componentOffset } from '~/utils/spacing';
import { VueComponentMixin } from '~/utils/vue-component';
import { imageVersion } from '~/utils/media';

export enum FormStep {
  BASIC_INFO,
  META_INFO,
  LOCATION,
}

interface EditGroupFormInterface {
  groupId: GroupItem['id'] | null;
  onCancel?: () => void;
}

interface VuetifyButton extends Vue {
  $el: HTMLButtonElement;
}

export interface FormValues {
  areasOfInterest: string[];
  description: GroupItem['description'];
  image: string;
  kind: Kind;
  location: GeoLocation;
  name: GroupItem['name'];
  projectData: {
    priority: ProjectPriority;
    state: ProjectState;
  };
  visibility: Visibility;
}

function createDefaultFormValues(): FormValues {
  return {
    areasOfInterest: [],
    description: '',
    image: '',
    kind: Kind.GROUP,
    location: createGeoLocation(),
    name: '',
    projectData: {
      priority: ProjectPriority.A,
      state: ProjectState.INTENT,
    },
    visibility: Visibility.PUBLIC,
  };
}

function createFormValuesFromGroupItem(item: CommunityItem): FormValues {
  const formValues = {
    areasOfInterest: item.areasOfInterest.map((area) => area.id),
    description: item.description,
    image: '',
    kind: item.kind,
    location: { ...item.location },
    name: item.name,
    projectData: {
      priority: ProjectPriority.A,
      state: ProjectState.INTENT,
    },
    visibility: item.visibility,
  };

  if (isProjectItem(item)) {
    formValues.projectData = {
      priority: item.projectPriority,
      state: item.projectState,
    };
  }

  return formValues;
}

interface SnackErrors {
  groupNotFound: boolean;
  navigationFailed: boolean;
  groupNotCreated: boolean;
  groupNotUpdated: boolean;
}

@Component
export default class EditGroupForm
  extends VueComponentMixin<EditGroupFormInterface, PrefetchComponent>(Prefetch)
  implements EditGroupFormInterface {
  @Prop({ default: null })
  public groupId!: GroupItem['id'] | null;

  @Ref('submitButton')
  protected readonly submitButton?: VuetifyButton;

  @Ref('form')
  protected readonly form?: VuetifyTypes.Form;

  protected areasOfInterestSearchTerm: string = '';

  protected errors: SnackErrors = {
    groupNotFound: false,
    navigationFailed: false,
    groupNotCreated: false,
    groupNotUpdated: false,
  };

  protected formStep: FormStep = FormStep.BASIC_INFO;

  protected formValues: FormValues = createDefaultFormValues();

  /**
   * Used for loading group data before mutation happens
   * @protected
   */
  protected immutableFormValues: FormValues = createDefaultFormValues();

  protected initializing: boolean = false;

  protected loading: boolean = false;

  protected loadingNextStep: boolean = false;

  protected loadAreasOfInterestFromAi: boolean = true;

  protected locationWasUpdated: boolean = false;

  protected validationErrors: ValidationCommit | null = null;

  protected get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  public get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  public get groupDetailStore() {
    return getModule(GroupDetailModule, this.$store);
  }

  public get groupMutationStore() {
    return getModule(GroupMutationModule, this.$store);
  }

  protected get areasOfInterestSelectItems() {
    return createAreaOfInterestSelectItems(
      this.areasOfInterestStore.categorisedInterests
    );
  }

  protected get allErrorMessages() {
    const messages: TranslateResult[] = [];
    if (!this.validationErrors) {
      return messages;
    }

    if (this.validationErrors.api.length > 0) {
      this.validationErrors.api.forEach((message) => {
        messages.push(this.$t(message));
      });
    }

    if (this.validationErrors.description.length > 0) {
      this.validationErrors.description.forEach((message) => {
        messages.push(this.$t(message));
      });
    }

    if (this.validationErrors.location.length > 0) {
      this.validationErrors.location.forEach((message) => {
        messages.push(this.$t(message));
      });
    }

    if (this.validationErrors.name.length > 0) {
      this.validationErrors.name.forEach((message) => {
        messages.push(this.$t(message));
      });
    }

    if (this.validationErrors.projectPriority.length > 0) {
      this.validationErrors.projectPriority.forEach((message) => {
        messages.push(this.$t(message));
      });
    }

    if (this.validationErrors.projectState.length > 0) {
      this.validationErrors.projectState.forEach((message) => {
        messages.push(this.$t(message));
      });
    }

    return messages;
  }

  protected get backwardLabel() {
    if (this.isFirstStep) {
      return this.$t('app.common.cancel');
    }

    if (this.formStep === FormStep.LOCATION) {
      return this.$t('app.place.reset');
    }

    return this.$t('app.common.back');
  }

  protected get forwardLabel() {
    if (this.isFinalStep) {
      return this.$t('app.common.finalize');
    }

    if (this.formStep === FormStep.LOCATION) {
      return this.$t('app.place.submit');
    }

    return this.$t('app.common.next');
  }

  protected get hasImage(): boolean {
    // TODO: API needs to inform us whether the image exists on the blob storage
    return !!this.formValues.image;
  }

  protected get imageUrl(): string {
    if (this.formValues.image) {
      return this.formValues.image;
    }

    if (this.groupId) {
      return getGroupImageUrl(this.groupId, imageVersion());
    }

    return '';
  }

  protected get isFinalStep() {
    return this.formStep === FormStep.META_INFO;
  }

  protected get isFirstStep() {
    return this.formStep === FormStep.BASIC_INFO;
  }

  protected get isLocationValid() {
    return (
      isLocationEmpty(this.formValues.location) ||
      isLocationValid(this.formValues.location)
    );
  }

  protected get isMetaStepDisabled() {
    if (!this.groupId && this.formValues.image.trim() === '') {
      return true;
    }

    if (
      this.formValues.name.trim() === '' ||
      this.formValues.description.trim() === ''
    ) {
      // Content and title must not be empty
      return true;
    }

    if (
      this.formValues.kind === Kind.PROJECT &&
      isLocationEmpty(this.formValues.location)
    ) {
      return true;
    }

    return !this.isLocationValid;
  }

  protected get isNextStepActive(): boolean {
    if (this.formStep === FormStep.BASIC_INFO && this.isMetaStepDisabled) {
      return false;
    }

    if (this.formStep === FormStep.META_INFO && this.isSubmissionDisabled) {
      return false;
    }

    if (this.formStep === FormStep.LOCATION && !this.isLocationValid) {
      return false;
    }

    return true;
  }

  protected get isSubmissionDisabled() {
    if (this.isMetaStepDisabled) {
      return true;
    }

    // At least one area of interest must be selected
    return this.formValues.areasOfInterest.length < 1;
  }

  protected get placeLabel() {
    if (this.formValues.kind === Kind.PROJECT) {
      // Place is required in projects
      return `${this.$t('app.project.place')}*`;
    }

    return this.$t('app.group.place');
  }

  protected get tabStep() {
    if (this.formStep === FormStep.LOCATION) {
      return FormStep.BASIC_INFO;
    }

    return this.formStep;
  }

  protected get title() {
    if (this.groupId) {
      return this.getTranslation('update');
    }

    return this.$t('app.communities.create');
  }

  public created() {
    this.setInitialData();
  }

  public prefetch() {
    return this.areasOfInterestStore.loadData().catch(() => {
      // not much to do here
    });
  }

  public render() {
    return (
      <v-card loading={this.initializing} disabled={this.initializing}>
        <v-card-title>
          {this.title}
          <v-spacer />
          <CloseButton onClose={this.cancel} />
        </v-card-title>
        <v-tabs value={this.tabStep} fixed-tabs>
          <v-tab onClick={this.goToBasicInfo}>
            {this.getTranslation('basicDataStep')}
          </v-tab>
          <v-tab disabled={this.isMetaStepDisabled} onClick={this.goToMeta}>
            {this.getTranslation('metaDataStep')}
          </v-tab>
        </v-tabs>

        <v-divider />

        <v-card-text class={`py-${componentOffset}`}>
          <v-form
            ref='form'
            onSubmit={this.groupId ? this.submitUpdate : this.submitCreate}
            transition='scale-transition'
          >
            <v-skeleton-loader loading={this.initializing} type='card'>
              <v-tabs-items value={this.formStep}>
                <v-tab-item value={FormStep.BASIC_INFO}>
                  <v-img
                    src={this.imageUrl}
                    class={`mx-auto mb-${componentOffset}`}
                    aspect-ratio={5 / 2}
                    max-width={660}
                    key={`edit-group-form-image-${
                      this.hasImage ? 'filled-in' : 'empty'
                    }`}
                  >
                    <ImageUploadPlaceholder slot='placeholder' />
                    <ImageSelection
                      onSelected={this.setMainPhoto}
                      absolute={true}
                    >
                      {this.$t(
                        this.hasImage
                          ? 'app.common.updatePhoto'
                          : 'app.common.addPhoto'
                      )}
                      {this.groupId ? '' : '*'}
                    </ImageSelection>
                  </v-img>
                  {!this.groupId && (
                    <v-row class='pt-2'>
                      <v-col cols='12'>
                        <v-radio-group row v-model={this.formValues.kind}>
                          <div slot='label'>
                            {this.$t('app.communities.kind')}:
                          </div>
                          <v-radio
                            label={this.$t('app.group.kind')}
                            value={Kind.GROUP}
                          />
                          <v-radio
                            label={this.$t('app.project.kind')}
                            value={Kind.PROJECT}
                          />
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  )}
                  <v-row no-gutters justify='center' class='pt-2'>
                    <v-col cols='12'>
                      <v-text-field
                        filled
                        label={this.getTranslation('name') + '*'}
                        required
                        rounded
                        rules={[this.nameIsRequiredRule]}
                        v-model={this.formValues.name}
                      />
                      <v-textarea
                        filled
                        label={this.getTranslation('description') + '*'}
                        required
                        rounded
                        rules={[this.descriptionIsRequiredRule]}
                        v-model={this.formValues.description}
                      />
                      <SelectPlaceField
                        label={this.placeLabel}
                        onChooseLocation={this.goToLocation}
                        v-model={this.formValues.location}
                      />
                      {this.locationWasUpdated && !this.isLocationValid && (
                        <v-alert type='warning'>
                          {this.$t('app.error.group.fixLocation')}
                        </v-alert>
                      )}
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    {this.formValues.kind === Kind.PROJECT && (
                      <v-row class='pt-2'>
                        <v-col cols='12'>
                          <v-radio-group
                            row
                            v-model={this.formValues.projectData.state}
                          >
                            <div slot='label'>
                              {this.$t('app.project.state.label')}:
                            </div>
                            <v-radio
                              label={this.$t(
                                `app.project.state.enumMap.${ProjectState.INTENT}`
                              )}
                              value={ProjectState.INTENT}
                            />
                            <v-radio
                              label={this.$t(
                                `app.project.state.enumMap.${ProjectState.PROJECT}`
                              )}
                              value={ProjectState.PROJECT}
                            />
                            <v-radio
                              label={this.$t(
                                `app.project.state.enumMap.${ProjectState.PROGRESS}`
                              )}
                              value={ProjectState.PROGRESS}
                            />
                            <v-radio
                              label={this.$t(
                                `app.project.state.enumMap.${ProjectState.FINALIZED}`
                              )}
                              value={ProjectState.FINALIZED}
                            />
                            <v-radio
                              label={this.$t(
                                `app.project.state.enumMap.${ProjectState.CANCELED}`
                              )}
                              value={ProjectState.CANCELED}
                            />
                          </v-radio-group>
                        </v-col>
                        <v-col cols='12'>
                          <v-radio-group
                            row
                            v-model={this.formValues.projectData.priority}
                          >
                            <div slot='label'>
                              {this.$t('app.project.priority.label')}:
                            </div>
                            <v-radio
                              label={this.$t(
                                `app.project.priority.enumMap.${ProjectPriority.A}`
                              )}
                              value={ProjectPriority.A}
                            />
                            <v-radio
                              label={this.$t(
                                `app.project.priority.enumMap.${ProjectPriority.B}`
                              )}
                              value={ProjectPriority.B}
                            />
                            <v-radio
                              label={this.$t(
                                `app.project.priority.enumMap.${ProjectPriority.C}`
                              )}
                              value={ProjectPriority.C}
                            />
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    )}
                  </v-expand-transition>
                </v-tab-item>
                <v-tab-item value={FormStep.LOCATION}>
                  <SelectPlace
                    groupId={this.groupId || undefined}
                    v-model={this.formValues.location}
                    onInput={() => {
                      this.locationWasUpdated = true;
                    }}
                  />
                </v-tab-item>
                <v-tab-item value={FormStep.META_INFO}>
                  <v-autocomplete
                    autocomplete='off'
                    chips
                    deletable-chips
                    items={this.areasOfInterestSelectItems}
                    label={this.getTranslation('areasOfInterest') + '*'}
                    multiple
                    no-data-text={this.$t('app.common.noDataText')}
                    outlined
                    rounded
                    rules={[this.areasAreRequiredRule]}
                    search-input={this.areasOfInterestSearchTerm}
                    small-chips
                    v-model={this.formValues.areasOfInterest}
                    {...{
                      on: {
                        input: () => {
                          this.loadAreasOfInterestFromAi = false;
                          this.areasOfInterestSearchTerm = '';
                        },
                        'update:search-input': (value: string) => {
                          this.areasOfInterestSearchTerm = value;
                        },
                      },
                    }}
                  />
                  <h4>{this.getTranslation('visibility')}*</h4>
                  <v-radio-group v-model={this.formValues.visibility}>
                    <v-radio
                      label={this.getTranslation('public')}
                      value={Visibility.PUBLIC}
                      class='mb-0'
                    />
                    <div class='pl-5 mb-2'>
                      {this.getTranslation('publicHint')}
                    </div>
                    <v-radio
                      label={this.getTranslation('private')}
                      value={Visibility.PRIVATE}
                      class='mb-0'
                    />
                    <div class='pl-5 mb-2'>
                      {this.getTranslation('privateHint')}
                    </div>
                  </v-radio-group>
                </v-tab-item>
              </v-tabs-items>
            </v-skeleton-loader>
            <v-btn ref='submitButton' class='d-none' type='submit' />
          </v-form>
        </v-card-text>
        <v-divider />
        {this.allErrorMessages.map((message) => (
          <v-alert type='error'>{message}</v-alert>
        ))}
        <v-card-actions>
          <FormActionButton
            disabled={this.loadingNextStep}
            icon={this.isFirstStep ? undefined : FormActionIcon.LEFT}
            onClick={this.goBack}
          >
            {this.backwardLabel}
          </FormActionButton>
          <v-spacer />
          <FormActionButton
            disabled={!this.isNextStepActive}
            icon={this.isFinalStep ? undefined : FormActionIcon.RIGHT}
            loading={this.loadingNextStep || this.loading}
            onClick={this.goForward}
          >
            {this.forwardLabel}
          </FormActionButton>
        </v-card-actions>
        <ErrorSnack v-model={this.errors.groupNotFound}>
          {this.$t('app.error.group.notFound')}
        </ErrorSnack>
        <ErrorSnack v-model={this.errors.navigationFailed}>
          {this.$t('app.error.generic.navigationFailure')}
        </ErrorSnack>
        <ErrorSnack v-model={this.errors.groupNotCreated}>
          {this.$t('app.error.group.notCreated')}
        </ErrorSnack>
        <ErrorSnack v-model={this.errors.groupNotUpdated}>
          {this.$t('app.error.group.notUpdated')}
        </ErrorSnack>
      </v-card>
    );
  }

  protected submitCreate(e: Event) {
    e.preventDefault();

    if (this.isSubmissionDisabled) {
      if (!this.isFinalStep) {
        this.goForward();
      }

      return;
    }

    this.loading = true;

    this.groupMutationStore
      .create({
        data: this.formValues,
      })
      .catch((error: ValidationCommit) => {
        this.validationErrors = error;
        throw new Error('Not created');
      })
      .then((group) => {
        return this.$router
          .push(
            getLocalizedLocation(routes.groupDetail, this.$router, {
              guid: group.id,
            })
          )
          .catch(() => {
            this.errors.navigationFailed = true;
          })
          .then(() => {
            this.groupStore.initItems({ forceReload: true });
            this.cancel();
          });
      })
      .catch(() => {
        this.errors.groupNotCreated = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  protected submitUpdate(e: Event) {
    e.preventDefault();

    if (!this.groupId) {
      return;
    }

    if (this.isSubmissionDisabled) {
      if (!this.isFinalStep) {
        this.goForward();
      }
      return;
    }

    this.loading = true;

    const groupId = this.groupId;
    this.groupMutationStore
      .update({
        data: this.formValues,
        groupId,
      })
      .catch((error: ValidationCommit) => {
        this.validationErrors = error;
        throw new Error('Not updated');
      })
      .then((group) => {
        if (this.formValues.image) {
          // Hack to force refresh the image
          window.location.reload();
          return;
        }

        return this.groupDetailStore.loadData(group.id).then(() => {
          this.groupStore.initItems({ forceReload: true });
          this.cancel();
        });
      })
      .catch(() => {
        this.errors.groupNotUpdated = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @Emit('cancel')
  protected cancel() {
    this.setInitialData();
    return;
  }

  /**
   * Helper function to get translation based on community kind
   * @param translationKey
   * @protected
   */
  protected getTranslation(translationKey: string): TranslateResult {
    return this.$t(
      `app.${this.formValues.kind.toLowerCase()}.${translationKey}`
    );
  }

  protected goBack() {
    if (this.isFirstStep) {
      return this.cancel();
    }

    switch (this.formStep) {
      case FormStep.META_INFO:
        this.goToBasicInfo();
        break;
      case FormStep.LOCATION:
        // Reset location
        this.formValues.location = { ...this.immutableFormValues.location };
        this.goToBasicInfo();
        break;
      case FormStep.BASIC_INFO:
        this.goToMeta();
        break;
    }
  }

  protected goForward() {
    if (this.isFinalStep) {
      if (!this.submitButton) {
        return;
      }

      return this.submitButton.$el.click();
    }

    switch (this.formStep) {
      case FormStep.BASIC_INFO:
        if (!this.isMetaStepDisabled) {
          this.goToMeta();
        }
        break;
      case FormStep.LOCATION:
        this.goToBasicInfo();
        break;
    }
  }

  protected goToBasicInfo() {
    this.formStep = FormStep.BASIC_INFO;
  }

  protected goToLocation() {
    this.formStep = FormStep.LOCATION;
  }

  protected goToMeta() {
    if (this.formStep === FormStep.META_INFO) {
      return;
    }

    if (!this.loadAreasOfInterestFromAi) {
      this.formStep = FormStep.META_INFO;
      return;
    }

    this.loadingNextStep = true;
    let aiContentRequest = '';

    if (this.formValues.name) {
      aiContentRequest += `${this.formValues.name}. `;
    }

    if (this.formValues.description) {
      aiContentRequest += `${this.formValues.description}`;
    }

    this.$api.ai
      .areas(aiContentRequest)
      .then((result) => {
        this.formValues.areasOfInterest = result;
      })
      .finally(() => {
        this.loadingNextStep = false;
        this.formStep = FormStep.META_INFO;
      });
  }

  @Watch('groupId')
  protected setInitialData() {
    this.initializing = true;
    this.formStep = FormStep.BASIC_INFO;
    this.formValues = createDefaultFormValues();
    this.immutableFormValues = createDefaultFormValues();
    this.locationWasUpdated = false;
    // Do not load interests if we are editing a group
    this.loadAreasOfInterestFromAi = !this.groupId;
    if (this.form) {
      this.form.resetValidation();
    }

    if (!this.groupId) {
      this.initializing = false;
      return;
    }

    return this.groupStore
      .load({ groupId: this.groupId })
      .then((data) => {
        const group = data.length > 0 ? data[0] : null;
        if (!group) {
          this.formValues = createDefaultFormValues();
          this.immutableFormValues = createDefaultFormValues();
        } else {
          this.formValues = createFormValuesFromGroupItem(group);
          this.immutableFormValues = createFormValuesFromGroupItem(group);
        }
      })
      .finally(() => {
        this.initializing = false;
      });
  }

  protected setMainPhoto(data: ImageSelectionEvent) {
    if (data.firstImage) {
      this.formValues.image = data.firstImage;
    }
  }

  protected nameIsRequiredRule(input: string): boolean | TranslateResult {
    if (input.trim() === '') {
      return this.$t('app.error.group.nameIsRequired');
    }

    return true;
  }

  protected descriptionIsRequiredRule(
    input: string
  ): boolean | TranslateResult {
    if (input.trim() === '') {
      return this.$t('app.error.group.descriptionIsRequired');
    }

    return true;
  }

  protected areasAreRequiredRule(input: any): boolean | TranslateResult {
    if (input.length < 1) {
      return this.$t('app.error.group.areasAreRequired');
    }

    return true;
  }
}
