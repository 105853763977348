import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import AbstractModule from './AbstractModule';
import GroupModule from '~/app/core/store/modules/GroupModule';
import { createPostItem, PostItem } from '~/utils/post';
import { itemIsNotNull } from '~/utils/typeguards';
import { AreaOfInterestItem } from '~/utils/areasOfInterest';

type GetItemsCommit = PostItem[];

interface GetItemsInput {
  areasOfInterest: AreaOfInterestItem[];
  groupId?: string;
}
@Module({
  name: 'PostListModule',
  stateFactory: true,
  namespaced: true,
})
export default class PostListModule extends AbstractModule {
  public lastPage: boolean = true;

  public loading: boolean = false;

  public postIds: PostItem['id'][] = [];

  protected pageNumber: number = 0;

  @Action({ rawError: true })
  public getItems(data: GetItemsInput): Promise<GetItemsCommit> {
    this.setLoading(true);
    let promise;
    if (data.groupId) {
      promise = this.$api.communities.listPostsForCommunity(
        data.groupId,
        undefined,
        this.pageNumber,
        5
      );
    } else {
      promise = this.$api.communities.listPostsForCommunities(
        undefined,
        this.pageNumber,
        5
      );
    }

    return promise
      .then((result) => {
        const items = result.content
          ? result.content
              .map((item) => createPostItem(item, data.areasOfInterest))
              .filter(itemIsNotNull)
          : [];

        return getModule(GroupModule, this.store)
          .loadUncachedIds({ requestedIds: items.map((item) => item.group.id) })
          .then(() => {
            if (this.pageNumber === 0) {
              this.setItems(items.map((item) => item.id));
            } else {
              this.concatItems(items.map((item) => item.id));
            }
            this.setLastPage(result.last !== false);
            this.setPageNumber(this.pageNumber + 1);

            return items;
          });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  public setItems(data: PostItem['id'][]) {
    this.postIds = data;
  }

  @Mutation
  public concatItems(data: PostItem['id'][]) {
    this.postIds = this.postIds.concat(data);
  }

  @Mutation
  protected setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  protected setLastPage(data: boolean) {
    this.lastPage = data;
  }

  @Mutation
  public setPageNumber(data: number) {
    this.pageNumber = data;
  }
}
