import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './GroupTile.scss';
import { GroupImage } from '~/components/molecules';
import { GroupItem } from '~/utils/group';
import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';
import { greyBackgroundColor } from '~/utils/theme/colors';
import { GroupImageContext } from '~/components/molecules/groupImage/GroupImage';

const rootClass = 'ys-group-tile';

export interface GroupTileInterface {
  group: GroupItem;
  thin?: boolean;
}

@Component({
  style,
})
export default class GroupTile extends VueComponent<GroupTileInterface>
  implements GroupTileInterface {
  @Prop({ required: true })
  public group!: GroupItem;

  protected get route() {
    return getLocalizedLocation(routes.groupDetail, this.$router, {
      guid: this.group.id,
    });
  }

  protected get joined(): boolean {
    return this.group.membershipType !== null;
  }

  public render() {
    return (
      <v-card class={`${rootClass} text-center d-flex flex-column`}>
        <router-link to={this.route} class='flex-grow-0 flex-shrink-0'>
          <GroupImage group={this.group} context={GroupImageContext.TILE} />
        </router-link>
        <v-container
          class={`${rootClass}__content d-flex flex-column flex-grow-1`}
        >
          <router-link tag='h3' class={`${rootClass}__title`} to={this.route}>
            {this.group.name}
          </router-link>
          {this.$slots.default}
        </v-container>
      </v-card>
    );
  }
}
