import { TranslateResult } from 'vue-i18n';
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import {
  CzYsP14KcPostApiDtoPostCreationObject,
  CzYsP14KcPostApiDtoPostCreationObjectImageInfo,
  CzYsP14KcPostApiDtoPostUpdateObjectImageInfo,
} from '~/app/core/apiClient/api';
import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import GroupModule from '~/app/core/store/modules/GroupModule';
import PostsModule from '~/app/core/store/modules/PostsModule';
import groupPlacehodler from '~/assets/images/placeholder/survey-group_350x180.png';
import { CloseButton, FormActionButton } from '~/components/atoms';
import { FormActionIcon } from '~/components/atoms/formActionButton/FormActionButton';
import {
  ErrorSnack,
  GroupCard,
  ImageUploadPlaceholder,
  SelectGroupItem,
} from '~/components/molecules';
import { ImageSelection } from '~/components/organisms';
import { ImageSelectionEvent } from '~/components/organisms/imageSelection/ImageSelection';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import VisibilityEnum = CzYsP14KcPostApiDtoPostCreationObject.VisibilityEnum;
import {
  AreaOfInterestItem,
  createAreaOfInterestSelectItems,
} from '~/utils/areasOfInterest';
import { GroupItem, userCanCreatePost } from '~/utils/group';
import { PostItem } from '~/utils/post';
import getPostImageUrl from '~/utils/post/getPostImageUrl';
import { componentOffset } from '~/utils/spacing';
import { deleteColor } from '~/utils/theme/colors';
import { VueComponentMixin } from '~/utils/vue-component';
import { ImageItem, imageVersion } from '~/utils/media';

export enum FormStep {
  GROUP_CHOICE,
  BASIC_INFO,
  META_INFO,
}

interface EditPostFormInterface {
  postId: PostItem['id'] | null;
  groupId: GroupItem['id'] | null;
  onCancel?: () => void;
}

interface VuetifyButton extends Vue {
  $el: HTMLButtonElement;
}

interface FormValues {
  areasOfInterest: AreaOfInterestItem['id'][];
  content: PostItem['content'];
  image: string;
  images: ImageItem[];
  title: PostItem['title'];
  visibility: CzYsP14KcPostApiDtoPostCreationObject.VisibilityEnum;
}

function createDefaultFormValues(): FormValues {
  return {
    areasOfInterest: [],
    content: '',
    image: '',
    images: [],
    title: '',
    visibility: VisibilityEnum.PUBLIC,
  };
}

interface SnackErrors {
  groupNotFound: boolean;
  navigationFailed: boolean;
  postNotCreated: boolean;
  postNotUpdated: boolean;
}

interface UpdatedImage {
  id: string | null;
  data: string | null;
}

@Component
export default class EditPostForm
  extends VueComponentMixin<EditPostFormInterface, PrefetchComponent>(Prefetch)
  implements EditPostFormInterface {
  @Prop({ default: null })
  public postId!: PostItem['id'] | null;

  @Prop({ default: null })
  public groupId!: GroupItem['id'] | null;

  @Ref('submitButton')
  protected readonly submitButton!: VuetifyButton;

  protected areasOfInterestSearchTerm: string = '';

  protected loading: boolean = false;

  protected formStep: FormStep = FormStep.GROUP_CHOICE;

  protected formValues: FormValues = createDefaultFormValues();

  protected loadAreasOfInterestFromAi: boolean = true;

  protected loadingNextStep: boolean = false;

  protected selectedGroupId: GroupItem['id'] | null = null;

  protected updatedImages: UpdatedImage[] = [];

  protected errors: SnackErrors = {
    groupNotFound: false,
    navigationFailed: false,
    postNotCreated: false,
    postNotUpdated: false,
  };

  protected get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  public get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  public get postsStore() {
    return getModule(PostsModule, this.$store);
  }

  protected get areasOfInterestSelectItems() {
    return createAreaOfInterestSelectItems(
      this.areasOfInterestStore.categorisedInterests
    );
  }

  protected get group(): GroupItem | null {
    if (this.selectedGroupId) {
      for (const groupItem of this.groupStore.groupCache) {
        if (groupItem.id === this.selectedGroupId) {
          return groupItem;
        }
      }
    }

    return null;
  }

  protected get hasImage(): boolean {
    // TODO: API needs to inform us whether the image exists on the blob storage
    return !!this.formValues.image;
  }

  protected get imageUrl(): string {
    if (this.formValues.image) {
      return this.formValues.image;
    }

    if (this.postId) {
      return getPostImageUrl(this.postId, imageVersion());
    }

    return '';
  }

  protected get isBasicStepDisabled() {
    // Group must be chosen
    return !this.selectedGroupId;
  }

  protected get isFinalStep() {
    return this.formStep === FormStep.META_INFO;
  }

  protected get isFirstStep() {
    if (this.postId) {
      // We already have a post, so we cannot show the group choice
      return this.formStep === FormStep.BASIC_INFO;
    }

    return this.formStep === FormStep.GROUP_CHOICE;
  }

  protected get isMetaStepDisabled() {
    if (this.isBasicStepDisabled) {
      return true;
    }

    if (
      this.formValues.title.trim() === '' ||
      this.formValues.content.trim() === ''
    ) {
      // Content and title must not be empty
      return true;
    }

    return false;
  }

  protected get isNextStepActive(): boolean {
    if (this.formStep === FormStep.GROUP_CHOICE && this.isBasicStepDisabled) {
      return false;
    }

    if (this.formStep === FormStep.BASIC_INFO && this.isMetaStepDisabled) {
      return false;
    }

    if (this.formStep === FormStep.META_INFO && this.isSubmissionDisabled) {
      return false;
    }

    return true;
  }

  protected get isSubmissionDisabled() {
    if (this.isMetaStepDisabled) {
      return true;
    }

    // At least one area of interest must be selected
    return this.formValues.areasOfInterest.length < 1;
  }

  protected get managedGroups(): GroupItem[] {
    return this.groupStore.items.filter(userCanCreatePost);
  }

  protected get title() {
    if (this.postId) {
      return this.$t('app.posts.update');
    }

    return this.$t('app.posts.create');
  }

  protected get backwardLabel() {
    if (this.isFirstStep) {
      return this.$t('app.common.cancel');
    }

    return this.$t('app.common.back');
  }

  protected get forwardLabel() {
    if (this.isFinalStep) {
      return this.$t('app.common.finalize');
    }

    return this.$t('app.common.next');
  }

  public created() {
    this.setInitialData();
  }

  public prefetch() {
    return this.areasOfInterestStore.loadData().catch(() => {
      // not much to do here
    });
  }

  public render() {
    return (
      <v-card>
        <v-card-title>
          {this.title}
          <v-spacer />
          <CloseButton onClose={this.cancel} />
        </v-card-title>
        <v-card-subtitle class='py-2'>
          {this.group && <GroupCard group={this.group} disableLink={true} />}
        </v-card-subtitle>
        <v-tabs value={this.formStep} fixed-tabs>
          <v-tab
            class={this.postId ? 'd-none' : ''}
            onClick={this.goToGroupSelection}
          >
            {this.$t('app.communities.chooseCommunityStep')}
          </v-tab>
          <v-tab
            disabled={this.isBasicStepDisabled}
            onClick={this.goToBasicInfo}
          >
            {this.$t('app.posts.basicDataStep')}
          </v-tab>
          <v-tab disabled={this.isMetaStepDisabled} onClick={this.goToMeta}>
            {this.$t('app.posts.metaDataStep')}
          </v-tab>
        </v-tabs>

        <v-divider />

        <v-card-text class={`py-${componentOffset}`}>
          <v-form ref='form' onSubmit={this.submitHandler}>
            <v-tabs-items value={this.formStep}>
              <v-tab-item value={FormStep.GROUP_CHOICE}>
                <v-row justify='center' no-gutters>
                  <v-img src={groupPlacehodler} max-width={200} />
                </v-row>
                <div class='text-center'>
                  {this.$t('app.posts.chooseGroup')}
                </div>
                {this.managedGroups.map((group) => {
                  return (
                    <SelectGroupItem
                      name={group.name}
                      onSelected={() => {
                        this.selectGroup(group.id);
                      }}
                    />
                  );
                })}
                {this.managedGroups.length < 1 && (
                  <v-alert type='warning'>
                    {this.$t('app.error.post.noGroupsToCreatePost')}
                  </v-alert>
                )}
              </v-tab-item>
              <v-tab-item value={FormStep.BASIC_INFO}>
                <v-img
                  src={this.imageUrl}
                  class={`mx-auto mb-${componentOffset}`}
                  aspect-ratio={5 / 2}
                  max-width={660}
                  key={`edit-event-form-image-${
                    this.hasImage ? 'filled-in' : 'empty'
                  }`}
                >
                  <ImageUploadPlaceholder slot='placeholder' />
                  <ImageSelection
                    onSelected={this.setMainPhoto}
                    absolute={true}
                  >
                    {this.$t(
                      this.hasImage
                        ? 'app.common.updatePhoto'
                        : 'app.common.addPhoto'
                    )}
                  </ImageSelection>
                </v-img>
                <v-row no-gutters justify='center' class='py-2'></v-row>
                <v-text-field
                  filled
                  label={this.$t('app.post.title') + '*'}
                  required
                  rounded
                  rules={[this.titleIsRequiredRule]}
                  v-model={this.formValues.title}
                />
                <v-textarea
                  filled
                  label={this.$t('app.post.content') + '*'}
                  required
                  rounded
                  rules={[this.contentIsRequiredRule]}
                  v-model={this.formValues.content}
                />
                <ImageSelection multiple textButton onSelected={this.addPhotos}>
                  {this.$t('app.common.addPhotos')}
                </ImageSelection>
                <v-row>
                  {this.formValues.images.map((image, index) => {
                    return (
                      <v-col
                        cols='6'
                        md='3'
                        style='position:relative'
                        key={`edit-post-form-gallery-${index}`}
                      >
                        <v-row
                          class='fill-height grey'
                          align='center'
                          no-gutters
                        >
                          <v-img src={image.src} />
                          <v-btn
                            absolute
                            top
                            right
                            icon
                            color='white'
                            onClick={() => {
                              // Delete image
                              Vue.delete(this.formValues.images, index);

                              if (image.id) {
                                let foundImage = false;
                                for (const updatedImage in this.updatedImages) {
                                  if (
                                    this.updatedImages[updatedImage].id ===
                                    image.id
                                  ) {
                                    this.updatedImages[
                                      updatedImage
                                    ].data = null;
                                    foundImage = true;
                                    break;
                                  }
                                }
                                if (!foundImage) {
                                  this.updatedImages.push({
                                    id: image.id,
                                    data: null,
                                  });
                                }
                              }
                            }}
                          >
                            <v-icon style={{ textShadow: '0 0 2px black' }}>
                              mdi-trash-can
                            </v-icon>
                          </v-btn>
                          {this.postId && (
                            <ImageSelection
                              absolute
                              color='white'
                              icon='mdi-image'
                              onSelected={(data) => {
                                if (!data.firstImage || !image.id) {
                                  return;
                                }

                                Vue.set(this.formValues.images, index, {
                                  ...this.formValues.images[index],
                                  src: data.firstImage,
                                });

                                let updatedImage = false;

                                for (const key in this.updatedImages) {
                                  if (this.updatedImages[key].id === image.id) {
                                    Vue.set(this.updatedImages, key, {
                                      ...this.updatedImages[key],
                                      data: data.firstImage,
                                    });
                                    updatedImage = true;
                                    break;
                                  }
                                }

                                if (!updatedImage) {
                                  this.updatedImages.push({
                                    data: data.firstImage,
                                    id: image.id,
                                  });
                                }
                              }}
                              style='top:50%;left:50%;transform:translate3d(-50%,-50%,0)'
                              textButton
                            >
                              <div style='text-shadow: 0 1px 2px black;'>
                                {this.$t('app.common.replacePhoto')}
                              </div>
                            </ImageSelection>
                          )}
                        </v-row>
                      </v-col>
                    );
                  })}
                </v-row>
              </v-tab-item>
              <v-tab-item value={FormStep.META_INFO}>
                <v-autocomplete
                  autocomplete='off'
                  chips
                  deletable-chips
                  items={this.areasOfInterestSelectItems}
                  label={this.$t('app.post.areasOfInterest') + '*'}
                  multiple
                  no-data-text={this.$t('app.common.noDataText')}
                  outlined
                  rounded
                  rules={[this.areasAreRequiredRule]}
                  search-input={this.areasOfInterestSearchTerm}
                  small-chips
                  v-model={this.formValues.areasOfInterest}
                  {...{
                    on: {
                      input: () => {
                        // Once areas are changed, do not load interests anymore
                        this.loadAreasOfInterestFromAi = false;
                        this.areasOfInterestSearchTerm = '';
                      },
                      'update:search-input': (value: string) => {
                        this.areasOfInterestSearchTerm = value;
                      },
                    },
                  }}
                />
                <h4>{this.$t('app.post.visibility')}*</h4>
                <v-radio-group v-model={this.formValues.visibility}>
                  <v-radio
                    label={this.$t('app.post.public')}
                    value={VisibilityEnum.PUBLIC}
                    class='mb-0'
                  />
                  <div class='pl-5 mb-2'>{this.$t('app.post.publicHint')}</div>
                  <v-radio
                    label={this.$t('app.post.private')}
                    value={VisibilityEnum.PRIVATE}
                    class='mb-0'
                  />
                  <div class='pl-5 mb-2'>{this.$t('app.post.privateHint')}</div>
                </v-radio-group>
              </v-tab-item>
            </v-tabs-items>
            <v-btn ref='submitButton' class='d-none' type='submit' />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <FormActionButton
            disabled={this.loadingNextStep}
            icon={this.isFirstStep ? undefined : FormActionIcon.LEFT}
            onClick={this.goBack}
          >
            {this.backwardLabel}
          </FormActionButton>
          <v-spacer />
          <FormActionButton
            disabled={!this.isNextStepActive}
            icon={this.isFinalStep ? undefined : FormActionIcon.RIGHT}
            loading={this.loadingNextStep || this.loading}
            onClick={this.goForward}
          >
            {this.forwardLabel}
          </FormActionButton>
        </v-card-actions>
        <ErrorSnack v-model={this.errors.groupNotFound}>
          {this.$t('app.error.group.notFound')}
        </ErrorSnack>
        <ErrorSnack v-model={this.errors.navigationFailed}>
          {this.$t('app.error.generic.navigationFailure')}
        </ErrorSnack>
        <ErrorSnack v-model={this.errors.postNotCreated}>
          {this.$t('app.error.post.notCreated')}
        </ErrorSnack>
        <ErrorSnack v-model={this.errors.postNotUpdated}>
          {this.$t('app.error.post.notUpdated')}
        </ErrorSnack>
      </v-card>
    );
  }

  protected submitHandler(e: Event) {
    e.preventDefault();

    if (!this.selectedGroupId) {
      return;
    }

    if (this.isSubmissionDisabled) {
      if (!this.isFinalStep) {
        this.goForward();
      }

      return;
    }

    this.loading = true;

    // Check whether create new or patch existing post
    if (!this.postId) {
      // Create new post
      const allImages: CzYsP14KcPostApiDtoPostCreationObjectImageInfo[] = [];

      // Main picture must go first
      if (this.formValues.image) {
        allImages.push({
          data: this.formValues.image,
        });
      }

      if (this.formValues.images.length) {
        this.formValues.images.forEach((image) => {
          allImages.push({
            data: image.src,
          });
        });
      }

      this.postsStore
        .create({
          allAreasOfInterest: this.areasOfInterestStore.interests,
          data: {
            areasOfInterest: this.formValues.areasOfInterest,
            content: this.formValues.content,
            images: allImages,
            title: this.formValues.title,
            visibility: this.formValues.visibility,
          },
          groupId: this.selectedGroupId,
        })
        .then((post) => {
          return this.$router
            .push(
              getLocalizedLocation(routes.postDetail, this.$router, {
                guid: post.id,
              })
            )
            .catch(() => {
              this.errors.navigationFailed = true;
            })
            .then(() => {
              this.cancel();
              this.loading = false;
            });
        })
        .catch(() => {
          this.errors.postNotCreated = true;
          this.loading = false;
        });
    } else {
      // Update post
      this.postsStore
        .update({
          allAreasOfInterest: this.areasOfInterestStore.interests,
          data: {
            areasOfInterest: this.formValues.areasOfInterest,
            content: this.formValues.content,
            images: this.updatedImages,
            title: this.formValues.title,
            visibility: this.formValues.visibility,
          },
          postId: this.postId,
        })
        .then((post) => {
          if (this.updatedImages.length > 0) {
            // Hack to force refresh the image
            window.location.reload();
            return;
          }

          this.postsStore.setCurrentPost(post);
          this.cancel();
          this.loading = false;
        })
        .catch(() => {
          this.errors.postNotUpdated = true;
          this.loading = false;
        });
    }
  }

  @Emit('cancel')
  protected cancel() {
    this.setInitialData();
    return;
  }

  protected goBack() {
    if (this.isFirstStep) {
      return this.cancel();
    }

    switch (this.formStep) {
      case FormStep.META_INFO:
        this.goToBasicInfo();
        break;
      case FormStep.BASIC_INFO:
        this.goToGroupSelection();
        break;
    }
  }

  protected goForward() {
    if (this.isFinalStep) {
      return this.submitButton.$el.click();
    }

    switch (this.formStep) {
      case FormStep.GROUP_CHOICE:
        if (!this.isBasicStepDisabled) {
          this.goToBasicInfo();
        }
        break;
      case FormStep.BASIC_INFO:
        if (!this.isMetaStepDisabled) {
          this.goToMeta();
        }
        break;
    }
  }

  protected goToBasicInfo() {
    this.formStep = FormStep.BASIC_INFO;
  }

  protected goToGroupSelection() {
    this.formStep = FormStep.GROUP_CHOICE;
  }

  protected goToMeta() {
    if (this.formStep === FormStep.META_INFO) {
      return;
    }

    if (!this.loadAreasOfInterestFromAi) {
      this.formStep = FormStep.META_INFO;
      return;
    }

    this.loadingNextStep = true;
    let aiContentRequest = '';

    if (this.formValues.title) {
      aiContentRequest += `${this.formValues.title}. `;
    }

    if (this.formValues.content) {
      aiContentRequest += `${this.formValues.content}`;
    }

    this.$api.ai
      .areas(aiContentRequest)
      .then((result) => {
        this.formValues.areasOfInterest = result;
      })
      .finally(() => {
        this.loadingNextStep = false;
        this.formStep = FormStep.META_INFO;
      });
  }

  @Watch('postId')
  @Watch('groupId')
  protected setInitialData() {
    this.formStep = FormStep.GROUP_CHOICE;
    this.formValues = createDefaultFormValues();
    // Do not load interests if we are editing a post
    this.loadAreasOfInterestFromAi = !this.postId;
    this.updatedImages = [];

    this.selectGroup(this.groupId);

    // Load current post details when editing existing post
    if (this.postId) {
      this.postsStore
        .get({
          id: this.postId,
          areasOfInterest: this.areasOfInterestStore.interests,
        })
        .then((res) => {
          const areasOfInterestIds = res.areasOfInterest.map((area) => area.id);

          this.formValues = {
            areasOfInterest: areasOfInterestIds,
            content: res.contentOriginal,
            image: res.image.original.src,
            images: res.images,
            title: res.title,
            visibility: res.visibility,
          };
        });
    }
  }

  protected addPhotos(data: ImageSelectionEvent) {
    data.images.forEach((image) => {
      this.formValues.images.push({
        src: image,
      });

      if (this.postId) {
        this.updatedImages.push({
          id: null,
          data: image,
        });
      }
    });
  }

  protected selectGroup(groupId: GroupItem['id'] | null) {
    if (!groupId) {
      this.selectedGroupId = null;
      return;
    }

    // TODO: these groups are not part of the cache, so we need to fetch it if it does not exist

    for (const groupItem of this.groupStore.groupCache) {
      if (groupItem.id === groupId) {
        this.selectedGroupId = groupId;
        this.$nextTick(this.goToBasicInfo);
        return;
      }
    }

    this.loadingNextStep = true;
    this.groupStore
      .load({ groupId })
      .then(() => {
        this.selectedGroupId = groupId;
        this.$nextTick(this.goToBasicInfo);
      })
      .catch(() => {
        this.errors.groupNotFound = true;
      })
      .finally(() => {
        this.loadingNextStep = false;
      });
  }

  protected setMainPhoto(data: ImageSelectionEvent) {
    if (data.firstImage) {
      this.formValues.image = data.firstImage;
      if (this.postId) {
        this.updatedImages.push({
          id: this.postId,
          data: data.firstImage,
        });
      }
    }
  }

  protected titleIsRequiredRule(input: string): boolean | TranslateResult {
    if (input.trim() === '') {
      return this.$t('app.error.post.titleIsRequired');
    }

    return true;
  }

  protected contentIsRequiredRule(input: string): boolean | TranslateResult {
    if (input.trim() === '') {
      return this.$t('app.error.post.contentIsRequired');
    }

    return true;
  }

  protected areasAreRequiredRule(input: any): boolean | TranslateResult {
    if (input.length < 1) {
      return this.$t('app.error.post.areasAreRequired');
    }

    return true;
  }
}
