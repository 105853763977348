import Vue from 'vue';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import {
  CzYsP14KcSurveyApiDtoSurveyCreationObject,
  isApiFetchResponse,
} from '~/app/core/apiClient/api';
import { createSurveyItem, SurveyItem } from '~/utils/survey';
import { parse } from '~/utils/date-fns';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import { GroupItem } from '~/utils/group';
import GroupModule from '~/app/core/store/modules/GroupModule';

interface GetInput {
  id: SurveyItem['id'];
}

interface CreateInput {
  data: CzYsP14KcSurveyApiDtoSurveyCreationObject;
  groupId: GroupItem['id'];
}

interface LikeInput {
  id: SurveyItem['id'];
}

interface VoteInput {
  id: SurveyItem['id'];
  optionId: string;
}

interface UnlikeInput {
  id: SurveyItem['id'];
}

interface ReportSurveyInput {
  id: SurveyItem['id'];
}

interface LikingCommit {
  id: SurveyItem['id'];
  liking: boolean;
}

interface VotingCommit {
  id: SurveyItem['id'];
  optionId: SurveyItem['id'];
  voting: boolean;
}

interface ReportingCommit {
  id: SurveyItem['id'];
  reporting: boolean;
}

type GetSurveyCommit = SurveyItem;

type DeleteSurveyCommit = SurveyItem['id'];

type CacheSurveysCommit = SurveyItem[];

interface LikeStatusCommit {
  id: SurveyItem['id'];
  state: boolean;
}

interface ReportStatusCommit {
  id: SurveyItem['id'];
  state: boolean;
}

enum SurveyLikeApiResponse {
  LIKED = 'Liked already',
  UNLIKED = 'Not liked',
}

export enum SurveyError {
  TIME_EXPIRED = 'TIME_EXPIRED',
}

/**
 * Retrieve index key for an array of surveys
 * @param id
 * @param items
 */
function getItemCacheKey(id: string, items: SurveyItem[]): number | null {
  for (let key = 0; key < items.length; key++) {
    if (items[key].id === id) {
      return key;
    }
  }

  return null;
}

/**
 * Parse error message from API and return true if the error matches the expected message
 *
 * @param err
 * @param successMessage
 */
function parseErrorResponse(err: any, successMessage: string): Promise<true> {
  if (isApiFetchResponse(err)) {
    return err.json().then((response) => {
      if (response.message && response.message === successMessage) {
        // The survey was already unliked
        return true;
      }
      throw err;
    });
  }
  throw err;
}

@Module({
  name: 'SurveysModule',
  stateFactory: true,
  namespaced: true,
})
export default class SurveysModule extends AbstractModule {
  public cache: SurveyItem[] = [];

  @Action({ commit: 'setSurvey', rawError: true })
  public get(data: GetInput): Promise<GetSurveyCommit> {
    return getModule(AreasOfInterestModule, this.store)
      .loadData()
      .then((areas) => {
        return this.$api.surveys.getSurvey(data.id).then((result) => {
          const survey = createSurveyItem(result, areas.allItems);

          if (survey === null) {
            throw new Error('Survey could not be created');
          }

          return getModule(GroupModule, this.store)
            .loadUncachedIds({ requestedIds: [survey.group.id] })
            .then(() => survey);
        });
      });
  }

  @Action({ commit: 'setSurvey', rawError: true })
  public create(input: CreateInput): Promise<GetSurveyCommit> {
    return this.$api.communities
      .createSurvey(input.data, input.groupId)
      .then((result) => {
        return this.get({
          id: result,
        });
      });
  }

  @Action({ commit: 'setLiked', rawError: true })
  public like(data: LikeInput): Promise<LikeStatusCommit> {
    this.setLiking({ id: data.id, liking: true });
    const successfulResult = {
      id: data.id,
      state: true,
    };

    return this.$api.surveys
      .likeSurvey(data.id)
      .then((_) => successfulResult)
      .catch((err) => parseErrorResponse(err, SurveyLikeApiResponse.LIKED))
      .then(() => {
        // The survey was already liked
        return successfulResult;
      })
      .finally(() => {
        this.setLiking({ id: data.id, liking: false });
      });
  }

  @Action({ commit: 'setLiked', rawError: true })
  public unlike(data: UnlikeInput): Promise<LikeStatusCommit> {
    this.setLiking({ id: data.id, liking: true });

    const successfulResult = {
      id: data.id,
      state: false,
    };
    return this.$api.surveys
      .unlikeSurvey(data.id)
      .then((_) => successfulResult)
      .catch((err) => parseErrorResponse(err, SurveyLikeApiResponse.UNLIKED))
      .then(() => {
        // The survey was already unliked
        return successfulResult;
      })
      .finally(() => {
        this.setLiking({ id: data.id, liking: false });
      });
  }

  @Action({ commit: 'setSurvey', rawError: true })
  public vote(data: VoteInput): Promise<GetSurveyCommit> {
    // Just to be sure, check the end of voting against current time
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      const end = parse(this.cache[key].end).getTime();
      if (!isNaN(end) && new Date().getTime() > end) {
        return Promise.reject(new Error(SurveyError.TIME_EXPIRED));
      }
    }
    // We will assume the interests are already loaded through the app
    const areas = getModule(AreasOfInterestModule, this.store).interests;

    this.setVoting({ id: data.id, optionId: data.optionId, voting: true });
    return this.$api.surveys
      .voteInSurvey(data.id, data.optionId)
      .then((survey) => {
        return createSurveyItem(survey, areas);
      })
      .finally(() => {
        this.setVoting({ id: data.id, optionId: data.optionId, voting: false });
      });
  }

  @Action({ commit: 'setReported', rawError: true })
  public report(data: ReportSurveyInput): Promise<ReportStatusCommit> {
    this.setReporting({ id: data.id, reporting: true });
    return this.$api.surveys
      .toxicSurvey(data.id)
      .then((_) => {
        return {
          id: data.id,
          state: true,
        };
      })
      .finally(() => {
        this.setReporting({ id: data.id, reporting: false });
      });
  }

  @Mutation
  public setSurvey(data: GetSurveyCommit) {
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      Vue.set(this.cache, key, data);
    } else {
      this.cache.push(data);
    }
  }

  @Mutation
  public unsetSurvey(data: DeleteSurveyCommit) {
    const key = getItemCacheKey(data, this.cache);
    if (key !== null) {
      Vue.delete(this.cache, key);
    }
  }

  @Mutation
  public cacheSurveys(items: CacheSurveysCommit) {
    items.forEach((item) => {
      const key = getItemCacheKey(item.id, this.cache);
      if (key !== null) {
        Vue.set(this.cache, key, item);
      } else {
        this.cache.push(item);
      }
    });
  }

  @Mutation
  protected setLiking(data: LikingCommit) {
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      this.cache[key].state.liking = data.liking;
      Vue.set(this.cache, key, this.cache[key]);
    }
  }

  @Mutation
  protected setLiked(data: LikeStatusCommit) {
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      this.cache[key].liked = data.state;
      this.cache[key].likes = this.cache[key].likes + (data.state ? 1 : -1);
      Vue.set(this.cache, key, this.cache[key]);
    }
  }

  @Mutation
  protected setVoting(data: VotingCommit) {
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      this.cache[key].state.voting = data.voting;
      for (const index in this.cache[key].options) {
        if (
          data.voting &&
          this.cache[key].options[index].id === data.optionId
        ) {
          this.cache[key].options[index].beingVoted = true;
        } else {
          this.cache[key].options[index].beingVoted = false;
        }
      }
      Vue.set(this.cache, key, this.cache[key]);
    }
  }

  @Mutation
  protected setReporting(data: ReportingCommit) {
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      this.cache[key].state.reporting = data.reporting;
      Vue.set(this.cache, key, this.cache[key]);
    }
  }

  @Mutation
  protected setReported(data: ReportStatusCommit) {
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      this.cache[key].reported = data.state;
      Vue.set(this.cache, key, this.cache[key]);
    }
  }
}
