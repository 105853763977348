import Vue from 'vue';
import { Action, Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import {
  CommentItem,
  CommentParentId,
  CommentType,
  createComment,
} from '~/utils/comments';

interface GetCommentsInput {
  id: CommentParentId;
  type: CommentType;
  page: number;
  size?: number;
}

interface ReportCommentInput {
  id: CommentItem['id'];
  type: CommentType;
}

interface ReportingCommit {
  id: CommentItem['id'];
  type: CommentType;
  reporting: boolean;
}

type CacheCommentsCommit = {
  items: CommentItem[];
  type: CommentType;
  lastPage: boolean;
};

interface ReportStatusCommit {
  id: CommentItem['id'];
  type: CommentType;
  state: boolean;
}

/**
 * Retrieve index key for an array of posts
 * @param id
 * @param items
 */
function getItemCacheKey(id: string, items: CommentItem[]): number | null {
  for (let key = 0; key < items.length; key++) {
    if (items[key].id === id) {
      return key;
    }
  }

  return null;
}

interface CommentsCache {
  [key: string]: CommentItem[];
}

@Module({
  name: 'CommentsModule',
  stateFactory: true,
  namespaced: true,
})
export default class CommentsModule extends AbstractModule {
  public cache: CommentsCache = {
    [CommentType.GROUP]: [],
    [CommentType.EVENT]: [],
    [CommentType.POST]: [],
    [CommentType.SURVEY]: [],
  };

  @Action({ commit: 'cacheComments', rawError: true })
  public loadComments(data: GetCommentsInput): Promise<CacheCommentsCommit> {
    return this.$api.comments
      .getComments(data.type, data.id, undefined, data.page, data.size)
      .then((result) => {
        if (!result.content) {
          throw new Error('No result returned');
        }

        const comments: CommentItem[] = [];

        result.content.forEach((apiComment) => {
          const comment = createComment(apiComment, data.type);
          if (comment !== null) {
            comments.push(comment);
          }
        });

        return {
          items: comments,
          lastPage: result.last === true,
          type: data.type,
        };
      });
  }

  @Action({ commit: 'setReported', rawError: true })
  public report(data: ReportCommentInput): Promise<ReportStatusCommit> {
    this.setReporting({ id: data.id, type: data.type, reporting: true });
    return this.$api.comments
      .toxicComment(data.id)
      .then((_) => {
        return {
          id: data.id,
          type: data.type,
          state: true,
        };
      })
      .finally(() => {
        this.setReporting({ id: data.id, type: data.type, reporting: false });
      });
  }

  @Mutation
  public cacheComments(data: CacheCommentsCommit) {
    data.items.forEach((item) => {
      const key = getItemCacheKey(item.id, this.cache[data.type]);
      if (key !== null) {
        Vue.set(this.cache[data.type], key, item);
      } else {
        this.cache[data.type].push(item);
      }
    });
  }

  @Mutation
  protected setReporting(data: ReportingCommit) {
    const key = getItemCacheKey(data.id, this.cache[data.type]);
    if (key !== null) {
      this.cache[data.type][key].state.reporting = data.reporting;
      Vue.set(this.cache, key, this.cache[data.type][key]);
    }
  }

  @Mutation
  protected setReported(data: ReportStatusCommit) {
    const key = getItemCacheKey(data.id, this.cache[data.type]);
    if (key !== null) {
      this.cache[data.type][key].state.reported = data.state;
      Vue.set(this.cache, key, this.cache[data.type][key]);
    }
  }
}
