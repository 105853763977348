export enum Colors {
  ACCENT = '#f39600',
  ACCENT_LIGHTEN4 = '#fff0d9',
  ANCHOR = '#009dde',
  ANCHOR_HOVER = '#f39600',
  PRIMARY = '#01253e',
  SECONDARY = '#a2c516',
  SECONDARY_LIGHTEN4 = '#f1f7d7',
  TERTIARY = '#009dde',
  TERTIARY_LIGHTEN4 = '#e1f6ff',
  TEXT = '#1d1d1d',
}

export const deleteColor = 'red';
export const editColor = 'secondary';
export const errorColor = 'red darken-1';
export const greyBackgroundColor = 'grey lighten-4';
export const greyForegroundColor = 'grey lighten-2';
export const greyTextColor = 'grey--text text--darken-1';
export const redTextColor = 'red--text text--darken-1';
export const reportColor = 'red lighten-1';
export const successColor = 'success';
