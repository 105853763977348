import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './Content.scss';

interface ContentInterface {
  text: string;
}

const rootClass = 'ys-content';

@Component({ style })
export default class Content extends VueComponent<ContentInterface>
  implements ContentInterface {
  @Prop({ type: String, required: true })
  public text!: string;

  public render() {
    if (this.text.trim() === '') {
      return;
    }

    return (
      <div class={rootClass}>
        {this.text.split(/\n/).map((chunk) => (
          <p>{chunk}</p>
        ))}
      </div>
    );
  }
}
