import { RouteConfig } from 'vue-router';

import Homepage from '~/views/Homepage';

const config: RouteConfig = {
  component: Homepage,
  path: '',
  alias: 'homepage',
};

export default config;
