export default {
  app: {
    registerDialog: {
      headline: 'Registrace',
      noAccount: 'Účet nenalezen!',
      registrationRequired: 'Nejprve je nutné se zaregistrovat.',
      text:
        'Registraci jednoduše provedete za pomci svého mobilního telefonu v naší chytré aplikaci.',
      actionText: 'Aplikaci stáhnete zde:',
    },
  },
};
