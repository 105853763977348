import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

const rootClass = 'ys-loading';

@Component
export default class Loading extends VueComponent<{}> {
  public render() {
    return (
      <v-container fluid fill-height>
        <v-row class='fill-height' justify='center' align='center'>
          <v-progress-circular
            indeterminate
            color='secondary'
            size='80'
            width='5'
          />
        </v-row>
      </v-container>
    );
  }
}
