import { Component, Prop, Watch } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import { EventBanner } from '~/components/molecules';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { getModule } from 'vuex-module-decorators';
import EventFeedModule from '~/app/core/store/modules/EventFeedModule';
import { ButtonSize, LoadMoreButton } from '~/components/atoms';
import { EventTile } from '~/components/organisms';
import { EventInstanceItem } from '~/utils/event';
import { format, parse } from '~/utils/date-fns';
import EventsModule from '~/app/core/store/modules/EventsModule';

const rootClass = 'ys-event-list';

interface EventListInterface {
  groupId?: string;
}

@Component
export default class EventList
  extends VueComponentMixin<EventListInterface, PrefetchComponent>(Prefetch)
  implements EventListInterface {
  @Prop({ type: String })
  public groupId?: string;

  protected get eventsStore() {
    return getModule(EventsModule, this.$store);
  }

  protected get eventFeedStore() {
    return getModule(EventFeedModule, this.$store);
  }

  protected get items() {
    // Sorry for doing this, but we have no component that's dumb enough to be used, so I have
    // to duplicate it...fml
    const eventCalendar: { [key: string]: EventInstanceItem[] } = {};
    if (this.eventFeedStore.eventInstanceIdentifications) {
      this.eventFeedStore.eventInstanceIdentifications.forEach(
        (eventIdentification) => {
          // TODO: Bad performance, can't use id-key map because it's not reactive
          // TODO: Figure out a way to use MAP to retrieve events by key, instead of looping
          for (const event of this.eventsStore.instanceCache) {
            if (
              event.id === eventIdentification.id &&
              event.date === eventIdentification.date
            ) {
              if (!eventCalendar[event.date]) {
                eventCalendar[event.date] = [event];
              } else {
                eventCalendar[event.date].push(event);
              }
              break;
            }
          }
        }
      );
    }
    return eventCalendar;
  }

  protected get isLastPage() {
    return this.eventFeedStore.lastPage;
  }

  protected get loading() {
    return this.eventFeedStore.loading;
  }

  public prefetch() {
    if (this.$ssrContext) {
      return Promise.resolve();
    }

    return this.loadData();
  }

  public beforeDestroy() {
    this.eventFeedStore.setPageNumber(0);
    this.eventFeedStore.setItems([]);
  }

  @Watch('groupId')
  protected loadData() {
    this.eventFeedStore.setPageNumber(0);
    this.eventFeedStore.setItems([]);
    return this.loadMore();
  }

  public render() {
    return (
      <div class={rootClass}>
        {this.loading &&
          this.eventFeedStore.eventInstanceIdentifications.length < 1 && (
            <div>
              <v-skeleton-loader
                type='list-item-avatar-three-line'
                tile={true}
              />
            </div>
          )}
        {this.renderItems()}
        {!this.loading &&
          this.eventFeedStore.eventInstanceIdentifications.length < 1 && (
            <p>{this.$t('app.event.noEvents')}</p>
          )}
        {!this.isLastPage && (
          <div class='text-center mt-4'>
            <LoadMoreButton
              loading={this.loading}
              onLoadMore={this.loadMore}
              size={ButtonSize.LARGE}
            />
          </div>
        )}
      </div>
    );
  }

  protected renderItems() {
    if (
      this.loading &&
      this.eventFeedStore.eventInstanceIdentifications.length < 1
    ) {
      return;
    }

    const eventDates = [];
    let first = true;
    for (const eventDate in this.items) {
      if (this.items[eventDate]) {
        const date = format(parse(eventDate), 'dddd Do Mo').toUpperCase();
        eventDates.push(
          <section class='mb-3 pb-3' key={eventDate}>
            {!first && <v-divider class='mb-3' />}
            <h2 class='text-uppercase mb-3'>{date}</h2>
            {this.items[eventDate].map((event, index) => {
              return (
                <EventTile
                  class={
                    this.items[eventDate].length !== index + 1
                      ? 'mb-4'
                      : undefined
                  }
                  event={event}
                  key={`event-list-${eventDate}-${event.id}`}
                />
              );
            })}
          </section>
        );
        first = false;
      }
    }

    return eventDates;
  }

  protected loadMore() {
    return this.eventFeedStore
      .getItems(this.groupId)
      .then(this.eventsStore.cacheEventInstances);
  }
}
