import { Component, Prop } from 'vue-property-decorator';

import { EditGroupForm } from '~/components/templates';
import VDialogCloseHandler, {
  VDialogCloseHandlerInterface,
} from '~/mixins/VDialogCloseHandler';
import { GroupItem } from '~/utils/group';
import { VueComponentMixin } from '~/utils/vue-component';

interface EditGroupModalInterface {
  groupId?: GroupItem['id'] | null;
}

@Component
export default class EditGroupModal
  extends VueComponentMixin<
    EditGroupModalInterface,
    VDialogCloseHandlerInterface
  >(VDialogCloseHandler)
  implements EditGroupModalInterface {
  @Prop({ default: null })
  public groupId!: GroupItem['id'] | null;

  public created() {
    if (this.setPersistent) {
      this.setPersistent();
    }
  }

  public render() {
    return (
      <v-dialog max-width='900' scrollable {...this.vDialogAttributes}>
        <EditGroupForm groupId={this.groupId} onCancel={this.vDialogClose} />
      </v-dialog>
    );
  }
}
