import { IconName } from '~/components/atoms/icons/Icon';

export interface LanguageLocaleInterface {
  locale: string;
  name: string;
  icon: IconName;
}
const languageMap: LanguageLocaleInterface[] = [
  {
    locale: 'cs-CZ',
    name: 'Čeština',
    icon: ['flags', 'Cs'],
  },
];

export default languageMap;
