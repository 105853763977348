import { CzYsP14KcCommentApiDtoCommentObject } from '~/app/core/apiClient/api';
import { CommentItem, CommentType } from './';
import createUser from '~/utils/users/createUser';
import { format } from '~/utils/date-fns';
import { RFC3339Format } from '~/utils/dateTime';
import DeletionTypeEnum = CzYsP14KcCommentApiDtoCommentObject.DeletionTypeEnum;

export default function(
  comment: CzYsP14KcCommentApiDtoCommentObject,
  type: CommentType
): CommentItem | null {
  if (!comment.id) {
    return null;
  }

  return {
    id: comment.id,
    content: comment.content || '',
    date: comment.created || format(new Date(), RFC3339Format),
    deleted: comment.deletionType === DeletionTypeEnum.USER,
    state: {
      // TODO: Missing API data
      reported: false,
      reporting: false,
    },
    user: comment.user ? createUser(comment.user) : null,
    toxic: comment.deletionType === DeletionTypeEnum.TOXIC,
    type,
  };
}
