import { Component, Emit, Prop } from 'vue-property-decorator';

import placeholderHead from '~/assets/images/placeholder/post_1114x350.png';
import placeholderTile from '~/assets/images/placeholder/post_350x180.png';
import placeholderBanner from '~/assets/images/placeholder/post_40x40.png';
import { ImageSettings } from '~/utils/image';
import { ImageSize } from '~/utils/media';
import { PostItem } from '~/utils/post';
import { VueComponent } from '~/utils/vue-component';

export enum PostImageContext {
  HEAD,
  TILE,
  AVATAR,
}

interface PostImageInterface {
  context: PostImageContext;
  post: PostItem;
}

@Component
export default class PostImage extends VueComponent<PostImageInterface>
  implements PostImageInterface {
  @Prop({ required: true })
  public context!: PostImageContext;

  @Prop({ required: true })
  public post!: PostItem;

  protected get settings(): ImageSettings {
    switch (this.context) {
      case PostImageContext.HEAD:
        return {
          placeholderImage: placeholderHead,
          height: '350px',
          size: ImageSize.ORIGINAL,
        };
      case PostImageContext.AVATAR:
        return {
          placeholderImage: placeholderBanner,
          ratio: 1,
          size: ImageSize.THUMBNAIL,
        };
      default:
        return {
          placeholderImage: placeholderTile,
          ratio: 4 / 3,
          size: ImageSize.SMALL,
        };
    }
  }

  protected get imageSrc(): string {
    switch (this.settings.size) {
      case ImageSize.THUMBNAIL:
        return this.post.image.thumbnail.src;
      case ImageSize.SMALL:
        return this.post.image.small.src;
      default:
        return this.post.image.original.src;
    }
  }

  public render() {
    return (
      <v-img
        alt={this.post.title}
        aspect-ratio={this.settings.height ? undefined : this.settings.ratio}
        height={this.settings.height ? this.settings.height : undefined}
        max-height={this.settings.height ? this.settings.height : undefined}
        src={this.imageSrc}
        key={`post-image-${this.post.id}`}
        onClick={this.emitClick}
      >
        <v-img
          aspect-ratio={this.settings.height ? undefined : this.settings.ratio}
          height={this.settings.height ? this.settings.height : undefined}
          position='bottom center'
          slot='placeholder'
          src={this.settings.placeholderImage}
        />
        {this.$slots.default}
      </v-img>
    );
  }

  @Emit('click')
  protected emitClick() {
    return;
  }
}
