import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';

import style from './SelectPlaceField.scss';

import {
  createAddressFromLocation,
  createEmptyGeoLocation,
  GeoLocation,
} from '~/utils/location';
import { ValueProp, VueComponent } from '~/utils/vue-component';
import { TranslateResult } from 'vue-i18n';

interface SelectPlaceFieldInterface extends ValueProp<GeoLocation> {
  clearable?: boolean;
  label: TranslateResult;
  onChooseLocation?: () => any;
}

const rootClass = 'ys-select-place-field';

@Component({ style })
export default class SelectPlaceField
  extends VueComponent<SelectPlaceFieldInterface>
  implements SelectPlaceFieldInterface {
  @Prop({ default: false })
  public clearable!: boolean;

  @Prop({ required: true })
  public value!: GeoLocation;

  @Prop({ required: true })
  public label!: TranslateResult;

  @Ref('clearButton')
  protected readonly clearButton!: Vue;

  public render() {
    return (
      <div class={rootClass} onClick={this.emitChooseLocation}>
        <v-text-field
          class='v-input--with-buttons'
          disabled
          filled
          label={this.label}
          readonly
          rounded
          value={createAddressFromLocation(this.value, this.$i18n)}
        >
          <v-btn
            class='v-btn--prepended v-btn--squared'
            elevation={0}
            onClick={this.clearLocation}
            slot='prepend-inner'
            style={{ boxShadow: 'none !important' }}
            icon
            ref='clearButton'
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            class='v-btn--appended'
            block
            color='secondary'
            elevation={0}
            onClick={this.emitChooseLocation}
            rounded
            slot='append'
            x-large
          >
            <v-icon left>mdi-map-marker</v-icon>
            {this.$t('app.place.choose')}
          </v-btn>
        </v-text-field>
      </div>
    );
  }

  @Emit('input')
  protected clearLocation() {
    return createEmptyGeoLocation();
  }

  protected emitChooseLocation(e: Event) {
    if (
      e.target === this.clearButton.$el ||
      (e.target instanceof Node && this.clearButton.$el.contains(e.target))
    ) {
      return;
    }

    this.$emit('chooseLocation');
  }
}
