import eventDetail from './eventDetail';
import events from './events';
import findGroups from './findGroups';
import groupDetail from './groupDetail';
import groupPreview from './groupPreview';
import groups from './groups';
import homepage from './homepage';
import eventPreview from './eventPreview';
import postDetail from './postDetail';
import postPreview from './postPreview';
import surveyDetail from './surveyDetail';
import surveyPreview from './surveyPreview';
import unknown from './unknown';

const routes = {
  eventDetail,
  eventPreview,
  events,
  findGroups,
  groupDetail,
  groupPreview,
  groups,
  homepage,
  postDetail,
  postPreview,
  surveyDetail,
  surveyPreview,
  unknown,
};

export { routes };

// Order matters, more specific routes must come first
export default [
  eventDetail,
  eventPreview,
  groupDetail,
  groupPreview,
  findGroups,
  postDetail,
  postPreview,
  surveyDetail,
  surveyPreview,
  events,
  groups,
  homepage,
  unknown,
];
