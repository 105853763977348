import { Component, Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VueComponentMixin } from '~/utils/vue-component';
import UserModule, {
  UserInfo,
  User,
} from '~/app/core/store/modules/UserModule';
import { CzYsP14KcUserApiDtoUserUpdateObject } from '~/app/core/apiClient/api';
import { acceptedImageMimes } from '~/utils/media';
import VDialogCloseHandler, {
  VDialogCloseHandlerInterface,
} from '~/mixins/VDialogCloseHandler';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { getUserImageUrl } from '~/utils/user';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import { createAreaOfInterestSelectItems } from '~/utils/areasOfInterest';
import equals from '~/utils/array';

const rootClass = 'ys-profile-modal';

@Component
export default class ProfileModal extends VueComponentMixin<
  {},
  VDialogCloseHandlerInterface & PrefetchComponent
>(VDialogCloseHandler, Prefetch) {
  protected areasOfInterestSearchTerm: string = '';

  protected loading: boolean = false;

  protected userChanges: UserInfo = {
    displayName: '',
    phoneNumber: '',
    photoURL: '',
    areasOfInterest: [],
  };

  public get userStore(): UserModule {
    return getModule(UserModule, this.$store);
  }

  public get user(): User | null {
    return this.userStore.user;
  }

  protected get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  protected get imageUrl() {
    if (this.userChanges.photoURL) {
      return this.userChanges.photoURL;
    }

    return this.user ? getUserImageUrl(this.user.id) : '';
  }

  protected get areasOfInterestSelectItems() {
    return createAreaOfInterestSelectItems(
      this.areasOfInterestStore.categorisedInterests
    );
  }

  @Ref('fileInput')
  protected fileInput!: HTMLInputElement;

  public prefetch() {
    if (this.$ssrContext) {
      return Promise.resolve();
    }

    return Promise.all([
      this.userStore.getUserData().finally(() => {
        this.setDataFromStore();
      }),
      this.areasOfInterestStore.loadData().catch(() => {
        // nothing to do here
      }),
    ]);
  }

  public render() {
    return (
      <v-dialog
        content-class={`${rootClass}__dialog`}
        width='800'
        {...this.vDialogAttributes}
      >
        <v-card>
          <v-card-title
            class='headline grey lighten-2'
            style='position:relative;'
          >
            {this.$t('app.common.userProfile')}
            <v-btn icon absolute right onClick={this.cancel}>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row class='pt-3'>
              <v-col cols='4'>
                <v-img
                  alt={this.$t('app.profile.photoAlt')}
                  aspect-ratio={3 / 4}
                  src={this.imageUrl}
                  style='background-color: grey'
                />
                <v-btn onClick={this.openFileDialog} block>
                  <span style='white-space: normal;'>
                    {this.$t('app.profile.changePhoto')}
                  </span>
                </v-btn>
                <input
                  accept={acceptedImageMimes.join(',')}
                  type='file'
                  ref='fileInput'
                  style='display:none'
                  onChange={this.changePhoto}
                />
              </v-col>
              <v-col cols='8'>
                <v-text-field
                  class='mb-3'
                  label={this.$t('app.login.email')}
                  value={this.user?.email}
                  disabled
                  messages={this.$t('app.profile.emailCaption')}
                  persistent-hint
                  filled
                  rounded
                />
                <v-text-field
                  label={this.$t('app.profile.displayedName')}
                  v-model={this.userChanges.displayName}
                  filled
                  rounded
                />
                <v-text-field
                  label={this.$t('app.profile.phone')}
                  v-model={this.userChanges.phoneNumber}
                  type='tel'
                  filled
                  rounded
                />
                <v-autocomplete
                  autocomplete='off'
                  chips
                  deletable-chips
                  label={this.$t('app.profile.areasOfInterest')}
                  items={this.areasOfInterestSelectItems}
                  multiple
                  no-data-text={this.$t('app.common.noDataText')}
                  outlined
                  rounded
                  search-input={this.areasOfInterestSearchTerm}
                  small-chips
                  v-model={this.userChanges.areasOfInterest}
                  {...{
                    on: {
                      input: () => {
                        this.areasOfInterestSearchTerm = '';
                      },
                      'update:search-input': (value: string) => {
                        this.areasOfInterestSearchTerm = value;
                      },
                    },
                  }}
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn loading={this.loading} onClick={this.cancel}>
              {this.$t('app.common.cancel')}
            </v-btn>
            <v-spacer />
            <v-btn loading={this.loading} onClick={this.saveUserInformation}>
              {this.$t('app.common.save')}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    );
  }

  protected cancel() {
    this.setDataFromStore();
    if (this.vDialogClose) {
      this.vDialogClose();
    }
  }

  protected setDataFromStore() {
    this.userChanges.displayName = this.user?.displayName || '';
    this.userChanges.phoneNumber = this.user?.phoneNumber || '';
    this.userChanges.photoURL = this.user?.photoURL || '';
    this.userChanges.areasOfInterest = this.user?.areasOfInterest || [];
  }

  protected openFileDialog() {
    this.fileInput.click();
  }

  protected changePhoto() {
    if (this.fileInput.files) {
      const reader = new FileReader();
      reader.readAsDataURL(this.fileInput.files[0]);
      reader.onloadend = (e) => {
        this.userChanges.photoURL =
          typeof e.target?.result === 'string' ? e.target.result : '';
      };
    }
  }

  protected saveUserInformation() {
    if (!this.userStore.user?.id) {
      return;
    }
    const data: CzYsP14KcUserApiDtoUserUpdateObject = {};
    let dataChanged: boolean = false;

    if (
      this.userChanges.displayName &&
      this.userChanges.displayName !== this.userStore.user.displayName
    ) {
      data.displayName = this.userChanges.displayName;
      dataChanged = true;
    }
    if (
      this.userChanges.phoneNumber &&
      this.userChanges.phoneNumber !== this.userStore.user.phoneNumber
    ) {
      data.phone = this.userChanges.phoneNumber;
      dataChanged = true;
    }
    if (
      this.userChanges.photoURL &&
      this.userChanges.photoURL !== this.userStore.user.photoURL
    ) {
      data.avatarUrl = this.userChanges.photoURL;
      dataChanged = true;
    }

    if (
      !equals({
        array1: this.userChanges.areasOfInterest,
        array2: this.userStore.user.areasOfInterest,
      })
    ) {
      data.areasOfInterest = this.userChanges.areasOfInterest;
      dataChanged = true;
    }

    if (dataChanged) {
      this.loading = true;
      this.$api.users
        .updateUser(data, this.userStore.user.id)
        .then(() => {
          this.userStore.patchUser({ user: data });
        })
        .finally(() => {
          this.loading = false;
        });
    }

    if (this.vDialogClose) {
      this.vDialogClose();
    }
  }
}
