import { Component, Prop } from 'vue-property-decorator';

import placeholderHead from '~/assets/images/placeholder/survey-group_1114x350.png';
import placeholderTile from '~/assets/images/placeholder/survey-group_350x180.png';
import placeholderBanner from '~/assets/images/placeholder/survey-group_40x40.png';
import { GroupItem } from '~/utils/group';
import { ImageSettings } from '~/utils/image';
import { ImageSize } from '~/utils/media';
import { VueComponent } from '~/utils/vue-component';

export enum GroupImageContext {
  HEAD,
  TILE,
  AVATAR,
}

interface GroupImageInterface {
  context: GroupImageContext;
  group: GroupItem;
}

@Component
export default class GroupImage extends VueComponent<GroupImageInterface>
  implements GroupImageInterface {
  @Prop({ required: true })
  public context!: GroupImageContext;

  @Prop({ required: true })
  public group!: GroupItem;

  protected get settings(): ImageSettings {
    switch (this.context) {
      case GroupImageContext.HEAD:
        return {
          placeholderImage: placeholderHead,
          height: '350px',
          size: ImageSize.ORIGINAL,
        };
      case GroupImageContext.AVATAR:
        return {
          placeholderImage: placeholderBanner,
          ratio: 1,
          size: ImageSize.THUMBNAIL,
        };
      case GroupImageContext.TILE:
        return {
          placeholderImage: placeholderTile,
          ratio: 2,
          size: ImageSize.SMALL,
        };
    }
  }

  protected get imageSrc(): string {
    switch (this.settings.size) {
      case ImageSize.THUMBNAIL:
        return this.group.image.thumbnail.src;
      case ImageSize.SMALL:
        return this.group.image.small.src;
      default:
        return this.group.image.original.src;
    }
  }

  public render() {
    return (
      <v-img
        alt={this.group.name}
        aspect-ratio={this.settings.height ? undefined : this.settings.ratio}
        height={this.settings.height ? this.settings.height : undefined}
        max-height={this.settings.height ? this.settings.height : undefined}
        src={this.imageSrc}
        key={`group-image-${this.group.id}`}
      >
        <v-img
          aspect-ratio={this.settings.height ? undefined : this.settings.ratio}
          height={this.settings.height ? this.settings.height : undefined}
          slot='placeholder'
          src={this.settings.placeholderImage}
        />
        {this.$slots.default}
      </v-img>
    );
  }
}
