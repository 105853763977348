import { Component, Watch, Prop } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import { PostTile } from '~/components/organisms';
import { PostItem } from '~/utils/post';

import { getModule } from 'vuex-module-decorators';
import PostListModule from '~/app/core/store/modules/PostListModule';
import { PrefetchComponent, Prefetch } from '~/mixins/prefetch';
import { ButtonSize, LoadMoreButton } from '~/components/atoms';
import PostsModule from '~/app/core/store/modules/PostsModule';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import GroupModule from '~/app/core/store/modules/GroupModule';

interface PostListInterface {
  groupId?: string;
}

@Component
export default class PostList
  extends VueComponentMixin<PostListInterface, PrefetchComponent>(Prefetch)
  implements PostListInterface {
  @Prop({ type: String })
  public groupId?: string;

  protected get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  protected get postsStore() {
    return getModule(PostsModule, this.$store);
  }

  protected get postListStore() {
    return getModule(PostListModule, this.$store);
  }

  protected get items(): PostItem[] {
    const items: PostItem[] = [];

    this.postListStore.postIds.forEach((id) => {
      // TODO: Bad performance, can't use id-key map because it's not reactive
      // TODO: Figure out a way to use MAP to retrieve posts by key, instead of looping
      for (const postItem of this.postsStore.cache) {
        if (postItem.id === id) {
          items.push(postItem);
          break;
        }
      }
    });

    return items;
  }

  protected get isLastPage() {
    return this.postListStore.lastPage;
  }

  protected get loading() {
    return this.postListStore.loading;
  }

  public prefetch() {
    if (this.$ssrContext) {
      return Promise.resolve();
    }

    return Promise.all([this.loadData(), this.areasOfInterestStore.loadData()]);
  }

  public beforeDestroy() {
    this.postListStore.setPageNumber(0);
    this.postListStore.setItems([]);
  }

  @Watch('groupId')
  protected loadData() {
    this.postListStore.setPageNumber(0);
    this.postListStore.setItems([]);
    return this.postListStore
      .getItems({
        groupId: this.groupId,
        areasOfInterest: this.areasOfInterestStore.interests,
      })
      .then(this.postsStore.cachePosts);
  }

  public render() {
    return (
      <div key={this.groupId || 'homepage'}>
        {this.loading && this.items.length < 1 && (
          <div>
            <v-skeleton-loader type='list-item-avatar-three-line' tile={true} />
          </div>
        )}
        {this.items.map((item) => {
          return <PostTile post={item} key={item.id} class='mb-6' />;
        })}
        {!this.loading && this.items.length < 1 && (
          <p>{this.$t('app.posts.noPosts')}</p>
        )}
        {!this.isLastPage && (
          <div class='text-center'>
            <LoadMoreButton
              loading={this.loading}
              onLoadMore={this.loadMore}
              size={ButtonSize.LARGE}
            />
          </div>
        )}
      </div>
    );
  }

  protected loadMore() {
    this.postListStore
      .getItems({
        groupId: this.groupId,
        areasOfInterest: this.areasOfInterestStore.interests,
      })
      .then((items) => {
        this.postsStore.cachePosts(items);
      });
  }
}
