import { RouteConfig } from 'vue-router';

import GroupDetail from '~/views/GroupDetail';
import parameters from './parameters';

const config: RouteConfig = {
  component: GroupDetail,
  path: `public/group/${parameters.guidPath}`,
  meta: {
    public: true,
  },
};

export default config;
