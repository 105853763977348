import { Component, Prop } from 'vue-property-decorator';

import placeholderHead from '~/assets/images/placeholder/event_1114x350.png';
import placeholderTile from '~/assets/images/placeholder/event_350x180.png';
import placeholderBanner from '~/assets/images/placeholder/event_40x40.png';
import { EventInstanceItem, EventItem } from '~/utils/event';
import { ImageSettings } from '~/utils/image';
import { ImageSize } from '~/utils/media';
import { VueComponent } from '~/utils/vue-component';

export enum EventImageContext {
  HEAD,
  TILE,
  AVATAR,
}

interface EventImageInterface {
  context: EventImageContext;
  event: EventInstanceItem | EventItem;
}

@Component
export default class EventImage extends VueComponent<EventImageInterface>
  implements EventImageInterface {
  @Prop({ required: true })
  public context!: EventImageContext;

  @Prop({ required: true })
  public event!: EventInstanceItem;

  protected get settings(): ImageSettings {
    switch (this.context) {
      case EventImageContext.HEAD:
        return {
          placeholderImage: placeholderHead,
          height: '350px',
          size: ImageSize.ORIGINAL,
        };
      case EventImageContext.AVATAR:
        return {
          placeholderImage: placeholderBanner,
          ratio: 1,
          size: ImageSize.THUMBNAIL,
        };
      // TILE
      default:
        return {
          placeholderImage: placeholderTile,
          ratio: 16 / 9,
          size: ImageSize.SMALL,
        };
    }
  }

  protected get imageSrc(): string {
    switch (this.settings.size) {
      case ImageSize.THUMBNAIL:
        return this.event.image.thumbnail.src;
      case ImageSize.SMALL:
        return this.event.image.small.src;
      default:
        return this.event.image.original.src;
    }
  }

  public render() {
    return (
      <v-img
        alt={this.event.title}
        aspect-ratio={this.settings.height ? undefined : this.settings.ratio}
        height={this.settings.height ? this.settings.height : undefined}
        max-height={this.settings.height ? this.settings.height : undefined}
        src={this.imageSrc}
        key={`event-image-${this.event.id}`}
      >
        <v-img
          aspect-ratio={this.settings.height ? undefined : this.settings.ratio}
          height={this.settings.height ? this.settings.height : undefined}
          position='bottom center'
          slot='placeholder'
          src={this.settings.placeholderImage}
        />
        {this.$slots.default}
      </v-img>
    );
  }
}
