import { CzYsP14KcCommentApiDtoCommentObjectUser } from '~/app/core/apiClient/api';
import { User } from './';

type UserTypes = CzYsP14KcCommentApiDtoCommentObjectUser;

export default function(user: UserTypes): User | null {
  if (!user.id || !user.displayName) {
    return null;
  }

  return {
    id: user.id,
    name: user.displayName,
  };
}
