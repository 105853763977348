import imageVersion from '~/utils/media/imageVersion';
import appEnv from '~/app/core/appEnv';
const acceptedImageMimes = ['image/png', 'image/jpeg'];

interface ImageItem {
  data?: string;
  id?: string;
  src: string;
}

const baseMediaUrl = appEnv.BLOB_STORAGE_URL;

enum ImageSize {
  ORIGINAL,
  THUMBNAIL,
  SMALL,
}

function getImageSizeSuffix(size: ImageSize): string {
  switch (size) {
    case ImageSize.ORIGINAL:
      return '';
    case ImageSize.THUMBNAIL:
      return '/thumbnail';
    case ImageSize.SMALL:
      return '/300';
  }
}

export {
  acceptedImageMimes,
  baseMediaUrl,
  getImageSizeSuffix,
  ImageItem,
  imageVersion,
  ImageSize,
};
