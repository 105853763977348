import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { getLocalizedLocation } from '~/app/core/router';
import groupDetailRoute from '~/app/core/router/routes/groupDetail';
import homepageRoute from '~/app/core/router/routes/homepage';
import groupsRoute from '~/app/core/router/routes/groups';
import eventsRoute from '~/app/core/router/routes/events';
import GroupModule from '~/app/core/store/modules/GroupModule';
import { FindGroupsButton } from '~/components/atoms';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { greyBackgroundColor } from '~/utils/theme/colors';
import { groupIcon, userCanEditGroup } from '~/utils/group';
import { VueComponentMixin } from '~/utils/vue-component';

import style from './Sidebar.scss';

const rootClass = 'ys-sidebar';

@Component({
  style,
})
export default class Sidebar extends VueComponentMixin<{}, PrefetchComponent>(
  Prefetch
) {
  protected groupsCollapsed = false;

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  public prefetch() {
    if (this.$ssrContext || this.groupStore.items.length > 0) {
      return Promise.resolve();
    }

    return this.groupStore.initItems();
  }

  public render() {
    return (
      <v-navigation-drawer
        class={`${rootClass}`}
        app
        light
        clipped
        mobile-breakpoint='sm'
        color={`${greyBackgroundColor}`}
      >
        <v-list>
          <v-list-item
            link
            exact
            to={getLocalizedLocation(homepageRoute, this.$router, {})}
          >
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class={`${rootClass}__category`}>
                {this.$t('app.menu.dashboard')}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider dark class='my-4' />

          <v-list-group v-model={this.groupsCollapsed}>
            <v-icon
              slot='prependIcon'
              onClick={() => {
                this.$router.push(
                  getLocalizedLocation(groupsRoute, this.$router, {})
                );
              }}
            >
              {groupIcon}
            </v-icon>
            <v-list-item-content
              slot='activator'
              onClick={() => {
                this.$router.push(
                  getLocalizedLocation(groupsRoute, this.$router, {})
                );
              }}
            >
              <v-list-item-title class={`${rootClass}__category`}>
                {this.$t('app.menu.groups')}
              </v-list-item-title>
            </v-list-item-content>

            {this.groupStore.sortedItems.map((group) => {
              return (
                <v-list-item
                  class={`${rootClass}__item`}
                  link
                  to={getLocalizedLocation(groupDetailRoute, this.$router, {
                    guid: group.id,
                  })}
                >
                  <v-list-item-icon class='mx-3'>
                    {group.membershipType &&
                      userCanEditGroup(group.membershipType) && (
                        <v-icon size='17'>mdi-settings</v-icon>
                      )}
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{group.name}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              );
            })}
          </v-list-group>

          <v-list-item class='mt-3'>
            <v-row justify='center'>
              <FindGroupsButton />
            </v-row>
          </v-list-item>

          <v-divider dark class='my-4' />

          <v-list-item to={getLocalizedLocation(eventsRoute, this.$router, {})}>
            <v-list-item-icon>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class={`${rootClass}__category`}>
                {this.$t('app.menu.events')}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    );
  }
}
