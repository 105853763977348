import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './ImageWrapper.scss';

import { Image } from '~/components/atoms';
import ImageComponent, { ImageInterface } from '~/components/atoms/image/Image';
import { ThemeRatios } from '~/utils/theme';

import Timeout = NodeJS.Timeout;

export interface Sizes {
  minWidth?: number;
  size: number;
  unit: string;
}

interface ImageWrapperInterface {
  image: ImageInterface;
  ratio?: ThemeRatios;
  sizes?: Sizes[];
}

enum Cover {
  WIDTH = '--cover-width',
  HEIGHT = '--cover-height',
}

const rootClass = 'ys-image-wrapper';

@Component({
  style,
})
export default class ImageWrapper extends VueComponent<ImageWrapperInterface>
  implements ImageWrapperInterface {
  @Prop({ required: true })
  public image!: ImageInterface;

  @Prop()
  public ratio?: ThemeRatios;

  @Prop()
  public sizes?: Sizes[];

  @Ref('image')
  protected readonly imageComponent?: ImageComponent;

  protected cover: Cover = Cover.WIDTH;

  protected resizeThrottle?: Timeout;

  protected imageRatioRecalcHelper = 0;

  protected get imageRatio(): number {
    // Referencing helper to recalculate the getter values
    // tslint:disable-next-line
    this.imageRatioRecalcHelper;
    // TODO: The image ratio should be determined here, ideally from backend data, this is
    // an example implementation that will not work correctly, the image size must be known before load
    // to correctly determine sizes (cannot work with sizes after image load)
    if (!this.imageComponent) {
      return 0;
    }

    const {
      width,
      height,
    } = this.imageComponent.imageElement.getBoundingClientRect();
    if (height < 1) {
      return 0;
    }

    return width / height;
  }

  public get imageSizes(): string {
    if (!this.sizes) {
      return '';
    }

    const sizes: string[] = [];

    this.sizes.forEach((size) => {
      // Size adjustment
      let delta = 1;

      if (this.ratio && this.imageRatio && this.imageRatio > this.ratio) {
        delta = this.imageRatio / this.ratio;
      }

      const adjustedSize = size.size * delta;

      const sizesMember = `${
        size.minWidth ? `(min-width: ${size.minWidth}) ` : ''
      }${Math.ceil(adjustedSize)}${size.unit}`;
      sizes.push(sizesMember);
    });

    return sizes.join(', ');
  }

  @Watch('ratio')
  public handleListeners() {
    if (this.ratio) {
      window.removeEventListener('resize', this.coverHandler);
    } else {
      window.addEventListener('resize', this.coverHandler);
    }
    this.setCover();
  }

  public mounted() {
    if (!this.ratio) {
      window.addEventListener('resize', this.coverHandler);
    }
  }

  public beforeDestroy() {
    if (!this.ratio) {
      window.removeEventListener('resize', this.coverHandler);
    }
  }

  public render() {
    const classes = [rootClass, rootClass + this.cover];

    if (this.ratio) {
      classes.push(rootClass + '--ratio-' + ThemeRatios[this.ratio]);
    }

    return (
      <div class={classes.join(' ')}>
        {(() => {
          if (!this.image || (this.image && !this.image.src)) {
            return;
          }
          return (
            <Image
              absolute={true}
              src={this.image.src}
              alt={this.image.alt}
              sizes={this.imageSizes}
              ref='image'
              onLoad={this.loaded}
            />
          );
        })()}
      </div>
    );
  }

  protected loaded() {
    this.imageRatioRecalcHelper++;
    this.setCover();
  }

  @Watch('image', { deep: true })
  protected coverHandler() {
    if (this.resizeThrottle) {
      clearTimeout(this.resizeThrottle);
    }

    this.resizeThrottle = setTimeout(() => {
      this.setCover();
    }, 50);
  }

  public setCover() {
    if (!this.image || !this.ratio || !this.imageRatio) {
      return;
    }

    if (this.ratio < this.imageRatio) {
      this.cover = Cover.HEIGHT;
    } else {
      this.cover = Cover.WIDTH;
    }
  }
}
