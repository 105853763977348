import { Component, Prop } from 'vue-property-decorator';

import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';
import {
  default as GroupImage,
  GroupImageContext,
} from '~/components/molecules/groupImage/GroupImage';
import { GroupItem } from '~/utils/group';
import typography from '~/utils/typography';
import { VueComponent } from '~/utils/vue-component';

export interface GroupCardInterface {
  group: GroupItem;
  disableLink?: boolean;
}

@Component
export default class GroupCard extends VueComponent<GroupCardInterface>
  implements GroupCardInterface {
  @Prop({ required: true })
  public group!: GroupItem;

  @Prop({ default: false })
  public disableLink!: boolean;

  protected get route() {
    if (this.disableLink) {
      return;
    }

    let config = routes.groupDetail;
    if (this.$route.meta.public) {
      config = routes.groupPreview;
    }
    return getLocalizedLocation(config, this.$router, {
      guid: this.group.id,
    });
  }

  public render() {
    if (this.route) {
      return (
        <router-link to={this.route} class='d-block text-decoration-none'>
          {this.renderContent()}
        </router-link>
      );
    }

    return this.renderContent();
  }

  protected renderContent() {
    return (
      <v-row no-gutters align='center'>
        <v-avatar class='mr-2' size='40'>
          <GroupImage context={GroupImageContext.AVATAR} group={this.group} />
        </v-avatar>
        <v-row no-gutters class='flex-column' justify='space-around'>
          <span class={typography.caption}>
            {this.$slots.caption ||
              this.$t(`app.${this.group.kind.toLowerCase()}.kind`)}
          </span>
          <span class={typography.subtitle}>{this.group.name}</span>
        </v-row>
      </v-row>
    );
  }
}
