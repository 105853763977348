import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './EventDateSelector.scss';
import { format, parse } from '~/utils/date-fns';
import { createDefaultDaySelection } from '~/utils/event';
import { dateFormatWithDays, RFC3339Format } from '~/utils/dateTime';
import { TranslateResult } from 'vue-i18n';

const rootClass = 'ys-event-date-selector';

export interface DaySelection {
  [key: string]: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export enum InputMode {
  DATE_ONLY,
  SINGLEDAY,
  MULTIDAY,
  REPEATING,
}

export interface Selection {
  end: Date | undefined;
  mode: InputMode;
  selection: DaySelection;
  start: Date;
  timeFrom: string;
  timeTo: string;
}

interface EventDateSelectorInterface {
  canChoosePastDates: boolean;
  daysOfWeek: DaySelection;
  end: Date | null;
  inputMode: InputMode;
  start: Date;
  timeEnd: string | null;
  timeStart: string | null;
  errorFields?: ErrorFields;
  onUpdatedDaySelection?: (selection: DaySelection) => void;
  onUpdateEnd?: (value: Date | null) => void;
  onUpdateInputMode?: (value: InputMode) => void;
  onUpdateStart?: (value: Date) => void;
  onUpdateTimeStart?: (value: string | null) => void;
  onUpdateTimeEnd?: (value: string | null) => void;
}

export interface ErrorFields {
  [key: string]: boolean;
  daySelection: boolean;
  end: boolean;
  endBeforeStart: boolean;
  isInPast: boolean;
  start: boolean;
  timeEnd: boolean;
  timeStart: boolean;
}

export function createDefaultErrorFields(): ErrorFields {
  return {
    daySelection: false,
    end: false,
    endBeforeStart: false,
    isInPast: false,
    start: false,
    timeEnd: false,
    timeStart: false,
  };
}

function isVueDaySelectionElement(data: any): data is Vue {
  return typeof data.$el !== undefined;
}

function isDayButton(data: any): data is HTMLButtonElement {
  return typeof data.blur === 'function';
}

@Component({
  style,
})
export default class EventDateSelector
  extends VueComponent<EventDateSelectorInterface>
  implements EventDateSelectorInterface {
  @Prop({ required: true })
  public canChoosePastDates!: boolean;

  @Prop({
    default: () => {
      const data: DaySelection = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      };

      return createDefaultDaySelection();
    },
  })
  public daysOfWeek!: DaySelection;

  @Prop({ required: true })
  public end!: Date | null;

  @Prop({ required: true })
  public start!: Date;

  @Prop({ required: true })
  public timeStart!: string | null;

  @Prop({ required: true })
  public timeEnd!: string | null;

  @Prop({ default: InputMode.SINGLEDAY })
  public inputMode!: InputMode;

  @Prop({ default: createDefaultErrorFields })
  public errorFields!: ErrorFields;

  protected showTimeFrom: boolean = false;

  protected showTimeTo: boolean = false;

  protected get formatedStart(): string {
    return format(this.start, 'YYYY-MM-DD');
  }

  protected get formatedEnd(): string | undefined {
    if (!this.end) {
      return;
    }

    return format(this.end, 'YYYY-MM-DD');
  }

  protected get formattedTimeStart(): string {
    if (!this.timeStart) {
      return '';
    }

    return this.timeStart
      .split(':')
      .slice(0, 2)
      .join(':');
  }

  protected get formattedTimeEnd(): string {
    if (!this.timeEnd) {
      return '';
    }
    const end = this.timeEnd
      .split(':')
      .slice(0, 2)
      .join(':');

    return end === '00:00' ? '24:00' : end;
  }

  protected get dateRange() {
    const range = [this.formatedStart];

    if (this.formatedEnd) {
      range.push(this.formatedEnd);
    }

    return range;
  }

  protected get daySelectionLabelClass() {
    const classes = ['caption'];
    if (this.errorFields.daySelection) {
      classes.push('error--text');
    }

    return classes.join(' ');
  }

  protected get minimumDate(): string | undefined {
    if (this.canChoosePastDates) {
      return undefined;
    }

    return format(new Date(), RFC3339Format);
  }

  @Watch('inputMode')
  protected inputModeChanged() {
    this.showTimeFrom = false;
    this.showTimeTo = false;
  }

  public render() {
    return (
      <div>
        {this.inputMode !== InputMode.DATE_ONLY && (
          <v-radio-group
            class={`${rootClass}__mode`}
            value={this.inputMode}
            onChange={this.updateInputMode}
            row
          >
            <v-radio
              label={this.$t('app.event.timeInput.singleDay')}
              value={InputMode.SINGLEDAY}
            />
            <v-radio
              label={this.$t('app.event.timeInput.multiDay')}
              value={InputMode.MULTIDAY}
            />
            <v-radio
              label={this.$t('app.event.timeInput.repeating')}
              value={InputMode.REPEATING}
            />
          </v-radio-group>
        )}
        <v-window
          class={`${rootClass}__frame px-4 pt-3 mr-3`}
          v-model={this.inputMode}
        >
          <v-window-item value={InputMode.DATE_ONLY}>
            <v-row>
              <v-col
                cols='3'
                md='2'
                class={`${rootClass}__label py-0 text-no-wrap align-center d-flex`}
              >
                {this.$t('app.event.date')}
              </v-col>
              <v-col class='py-0'>{this.renderDateStart()}</v-col>
            </v-row>
          </v-window-item>
          <v-window-item value={InputMode.SINGLEDAY}>
            <v-row>
              <v-col
                cols='3'
                md='2'
                class={`${rootClass}__label py-0 text-no-wrap align-center d-flex`}
              >
                {this.$t('app.event.date')}
              </v-col>
              <v-col class='py-0'>{this.renderDateStart()}</v-col>
            </v-row>
            <v-row>
              <v-col class='py-0'>
                {this.renderTimeRange(InputMode.SINGLEDAY)}
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item value={InputMode.MULTIDAY}>
            <v-row class={`${rootClass}__multi-day`}>
              <v-col class='py-0 flex-grow-0 flex-shrink-0'>
                <v-row class='flex-column fill-height'>
                  <v-col class='py-0'>
                    <v-row class='fill-height'>
                      <v-col
                        class={`${rootClass}__label py-0 align-center d-flex text-no-wrap`}
                      >
                        {this.$t('app.event.dateStart')}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class='py-0'>
                    <v-row class='fill-height'>
                      <v-col
                        class={`${rootClass}__label py-0 align-center d-flex text-no-wrap`}
                      >
                        {this.$t('app.event.dateEnd')}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class={`py-0 ${rootClass}__date-range`}>
                {this.renderDateRange(
                  this.$t('app.event.date'),
                  this.$t('app.event.date')
                )}
              </v-col>
              <v-col class={`py-0 ${rootClass}__time-range`}>
                <v-row class='flex-column fill-height' justify='center'>
                  <v-col class='py-0'>
                    {this.renderTimeFrom(
                      InputMode.MULTIDAY,
                      this.$t('app.event.time')
                    )}
                  </v-col>
                  <v-col class='py-0'>
                    {this.renderTimeTo(
                      InputMode.MULTIDAY,
                      this.$t('app.event.time')
                    )}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item value={InputMode.REPEATING}>
            <v-row>
              <v-col class='py-0 flex-shrink-0 flex-grow-0'>
                <v-row class='flex-column fill-height' justify='center'>
                  <v-col class='py-0'>
                    <v-row class='fill-height' no-gutters>
                      <v-col
                        class={`${rootClass}__label py-0 align-center d-flex text-no-wrap`}
                      >
                        {this.$t('app.event.dateRangeStart')}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class='py-0'>
                    <v-row class='fill-height' no-gutters>
                      <v-col
                        class={`${rootClass}__label py-0 align-center d-flex text-no-wrap`}
                      >
                        {this.$t('app.event.dateRangeEnd')}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class='py-0'>{this.renderDateRange()}</v-col>
            </v-row>
            <v-row>
              <v-col>
                <h4 class={this.daySelectionLabelClass}>
                  {this.$t('app.event.daysOfWeek')}
                </h4>
                <v-row justify='space-between' no-gutters class='py-1'>
                  {(() => {
                    const inputs: JSX.Element[] = [];
                    for (const daysOfWeekKey in this.daysOfWeek) {
                      if (!this.daysOfWeek.hasOwnProperty(daysOfWeekKey)) {
                        continue;
                      }
                      inputs.push(
                        <v-btn
                          class='mx-1'
                          ref={`daySelectionButton${daysOfWeekKey}`}
                          key={`event-date-selector-switch-${daysOfWeekKey}`}
                          onClick={() => {
                            const ref = this.$refs[
                              `daySelectionButton${daysOfWeekKey}`
                            ];
                            if (
                              isVueDaySelectionElement(ref) &&
                              isDayButton(ref.$el)
                            ) {
                              ref.$el.blur();
                            }

                            this.updatedDaySelection(
                              daysOfWeekKey,
                              !this.daysOfWeek[daysOfWeekKey]
                            );
                          }}
                          elevation={0}
                          fab
                          small
                          type='button'
                          retain-focus-on-click={false}
                          color={
                            this.daysOfWeek[daysOfWeekKey]
                              ? 'secondary'
                              : undefined
                          }
                        >
                          {this.$t('app.common.days.' + daysOfWeekKey)
                            .toString()
                            .substr(0, 2)}
                        </v-btn>
                      );
                    }

                    return inputs;
                  })()}
                </v-row>
              </v-col>
            </v-row>
            {this.renderTimeRange(InputMode.REPEATING)}
          </v-window-item>
        </v-window>
        {this.errorFields.isInPast && (
          <v-alert class='mt-3' type='warning'>
            {this.$t('app.common.dateIsInPast')}
          </v-alert>
        )}
        {this.errorFields.endBeforeStart && (
          <v-alert class='mt-3' type='warning'>
            {this.$t('app.common.dateEndBeforePast')}
          </v-alert>
        )}
      </div>
    );
  }

  protected renderDateStart() {
    return (
      <v-menu
        close-on-content-click={false}
        offset-y
        min-width={290}
        scopedSlots={{
          activator: (scope: any) => {
            return (
              <div
                {...{
                  on: scope.on,
                }}
              >
                <v-text-field
                  value={format(this.start, dateFormatWithDays)}
                  error={this.errorFields.start}
                  filled
                  rounded
                />
              </div>
            );
          },
        }}
      >
        <v-date-picker
          key={`event-date-selector-from`}
          first-day-of-week={1}
          min={this.minimumDate}
          value={this.formatedStart}
          onInput={this.updateStart}
        />
      </v-menu>
    );
  }

  protected renderDateRange(
    startLabel?: TranslateResult,
    endLabel?: TranslateResult
  ) {
    return (
      <v-menu
        close-on-content-click={false}
        offset-y
        min-width={290}
        scopedSlots={{
          activator: (scope: any) => {
            return (
              <v-row class='flex-column fill-height' justify='center'>
                <v-col class='py-0'>
                  <div
                    {...{
                      on: scope.on,
                    }}
                  >
                    <v-text-field
                      error={this.errorFields.start}
                      filled
                      label={startLabel}
                      readonly
                      rounded
                      value={format(this.start, dateFormatWithDays)}
                    />
                  </div>
                </v-col>
                <v-col class='py-0'>
                  <div
                    {...{
                      on: scope.on,
                    }}
                  >
                    <v-text-field
                      error={this.errorFields.end}
                      filled
                      label={endLabel}
                      readonly
                      rounded
                      value={
                        this.end
                          ? format(this.end, dateFormatWithDays)
                          : this.end
                      }
                    />
                  </div>
                </v-col>
              </v-row>
            );
          },
        }}
      >
        <v-date-picker
          key={`event-date-selector-range`}
          first-day-of-week={1}
          range
          value={this.dateRange}
          onInput={this.updateRange}
        />
      </v-menu>
    );
  }

  protected renderTimeFrom(inputMode: InputMode, label: TranslateResult) {
    return (
      <v-menu
        value={this.showTimeFrom && this.inputMode === inputMode}
        onInput={(value: boolean) => {
          this.showTimeFrom = value;
        }}
        close-on-content-click={false}
        internal-activator={true}
        offset-y
        min-width={290}
        scopedSlots={{
          activator: (scope: any) => {
            return (
              <div
                {...{
                  on: scope.on,
                }}
              >
                <v-text-field
                  error={this.errorFields.timeStart}
                  filled
                  label={label}
                  readonly
                  rounded
                  value={this.formattedTimeStart}
                />
              </div>
            );
          },
        }}
      >
        <v-time-picker
          value={this.timeStart}
          onInput={this.updateTimeStart}
          format='24hr'
        >
          <v-spacer />
          <v-btn
            text
            color='secondary'
            onClick={() => {
              this.showTimeFrom = false;
            }}
          >
            {this.$t('app.common.choose')}
          </v-btn>
        </v-time-picker>
      </v-menu>
    );
  }

  protected renderTimeTo(inputMode: InputMode, label: TranslateResult) {
    return (
      <v-menu
        value={this.showTimeTo && this.inputMode === inputMode}
        onInput={(value: boolean) => {
          this.showTimeTo = value;
        }}
        internal-activator={true}
        close-on-content-click={false}
        offset-y
        min-width={290}
        scopedSlots={{
          activator: (scope: any) => {
            return (
              <div
                {...{
                  on: scope.on,
                }}
              >
                <v-text-field
                  error={this.errorFields.timeEnd}
                  filled
                  label={label}
                  readonly
                  rounded
                  value={this.formattedTimeEnd}
                />
              </div>
            );
          },
        }}
      >
        <v-time-picker
          value={this.timeEnd}
          onInput={this.updateTimeEnd}
          format='24hr'
        >
          <v-spacer />
          <v-btn
            text
            color='secondary'
            onClick={() => {
              this.showTimeTo = false;
            }}
          >
            {this.$t(this.timeEnd ? 'app.common.choose' : 'app.common.close')}
          </v-btn>
        </v-time-picker>
      </v-menu>
    );
  }

  protected renderTimeRange(inputMode: InputMode) {
    return (
      <v-row>
        <v-col
          cols='3'
          md='2'
          class={`${rootClass}__label py-0 text-no-wrap align-center d-flex`}
        >
          {this.$t('app.event.time')}
        </v-col>
        <v-col class='py-0'>
          <v-row>
            <v-col class='py-0'>
              {this.renderTimeFrom(inputMode, this.$t('app.event.timeStart'))}
            </v-col>
            <v-col
              cols='auto'
              class={`${rootClass}__label py-0 text-no-wrap align-center d-flex`}
            >
              &mdash;
            </v-col>
            <v-col class='py-0'>
              {this.renderTimeTo(inputMode, this.$t('app.event.timeEnd'))}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    );
  }

  @Emit('updateStart')
  protected updateStart(value?: string): Date | null {
    if (!value) {
      return null;
    }

    const date = parse(value);
    if (isNaN(date.getTime())) {
      return null;
    }

    return date;
  }

  @Emit('updateEnd')
  protected updateEnd(value?: string): Date | null {
    if (!value) {
      return null;
    }

    const date = parse(value);
    if (isNaN(date.getTime())) {
      return null;
    }

    return date;
  }

  @Emit('updateInputMode')
  protected updateInputMode(value: InputMode): InputMode {
    return value;
  }

  protected updateRange(value: string[]): void {
    value.sort();
    const start = value.length > 0 ? value[0] : undefined;
    const end = value.length > 1 ? value[1] : undefined;
    this.updateStart(start);
    this.updateEnd(end);
  }

  @Emit('updateTimeStart')
  protected updateTimeStart(value?: string): string | null {
    if (value && value.split(':').length < 3) {
      value += ':00';
    }

    return value || null;
  }

  @Emit('updateTimeEnd')
  protected updateTimeEnd(value?: string): string | null {
    if (value && value.split(':').length < 3) {
      value += ':00';
    }

    return value || null;
  }

  @Emit('updatedDaySelection')
  protected updatedDaySelection(key: string, value: boolean): DaySelection {
    const daySelection = { ...this.daysOfWeek };
    if (typeof daySelection[key] !== undefined) {
      daySelection[key] = value;
    }

    return daySelection;
  }
}
