import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import {
  EditGroupModal,
  EditEventModal,
  EditPostModal,
  EditSurveyModal,
} from '..';
import { getModule } from 'vuex-module-decorators';
import MutationModalsModule from '~/app/core/store/modules/MutationModalsModule';

@Component
export default class MutationModals extends VueComponent<{}> {
  public get modalStore() {
    return getModule(MutationModalsModule, this.$store);
  }

  public render() {
    return (
      <div>
        <EditEventModal
          active={this.modalStore.isNewEventModalOpen}
          onCloseDialog={() => {
            this.modalStore.setNewEventModalIsOpen(false);
          }}
        />
        <EditGroupModal
          active={this.modalStore.isNewGroupModalOpen}
          createNew={true}
          onCloseDialog={() => {
            this.modalStore.setNewGroupModalIsOpen(false);
          }}
        />
        <EditPostModal
          active={this.modalStore.isNewPostModalOpen}
          onCloseDialog={() => {
            this.modalStore.setNewPostModalIsOpen(false);
          }}
        />
        <EditSurveyModal
          active={this.modalStore.isNewSurveyModalOpen}
          onCloseDialog={() => {
            this.modalStore.setNewSurveyModalIsOpen(false);
          }}
        />
      </div>
    );
  }
}
