import { EventItem } from '~/utils/event/index';
import { addDays, format, parse } from '~/utils/date-fns';
import mapDaysOfWeekToArray from '~/utils/event/mapDaysOfWeekToArray';
import { routeParameterDateFormat } from '~/app/core/router/routes/parameters/date';

export default function(event: EventItem): string {
  const date = parse(event.dateStart);
  // Repeatable event has daysOfWeek defined
  if (event.daySelection) {
    // Because daysOfWeek begin with first element on monday and Date.getDay returns indexed
    // array beginning with sunday, we need to remap daysOfWeek to
    // sunday indexed array (sunday = 0, monday = 1)
    const dayOfWeek = date.getDay();
    const daysOfWeek = mapDaysOfWeekToArray(event.daySelection);
    daysOfWeek.unshift(daysOfWeek[6]);
    daysOfWeek.pop();

    // How many days shall we add to the dateStart (first event instance)
    let add = 0;

    // Need to loop over two weeks to make sure we get a match
    for (let dayIndex = 0; dayIndex <= 13; dayIndex++) {
      if (dayOfWeek > dayIndex) {
        // skip days defined before event start
        continue;
      }

      // Check for the first weekday, that is selected
      if (daysOfWeek[dayIndex % 7]) {
        // If it is add as many days as is the difference between event date (when it starts repeating)
        // and selected day of week (first instance during the repeated time frame)
        add = dayIndex - dayOfWeek;
        break;
      }
    }

    // Add as many days to the event start as needed
    return format(addDays(date, add), routeParameterDateFormat);
  }

  return format(date, routeParameterDateFormat);
}
