import { Component, Prop } from 'vue-property-decorator';
import { SpreadAttributes, VueComponent } from '~/utils/vue-component';

export enum ButtonSize {
  EXTRA_SMALL = 'x-small',
  EXTRA_LARGE = 'x-large',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export interface BaseButtonInterface {
  block?: boolean;
  disabled?: boolean;
  loading?: boolean;
  size?: ButtonSize;
}

function buttonSizeValidator(data: any): data is ButtonSize {
  for (const size in ButtonSize) {
    if (
      ButtonSize.hasOwnProperty(size) &&
      data === (ButtonSize as { [key: string]: any })[size]
    ) {
      return true;
    }
  }

  return false;
}

@Component
export default class BaseButton<T> extends VueComponent<T & BaseButtonInterface>
  implements BaseButtonInterface {
  @Prop({ default: false, type: Boolean })
  public block!: boolean;

  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  @Prop({ default: ButtonSize.NORMAL, validator: buttonSizeValidator })
  public size!: ButtonSize;

  protected get baseAttributes(): SpreadAttributes {
    const attributes: { [key: string]: any } = {
      disabled: this.disabled,
      loading: this.loading,
    };

    if (this.size !== ButtonSize.NORMAL) {
      attributes[this.size] = true;
    }

    if (this.block) {
      attributes.block = true;
    }

    return { attrs: attributes };
  }
}
