import { Component, Ref, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';
import groupDetailRoute from '~/app/core/router/routes/groupDetail';
import GroupDetailModule from '~/app/core/store/modules/GroupDetailModule';
import { SuccessSnack } from '~/components/molecules';
import {
  ShareButtonProps,
  ToolbarButtonProps,
} from '~/components/molecules/toolbarSubmenu/ToolbarSubmenu';
import {
  DetailToolbar,
  EventList,
  FeedList,
  GroupActionButtons,
  GroupBody,
  GroupHead,
} from '~/components/organisms';
import { CommentTypeBlock, EditGroupModal } from '~/components/templates';
import HeadManagement from '~/mixins/HeadManagement';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import RequestErrorComponent from '~/mixins/RequestErrorComponent';
import { commentsFragment, CommentType } from '~/utils/comments';
import { getGroupImageUrl, Kind } from '~/utils/group';
import { createShareUri } from '~/utils/http/links';
import { imageVersion } from '~/utils/media';
import { greyBackgroundColor } from '~/utils/theme/colors';
import createSeoTags, { TYPE } from '~/utils/views/createSeoTags';
import { VueComponentMixin } from '~/utils/vue-component';
import { headerHeight } from '~/components/organisms/header/Header';
import ScrollToRefMixin from '~/mixins/ScrollToRefMixin';

interface GroupSnacks {
  copied: boolean;
}

enum DisplayedTab {
  FEED,
  EVENTS,
  COMMENTS,
}

@Component
export default class GroupDetail extends VueComponentMixin<
  {},
  RequestErrorComponent & PrefetchComponent & ScrollToRefMixin
>(Prefetch, HeadManagement, RequestErrorComponent, ScrollToRefMixin) {
  @Ref('commentBlock')
  protected readonly commentBlock?: Vue;

  protected displayedTab: DisplayedTab = DisplayedTab.FEED;

  protected groupEditOpen: boolean = false;

  protected groupActions: GroupSnacks = {
    copied: false,
  };

  protected scrollCommentsAfterLoad: boolean = false;

  public title() {
    if (this.loadingPrefetchData) {
      return '...';
    }

    return this.groupModule.id
      ? this.groupModule.name
      : this.$t('app.error.group.notFound');
  }

  public headTags(): string {
    return createSeoTags(
      this.groupModule.name,
      this.groupModule.description,
      this.$router.resolve(
        getLocalizedLocation(routes.groupPreview, this.$router, {
          guid: this.groupId,
        })
      ).href,
      TYPE.ARTICLE,
      getGroupImageUrl(this.groupId, imageVersion())
    );
  }

  protected get editButton(): ToolbarButtonProps | undefined {
    if (!this.groupModule.editable || this.preview) {
      return;
    }

    return {
      disabled: false,
      loading: false,
    };
  }

  protected get shareButton(): ShareButtonProps | undefined {
    return {
      disabled: false,
      loading: false,
      uri: createShareUri(
        this.$router.resolve(
          getLocalizedLocation(routes.groupPreview, this.$router, {
            guid: this.groupId,
          })
        ).href
      ),
    };
  }

  protected get groupModule() {
    return getModule(GroupDetailModule, this.$store);
  }

  protected get preview() {
    return !!this.$route.meta.public;
  }

  public prefetch() {
    if (this.$ssrContext) {
      return Promise.resolve();
    }

    this.scrollCommentsAfterLoad = true;

    return this.groupModule.loadData(this.groupId).catch((err) => {
      if (err.status && err.status === 401 && this.$route.meta.public) {
        return this.$router.push(
          getLocalizedLocation(groupDetailRoute, this.$router, {
            guid: this.groupId,
          })
        );
      }
      this.requestErrorHandler(err);
    });
  }

  public get groupId() {
    return this.$route.params.guid;
  }

  public render() {
    if (this.loadingPrefetchData) {
      return <v-progress-linear indeterminate />;
    } else if (this.requestErrorComponent) {
      return this.requestErrorComponent;
    }

    return (
      <v-container>
        <GroupHead key={`group-detail-head-${this.groupId}`} />
        <v-card class={`${greyBackgroundColor} pb-0 pa-0`} flat>
          <v-card-text>
            <DetailToolbar
              edit={this.editButton}
              share={this.shareButton}
              onEdit={() => {
                this.groupEditOpen = true;
              }}
              onShare={() => {
                this.groupActions.copied = true;
              }}
            >
              {!this.preview && <GroupActionButtons slot='left' />}
              <div slot='right' class={`mx-1`}>
                <v-chip>
                  {this.$tc(
                    'app.communities.member',
                    this.groupModule.members,
                    {
                      count: this.groupModule.members,
                    }
                  )}
                </v-chip>
              </div>
            </DetailToolbar>
          </v-card-text>
          <GroupBody key={`group-detail-head-${this.groupId}`} />
        </v-card>
        <div class='ys-inner-space'>
          <v-tabs
            v-model={this.displayedTab}
            fixed-tabs
            class='v-tabs--forcehide-arrows'
          >
            <v-tab>{this.$t('app.common.feed')}</v-tab>
            <v-tab>
              {this.groupModule.kind === Kind.PROJECT
                ? this.$t('app.event.events')
                : this.$t('app.event.soonest')}
            </v-tab>
            <v-tab>{this.$t('app.comment.comments')}</v-tab>
          </v-tabs>
          <v-row justify='center'>
            <v-col cols='12' md='10' lg='9'>
              <v-tabs-items v-model={this.displayedTab}>
                <v-tab-item value={DisplayedTab.FEED}>
                  <FeedList groupId={this.groupId} />
                </v-tab-item>
                <v-tab-item value={DisplayedTab.EVENTS}>
                  <EventList groupId={this.groupId} />
                </v-tab-item>
                <v-tab-item value={DisplayedTab.COMMENTS}>
                  <CommentTypeBlock
                    firstPageSize={2}
                    onCommentsLoaded={() => {
                      if (
                        !this.scrollCommentsAfterLoad ||
                        this.$route.hash !== commentsFragment
                      ) {
                        return;
                      }

                      this.scrollToComments();
                    }}
                    parentId={this.groupId}
                    ref='commentBlock'
                    showTitle={false}
                    type={CommentType.GROUP}
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </div>

        <EditGroupModal
          active={this.groupEditOpen}
          createNew={false}
          groupId={this.groupId}
          onCloseDialog={() => {
            this.groupEditOpen = false;
          }}
        />
        <SuccessSnack v-model={this.groupActions.copied}>
          {this.$t('app.share.success')}
        </SuccessSnack>
      </v-container>
    );
  }

  protected scrollToComments() {
    if (!this.commentBlock) {
      return;
    }

    this.scrollCommentsAfterLoad = false;

    this.scrollToRef(this.commentBlock, -headerHeight);
  }
}
