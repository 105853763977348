import { createDaysOfWeek, createDefaultDaySelection, EventItem } from './';
import {
  CzYsP14KcEventApiDtoEventDetailObject,
  CzYsP14KcEventApiDtoEventInstanceDetailObject,
} from '~/app/core/apiClient/api';
import { EventItemComponent as SearchItem } from '~/components/organisms/eventTile/EventTile';
import {
  AreaOfInterestItem,
  mapAreasOfInterest,
} from '~/utils/areasOfInterest';
import { format, parse } from '~/utils/date-fns';
import { RFC3339Format } from '~/utils/dateTime';
import getEventImageUrl from '~/utils/event/getEventImageUrl';
import { createEmptyGeoLocation } from '~/utils/location';
import { ImageSize, imageVersion } from '~/utils/media';

import VisibilityEnum = CzYsP14KcEventApiDtoEventDetailObject.VisibilityEnum;

type Inputs =
  | SearchItem
  | CzYsP14KcEventApiDtoEventDetailObject
  | CzYsP14KcEventApiDtoEventInstanceDetailObject;

// TODO: We are using so many inputs because we have so many interfaces, yeeey
function isEventDetailObjectDto(
  data: any
): data is CzYsP14KcEventApiDtoEventDetailObject {
  // TODO: This typeguard is only relevant for the create funciton, do not use it, it's far from complete
  return (
    data &&
    typeof data === 'object' &&
    typeof data.dateStart === 'string' &&
    typeof data.content === 'string'
  );
}

function isSearchItemWithDate(data: any): data is SearchItem {
  return typeof data.date === 'string';
}

export default function(
  data: Inputs,
  areas: AreaOfInterestItem[]
): EventItem | null {
  if (!data.id || !data.title) {
    return null;
  }

  let areasOfInterest: AreaOfInterestItem[] = [];
  let annotation = '';
  let capacity: number | null = null;
  let content = '';
  let dateEnd = '';
  let dateStart = '';
  let daySelection = createDefaultDaySelection();
  const flags = {
    barrierFree: false,
    dogsAllowed: false,
  };
  let link = '';
  let location = createEmptyGeoLocation();
  let price: string | null = null;
  let timeEnd: string = '00:00:00';
  let timeStart: string = '00:00:00';
  let visibility: VisibilityEnum = VisibilityEnum.PUBLIC;

  if (isEventDetailObjectDto(data)) {
    annotation = data.annotation || '';

    if (data.areasOfInterest) {
      areasOfInterest = mapAreasOfInterest(data.areasOfInterest, areas);
    }

    if (data.capacity) {
      capacity = data.capacity;
    }

    content = data.content || '';

    if (data.dateEnd) {
      const parsedDate = parse(data.dateEnd);
      if (!isNaN(parsedDate.getTime())) {
        dateEnd = format(parsedDate, RFC3339Format);
      }
    }

    if (data.dateStart) {
      const parsedDate = parse(data.dateStart);
      if (!isNaN(parsedDate.getTime())) {
        dateStart = format(parsedDate, RFC3339Format);
      }
    }

    if (data.daysOfWeek) {
      daySelection = createDaysOfWeek(data.daysOfWeek);
    }

    data.flags?.forEach((flag) => {
      switch (flag) {
        case CzYsP14KcEventApiDtoEventDetailObject.FlagsEnum.BARRIERFREE:
          flags.barrierFree = true;
          break;
        case CzYsP14KcEventApiDtoEventDetailObject.FlagsEnum.DOGSALLOWED:
          flags.dogsAllowed = true;
          break;
      }
    });

    link = data.link || '';

    if (data.place) {
      location = {
        address: data.place.address || '',
        ruianId: data.place.ruianId || null,
        lat: data.place.latitude || null,
        lng: data.place.longitude || null,
        name: data.place.name || '',
      };
    }

    if (data.price) {
      price = data.price;
    }

    if (data.timeEnd) {
      timeEnd = data.timeEnd;
    }

    if (data.timeStart) {
      timeStart = data.timeStart;
    }

    if (data.visibility) {
      visibility = data.visibility;
    }
  } else if (isSearchItemWithDate(data)) {
    dateStart = data.date;
  }

  if (!dateStart) {
    return null;
  }

  if (!dateEnd) {
    dateEnd = dateStart;
  }

  const mediaVersion = imageVersion();

  return {
    annotation,
    areasOfInterest,
    capacity,
    content,
    dateEnd,
    dateStart,
    daySelection,
    flags,
    id: data.id,
    image: {
      thumbnail: {
        src: getEventImageUrl(data.id, mediaVersion, ImageSize.THUMBNAIL),
      },
      small: {
        src: getEventImageUrl(data.id, mediaVersion, ImageSize.SMALL),
      },
      original: {
        src: getEventImageUrl(data.id, mediaVersion, ImageSize.ORIGINAL),
      },
    },
    link,
    location,
    price,
    timeEnd,
    timeStart,
    title: data.title,
    visibility,
  };
}

export function createEmptyEventItem(): EventItem {
  return {
    annotation: '',
    areasOfInterest: [],
    capacity: null,
    content: '',
    dateEnd: format(new Date(), RFC3339Format),
    dateStart: format(new Date(), RFC3339Format),
    daySelection: createDefaultDaySelection(),
    flags: {
      barrierFree: false,
      dogsAllowed: false,
    },
    id: '',
    image: {
      thumbnail: {
        src: '',
      },
      small: {
        src: '',
      },
      original: {
        src: '',
      },
    },
    link: '',
    location: {
      address: '',
      ruianId: null,
      lat: null,
      lng: null,
      name: '',
    },
    price: null,
    timeEnd: '00:00:00',
    timeStart: '00:00:00',
    title: '',
    visibility: VisibilityEnum.PUBLIC,
  };
}
