export default {
  app: {
    error: {
      view: {
        error: 'chyba',
        forbidden: 'Přístup odepřen',
        forbiddenParagraph: 'Nemáte opravnění zobrazit tuto stránku.',
        generic: 'Nastala chyba, zkuste to prosím později.',
        notFound: 'Stránka nenalezena',
        notFoundParagraph1: 'Je nám líto, požadovaná stránka nebyla nalezena.',
        notFoundParagraph2:
          'Ujistěte se, že jste neudělali chybu v URL adrese.',
        notFoundParagraph3:
          'Je možné, že stránka byla přemístěna nebo smazána.',
      },
    },
  },
};
