import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { greyBackgroundColor } from '~/utils/theme/colors';
import { ButtonSize, CommentsButton, LikeButton } from '~/components/atoms';
import {
  LikeEvent,
  UnlikeEvent,
} from '~/components/atoms/likeButton/LikeButton';
import { ShowCommentsEvent } from '~/components/atoms/commentsButton/CommentsButton';

interface BottomToolbarInterface {
  commentsCount?: number | null;
  likeAvailable?: boolean;
  liked?: boolean;
  likes?: number | null;
  loadingLike?: boolean;
  onLike?: (event: LikeEvent) => void;
  onUnlike?: (event: UnlikeEvent) => void;
  onShowComments?: () => void;
}

@Component
export default class BottomToolbar extends VueComponent<BottomToolbarInterface>
  implements BottomToolbarInterface {
  @Prop({ default: null })
  public commentsCount!: number | null;

  @Prop({ type: Boolean, default: true })
  public likeAvailable!: boolean;

  @Prop({ type: Boolean, default: false })
  public liked!: boolean;

  @Prop({ type: Number, default: null })
  public likes!: number | null;

  @Prop({ type: Boolean, default: false })
  public loadingLike!: boolean;

  public render() {
    return (
      <div
        class={`d-flex flex-wrap flex-sm-nowrap justify-space-around pa-2 ${greyBackgroundColor}`}
      >
        <LikeButton
          active={this.likeAvailable && this.likes !== null}
          liked={this.liked}
          likes={this.likes}
          loading={this.loadingLike}
          size={ButtonSize.SMALL}
          onLike={this.emitLike}
          onUnlike={this.emitUnlike}
        />
        {this.$slots.left}
        {this.$slots.right}
        <CommentsButton
          active={this.commentsCount !== null}
          commentsCount={this.commentsCount}
          size={ButtonSize.SMALL}
          onShowComments={this.emitShowComments}
        />
      </div>
    );
  }

  @Emit('like')
  protected emitLike(): LikeEvent {
    return;
  }

  @Emit('unlike')
  protected emitUnlike(): UnlikeEvent {
    return;
  }

  @Emit('showComments')
  protected emitShowComments(): ShowCommentsEvent {
    return;
  }
}
