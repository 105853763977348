import { Component, Emit } from 'vue-property-decorator';
import BaseButton from '~/components/atoms/baseButton/BaseButton';

@Component
export default class AddButton extends BaseButton<{}> {
  public render() {
    return (
      <v-btn
        text
        color='secondary'
        {...this.baseAttributes}
        onClick={this.click}
      >
        {this.$slots.default}
        <v-icon right>mdi-plus-circle-outline</v-icon>
      </v-btn>
    );
  }

  @Emit('click')
  protected click() {
    return;
  }
}
