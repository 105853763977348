import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';

import CommonLocalization from './common';
import FooterLocalization from './footer';
import LocationLocalization from './location';
import MenuLocalization from './menu';
import PlaceLocalization from './place';
import RouterLocalization from './router';
import ShareLocalization from './share';
import WelcomeLocalization from './welcome';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  CommonLocalization,
  FooterLocalization,
  MenuLocalization,
  LocationLocalization,
  PlaceLocalization,
  RouterLocalization,
  ShareLocalization,
  WelcomeLocalization,
]) as LocaleMessages;

export default mergedLocalizations;
