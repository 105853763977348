import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import LanguagesList, {
  LanguageLocaleInterface,
} from '~/utils/localization/languageMap';
import { Icons } from '~/components/atoms';
import style from './LanguageSelector.scss';

export interface LanguageSelectorInterface {
  currentLanguage: LanguageLocaleInterface;
}

const rootClass = 'ys-language-selector';

@Component({
  style,
})
export default class LanguageSelector
  extends VueComponent<LanguageSelectorInterface>
  implements LanguageSelectorInterface {
  @Prop({ type: Object })
  public currentLanguage!: LanguageLocaleInterface;

  public render() {
    return (
      <div class={rootClass}>
        <v-menu
          eager
          offset-y
          scopedSlots={{
            activator: (scope: any) => {
              return (
                <a
                  title={this.$t('app.common.selectLanguage')}
                  {...{
                    on: scope.on,
                  }}
                >
                  <Icons.common.Language size={2} />
                  <span class={`${rootClass}__label`}>
                    {this.$t('app.common.language')}
                  </span>
                  <span class={`${rootClass}__current`}>
                    {this.currentLanguage.name}
                  </span>
                </a>
              );
            },
          }}
        >
          <v-list>
            {LanguagesList.filter(
              (item) => item.locale !== this.$i18n.locale
            ).map((lang) => (
              <v-list-item>
                <a href={`/${lang.locale}`} class={`${rootClass}__item`}>
                  {lang.name}
                </a>
              </v-list-item>
            ))}
          </v-list>
        </v-menu>
      </div>
    );
  }
}
