import { Component, Prop } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import VDialogCloseHandler, {
  VDialogCloseHandlerInterface,
} from '~/mixins/VDialogCloseHandler';
import appPreview from '~/assets/images/mobile_app.png';
import appStoreBadge from '~/assets/images/app_store_badge.png';
import googlePlayBadge from '~/assets/images/google_play_badge.png';
import { redTextColor } from '~/utils/theme/colors';

interface RegisterDialogInterface {
  active: boolean;
  noAccount?: boolean;
}

@Component
export default class RegisterDialog
  extends VueComponentMixin<
    RegisterDialogInterface,
    VDialogCloseHandlerInterface
  >(VDialogCloseHandler)
  implements RegisterDialogInterface {
  @Prop({ required: true })
  public active!: boolean;

  @Prop({ type: Boolean, default: false })
  public noAccount!: boolean;

  public render() {
    return (
      <v-dialog max-width='700' {...this.vDialogAttributes}>
        <v-card class='px-7 py-3' style='border-radius: 10px'>
          <v-btn icon absolute right onClick={this.vDialogClose}>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-container class='py-4' fluid>
            <v-row>
              <v-col cols={12} md={5}>
                <v-img src={appPreview} />
              </v-col>
              <v-col cols={12} md={6} offset-md={1}>
                {this.noAccount && (
                  <div>
                    <h2 class={redTextColor}>
                      {this.$t('app.registerDialog.noAccount')}
                    </h2>
                    <p>{this.$t('app.registerDialog.registrationRequired')}</p>
                  </div>
                )}
                <h2>{this.$t('app.registerDialog.headline')}</h2>
                <p>{this.$t('app.registerDialog.text')}</p>
                <br />
                <h4 class='mb-2'>{this.$t('app.registerDialog.actionText')}</h4>
                <div style='max-width: 60%'>
                  <v-img src={appStoreBadge} />
                  <br />
                  <v-img src={googlePlayBadge} />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    );
  }
}
