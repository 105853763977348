import apiUrl from './apiUrl';
import { Configuration } from './api/Configuration';
import {
  AreasOfInterestApi,
  CommentsApi,
  CommunitiesApi,
  EventsApi,
  FeedApi,
  PostsApi,
  UsersApi,
  WordPressAuthorsApi,
  RIANApi,
  SurveysApi,
  isApiFetchResponse,
  PlacesApi,
  ArtificialIntelligenceApi,
  CommunityMembershipsApi,
} from '~/app/core/apiClient/api';
import { getModule } from 'vuex-module-decorators';
import UserModule from '~/app/core/store/modules/UserModule';
import { Store } from 'vuex';
import { TranslateResult } from 'vue-i18n';

interface ErrorMessages {
  badRequest?: TranslateResult;
  conflict?: TranslateResult;
  forbidden?: TranslateResult;
  generic: TranslateResult;
}

export enum CommentType {
  POST = 'POST',
  EVENT = 'EVENT',
  SURVEY = 'SURVEY',
  COMMUNITY = 'COMMUNITY',
}

export default class {
  protected configuration: Configuration;

  protected userStore: UserModule;

  protected aiApi: ArtificialIntelligenceApi;

  protected areasOfInterestApi: AreasOfInterestApi;

  protected commentsApi: CommentsApi;

  protected communitiesApi: CommunitiesApi;

  protected communityMembershipApi: CommunityMembershipsApi;

  protected eventsApi: EventsApi;

  protected feedsApi: FeedApi;

  protected postsApi: PostsApi;

  protected placesApi: PlacesApi;

  protected ruianApi: RIANApi;

  protected surveysApi: SurveysApi;

  protected usersApi: UsersApi;

  protected wpAuthorsApi: WordPressAuthorsApi;

  public get areasOfInterest() {
    return this.areasOfInterestApi;
  }

  public get ai() {
    return this.aiApi;
  }

  public get communities() {
    return this.communitiesApi;
  }

  public get communityMembership() {
    return this.communityMembershipApi;
  }

  public get comments() {
    return this.commentsApi;
  }

  public get events() {
    return this.eventsApi;
  }

  public get feeds() {
    return this.feedsApi;
  }

  public get posts() {
    return this.postsApi;
  }

  public get places() {
    return this.placesApi;
  }

  public get ruian() {
    return this.ruianApi;
  }

  public get surveys() {
    return this.surveysApi;
  }

  public get users() {
    return this.usersApi;
  }

  public get wpAuthors() {
    return this.wpAuthorsApi;
  }

  public constructor(store: Store<any>) {
    this.userStore = getModule(UserModule, store);
    this.configuration = new Configuration({
      basePath: apiUrl,
      accessToken: (name, scope) => this.accessToken(),
    });

    const fetchWithCredential = (input: RequestInfo, init: RequestInit) => {
      // Check whether the user has credentials before accessing api
      return this.userStore
        .refreshUserToken()
        .catch(() => {
          // If the refresh token could not be fetched, just ignore it, we will get an error response from aPI
        })
        .then(() => {
          return fetch(input, init);
        });
    };

    this.aiApi = new ArtificialIntelligenceApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
    this.areasOfInterestApi = new AreasOfInterestApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
    this.commentsApi = new CommentsApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
    this.communitiesApi = new CommunitiesApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
    this.communityMembershipApi = new CommunityMembershipsApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
    this.eventsApi = new EventsApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
    this.feedsApi = new FeedApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
    this.postsApi = new PostsApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
    this.placesApi = new PlacesApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
    this.ruianApi = new RIANApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
    this.surveysApi = new SurveysApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
    this.usersApi = new UsersApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
    this.wpAuthorsApi = new WordPressAuthorsApi(
      this.configuration,
      undefined,
      fetchWithCredential
    );
  }

  public handleApiErrorMessages(
    err: any,
    messages: ErrorMessages
  ): Promise<TranslateResult> {
    if (isApiFetchResponse(err)) {
      return err
        .json()
        .then((result) => {
          switch (result.status) {
            case 403:
              return messages.forbidden || messages.generic;
            case 400:
              return messages.badRequest || messages.generic;
            case 409:
              return messages.conflict || messages.generic;
            default:
              return messages.generic;
          }
        })
        .catch(() => {
          return messages.generic;
        });
    }

    return Promise.resolve(messages.generic);
  }

  protected accessToken(): string {
    return this.userStore.accessToken || '';
  }
}
