export default {
  app: {
    group: {
      areasOfInterest: 'Oblasti zájmu skupiny',
      basicDataStep: 'Základní informace',
      chooseAreasOfInterest: 'Vyberte oblasti zájmu',
      createGroup: 'Založit skupinu',
      description: 'Popis skupiny',
      editGroup: 'Upravit skupinu',
      groupChat: 'Skupinová diskuze',
      groupName: 'Název',
      groups: 'Skupiny',
      joinPrivateHint:
        'Tato skupina je soukromá. Její příspěvky uvidíte teprve poté, co budete členem.',
      kind: 'Skupina',
      manageGroup: 'Spravovat skupinu',
      mapTitle: 'Místo skupiny',
      metaDataStep: 'Zaměření skupiny',
      myGroups: 'Moje skupiny',
      name: 'Název skupiny',
      officialLabel: 'Oficiální skupina',
      place: 'Místo skupiny',
      private: 'Soukromá',
      privateLabel: 'Soukromá skupina',
      privateHint: 'Soukromé příspěvky, žádosti o členství',
      public: 'Veřejná',
      publicLabel: 'Veřejná skupina',
      publicHint: 'Veřejné příspěvky, kdokoliv se může přidat',
      visibility: 'Viditelnost skupiny',
      title: 'Skupiny',
      update: 'Upravit skupinu',
    },
  },
};
