import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { rootClass } from '~/components/atoms/icons/Icon';

@Component
export default class CsFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class={`${rootClass}--flag ${rootClass}--gb`}>
        <svg
          viewBox='0 0 32 32'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          {/*# TODO: Add icon*/}
        </svg>
      </i>
    );
  }
}
