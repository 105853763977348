import { Component, Prop } from 'vue-property-decorator';

import { CzYsP14KcCommunityApiDtoCommunityDetailObject } from '~/app/core/apiClient/api';
import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';
import { GroupItem, MCP14_GROUP_ID } from '~/utils/group';
import { VueComponent } from '~/utils/vue-component';

import MembershipTypeEnum = CzYsP14KcCommunityApiDtoCommunityDetailObject.MembershipTypeEnum;

interface GroupChipInterface {
  group: GroupItem;
}

@Component
export default class GroupChip extends VueComponent<GroupChipInterface>
  implements GroupChipInterface {
  @Prop({ required: true })
  public group!: GroupItem;

  protected get color() {
    if (this.group.id === MCP14_GROUP_ID) {
      return 'secondary';
    }
    switch (this.group.membershipType) {
      case MembershipTypeEnum.OWNER:
        return 'accent';
      case MembershipTypeEnum.DEPUTY:
        return 'accent';
      case MembershipTypeEnum.MEMBER:
        return 'tertiary';
      default:
        // Not a member
        return 'secondary';
    }
  }

  protected get route() {
    return getLocalizedLocation(routes.groupDetail, this.$router, {
      guid: this.group.id,
    });
  }

  public render() {
    return (
      <v-chip
        to={this.route}
        text-color={this.color}
        color={`${this.color} lighten-4`}
      >
        {this.group.name}
      </v-chip>
    );
  }
}
