import { VueComponent } from '~/utils/vue-component';
import { Component, Prop } from 'vue-property-decorator';

import { format, parse } from '~/utils/date-fns';
import EventTimes from '~/components/molecules/eventTimes/EventTimes';
import {
  BottomToolbar,
  ErrorSnack,
  EventImage,
  SuccessSnack,
} from '~/components/molecules';
import { EventInstanceItem } from '~/utils/event';
import { EventDetailLink, GroupChip } from '~/components/atoms';
import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';
import { routeParameterDateFormat } from '~/app/core/router/routes/parameters/date';
import { EventImageContext } from '~/components/molecules/eventImage/EventImage';
import { GroupItem, Kind } from '~/utils/group';
import ToolbarSubmenu, {
  ShareButtonProps,
  ToolbarButtonProps,
} from '~/components/molecules/toolbarSubmenu/ToolbarSubmenu';
import { createShareUri } from '~/utils/http/links';
import { getModule } from 'vuex-module-decorators';
import UserModule from '~/app/core/store/modules/UserModule';
import { LocationBlock } from '~/components/molecules';
import { GeoLocation } from '~/utils/location';
import EventsModule from '~/app/core/store/modules/EventsModule';
import { commentsFragment } from '~/utils/comments';

interface EventListItemInterface {
  event: EventInstanceItem;
}

interface EventListItemSnacks {
  copied: boolean;
  reported: boolean;
  reportFailed: boolean;
}

export interface EventItemComponent {
  timeFrom?: string;
  timeTo?: string;
  title?: string;
  place: GeoLocation;
  imageUrl?: string;
  id: string;
  interests?: string[];
  date: string;
  group?: GroupItem;
  perex: string;
}

@Component
export default class EventTile extends VueComponent<EventListItemInterface> {
  @Prop({ required: true })
  public event!: EventInstanceItem;

  protected eventActions: EventListItemSnacks = {
    copied: false,
    reported: false,
    reportFailed: false,
  };

  protected reportingEvent: boolean = false;

  protected get date() {
    return parse(this.event.date);
  }

  protected get eventsStore() {
    return getModule(EventsModule, this.$store);
  }

  protected get userStore() {
    return getModule(UserModule, this.$store);
  }

  protected get route() {
    if (!this.event) {
      return null;
    }

    const date = parse(this.event.date);
    if (isNaN(date.getTime())) {
      return null;
    }

    return getLocalizedLocation(routes.eventDetail, this.$router, {
      guid: this.event.id,
      date: format(date, routeParameterDateFormat),
    });
  }

  protected get reportButton(): ToolbarButtonProps | undefined {
    if (!this.userStore.user) {
      return;
    }

    return {
      disabled: this.eventActions.reported,
      loading: this.reportingEvent,
    };
  }

  protected get shareButton(): ShareButtonProps | undefined {
    if (isNaN(this.date.getTime())) {
      return;
    }
    return {
      disabled: false,
      loading: false,
      uri: createShareUri(
        this.$router.resolve(
          getLocalizedLocation(routes.eventPreview, this.$router, {
            date: format(this.date, routeParameterDateFormat),
            guid: this.event.id,
          })
        ).href
      ),
    };
  }

  public render() {
    return (
      <v-card rounded>
        <v-card-title class='py-4 flex-nowrap'>
          <h2 class='text-truncate pb-1 flex-shrink-1'>
            <router-link class='text-decoration-none' to={this.route}>
              {this.event.title}
            </router-link>
          </h2>

          <v-spacer />

          {this.event.group && (
            <GroupChip class='mr-2 flex-shrink-0' group={this.event.group} />
          )}

          <ToolbarSubmenu
            class='flex-shrink-0'
            report={this.reportButton}
            share={this.shareButton}
            onReport={this.reportEvent}
            onShare={() => {
              this.eventActions.copied = true;
            }}
          />
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col cols='12' md='3'>
              <v-card to={this.route} rounded='lg' flat>
                <EventImage
                  event={this.event}
                  context={EventImageContext.TILE}
                />
              </v-card>
            </v-col>
            <v-col>
              {this.event.perex && this.event.perex + ' '}
              <EventDetailLink event={this.event} />
            </v-col>
            <v-col class='d-flex pt-0' cols='12'>
              <LocationBlock location={this.event.place} />
              <v-spacer />
              {this.event.group?.kind !== Kind.PROJECT && (
                <EventTimes
                  start={this.event.timeStart}
                  end={this.event.timeEnd}
                />
              )}
            </v-col>
          </v-row>
        </v-card-text>

        <BottomToolbar
          commentsCount={this.event.comments}
          liked={this.event.liked}
          likes={this.event.likes}
          loadingLike={this.event.state.liking}
          onLike={this.likeEvent}
          onUnlike={this.unlikeEvent}
          onShowComments={() => {
            this.$router.push({
              ...this.route,
              hash: commentsFragment,
            });
          }}
        />
        <ErrorSnack v-model={this.eventActions.reportFailed}>
          {this.$t('app.error.event.reportFailed')}
        </ErrorSnack>

        <SuccessSnack v-model={this.eventActions.reported}>
          {this.$t('app.events.reported')}
        </SuccessSnack>
        <SuccessSnack v-model={this.eventActions.copied}>
          {this.$t('app.share.success')}
        </SuccessSnack>
      </v-card>
    );
  }

  protected likeEvent() {
    this.eventsStore.like({ id: this.event.id });
  }

  protected unlikeEvent() {
    this.eventsStore.unlike({ id: this.event.id });
  }

  protected reportEvent() {
    this.reportingEvent = true;
    this.$api.events
      .toxicEvent(this.event.id)
      .then(() => {
        this.eventActions.reported = true;
      })
      .catch((_) => {
        this.eventActions.reportFailed = true;
      })
      .finally(() => {
        this.reportingEvent = false;
      });
  }
}
