import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { ValueProp, VueComponent } from '~/utils/vue-component';
import { MapPicker } from '~/components/organisms';
import { createRuianLocationItem, RuianLocationItem } from '~/utils/ruian';

import style from './SelectPlace.scss';
import {
  createEmptyGeoLocation,
  GeoLocation,
  isLocationEmpty,
} from '~/utils/location';
import { MapLocation } from '~/components/organisms/mapPicker/MapPicker';

export interface SelectPlaceInterface extends ValueProp<GeoLocation> {
  groupId?: string;
}

export interface AddressSearch {
  enabled: boolean;
  lastSearchTerm: string;
  loading: boolean;
  results: RuianLocationItem[];
  term: string;
}

type NameSearch = AddressSearch;

const originalAddressRuianIdentifier = 'original-address';

interface VuetifyAutocomplete extends Vue {
  activateMenu: () => void;
}

const rootClass = 'ys-select-place';

@Component({ style })
export default class SelectPlace extends VueComponent<SelectPlaceInterface>
  implements SelectPlaceInterface {
  @Prop({ required: true })
  public value!: GeoLocation;

  @Prop({ type: String })
  public groupId?: string;

  @Ref('addressSelect')
  protected readonly addressSelect!: VuetifyAutocomplete;

  protected geoCoding: boolean = false;

  protected addressSearch: AddressSearch = {
    enabled: false,
    lastSearchTerm: '',
    loading: false,
    results: [],
    term: '',
  };

  protected nameSearch: NameSearch = {
    // We do not use this flag for name search
    enabled: true,
    lastSearchTerm: '',
    loading: false,
    results: [],
    term: '',
  };

  protected get isLocationEmpty(): boolean {
    return isLocationEmpty(this.value);
  }

  protected get mapLocation(): MapLocation | null {
    const { lat, lng } = this.value;
    if (lat === null || lng === null) {
      return null;
    }

    return {
      lat,
      lng,
    };
  }

  protected get addressSearchResults(): AddressSearch['results'] {
    const items: AddressSearch['results'] = [];

    if (!this.isLocationEmpty) {
      items.push(
        createRuianLocationItem({
          ...this.value,
          latitude: this.value.lat || undefined,
          longitude: this.value.lng || undefined,
          ruianId: this.value.ruianId || undefined,
        })
      );
    }

    return items.concat(this.addressSearch.results);
  }

  protected get pickerClasses(): string {
    const classes = [`${rootClass}__picker`];
    if (this.geoCoding) {
      classes.push(`${rootClass}__picker--geocoding`);
    }

    return classes.join(' ');
  }

  public render() {
    return (
      <v-card flat class={rootClass}>
        <v-card-text class='pt-4 px-0'>
          {this.groupId ? (
            <v-combobox
              autocomplete='off'
              value={this.value.name || null}
              items={this.nameSearch.results}
              item-text='name'
              item-value='name'
              no-filter={true}
              loading={this.nameSearch.loading}
              no-data-text={
                this.nameSearch.term.length < 3
                  ? this.$t('app.place.startTypingName')
                  : ''
              }
              return-object
              label={this.$t('app.place.name')}
              search-input={this.nameSearch.term}
              filled
              rounded
              {...{
                on: {
                  'update:search-input': (value: string) => {
                    this.nameSearch.term =
                      (value && value.replace(/^\s+/, '')) || '';
                  },
                  blur: () => {
                    this.updateName(this.nameSearch.term);
                    this.resetNameSearch();
                  },
                  input: (value?: RuianLocationItem) => {
                    if (!value) {
                      return;
                    } else {
                      if (value.ruianId === originalAddressRuianIdentifier) {
                        // Same place was selected
                        return;
                      }
                      const { lat, lng } = value;
                      if (!lat || !lng) {
                        this.nameSearch.term = value.name || '';
                        return;
                      }
                      this.setLocation({
                        ...value,
                      });
                    }
                  },
                },
              }}
            />
          ) : (
            <v-text-field
              value={this.value.name}
              label={this.$t('app.place.name')}
              onInput={this.updateName}
              filled
              rounded
            />
          )}
          <v-autocomplete
            autocomplete='off'
            value={this.value.address || null}
            items={this.addressSearchResults}
            item-text='address'
            item-value='address'
            no-filter={true}
            loading={this.addressSearch.loading}
            no-data-text={this.$t('app.place.startTypingAddress')}
            ref='addressSelect'
            return-object
            label={this.$t('app.place.address')}
            search-input={this.addressSearch.term}
            filled
            rounded
            {...{
              on: {
                'update:search-input': (value: string) => {
                  this.addressSearch.term =
                    (value && value.replace(/^\s+/, '')) || '';
                },
                blur: () => {
                  this.resetAddressSearch();
                },
                keydown: () => {
                  this.addressSearch.enabled = true;
                },
                input: (value?: RuianLocationItem) => {
                  this.addressSearch.enabled = false;
                  if (!value) {
                    this.setLocation(createEmptyGeoLocation());
                  } else {
                    if (value.ruianId === originalAddressRuianIdentifier) {
                      // Same address was selected
                      return;
                    }
                    const { lat, lng } = value;
                    if (!lat || !lng) {
                      if (value.address) {
                        this.addressSearch.enabled = true;
                        this.searchRuian(value.address).then((_) => {
                          this.addressSelect.activateMenu();
                          this.addressSearch.term = value.address || '';
                        });
                      }
                      return;
                    }
                    this.setLocation({
                      ...this.value,
                      address: value.address,
                      lat: value.lat,
                      lng: value.lng,
                      ruianId: value.ruianId || null,
                    });
                  }
                },
              },
            }}
          />

          <div class={this.pickerClasses}>
            <v-progress-linear
              active={this.geoCoding}
              absolute
              indeterminate
              height={20}
            >
              <span class='caption white--text'>
                {this.$t('app.place.loadingAddress')}
              </span>
            </v-progress-linear>
            <MapPicker
              value={this.mapLocation}
              onGeoCodedAddress={(event) => {
                this.setLocation({
                  ...this.value,
                  address: event.address,
                  ruianId: null,
                });
              }}
              onGeoCoding={(state) => {
                this.geoCoding = state;
              }}
              onInput={(location) => {
                this.resetAddressSearch();
                this.resetNameSearch();

                if (location === null) {
                  this.setLocation({
                    ...this.value,
                    lat: null,
                    lng: null,
                    ruianId: null,
                  });
                  return;
                }

                this.setLocation({
                  ...this.value,
                  lat: location.lat,
                  lng: location.lng,
                  ruianId: null,
                });
              }}
            />
          </div>
        </v-card-text>
      </v-card>
    );
  }

  /**
   * Append original address to the autocomplete so it is always available
   *
   * @protected
   */
  @Watch('value', { immediate: true })
  protected appendOriginalAddress() {
    if (this.value.address) {
      for (const ruian of this.addressSearch.results) {
        if (ruian.address === this.value.address) {
          return;
        }
      }

      const ruianItem = createRuianLocationItem({
        ruianId: originalAddressRuianIdentifier,
        address: this.value.address,
        latitude: this.value.lat || 0,
        longitude: this.value.lng || 0,
      });
      if (!ruianItem) {
        return;
      }

      this.addressSearch.results.push(ruianItem);
    }
  }

  @Watch('addressSearch.term')
  protected searchRuian(value: string) {
    if (!this.addressSearch.enabled) {
      return Promise.resolve();
    }

    if (this.addressSearch.lastSearchTerm === value) {
      return Promise.resolve();
    }

    this.addressSearch.lastSearchTerm = value;

    if (value.length < 2) {
      this.addressSearch.results = [];
      return Promise.resolve();
    }

    this.addressSearch.loading = true;
    return this.$api.ruian
      .listStreets(value)
      .then((result) => {
        if (result.length < 1) {
          return;
        }

        if (this.addressSearch.lastSearchTerm === value) {
          // In case of race condition, update this field only if it is the current search term
          this.addressSearch.results = result.map(createRuianLocationItem);
        }
      })
      .finally(() => {
        if (this.addressSearch.lastSearchTerm === value) {
          // In case of race condition, update this field only if it is the current search term
          this.addressSearch.loading = false;
        }
      });
  }

  @Watch('nameSearch.term')
  protected searchPlace(value: string) {
    if (!this.groupId || this.nameSearch.lastSearchTerm === value) {
      return Promise.resolve();
    }

    if (value.length < 1) {
      this.nameSearch.results = [];
      return Promise.resolve();
    }

    this.nameSearch.loading = true;
    return this.$api.places
      .findCommunityPlace(value, this.groupId)
      .then((result) => {
        if (result.length < 1) {
          return;
        }

        this.nameSearch.results = result.map(createRuianLocationItem);
        this.nameSearch.lastSearchTerm = value;
      })
      .finally(() => {
        this.nameSearch.loading = false;
      });
  }

  @Emit('input')
  protected setLocation(location: GeoLocation): SelectPlace['value'] {
    return {
      ...location,
    };
  }

  @Emit('input')
  protected updateName(name: string): SelectPlace['value'] {
    return {
      ...this.value,
      name,
    };
  }

  protected resetNameSearch() {
    this.nameSearch = {
      // We do not use this flag for name search
      enabled: true,
      term: '',
      lastSearchTerm: '',
      loading: false,
      results: [],
    };
  }

  protected resetAddressSearch() {
    this.addressSearch = {
      enabled: false,
      term: '',
      lastSearchTerm: '',
      loading: false,
      results: [],
    };
  }
}
