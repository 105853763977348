export default {
  app: {
    welcome: {
      title: 'Vítejte na komunitním webu Prahy 14',
      paragraph1:
        'Máte zájem se aktivně podílet na dění Prahy 14? Náš komunitní web Vás zapojí do přímého dění nnaší městské části. Mějte <b>přehled o všem</b>, co se na Praze 14 <b>dělo, děje i bude dít</b>.',
      benefit1:
        'Zůstaňte ve spojení s členy vaší skupiny i z prostředí webového prohlížeče.',
      benefit2:
        'Zakládejte rychle a snadno události, příspěvky či jiný obsah do vaší skupiny.',
      benefit3:
        'Nezapomeňte sledovat aktuální dění z mobilní aplikace, která navíc přináší mnoho pokročilých možností!',
    },
  },
};
