export default {
  app: {
    eventListItem: {
      imageAlt: 'Obrázek eventu',
      shareButtonLabel: 'Sdílet',
      likeButtonLabel: 'Like',
      detailButtonLabel: 'Detail akce',
      from: 'Od',
      to: 'Do',
      allDay: 'Celý den',
    },
  },
};
