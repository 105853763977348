import { EventInstanceItem } from './';
import {
  CzYsP14KcEventApiDtoEventInstanceDetailObject,
  CzYsP14KcEventApiDtoEventInstanceObject,
} from '~/app/core/apiClient/api';
import {
  AreaOfInterestItem,
  mapAreasOfInterest,
} from '~/utils/areasOfInterest';
import { format, parse } from '~/utils/date-fns';
import { RFC3339Format } from '~/utils/dateTime';
import getEventImageUrl from '~/utils/event/getEventImageUrl';
import createGroupItem from '~/utils/group/createGroupItem';
import { createEmptyGeoLocation, GeoLocation } from '~/utils/location';
import { ImageSize, imageVersion } from '~/utils/media';

type Input =
  | CzYsP14KcEventApiDtoEventInstanceDetailObject
  | CzYsP14KcEventApiDtoEventInstanceObject;

function isEventInstanceObject(
  data: any
): data is CzYsP14KcEventApiDtoEventInstanceObject {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.id === 'string' &&
    data.community &&
    typeof data.community === 'object' &&
    typeof data.title === 'string' &&
    typeof data.content === 'string' &&
    typeof data.date === 'string' &&
    typeof data.likeCount === 'number' &&
    typeof data.commentCount === 'number' &&
    (typeof data.timeStart === 'string' || data.timeStart === null) &&
    (typeof data.timeEnd === 'string' || data.timeEnd === null) &&
    data.place &&
    typeof data.place === 'object'
  );
}

function isEventInstanceDetailObject(
  data: any
): data is CzYsP14KcEventApiDtoEventInstanceDetailObject {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.id === 'string' &&
    data.community &&
    typeof data.community === 'object' &&
    data.user &&
    typeof data.user === 'object' &&
    typeof data.title === 'string' &&
    typeof data.content === 'string' &&
    typeof data.dateStart === 'string' &&
    typeof data.dateEnd === 'string' &&
    typeof data.likeCount === 'number' &&
    (typeof data.timeStart === 'string' || data.timeStart === null) &&
    (typeof data.timeEnd === 'string' || data.timeEnd === null) &&
    data.place &&
    typeof data.place === 'object'
  );
}

export default function(
  data: Input,
  allAreas: AreaOfInterestItem[]
): EventInstanceItem | null {
  if (!data.id || !data.title) {
    return null;
  }

  let areasOfInterest: AreaOfInterestItem[] = [];
  let comments = null;
  let likes = null;
  const place: GeoLocation = createEmptyGeoLocation();
  let start: Date | null = null;

  if (data.place) {
    place.address = data.place.address || '';
    place.name = data.place.name || '';
    place.lat = data.place.latitude || null;
    place.lng = data.place.longitude || null;
  }
  if (typeof data.commentCount !== 'undefined') {
    comments = data.commentCount;
  }

  if (typeof data.likeCount !== 'undefined') {
    likes = data.likeCount;
  }

  if (isEventInstanceDetailObject(data)) {
    if (data.areasOfInterest) {
      areasOfInterest = mapAreasOfInterest(data.areasOfInterest, allAreas);
    }
    if (data.dateStart) {
      start = parse(data.dateStart);
    }
  } else if (isEventInstanceObject(data)) {
    if (data.date) {
      start = parse(data.date);
    }
  }

  if (!start || isNaN(start.getTime())) {
    return null;
  }

  const mediaVersion = imageVersion();

  return {
    areasOfInterest,
    comments,
    content: data.content || '',
    date: format(start, RFC3339Format),
    group: data.community ? createGroupItem(data.community, allAreas) : null,
    id: data.id,
    image: {
      thumbnail: {
        src: getEventImageUrl(data.id, mediaVersion, ImageSize.THUMBNAIL),
      },
      small: {
        src: getEventImageUrl(data.id, mediaVersion, ImageSize.SMALL),
      },
      original: {
        src: getEventImageUrl(data.id, mediaVersion, ImageSize.ORIGINAL),
      },
    },
    liked: !!(data.user && data.user.liked),
    likes,
    perex: data.content || '',
    place,
    reported: false,
    state: {
      liking: false,
      loading: false,
      reporting: false,
    },
    timeEnd: data.timeEnd || null,
    timeStart: data.timeStart || null,
    title: data.title,
  };
}
