import createSurveyItem from './createSurveyItem';
import { CzYsP14KcSurveyApiDtoSurveyObject } from '~/app/core/apiClient/api';
import VisibilityEnum = CzYsP14KcSurveyApiDtoSurveyObject.VisibilityEnum;
import { ImageItem } from '~/utils/media';
import { FeedType } from '~/utils/feed';
import { AreaOfInterestItem } from '~/utils/areasOfInterest';

const icons = {
  create: 'mdi-poll-box',
};

interface SurveyState {
  active: boolean;
  liking: boolean;
  loading: boolean;
  reporting: boolean;
  voting: boolean;
}

interface VoteOption {
  beingVoted: boolean;
  count: number;
  id: string | null;
  name: string;
  percentage: number;
}

interface VoteState {
  date: string;
  option: string;
  isDirty: boolean;
}

interface SurveyItem {
  areasOfInterest: AreaOfInterestItem[];
  comments: number;
  content: string;
  created: string;
  end: string;
  excerpt: string;
  group: {
    id: string;
    name: string;
  };
  id: string;
  image: {
    thumbnail: ImageItem;
    small: ImageItem;
    original: ImageItem;
  };
  liked: boolean;
  likes: number;
  options: VoteOption[];
  reported: boolean;
  state: SurveyState;
  title: string;
  type: FeedType;
  visibility: VisibilityEnum;
  voted: VoteState | null;
}

function isSurveyItem(data: any): data is SurveyItem {
  if (!data || typeof data !== 'object') {
    return false;
  }

  return data.type === FeedType.SURVEY;
}

export {
  createSurveyItem,
  icons,
  isSurveyItem,
  SurveyItem,
  VoteOption,
  VoteState,
};
