import { Component, Prop } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import VDialogCloseHandler, {
  VDialogCloseHandlerInterface,
} from '~/mixins/VDialogCloseHandler';
import { EditSurveyForm } from '~/components/templates';
import { SurveyItem } from '~/utils/survey';
import { GroupItem } from '~/utils/group';

interface EditSurveyModalInterface {
  surveyId?: SurveyItem['id'] | null;
  groupId?: GroupItem['id'] | null;
}

@Component
export default class EditSurveyModal
  extends VueComponentMixin<
    EditSurveyModalInterface,
    VDialogCloseHandlerInterface
  >(VDialogCloseHandler)
  implements EditSurveyModalInterface {
  @Prop({ default: null })
  public surveyId!: SurveyItem['id'] | null;

  @Prop({ default: null })
  public groupId!: GroupItem['id'] | null;

  public created() {
    if (this.setPersistent) {
      this.setPersistent();
    }
  }

  public render() {
    return (
      <v-dialog max-width='900' scrollable {...this.vDialogAttributes}>
        <EditSurveyForm
          surveyId={this.surveyId}
          groupId={this.groupId}
          onCancel={this.close}
        />
      </v-dialog>
    );
  }

  protected close() {
    if (this.vDialogClose) {
      this.vDialogClose();
    }
  }
}
