import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { format } from '~/utils/date-fns';

interface DateStickerInterface {
  date: Date;
  showTime?: boolean;
}

@Component
export default class DateSticker extends VueComponent<DateStickerInterface>
  implements DateStickerInterface {
  @Prop({ type: Date, required: true })
  public date!: Date;

  @Prop({ type: Boolean, default: false })
  public showTime!: boolean;

  public render() {
    return (
      <v-avatar color='white' size={80}>
        <div class='text-center black--text'>
          <div class='headline'>{format(this.date, 'DD')}</div>
          <div class='text-lowercase'>{format(this.date, 'MMM')}</div>
          {this.showTime && (
            <span class='caption'>{format(this.date, 'HH:mm')}</span>
          )}
        </div>
      </v-avatar>
    );
  }
}
