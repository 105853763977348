export default {
  app: {
    place: {
      address: 'Adresa',
      choose: 'Vybrat místo',
      loadingAddress: 'Probíhá načítání adresy',
      specify: 'Upřesnit zadání místa',
      submit: 'Potvrdit polohu',
      name: 'Název místa',
      reset: 'Zrušit',
      showMap: 'Zobrazit mapu',
      startTypingAddress: 'Začněte psát název ulice',
      startTypingName: 'Začněte psát název lokality, nebo zadejte novou',
    },
  },
};
