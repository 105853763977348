import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import AreasOfInterestModule from './AreasOfInterestModule';
import { CzYsP14KcCommunityApiDtoCommunityDetailObject } from '~/app/core/apiClient/api';
import { AreaOfInterestItem } from '~/utils/areasOfInterest';
import {
  CommunityItem,
  GroupItem,
  Kind,
  ProjectPriority,
  ProjectState,
  Visibility,
} from '~/utils/group';
import { GeoLocation } from '~/utils/location';
import GroupModule from '~/app/core/store/modules/GroupModule';
import createGroupItem from '~/utils/group/createGroupItem';
import MembershipTypeEnum = CzYsP14KcCommunityApiDtoCommunityDetailObject.MembershipTypeEnum;

interface StoreDataCommit {
  group: GroupItem;
}

interface AreasOfInterestCommit {
  allItems: AreaOfInterestItem[];
}

@Module({
  name: 'GroupDetailModule',
  stateFactory: true,
  namespaced: true,
})
export default class GroupDetailModule extends AbstractModule {
  public id: string = '';
  public name: string = '';
  public description: string = '';
  public editable: boolean = false;
  public image: string = '';
  public kind: Kind = Kind.GROUP;
  public areasOfInterest: AreaOfInterestItem[] = [];
  public areasOfInterestIds: string[] = [];
  public visibility: Visibility | null = null;
  public members: number = 0;
  public membershipType: MembershipTypeEnum | null = null;
  public loading: boolean = false;
  public location: GeoLocation = {
    address: '',
    ruianId: null,
    lat: null,
    lng: null,
    name: '',
  };

  /**
   * Future proofing, do not save everything as separate props, use group prop
   */
  public group: CommunityItem | null = null;

  @Action({ commit: 'storeData', rawError: true })
  public loadData(groupId: string): Promise<StoreDataCommit> {
    this.setLoading(true);

    return getModule(AreasOfInterestModule, this.store)
      .loadData()
      .then((areas) => {
        return this.$api.communities.getCommunity(groupId).then((groupData) => {
          this.createAreasOfInterest({ allItems: areas.allItems });

          const groupItem = createGroupItem(groupData, this.areasOfInterest);
          if (groupItem === null) {
            throw new Error('Could not create group');
          }

          getModule(GroupModule, this.store).cacheGroups([groupItem]);

          return {
            group: groupItem,
          };
        });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  protected storeData(data: StoreDataCommit) {
    this.id = data.group.id;
    this.kind = data.group.kind;
    this.loading = false;
    this.name = data.group.name;
    this.description = data.group.description;
    this.editable = data.group.state.editable;
    this.members = data.group.members || 0;
    this.membershipType = data.group.membershipType;
    this.image = data.group.image.original.src;
    this.visibility = data.group.visibility;
    this.location = data.group.location;
    this.areasOfInterestIds = data.group.areasOfInterest.map((area) => area.id);
    this.group = { ...data.group };
  }

  @Mutation
  protected createAreasOfInterest(data: AreasOfInterestCommit) {
    this.areasOfInterest = [];
    this.areasOfInterestIds.map((id) => {
      for (const item of data.allItems) {
        if (id === item.id) {
          this.areasOfInterest.push(item);
        }
      }
    });
  }

  @Mutation
  protected setLoading(state: boolean) {
    this.loading = state;
  }
}
