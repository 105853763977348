import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseButton from '~/components/atoms/baseButton/BaseButton';
import { getModule } from 'vuex-module-decorators';
import UserModule from '~/app/core/store/modules/UserModule';

export type LikeEvent = void;

export type UnlikeEvent = void;

export interface LikeButtonInterface {
  active?: boolean;
  liked: boolean;
  likes?: number | null;
  onLike?: (event: LikeEvent) => void;
  onUnlike?: (event: UnlikeEvent) => void;
}

@Component
export default class LikeButton extends BaseButton<LikeButtonInterface>
  implements LikeButtonInterface {
  @Prop({ default: true })
  public active!: boolean;

  @Prop({ required: false, type: Boolean })
  public liked!: boolean;

  @Prop({ default: null })
  public likes!: number | null;

  protected get text() {
    return this.$t(this.liked ? 'app.common.unlike' : 'app.common.like');
  }

  protected get icon() {
    return this.liked ? 'mdi-thumb-down-outline' : 'mdi-thumb-up-outline';
  }

  protected get userStore() {
    return getModule(UserModule, this.$store);
  }

  public render() {
    if (!this.active) {
      return;
    }

    return (
      <v-btn onClick={this.buttonClickHandler} text {...this.baseAttributes}>
        <v-icon left>{this.icon}</v-icon>
        {this.text}
        {this.likes !== null ? ` (${this.likes})` : ''}
      </v-btn>
    );
  }

  protected buttonClickHandler() {
    if (!this.userStore.user) {
      return;
    }

    if (this.liked) {
      this.emitUnlike();
    } else {
      this.emitLike();
    }
  }

  @Emit('like')
  protected emitLike(): LikeEvent {
    return;
  }

  @Emit('unlike')
  protected emitUnlike(): UnlikeEvent {
    return;
  }
}
