import { VueComponent } from '~/utils/vue-component';
import { Component, Prop } from 'vue-property-decorator';

import Firebase from 'firebase';
import 'firebaseui/dist/firebaseui.css';
import firebaseui from 'firebaseui/dist';
import { getModule } from 'vuex-module-decorators';
import UserModule from '~/app/core/store/modules/UserModule';
import AuthUI = firebaseui.auth.AuthUI;

import style from './FirebaseUi.scss';

const rootClass: string = 'ys-firebase-ui';

export interface FirebaseUiInterface {
  options: firebaseui.auth.Config;
}

@Component({ style })
export default class FirebaseUi extends VueComponent<FirebaseUiInterface>
  implements FirebaseUiInterface {
  @Prop({ type: Object })
  public options!: firebaseui.auth.Config;

  protected authUi!: AuthUI | null;

  public mounted() {
    const userModule = getModule(UserModule, this.$store);
    userModule.initAuth();

    // it touches window object -> can't import immediately with SSR activated
    import('firebaseui').then((lib) => {
      this.authUi =
        lib.auth.AuthUI.getInstance() ||
        new lib.auth.AuthUI(userModule.firebaseAuth);

      Firebase.auth().onAuthStateChanged((user) => {
        if (!this.authUi) {
          return;
        }

        if (user) {
          this.authUi.reset();
        } else {
          this.authUi.start(`.${rootClass}`, this.options);
        }
      });
    });
  }

  public beforeDestroy() {
    if (this.authUi) {
      this.authUi.delete();
      this.authUi = null;
    }
  }

  public render() {
    return <div class={rootClass}></div>;
  }
}
