import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';
import Vuetify from 'vuetify/es5/locale/en';
import CommentLocalization from './comment';
import CommunityLocalization from './community';
import ErrorLocalization from './error';
import EventLocalization from './event';
import FeedLocalization from './feed';
import GeneralLocalization from './general';
import PostLocalization from './post';
import SurveyLocalization from './survey';
import UserLocalization from './user';

const localizations: LocaleMessages[] = [
  CommentLocalization,
  CommunityLocalization,
  ErrorLocalization,
  EventLocalization,
  FeedLocalization,
  GeneralLocalization,
  PostLocalization,
  SurveyLocalization,
  UserLocalization,
  {
    $vuetify: Vuetify,
  },
];

const mergedLocalizations: LocaleMessages = deepmerge.all(
  localizations
) as LocaleMessages;

export default mergedLocalizations;

export function choiceIndex(choice: number, choicesLength: number): number {
  let index = 3;

  if (choice === 0 || choice === 1) {
    index = choice;
  } else if (choice < 5) {
    index = 2;
  }

  return choicesLength < index ? choicesLength : index;
}
