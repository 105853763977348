import { Action, getModule, Module } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import {
  CzYsP14KcCommunityApiDtoCommunityDetailObject,
  CzYsP14KcCommunityApiDtoKindGroupObject,
  CzYsP14KcCommunityApiDtoKindGroupUpdateObject,
  CzYsP14KcCommunityApiDtoKindProjectDataObject,
  CzYsP14KcCommunityApiDtoKindProjectObject,
  CzYsP14KcCommunityApiDtoKindProjectUpdateObject,
} from '~/app/core/apiClient/api';
import GroupModule from '~/app/core/store/modules/GroupModule';
import { FormValues } from '~/components/templates/editGroupForm/EditGroupForm';
import {
  GroupItem,
  isProjectPriorityEnum,
  isProjectStateEnum,
  Kind,
  ProjectPriority,
  ProjectState,
  Visibility,
} from '~/utils/group';
import { isLocationEmpty, isLocationValid } from '~/utils/location';
import VisibilityEnum = CzYsP14KcCommunityApiDtoCommunityDetailObject.VisibilityEnum;
import KindEnum = CzYsP14KcCommunityApiDtoKindGroupUpdateObject.KindEnum;

type TranslationKey = string;

export interface ValidationCommit {
  api: TranslationKey[];
  description: TranslationKey[];
  location: TranslationKey[];
  name: TranslationKey[];
  projectPriority: TranslationKey[];
  projectState: TranslationKey[];
}

interface CreateInput {
  data: FormValues;
}

interface UpdateInput {
  data: FormValues;
  groupId: GroupItem['id'];
}

export function validationHasErrors(validation: ValidationCommit): boolean {
  return (
    validation.api.length > 0 ||
    validation.description.length > 0 ||
    validation.name.length > 0
  );
}

type CreateBody =
  | CzYsP14KcCommunityApiDtoKindGroupObject
  | CzYsP14KcCommunityApiDtoKindProjectObject;
type UpdateBody =
  | CzYsP14KcCommunityApiDtoKindGroupUpdateObject
  | CzYsP14KcCommunityApiDtoKindProjectUpdateObject;

function getApiProjectPriority(
  priority: ProjectPriority
): CzYsP14KcCommunityApiDtoKindProjectDataObject.PriorityEnum {
  switch (priority) {
    case ProjectPriority.B:
      return CzYsP14KcCommunityApiDtoKindProjectDataObject.PriorityEnum.B;
    case ProjectPriority.C:
      return CzYsP14KcCommunityApiDtoKindProjectDataObject.PriorityEnum.C;
    default:
      return CzYsP14KcCommunityApiDtoKindProjectDataObject.PriorityEnum.A;
  }
}

function getApiProjectState(
  state: ProjectState
): CzYsP14KcCommunityApiDtoKindProjectDataObject.StateEnum {
  switch (state) {
    case ProjectState.PROJECT:
      return CzYsP14KcCommunityApiDtoKindProjectDataObject.StateEnum.PROJECT;
    case ProjectState.PROGRESS:
      return CzYsP14KcCommunityApiDtoKindProjectDataObject.StateEnum.PROGRESS;
    case ProjectState.FINALIZED:
      return CzYsP14KcCommunityApiDtoKindProjectDataObject.StateEnum.FINALIZED;
    case ProjectState.CANCELED:
      return CzYsP14KcCommunityApiDtoKindProjectDataObject.StateEnum.CANCELED;
    default:
      return CzYsP14KcCommunityApiDtoKindProjectDataObject.StateEnum.INTENT;
  }
}

@Module({
  name: 'GroupMutationModule',
  stateFactory: true,
  namespaced: true,
})
export default class GroupMutationModule extends AbstractModule {
  /**
   * @throws ValidationCommit
   * @param input
   */
  @Action({ rawError: true })
  public create(input: CreateInput): Promise<GroupItem> {
    return this.validate(input.data).then((validation) => {
      if (validationHasErrors(validation)) {
        throw validation;
      }

      let visibility: VisibilityEnum;

      switch (input.data.visibility) {
        case Visibility.PUBLIC:
          visibility = VisibilityEnum.PUBLIC;
          break;
        default:
          visibility = VisibilityEnum.PRIVATE;
      }
      const place: CreateBody['place'] | null = isLocationEmpty(
        input.data.location
      )
        ? undefined
        : {
            address: input.data.location.address,
            latitude: input.data.location.lat || undefined,
            longitude: input.data.location.lng || undefined,
            name: input.data.location.name,
            ruianId: input.data.location.ruianId || undefined,
          };

      const commonBody: CreateBody = {
        areasOfInterest: input.data.areasOfInterest,
        description: input.data.description,
        name: input.data.name,
        image: input.data.image,
        kind: KindEnum.GROUP,
        place,
        visibility,
      };

      if (input.data.image) {
        commonBody.image = input.data.image;
      }

      let promise: Promise<string>;

      if (input.data.kind === Kind.PROJECT) {
        promise = this.$api.communities.createCommunity({
          ...commonBody,
          data: {
            priority: getApiProjectPriority(input.data.projectData.priority),
            state: getApiProjectState(input.data.projectData.state),
          },
          kind: KindEnum.PROJECT,
        });
      } else {
        promise = this.$api.communities.createCommunity(commonBody);
      }

      return promise
        .catch((err) => {
          return this.$api
            .handleApiErrorMessages(err, {
              forbidden: 'app.error.group.create.forbidden',
              generic: 'app.error.group.create.generic',
            })
            .then((message) => {
              validation.api.push(message.toString());
              throw validation;
            });
        })
        .then((result) => {
          return getModule(GroupModule, this.store)
            .load({ groupId: result })
            .then((items) => {
              if (items.length > 0) {
                return items[0];
              }
              throw new Error('Could not load created group');
            })
            .catch(() => {
              validation.api.push('app.error.group.couldNotReload');
              throw validation;
            });
        });
    });
  }

  /**
   * @throws ValidationCommit
   * @param input
   */
  @Action({ rawError: true })
  public update(input: UpdateInput): Promise<GroupItem> {
    return this.validate(input.data).then((validation) => {
      if (validationHasErrors(validation)) {
        throw validation;
      }

      let visibility: VisibilityEnum | undefined;

      switch (input.data.visibility) {
        case Visibility.PUBLIC:
          visibility = VisibilityEnum.PUBLIC;
          break;
        case Visibility.PRIVATE:
          visibility = VisibilityEnum.PRIVATE;
          break;
      }

      const place: UpdateBody['place'] | null = isLocationEmpty(
        input.data.location
      )
        ? null
        : {
            address: input.data.location.address,
            latitude: input.data.location.lat || undefined,
            longitude: input.data.location.lng || undefined,
            name: input.data.location.name,
            ruianId: input.data.location.ruianId || undefined,
          };

      const commonBody: UpdateBody = {
        areasOfInterest: input.data.areasOfInterest,
        description: input.data.description,
        kind: KindEnum.GROUP,
        name: input.data.name,
        // It is possible to send null to a place to erase it, however there is a bug in
        // swagger code gen, which does not allow for definition of nullable $ref fields
        // https://github.com/swagger-api/swagger-core/issues/3518
        // https://github.com/springdoc/springdoc-openapi/issues/573
        // Therefore we must ts-ignore the place line to allow sending null
        // @ts-ignore
        place,
        visibility,
      };

      if (input.data.image) {
        commonBody.image = input.data.image;
      }

      let promise: Promise<any>;

      if (input.data.kind === Kind.PROJECT) {
        promise = this.$api.communities.updateCommunity(
          {
            ...commonBody,
            data: {
              priority: getApiProjectPriority(input.data.projectData.priority),
              state: getApiProjectState(input.data.projectData.state),
            },
            kind: KindEnum.PROJECT,
          },
          input.groupId
        );
      } else {
        promise = this.$api.communities.updateCommunity(
          commonBody,
          input.groupId
        );
      }

      return promise
        .catch((err) => {
          return this.$api
            .handleApiErrorMessages(err, {
              forbidden: 'app.error.group.update.forbidden',
              generic: 'app.error.group.update.generic',
            })
            .then((message) => {
              validation.api.push(message.toString());
              throw validation;
            });
        })
        .then(() => {
          return getModule(GroupModule, this.store)
            .load({ groupId: input.groupId })
            .then((items) => {
              if (items.length > 0) {
                return items[0];
              }
              throw new Error('Could not load updated group');
            })
            .catch(() => {
              validation.api.push('app.error.group.couldNotReload');
              throw validation;
            });
        });
    });
  }

  @Action({ rawError: true })
  protected validate(values: FormValues): Promise<ValidationCommit> {
    const validation: ValidationCommit = {
      api: [],
      description: [],
      location: [],
      name: [],
      projectPriority: [],
      projectState: [],
    };

    if (values.name.trim() === '') {
      validation.name.push('app.error.group.nameIsRequired');
    }

    if (values.description.trim() === '') {
      validation.description.push('app.error.group.descriptionIsRequired');
    }

    if (
      !isLocationEmpty(values.location) &&
      !isLocationValid(values.location)
    ) {
      if (values.location.name) {
        validation.location.push('app.error.group.locationHasNameOnly');
      }
    }

    if (values.kind === Kind.PROJECT) {
      if (isLocationEmpty(values.location)) {
        validation.location.push('app.error.group.missingLocation');
      }

      if (!values.projectData.priority) {
        validation.projectPriority.push('app.error.project.priorityMissing');
      } else if (!isProjectPriorityEnum(values.projectData.priority)) {
        validation.projectPriority.push('app.error.project.priorityInvalid');
      }

      if (!values.projectData.state) {
        validation.projectPriority.push('app.error.project.stateMissing');
      } else if (!isProjectStateEnum(values.projectData.state)) {
        validation.projectPriority.push('app.error.project.stateInvalid');
      }
    }

    return Promise.resolve(validation);
  }
}
