import Vue from 'vue';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import {
  CzYsP14KcPostApiDtoPostCreationObject,
  CzYsP14KcPostApiDtoPostUpdateObject,
  isApiFetchResponse,
} from '~/app/core/apiClient/api';
import { AreaOfInterestItem } from '~/utils/areasOfInterest';
import { createPostItem, PostItem } from '~/utils/post';
import { GroupItem } from '~/utils/group';
import GroupModule from '~/app/core/store/modules/GroupModule';

interface GetInput {
  id: PostItem['id'];
  areasOfInterest: AreaOfInterestItem[];
}

interface CreateInput {
  allAreasOfInterest: AreaOfInterestItem[];
  data: CzYsP14KcPostApiDtoPostCreationObject;
  groupId: GroupItem['id'];
}

interface UpdateInput {
  allAreasOfInterest: AreaOfInterestItem[];
  data: CzYsP14KcPostApiDtoPostUpdateObject;
  postId: PostItem['id'];
}

interface LikeInput {
  id: PostItem['id'];
}

interface UnlikeInput {
  id: PostItem['id'];
}

interface ReportPostInput {
  id: PostItem['id'];
}

interface LikingCommit {
  id: PostItem['id'];
  liking: boolean;
}

interface ReportingCommit {
  id: PostItem['id'];
  reporting: boolean;
}

type GetPostCommit = PostItem;

type DeletePostCommit = PostItem['id'];

type CachePostsCommit = PostItem[];

interface LikeStatusCommit {
  id: PostItem['id'];
  state: boolean;
}

interface ReportStatusCommit {
  id: PostItem['id'];
  state: boolean;
}

enum PostLikeApiResponse {
  LIKED = 'Liked already',
  UNLIKED = 'Not liked',
}

/**
 * Retrieve index key for an array of posts
 * @param id
 * @param items
 */
function getItemCacheKey(id: string, items: PostItem[]): number | null {
  for (let key = 0; key < items.length; key++) {
    if (items[key].id === id) {
      return key;
    }
  }

  return null;
}

/**
 * Parse error message from API and return true if the error matches the expected message
 *
 * @param err
 * @param successMessage
 */
function parseErrorResponse(err: any, successMessage: string): Promise<true> {
  if (isApiFetchResponse(err)) {
    return err.json().then((response) => {
      if (response.message && response.message === successMessage) {
        // The post was already unliked
        return true;
      }
      throw err;
    });
  }
  throw err;
}

@Module({
  name: 'PostsModule',
  stateFactory: true,
  namespaced: true,
})
export default class PostsModule extends AbstractModule {
  public cache: PostItem[] = [];

  public currentPost: PostItem | null = null;

  @Action({ commit: 'setPost', rawError: true })
  public get(data: GetInput): Promise<GetPostCommit> {
    return this.$api.posts.getPost(data.id).then((result) => {
      const post = createPostItem(result, data.areasOfInterest);

      if (post === null) {
        throw new Error('Post could not be created');
      }

      return getModule(GroupModule, this.store)
        .loadUncachedIds({ requestedIds: [post.group.id] })
        .then(() => post);
    });
  }

  @Action({ commit: 'setPost', rawError: true })
  public create(input: CreateInput): Promise<GetPostCommit> {
    return this.$api.communities
      .submitPost(input.data, input.groupId)
      .then((result) => {
        return this.get({
          id: result,
          areasOfInterest: input.allAreasOfInterest,
        });
      });
  }

  @Action({ commit: 'setPost', rawError: true })
  public update(input: UpdateInput): Promise<GetPostCommit> {
    return this.$api.posts.updatePost(input.data, input.postId).then(() => {
      return this.get({
        id: input.postId,
        areasOfInterest: input.allAreasOfInterest,
      });
    });
  }

  @Action({ commit: 'setLiked', rawError: true })
  public like(data: LikeInput): Promise<LikeStatusCommit> {
    this.setLiking({ id: data.id, liking: true });
    const successfulResult = {
      id: data.id,
      state: true,
    };

    return this.$api.posts
      .likePost(data.id)
      .then((_) => successfulResult)
      .catch((err) => parseErrorResponse(err, PostLikeApiResponse.LIKED))
      .then(() => {
        // The post was already liked
        return successfulResult;
      })
      .finally(() => {
        this.setLiking({ id: data.id, liking: false });
      });
  }

  @Action({ commit: 'setLiked', rawError: true })
  public unlike(data: UnlikeInput): Promise<LikeStatusCommit> {
    this.setLiking({ id: data.id, liking: true });

    const successfulResult = {
      id: data.id,
      state: false,
    };
    return this.$api.posts
      .unlikePost(data.id)
      .then((_) => successfulResult)
      .catch((err) => parseErrorResponse(err, PostLikeApiResponse.UNLIKED))
      .then(() => {
        // The post was already unliked
        return successfulResult;
      })
      .finally(() => {
        this.setLiking({ id: data.id, liking: false });
      });
  }

  @Action({ commit: 'setReported', rawError: true })
  public report(data: ReportPostInput): Promise<ReportStatusCommit> {
    this.setReporting({ id: data.id, reporting: true });
    return this.$api.posts
      .toxicPost(data.id)
      .then((_) => {
        return {
          id: data.id,
          state: true,
        };
      })
      .finally(() => {
        this.setReporting({ id: data.id, reporting: false });
      });
  }

  @Mutation
  public setCurrentPost(data: GetPostCommit) {
    this.currentPost = data;
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      Vue.set(this.cache, key, data);
    } else {
      this.cache.push(data);
    }
  }

  @Mutation
  public setPost(data: GetPostCommit) {
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      Vue.set(this.cache, key, data);
    } else {
      this.cache.push(data);
    }
  }

  @Mutation
  public unsetPost(data: DeletePostCommit) {
    const key = getItemCacheKey(data, this.cache);
    if (key !== null) {
      Vue.delete(this.cache, key);
    }
  }

  @Mutation
  public cachePosts(items: CachePostsCommit) {
    items.forEach((item) => {
      const key = getItemCacheKey(item.id, this.cache);
      if (key !== null) {
        Vue.set(this.cache, key, item);
      } else {
        this.cache.push(item);
      }
    });
  }

  @Mutation
  protected setLiking(data: LikingCommit) {
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      this.cache[key].state.liking = data.liking;
      Vue.set(this.cache, key, this.cache[key]);

      if (this.currentPost && this.currentPost.id === data.id) {
        this.currentPost.state.liking = this.cache[key].state.liking;
      }
    }
  }

  @Mutation
  protected setLiked(data: LikeStatusCommit) {
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      this.cache[key].liked = data.state;
      this.cache[key].likes = this.cache[key].likes + (data.state ? 1 : -1);
      Vue.set(this.cache, key, this.cache[key]);

      if (this.currentPost && this.currentPost.id === data.id) {
        this.currentPost.liked = this.cache[key].liked;
        this.currentPost.likes = this.cache[key].likes;
      }
    }
  }

  @Mutation
  protected setReporting(data: ReportingCommit) {
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      this.cache[key].state.reporting = data.reporting;
      Vue.set(this.cache, key, this.cache[key]);

      if (this.currentPost && this.currentPost.id === data.id) {
        this.currentPost.state.reporting = this.cache[key].state.reporting;
      }
    }
  }

  @Mutation
  protected setReported(data: ReportStatusCommit) {
    const key = getItemCacheKey(data.id, this.cache);
    if (key !== null) {
      this.cache[key].reported = data.state;
      Vue.set(this.cache, key, this.cache[key]);

      if (this.currentPost && this.currentPost.id === data.id) {
        this.currentPost.reported = this.cache[key].reported;
      }
    }
  }
}
