import { CzYsP14KcPlaceApiDtoPlaceDetailObject } from '~/app/core/apiClient/api';
import { createEmptyGeoLocation, GeoLocation } from '../location';

export default function(
  location?: CzYsP14KcPlaceApiDtoPlaceDetailObject
): GeoLocation {
  if (!location) {
    return createEmptyGeoLocation();
  }
  return {
    address: location.address || '',
    ruianId: location.ruianId || null,
    lat: location.latitude || null,
    lng: location.longitude || null,
    name: location.name || '',
  };
}
