import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

const rootClass = 'ys-area-of-interest';

export interface AreaOfInterestInterface {
  id: string;
  small?: boolean;
  title: string;
}

@Component
export default class AreaOfInterest
  extends VueComponent<AreaOfInterestInterface>
  implements AreaOfInterestInterface {
  @Prop({ type: String, required: true })
  public id!: string;

  @Prop({ type: Boolean, default: false })
  public small!: boolean;

  @Prop({ type: String, default: '' })
  public title!: string;

  public render() {
    return (
      <v-chip
        class={`${rootClass} mr-1 mb-1`}
        color='primary'
        small={this.small}
      >
        {this.title}
      </v-chip>
    );
  }
}
