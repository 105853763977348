import { Component, Prop, Watch } from 'vue-property-decorator';

import { CloseButton } from '~/components/atoms';
import VDialogCloseHandler, {
  VDialogCloseHandlerInterface,
} from '~/mixins/VDialogCloseHandler';
import { ImageItem } from '~/utils/media';
import { VueComponentMixin } from '~/utils/vue-component';

import style from './DetailGallery.scss';

const rootClass = 'ys-detail-gallery';

interface DetailGalleryInterface {
  forceSlide?: number;
  images: ImageItem[];
}

@Component({ style })
export default class DetailGallery
  extends VueComponentMixin<
    DetailGalleryInterface,
    VDialogCloseHandlerInterface
  >(VDialogCloseHandler)
  implements DetailGalleryInterface {
  @Prop({ default: 0, type: Number })
  public forceSlide!: number;

  @Prop({ required: true })
  public images!: ImageItem[];

  protected slide: number = 0;

  @Watch('active', { immediate: true })
  @Watch('forceSlide')
  protected onActiveChange() {
    if (!this.active) {
      return;
    }
    this.slide = this.forceSlide;
  }

  public render() {
    if (this.images.length < 1) {
      return;
    }
    return (
      <v-dialog content-class={rootClass} {...this.vDialogAttributes}>
        <v-card>
          <v-card-title>
            <v-spacer />
            <CloseButton onClose={this.vDialogClose} />
          </v-card-title>
          {this.renderCarousel()}
        </v-card>
      </v-dialog>
    );
  }

  protected renderCarousel() {
    if (!this.active) {
      return;
    }
    if (this.images.length === 1) {
      return <v-img height='80vh' contain src={this.images[0].src} />;
    }

    const items = this.images.map((image, index) => {
      return <v-carousel-item key={index} contain src={image.src} />;
    });
    return (
      <v-carousel
        height='80vh'
        class={`${rootClass}__carousel`}
        value={this.slide}
        continuous
        hide-delimiters
      >
        {items}
      </v-carousel>
    );
  }
}
