export default {
  app: {
    survey: {
      areasOfInterest: 'Zaměření ankety',
      content: 'Obsah',
      ended: 'Anketa skončila',
      endsAt: 'Anketa skončí',
      numberOfVotes: 'Žádný hlas | Jeden Hlas | {count} hlasy | {count} hlasů',
      private: 'Soukromá anketa',
      privateHint: 'Anketu uvidí pouze členové skupiny',
      public: 'Veřejná anketa',
      publicHint: 'Anketu uvidí všichni uživatelé aplikace',
      title: 'Titulek',
      type: 'Anketa',
      until: 'Do kdy anketa platí',
      visibility: 'Viditelnost',
      votedAt: 'Již jste hlasovali',
      voteUntil: 'Hlasujte do',
    },
  },
};
