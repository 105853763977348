import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';

import Surveys from './surveys';
import Survey from './survey';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  Survey,
  Surveys,
]) as LocaleMessages;

export default mergedLocalizations;
