export default {
  app: {
    common: {
      addPhoto: 'Přidat fotografii',
      addPhotos: 'Přidat další fotografie',
      all: 'Vše',
      appDescription: 'Komuntní centrum Prahy 14',
      appName: 'Moje čtrnáctka',
      back: 'Zpět',
      brand: 'Znalostní centrum',
      cancel: 'Zrušit',
      choose: 'Vybrat',
      comments: 'Komentáře',
      close: 'Zavřít',
      dateIsInPast: 'Datum musí být v budoucnu',
      dateEndBeforePast: 'Začátek musí být před koncem',
      days: {
        monday: 'Pondělí',
        tuesday: 'Úterý',
        wednesday: 'Středa',
        thursday: 'Čtvrtek',
        friday: 'Pátek',
        saturday: 'Sobota',
        sunday: 'Neděle',
      },
      delete: 'Smazat',
      deleteConfirmation: 'Opravdu si přejete smazat tuto položku?',
      edit: 'Upravit',
      feed: 'Nástěnka',
      finalize: 'Dokonči',
      find: 'Hledat',
      goToHomepage: 'Přejít na hlavní stránku',
      language: 'Jazyk',
      like: 'To se mi líbí',
      next: 'Další',
      no: 'Ne',
      noDataText: 'Žádné výsledky nebyly nalezené.',
      userProfile: 'Uživatelský profil',
      replacePhoto: 'Změnit fotku',
      report: 'Nahlásit',
      reported: 'Obsah nahlášen',
      personalized: 'Personalizováno',
      save: 'Uložit',
      saveChanges: 'Uložit úpravy',
      share: 'Sdílet',
      selectLanguage: 'Vyberte jazyk',
      showMore: 'Zobrazit více',
      showAll: 'Zobrazit všechny',
      unlike: 'Už se mi to nelíbí',
      updatePhoto: 'Upravit fotografii',
      yes: 'Ano',
    },
  },
};
