import { Component, Emit, Prop } from 'vue-property-decorator';
import { reportColor } from '~/utils/theme/colors';
import BaseButton from '~/components/atoms/baseButton/BaseButton';
import { userCanReportHarmfulContent } from '~/utils/user';
import { getModule } from 'vuex-module-decorators';
import UserModule from '~/app/core/store/modules/UserModule';

export type ReportEvent = void;

export interface ReportButtonInterface {
  active?: boolean;
  onReport?: (data: ReportEvent) => void;
  reported: boolean;
}

@Component
export default class ReportButton extends BaseButton<ReportButtonInterface> {
  @Prop({ default: true, type: Boolean })
  public active!: boolean;

  @Prop({ required: true, type: Boolean })
  public reported!: boolean;

  protected get icon() {
    return this.reported ? 'mdi-check-decagram' : 'mdi-alert';
  }

  protected get userStore() {
    return getModule(UserModule, this.$store);
  }

  public render() {
    if (!this.active) {
      return;
    }

    if (!userCanReportHarmfulContent(this.userStore.user)) {
      return;
    }

    return (
      <v-btn
        {...this.baseAttributes}
        color={reportColor}
        disabled={this.reported}
        onClick={this.emitReport}
        text
      >
        <v-icon left>{this.icon}</v-icon>
        {this.$slots.default || this.reported
          ? this.$t('app.common.reported')
          : this.$t('app.common.report')}
      </v-btn>
    );
  }

  @Emit('report')
  protected emitReport(): ReportEvent {
    return;
  }
}
