import { DaySelection, weekDays } from './';

export default function(daySelection: DaySelection): boolean[] {
  const dayArray: boolean[] = [];

  weekDays.forEach((value, index) => {
    dayArray.push(daySelection[value]);
  });

  return dayArray;
}
