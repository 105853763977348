import { Component, Emit, Prop } from 'vue-property-decorator';
import { editColor } from '~/utils/theme/colors';
import BaseButton from '~/components/atoms/baseButton/BaseButton';

export type EditEvent = void;

export interface EditButtonInterface {
  active?: boolean;
  onEdit?: (data: EditEvent) => void;
}

@Component
export default class EditButton extends BaseButton<EditButtonInterface> {
  @Prop({ default: true, type: Boolean })
  public active!: boolean;

  public render() {
    if (!this.active) {
      return;
    }

    return (
      <v-btn onClick={this.emitEdit} {...this.baseAttributes} text>
        <v-icon left>mdi-settings</v-icon>
        {this.$slots.default || this.$t('app.common.edit')}
      </v-btn>
    );
  }

  @Emit('edit')
  protected emitEdit(): EditEvent {
    return;
  }
}
