import { Component, Prop } from 'vue-property-decorator';
import { icons } from '~/components/atoms/icons';
import { VueComponent } from '~/utils/vue-component';
import { BaseIconInterface } from './BaseIcon';
import style from './Icon.scss';

export type IconName =
  | ['common', keyof typeof icons.common]
  | ['flags', keyof typeof icons.flags];

export interface IconInterface extends BaseIconInterface {
  name: IconName;
}

export const rootClass = 'ys-icon';

@Component({
  style,
})
export default class Icon extends VueComponent<IconInterface>
  implements IconInterface {
  @Prop({ default: false })
  public bordered!: boolean;

  @Prop({ required: true })
  public name!: IconName;

  @Prop({ default: 1, type: Number })
  public size!: number;

  public render() {
    let iconName: IconName | string = this.name;
    if (this.name instanceof Array) {
      iconName = this.name.join('-');
    }

    const classes = [rootClass];

    if (this.bordered) {
      classes.push(`${rootClass}--bordered`);
    }

    return (
      <v-icon
        class={classes.join(' ')}
        size={`${this.size}em`}
        nativeOnClick={() => this.$emit('click')}
      >
        {`$vuetify.icons.${iconName}`}
      </v-icon>
    );
  }
}
