import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { getModule } from 'vuex-module-decorators';

import { CzYsP14KcSurveyApiDtoSurveyCreationObject } from '~/app/core/apiClient/api';
import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import SurveysModule from '~/app/core/store/modules/SurveysModule';
import groupPlacehodler from '~/assets/images/placeholder/survey-group_350x180.png';
import GroupModule from '~/app/core/store/modules/GroupModule';
import { AddButton, CloseButton, FormActionButton } from '~/components/atoms';
import { FormActionIcon } from '~/components/atoms/formActionButton/FormActionButton';
import { ErrorSnack, GroupCard, SelectGroupItem } from '~/components/molecules';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import VisibilityEnum = CzYsP14KcSurveyApiDtoSurveyCreationObject.VisibilityEnum;
import {
  AreaOfInterestItem,
  createAreaOfInterestSelectItems,
} from '~/utils/areasOfInterest';
import { format, parse } from '~/utils/date-fns';
import { dateTimeFormatWithDays } from '~/utils/dateTime';
import { GroupItem, userCanCreateSurvey } from '~/utils/group';
import { componentOffset } from '~/utils/spacing';
import { SurveyItem } from '~/utils/survey';
import { isHTMLInput } from '~/utils/typeguards';
import { VueComponentMixin } from '~/utils/vue-component';

export enum FormStep {
  GROUP_CHOICE,
  BASIC_INFO,
  OPTIONS,
  META_INFO,
}

interface EditSurveyFormInterface {
  surveyId: SurveyItem['id'] | null;
  groupId: GroupItem['id'] | null;
  onCancel?: () => void;
}

interface VuetifyButton extends Vue {
  $el: HTMLButtonElement;
}

interface FormValues {
  areasOfInterest: AreaOfInterestItem['id'][];
  content: SurveyItem['content'];
  image: string;
  options: string[];
  title: SurveyItem['title'];
  until: Date | null;
  visibility: CzYsP14KcSurveyApiDtoSurveyCreationObject.VisibilityEnum;
}

function createDefaultFormValues(): FormValues {
  return {
    areasOfInterest: [],
    content: '',
    image: '',
    options: ['', ''],
    title: '',
    until: null,
    visibility: VisibilityEnum.PUBLIC,
  };
}

interface SnackErrors {
  expiryRequired: boolean;
  groupNotFound: boolean;
  navigationFailed: boolean;
  surveyNotCreated: boolean;
}

const rootClass = 'ys-edit-survey-form';

@Component
export default class EditSurveyForm
  extends VueComponentMixin<EditSurveyFormInterface, PrefetchComponent>(
    Prefetch
  )
  implements EditSurveyFormInterface {
  @Prop({ default: null })
  public surveyId!: SurveyItem['id'] | null;

  @Prop({ default: null })
  public groupId!: GroupItem['id'] | null;

  @Ref('submitButton')
  protected readonly submitButton!: VuetifyButton;

  protected areasOfInterestSearchTerm: string = '';

  protected loading: boolean = false;

  protected formStep: FormStep = FormStep.GROUP_CHOICE;

  protected formValues: FormValues = createDefaultFormValues();

  protected loadAreasOfInterestFromAi: boolean = true;

  protected loadingNextStep: boolean = false;

  protected selectedGroupId: GroupItem['id'] | null = null;

  protected selectingEndOfSurvey: boolean = false;

  protected selectingEndOfSurveyTime: boolean = false;

  protected errors: SnackErrors = {
    expiryRequired: false,
    groupNotFound: false,
    navigationFailed: false,
    surveyNotCreated: false,
  };

  protected get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  public get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  public get surveysStore() {
    return getModule(SurveysModule, this.$store);
  }

  protected get areasOfInterestSelectItems() {
    return createAreaOfInterestSelectItems(
      this.areasOfInterestStore.categorisedInterests
    );
  }

  protected get formattedEndOfSurvey(): string {
    return this.formValues.until
      ? format(this.formValues.until, dateTimeFormatWithDays)
      : '';
  }

  protected get datePickerEndOfSurvey(): string {
    return this.formValues.until
      ? format(this.formValues.until, 'YYYY-MM-DD')
      : '';
  }

  protected get timePickerEndOfSurvey(): string {
    return this.formValues.until
      ? format(this.formValues.until, 'HH:mm:ss')
      : '';
  }

  protected get group(): GroupItem | null {
    if (this.selectedGroupId) {
      for (const groupItem of this.groupStore.groupCache) {
        if (groupItem.id === this.selectedGroupId) {
          return groupItem;
        }
      }
    }

    return null;
  }

  protected get hasImage(): boolean {
    // TODO: API needs to inform us whether the image exists on the blob storage
    return !!this.formValues.image;
  }

  protected get isBasicStepDisabled() {
    // Group must be chosen
    return !this.selectedGroupId;
  }

  protected get isFinalStep() {
    return this.formStep === FormStep.META_INFO;
  }

  protected get isFirstStep() {
    if (this.surveyId) {
      // We already have a survey, so we cannot show the group choice
      return this.formStep === FormStep.BASIC_INFO;
    }

    return this.formStep === FormStep.GROUP_CHOICE;
  }

  protected get isMetaStepDisabled() {
    if (this.isOptionsStepDisabled) {
      return true;
    }

    for (const option of this.formValues.options) {
      if (option.trim() === '') {
        return true;
      }
    }

    return this.formValues.options.length < 2;
  }

  protected get isOptionsStepDisabled() {
    if (this.isBasicStepDisabled) {
      return true;
    }

    if (
      this.formValues.title.trim() === '' ||
      this.formValues.content.trim() === '' ||
      this.formValues.until === null ||
      !this.isEndOfSurveyInFuture()
    ) {
      // Content and title must not be empty
      return true;
    }

    return false;
  }

  protected get isNextStepActive(): boolean {
    if (this.formStep === FormStep.GROUP_CHOICE && this.isBasicStepDisabled) {
      return false;
    }

    if (this.formStep === FormStep.BASIC_INFO && this.isOptionsStepDisabled) {
      return false;
    }

    if (this.formStep === FormStep.OPTIONS && this.isMetaStepDisabled) {
      return false;
    }

    if (this.formStep === FormStep.META_INFO && this.isSubmissionDisabled) {
      return false;
    }

    return true;
  }

  protected get isSubmissionDisabled() {
    if (this.isMetaStepDisabled) {
      return true;
    }

    // At least one area of interest must be selected
    return this.formValues.areasOfInterest.length < 1;
  }

  protected get managedGroups(): GroupItem[] {
    return this.groupStore.items.filter(userCanCreateSurvey);
  }

  protected get title() {
    if (this.surveyId) {
      return this.$t('app.surveys.update');
    }

    return this.$t('app.surveys.create');
  }

  protected get backwardLabel() {
    if (this.isFirstStep) {
      return this.$t('app.common.cancel');
    }

    return this.$t('app.common.back');
  }

  protected get forwardLabel() {
    if (this.isFinalStep) {
      return this.$t('app.common.finalize');
    }

    return this.$t('app.common.next');
  }

  public created() {
    this.setInitialData();
  }

  public prefetch() {
    return this.areasOfInterestStore.loadData().catch(() => {
      // not much to do here
    });
  }

  public render() {
    return (
      <v-card class={rootClass}>
        <v-card-title>
          {this.title}
          <v-spacer />
          <CloseButton onClose={this.cancel} />
        </v-card-title>
        <v-card-subtitle class='py-2'>
          {this.group && <GroupCard group={this.group} disableLink={true} />}
        </v-card-subtitle>
        <v-tabs value={this.formStep} fixed-tabs>
          <v-tab
            class={this.surveyId ? 'd-none' : ''}
            onClick={this.goToGroupSelection}
          >
            {this.$t('app.communities.chooseCommunityStep')}
          </v-tab>
          <v-tab
            disabled={this.isBasicStepDisabled}
            onClick={this.goToBasicInfo}
          >
            {this.$t('app.surveys.basicDataStep')}
          </v-tab>
          <v-tab
            disabled={this.isOptionsStepDisabled}
            onClick={this.goToOptions}
          >
            {this.$t('app.surveys.optionsStep')}
          </v-tab>
          <v-tab disabled={this.isMetaStepDisabled} onClick={this.goToMeta}>
            {this.$t('app.surveys.metaDataStep')}
          </v-tab>
        </v-tabs>

        <v-divider />

        <v-card-text class={`py-${componentOffset}`}>
          <v-form ref='form' onSubmit={this.submitHandler}>
            <v-tabs-items value={this.formStep}>
              <v-tab-item value={FormStep.GROUP_CHOICE}>
                <v-row justify='center' no-gutters>
                  <v-img src={groupPlacehodler} max-width={200} />
                </v-row>
                <div class='text-center'>
                  {this.$t('app.surveys.chooseGroup')}
                </div>
                {this.managedGroups.map((group) => {
                  return (
                    <SelectGroupItem
                      name={group.name}
                      onSelected={() => {
                        this.selectGroup(group.id);
                      }}
                    />
                  );
                })}
                {this.managedGroups.length < 1 && (
                  <v-alert type='warning'>
                    {this.$t('app.error.survey.noGroupsToCreateSurvey')}
                  </v-alert>
                )}
              </v-tab-item>
              <v-tab-item value={FormStep.BASIC_INFO}>
                <v-text-field
                  filled
                  label={this.$t('app.survey.title') + '*'}
                  required
                  rounded
                  rules={[this.titleIsRequiredRule]}
                  v-model={this.formValues.title}
                />
                <v-textarea
                  filled
                  label={this.$t('app.survey.content') + '*'}
                  required
                  rounded
                  rules={[this.contentIsRequiredRule]}
                  v-model={this.formValues.content}
                />
                <v-menu
                  v-model={this.selectingEndOfSurvey}
                  close-on-content-click={false}
                  offset-y
                  min-width={290}
                  max-width={290}
                  scopedSlots={{
                    activator: () => {
                      return (
                        <div
                          onClick={() => {
                            this.selectingEndOfSurvey = true;
                          }}
                        >
                          <v-text-field
                            label={this.$t('app.survey.until') + '*'}
                            value={this.formattedEndOfSurvey}
                            filled
                            rounded
                            rules={[this.endOfSurveyTooEarlyRule]}
                            onFocus={() => {
                              this.selectingEndOfSurvey = true;
                            }}
                          />
                        </div>
                      );
                    },
                  }}
                >
                  {(() => {
                    if (this.selectingEndOfSurveyTime) {
                      return (
                        <v-time-picker
                          value={this.timePickerEndOfSurvey}
                          onInput={this.updateEndOfSurveyTime}
                          format='24hr'
                          {...{
                            on: {
                              'click:minute': () => {
                                this.selectingEndOfSurvey = false;
                                this.selectingEndOfSurveyTime = false;
                              },
                            },
                          }}
                        >
                          <v-spacer />
                          <v-btn
                            text
                            color='secondary'
                            onClick={() => {
                              this.selectingEndOfSurvey = false;
                              this.selectingEndOfSurveyTime = false;
                            }}
                          >
                            {this.$t('app.common.choose')}
                          </v-btn>
                        </v-time-picker>
                      );
                    } else {
                      return (
                        <v-date-picker
                          allowed-dates={this.allowedDates}
                          first-day-of-week={1}
                          value={this.datePickerEndOfSurvey}
                          onInput={this.updateEndOfSurvey}
                        />
                      );
                    }
                  })()}
                </v-menu>
              </v-tab-item>
              <v-tab-item value={FormStep.OPTIONS}>
                {this.formValues.options.map((option, index) => {
                  return (
                    <v-row no-gutters>
                      <v-text-field
                        class={`${rootClass}__option ${rootClass}__option--index-${index}`}
                        filled
                        label={this.$t('app.surveys.optionNumber', {
                          number: index + 1,
                        })}
                        required
                        rounded
                        rules={[this.optionCannotBeEmptyRule]}
                        value={option}
                        onInput={(value: string) => {
                          Vue.set(this.formValues.options, index, value);
                        }}
                      />
                      {index > 1 && (
                        <v-btn
                          class='mt-2'
                          icon
                          onClick={() => {
                            Vue.delete(this.formValues.options, index);
                          }}
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      )}
                    </v-row>
                  );
                })}
                <v-row no-gutters>
                  <v-spacer />
                  <AddButton onClick={this.addOption}>
                    {this.$t('app.surveys.addOption')}
                  </AddButton>
                </v-row>
              </v-tab-item>
              <v-tab-item value={FormStep.META_INFO}>
                <v-autocomplete
                  autocomplete='off'
                  chips
                  deletable-chips
                  items={this.areasOfInterestSelectItems}
                  label={this.$t('app.survey.areasOfInterest') + '*'}
                  multiple
                  no-data-text={this.$t('app.common.noDataText')}
                  outlined
                  rounded
                  rules={[this.areasAreRequiredRule]}
                  search-input={this.areasOfInterestSearchTerm}
                  small-chips
                  v-model={this.formValues.areasOfInterest}
                  {...{
                    on: {
                      input: () => {
                        // Once areas are changed, do not load interests anymore
                        this.loadAreasOfInterestFromAi = false;
                        this.areasOfInterestSearchTerm = '';
                      },
                      'update:search-input': (value: string) => {
                        this.areasOfInterestSearchTerm = value;
                      },
                    },
                  }}
                />
                <h4>{this.$t('app.survey.visibility')}*</h4>
                <v-radio-group v-model={this.formValues.visibility}>
                  <v-radio
                    label={this.$t('app.survey.public')}
                    value={VisibilityEnum.PUBLIC}
                    class='mb-0'
                  />
                  <div class='pl-5 mb-2'>
                    {this.$t('app.survey.publicHint')}
                  </div>
                  <v-radio
                    label={this.$t('app.survey.private')}
                    value={VisibilityEnum.PRIVATE}
                    class='mb-0'
                  />
                  <div class='pl-5 mb-2'>
                    {this.$t('app.survey.privateHint')}
                  </div>
                </v-radio-group>
              </v-tab-item>
            </v-tabs-items>
            <v-btn ref='submitButton' class='d-none' type='submit' />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <FormActionButton
            disabled={this.loadingNextStep}
            icon={this.isFirstStep ? undefined : FormActionIcon.LEFT}
            onClick={this.goBack}
          >
            {this.backwardLabel}
          </FormActionButton>
          <v-spacer />
          <FormActionButton
            disabled={!this.isNextStepActive}
            icon={this.isFinalStep ? undefined : FormActionIcon.RIGHT}
            loading={this.loadingNextStep || this.loading}
            onClick={this.goForward}
          >
            {this.forwardLabel}
          </FormActionButton>
        </v-card-actions>
        <ErrorSnack v-model={this.errors.groupNotFound}>
          {this.$t('app.error.group.notFound')}
        </ErrorSnack>
        <ErrorSnack v-model={this.errors.navigationFailed}>
          {this.$t('app.error.generic.navigationFailure')}
        </ErrorSnack>
        <ErrorSnack v-model={this.errors.surveyNotCreated}>
          {this.$t('app.error.survey.notCreated')}
        </ErrorSnack>
        <ErrorSnack v-model={this.errors.expiryRequired}>
          {this.$t('app.error.survey.expiryRequired')}
        </ErrorSnack>
      </v-card>
    );
  }

  protected submitHandler(e: Event) {
    e.preventDefault();

    if (!this.selectedGroupId) {
      return;
    }

    if (this.isSubmissionDisabled) {
      if (!this.isFinalStep) {
        this.goForward();
      }

      return;
    }

    if (!this.formValues.until) {
      this.errors.expiryRequired = true;
      return;
    }

    this.loading = true;

    this.surveysStore
      .create({
        data: {
          areasOfInterest: this.formValues.areasOfInterest,
          content: this.formValues.content,
          options: this.formValues.options,
          title: this.formValues.title,
          to: this.formValues.until.toISOString(),
          visibility: this.formValues.visibility,
        },
        groupId: this.selectedGroupId,
      })
      .then((survey) => {
        return this.$router
          .push(
            getLocalizedLocation(routes.surveyDetail, this.$router, {
              guid: survey.id,
            })
          )
          .catch(() => {
            this.errors.navigationFailed = true;
          })
          .then(() => {
            this.cancel();
            this.loading = false;
          });
      })
      .catch(() => {
        this.errors.surveyNotCreated = true;
        this.loading = false;
      });
  }

  @Emit('cancel')
  protected cancel() {
    this.setInitialData();
    return;
  }

  protected goBack() {
    if (this.isFirstStep) {
      return this.cancel();
    }

    switch (this.formStep) {
      case FormStep.META_INFO:
        this.goToOptions();
        this.formStep = FormStep.OPTIONS;
        break;
      case FormStep.OPTIONS:
        this.goToBasicInfo();
        this.formStep = FormStep.BASIC_INFO;
        break;
      case FormStep.BASIC_INFO:
        this.goToGroupSelection();
        break;
    }
  }

  protected goForward() {
    if (this.isFinalStep) {
      return this.submitButton.$el.click();
    }

    switch (this.formStep) {
      case FormStep.GROUP_CHOICE:
        if (!this.isBasicStepDisabled) {
          this.goToBasicInfo();
        }
        break;
      case FormStep.OPTIONS:
        if (!this.isMetaStepDisabled) {
          this.goToMeta();
        }
        break;
      case FormStep.BASIC_INFO:
        if (!this.isOptionsStepDisabled) {
          this.goToOptions();
        }
        break;
    }
  }

  protected goToBasicInfo() {
    this.formStep = FormStep.BASIC_INFO;
  }

  protected goToGroupSelection() {
    this.formStep = FormStep.GROUP_CHOICE;
  }

  protected goToMeta() {
    if (this.formStep === FormStep.META_INFO) {
      return;
    }

    if (!this.loadAreasOfInterestFromAi) {
      this.formStep = FormStep.META_INFO;
      return;
    }

    this.loadingNextStep = true;
    let aiContentRequest = '';

    if (this.formValues.title) {
      aiContentRequest += `${this.formValues.title}. `;
    }

    if (this.formValues.content) {
      aiContentRequest += `${this.formValues.content}`;
    }

    this.$api.ai
      .areas(aiContentRequest)
      .then((result) => {
        this.formValues.areasOfInterest = result;
      })
      .finally(() => {
        this.loadingNextStep = false;
        this.formStep = FormStep.META_INFO;
      });
  }

  protected goToOptions() {
    this.formStep = FormStep.OPTIONS;
  }

  @Watch('surveyId')
  @Watch('groupId')
  protected setInitialData() {
    this.formStep = FormStep.GROUP_CHOICE;
    this.formValues = createDefaultFormValues();
    // Do not load interests if we are editing a survey
    this.loadAreasOfInterestFromAi = !this.surveyId;

    this.selectGroup(this.groupId);

    // TODO: Load data from survey ID, edit is not available now
  }

  protected selectGroup(groupId: GroupItem['id'] | null) {
    if (!groupId) {
      this.selectedGroupId = null;
      return;
    }

    // TODO: these groups are not part of the cache, so we need to fetch it if it does not exist
    for (const groupItem of this.groupStore.groupCache) {
      if (groupItem.id === groupId) {
        this.selectedGroupId = groupId;
        this.$nextTick(this.goToBasicInfo);
        return;
      }
    }

    this.loadingNextStep = true;
    this.groupStore
      .load({ groupId })
      .then(() => {
        this.selectedGroupId = groupId;
        this.$nextTick(this.goToBasicInfo);
      })
      .catch(() => {
        this.errors.groupNotFound = true;
      })
      .finally(() => {
        this.loadingNextStep = false;
      });
  }

  protected addOption() {
    this.formValues.options.push('');
    this.$nextTick(() => {
      const index = this.formValues.options.length - 1;

      const option = this.$el.querySelector(
        `.${rootClass}__option--index-${index} input`
      );
      if (isHTMLInput(option)) {
        option.focus();
      }
    });
  }

  protected titleIsRequiredRule(input: string): boolean | TranslateResult {
    if (input.trim() === '') {
      return this.$t('app.error.survey.titleIsRequired');
    }

    return true;
  }

  protected contentIsRequiredRule(input: string): boolean | TranslateResult {
    if (input.trim() === '') {
      return this.$t('app.error.survey.contentIsRequired');
    }

    return true;
  }

  protected optionCannotBeEmptyRule(input: string): boolean | TranslateResult {
    if (input.trim() === '') {
      return this.$t('app.error.survey.optionCannotBeEmpty');
    }

    return true;
  }

  protected areasAreRequiredRule(input: any): boolean | TranslateResult {
    if (input.length < 1) {
      return this.$t('app.error.survey.areasAreRequired');
    }

    return true;
  }

  protected endOfSurveyTooEarlyRule(): boolean | TranslateResult {
    if (!this.formValues.until) {
      return this.$t('app.error.survey.expiryRequired');
    }

    if (this.isEndOfSurveyInFuture()) {
      return true;
    }

    return this.$t('app.error.survey.expiryMustBeInFuture');
  }

  protected isEndOfSurveyInFuture(): boolean {
    if (!this.formValues.until) {
      return false;
    }

    const parsedDate = parse(this.formValues.until);
    if (isNaN(parsedDate.getTime())) {
      return false;
    }

    const now = new Date().getTime();

    return parsedDate.getTime() > now;
  }

  protected allowedDates(date: string) {
    const parsedDate = parse(date);
    const today = format(new Date(), 'YYYY-MM-DD');

    return parse(today) <= parsedDate;
  }

  protected updateEndOfSurvey(value: string) {
    const date = parse(value);
    if (isNaN(date.getTime())) {
      return;
    }

    this.formValues.until = date;
    this.selectingEndOfSurveyTime = true;
  }

  protected updateEndOfSurveyTime(value: string) {
    if (!this.formValues.until) {
      // Cannot choose time when there is no date selected
      return;
    }

    const dateTime = parse(
      format(this.formValues.until, 'YYYY-MM-DD ') + value
    );

    if (isNaN(dateTime.getTime())) {
      return;
    }

    this.formValues.until = dateTime;
  }
}
