import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { EventTimeRange } from '~/components/molecules/eventDates/EventDates';

interface EventTimesInterface {
  end: EventTimeRange['end'];
  icon?: boolean;
  start: EventTimeRange['start'];
}

@Component
export default class EventTimes extends VueComponent<EventTimesInterface>
  implements EventTimesInterface {
  @Prop({ required: true })
  public start!: EventTimeRange['start'];

  @Prop({ default: true })
  public icon!: boolean;

  @Prop({ required: true })
  public end!: EventTimeRange['end'];

  protected get timeStart(): string | null {
    if (!this.start) {
      return null;
    }

    const startParts = this.start.split(':');
    if (startParts.length < 2) {
      return null;
    }

    return [startParts[0], startParts[1]].join(':');
  }

  protected get timeEnd(): string | null {
    if (!this.end) {
      return null;
    }

    const endParts = this.end.split(':');
    if (endParts.length < 2) {
      return null;
    }

    return [endParts[0], endParts[1]].join(':');
  }

  public render() {
    const renderParts: (JSX.Element | string)[] = [];

    if (this.timeStart) {
      renderParts.push(
        <span>
          {`${!this.timeEnd ? this.$t('app.eventListItem.from') + ' ' : ''}${
            this.timeStart
          }`}
        </span>
      );
    }

    if (this.timeEnd) {
      if (this.timeStart) {
        renderParts.push(<span style='white-space:pre'> - </span>);
      }
      renderParts.push(
        <span>
          {`${!this.timeStart ? this.$t('app.eventListItem.to') + ' ' : ''}${
            this.timeEnd
          }`}
        </span>
      );
    }

    if (!this.timeStart && !this.timeEnd) {
      renderParts.push(<span>{this.$t('app.eventListItem.allDay')}</span>);
    }

    return (
      <div class='font-weight-medium d-flex justify-center align-center text-no-wrap'>
        {this.icon && <v-icon left>mdi-clock-outline</v-icon>}
        {renderParts}
      </div>
    );
  }
}
