import 'firebaseui/dist/firebaseui.css';
import { Component, Watch } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './Login.scss';
import background from '~/assets/images/login_picture.png';
import FooterPush from '~/components/atoms/footerPush/FooterPush';
import { ForgottenDialog, RegisterDialog } from '~/components/templates';
import { LoginForm } from '~/components/organisms';
import { groupIcon } from '~/utils/group';
import UserModule from '~/app/core/store/modules/UserModule';
import { getModule } from 'vuex-module-decorators';

const rootClass = 'ys-login';

@Component({
  style,
})
export default class Login extends VueComponent<{}> {
  protected forgottenDialog: boolean = false;

  protected registerDialog: boolean = false;

  protected notRegisteredDialog: boolean = false;

  protected get isNotRegistered() {
    return getModule(UserModule, this.$store).isNotRegistered;
  }

  public render() {
    return (
      <div class={`${rootClass} container`}>
        <div class='ys-inner-space'>
          <v-row>
            <v-col class={`${rootClass}__welcome`} cols='12' md='6'>
              <h1 class={`${rootClass}__heading mb-3`}>
                {this.$t('app.welcome.title')}
              </h1>
              <p domPropsInnerHTML={this.$t('app.welcome.paragraph1')} />

              <v-banner two-line>
                <v-avatar slot='icon' size='40'>
                  <v-icon>{groupIcon}</v-icon>
                </v-avatar>
                {this.$t('app.welcome.benefit1')}
              </v-banner>

              <v-banner two-line>
                <v-avatar slot='icon' size='40'>
                  <v-icon>mdi-calendar-month</v-icon>
                </v-avatar>
                {this.$t('app.welcome.benefit2')}
              </v-banner>

              <v-banner two-line>
                <v-avatar slot='icon' size='40'>
                  <v-icon>mdi-cellphone-android</v-icon>
                </v-avatar>
                {this.$t('app.welcome.benefit3')}
              </v-banner>
              <div
                class={`${rootClass}__background d-none d-md-block`}
                style={`background-image: url(${background})`}
              />
            </v-col>

            <v-col class={`${rootClass}__login`} cols='12' md='5' offset-md={1}>
              <LoginForm
                onOpenForgottenDialog={() => {
                  this.forgottenDialog = true;
                }}
                onOpenRegisterDialog={() => {
                  this.registerDialog = true;
                }}
              />
            </v-col>
          </v-row>
        </div>
        <ForgottenDialog
          active={this.forgottenDialog}
          onCloseDialog={() => {
            this.forgottenDialog = false;
          }}
        />
        <RegisterDialog
          active={this.registerDialog}
          noAccount={this.isNotRegistered}
          onCloseDialog={() => {
            this.registerDialog = false;
          }}
        />
        <FooterPush />
      </div>
    );
  }

  @Watch('isNotRegistered', { immediate: true })
  protected watchRegistrationChanges() {
    // If the status becomes not registered, set the dialog as active
    if (this.isNotRegistered) {
      this.registerDialog = true;
    }
  }
}
