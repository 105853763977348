import { RouteConfig } from 'vue-router';

import FindGroups from '~/views/FindGroups';

const config: RouteConfig = {
  component: FindGroups,
  path: 'find-groups',
};

export default config;
