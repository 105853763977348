import { Location } from 'vue-router';

export function isLocation(data: any): data is Location {
  if (typeof data !== 'object') {
    return false;
  }

  const locationProps = [
    'name',
    'path',
    'hash',
    'query',
    'params',
    'append',
    'replace',
  ];

  for (const property of locationProps) {
    if (data.hasOwnProperty(property)) {
      return true;
    }
  }

  return false;
}
