import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VueComponentMixin } from '~/utils/vue-component';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import HeadManagement from '~/mixins/HeadManagement';
import EventsModule, {
  EventRequestObject,
} from '~/app/core/store/modules/EventsModule';
import { EventTile } from '~/components/organisms';
import { format, parse } from '~/utils/date-fns';
import EventsSearchForm from '~/components/organisms/eventSearchForm/EventsSearchForm';
import { LoadMoreButton } from '~/components/atoms';
import { EventInstanceIdentification, EventInstanceItem } from '~/utils/event';

@Component
export default class Events extends VueComponentMixin<{}, PrefetchComponent>(
  Prefetch,
  HeadManagement
) {
  protected eventInstanceIdentifications: EventInstanceIdentification[] = [];

  public get eventsStore() {
    return getModule(EventsModule, this.$store);
  }

  public get isLastPage() {
    return this.eventsStore.isLastPage;
  }

  public get events() {
    const eventCalendar: { [key: string]: EventInstanceItem[] } = {};
    if (this.eventInstanceIdentifications) {
      this.eventInstanceIdentifications.forEach((eventIdentification) => {
        // TODO: Bad performance, can't use id-key map because it's not reactive
        // TODO: Figure out a way to use MAP to retrieve events by key, instead of looping
        for (const event of this.eventsStore.instanceCache) {
          if (
            event.id === eventIdentification.id &&
            event.date === eventIdentification.date
          ) {
            if (!eventCalendar[event.date]) {
              eventCalendar[event.date] = [event];
            } else {
              eventCalendar[event.date].push(event);
            }
            break;
          }
        }
      });
    }
    return eventCalendar;
  }

  public title() {
    return this.$t('app.events.title');
  }

  public prefetch() {
    if (this.$ssrContext) {
      return Promise.resolve();
    }

    return this.eventsStore.getEvents({}).then((items) => {
      this.eventInstanceIdentifications = items.map((item) => {
        return {
          id: item.id,
          date: item.date,
        };
      });
    });
  }

  public render() {
    return (
      <v-container key={'unique-identifier-events'}>
        <v-row class='fill-height'>
          <v-col cols='12'>
            <EventsSearchForm onSubmit={this.formSubmitted} />
          </v-col>
          <v-col cols='12'>{this.renderItems()}</v-col>
          <v-col cols='12 text-center'>
            {!this.isLastPage && (
              <LoadMoreButton
                loading={this.eventsStore.loading}
                onLoadMore={this.loadMoreEvents}
              />
            )}
          </v-col>
        </v-row>
      </v-container>
    );
  }

  protected renderItems() {
    if (
      this.eventsStore.loading &&
      this.eventInstanceIdentifications.length < 1
    ) {
      return;
    }
    const eventDates = [];
    for (const eventDate in this.events) {
      if (this.events[eventDate]) {
        const date = format(parse(eventDate), 'dddd Do Mo').toUpperCase();
        eventDates.push(
          <section class='mb-3 pb-3' key={eventDate}>
            <v-divider class='mb-3' />
            <h2 class='text-uppercase mb-3'>{date}</h2>
            {this.events[eventDate].map((event, index) => {
              return (
                <EventTile
                  class={
                    this.events[eventDate].length !== index + 1
                      ? 'mb-4'
                      : undefined
                  }
                  event={event}
                  key={`event-search-${eventDate}-${event.id}`}
                />
              );
            })}
          </section>
        );
      }
    }
    if (eventDates.length < 1) {
      return (
        <h2 class='text-center'>{this.$t('app.events.noItemsMessage')}</h2>
      );
    }
    return eventDates;
  }

  protected formSubmitted(data: EventRequestObject) {
    this.eventsStore.getEvents(data).then((items) => {
      this.eventInstanceIdentifications = items.map((item) => {
        return {
          id: item.id,
          date: item.date,
        };
      });
    });
  }

  protected loadMoreEvents() {
    return this.eventsStore.loadNextEvents().then((items) => {
      this.eventInstanceIdentifications = this.eventInstanceIdentifications.concat(
        items.map((item) => {
          return {
            id: item.id,
            date: item.date,
          };
        })
      );
    });
  }
}
