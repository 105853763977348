import { Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';

@Module({
  name: 'MutationModalsModule',
  stateFactory: true,
  namespaced: true,
})
export default class MutationModalsModule extends AbstractModule {
  protected newEventModalIsOpen: boolean = false;
  protected newGroupModalIsOpen: boolean = false;
  protected newPostModalIsOpen: boolean = false;
  protected newSurveyModalIsOpen: boolean = false;

  public get isNewEventModalOpen() {
    return this.newEventModalIsOpen;
  }
  public get isNewGroupModalOpen() {
    return this.newGroupModalIsOpen;
  }
  public get isNewPostModalOpen() {
    return this.newPostModalIsOpen;
  }
  public get isNewSurveyModalOpen() {
    return this.newSurveyModalIsOpen;
  }

  @Mutation
  public setNewEventModalIsOpen(data: boolean) {
    this.newEventModalIsOpen = data;
  }

  @Mutation
  public setNewGroupModalIsOpen(data: boolean) {
    this.newGroupModalIsOpen = data;
  }

  @Mutation
  public setNewPostModalIsOpen(data: boolean) {
    this.newPostModalIsOpen = data;
  }

  @Mutation
  public setNewSurveyModalIsOpen(data: boolean) {
    this.newSurveyModalIsOpen = data;
  }
}
