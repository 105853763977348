export default {
  app: {
    comment: {
      addComment: 'Vložit komentář',
      comments: 'Komentáře',
      deleted: 'Uživatel smazal tento komentář',
      removed: 'Komentář byl odstraněn',
      nComments:
        '0 komentářů | 1 komentář | {count} komentáře | {count} komentářů',
    },
  },
};
