import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { AreaOfInterest } from '~/components/atoms';
import { AreaOfInterestInterface } from '~/components/atoms/areaOfInterest/AreaOfInterest';

const rootClass = 'ys-tag-list';

interface AreasOfInterestInterface {
  items: AreaOfInterestInterface[];
  small?: boolean;
}

@Component
export default class AreasOfInterests
  extends VueComponent<AreasOfInterestInterface>
  implements AreasOfInterestInterface {
  @Prop({ required: true })
  public items!: AreaOfInterestInterface[];

  @Prop({ type: Boolean, default: false })
  public small!: boolean;

  public render() {
    return (
      <div class={`${rootClass} mr-n1`}>
        {this.items.map((item) => {
          return (
            <AreaOfInterest
              id={item.id}
              title={item.title}
              small={this.small}
            />
          );
        })}
      </div>
    );
  }
}
