import { Component, Prop } from 'vue-property-decorator';

import { EditEventForm } from '~/components/templates';
import VDialogCloseHandler, {
  VDialogCloseHandlerInterface,
} from '~/mixins/VDialogCloseHandler';
import { EventItem } from '~/utils/event';
import { GroupItem } from '~/utils/group';
import { VueComponentMixin } from '~/utils/vue-component';

interface EditEventModalInterface {
  eventId?: EventItem['id'] | null;
  groupId?: GroupItem['id'] | null;
}

@Component
export default class EditEventModal
  extends VueComponentMixin<
    EditEventModalInterface,
    VDialogCloseHandlerInterface
  >(VDialogCloseHandler)
  implements EditEventModalInterface {
  @Prop({ default: null })
  public eventId!: EventItem['id'] | null;

  @Prop({ default: null })
  public groupId!: GroupItem['id'] | null;

  public created() {
    if (this.setPersistent) {
      this.setPersistent();
    }
  }

  public render() {
    return (
      <v-dialog max-width='900' scrollable {...this.vDialogAttributes}>
        <EditEventForm
          eventId={this.eventId}
          groupId={this.groupId}
          onCancel={this.vDialogClose}
        />
      </v-dialog>
    );
  }
}
