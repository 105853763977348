import { baseMediaUrl, getImageSizeSuffix, ImageSize } from '~/utils/media';

export default function(
  postId: string,
  version: string,
  size?: ImageSize
): string {
  return `${baseMediaUrl}/survey/${postId}${
    size ? getImageSizeSuffix(size) : ''
  }#${version}`;
}
