import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { EventInstanceItem } from '~/utils/event';
import { getLocalizedLocation } from '~/app/core/router';
import eventDetailRoute from '~/app/core/router/routes/eventDetail';
import { format, parse } from '~/utils/date-fns';
import { routeParameterDateFormat } from '~/app/core/router/routes/parameters/date';
import { Colors } from '~/utils/theme/colors';

interface EventDetailLinkInterface {
  event: EventInstanceItem;
}

@Component
export default class EventDetailLink
  extends VueComponent<EventDetailLinkInterface>
  implements EventDetailLinkInterface {
  @Prop({ required: true })
  public event!: EventInstanceItem;

  protected get date(): Date | null {
    const date = parse(this.event.date);
    if (isNaN(date.getTime())) {
      return null;
    }

    return date;
  }

  public render() {
    if (!this.date) {
      return;
    }

    return (
      <router-link
        class='font-weight-medium secondary--text'
        style={`color:${Colors.SECONDARY}`}
        to={getLocalizedLocation(eventDetailRoute, this.$router, {
          guid: this.event.id,
          date: format(this.date, routeParameterDateFormat),
        })}
      >
        {this.$slots.default || this.$t('app.events.detailLink')}
      </router-link>
    );
  }
}
