import { Component, Ref, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';
import surveyDetailRoute from '~/app/core/router/routes/surveyDetail';
import SurveysModule from '~/app/core/store/modules/SurveysModule';
import UserModule from '~/app/core/store/modules/UserModule';
import { SurveyKindLabel } from '~/components/atoms';
import {
  ShareButtonProps,
  ToolbarButtonProps,
} from '~/components/molecules/toolbarSubmenu/ToolbarSubmenu';
import {
  AreasOfInterests,
  BottomToolbar,
  ErrorSnack,
  GroupCard,
  SuccessSnack,
} from '~/components/molecules';
import { DetailToolbar, SurveyBody, SurveyHead } from '~/components/organisms';
import { CommentTypeBlock } from '~/components/templates';
import { NotFound } from '~/components/templates/common';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import HeadManagement from '~/mixins/HeadManagement';
import RequestErrorComponent from '~/mixins/RequestErrorComponent';
import { commentsFragment, CommentType } from '~/utils/comments';
import createGroupItem from '~/utils/group/createGroupItem';
import { createShareUri } from '~/utils/http/links';
import { imageVersion } from '~/utils/media';
import { SurveyItem } from '~/utils/survey';
import getSurveyImageUrl from '~/utils/survey/getSurveyImageUrl';
import { greyBackgroundColor } from '~/utils/theme/colors';
import createSeoTags, { TYPE } from '~/utils/views/createSeoTags';
import { VueComponentMixin } from '~/utils/vue-component';
import { headerHeight } from '~/components/organisms/header/Header';
import ScrollToRefMixin from '~/mixins/ScrollToRefMixin';
import { GroupItem } from '~/utils/group';
import GroupModule from '~/app/core/store/modules/GroupModule';

interface SurveySnacks {
  copied: boolean;
  reported: boolean;
  reportFailed: boolean;
}

@Component
export default class SurveyDetail extends VueComponentMixin<
  {},
  RequestErrorComponent & PrefetchComponent & ScrollToRefMixin
>(Prefetch, HeadManagement, RequestErrorComponent, ScrollToRefMixin) {
  @Ref('commentBlock')
  protected readonly commentBlock?: Vue;

  protected error: boolean = false;

  protected found: boolean = true;

  protected forbidden: boolean = false;

  protected scrollCommentsAfterLoad: boolean = false;

  protected surveyActions: SurveySnacks = {
    copied: false,
    reported: false,
    reportFailed: false,
  };

  public title() {
    if (this.loadingPrefetchData) {
      return '...';
    }

    return this.survey
      ? this.survey.title
      : this.$t('app.error.survey.notFound');
  }

  public headTags(): string {
    if (!this.survey) {
      return '';
    }

    return createSeoTags(
      this.survey.title,
      this.survey.content,
      this.$router.resolve(
        getLocalizedLocation(routes.surveyPreview, this.$router, {
          guid: this.surveyId,
        })
      ).href,
      TYPE.ARTICLE,
      getSurveyImageUrl(this.surveyId, imageVersion())
    );
  }

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  protected get userStore() {
    return getModule(UserModule, this.$store);
  }

  public get surveyId() {
    return this.$route.params.guid;
  }

  protected get surveysStore() {
    return getModule(SurveysModule, this.$store);
  }

  protected get survey(): SurveyItem | null {
    for (const survey of this.surveysStore.cache) {
      if (survey.id === this.surveyId) {
        return survey;
      }
    }

    return null;
  }

  protected get groupItem(): GroupItem | null {
    if (!this.survey) {
      return null;
    }

    for (const group of this.groupStore.groupCache) {
      if (group.id === this.survey.group.id) {
        return group;
      }
    }

    return null;
  }

  protected get reportButton(): ToolbarButtonProps | undefined {
    if (!this.userStore.user) {
      return;
    }

    return {
      disabled: this.surveyActions.reported,
      loading: this.survey ? this.survey.state.reporting : false,
    };
  }

  protected get shareButton(): ShareButtonProps | undefined {
    return {
      disabled: false,
      loading: false,
      uri: createShareUri(
        this.$router.resolve(
          getLocalizedLocation(routes.surveyPreview, this.$router, {
            guid: this.surveyId,
          })
        ).href
      ),
    };
  }

  public prefetch() {
    this.requestErrorResetStatus();

    if (this.$ssrContext) {
      return Promise.resolve();
    }

    this.scrollCommentsAfterLoad = true;

    return this.surveysStore.get({ id: this.surveyId }).catch((err) => {
      if (err.status && err.status === 401 && this.$route.meta.public) {
        return this.$router.push(
          getLocalizedLocation(surveyDetailRoute, this.$router, {
            guid: this.surveyId,
          })
        );
      }
      this.requestErrorHandler(err);
    });
  }

  public render() {
    if (this.loadingPrefetchData) {
      return <v-progress-linear indeterminate />;
    } else if (this.requestErrorComponent) {
      return this.requestErrorComponent;
    } else if (this.survey === null) {
      return <NotFound />;
    }

    return (
      <v-container key={`unique-id-survey-detail-${this.surveyId}`}>
        <v-card class={`${greyBackgroundColor} pb-0 pa-0`} flat>
          <SurveyHead survey={this.survey} />
          <v-card-text>
            <DetailToolbar
              report={this.reportButton}
              share={this.shareButton}
              onReport={this.reportSurvey}
              onShare={() => {
                this.surveyActions.copied = true;
              }}
            >
              {this.groupItem && (
                <GroupCard group={this.groupItem} slot='left' />
              )}
              <SurveyKindLabel slot='right' class='mx-1' />
            </DetailToolbar>
            <SurveyBody survey={this.survey} />
          </v-card-text>
          <v-divider />
          <v-container>
            <AreasOfInterests items={this.survey.areasOfInterest} />
          </v-container>
          <BottomToolbar
            commentsCount={this.survey.comments}
            liked={this.survey.liked}
            likes={this.survey.likes}
            loadingLike={this.survey.state.liking}
            onLike={this.like}
            onUnlike={this.unlike}
            onShowComments={this.scrollToComments}
          />
        </v-card>

        <CommentTypeBlock
          onCommentsLoaded={() => {
            if (
              !this.scrollCommentsAfterLoad ||
              this.$route.hash !== commentsFragment
            ) {
              return;
            }

            this.scrollToComments();
          }}
          parentId={this.surveyId}
          ref='commentBlock'
          type={CommentType.SURVEY}
        />
        <ErrorSnack v-model={this.surveyActions.reportFailed}>
          {this.$t('app.error.survey.reportFailed')}
        </ErrorSnack>
        <SuccessSnack v-model={this.surveyActions.reported}>
          {this.$t('app.surveys.reported')}
        </SuccessSnack>
        <SuccessSnack v-model={this.surveyActions.copied}>
          {this.$t('app.share.success')}
        </SuccessSnack>
      </v-container>
    );
  }

  protected reportSurvey() {
    if (!this.survey) {
      return;
    }

    this.surveysStore
      .report({ id: this.survey.id })
      .then(() => {
        this.surveyActions.reported = true;
      })
      .catch(() => {
        this.surveyActions.reportFailed = true;
      });
  }

  protected like() {
    this.surveysStore
      .like({
        id: this.surveyId,
      })
      .catch(() => {
        // TODO: implement user error
      });
  }

  protected unlike() {
    this.surveysStore
      .unlike({
        id: this.surveyId,
      })
      .catch(() => {
        // TODO: implement user error
      });
  }

  protected scrollToComments() {
    if (!this.commentBlock) {
      return;
    }

    this.scrollCommentsAfterLoad = false;

    this.scrollToRef(this.commentBlock, -headerHeight);
  }
}
