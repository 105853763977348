import { Component, Emit } from 'vue-property-decorator';
import BaseButton from '~/components/atoms/baseButton/BaseButton';
import { SpreadAttributes } from '~/utils/vue-component';

interface ShowMapButtonInterface {
  onShowMap?: () => any;
}

@Component
export default class ShowMapButton extends BaseButton<ShowMapButtonInterface> {
  public render() {
    return (
      <v-btn
        block
        color='secondary'
        onClick={this.emitShowMap}
        text
        {...this.baseAttributes}
      >
        <v-icon left>mdi-map-marker</v-icon>
        {this.$t('app.place.showMap')}
      </v-btn>
    );
  }

  @Emit('showMap')
  protected emitShowMap() {
    return;
  }
}
