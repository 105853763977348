import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { VoteOption } from '~/utils/survey';

import style from './SurveyOptions.scss';
import { greyForegroundColor } from '~/utils/theme/colors';
import { getModule } from 'vuex-module-decorators';
import UserModule from '~/app/core/store/modules/UserModule';

export interface SelectEvent {
  optionId: VoteOption['id'];
}

interface SurveyOptionsInterface {
  active?: boolean;
  options: VoteOption[];
  onSelected?: (data: SelectEvent) => void;
}

const rootClass = 'ys-survey-options';

@Component({ style })
export default class SurveyOptions extends VueComponent<SurveyOptionsInterface>
  implements SurveyOptionsInterface {
  @Prop({ default: false })
  public active!: boolean;

  @Prop({ required: true })
  public options!: VoteOption[];

  protected get userStore() {
    return getModule(UserModule, this.$store);
  }

  protected get beingVoted() {
    for (const option of this.options) {
      if (option.beingVoted) {
        return true;
      }
    }

    return false;
  }

  public render() {
    return (
      <div class={rootClass}>
        {this.options.map((option, index) => {
          const votingActive = this.active && option.id && this.userStore.user;
          const classes = ['mb-2', `${rootClass}__option`];
          if (votingActive) {
            classes.push(`${rootClass}__option--active`);
          }

          return (
            <v-row
              class={classes.join(' ')}
              onClick={() => {
                if (votingActive) {
                  this.emitSelected(option.id);
                }
              }}
              key={`survey-option-${option.id || index}`}
            >
              {(this.active || this.beingVoted) && (
                <v-col cols='auto' class='pr-0 py-0'>
                  <v-radio-group value={option.beingVoted}>
                    <v-radio value={true} />
                  </v-radio-group>
                </v-col>
              )}
              <v-col class='flex-grow-1 py-0'>
                <v-progress-linear
                  height={2}
                  active={option.beingVoted}
                  color='secondary'
                  indeterminate
                />
                <v-progress-linear
                  background-color={greyForegroundColor}
                  color='secondary'
                  height={30}
                  value={option.beingVoted ? 0 : option.percentage}
                  stream={option.beingVoted}
                  rounded={true}
                >
                  <v-row no-gutters class='px-2' justify='start'>
                    <span class='font-weight-medium'>{option.name}</span>
                  </v-row>
                </v-progress-linear>
                <v-progress-linear
                  height={2}
                  active={option.beingVoted}
                  color='secondary'
                  indeterminate
                />
                <div class='caption pa-1'>
                  {this.$tc('app.survey.numberOfVotes', option.count, {
                    count: option.count,
                  })}
                </div>
              </v-col>
            </v-row>
          );
        })}
      </div>
    );
  }

  @Emit('selected')
  protected emitSelected(value: VoteOption['id']): SelectEvent {
    return {
      optionId: value,
    };
  }
}
