export default {
  app: {
    login: {
      title: 'Přihlaste se',
      email: 'E-mail',
      forgottenPassword: 'Zapomenuté heslo?',
      password: 'Heslo',
      submit: 'Přihlásit se',
      logout: 'Odhlásit se',
      loggedIn: 'Jste přihlášen(a)',
      emptyEmail: 'Není vyplněna e-mailová adresa.',
      emptyPassword: 'Není vyplněno přihlašovací heslo.',
      invalidEmail: 'Nesprávně zadaná e-mailová adresa.',
      userNotFound:
        'Nenašli jsme vaši emailovou adresu. Zkontrolujte ji, nebo se registrujte.',
      wrongPassword: 'Nesprávné heslo.',
      genericError: 'Při přihlašování se vyskytla chyba.',
      register: 'Registrovat se',
      or: 'nebo přihlášení pomocí',
    },
  },
};
