import { Component } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import HeadManagement from '~/mixins/HeadManagement';
import { GroupList } from '~/components/organisms';
import { getModule } from 'vuex-module-decorators';
import GroupModule, { sortGroups } from '~/app/core/store/modules/GroupModule';
import { userCanEditGroup } from '~/utils/group';
import { ButtonSize, FindGroupsButton } from '~/components/atoms';

@Component
export default class Homepage extends VueComponentMixin<{}, PrefetchComponent>(
  Prefetch,
  HeadManagement
) {
  public title() {
    return '';
  }

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  public prefetch() {
    if (this.$ssrContext || this.groupStore.items.length > 0) {
      Promise.resolve();
    }
    return this.groupStore.initItems();
  }

  protected get managedGroups() {
    return this.groupStore.sortedItems.filter((group) => {
      return group.membershipType
        ? userCanEditGroup(group.membershipType)
        : false;
    });
  }

  protected get memberGroups() {
    return this.groupStore.sortedItems.filter((group) => {
      return group.membershipType
        ? !userCanEditGroup(group.membershipType)
        : true;
    });
  }

  public render() {
    return (
      <v-container>
        {this.managedGroups.length > 0 && (
          <GroupList
            class='pb-0 mt-2 mb-8'
            title={this.$t('app.communities.withPermissions')}
            items={this.managedGroups}
          />
        )}

        <GroupList
          class='pb-0 mt-2 mb-8'
          title={this.$t('app.communities.withoutPermissions')}
          items={this.memberGroups}
        />
      </v-container>
    );
  }
}
