export default {
  app: {
    error: {
      generic: {
        actionFailed: 'Nepodařilo se vykonat akci.',
        commentReportFailed: 'Nepodařilo se nahlásit komentář.',
        navigationFailure: 'Nebylo možné přejít na vyžádaný obsah.',
      },
    },
  },
};
