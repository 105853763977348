import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import {
  CommentItem,
  CommentParentId,
  CommentsPagingInfo,
  CommentType,
  defaultCommentsPagingInfo,
} from '~/utils/comments';
import { VueComponent } from '~/utils/vue-component';
import CommentList from '~/components/organisms/commentList/CommentList';
import { ErrorSnack } from '~/components/molecules';
import { getModule } from 'vuex-module-decorators';
import CommentsModule from '~/app/core/store/modules/CommentsModule';
import { ReportComment } from '~/components/molecules/comment/Comment';

interface CommentTypeBlockInterface {
  firstPageSize?: number;
  onCommentsLoaded?: () => void;
  parentId: CommentParentId;
  showTitle?: boolean;
  type: CommentType;
}
@Component
export default class CommentTypeBlock
  extends VueComponent<CommentTypeBlockInterface>
  implements CommentTypeBlockInterface {
  @Prop()
  public firstPageSize?: number;

  @Prop({ required: true })
  public parentId!: CommentParentId;

  @Prop({ default: true, type: Boolean })
  public showTitle!: boolean;

  @Prop({ required: true })
  public type!: CommentType;

  protected commentsPaging: CommentsPagingInfo = defaultCommentsPagingInfo();

  protected loading: boolean = false;

  protected commentIds: CommentItem['id'][] = [];

  protected commentReportError: boolean = false;

  protected get commentsStore() {
    return getModule(CommentsModule, this.$store);
  }

  protected get comments() {
    const comments: CommentItem[] = [];
    this.commentIds.forEach((id) => {
      // TODO: Bad performance, can't use id-key map because it's not reactive
      // TODO: Figure out a way to use MAP to retrieve comments by key, instead of looping
      for (const index in this.commentsStore.cache[this.type]) {
        if (
          this.commentsStore.cache[this.type][index] &&
          this.commentsStore.cache[this.type][index].id === id
        ) {
          comments.push(this.commentsStore.cache[this.type][index]);
          break;
        }
      }
    });

    return comments;
  }

  public created() {
    this.resetComments();
    this.loadComments();
  }

  public render() {
    if (this.comments.length < 1 && !this.loading) {
      return;
    }

    return (
      <v-card tile flat>
        {this.showTitle && (
          <v-card-title>{this.$t('app.common.comments')}</v-card-title>
        )}

        <v-card-text>
          <CommentList
            comments={this.comments}
            loading={this.loading}
            initializing={this.loading && this.comments.length < 1}
            showLoadButton={!this.commentsPaging.lastPage}
            onLoadMore={this.loadComments}
            onReport={this.reportComment}
          />
        </v-card-text>
        <ErrorSnack v-model={this.commentReportError}>
          {this.$t('app.error.generic.commentReportFailed')}
        </ErrorSnack>
      </v-card>
    );
  }

  @Watch('parentId')
  @Watch('type')
  protected watchProps() {
    this.resetComments();
    this.loadComments();
  }

  protected loadComments() {
    this.loading = true;
    this.commentsStore
      .loadComments({
        id: this.parentId,
        type: this.type,
        page: this.commentsPaging.page,
        size: this.commentsPaging.size,
      })
      .then((commit) => {
        if (
          this.firstPageSize &&
          this.firstPageSize === this.commentsPaging.size
        ) {
          // Reset the size after first successful request
          this.commentsPaging.size = defaultCommentsPagingInfo().size;
        }
        commit.items.forEach((item) => {
          if (this.commentIds.indexOf(item.id) < 0) {
            this.commentIds.push(item.id);
          }
        });
        this.commentsPaging.lastPage = commit.lastPage;
        this.commentsPaging.page++;
        this.$nextTick(() => {
          this.emitCommentsLoaded();
        });
      })
      .catch(() => {
        this.commentsPaging.lastPage = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  protected resetComments() {
    this.commentsPaging = defaultCommentsPagingInfo();
    this.commentIds = [];
    if (this.firstPageSize && this.firstPageSize > 0) {
      this.commentsPaging.size = this.firstPageSize;
    }
  }

  protected reportComment(data: ReportComment) {
    this.commentsStore
      .report({
        id: data.id,
        type: this.type,
      })
      .catch(() => {
        this.commentReportError = true;
      });
  }

  @Emit('commentsLoaded')
  protected emitCommentsLoaded() {
    return;
  }
}
