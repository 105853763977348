import { Component } from 'vue-property-decorator';
import BaseButton from '~/components/atoms/baseButton/BaseButton';
import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';

@Component
export default class HomepageButton extends BaseButton<{}> {
  public render() {
    return (
      <v-btn
        {...this.baseAttributes}
        color='secondary'
        to={getLocalizedLocation(routes.homepage, this.$router, {})}
        rounded
      >
        {this.$slots.default || this.$t('app.common.goToHomepage')}
      </v-btn>
    );
  }
}
