import { IVueI18n } from 'vue-i18n';

export interface GeoLocation {
  address: string;
  lat: number | null;
  lng: number | null;
  name: string;
  ruianId: string | null;
}

export function createAddressFromLocation(
  location: GeoLocation,
  i18n: IVueI18n
): string {
  const parts: string[] = [];
  if (location) {
    if (location.name) {
      parts.push(location.name);
    }
    if (location.address) {
      parts.push(location.address);
    } else {
      if (location.lat) {
        parts.push(
          `${i18n.t('app.location.latitude')}: ${location.lat.toFixed(5)}`
        );
      }
      if (location.lng) {
        parts.push(
          `${i18n.t('app.location.longitude')}: ${location.lng.toFixed(5)}`
        );
      }
    }
  }

  return parts.join(', ');
}

export function createEmptyGeoLocation(): GeoLocation {
  return {
    address: '',
    lat: null,
    lng: null,
    name: '',
    ruianId: null,
  };
}

export function isLocationEmpty(location: GeoLocation) {
  return !location.lat && !location.lng && !location.name && !location.address;
}

export function isLocationValid(location: GeoLocation): boolean {
  if (location.ruianId) {
    return true;
  }

  if (!location.lat || !location.lng) {
    return false;
  }

  return !!(location.name || location.address);
}
