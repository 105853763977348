import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { PostItem } from '~/utils/post';

interface PostKindLabelInterface {
  post: PostItem;
}

@Component
export default class PostKindLabel extends VueComponent<PostKindLabelInterface>
  implements PostKindLabelInterface {
  @Prop({ required: true })
  public post!: PostItem;

  public render() {
    return <v-chip>{this.$t(`app.post.source.${this.post.source}`)}</v-chip>;
  }
}
