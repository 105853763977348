import { LocaleMessage } from 'vue-i18n';
import { Component, Prop } from 'vue-property-decorator';
import {
  compileToFunctions,
  ssrCompileToFunctions,
} from 'vue-template-compiler';

import { VueComponent } from '~/utils/vue-component';

import style from './HtmlRenderer.scss';

interface HTMLRenderer {
  content: string | LocaleMessage;
}

const rootClass = 'ys-html-renderer';

@Component({ style })
export default class HtmlRenderer extends VueComponent<HTMLRenderer>
  implements HTMLRenderer {
  @Prop({ required: true })
  public content!: string | LocaleMessage;

  protected templateRenderer: any;

  protected transformedContent: string = '';

  /**
   * Recompiles the content on the client side
   */
  public created() {
    if (typeof this.content !== 'string') {
      return;
    }
    this.transformedContent = this.content;
    if (this.transformedContent.trim() === '') {
      return;
    }

    const complile = this.$ssrContext
      ? ssrCompileToFunctions
      : compileToFunctions;
    const { render, staticRenderFns } = complile(
      `<div class="${rootClass}">${this.transformedContent}</div>`
    );
    this.templateRenderer = render;
    this.$options.staticRenderFns = staticRenderFns;
  }

  public render() {
    if (this.templateRenderer) {
      return this.templateRenderer();
    }
    return <div class={rootClass}>{this.transformedContent}</div>;
  }
}
