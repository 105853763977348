import { Component } from 'vue-property-decorator';

import StatusCode from '~/utils/http/StatusCode';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class Error extends VueComponent<{}> {
  public render() {
    return (
      <v-container fluid class='pa-0 text-center'>
        <h1 class='title my-5'>{this.$t('app.error.view.generic')}</h1>
        <StatusCode code={500} />
      </v-container>
    );
  }
}
