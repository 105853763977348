import Vue from 'vue';
import Router, { Location, RouteConfig } from 'vue-router';

import {
  defaultLocale,
  encodeRoutePath,
  supportedLocales,
} from '~/app/localization';
import createI18n from '~/app/localization/plugin';
import routes, { routes as routeConfigs } from './routes';
import parameters from './routes/parameters';

Vue.use(Router);

/**
 * For internal use only, to build localized routes
 */
const i18n = createI18n();

/**
 * For internal use only, to build array of localized routes
 *
 * localizeRoute function defined below should be used for all other purposes
 *
 * @param config
 * @param locale
 */
function localizeRouteFromLocale(
  config: RouteConfig,
  locale: string
): RouteConfig {
  const localizedRoute = {
    ...config,
    meta: {
      ...(config.meta ? config.meta : {}),
      locale,
    },
  };

  if (!localizedRoute.name) {
    // We need to set a unique name so that router can route this route...routy routie McRoute
    localizedRoute.name = `${locale}|${localizedRoute.path}`;
  }

  // We need to encode the path (e.g. empty string is not valid translation key, dots are not allowed either
  const routePath = encodeRoutePath(config.path);
  if (i18n.te(`app.router.${routePath}.path`, locale)) {
    localizedRoute.path = i18n
      .t(`app.router.${routePath}.path`, locale)
      .toString();
  }

  // Now to localize aliases
  if (config.alias) {
    if (typeof config.alias === 'string') {
      // If it is string, simply check whether the string translation exists and assign it
      if (i18n.te(`app.router.${routePath}.alias`, locale)) {
        localizedRoute.alias = i18n
          .t(`app.router.${routePath}.alias`, locale)
          .toString();
      }
    } else if (Array.isArray(config.alias)) {
      // If it's an array, we will loop through each alias
      config.alias.forEach((alias, index) => {
        // Use the encoded value
        const routeAlias = encodeRoutePath(alias);
        // Check if this alias is translated and to satisfy typescript, check that localizedRoute.alias is still
        // an array
        if (
          i18n.te(`app.router.${routePath}.alias.${routeAlias}`, locale) &&
          Array.isArray(localizedRoute.alias)
        ) {
          localizedRoute.alias[index] = i18n
            .t(`app.router.${routePath}.alias.${routeAlias}`, locale)
            .toString();
        }
      });
    }
  }

  return localizedRoute;
}

/**
 * Creates a localized version of the route from current or provided locale
 *
 * @param config
 * @param router
 * @param locale
 */
export function localizeRoute(
  config: RouteConfig,
  router: Router,
  locale?: string
): RouteConfig {
  if (!locale) {
    locale = getLocaleFromRouter(router);
  }

  return localizeRouteFromLocale(config, locale);
}

export function getLocaleFromRouter(router: Router): string {
  const { matched } = router.currentRoute;
  for (const routeRecord of matched) {
    if (routeRecord.meta.locale) {
      return routeRecord.meta.locale;
    }
  }

  return defaultLocale;
}

export function getPathWithoutLocale(router: Router): string {
  const locale = getLocaleFromRouter(router);
  const path = router.currentRoute.path;

  const regex = new RegExp(`^/${locale}`, 'g');
  return path.replace(regex, '');
}

// Generate language routes
const languageRoutes = supportedLocales.map(
  (locale): RouteConfig => {
    const languageConfig: RouteConfig = {
      path: `/${locale}`,
      component: {
        render(c) {
          return c('router-view');
        },
      },
      meta: {
        locale,
      },
    };

    languageConfig.children = [];

    for (const route of routes) {
      languageConfig.children.push(localizeRouteFromLocale(route, locale));
    }

    return languageConfig;
  }
);

export function getLocalizedLocation(
  config: RouteConfig,
  router: Router,
  params: Location['params'],
  locale?: string
): Location {
  const localizedConfig = localizeRoute(config, router, locale);
  return {
    name: localizedConfig.name,
    params,
  };
}

export default function() {
  const router = new Router({
    mode: 'history',
    linkExactActiveClass: 'active',
    base: '/',
    routes: languageRoutes,
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        if (document) {
          const anchor = document.querySelector(to.hash) as HTMLElement;
          if (anchor) {
            const scrollTo = Math.round(
              anchor.getBoundingClientRect().top + window.scrollY
            );
            return window.scrollTo({ top: scrollTo, behavior: 'smooth' });
          }
        }

        return {
          selector: to.hash,
        };
      }

      return savedPosition
        ? savedPosition
        : { x: 0, y: from.meta.scrollY || 0 };
    },
  });

  router.addRoutes([
    {
      path: `/public/post/${parameters.guidPath}`,
      redirect: (to) => {
        return getLocalizedLocation(
          routeConfigs.postPreview,
          router,
          to.params,
          defaultLocale
        );
      },
    },
    {
      path: `/public/event/${parameters.guidPath}/${parameters.datePath}`,
      redirect: (to) => {
        return getLocalizedLocation(
          routeConfigs.eventPreview,
          router,
          to.params,
          defaultLocale
        );
      },
    },
    {
      path: `/public/group/${parameters.guidPath}`,
      redirect: (to) => {
        return getLocalizedLocation(
          routeConfigs.groupPreview,
          router,
          to.params,
          defaultLocale
        );
      },
    },
    {
      path: `/public/survey/${parameters.guidPath}`,
      redirect: (to) => {
        return getLocalizedLocation(
          routeConfigs.surveyPreview,
          router,
          to.params,
          defaultLocale
        );
      },
    },
    {
      path: '*',
      redirect: `/${defaultLocale}`,
    },
  ]);

  return router;
}
