import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { deleteColor } from '~/utils/theme/colors';
import BaseButton from '~/components/atoms/baseButton/BaseButton';

export type DeleteEvent = void;

export interface DeleteButtonInterface {
  active?: boolean;
  onDelete?: (data: DeleteEvent) => void;
}

@Component
export default class DeleteButton extends BaseButton<DeleteButtonInterface> {
  @Prop({ default: true, type: Boolean })
  public active!: boolean;

  protected deleteConfirmation: boolean = false;

  public render() {
    if (!this.active) {
      return;
    }

    return (
      <v-btn
        color={deleteColor}
        onClick={() => {
          this.deleteConfirmation = true;
        }}
        {...this.baseAttributes}
        text
      >
        <v-icon left>mdi-delete</v-icon>
        {this.$slots.default || this.$t('app.common.delete')}
        <v-dialog v-model={this.deleteConfirmation} max-width={600}>
          <v-card>
            <v-card-title>
              {this.$slots.deleteConfirmationTitle ||
                this.$t('app.common.deleteConfirmation')}
            </v-card-title>
            <v-card-actions>
              <v-btn
                text
                onClick={() => {
                  this.deleteConfirmation = false;
                }}
              >
                {this.$t('app.common.cancel')}
              </v-btn>
              <v-spacer />
              <v-btn
                text
                color={deleteColor}
                onClick={this.emitDelete}
                loading={this.loading}
                disabled={this.disabled}
              >
                {this.$t('app.common.delete')}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-btn>
    );
  }

  @Emit('delete')
  protected emitDelete(): DeleteEvent {
    this.deleteConfirmation = false;
    return;
  }
}
