import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './ArrowBase.scss';
import { rootClass } from '~/components/atoms/icons/Icon';

export enum IconDirection {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down',
}

interface ArrowIconInterface {
  direction?: IconDirection;
  lineWidth?: number;
}

@Component({
  style,
})
export default class ArrowBaseIcon extends VueComponent<ArrowIconInterface>
  implements ArrowIconInterface {
  @Prop({ default: IconDirection.RIGHT, type: String })
  public direction!: IconDirection;

  @Prop({ default: 2, type: Number })
  public lineWidth!: number;

  public render() {
    const classes = [`${rootClass}--common`, `${rootClass}--arrow`];
    classes.push(`${rootClass}-arrow--` + this.direction);
    return (
      <i class={classes.join(' ')}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-miterlimit='10'
          clip-rule='evenodd'
          viewBox='0 0 20 20'
        >
          <defs />
          <path
            fill='none'
            stroke='currentColor'
            stroke-width={this.lineWidth + 'px'}
            d='M7.056 15.888L12.944 10 7.056 4.112'
          />
        </svg>
      </i>
    );
  }
}
