import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { createAddressFromLocation, GeoLocation } from '~/utils/location';

interface LocationBlockInterface {
  location: GeoLocation;
  icon?: boolean;
}

@Component
export default class LocationBlock extends VueComponent<LocationBlockInterface>
  implements LocationBlockInterface {
  @Prop({ required: true })
  public location!: GeoLocation;

  @Prop({ default: true })
  public icon!: boolean;

  public render() {
    const location = createAddressFromLocation(this.location, this.$i18n);
    if (!location) {
      return;
    }
    return (
      <div class='d-flex justify-center align-center'>
        {this.icon && <v-icon left>mdi-map-marker</v-icon>}
        {location}
      </div>
    );
  }
}
