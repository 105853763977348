import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './EventBody.scss';
import { Content } from '~/components/atoms';
import { EventDates, EventPlan, Link } from '~/components/molecules';
import { MapWidget } from '~/components/organisms';
import {
  EventTimeRange,
  EventDateRange,
} from '~/components/molecules/eventDates/EventDates';
import { GeoPosition } from '~/utils/googleMaps';
import { FutureEvent } from '~/utils/event';

const rootClass = 'ys-event-body';

interface EventBodyInterface {
  address?: string;
  barrierFree?: boolean;
  capacity?: number | null;
  content?: string;
  eventDate: Date;
  futurePlan: FutureEvent[];
  date: EventDateRange;
  time: EventTimeRange;
  dogsAllowed?: boolean;
  link?: string | null;
  place?: string;
  position: GeoPosition | null;
  price?: string;
}

@Component({
  style,
})
export default class EventBody extends VueComponent<EventBodyInterface>
  implements EventBodyInterface {
  @Prop({ type: String, default: '' })
  public address!: string;

  @Prop({ type: Boolean, default: false })
  public barrierFree!: boolean;

  @Prop({ type: Number, default: null })
  public capacity!: number | null;

  @Prop({ type: String, default: '' })
  public content!: string;

  @Prop({ required: true })
  public eventDate!: Date;

  @Prop({ required: true, type: Array })
  public futurePlan!: FutureEvent[];

  @Prop({ required: true })
  public date!: EventDateRange;

  @Prop({ required: true })
  public time!: EventTimeRange;

  @Prop({ type: Boolean, default: false })
  public dogsAllowed!: boolean;

  @Prop({ type: String, default: '' })
  public link!: string;

  @Prop({ type: String, default: '' })
  public place!: string;

  @Prop({ default: null })
  public position!: GeoPosition | null;

  @Prop({ type: String, default: '' })
  public price!: string;

  public render() {
    return (
      <div class={rootClass}>
        <v-row>
          <v-col cols='8'>
            <EventDates date={this.date} time={this.time} />
            {this.futurePlan.length > 0 && (
              <v-divider class={`${rootClass}__date-divider`} />
            )}
            <EventPlan plan={this.futurePlan} />

            <v-divider class={`${rootClass}__date-divider`} />
            <Content text={this.content} class='mt-4' />
          </v-col>
          <v-col cols='4'>
            <v-banner class={`${rootClass}__meta`}>
              <h4>{this.place}</h4>
              <div>{this.address}</div>
              {this.position && (
                <MapWidget position={this.position} class='py-2'>
                  <span slot='title'>{this.$t('app.event.mapTitle')}</span>
                </MapWidget>
              )}
            </v-banner>

            {this.link && (
              <v-banner class={`${rootClass}__meta`}>
                <v-avatar slot='icon' size='40'>
                  <v-icon>mdi-web</v-icon>
                </v-avatar>
                <Link to={this.link}>{this.link}</Link>
              </v-banner>
            )}

            <table class={`${rootClass}__parameters mt-3`}>
              <tr>
                <th>{this.$t('app.event.barrierFree')}:</th>
                <td>
                  {this.$t(
                    this.barrierFree ? 'app.common.yes' : 'app.common.no'
                  )}
                </td>
              </tr>
              <tr>
                <th>{this.$t('app.event.dogsAllowed')}:</th>
                <td>
                  {this.$t(
                    this.dogsAllowed ? 'app.common.yes' : 'app.common.no'
                  )}
                </td>
              </tr>
              {this.price && (
                <tr>
                  <th>{this.$t('app.event.price')}:</th>
                  <td>{`${this.price}`}</td>
                </tr>
              )}
              {this.capacity && (
                <tr>
                  <th>{this.$t('app.event.capacity')}:</th>
                  <td>
                    {this.$tc('app.event.person', this.capacity, {
                      count: this.capacity,
                    })}
                  </td>
                </tr>
              )}
            </table>
          </v-col>
        </v-row>
      </div>
    );
  }
}
