/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * moje14 API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: knowledge-center-1.0.0-SNAPSHOT-9ae3c44
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./Configuration";

const BASE_PATH = "https://p14kc.azurewebsites.net".replace(/\/+$/, "");

/**
*
* @export
*/
export const COLLECTION_FORMATS = {
csv: ",",
ssv: " ",
tsv: "\t",
pipes: "|",
};

/**
* Fetch response typeguard
* @export
*/
export function isApiFetchResponse(data: any): data is Response {
return (
typeof data.body !== 'undefined' &&
typeof data.status === 'number' &&
typeof data.type === 'string' &&
typeof data.json === 'function' &&
typeof data.text === 'function'
);
}

/**
*
* @export
* @interface FetchAPI
*/
export interface FetchAPI {
(url: string, init?: any): Promise<Response>;
  }

  /**
  *
  * @export
  * @interface FetchArgs
  */
  export interface FetchArgs {
  url: string;
  options: any;
  }

  /**
  *
  * @export
  * @class BaseAPI
  */
  export class BaseAPI {
  protected configuration?: Configuration;

  constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
  if (configuration) {
  this.configuration = configuration;
  this.basePath = configuration.basePath || this.basePath;
  }
  }
  };

  /**
  *
  * @export
  * @class RequiredError
  * @extends {Error}
  */
  export class RequiredError extends Error {
  name: string = "RequiredError"
  constructor(public field: string, msg?: string) {
  super(msg);
  }
  }
        /**
        * 
        * @export
        * @type Body
        */
        export type Body = CzYsP14KcCommunityApiDtoKindGroupObject | CzYsP14KcCommunityApiDtoKindProjectObject | CzYsP14KcCommunityApiDtoKindCityHallObject;
        /**
         * 
         * @export
         * @interface Body1
         */
        export interface Body1 {
            /**
             * 
             * @type {Blob}
             * @memberof Body1
             */
            file?: Blob;
        }
        /**
        * 
        * @export
        * @type Body2
        */
        export type Body2 = CzYsP14KcCommunityApiDtoKindGroupUpdateObject | CzYsP14KcCommunityApiDtoKindProjectUpdateObject | CzYsP14KcCommunityApiDtoKindCityHallUpdateObject;
        /**
         * 
         * @export
         * @interface CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject
         */
        export interface CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject
             */
            category: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcAreaofinterestApiDtoUserAreaOfInterestObject
         */
        export interface CzYsP14KcAreaofinterestApiDtoUserAreaOfInterestObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcAreaofinterestApiDtoUserAreaOfInterestObject
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcAreaofinterestApiDtoUserAreaOfInterestObject
             */
            category: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcAreaofinterestApiDtoUserAreaOfInterestObject
             */
            standard: boolean;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcAreaofinterestApiDtoUserAreaOfInterestObject
             */
            weight: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeCommentObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            parentType: CzYsP14KcBackofficeApiDtoBackOfficeCommentObject.ParentTypeEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            parentId: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            parentTitle: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            deleted?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            sentiment: number;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            toxicAi?: boolean;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            toxicCount: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            toxicStatus: CzYsP14KcBackofficeApiDtoBackOfficeCommentObject.ToxicStatusEnum;
            /**
             * 
             * @type {CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            user: CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            frontendUrl: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeCommentObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeCommentObject {
            /**
             * @export
             * @enum {string}
             */
            export enum ParentTypeEnum {
                POST = <any> 'POST',
                EVENT = <any> 'EVENT',
                SURVEY = <any> 'SURVEY',
                COMMUNITY = <any> 'COMMUNITY'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum ToxicStatusEnum {
                CLEAR = <any> 'CLEAR',
                SUSPICIOUS = <any> 'SUSPICIOUS',
                OK = <any> 'OK',
                TOXIC = <any> 'TOXIC'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeCommunityCountObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeCommunityCountObject {
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityCountObject
             */
            members: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityCountObject
             */
            posts: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityCountObject
             */
            comments: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityCountObject
             */
            events: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityCountObject
             */
            surveys: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            name: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            badge: boolean;
            /**
             * 
             * @type {CzYsP14KcBackofficeApiDtoBackOfficeCommunityObjectPlace}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            place?: CzYsP14KcBackofficeApiDtoBackOfficeCommunityObjectPlace;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            description: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            visibility: CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject.VisibilityEnum;
            /**
             * 
             * @type {Array<CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            areasOfInterest?: Array<CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject>;
            /**
             * 
             * @type {CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            owner: CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            members: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            sentiment: number;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            activity: boolean;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            toxicity: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            rating: CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject.RatingEnum;
            /**
             * 
             * @type {CzYsP14KcWordpressApiDtoWordpressAuthorObject}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            wordpressAuthor?: CzYsP14KcWordpressApiDtoWordpressAuthorObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            frontendUrl: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE',
                OFFICIAL = <any> 'OFFICIAL'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum RatingEnum {
                A = <any> 'A',
                B = <any> 'B',
                C = <any> 'C',
                D = <any> 'D',
                E = <any> 'E'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeCommunityObjectPlace
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeCommunityObjectPlace {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObjectPlace
             */
            name?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObjectPlace
             */
            address?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObjectPlace
             */
            latitude?: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityObjectPlace
             */
            longitude?: number;
        }
        /**
         * At least one param must be set.
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject {
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject
             */
            badge?: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject
             */
            visibility?: CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject.VisibilityEnum;
            /**
             * 
             * @type {CzYsP14KcWordpressApiDtoWordpressAuthorObject}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject
             */
            wordpressAuthor?: CzYsP14KcWordpressApiDtoWordpressAuthorObject;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE',
                OFFICIAL = <any> 'OFFICIAL'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeEventObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeEventObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            id: string;
            /**
             * 
             * @type {CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            user: CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            dateStart: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            dateEnd: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            timeStart: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            timeEnd: string;
            /**
             * 
             * @type {Array<boolean>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            daysOfWeek?: Array<boolean>;
            /**
             * 
             * @type {CzYsP14KcPlaceApiDtoPlaceDetailObject}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            place: CzYsP14KcPlaceApiDtoPlaceDetailObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            price?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            link?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            capacity?: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            visibility: CzYsP14KcBackofficeApiDtoBackOfficeEventObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            deleted?: string;
            /**
             * 
             * @type {Array<CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            areasOfInterest?: Array<CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject>;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            flags?: Array<CzYsP14KcBackofficeApiDtoBackOfficeEventObject.FlagsEnum>;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            likeCount: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            commentCount: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            readCount: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            frontendUrl: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            toxicAi?: boolean;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            toxicCount: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            toxicStatus: CzYsP14KcBackofficeApiDtoBackOfficeEventObject.ToxicStatusEnum;
            /**
             * 
             * @type {CzYsP14KcBackofficeApiDtoBackOfficeEventObjectCommunity}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            community?: CzYsP14KcBackofficeApiDtoBackOfficeEventObjectCommunity;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeEventObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeEventObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum FlagsEnum {
                BARRIERFREE = <any> 'BARRIER_FREE',
                DOGSALLOWED = <any> 'DOGS_ALLOWED'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum ToxicStatusEnum {
                CLEAR = <any> 'CLEAR',
                SUSPICIOUS = <any> 'SUSPICIOUS',
                OK = <any> 'OK',
                TOXIC = <any> 'TOXIC'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeEventObjectCommunity
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeEventObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeEventObjectCommunity
             */
            toxicity: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeMemberObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeMemberObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeMemberObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeMemberObject
             */
            displayName: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeMemberObject
             */
            rank: CzYsP14KcBackofficeApiDtoBackOfficeMemberObject.RankEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeMemberObject
             */
            banned: boolean;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeMemberObject
             */
            verified: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeMemberObject
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeMemberObject
             */
            type: CzYsP14KcBackofficeApiDtoBackOfficeMemberObject.TypeEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeMemberObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeMemberObject {
            /**
             * @export
             * @enum {string}
             */
            export enum RankEnum {
                STANDARD = <any> 'STANDARD',
                ACTIVE = <any> 'ACTIVE',
                AMBASSADOR = <any> 'AMBASSADOR'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum TypeEnum {
                APPLICANT = <any> 'APPLICANT',
                MEMBER = <any> 'MEMBER',
                DEPUTY = <any> 'DEPUTY',
                OWNER = <any> 'OWNER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            title?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            body: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            crisis: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            channel: CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject.ChannelEnum;
            /**
             * 
             * @type {CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            user: CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject;
            /**
             * 
             * @type {Array<CzYsP14KcNotificationApiDtoNotificationMessageRecipientObject>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            sendTo?: Array<CzYsP14KcNotificationApiDtoNotificationMessageRecipientObject>;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject {
            /**
             * @export
             * @enum {string}
             */
            export enum ChannelEnum {
                NONE = <any> 'NONE',
                PUSH = <any> 'PUSH',
                INAPP = <any> 'INAPP',
                YOURPASS = <any> 'YOURPASS'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
             */
            category?: CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject.CategoryEnum;
            /**
             * foreign key to ruian city part, list of city parts could be read from EP /city-part [code]
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
             */
            ruianCityPartCode?: string;
            /**
             * foreign key to ruian street, list of streets could be read from EP /street [streetId]
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
             */
            ruianStreetCode?: string;
            /**
             * foreign key to ruian place, list of places could be read from EP /street [ruianId]
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
             */
            ruianPlaceId?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
             */
            content?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
             */
            extSource?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
             */
            extId?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
             */
            extUrl?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
             */
            start: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
             */
            end?: string;
            /**
             * 
             * @type {any}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
             */
            data?: any;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject {
            /**
             * @export
             * @enum {string}
             */
            export enum CategoryEnum {
                ELECTRICITY = <any> 'ELECTRICITY',
                WATER = <any> 'WATER',
                HEATING = <any> 'HEATING',
                CONTAINER = <any> 'CONTAINER',
                TRANSPORT = <any> 'TRANSPORT',
                PUBLICTRANSPORT = <any> 'PUBLIC_TRANSPORT',
                SECURITY = <any> 'SECURITY',
                SUMMERCLEANING = <any> 'SUMMER_CLEANING',
                HEALTH = <any> 'HEALTH'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficePostObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficePostObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            title?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            visibility: CzYsP14KcBackofficeApiDtoBackOfficePostObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            deleted?: string;
            /**
             * 
             * @type {CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            user: CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            source: CzYsP14KcBackofficeApiDtoBackOfficePostObject.SourceEnum;
            /**
             * 
             * @type {Array<CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            areasOfInterest?: Array<CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject>;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            likeCount: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            commentCount: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            readCount: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            frontendUrl: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            toxicAi?: boolean;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            toxicCount: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            toxicStatus: CzYsP14KcBackofficeApiDtoBackOfficePostObject.ToxicStatusEnum;
            /**
             * 
             * @type {CzYsP14KcBackofficeApiDtoBackOfficePostObjectCommunity}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            community?: CzYsP14KcBackofficeApiDtoBackOfficePostObjectCommunity;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficePostObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficePostObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum SourceEnum {
                SELF = <any> 'SELF',
                WORDPRESS = <any> 'WORDPRESS',
                FACEBOOK = <any> 'FACEBOOK',
                INSTAGRAM = <any> 'INSTAGRAM'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum ToxicStatusEnum {
                CLEAR = <any> 'CLEAR',
                SUSPICIOUS = <any> 'SUSPICIOUS',
                OK = <any> 'OK',
                TOXIC = <any> 'TOXIC'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficePostObjectCommunity
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficePostObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficePostObjectCommunity
             */
            toxicity: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeStreetObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeStreetObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            municipalityId: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            municipalityName: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            streetId: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            streetName: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            houseNumber: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            landmarkNumber?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            landmarkNumberSign?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            latitude: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            longitude: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            to: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            visibility: CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            deleted?: string;
            /**
             * 
             * @type {CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            user: CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject;
            /**
             * 
             * @type {Array<CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            areasOfInterest: Array<CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject>;
            /**
             * 
             * @type {CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectPost}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            post?: CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectPost;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectOption>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            options: Array<CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectOption>;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            likeCount: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            commentCount: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            readCount: number;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            toxicAi?: boolean;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            toxicCount: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            toxicStatus: CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject.ToxicStatusEnum;
            /**
             * 
             * @type {CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectCommunity}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            community?: CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectCommunity;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            frontendUrl?: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum ToxicStatusEnum {
                CLEAR = <any> 'CLEAR',
                SUSPICIOUS = <any> 'SUSPICIOUS',
                OK = <any> 'OK',
                TOXIC = <any> 'TOXIC'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectCommunity
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectCommunity
             */
            toxicity: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectOption
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectOption {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectOption
             */
            name: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectOption
             */
            voteCount: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectPost
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectPost {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectPost
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeSurveyObjectPost
             */
            title: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject {
            /**
             * Only [OK, TOXIC] values are valid
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject
             */
            status: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject.StatusEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject
             */
            messageToUser?: boolean;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject {
            /**
             * @export
             * @enum {string}
             */
            export enum StatusEnum {
                CLEAR = <any> 'CLEAR',
                SUSPICIOUS = <any> 'SUSPICIOUS',
                OK = <any> 'OK',
                TOXIC = <any> 'TOXIC'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserCountObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserCountObject {
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserCountObject
             */
            posts: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserCountObject
             */
            comments: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserCountObject
             */
            events: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserCountObject
             */
            likes: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObject {
            /**
             * 
             * @type {Array<CzYsP14KcAreaofinterestApiDtoUserAreaOfInterestObject>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObject
             */
            interests?: Array<CzYsP14KcAreaofinterestApiDtoUserAreaOfInterestObject>;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectCommunity>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObject
             */
            communities?: Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectCommunity>;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObject
             */
            posts?: Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost>;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObject
             */
            events?: Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent>;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObject
             */
            surveys?: Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey>;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObject
             */
            likes?: Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike>;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObject
             */
            comments?: Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment>;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectRead>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObject
             */
            reads?: Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectRead>;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
             */
            parentType: CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment.ParentTypeEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
             */
            parentId: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
             */
            parentTitle: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
             */
            deleted?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
             */
            sentiment: number;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
             */
            toxicAi?: boolean;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
             */
            toxicCount: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
             */
            toxicStatus: CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment.ToxicStatusEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
             */
            frontendUrl: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectComment {
            /**
             * @export
             * @enum {string}
             */
            export enum ParentTypeEnum {
                POST = <any> 'POST',
                EVENT = <any> 'EVENT',
                SURVEY = <any> 'SURVEY',
                COMMUNITY = <any> 'COMMUNITY'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum ToxicStatusEnum {
                CLEAR = <any> 'CLEAR',
                SUSPICIOUS = <any> 'SUSPICIOUS',
                OK = <any> 'OK',
                TOXIC = <any> 'TOXIC'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectCommunity
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectCommunity
             */
            badge: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectCommunity
             */
            membershipCreated: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectCommunity
             */
            membershipType: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectCommunity
             */
            yourPassId?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectCommunity
             */
            yourPassUrl?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectCommunity
             */
            frontendUrl: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            dateStart: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            dateEnd: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            timeStart: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            timeEnd: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            deleted?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            visibility: CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            communityId: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            communityName: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            frontendUrl: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            toxicAi?: boolean;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            toxicCount: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
             */
            toxicStatus: CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent.ToxicStatusEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectEvent {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum ToxicStatusEnum {
                CLEAR = <any> 'CLEAR',
                SUSPICIOUS = <any> 'SUSPICIOUS',
                OK = <any> 'OK',
                TOXIC = <any> 'TOXIC'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike
             */
            parentType: CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike.ParentTypeEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike
             */
            parentId: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike
             */
            parentTitle: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike
             */
            creationDate: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike
             */
            deletionDate?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike
             */
            frontendUrl: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike {
            /**
             * @export
             * @enum {string}
             */
            export enum ParentTypeEnum {
                POST = <any> 'POST',
                EVENT = <any> 'EVENT',
                SURVEY = <any> 'SURVEY'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
             */
            visibility: CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
             */
            deleted?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
             */
            communityId: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
             */
            communityName: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
             */
            frontendUrl: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
             */
            toxicAi?: boolean;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
             */
            toxicCount: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
             */
            toxicStatus: CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost.ToxicStatusEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectPost {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum ToxicStatusEnum {
                CLEAR = <any> 'CLEAR',
                SUSPICIOUS = <any> 'SUSPICIOUS',
                OK = <any> 'OK',
                TOXIC = <any> 'TOXIC'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectRead
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectRead {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectRead
             */
            parentType: CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectRead.ParentTypeEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectRead
             */
            parentId: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectRead
             */
            parentTitle: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectRead
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectRead
             */
            frontendUrl: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectRead
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectRead {
            /**
             * @export
             * @enum {string}
             */
            export enum ParentTypeEnum {
                POST = <any> 'POST',
                EVENT = <any> 'EVENT',
                SURVEY = <any> 'SURVEY'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
             */
            visibility: CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
             */
            deleted?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
             */
            communityId: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
             */
            communityName: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
             */
            frontendUrl: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
             */
            toxicAi?: boolean;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
             */
            toxicCount: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
             */
            toxicStatus: CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey.ToxicStatusEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectSurvey {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum ToxicStatusEnum {
                CLEAR = <any> 'CLEAR',
                SUSPICIOUS = <any> 'SUSPICIOUS',
                OK = <any> 'OK',
                TOXIC = <any> 'TOXIC'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            registrationDate: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            lastActivityDate?: string|null;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            deletionDate?: string|null;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            tokenUid: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            firstName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            lastName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            displayName: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            birthday?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            gender?: CzYsP14KcBackofficeApiDtoBackOfficeUserObject.GenderEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            phone?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            email: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            role: CzYsP14KcBackofficeApiDtoBackOfficeUserObject.RoleEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            banned: boolean;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            verified: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            rank: CzYsP14KcBackofficeApiDtoBackOfficeUserObject.RankEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            citizenP14?: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            education?: CzYsP14KcBackofficeApiDtoBackOfficeUserObject.EducationEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            job?: CzYsP14KcBackofficeApiDtoBackOfficeUserObject.JobEnum;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            sentiment: number;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            activity: boolean;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            toxicity: number;
            /**
             * 
             * @type {CzYsP14KcBackofficeApiDtoBackOfficeUserObjectUserPlace}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            place?: CzYsP14KcBackofficeApiDtoBackOfficeUserObjectUserPlace;
            /**
             * 
             * @type {Array<CzYsP14KcAreaofinterestApiDtoUserAreaOfInterestObject>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            areasOfInterest?: Array<CzYsP14KcAreaofinterestApiDtoUserAreaOfInterestObject>;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeUserObjectCommunityMembership>}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            communities?: Array<CzYsP14KcBackofficeApiDtoBackOfficeUserObjectCommunityMembership>;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeUserObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeUserObject {
            /**
             * @export
             * @enum {string}
             */
            export enum GenderEnum {
                MALE = <any> 'MALE',
                FEMALE = <any> 'FEMALE',
                OTHER = <any> 'OTHER'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum RoleEnum {
                USER = <any> 'USER',
                BACKOFFICEUSER = <any> 'BACKOFFICE_USER',
                BASICADMIN = <any> 'BASIC_ADMIN',
                SUPERADMIN = <any> 'SUPER_ADMIN'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum RankEnum {
                STANDARD = <any> 'STANDARD',
                ACTIVE = <any> 'ACTIVE',
                AMBASSADOR = <any> 'AMBASSADOR'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum EducationEnum {
                A = <any> 'A',
                B = <any> 'B',
                C = <any> 'C',
                D = <any> 'D',
                E = <any> 'E',
                H = <any> 'H',
                J = <any> 'J',
                K = <any> 'K',
                L = <any> 'L',
                M = <any> 'M',
                N = <any> 'N',
                P = <any> 'P',
                R = <any> 'R',
                T = <any> 'T',
                V = <any> 'V'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum JobEnum {
                STUDENT = <any> 'STUDENT',
                EMPLOYEE = <any> 'EMPLOYEE',
                BUSINESSMAN = <any> 'BUSINESSMAN',
                PENSIONER = <any> 'PENSIONER',
                UNEMPLOYED = <any> 'UNEMPLOYED',
                OTHER = <any> 'OTHER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserObjectCommunityMembership
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserObjectCommunityMembership {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObjectCommunityMembership
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObjectCommunityMembership
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObjectCommunityMembership
             */
            type: CzYsP14KcBackofficeApiDtoBackOfficeUserObjectCommunityMembership.TypeEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObjectCommunityMembership
             */
            created: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObjectCommunityMembership
             */
            badge: boolean;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeUserObjectCommunityMembership
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeUserObjectCommunityMembership {
            /**
             * @export
             * @enum {string}
             */
            export enum TypeEnum {
                APPLICANT = <any> 'APPLICANT',
                MEMBER = <any> 'MEMBER',
                DEPUTY = <any> 'DEPUTY',
                OWNER = <any> 'OWNER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserObjectUserPlace
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserObjectUserPlace {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObjectUserPlace
             */
            cityPartName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObjectUserPlace
             */
            cityBlockName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObjectUserPlace
             */
            streetName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserObjectUserPlace
             */
            address?: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject
             */
            displayName: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject
             */
            banned: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject
             */
            rank: CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject.RankEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject
             */
            verified: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject
             */
            deletionDate?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject
             */
            toxicity?: number;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeUserSimpleObject {
            /**
             * @export
             * @enum {string}
             */
            export enum RankEnum {
                STANDARD = <any> 'STANDARD',
                ACTIVE = <any> 'ACTIVE',
                AMBASSADOR = <any> 'AMBASSADOR'
            }
        }
        /**
         * At least one param must be set.
         * @export
         * @interface CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject
         */
        export interface CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject {
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject
             */
            banned?: boolean;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject
             */
            verified?: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject
             */
            role?: CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject.RoleEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject
             */
            rank?: CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject.RankEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject
         */
        export namespace CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject {
            /**
             * @export
             * @enum {string}
             */
            export enum RoleEnum {
                USER = <any> 'USER',
                BACKOFFICEUSER = <any> 'BACKOFFICE_USER',
                BASICADMIN = <any> 'BASIC_ADMIN',
                SUPERADMIN = <any> 'SUPER_ADMIN'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum RankEnum {
                STANDARD = <any> 'STANDARD',
                ACTIVE = <any> 'ACTIVE',
                AMBASSADOR = <any> 'AMBASSADOR'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBiApiDtoReportObject
         */
        export interface CzYsP14KcBiApiDtoReportObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoReportObject
             */
            reportId: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoReportObject
             */
            pageName: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBiApiDtoVisualObject
         */
        export interface CzYsP14KcBiApiDtoVisualObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoVisualObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoVisualObject
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoVisualObject
             */
            reportId: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoVisualObject
             */
            pageName: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoVisualObject
             */
            visualName: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoVisualObject
             */
            image: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoVisualObject
             */
            section: CzYsP14KcBiApiDtoVisualObject.SectionEnum;
            /**
             * 
             * @type {CzYsP14KcBiApiDtoVisualObjectFilter}
             * @memberof CzYsP14KcBiApiDtoVisualObject
             */
            filter?: CzYsP14KcBiApiDtoVisualObjectFilter;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoVisualObject
             */
            activated?: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcBiApiDtoVisualObject
         */
        export namespace CzYsP14KcBiApiDtoVisualObject {
            /**
             * @export
             * @enum {string}
             */
            export enum SectionEnum {
                COMMUNITY = <any> 'COMMUNITY',
                COMMUNITIES = <any> 'COMMUNITIES',
                USER = <any> 'USER',
                USERS = <any> 'USERS',
                TRENDS = <any> 'TRENDS'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBiApiDtoVisualObjectFilter
         */
        export interface CzYsP14KcBiApiDtoVisualObjectFilter {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoVisualObjectFilter
             */
            column?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoVisualObjectFilter
             */
            table?: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcBiApiDtoWidgetObject
         */
        export interface CzYsP14KcBiApiDtoWidgetObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcBiApiDtoWidgetObject
             */
            id: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcBiApiDtoWidgetObject
             */
            active: boolean;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcChatbotChatbotTelemetryObject
         */
        export interface CzYsP14KcChatbotChatbotTelemetryObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcChatbotChatbotTelemetryObject
             */
            id?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcChatbotChatbotTelemetryObject
             */
            timestamp?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcChatbotChatbotTelemetryObject
             */
            question?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcChatbotChatbotTelemetryObject
             */
            answer?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcChatbotChatbotTelemetryObject
             */
            score?: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcChatbotChatbotTelemetryObject
             */
            status?: CzYsP14KcChatbotChatbotTelemetryObject.StatusEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcChatbotChatbotTelemetryObject
         */
        export namespace CzYsP14KcChatbotChatbotTelemetryObject {
            /**
             * @export
             * @enum {string}
             */
            export enum StatusEnum {
                NEW = <any> 'NEW',
                PROCESSED = <any> 'PROCESSED'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcChatbotChatbotTelemetryUpdateObject
         */
        export interface CzYsP14KcChatbotChatbotTelemetryUpdateObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcChatbotChatbotTelemetryUpdateObject
             */
            status?: CzYsP14KcChatbotChatbotTelemetryUpdateObject.StatusEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcChatbotChatbotTelemetryUpdateObject
         */
        export namespace CzYsP14KcChatbotChatbotTelemetryUpdateObject {
            /**
             * @export
             * @enum {string}
             */
            export enum StatusEnum {
                NEW = <any> 'NEW',
                PROCESSED = <any> 'PROCESSED'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCityblockApiDtoCityBlockDetailObject
         */
        export interface CzYsP14KcCityblockApiDtoCityBlockDetailObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCityblockApiDtoCityBlockDetailObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCityblockApiDtoCityBlockDetailObject
             */
            name: string;
            /**
             * 
             * @type {CzYsP14KcRuianApiDtoRuianCityPartObject}
             * @memberof CzYsP14KcCityblockApiDtoCityBlockDetailObject
             */
            cityPart: CzYsP14KcRuianApiDtoRuianCityPartObject;
            /**
             * 
             * @type {Array<CzYsP14KcCityblockApiDtoCityBlockStreetObject>}
             * @memberof CzYsP14KcCityblockApiDtoCityBlockDetailObject
             */
            streets?: Array<CzYsP14KcCityblockApiDtoCityBlockStreetObject>;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCityblockApiDtoCityBlockObject
         */
        export interface CzYsP14KcCityblockApiDtoCityBlockObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCityblockApiDtoCityBlockObject
             */
            id?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCityblockApiDtoCityBlockObject
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCityblockApiDtoCityBlockObject
             */
            cityPartCode: string;
            /**
             * 
             * @type {Array<CzYsP14KcCityblockApiDtoCityBlockPointsObject>}
             * @memberof CzYsP14KcCityblockApiDtoCityBlockObject
             */
            points?: Array<CzYsP14KcCityblockApiDtoCityBlockPointsObject>;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCityblockApiDtoCityBlockPointsObject
         */
        export interface CzYsP14KcCityblockApiDtoCityBlockPointsObject {
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcCityblockApiDtoCityBlockPointsObject
             */
            latitude: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcCityblockApiDtoCityBlockPointsObject
             */
            longitude: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCityblockApiDtoCityBlockStreetObject
         */
        export interface CzYsP14KcCityblockApiDtoCityBlockStreetObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCityblockApiDtoCityBlockStreetObject
             */
            code: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCityblockApiDtoCityBlockStreetObject
             */
            name: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommentApiDtoCommentObject
         */
        export interface CzYsP14KcCommentApiDtoCommentObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommentApiDtoCommentObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommentApiDtoCommentObject
             */
            content: string|null;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommentApiDtoCommentObject
             */
            created: string;
            /**
             * 
             * @type {CzYsP14KcCommentApiDtoCommentObjectUser}
             * @memberof CzYsP14KcCommentApiDtoCommentObject
             */
            user: CzYsP14KcCommentApiDtoCommentObjectUser;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommentApiDtoCommentObject
             */
            deletionType: CzYsP14KcCommentApiDtoCommentObject.DeletionTypeEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommentApiDtoCommentObject
         */
        export namespace CzYsP14KcCommentApiDtoCommentObject {
            /**
             * @export
             * @enum {string}
             */
            export enum DeletionTypeEnum {
                NONE = <any> 'NONE',
                TOXIC = <any> 'TOXIC',
                USER = <any> 'USER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommentApiDtoCommentObjectUser
         */
        export interface CzYsP14KcCommentApiDtoCommentObjectUser {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommentApiDtoCommentObjectUser
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommentApiDtoCommentObjectUser
             */
            displayName: string|null;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommentApiDtoCommentObjectUser
             */
            rank: CzYsP14KcCommentApiDtoCommentObjectUser.RankEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommentApiDtoCommentObjectUser
         */
        export namespace CzYsP14KcCommentApiDtoCommentObjectUser {
            /**
             * @export
             * @enum {string}
             */
            export enum RankEnum {
                STANDARD = <any> 'STANDARD',
                ACTIVE = <any> 'ACTIVE',
                AMBASSADOR = <any> 'AMBASSADOR'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommentApiDtoCommentSubmissionObject
         */
        export interface CzYsP14KcCommentApiDtoCommentSubmissionObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommentApiDtoCommentSubmissionObject
             */
            content: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoCommunityDetailObject
         */
        export interface CzYsP14KcCommunityApiDtoCommunityDetailObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            kind: CzYsP14KcCommunityApiDtoCommunityDetailObject.KindEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            visibility: CzYsP14KcCommunityApiDtoCommunityDetailObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            yourPassUrl?: string;
            /**
             * 
             * @type {CzYsP14KcCommunityApiDtoCommunityPlaceObject}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            place?: CzYsP14KcCommunityApiDtoCommunityPlaceObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            description: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            badge: boolean;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            membershipType?: CzYsP14KcCommunityApiDtoCommunityDetailObject.MembershipTypeEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            canJoin?: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            ownerId: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            id: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcCommunityApiDtoCommunityDetailObject
             */
            members: number;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommunityApiDtoCommunityDetailObject
         */
        export namespace CzYsP14KcCommunityApiDtoCommunityDetailObject {
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE',
                OFFICIAL = <any> 'OFFICIAL'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum MembershipTypeEnum {
                INVITEE = <any> 'INVITEE',
                APPLICANT = <any> 'APPLICANT',
                MEMBER = <any> 'MEMBER',
                DEPUTY = <any> 'DEPUTY',
                OWNER = <any> 'OWNER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoCommunityListDetailObject
         */
        export interface CzYsP14KcCommunityApiDtoCommunityListDetailObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            kind: CzYsP14KcCommunityApiDtoCommunityListDetailObject.KindEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            visibility: CzYsP14KcCommunityApiDtoCommunityListDetailObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            yourPassUrl?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            description: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            badge: boolean;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            membershipType?: CzYsP14KcCommunityApiDtoCommunityListDetailObject.MembershipTypeEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            id: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommunityApiDtoCommunityListDetailObject
         */
        export namespace CzYsP14KcCommunityApiDtoCommunityListDetailObject {
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE',
                OFFICIAL = <any> 'OFFICIAL'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum MembershipTypeEnum {
                INVITEE = <any> 'INVITEE',
                APPLICANT = <any> 'APPLICANT',
                MEMBER = <any> 'MEMBER',
                DEPUTY = <any> 'DEPUTY',
                OWNER = <any> 'OWNER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoCommunityMapObject
         */
        export interface CzYsP14KcCommunityApiDtoCommunityMapObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityMapObject
             */
            kind: CzYsP14KcCommunityApiDtoCommunityMapObject.KindEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityMapObject
             */
            visibility: CzYsP14KcCommunityApiDtoCommunityMapObject.VisibilityEnum;
            /**
             * 
             * @type {CzYsP14KcCommunityApiDtoCommunityPlaceObject}
             * @memberof CzYsP14KcCommunityApiDtoCommunityMapObject
             */
            place?: CzYsP14KcCommunityApiDtoCommunityPlaceObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityMapObject
             */
            membershipType?: CzYsP14KcCommunityApiDtoCommunityMapObject.MembershipTypeEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityMapObject
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityMapObject
             */
            id: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommunityApiDtoCommunityMapObject
         */
        export namespace CzYsP14KcCommunityApiDtoCommunityMapObject {
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE',
                OFFICIAL = <any> 'OFFICIAL'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum MembershipTypeEnum {
                APPLICANT = <any> 'APPLICANT',
                MEMBER = <any> 'MEMBER',
                DEPUTY = <any> 'DEPUTY',
                OWNER = <any> 'OWNER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoCommunityMembershipObject
         */
        export interface CzYsP14KcCommunityApiDtoCommunityMembershipObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityMembershipObject
             */
            id: string|null;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityMembershipObject
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityMembershipObject
             */
            type: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject
         */
        export interface CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject
             */
            membershipType: CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject.MembershipTypeEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject
             */
            remainDeputy?: boolean;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject
         */
        export namespace CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject {
            /**
             * @export
             * @enum {string}
             */
            export enum MembershipTypeEnum {
                APPLICANT = <any> 'APPLICANT',
                MEMBER = <any> 'MEMBER',
                DEPUTY = <any> 'DEPUTY',
                OWNER = <any> 'OWNER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoCommunityPlaceObject
         */
        export interface CzYsP14KcCommunityApiDtoCommunityPlaceObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityPlaceObject
             */
            name?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityPlaceObject
             */
            address?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcCommunityApiDtoCommunityPlaceObject
             */
            latitude?: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcCommunityApiDtoCommunityPlaceObject
             */
            longitude?: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoCommunityPlaceObject
             */
            ruianId?: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoKindCityHallObject
         */
        export interface CzYsP14KcCommunityApiDtoKindCityHallObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallObject
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallObject
             */
            image: string;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallObject
             */
            areasOfInterest: Array<string>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallObject
             */
            description: string;
            /**
             * 
             * @type {CzYsP14KcCommunityApiDtoCommunityPlaceObject}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallObject
             */
            place?: CzYsP14KcCommunityApiDtoCommunityPlaceObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallObject
             */
            visibility: CzYsP14KcCommunityApiDtoKindCityHallObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallObject
             */
            kind?: CzYsP14KcCommunityApiDtoKindCityHallObject.KindEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommunityApiDtoKindCityHallObject
         */
        export namespace CzYsP14KcCommunityApiDtoKindCityHallObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE',
                OFFICIAL = <any> 'OFFICIAL'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoKindCityHallUpdateObject
         */
        export interface CzYsP14KcCommunityApiDtoKindCityHallUpdateObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallUpdateObject
             */
            name?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallUpdateObject
             */
            image?: string;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallUpdateObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallUpdateObject
             */
            description?: string;
            /**
             * 
             * @type {CzYsP14KcCommunityApiDtoCommunityPlaceObject}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallUpdateObject
             */
            place?: CzYsP14KcCommunityApiDtoCommunityPlaceObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallUpdateObject
             */
            visibility?: CzYsP14KcCommunityApiDtoKindCityHallUpdateObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindCityHallUpdateObject
             */
            kind?: CzYsP14KcCommunityApiDtoKindCityHallUpdateObject.KindEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommunityApiDtoKindCityHallUpdateObject
         */
        export namespace CzYsP14KcCommunityApiDtoKindCityHallUpdateObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE',
                OFFICIAL = <any> 'OFFICIAL'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoKindGroupObject
         */
        export interface CzYsP14KcCommunityApiDtoKindGroupObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupObject
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupObject
             */
            image: string;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupObject
             */
            areasOfInterest: Array<string>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupObject
             */
            description: string;
            /**
             * 
             * @type {CzYsP14KcCommunityApiDtoCommunityPlaceObject}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupObject
             */
            place?: CzYsP14KcCommunityApiDtoCommunityPlaceObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupObject
             */
            visibility: CzYsP14KcCommunityApiDtoKindGroupObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupObject
             */
            kind?: CzYsP14KcCommunityApiDtoKindGroupObject.KindEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommunityApiDtoKindGroupObject
         */
        export namespace CzYsP14KcCommunityApiDtoKindGroupObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE',
                OFFICIAL = <any> 'OFFICIAL'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoKindGroupUpdateObject
         */
        export interface CzYsP14KcCommunityApiDtoKindGroupUpdateObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupUpdateObject
             */
            name?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupUpdateObject
             */
            image?: string;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupUpdateObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupUpdateObject
             */
            description?: string;
            /**
             * 
             * @type {CzYsP14KcCommunityApiDtoCommunityPlaceObject}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupUpdateObject
             */
            place?: CzYsP14KcCommunityApiDtoCommunityPlaceObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupUpdateObject
             */
            visibility?: CzYsP14KcCommunityApiDtoKindGroupUpdateObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindGroupUpdateObject
             */
            kind?: CzYsP14KcCommunityApiDtoKindGroupUpdateObject.KindEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommunityApiDtoKindGroupUpdateObject
         */
        export namespace CzYsP14KcCommunityApiDtoKindGroupUpdateObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE',
                OFFICIAL = <any> 'OFFICIAL'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoKindProjectDataObject
         */
        export interface CzYsP14KcCommunityApiDtoKindProjectDataObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectDataObject
             */
            state: CzYsP14KcCommunityApiDtoKindProjectDataObject.StateEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectDataObject
             */
            priority: CzYsP14KcCommunityApiDtoKindProjectDataObject.PriorityEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommunityApiDtoKindProjectDataObject
         */
        export namespace CzYsP14KcCommunityApiDtoKindProjectDataObject {
            /**
             * @export
             * @enum {string}
             */
            export enum StateEnum {
                INTENT = <any> 'INTENT',
                PROJECT = <any> 'PROJECT',
                PROGRESS = <any> 'PROGRESS',
                FINALIZED = <any> 'FINALIZED',
                CANCELED = <any> 'CANCELED'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum PriorityEnum {
                A = <any> 'A',
                B = <any> 'B',
                C = <any> 'C'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoKindProjectObject
         */
        export interface CzYsP14KcCommunityApiDtoKindProjectObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectObject
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectObject
             */
            image: string;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectObject
             */
            areasOfInterest: Array<string>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectObject
             */
            description: string;
            /**
             * 
             * @type {CzYsP14KcCommunityApiDtoCommunityPlaceObject}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectObject
             */
            place: CzYsP14KcCommunityApiDtoCommunityPlaceObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectObject
             */
            visibility: CzYsP14KcCommunityApiDtoKindProjectObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectObject
             */
            kind?: CzYsP14KcCommunityApiDtoKindProjectObject.KindEnum;
            /**
             * 
             * @type {CzYsP14KcCommunityApiDtoKindProjectDataObject}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectObject
             */
            data: CzYsP14KcCommunityApiDtoKindProjectDataObject;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommunityApiDtoKindProjectObject
         */
        export namespace CzYsP14KcCommunityApiDtoKindProjectObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE',
                OFFICIAL = <any> 'OFFICIAL'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcCommunityApiDtoKindProjectUpdateObject
         */
        export interface CzYsP14KcCommunityApiDtoKindProjectUpdateObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectUpdateObject
             */
            name?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectUpdateObject
             */
            image?: string;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectUpdateObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectUpdateObject
             */
            description?: string;
            /**
             * 
             * @type {CzYsP14KcCommunityApiDtoCommunityPlaceObject}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectUpdateObject
             */
            place?: CzYsP14KcCommunityApiDtoCommunityPlaceObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectUpdateObject
             */
            visibility?: CzYsP14KcCommunityApiDtoKindProjectUpdateObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectUpdateObject
             */
            kind?: CzYsP14KcCommunityApiDtoKindProjectUpdateObject.KindEnum;
            /**
             * 
             * @type {CzYsP14KcCommunityApiDtoKindProjectDataObject}
             * @memberof CzYsP14KcCommunityApiDtoKindProjectUpdateObject
             */
            data?: CzYsP14KcCommunityApiDtoKindProjectDataObject;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcCommunityApiDtoKindProjectUpdateObject
         */
        export namespace CzYsP14KcCommunityApiDtoKindProjectUpdateObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE',
                OFFICIAL = <any> 'OFFICIAL'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoEventCreationObject
         */
        export interface CzYsP14KcEventApiDtoEventCreationObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            annotation?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            dateStart: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            dateEnd: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            timeStart: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            timeEnd: string;
            /**
             * 
             * @type {Array<boolean>}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            daysOfWeek?: Array<boolean>;
            /**
             * 
             * @type {CzYsP14KcPlaceApiDtoPlaceCreationObject}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            place: CzYsP14KcPlaceApiDtoPlaceCreationObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            price?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            link?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            capacity?: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            visibility: CzYsP14KcEventApiDtoEventCreationObject.VisibilityEnum;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            flags?: Array<CzYsP14KcEventApiDtoEventCreationObject.FlagsEnum>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventCreationObject
             */
            image?: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcEventApiDtoEventCreationObject
         */
        export namespace CzYsP14KcEventApiDtoEventCreationObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum FlagsEnum {
                BARRIERFREE = <any> 'BARRIER_FREE',
                DOGSALLOWED = <any> 'DOGS_ALLOWED'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoEventDetailObject
         */
        export interface CzYsP14KcEventApiDtoEventDetailObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            id?: string;
            /**
             * 
             * @type {CzYsP14KcEventApiDtoEventDetailObjectCommunity}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            community?: CzYsP14KcEventApiDtoEventDetailObjectCommunity;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            title?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            content?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            annotation?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            dateStart?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            dateEnd?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            timeStart?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            timeEnd?: string;
            /**
             * 
             * @type {Array<boolean>}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            daysOfWeek?: Array<boolean>;
            /**
             * 
             * @type {CzYsP14KcPlaceApiDtoPlaceDetailObject}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            place?: CzYsP14KcPlaceApiDtoPlaceDetailObject;
            /**
             * 
             * @type {Array<CzYsP14KcEventApiDtoPlanObject>}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            futurePlan?: Array<CzYsP14KcEventApiDtoPlanObject>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            price?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            link?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            capacity?: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            visibility?: CzYsP14KcEventApiDtoEventDetailObject.VisibilityEnum;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcEventApiDtoEventDetailObject
             */
            flags?: Array<CzYsP14KcEventApiDtoEventDetailObject.FlagsEnum>;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcEventApiDtoEventDetailObject
         */
        export namespace CzYsP14KcEventApiDtoEventDetailObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum FlagsEnum {
                BARRIERFREE = <any> 'BARRIER_FREE',
                DOGSALLOWED = <any> 'DOGS_ALLOWED'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoEventDetailObjectCommunity
         */
        export interface CzYsP14KcEventApiDtoEventDetailObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventDetailObjectCommunity
             */
            kind: CzYsP14KcEventApiDtoEventDetailObjectCommunity.KindEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcEventApiDtoEventDetailObjectCommunity
             */
            badge: boolean;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcEventApiDtoEventDetailObjectCommunity
         */
        export namespace CzYsP14KcEventApiDtoEventDetailObjectCommunity {
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoEventInstanceDetailObject
         */
        export interface CzYsP14KcEventApiDtoEventInstanceDetailObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            id?: string;
            /**
             * 
             * @type {CzYsP14KcEventApiDtoEventInstanceDetailObjectCommunity}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            community?: CzYsP14KcEventApiDtoEventInstanceDetailObjectCommunity;
            /**
             * 
             * @type {CzYsP14KcEventApiDtoEventInstanceDetailObjectUser}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            user?: CzYsP14KcEventApiDtoEventInstanceDetailObjectUser;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            title?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            content?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            dateStart?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            dateEnd?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            timeStart?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            timeEnd?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            visibility?: CzYsP14KcEventApiDtoEventInstanceDetailObject.VisibilityEnum;
            /**
             * 
             * @type {CzYsP14KcPlaceApiDtoPlaceDetailObject}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            place?: CzYsP14KcPlaceApiDtoPlaceDetailObject;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            likeCount?: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            commentCount?: number;
            /**
             * 
             * @type {Array<CzYsP14KcEventApiDtoPlanObject>}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            futurePlan?: Array<CzYsP14KcEventApiDtoPlanObject>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            price?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            link?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            capacity?: number;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObject
             */
            flags?: Array<CzYsP14KcEventApiDtoEventInstanceDetailObject.FlagsEnum>;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcEventApiDtoEventInstanceDetailObject
         */
        export namespace CzYsP14KcEventApiDtoEventInstanceDetailObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum FlagsEnum {
                BARRIERFREE = <any> 'BARRIER_FREE',
                DOGSALLOWED = <any> 'DOGS_ALLOWED'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoEventInstanceDetailObjectCommunity
         */
        export interface CzYsP14KcEventApiDtoEventInstanceDetailObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObjectCommunity
             */
            kind: CzYsP14KcEventApiDtoEventInstanceDetailObjectCommunity.KindEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObjectCommunity
             */
            membershipType?: CzYsP14KcEventApiDtoEventInstanceDetailObjectCommunity.MembershipTypeEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcEventApiDtoEventInstanceDetailObjectCommunity
         */
        export namespace CzYsP14KcEventApiDtoEventInstanceDetailObjectCommunity {
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum MembershipTypeEnum {
                APPLICANT = <any> 'APPLICANT',
                MEMBER = <any> 'MEMBER',
                DEPUTY = <any> 'DEPUTY',
                OWNER = <any> 'OWNER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoEventInstanceDetailObjectUser
         */
        export interface CzYsP14KcEventApiDtoEventInstanceDetailObjectUser {
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObjectUser
             */
            editable: boolean;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcEventApiDtoEventInstanceDetailObjectUser
             */
            liked: boolean;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoEventInstanceForMapObject
         */
        export interface CzYsP14KcEventApiDtoEventInstanceForMapObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObject
             */
            id?: string;
            /**
             * 
             * @type {CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObject
             */
            community?: CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObject
             */
            title?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObject
             */
            dateStart?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObject
             */
            dateEnd?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObject
             */
            timeStart?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObject
             */
            timeEnd?: string;
            /**
             * 
             * @type {CzYsP14KcPlaceApiDtoPlaceDetailObject}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObject
             */
            place?: CzYsP14KcPlaceApiDtoPlaceDetailObject;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity
         */
        export interface CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity
             */
            kind: CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity.KindEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity
             */
            badge?: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity
             */
            color?: CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity.ColorEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity
         */
        export namespace CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity {
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum ColorEnum {
                MCP14 = <any> 'MCP14',
                OWNER = <any> 'OWNER',
                MEMBER = <any> 'MEMBER',
                NONE = <any> 'NONE'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoEventInstanceObject
         */
        export interface CzYsP14KcEventApiDtoEventInstanceObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObject
             */
            id?: string;
            /**
             * 
             * @type {CzYsP14KcEventApiDtoEventInstanceObjectCommunity}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObject
             */
            community?: CzYsP14KcEventApiDtoEventInstanceObjectCommunity;
            /**
             * 
             * @type {CzYsP14KcEventApiDtoEventInstanceObjectUser}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObject
             */
            user?: CzYsP14KcEventApiDtoEventInstanceObjectUser;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObject
             */
            title?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObject
             */
            content?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObject
             */
            date?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObject
             */
            timeStart?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObject
             */
            timeEnd?: string;
            /**
             * 
             * @type {CzYsP14KcPlaceApiDtoPlaceDetailObject}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObject
             */
            place?: CzYsP14KcPlaceApiDtoPlaceDetailObject;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObject
             */
            likeCount?: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObject
             */
            commentCount?: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoEventInstanceObjectCommunity
         */
        export interface CzYsP14KcEventApiDtoEventInstanceObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObjectCommunity
             */
            kind: CzYsP14KcEventApiDtoEventInstanceObjectCommunity.KindEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObjectCommunity
             */
            badge: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObjectCommunity
             */
            membershipType?: CzYsP14KcEventApiDtoEventInstanceObjectCommunity.MembershipTypeEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObjectCommunity
             */
            color?: CzYsP14KcEventApiDtoEventInstanceObjectCommunity.ColorEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcEventApiDtoEventInstanceObjectCommunity
         */
        export namespace CzYsP14KcEventApiDtoEventInstanceObjectCommunity {
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum MembershipTypeEnum {
                APPLICANT = <any> 'APPLICANT',
                MEMBER = <any> 'MEMBER',
                DEPUTY = <any> 'DEPUTY',
                OWNER = <any> 'OWNER'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum ColorEnum {
                MCP14 = <any> 'MCP14',
                OWNER = <any> 'OWNER',
                MEMBER = <any> 'MEMBER',
                NONE = <any> 'NONE'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoEventInstanceObjectUser
         */
        export interface CzYsP14KcEventApiDtoEventInstanceObjectUser {
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObjectUser
             */
            editable: boolean;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcEventApiDtoEventInstanceObjectUser
             */
            liked: boolean;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoEventUpdateObject
         */
        export interface CzYsP14KcEventApiDtoEventUpdateObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            title?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            content?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            annotation?: string|null;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            dateStart?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            dateEnd?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            timeStart?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            timeEnd?: string;
            /**
             * 
             * @type {Array<boolean>}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            daysOfWeek?: Array<boolean>|null;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            exclusions?: Array<string>;
            /**
             * 
             * @type {CzYsP14KcPlaceApiDtoPlaceCreationObject}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            place?: CzYsP14KcPlaceApiDtoPlaceCreationObject;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            price?: string|null;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            link?: string|null;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            capacity?: number|null;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            visibility?: CzYsP14KcEventApiDtoEventUpdateObject.VisibilityEnum;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            flags?: Array<CzYsP14KcEventApiDtoEventUpdateObject.FlagsEnum>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoEventUpdateObject
             */
            image?: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcEventApiDtoEventUpdateObject
         */
        export namespace CzYsP14KcEventApiDtoEventUpdateObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum FlagsEnum {
                BARRIERFREE = <any> 'BARRIER_FREE',
                DOGSALLOWED = <any> 'DOGS_ALLOWED'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcEventApiDtoPlanObject
         */
        export interface CzYsP14KcEventApiDtoPlanObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoPlanObject
             */
            date?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoPlanObject
             */
            start?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcEventApiDtoPlanObject
             */
            end?: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcFcmApiDtoFcmTokenCreationObject
         */
        export interface CzYsP14KcFcmApiDtoFcmTokenCreationObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFcmApiDtoFcmTokenCreationObject
             */
            token: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcFeedApiDtoFeedWrapperObject
         */
        export interface CzYsP14KcFeedApiDtoFeedWrapperObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoFeedWrapperObject
             */
            type: CzYsP14KcFeedApiDtoFeedWrapperObject.TypeEnum;
            /**
             * The actual type depends on the value of the **type** field:  * POST: PostItemObject  * SURVEY: SurveyItemObject  * EVENT_LIST: array of EventInstanceObject  * OPERATIONAL_LIST: array of OperationalInfoObject
             * @type {any}
             * @memberof CzYsP14KcFeedApiDtoFeedWrapperObject
             */
            content: any;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcFeedApiDtoFeedWrapperObject
         */
        export namespace CzYsP14KcFeedApiDtoFeedWrapperObject {
            /**
             * @export
             * @enum {string}
             */
            export enum TypeEnum {
                POST = <any> 'POST',
                SURVEY = <any> 'SURVEY',
                EVENTLIST = <any> 'EVENT_LIST',
                OPERATIONALLIST = <any> 'OPERATIONAL_LIST'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcFeedApiDtoPostItemObject
         */
        export interface CzYsP14KcFeedApiDtoPostItemObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoPostItemObject
             */
            id: string;
            /**
             * 
             * @type {CzYsP14KcFeedApiDtoPostItemObjectCommunity}
             * @memberof CzYsP14KcFeedApiDtoPostItemObject
             */
            community: CzYsP14KcFeedApiDtoPostItemObjectCommunity;
            /**
             * 
             * @type {CzYsP14KcFeedApiDtoPostItemObjectUser}
             * @memberof CzYsP14KcFeedApiDtoPostItemObject
             */
            user?: CzYsP14KcFeedApiDtoPostItemObjectUser;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoPostItemObject
             */
            title?: string|null;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoPostItemObject
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoPostItemObject
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoPostItemObject
             */
            source: CzYsP14KcFeedApiDtoPostItemObject.SourceEnum;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcFeedApiDtoPostItemObject
             */
            likeCount: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcFeedApiDtoPostItemObject
             */
            commentCount: number;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcFeedApiDtoPostItemObject
         */
        export namespace CzYsP14KcFeedApiDtoPostItemObject {
            /**
             * @export
             * @enum {string}
             */
            export enum SourceEnum {
                SELF = <any> 'SELF',
                WORDPRESS = <any> 'WORDPRESS',
                FACEBOOK = <any> 'FACEBOOK',
                INSTAGRAM = <any> 'INSTAGRAM'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcFeedApiDtoPostItemObjectCommunity
         */
        export interface CzYsP14KcFeedApiDtoPostItemObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoPostItemObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoPostItemObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoPostItemObjectCommunity
             */
            kind: CzYsP14KcFeedApiDtoPostItemObjectCommunity.KindEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcFeedApiDtoPostItemObjectCommunity
             */
            badge: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoPostItemObjectCommunity
             */
            membershipType?: CzYsP14KcFeedApiDtoPostItemObjectCommunity.MembershipTypeEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcFeedApiDtoPostItemObjectCommunity
         */
        export namespace CzYsP14KcFeedApiDtoPostItemObjectCommunity {
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum MembershipTypeEnum {
                APPLICANT = <any> 'APPLICANT',
                MEMBER = <any> 'MEMBER',
                DEPUTY = <any> 'DEPUTY',
                OWNER = <any> 'OWNER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcFeedApiDtoPostItemObjectUser
         */
        export interface CzYsP14KcFeedApiDtoPostItemObjectUser {
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcFeedApiDtoPostItemObjectUser
             */
            editable: boolean;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcFeedApiDtoPostItemObjectUser
             */
            liked: boolean;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcFeedApiDtoSurveyItemObject
         */
        export interface CzYsP14KcFeedApiDtoSurveyItemObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObject
             */
            id: string;
            /**
             * 
             * @type {CzYsP14KcFeedApiDtoSurveyItemObjectCommunity}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObject
             */
            community: CzYsP14KcFeedApiDtoSurveyItemObjectCommunity;
            /**
             * 
             * @type {CzYsP14KcFeedApiDtoSurveyItemObjectUser}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObject
             */
            user?: CzYsP14KcFeedApiDtoSurveyItemObjectUser;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObject
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObject
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObject
             */
            to: string;
            /**
             * 
             * @type {Array<CzYsP14KcFeedApiDtoSurveyItemObjectOption>}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObject
             */
            options: Array<CzYsP14KcFeedApiDtoSurveyItemObjectOption>;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObject
             */
            created: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObject
             */
            likeCount: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObject
             */
            commentCount: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcFeedApiDtoSurveyItemObjectCommunity
         */
        export interface CzYsP14KcFeedApiDtoSurveyItemObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObjectCommunity
             */
            kind: CzYsP14KcFeedApiDtoSurveyItemObjectCommunity.KindEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObjectCommunity
             */
            badge: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObjectCommunity
             */
            membershipType?: CzYsP14KcFeedApiDtoSurveyItemObjectCommunity.MembershipTypeEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcFeedApiDtoSurveyItemObjectCommunity
         */
        export namespace CzYsP14KcFeedApiDtoSurveyItemObjectCommunity {
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum MembershipTypeEnum {
                APPLICANT = <any> 'APPLICANT',
                MEMBER = <any> 'MEMBER',
                DEPUTY = <any> 'DEPUTY',
                OWNER = <any> 'OWNER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcFeedApiDtoSurveyItemObjectOption
         */
        export interface CzYsP14KcFeedApiDtoSurveyItemObjectOption {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObjectOption
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObjectOption
             */
            name: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObjectOption
             */
            voteCount: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcFeedApiDtoSurveyItemObjectUser
         */
        export interface CzYsP14KcFeedApiDtoSurveyItemObjectUser {
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObjectUser
             */
            liked: boolean;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcFeedApiDtoSurveyItemObjectUser
             */
            voted: boolean;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcGdprconsentApiDtoGdprConsentObject
         */
        export interface CzYsP14KcGdprconsentApiDtoGdprConsentObject {
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcGdprconsentApiDtoGdprConsentObject
             */
            notification: boolean;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcHelpdeskApiDtoTicketCreateObject
         */
        export interface CzYsP14KcHelpdeskApiDtoTicketCreateObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketCreateObject
             */
            category: CzYsP14KcHelpdeskApiDtoTicketCreateObject.CategoryEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketCreateObject
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketCreateObject
             */
            description: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketCreateObject
             */
            latitude: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketCreateObject
             */
            longitude: number;
            /**
             * 
             * @type {Array<CzYsP14KcHelpdeskApiDtoTicketCreateObjectImageInfo>}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketCreateObject
             */
            images?: Array<CzYsP14KcHelpdeskApiDtoTicketCreateObjectImageInfo>;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcHelpdeskApiDtoTicketCreateObject
         */
        export namespace CzYsP14KcHelpdeskApiDtoTicketCreateObject {
            /**
             * @export
             * @enum {string}
             */
            export enum CategoryEnum {
                TEST = <any> 'TEST',
                GARBAGE = <any> 'GARBAGE',
                TRAFFIC = <any> 'TRAFFIC',
                PUBLICSPACES = <any> 'PUBLIC_SPACES',
                STREETLIGHTS = <any> 'STREET_LIGHTS',
                SPORTSVENUES = <any> 'SPORTS_VENUES',
                OTHER = <any> 'OTHER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcHelpdeskApiDtoTicketCreateObjectImageInfo
         */
        export interface CzYsP14KcHelpdeskApiDtoTicketCreateObjectImageInfo {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketCreateObjectImageInfo
             */
            name?: string|null;
            /**
             * base64-encoded data URL, must contain the media type
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketCreateObjectImageInfo
             */
            data: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcHelpdeskApiDtoTicketItemObject
         */
        export interface CzYsP14KcHelpdeskApiDtoTicketItemObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketItemObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketItemObject
             */
            category: CzYsP14KcHelpdeskApiDtoTicketItemObject.CategoryEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketItemObject
             */
            name: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketItemObject
             */
            latitude: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketItemObject
             */
            longitude: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketItemObject
             */
            source: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketItemObject
             */
            createdAt: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketItemObject
             */
            resolvedAt?: string|null;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketItemObject
             */
            state: string;
            /**
             * presumed location of the first image, may not actually exist
             * @type {string}
             * @memberof CzYsP14KcHelpdeskApiDtoTicketItemObject
             */
            imageUrl: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcHelpdeskApiDtoTicketItemObject
         */
        export namespace CzYsP14KcHelpdeskApiDtoTicketItemObject {
            /**
             * @export
             * @enum {string}
             */
            export enum CategoryEnum {
                TEST = <any> 'TEST',
                GARBAGE = <any> 'GARBAGE',
                TRAFFIC = <any> 'TRAFFIC',
                PUBLICSPACES = <any> 'PUBLIC_SPACES',
                STREETLIGHTS = <any> 'STREET_LIGHTS',
                SPORTSVENUES = <any> 'SPORTS_VENUES',
                OTHER = <any> 'OTHER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcInvitationDtoInvitationEmails
         */
        export interface CzYsP14KcInvitationDtoInvitationEmails {
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcInvitationDtoInvitationEmails
             */
            value?: Array<string>;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcInvitationDtoInvitationEmailsResultObject
         */
        export interface CzYsP14KcInvitationDtoInvitationEmailsResultObject {
            /**
             * a list of emails to which the invitation has been sent
             * @type {Array<string>}
             * @memberof CzYsP14KcInvitationDtoInvitationEmailsResultObject
             */
            sent: Array<string>;
            /**
             * a list of emails that are already members
             * @type {Array<string>}
             * @memberof CzYsP14KcInvitationDtoInvitationEmailsResultObject
             */
            alreadyMember: Array<string>;
            /**
             * a list of emails whose membership applications have been approved
             * @type {Array<string>}
             * @memberof CzYsP14KcInvitationDtoInvitationEmailsResultObject
             */
            approved: Array<string>;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcMonitoringApiTechnicalMonitoringEndpointTaskMonitoringStatus
         */
        export interface CzYsP14KcMonitoringApiTechnicalMonitoringEndpointTaskMonitoringStatus {
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcMonitoringApiTechnicalMonitoringEndpointTaskMonitoringStatus
             */
            enabled?: boolean;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcNotificationApiDtoNotificationMessageCreationObject
         */
        export interface CzYsP14KcNotificationApiDtoNotificationMessageCreationObject {
            /**
             * The value must be null, when a channel is YOURPASS.
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageCreationObject
             */
            title?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageCreationObject
             */
            body: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageCreationObject
             */
            crisis: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageCreationObject
             */
            channel: CzYsP14KcNotificationApiDtoNotificationMessageCreationObject.ChannelEnum;
            /**
             * The ids of areas of interests. If null, a message is sent to everybody. This value must be null, when a channel is YOURPASS.
             * @type {Array<string>}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageCreationObject
             */
            sendTo?: Array<string>;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcNotificationApiDtoNotificationMessageCreationObject
         */
        export namespace CzYsP14KcNotificationApiDtoNotificationMessageCreationObject {
            /**
             * @export
             * @enum {string}
             */
            export enum ChannelEnum {
                NONE = <any> 'NONE',
                PUSH = <any> 'PUSH',
                INAPP = <any> 'INAPP',
                YOURPASS = <any> 'YOURPASS'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcNotificationApiDtoNotificationMessageObject
         */
        export interface CzYsP14KcNotificationApiDtoNotificationMessageObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageObject
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageObject
             */
            title?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageObject
             */
            body: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageObject
             */
            crisis: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageObject
             */
            channel: CzYsP14KcNotificationApiDtoNotificationMessageObject.ChannelEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcNotificationApiDtoNotificationMessageObject
         */
        export namespace CzYsP14KcNotificationApiDtoNotificationMessageObject {
            /**
             * @export
             * @enum {string}
             */
            export enum ChannelEnum {
                NONE = <any> 'NONE',
                PUSH = <any> 'PUSH',
                INAPP = <any> 'INAPP',
                YOURPASS = <any> 'YOURPASS'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcNotificationApiDtoNotificationMessageRecipientObject
         */
        export interface CzYsP14KcNotificationApiDtoNotificationMessageRecipientObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageRecipientObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageRecipientObject
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationMessageRecipientObject
             */
            type: CzYsP14KcNotificationApiDtoNotificationMessageRecipientObject.TypeEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcNotificationApiDtoNotificationMessageRecipientObject
         */
        export namespace CzYsP14KcNotificationApiDtoNotificationMessageRecipientObject {
            /**
             * @export
             * @enum {string}
             */
            export enum TypeEnum {
                AREAOFINTEREST = <any> 'AREA_OF_INTEREST',
                USER = <any> 'USER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcNotificationApiDtoNotificationObject
         */
        export interface CzYsP14KcNotificationApiDtoNotificationObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObject
             */
            id?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObject
             */
            title?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObject
             */
            url?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObject
             */
            created?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObject
             */
            validTo?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObject
             */
            read?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObject
             */
            entityType?: CzYsP14KcNotificationApiDtoNotificationObject.EntityTypeEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObject
             */
            actionType?: CzYsP14KcNotificationApiDtoNotificationObject.ActionTypeEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObject
             */
            entityId?: string;
            /**
             * 
             * @type {CzYsP14KcNotificationApiDtoNotificationObjectCommunity}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObject
             */
            community?: CzYsP14KcNotificationApiDtoNotificationObjectCommunity;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObject
             */
            groupId?: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcNotificationApiDtoNotificationObject
         */
        export namespace CzYsP14KcNotificationApiDtoNotificationObject {
            /**
             * @export
             * @enum {string}
             */
            export enum EntityTypeEnum {
                POST = <any> 'POST',
                EVENT = <any> 'EVENT',
                SURVEY = <any> 'SURVEY',
                MEMBERSHIP = <any> 'MEMBERSHIP',
                MESSAGE = <any> 'MESSAGE',
                CRISIS = <any> 'CRISIS',
                OPERATIONAL = <any> 'OPERATIONAL'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum ActionTypeEnum {
                CREATE = <any> 'CREATE',
                DELETE = <any> 'DELETE',
                SETDEPUTY = <any> 'SET_DEPUTY',
                REMOVEDEPUTY = <any> 'REMOVE_DEPUTY',
                SETOWNER = <any> 'SET_OWNER',
                APPROVE = <any> 'APPROVE',
                REJECT = <any> 'REJECT',
                USERREQUEST = <any> 'USER_REQUEST',
                CANCELREQUEST = <any> 'CANCEL_REQUEST',
                VOLUNTARYEXIT = <any> 'VOLUNTARY_EXIT',
                PROMO = <any> 'PROMO',
                INFO = <any> 'INFO'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcNotificationApiDtoNotificationObjectCommunity
         */
        export interface CzYsP14KcNotificationApiDtoNotificationObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcNotificationApiDtoNotificationObjectCommunity
             */
            kind: CzYsP14KcNotificationApiDtoNotificationObjectCommunity.KindEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcNotificationApiDtoNotificationObjectCommunity
         */
        export namespace CzYsP14KcNotificationApiDtoNotificationObjectCommunity {
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcOperationalApiDtoOperationalInfoCategoryObject
         */
        export interface CzYsP14KcOperationalApiDtoOperationalInfoCategoryObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoCategoryObject
             */
            category: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoCategoryObject
             */
            all: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoCategoryObject
             */
            actual: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoCategoryObject
             */
            newone: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcOperationalApiDtoOperationalInfoObject
         */
        export interface CzYsP14KcOperationalApiDtoOperationalInfoObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            extSource?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            extUrl?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            start: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            end?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            content?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            address?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            category: CzYsP14KcOperationalApiDtoOperationalInfoObject.CategoryEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            isNew: boolean;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            latitude?: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            longitude?: number;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcOperationalApiDtoOperationalInfoObject
         */
        export namespace CzYsP14KcOperationalApiDtoOperationalInfoObject {
            /**
             * @export
             * @enum {string}
             */
            export enum CategoryEnum {
                ELECTRICITY = <any> 'ELECTRICITY',
                WATER = <any> 'WATER',
                HEATING = <any> 'HEATING',
                CONTAINER = <any> 'CONTAINER',
                TRANSPORT = <any> 'TRANSPORT',
                PUBLICTRANSPORT = <any> 'PUBLIC_TRANSPORT',
                SECURITY = <any> 'SECURITY',
                SUMMERCLEANING = <any> 'SUMMER_CLEANING',
                HEALTH = <any> 'HEALTH'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcPlaceApiDtoPlaceCreationObject
         */
        export interface CzYsP14KcPlaceApiDtoPlaceCreationObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPlaceApiDtoPlaceCreationObject
             */
            name?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPlaceApiDtoPlaceCreationObject
             */
            address?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcPlaceApiDtoPlaceCreationObject
             */
            latitude?: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcPlaceApiDtoPlaceCreationObject
             */
            longitude?: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPlaceApiDtoPlaceCreationObject
             */
            ruianId?: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcPlaceApiDtoPlaceDetailObject
         */
        export interface CzYsP14KcPlaceApiDtoPlaceDetailObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPlaceApiDtoPlaceDetailObject
             */
            name?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPlaceApiDtoPlaceDetailObject
             */
            address?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcPlaceApiDtoPlaceDetailObject
             */
            latitude?: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcPlaceApiDtoPlaceDetailObject
             */
            longitude?: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPlaceApiDtoPlaceDetailObject
             */
            ruianId?: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcPostApiDtoPostCreationObject
         */
        export interface CzYsP14KcPostApiDtoPostCreationObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostCreationObject
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostCreationObject
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostCreationObject
             */
            visibility: CzYsP14KcPostApiDtoPostCreationObject.VisibilityEnum;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcPostApiDtoPostCreationObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {Array<CzYsP14KcPostApiDtoPostCreationObjectImageInfo>}
             * @memberof CzYsP14KcPostApiDtoPostCreationObject
             */
            images?: Array<CzYsP14KcPostApiDtoPostCreationObjectImageInfo>;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcPostApiDtoPostCreationObject
         */
        export namespace CzYsP14KcPostApiDtoPostCreationObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcPostApiDtoPostCreationObjectImageInfo
         */
        export interface CzYsP14KcPostApiDtoPostCreationObjectImageInfo {
            /**
             * base64-encoded data URL, must contain the media type
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostCreationObjectImageInfo
             */
            data: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcPostApiDtoPostObject
         */
        export interface CzYsP14KcPostApiDtoPostObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            id: string;
            /**
             * 
             * @type {CzYsP14KcPostApiDtoPostObjectCommunity}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            community: CzYsP14KcPostApiDtoPostObjectCommunity;
            /**
             * 
             * @type {CzYsP14KcPostApiDtoPostObjectAuthor}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            author?: CzYsP14KcPostApiDtoPostObjectAuthor;
            /**
             * 
             * @type {CzYsP14KcPostApiDtoPostObjectUser}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            user?: CzYsP14KcPostApiDtoPostObjectUser;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            title?: string|null;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            contentOriginal?: string|null;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            visibility: CzYsP14KcPostApiDtoPostObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            source: CzYsP14KcPostApiDtoPostObject.SourceEnum;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            likeCount: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            commentCount: number;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            areasOfInterest: Array<string>;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            imageUrls: Array<string>;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcPostApiDtoPostObject
             */
            imageIds: Array<string>;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcPostApiDtoPostObject
         */
        export namespace CzYsP14KcPostApiDtoPostObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum SourceEnum {
                SELF = <any> 'SELF',
                WORDPRESS = <any> 'WORDPRESS',
                FACEBOOK = <any> 'FACEBOOK',
                INSTAGRAM = <any> 'INSTAGRAM'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcPostApiDtoPostObjectAuthor
         */
        export interface CzYsP14KcPostApiDtoPostObjectAuthor {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObjectAuthor
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObjectAuthor
             */
            displayName: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcPostApiDtoPostObjectCommunity
         */
        export interface CzYsP14KcPostApiDtoPostObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObjectCommunity
             */
            kind: CzYsP14KcPostApiDtoPostObjectCommunity.KindEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostObjectCommunity
             */
            membershipType?: CzYsP14KcPostApiDtoPostObjectCommunity.MembershipTypeEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcPostApiDtoPostObjectCommunity
         */
        export namespace CzYsP14KcPostApiDtoPostObjectCommunity {
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum MembershipTypeEnum {
                APPLICANT = <any> 'APPLICANT',
                MEMBER = <any> 'MEMBER',
                DEPUTY = <any> 'DEPUTY',
                OWNER = <any> 'OWNER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcPostApiDtoPostObjectUser
         */
        export interface CzYsP14KcPostApiDtoPostObjectUser {
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcPostApiDtoPostObjectUser
             */
            editable: boolean;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcPostApiDtoPostObjectUser
             */
            liked: boolean;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcPostApiDtoPostUpdateObject
         */
        export interface CzYsP14KcPostApiDtoPostUpdateObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostUpdateObject
             */
            title?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostUpdateObject
             */
            content?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostUpdateObject
             */
            visibility?: CzYsP14KcPostApiDtoPostUpdateObject.VisibilityEnum;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcPostApiDtoPostUpdateObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {Array<CzYsP14KcPostApiDtoPostUpdateObjectImageInfo>}
             * @memberof CzYsP14KcPostApiDtoPostUpdateObject
             */
            images?: Array<CzYsP14KcPostApiDtoPostUpdateObjectImageInfo>;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcPostApiDtoPostUpdateObject
         */
        export namespace CzYsP14KcPostApiDtoPostUpdateObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcPostApiDtoPostUpdateObjectImageInfo
         */
        export interface CzYsP14KcPostApiDtoPostUpdateObjectImageInfo {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostUpdateObjectImageInfo
             */
            id?: string|null;
            /**
             * base64-encoded data URL, must contain the media type
             * @type {string}
             * @memberof CzYsP14KcPostApiDtoPostUpdateObjectImageInfo
             */
            data?: string|null;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcRuianApiDtoRuianAddressObject
         */
        export interface CzYsP14KcRuianApiDtoRuianAddressObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcRuianApiDtoRuianAddressObject
             */
            streetId?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcRuianApiDtoRuianAddressObject
             */
            streetName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcRuianApiDtoRuianAddressObject
             */
            address?: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcRuianApiDtoRuianAddressObject
             */
            longitude?: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcRuianApiDtoRuianAddressObject
             */
            latitude?: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcRuianApiDtoRuianAddressObject
             */
            ruianId?: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcRuianApiDtoRuianCityPartObject
         */
        export interface CzYsP14KcRuianApiDtoRuianCityPartObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcRuianApiDtoRuianCityPartObject
             */
            code: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcRuianApiDtoRuianCityPartObject
             */
            name: string;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcSurveyApiDtoSurveyCreationObject
         */
        export interface CzYsP14KcSurveyApiDtoSurveyCreationObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyCreationObject
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyCreationObject
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyCreationObject
             */
            visibility: CzYsP14KcSurveyApiDtoSurveyCreationObject.VisibilityEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyCreationObject
             */
            to: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyCreationObject
             */
            postId?: string;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcSurveyApiDtoSurveyCreationObject
             */
            options: Array<string>;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcSurveyApiDtoSurveyCreationObject
             */
            areasOfInterest: Array<string>;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcSurveyApiDtoSurveyCreationObject
         */
        export namespace CzYsP14KcSurveyApiDtoSurveyCreationObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcSurveyApiDtoSurveyObject
         */
        export interface CzYsP14KcSurveyApiDtoSurveyObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObject
             */
            to: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObject
             */
            title: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObject
             */
            content: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObject
             */
            created: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObject
             */
            visibility: CzYsP14KcSurveyApiDtoSurveyObject.VisibilityEnum;
            /**
             * 
             * @type {Array<CzYsP14KcSurveyApiDtoSurveyOptionObject>}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObject
             */
            options: Array<CzYsP14KcSurveyApiDtoSurveyOptionObject>;
            /**
             * 
             * @type {CzYsP14KcSurveyApiDtoSurveyObjectCommunity}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObject
             */
            community: CzYsP14KcSurveyApiDtoSurveyObjectCommunity;
            /**
             * 
             * @type {CzYsP14KcSurveyApiDtoSurveyObjectUser}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObject
             */
            user?: CzYsP14KcSurveyApiDtoSurveyObjectUser;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObject
             */
            likeCount?: number;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObject
             */
            commentCount?: number;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObject
             */
            areasOfInterest?: Array<string>;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcSurveyApiDtoSurveyObject
         */
        export namespace CzYsP14KcSurveyApiDtoSurveyObject {
            /**
             * @export
             * @enum {string}
             */
            export enum VisibilityEnum {
                PUBLIC = <any> 'PUBLIC',
                PRIVATE = <any> 'PRIVATE'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcSurveyApiDtoSurveyObjectCommunity
         */
        export interface CzYsP14KcSurveyApiDtoSurveyObjectCommunity {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObjectCommunity
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObjectCommunity
             */
            name: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObjectCommunity
             */
            kind: CzYsP14KcSurveyApiDtoSurveyObjectCommunity.KindEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObjectCommunity
             */
            membershipType?: CzYsP14KcSurveyApiDtoSurveyObjectCommunity.MembershipTypeEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcSurveyApiDtoSurveyObjectCommunity
         */
        export namespace CzYsP14KcSurveyApiDtoSurveyObjectCommunity {
            /**
             * @export
             * @enum {string}
             */
            export enum KindEnum {
                CITYHALL = <any> 'CITY_HALL',
                GROUP = <any> 'GROUP',
                PROJECT = <any> 'PROJECT'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum MembershipTypeEnum {
                APPLICANT = <any> 'APPLICANT',
                MEMBER = <any> 'MEMBER',
                DEPUTY = <any> 'DEPUTY',
                OWNER = <any> 'OWNER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcSurveyApiDtoSurveyObjectUser
         */
        export interface CzYsP14KcSurveyApiDtoSurveyObjectUser {
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObjectUser
             */
            alreadyVoted: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObjectUser
             */
            userVotedOption?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObjectUser
             */
            voteDate?: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcSurveyApiDtoSurveyObjectUser
             */
            liked: boolean;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcSurveyApiDtoSurveyOptionObject
         */
        export interface CzYsP14KcSurveyApiDtoSurveyOptionObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyOptionObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcSurveyApiDtoSurveyOptionObject
             */
            name: string;
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcSurveyApiDtoSurveyOptionObject
             */
            voteCount: number;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject
         */
        export interface CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject
             */
            id?: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject
             */
            response?: boolean;
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcUserApiDtoUserDetailObject
         */
        export interface CzYsP14KcUserApiDtoUserDetailObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            firstName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            lastName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            birthday?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            gender?: CzYsP14KcUserApiDtoUserDetailObject.GenderEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            citizenP14: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            education?: CzYsP14KcUserApiDtoUserDetailObject.EducationEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            job?: CzYsP14KcUserApiDtoUserDetailObject.JobEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            address?: string;
            /**
             * foreign key to ruian city part, list of city parts could be read from EP /city-part [code]
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            ruianCityPartCode?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            ruianCityPartName?: string;
            /**
             * foreign key to ruian street, list of streets could be read from EP /street [streetId]
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            ruianStreetCode?: string;
            /**
             * foreign key to ruian place, list of places could be read from EP /street [ruianId]
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            ruianPlaceId?: string;
            /**
             * foreign key to city block, list of city blocks could be read from EP /city-block [id]
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            cityBlockId?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserDetailObject
             */
            cityBlockName?: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcUserApiDtoUserDetailObject
         */
        export namespace CzYsP14KcUserApiDtoUserDetailObject {
            /**
             * @export
             * @enum {string}
             */
            export enum GenderEnum {
                MALE = <any> 'MALE',
                FEMALE = <any> 'FEMALE',
                OTHER = <any> 'OTHER'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum EducationEnum {
                A = <any> 'A',
                B = <any> 'B',
                C = <any> 'C',
                D = <any> 'D',
                E = <any> 'E',
                H = <any> 'H',
                J = <any> 'J',
                K = <any> 'K',
                L = <any> 'L',
                M = <any> 'M',
                N = <any> 'N',
                P = <any> 'P',
                R = <any> 'R',
                T = <any> 'T',
                V = <any> 'V'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum JobEnum {
                STUDENT = <any> 'STUDENT',
                EMPLOYEE = <any> 'EMPLOYEE',
                BUSINESSMAN = <any> 'BUSINESSMAN',
                PENSIONER = <any> 'PENSIONER',
                UNEMPLOYED = <any> 'UNEMPLOYED',
                OTHER = <any> 'OTHER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcUserApiDtoUserNotificationObject
         */
        export interface CzYsP14KcUserApiDtoUserNotificationObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserNotificationObject
             */
            event?: CzYsP14KcUserApiDtoUserNotificationObject.EventEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserNotificationObject
             */
            feed?: CzYsP14KcUserApiDtoUserNotificationObject.FeedEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserNotificationObject
             */
            office?: CzYsP14KcUserApiDtoUserNotificationObject.OfficeEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserNotificationObject
             */
            operational?: CzYsP14KcUserApiDtoUserNotificationObject.OperationalEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserNotificationObject
             */
            membership?: CzYsP14KcUserApiDtoUserNotificationObject.MembershipEnum;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcUserApiDtoUserNotificationObject
         */
        export namespace CzYsP14KcUserApiDtoUserNotificationObject {
            /**
             * @export
             * @enum {string}
             */
            export enum EventEnum {
                NONE = <any> 'NONE',
                PUSH = <any> 'PUSH',
                INAPP = <any> 'INAPP',
                YOURPASS = <any> 'YOURPASS'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum FeedEnum {
                NONE = <any> 'NONE',
                PUSH = <any> 'PUSH',
                INAPP = <any> 'INAPP',
                YOURPASS = <any> 'YOURPASS'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum OfficeEnum {
                NONE = <any> 'NONE',
                PUSH = <any> 'PUSH',
                INAPP = <any> 'INAPP',
                YOURPASS = <any> 'YOURPASS'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum OperationalEnum {
                NONE = <any> 'NONE',
                PUSH = <any> 'PUSH',
                INAPP = <any> 'INAPP',
                YOURPASS = <any> 'YOURPASS'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum MembershipEnum {
                NONE = <any> 'NONE',
                PUSH = <any> 'PUSH',
                INAPP = <any> 'INAPP',
                YOURPASS = <any> 'YOURPASS'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcUserApiDtoUserObject
         */
        export interface CzYsP14KcUserApiDtoUserObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserObject
             */
            id: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserObject
             */
            displayName: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserObject
             */
            phone?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserObject
             */
            email?: string;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcUserApiDtoUserObject
             */
            verified: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserObject
             */
            role: CzYsP14KcUserApiDtoUserObject.RoleEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserObject
             */
            rank: CzYsP14KcUserApiDtoUserObject.RankEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserObject
             */
            locale: CzYsP14KcUserApiDtoUserObject.LocaleEnum;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcUserApiDtoUserObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {CzYsP14KcGdprconsentApiDtoGdprConsentObject}
             * @memberof CzYsP14KcUserApiDtoUserObject
             */
            gdpr?: CzYsP14KcGdprconsentApiDtoGdprConsentObject;
            /**
             * 
             * @type {CzYsP14KcUserApiDtoUserDetailObject}
             * @memberof CzYsP14KcUserApiDtoUserObject
             */
            detail?: CzYsP14KcUserApiDtoUserDetailObject;
            /**
             * 
             * @type {CzYsP14KcUserApiDtoUserNotificationObject}
             * @memberof CzYsP14KcUserApiDtoUserObject
             */
            notification?: CzYsP14KcUserApiDtoUserNotificationObject;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcUserApiDtoUserObject
         */
        export namespace CzYsP14KcUserApiDtoUserObject {
            /**
             * @export
             * @enum {string}
             */
            export enum RoleEnum {
                USER = <any> 'USER',
                BACKOFFICEUSER = <any> 'BACKOFFICE_USER',
                BASICADMIN = <any> 'BASIC_ADMIN',
                SUPERADMIN = <any> 'SUPER_ADMIN'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum RankEnum {
                STANDARD = <any> 'STANDARD',
                ACTIVE = <any> 'ACTIVE',
                AMBASSADOR = <any> 'AMBASSADOR'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum LocaleEnum {
                CsCZ = <any> 'cs_CZ',
                EnUS = <any> 'en_US',
                UkUA = <any> 'uk_UA',
                RuRU = <any> 'ru_RU'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcUserApiDtoUserRegistrationObject
         */
        export interface CzYsP14KcUserApiDtoUserRegistrationObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObject
             */
            displayName: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObject
             */
            phone?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObject
             */
            email: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObject
             */
            avatarUrl?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObject
             */
            locale?: CzYsP14KcUserApiDtoUserRegistrationObject.LocaleEnum;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {CzYsP14KcGdprconsentApiDtoGdprConsentObject}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObject
             */
            gdpr?: CzYsP14KcGdprconsentApiDtoGdprConsentObject;
            /**
             * 
             * @type {CzYsP14KcUserApiDtoUserNotificationObject}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObject
             */
            notification?: CzYsP14KcUserApiDtoUserNotificationObject;
            /**
             * 
             * @type {CzYsP14KcUserApiDtoUserRegistrationObjectDetail}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObject
             */
            detail?: CzYsP14KcUserApiDtoUserRegistrationObjectDetail;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcUserApiDtoUserRegistrationObject
         */
        export namespace CzYsP14KcUserApiDtoUserRegistrationObject {
            /**
             * @export
             * @enum {string}
             */
            export enum LocaleEnum {
                CsCZ = <any> 'cs_CZ',
                EnUS = <any> 'en_US',
                UkUA = <any> 'uk_UA',
                RuRU = <any> 'ru_RU'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcUserApiDtoUserRegistrationObjectDetail
         */
        export interface CzYsP14KcUserApiDtoUserRegistrationObjectDetail {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObjectDetail
             */
            firstName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObjectDetail
             */
            lastName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObjectDetail
             */
            birthday?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObjectDetail
             */
            gender?: CzYsP14KcUserApiDtoUserRegistrationObjectDetail.GenderEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObjectDetail
             */
            citizenP14?: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObjectDetail
             */
            education?: CzYsP14KcUserApiDtoUserRegistrationObjectDetail.EducationEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObjectDetail
             */
            job?: CzYsP14KcUserApiDtoUserRegistrationObjectDetail.JobEnum;
            /**
             * foreign key to ruian city part, list of city parts could be read from EP /city-part [code]
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObjectDetail
             */
            ruianCityPartCode?: string;
            /**
             * foreign key to ruian street, list of streets could be read from EP /street [streetId]
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObjectDetail
             */
            ruianStreetCode?: string;
            /**
             * foreign key to ruian place, list of places could be read from EP /street [ruianId]
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObjectDetail
             */
            ruianPlaceId?: string;
            /**
             * foreign key to city block, list of city blocks could be read from EP /city-block [id]
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserRegistrationObjectDetail
             */
            cityBlockId?: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcUserApiDtoUserRegistrationObjectDetail
         */
        export namespace CzYsP14KcUserApiDtoUserRegistrationObjectDetail {
            /**
             * @export
             * @enum {string}
             */
            export enum GenderEnum {
                MALE = <any> 'MALE',
                FEMALE = <any> 'FEMALE',
                OTHER = <any> 'OTHER'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum EducationEnum {
                A = <any> 'A',
                B = <any> 'B',
                C = <any> 'C',
                D = <any> 'D',
                E = <any> 'E',
                H = <any> 'H',
                J = <any> 'J',
                K = <any> 'K',
                L = <any> 'L',
                M = <any> 'M',
                N = <any> 'N',
                P = <any> 'P',
                R = <any> 'R',
                T = <any> 'T',
                V = <any> 'V'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum JobEnum {
                STUDENT = <any> 'STUDENT',
                EMPLOYEE = <any> 'EMPLOYEE',
                BUSINESSMAN = <any> 'BUSINESSMAN',
                PENSIONER = <any> 'PENSIONER',
                UNEMPLOYED = <any> 'UNEMPLOYED',
                OTHER = <any> 'OTHER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcUserApiDtoUserUpdateObject
         */
        export interface CzYsP14KcUserApiDtoUserUpdateObject {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObject
             */
            displayName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObject
             */
            phone?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObject
             */
            email?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObject
             */
            locale?: CzYsP14KcUserApiDtoUserUpdateObject.LocaleEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObject
             */
            avatarUrl?: string;
            /**
             * 
             * @type {Array<string>}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObject
             */
            areasOfInterest?: Array<string>;
            /**
             * 
             * @type {CzYsP14KcGdprconsentApiDtoGdprConsentObject}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObject
             */
            gdpr?: CzYsP14KcGdprconsentApiDtoGdprConsentObject;
            /**
             * 
             * @type {CzYsP14KcUserApiDtoUserNotificationObject}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObject
             */
            notification?: CzYsP14KcUserApiDtoUserNotificationObject;
            /**
             * 
             * @type {CzYsP14KcUserApiDtoUserUpdateObjectDetail}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObject
             */
            detail?: CzYsP14KcUserApiDtoUserUpdateObjectDetail;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcUserApiDtoUserUpdateObject
         */
        export namespace CzYsP14KcUserApiDtoUserUpdateObject {
            /**
             * @export
             * @enum {string}
             */
            export enum LocaleEnum {
                CsCZ = <any> 'cs_CZ',
                EnUS = <any> 'en_US',
                UkUA = <any> 'uk_UA',
                RuRU = <any> 'ru_RU'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcUserApiDtoUserUpdateObjectDetail
         */
        export interface CzYsP14KcUserApiDtoUserUpdateObjectDetail {
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObjectDetail
             */
            firstName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObjectDetail
             */
            lastName?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObjectDetail
             */
            birthday?: string;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObjectDetail
             */
            gender?: CzYsP14KcUserApiDtoUserUpdateObjectDetail.GenderEnum;
            /**
             * 
             * @type {boolean}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObjectDetail
             */
            citizenP14?: boolean;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObjectDetail
             */
            education?: CzYsP14KcUserApiDtoUserUpdateObjectDetail.EducationEnum;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObjectDetail
             */
            job?: CzYsP14KcUserApiDtoUserUpdateObjectDetail.JobEnum;
            /**
             * foreign key to ruian city part, list of city parts could be read from EP /city-part [code]
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObjectDetail
             */
            ruianCityPartCode?: string;
            /**
             * foreign key to ruian street, list of streets could be read from EP /street [streetId]
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObjectDetail
             */
            ruianStreetCode?: string;
            /**
             * foreign key to ruian place, list of places could be read from EP /street [ruianId]
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObjectDetail
             */
            ruianPlaceId?: string;
            /**
             * foreign key to city block, list of city blocks could be read from EP /city-block [id]
             * @type {string}
             * @memberof CzYsP14KcUserApiDtoUserUpdateObjectDetail
             */
            cityBlockId?: string;
        }
        
        /**
         * @export
         * @namespace CzYsP14KcUserApiDtoUserUpdateObjectDetail
         */
        export namespace CzYsP14KcUserApiDtoUserUpdateObjectDetail {
            /**
             * @export
             * @enum {string}
             */
            export enum GenderEnum {
                MALE = <any> 'MALE',
                FEMALE = <any> 'FEMALE',
                OTHER = <any> 'OTHER'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum EducationEnum {
                A = <any> 'A',
                B = <any> 'B',
                C = <any> 'C',
                D = <any> 'D',
                E = <any> 'E',
                H = <any> 'H',
                J = <any> 'J',
                K = <any> 'K',
                L = <any> 'L',
                M = <any> 'M',
                N = <any> 'N',
                P = <any> 'P',
                R = <any> 'R',
                T = <any> 'T',
                V = <any> 'V'
            }
            /**
             * @export
             * @enum {string}
             */
            export enum JobEnum {
                STUDENT = <any> 'STUDENT',
                EMPLOYEE = <any> 'EMPLOYEE',
                BUSINESSMAN = <any> 'BUSINESSMAN',
                PENSIONER = <any> 'PENSIONER',
                UNEMPLOYED = <any> 'UNEMPLOYED',
                OTHER = <any> 'OTHER'
            }
        }
        /**
         * 
         * @export
         * @interface CzYsP14KcWordpressApiDtoWordpressAuthorObject
         */
        export interface CzYsP14KcWordpressApiDtoWordpressAuthorObject {
            /**
             * 
             * @type {number}
             * @memberof CzYsP14KcWordpressApiDtoWordpressAuthorObject
             */
            id: number;
            /**
             * 
             * @type {string}
             * @memberof CzYsP14KcWordpressApiDtoWordpressAuthorObject
             */
            name: string;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeCommentObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            content?: Array<CzYsP14KcBackofficeApiDtoBackOfficeCommentObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            content?: Array<CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeEventObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            content?: Array<CzYsP14KcBackofficeApiDtoBackOfficeEventObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            content?: Array<CzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficePostObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            content?: Array<CzYsP14KcBackofficeApiDtoBackOfficePostObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeStreetObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            content?: Array<CzYsP14KcBackofficeApiDtoBackOfficeStreetObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            content?: Array<CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcBackofficeApiDtoBackOfficeUserObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            content?: Array<CzYsP14KcBackofficeApiDtoBackOfficeUserObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcCityblockApiDtoCityBlockObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject
             */
            content?: Array<CzYsP14KcCityblockApiDtoCityBlockObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcCommentApiDtoCommentObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject
             */
            content?: Array<CzYsP14KcCommentApiDtoCommentObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcCommunityApiDtoCommunityListDetailObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            content?: Array<CzYsP14KcCommunityApiDtoCommunityListDetailObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcEventApiDtoEventInstanceObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject
             */
            content?: Array<CzYsP14KcEventApiDtoEventInstanceObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcFeedApiDtoFeedWrapperObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
             */
            content?: Array<CzYsP14KcFeedApiDtoFeedWrapperObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcFeedApiDtoPostItemObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject
             */
            content?: Array<CzYsP14KcFeedApiDtoPostItemObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcNotificationApiDtoNotificationObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject
             */
            content?: Array<CzYsP14KcNotificationApiDtoNotificationObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject
         */
        export interface OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            totalElements?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            totalPages?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            first?: boolean;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainPageable}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            pageable?: OrgSpringframeworkDataDomainPageable;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            numberOfElements?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            last?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            size?: number;
            /**
             * 
             * @type {Array<CzYsP14KcOperationalApiDtoOperationalInfoObject>}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            content?: Array<CzYsP14KcOperationalApiDtoOperationalInfoObject>;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            number?: number;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject
             */
            empty?: boolean;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainPageable
         */
        export interface OrgSpringframeworkDataDomainPageable {
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageable
             */
            pageNumber?: number;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageable
             */
            pageSize?: number;
            /**
             * 
             * @type {OrgSpringframeworkDataDomainSort}
             * @memberof OrgSpringframeworkDataDomainPageable
             */
            sort?: OrgSpringframeworkDataDomainSort;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageable
             */
            unpaged?: boolean;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainPageable
             */
            paged?: boolean;
            /**
             * 
             * @type {number}
             * @memberof OrgSpringframeworkDataDomainPageable
             */
            offset?: number;
        }
        /**
         * 
         * @export
         * @interface OrgSpringframeworkDataDomainSort
         */
        export interface OrgSpringframeworkDataDomainSort {
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainSort
             */
            sorted?: boolean;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainSort
             */
            unsorted?: boolean;
            /**
             * 
             * @type {boolean}
             * @memberof OrgSpringframeworkDataDomainSort
             */
            empty?: boolean;
        }
    /**
    * ActuatorApi - fetch parameter creator
    * @export
    */
    export const ActuatorApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      monitoredTasks(options: any = {}): FetchArgs {
      const localVarPath = `/actuator/monitored-task`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @param {CzYsP14KcMonitoringApiTechnicalMonitoringEndpointTaskMonitoringStatus} body 
        * @param {string} code 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      setStatus(body: CzYsP14KcMonitoringApiTechnicalMonitoringEndpointTaskMonitoringStatus, code: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling setStatus.');
          }
          // verify required parameter 'code' is not null or undefined
          if (code === null || code === undefined) {
          throw new RequiredError('code','Required parameter code was null or undefined when calling setStatus.');
          }
      const localVarPath = `/actuator/monitored-task/{code}`
      .replace(`{${"code"}}`, encodeURIComponent(String(code)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcMonitoringApiTechnicalMonitoringEndpointTaskMonitoringStatus" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * ActuatorApi - functional programming interface
    * @export
    */
    export const ActuatorApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      monitoredTasks(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
      const localVarFetchArgs = ActuatorApiFetchParamCreator(configuration).monitoredTasks(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @param {CzYsP14KcMonitoringApiTechnicalMonitoringEndpointTaskMonitoringStatus} body 
        * @param {string} code 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      setStatus(body: CzYsP14KcMonitoringApiTechnicalMonitoringEndpointTaskMonitoringStatus, code: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = ActuatorApiFetchParamCreator(configuration).setStatus(body, code, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * ActuatorApi - factory interface
    * @export
    */
    export const ActuatorApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      monitoredTasks(options?: any) {
      return ActuatorApiFp(configuration).monitoredTasks(options)(fetch, basePath);
      },
      /**
      * 
        * @param {CzYsP14KcMonitoringApiTechnicalMonitoringEndpointTaskMonitoringStatus} body 
        * @param {string} code 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      setStatus(body: CzYsP14KcMonitoringApiTechnicalMonitoringEndpointTaskMonitoringStatus, code: string, options?: any) {
      return ActuatorApiFp(configuration).setStatus(body, code, options)(fetch, basePath);
      },
    };
    };

    /**
    * ActuatorApi - object-oriented interface
    * @export
    * @class ActuatorApi
    * @extends {BaseAPI}
    */
      export class ActuatorApi extends BaseAPI {
      /**
      * 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof ActuatorApi
      */
      public monitoredTasks(options?: any) {
      return ActuatorApiFp(this.configuration).monitoredTasks(options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @param {CzYsP14KcMonitoringApiTechnicalMonitoringEndpointTaskMonitoringStatus} body 
        * @param {string} code 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof ActuatorApi
      */
      public setStatus(body: CzYsP14KcMonitoringApiTechnicalMonitoringEndpointTaskMonitoringStatus, code: string, options?: any) {
      return ActuatorApiFp(this.configuration).setStatus(body, code, options)(this.fetch, this.basePath);
      }

    }
    /**
    * AreasOfInterestApi - fetch parameter creator
    * @export
    */
    export const AreasOfInterestApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns list of all areas of interest
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getAreaOfInterests(options: any = {}): FetchArgs {
      const localVarPath = `/area-of-interest`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * AreasOfInterestApi - functional programming interface
    * @export
    */
    export const AreasOfInterestApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns list of all areas of interest
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getAreaOfInterests(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcAreaofinterestApiDtoAreaOfInterestObject>> {
      const localVarFetchArgs = AreasOfInterestApiFetchParamCreator(configuration).getAreaOfInterests(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * AreasOfInterestApi - factory interface
    * @export
    */
    export const AreasOfInterestApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns list of all areas of interest
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getAreaOfInterests(options?: any) {
      return AreasOfInterestApiFp(configuration).getAreaOfInterests(options)(fetch, basePath);
      },
    };
    };

    /**
    * AreasOfInterestApi - object-oriented interface
    * @export
    * @class AreasOfInterestApi
    * @extends {BaseAPI}
    */
      export class AreasOfInterestApi extends BaseAPI {
      /**
      * 
        * @summary Returns list of all areas of interest
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof AreasOfInterestApi
      */
      public getAreaOfInterests(options?: any) {
      return AreasOfInterestApiFp(this.configuration).getAreaOfInterests(options)(this.fetch, this.basePath);
      }

    }
    /**
    * ArtificialIntelligenceApi - fetch parameter creator
    * @export
    */
    export const ArtificialIntelligenceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Area of interests detection from text
        * @param {string} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      areas(body: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling areas.');
          }
      const localVarPath = `/ai/area-of-interest`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * ArtificialIntelligenceApi - functional programming interface
    * @export
    */
    export const ArtificialIntelligenceApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Area of interests detection from text
        * @param {string} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      areas(body: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
      const localVarFetchArgs = ArtificialIntelligenceApiFetchParamCreator(configuration).areas(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * ArtificialIntelligenceApi - factory interface
    * @export
    */
    export const ArtificialIntelligenceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Area of interests detection from text
        * @param {string} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      areas(body: string, options?: any) {
      return ArtificialIntelligenceApiFp(configuration).areas(body, options)(fetch, basePath);
      },
    };
    };

    /**
    * ArtificialIntelligenceApi - object-oriented interface
    * @export
    * @class ArtificialIntelligenceApi
    * @extends {BaseAPI}
    */
      export class ArtificialIntelligenceApi extends BaseAPI {
      /**
      * 
        * @summary Area of interests detection from text
        * @param {string} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof ArtificialIntelligenceApi
      */
      public areas(body: string, options?: any) {
      return ArtificialIntelligenceApiFp(this.configuration).areas(body, options)(this.fetch, this.basePath);
      }

    }
    /**
    * BackOfficeForBusinessIntelligenceApi - fetch parameter creator
    * @export
    */
    export const BackOfficeForBusinessIntelligenceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Retrieve a report embed token
        * @param {string} reportId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getReportEmbedToken(reportId: string, options: any = {}): FetchArgs {
          // verify required parameter 'reportId' is not null or undefined
          if (reportId === null || reportId === undefined) {
          throw new RequiredError('reportId','Required parameter reportId was null or undefined when calling getReportEmbedToken.');
          }
      const localVarPath = `/backoffice/business-intelligence/report-token`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (reportId !== undefined) {
              localVarQueryParameter['reportId'] = reportId;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Retrieve a report embed token
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getReportTrends(options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/business-intelligence/trends`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * BackOfficeForBusinessIntelligenceApi - functional programming interface
    * @export
    */
    export const BackOfficeForBusinessIntelligenceApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Retrieve a report embed token
        * @param {string} reportId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getReportEmbedToken(reportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: any; }> {
      const localVarFetchArgs = BackOfficeForBusinessIntelligenceApiFetchParamCreator(configuration).getReportEmbedToken(reportId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Retrieve a report embed token
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getReportTrends(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcBiApiDtoReportObject> {
      const localVarFetchArgs = BackOfficeForBusinessIntelligenceApiFetchParamCreator(configuration).getReportTrends(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * BackOfficeForBusinessIntelligenceApi - factory interface
    * @export
    */
    export const BackOfficeForBusinessIntelligenceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Retrieve a report embed token
        * @param {string} reportId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getReportEmbedToken(reportId: string, options?: any) {
      return BackOfficeForBusinessIntelligenceApiFp(configuration).getReportEmbedToken(reportId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Retrieve a report embed token
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getReportTrends(options?: any) {
      return BackOfficeForBusinessIntelligenceApiFp(configuration).getReportTrends(options)(fetch, basePath);
      },
    };
    };

    /**
    * BackOfficeForBusinessIntelligenceApi - object-oriented interface
    * @export
    * @class BackOfficeForBusinessIntelligenceApi
    * @extends {BaseAPI}
    */
      export class BackOfficeForBusinessIntelligenceApi extends BaseAPI {
      /**
      * 
        * @summary Retrieve a report embed token
        * @param {string} reportId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForBusinessIntelligenceApi
      */
      public getReportEmbedToken(reportId: string, options?: any) {
      return BackOfficeForBusinessIntelligenceApiFp(this.configuration).getReportEmbedToken(reportId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Retrieve a report embed token
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForBusinessIntelligenceApi
      */
      public getReportTrends(options?: any) {
      return BackOfficeForBusinessIntelligenceApiFp(this.configuration).getReportTrends(options)(this.fetch, this.basePath);
      }

    }
    /**
    * BackOfficeForChatbotApi - fetch parameter creator
    * @export
    */
    export const BackOfficeForChatbotApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns chatbot telemetry
        * @param {string} [from] 
        * @param {string} [to] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getChatbotTelemetry(from?: string, to?: string, options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/chatbot`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (from !== undefined) {
              localVarQueryParameter['from'] = (from as any instanceof Date) ? (from as any).toISOString().substr(0,10) : from;
          }

          if (to !== undefined) {
              localVarQueryParameter['to'] = (to as any instanceof Date) ? (to as any).toISOString().substr(0,10) : to;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Updates chatbot telemetry status
        * @param {CzYsP14KcChatbotChatbotTelemetryUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      updateChatbotTelemetry(body: CzYsP14KcChatbotChatbotTelemetryUpdateObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling updateChatbotTelemetry.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling updateChatbotTelemetry.');
          }
      const localVarPath = `/backoffice/chatbot/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcChatbotChatbotTelemetryUpdateObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * BackOfficeForChatbotApi - functional programming interface
    * @export
    */
    export const BackOfficeForChatbotApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns chatbot telemetry
        * @param {string} [from] 
        * @param {string} [to] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getChatbotTelemetry(from?: string, to?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcChatbotChatbotTelemetryObject>> {
      const localVarFetchArgs = BackOfficeForChatbotApiFetchParamCreator(configuration).getChatbotTelemetry(from, to, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Updates chatbot telemetry status
        * @param {CzYsP14KcChatbotChatbotTelemetryUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateChatbotTelemetry(body: CzYsP14KcChatbotChatbotTelemetryUpdateObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForChatbotApiFetchParamCreator(configuration).updateChatbotTelemetry(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * BackOfficeForChatbotApi - factory interface
    * @export
    */
    export const BackOfficeForChatbotApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns chatbot telemetry
        * @param {string} [from] 
        * @param {string} [to] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getChatbotTelemetry(from?: string, to?: string, options?: any) {
      return BackOfficeForChatbotApiFp(configuration).getChatbotTelemetry(from, to, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Updates chatbot telemetry status
        * @param {CzYsP14KcChatbotChatbotTelemetryUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateChatbotTelemetry(body: CzYsP14KcChatbotChatbotTelemetryUpdateObject, id: string, options?: any) {
      return BackOfficeForChatbotApiFp(configuration).updateChatbotTelemetry(body, id, options)(fetch, basePath);
      },
    };
    };

    /**
    * BackOfficeForChatbotApi - object-oriented interface
    * @export
    * @class BackOfficeForChatbotApi
    * @extends {BaseAPI}
    */
      export class BackOfficeForChatbotApi extends BaseAPI {
      /**
      * 
        * @summary Returns chatbot telemetry
        * @param {string} [from] 
        * @param {string} [to] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForChatbotApi
      */
      public getChatbotTelemetry(from?: string, to?: string, options?: any) {
      return BackOfficeForChatbotApiFp(this.configuration).getChatbotTelemetry(from, to, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Updates chatbot telemetry status
        * @param {CzYsP14KcChatbotChatbotTelemetryUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForChatbotApi
      */
      public updateChatbotTelemetry(body: CzYsP14KcChatbotChatbotTelemetryUpdateObject, id: string, options?: any) {
      return BackOfficeForChatbotApiFp(this.configuration).updateChatbotTelemetry(body, id, options)(this.fetch, this.basePath);
      }

    }
    /**
    * BackOfficeForCityBlocksApi - fetch parameter creator
    * @export
    */
    export const BackOfficeForCityBlocksApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Creates city block
        * @param {CzYsP14KcCityblockApiDtoCityBlockObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      backofficeCreateCityBlock(body: CzYsP14KcCityblockApiDtoCityBlockObject, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling backofficeCreateCityBlock.');
          }
      const localVarPath = `/backoffice/city-block`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcCityblockApiDtoCityBlockObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Deletes city block
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      backofficeDeleteCityBlock(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling backofficeDeleteCityBlock.');
          }
      const localVarPath = `/backoffice/city-block/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns city block details
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      backofficeGetCityBlockDetail(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling backofficeGetCityBlockDetail.');
          }
      const localVarPath = `/backoffice/city-block/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns list of city block streets
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      backofficeGetCityBlockStreets(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling backofficeGetCityBlockStreets.');
          }
      const localVarPath = `/backoffice/city-block/{id}/streets`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns paged list of city blocks
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      backofficeListCityBlocks(page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/city-block`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Updates city block
        * @param {CzYsP14KcCityblockApiDtoCityBlockObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      backofficeUpdateCityBlock(body: CzYsP14KcCityblockApiDtoCityBlockObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling backofficeUpdateCityBlock.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling backofficeUpdateCityBlock.');
          }
      const localVarPath = `/backoffice/city-block/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcCityblockApiDtoCityBlockObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Modify list of streets for city block
        * @param {Array<string>} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      backofficeUpdateCityBlockStreets(body: Array<string>, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling backofficeUpdateCityBlockStreets.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling backofficeUpdateCityBlockStreets.');
          }
      const localVarPath = `/backoffice/city-block/{id}/streets`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * BackOfficeForCityBlocksApi - functional programming interface
    * @export
    */
    export const BackOfficeForCityBlocksApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Creates city block
        * @param {CzYsP14KcCityblockApiDtoCityBlockObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeCreateCityBlock(body: CzYsP14KcCityblockApiDtoCityBlockObject, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcCityblockApiDtoCityBlockObject> {
      const localVarFetchArgs = BackOfficeForCityBlocksApiFetchParamCreator(configuration).backofficeCreateCityBlock(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Deletes city block
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeDeleteCityBlock(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForCityBlocksApiFetchParamCreator(configuration).backofficeDeleteCityBlock(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns city block details
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeGetCityBlockDetail(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcCityblockApiDtoCityBlockDetailObject> {
      const localVarFetchArgs = BackOfficeForCityBlocksApiFetchParamCreator(configuration).backofficeGetCityBlockDetail(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns list of city block streets
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeGetCityBlockStreets(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcCityblockApiDtoCityBlockStreetObject>> {
      const localVarFetchArgs = BackOfficeForCityBlocksApiFetchParamCreator(configuration).backofficeGetCityBlockStreets(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns paged list of city blocks
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeListCityBlocks(page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcCityblockApiDtoCityBlockObject> {
      const localVarFetchArgs = BackOfficeForCityBlocksApiFetchParamCreator(configuration).backofficeListCityBlocks(page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Updates city block
        * @param {CzYsP14KcCityblockApiDtoCityBlockObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeUpdateCityBlock(body: CzYsP14KcCityblockApiDtoCityBlockObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcCityblockApiDtoCityBlockObject> {
      const localVarFetchArgs = BackOfficeForCityBlocksApiFetchParamCreator(configuration).backofficeUpdateCityBlock(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Modify list of streets for city block
        * @param {Array<string>} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeUpdateCityBlockStreets(body: Array<string>, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcCityblockApiDtoCityBlockStreetObject>> {
      const localVarFetchArgs = BackOfficeForCityBlocksApiFetchParamCreator(configuration).backofficeUpdateCityBlockStreets(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * BackOfficeForCityBlocksApi - factory interface
    * @export
    */
    export const BackOfficeForCityBlocksApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Creates city block
        * @param {CzYsP14KcCityblockApiDtoCityBlockObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeCreateCityBlock(body: CzYsP14KcCityblockApiDtoCityBlockObject, options?: any) {
      return BackOfficeForCityBlocksApiFp(configuration).backofficeCreateCityBlock(body, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Deletes city block
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeDeleteCityBlock(id: string, options?: any) {
      return BackOfficeForCityBlocksApiFp(configuration).backofficeDeleteCityBlock(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns city block details
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeGetCityBlockDetail(id: string, options?: any) {
      return BackOfficeForCityBlocksApiFp(configuration).backofficeGetCityBlockDetail(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns list of city block streets
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeGetCityBlockStreets(id: string, options?: any) {
      return BackOfficeForCityBlocksApiFp(configuration).backofficeGetCityBlockStreets(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns paged list of city blocks
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeListCityBlocks(page?: number, size?: number, options?: any) {
      return BackOfficeForCityBlocksApiFp(configuration).backofficeListCityBlocks(page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Updates city block
        * @param {CzYsP14KcCityblockApiDtoCityBlockObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeUpdateCityBlock(body: CzYsP14KcCityblockApiDtoCityBlockObject, id: string, options?: any) {
      return BackOfficeForCityBlocksApiFp(configuration).backofficeUpdateCityBlock(body, id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Modify list of streets for city block
        * @param {Array<string>} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      backofficeUpdateCityBlockStreets(body: Array<string>, id: string, options?: any) {
      return BackOfficeForCityBlocksApiFp(configuration).backofficeUpdateCityBlockStreets(body, id, options)(fetch, basePath);
      },
    };
    };

    /**
    * BackOfficeForCityBlocksApi - object-oriented interface
    * @export
    * @class BackOfficeForCityBlocksApi
    * @extends {BaseAPI}
    */
      export class BackOfficeForCityBlocksApi extends BaseAPI {
      /**
      * 
        * @summary Creates city block
        * @param {CzYsP14KcCityblockApiDtoCityBlockObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCityBlocksApi
      */
      public backofficeCreateCityBlock(body: CzYsP14KcCityblockApiDtoCityBlockObject, options?: any) {
      return BackOfficeForCityBlocksApiFp(this.configuration).backofficeCreateCityBlock(body, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Deletes city block
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCityBlocksApi
      */
      public backofficeDeleteCityBlock(id: string, options?: any) {
      return BackOfficeForCityBlocksApiFp(this.configuration).backofficeDeleteCityBlock(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns city block details
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCityBlocksApi
      */
      public backofficeGetCityBlockDetail(id: string, options?: any) {
      return BackOfficeForCityBlocksApiFp(this.configuration).backofficeGetCityBlockDetail(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns list of city block streets
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCityBlocksApi
      */
      public backofficeGetCityBlockStreets(id: string, options?: any) {
      return BackOfficeForCityBlocksApiFp(this.configuration).backofficeGetCityBlockStreets(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns paged list of city blocks
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCityBlocksApi
      */
      public backofficeListCityBlocks(page?: number, size?: number, options?: any) {
      return BackOfficeForCityBlocksApiFp(this.configuration).backofficeListCityBlocks(page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Updates city block
        * @param {CzYsP14KcCityblockApiDtoCityBlockObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCityBlocksApi
      */
      public backofficeUpdateCityBlock(body: CzYsP14KcCityblockApiDtoCityBlockObject, id: string, options?: any) {
      return BackOfficeForCityBlocksApiFp(this.configuration).backofficeUpdateCityBlock(body, id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Modify list of streets for city block
        * @param {Array<string>} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCityBlocksApi
      */
      public backofficeUpdateCityBlockStreets(body: Array<string>, id: string, options?: any) {
      return BackOfficeForCityBlocksApiFp(this.configuration).backofficeUpdateCityBlockStreets(body, id, options)(this.fetch, this.basePath);
      }

    }
    /**
    * BackOfficeForCommunitiesApi - fetch parameter creator
    * @export
    */
    export const BackOfficeForCommunitiesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Lists all communities, including their sentiment
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeCommunities(options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/community`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns a community, including its sentiment
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeCommunity(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeCommunity.');
          }
      const localVarPath = `/backoffice/community/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns community's comments
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeCommunityComments(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeCommunityComments.');
          }
      const localVarPath = `/backoffice/community/{id}/comments`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns community's members, posts, comments, events, surveys count
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeCommunityCounts(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeCommunityCounts.');
          }
      const localVarPath = `/backoffice/community/{id}/counts`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns community's events
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeCommunityEvents(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeCommunityEvents.');
          }
      const localVarPath = `/backoffice/community/{id}/events`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns community's members
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeCommunityMembers(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeCommunityMembers.');
          }
      const localVarPath = `/backoffice/community/{id}/members`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns community's posts
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeCommunityPosts(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeCommunityPosts.');
          }
      const localVarPath = `/backoffice/community/{id}/posts`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns community's surveys
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeCommunitySurveys(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeCommunitySurveys.');
          }
      const localVarPath = `/backoffice/community/{id}/surveys`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Updates community
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      updateBackofficeCommunity(body: CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling updateBackofficeCommunity.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling updateBackofficeCommunity.');
          }
      const localVarPath = `/backoffice/community/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * BackOfficeForCommunitiesApi - functional programming interface
    * @export
    */
    export const BackOfficeForCommunitiesApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Lists all communities, including their sentiment
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommunityObject> {
      const localVarFetchArgs = BackOfficeForCommunitiesApiFetchParamCreator(configuration).getBackofficeCommunities(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns a community, including its sentiment
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunity(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcBackofficeApiDtoBackOfficeCommunityObject> {
      const localVarFetchArgs = BackOfficeForCommunitiesApiFetchParamCreator(configuration).getBackofficeCommunity(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns community's comments
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunityComments(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcBackofficeApiDtoBackOfficeCommentObject>> {
      const localVarFetchArgs = BackOfficeForCommunitiesApiFetchParamCreator(configuration).getBackofficeCommunityComments(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns community's members, posts, comments, events, surveys count
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunityCounts(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcBackofficeApiDtoBackOfficeCommunityCountObject> {
      const localVarFetchArgs = BackOfficeForCommunitiesApiFetchParamCreator(configuration).getBackofficeCommunityCounts(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns community's events
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunityEvents(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcBackofficeApiDtoBackOfficeEventObject>> {
      const localVarFetchArgs = BackOfficeForCommunitiesApiFetchParamCreator(configuration).getBackofficeCommunityEvents(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns community's members
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunityMembers(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcBackofficeApiDtoBackOfficeMemberObject>> {
      const localVarFetchArgs = BackOfficeForCommunitiesApiFetchParamCreator(configuration).getBackofficeCommunityMembers(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns community's posts
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunityPosts(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcBackofficeApiDtoBackOfficePostObject>> {
      const localVarFetchArgs = BackOfficeForCommunitiesApiFetchParamCreator(configuration).getBackofficeCommunityPosts(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns community's surveys
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunitySurveys(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcBackofficeApiDtoBackOfficeSurveyObject>> {
      const localVarFetchArgs = BackOfficeForCommunitiesApiFetchParamCreator(configuration).getBackofficeCommunitySurveys(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Updates community
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateBackofficeCommunity(body: CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForCommunitiesApiFetchParamCreator(configuration).updateBackofficeCommunity(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * BackOfficeForCommunitiesApi - factory interface
    * @export
    */
    export const BackOfficeForCommunitiesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Lists all communities, including their sentiment
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunities(options?: any) {
      return BackOfficeForCommunitiesApiFp(configuration).getBackofficeCommunities(options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns a community, including its sentiment
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunity(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(configuration).getBackofficeCommunity(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns community's comments
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunityComments(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(configuration).getBackofficeCommunityComments(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns community's members, posts, comments, events, surveys count
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunityCounts(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(configuration).getBackofficeCommunityCounts(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns community's events
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunityEvents(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(configuration).getBackofficeCommunityEvents(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns community's members
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunityMembers(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(configuration).getBackofficeCommunityMembers(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns community's posts
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunityPosts(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(configuration).getBackofficeCommunityPosts(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns community's surveys
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeCommunitySurveys(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(configuration).getBackofficeCommunitySurveys(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Updates community
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateBackofficeCommunity(body: CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject, id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(configuration).updateBackofficeCommunity(body, id, options)(fetch, basePath);
      },
    };
    };

    /**
    * BackOfficeForCommunitiesApi - object-oriented interface
    * @export
    * @class BackOfficeForCommunitiesApi
    * @extends {BaseAPI}
    */
      export class BackOfficeForCommunitiesApi extends BaseAPI {
      /**
      * 
        * @summary Lists all communities, including their sentiment
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCommunitiesApi
      */
      public getBackofficeCommunities(options?: any) {
      return BackOfficeForCommunitiesApiFp(this.configuration).getBackofficeCommunities(options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns a community, including its sentiment
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCommunitiesApi
      */
      public getBackofficeCommunity(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(this.configuration).getBackofficeCommunity(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns community's comments
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCommunitiesApi
      */
      public getBackofficeCommunityComments(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(this.configuration).getBackofficeCommunityComments(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns community's members, posts, comments, events, surveys count
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCommunitiesApi
      */
      public getBackofficeCommunityCounts(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(this.configuration).getBackofficeCommunityCounts(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns community's events
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCommunitiesApi
      */
      public getBackofficeCommunityEvents(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(this.configuration).getBackofficeCommunityEvents(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns community's members
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCommunitiesApi
      */
      public getBackofficeCommunityMembers(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(this.configuration).getBackofficeCommunityMembers(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns community's posts
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCommunitiesApi
      */
      public getBackofficeCommunityPosts(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(this.configuration).getBackofficeCommunityPosts(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns community's surveys
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCommunitiesApi
      */
      public getBackofficeCommunitySurveys(id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(this.configuration).getBackofficeCommunitySurveys(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Updates community
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForCommunitiesApi
      */
      public updateBackofficeCommunity(body: CzYsP14KcBackofficeApiDtoBackOfficeCommunityUpdateObject, id: string, options?: any) {
      return BackOfficeForCommunitiesApiFp(this.configuration).updateBackofficeCommunity(body, id, options)(this.fetch, this.basePath);
      }

    }
    /**
    * BackOfficeForMessageCenterApi - fetch parameter creator
    * @export
    */
    export const BackOfficeForMessageCenterApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns list of all incoming messages (operational information)
        * @param {string} [text] 
        * @param {Array<string>} [category] 
        * @param {string} [extSource] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeIncomingMessages(text?: string, category?: Array<string>, extSource?: string, options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/message-centre/incoming`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (category) {
            localVarQueryParameter['category'] = category;
          }

          if (extSource !== undefined) {
              localVarQueryParameter['extSource'] = extSource;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns list of all outgoing messages
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeOutgoingMessages(options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/message-centre/outgoing`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * If a value of channel is [YOURPASS], the message is sent only to YourPass city card, otherwise the message is sent to mobile applications [PUSH].
        * @summary Sends a new message to users
        * @param {CzYsP14KcNotificationApiDtoNotificationMessageCreationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      sendBackofficeMessage(body: CzYsP14KcNotificationApiDtoNotificationMessageCreationObject, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling sendBackofficeMessage.');
          }
      const localVarPath = `/backoffice/message-centre/message`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcNotificationApiDtoNotificationMessageCreationObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * BackOfficeForMessageCenterApi - functional programming interface
    * @export
    */
    export const BackOfficeForMessageCenterApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns list of all incoming messages (operational information)
        * @param {string} [text] 
        * @param {Array<string>} [category] 
        * @param {string} [extSource] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeIncomingMessages(text?: string, category?: Array<string>, extSource?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject> {
      const localVarFetchArgs = BackOfficeForMessageCenterApiFetchParamCreator(configuration).getBackofficeIncomingMessages(text, category, extSource, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns list of all outgoing messages
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeOutgoingMessages(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeNotificationMessageObject> {
      const localVarFetchArgs = BackOfficeForMessageCenterApiFetchParamCreator(configuration).getBackofficeOutgoingMessages(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * If a value of channel is [YOURPASS], the message is sent only to YourPass city card, otherwise the message is sent to mobile applications [PUSH].
        * @summary Sends a new message to users
        * @param {CzYsP14KcNotificationApiDtoNotificationMessageCreationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      sendBackofficeMessage(body: CzYsP14KcNotificationApiDtoNotificationMessageCreationObject, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForMessageCenterApiFetchParamCreator(configuration).sendBackofficeMessage(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * BackOfficeForMessageCenterApi - factory interface
    * @export
    */
    export const BackOfficeForMessageCenterApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns list of all incoming messages (operational information)
        * @param {string} [text] 
        * @param {Array<string>} [category] 
        * @param {string} [extSource] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeIncomingMessages(text?: string, category?: Array<string>, extSource?: string, options?: any) {
      return BackOfficeForMessageCenterApiFp(configuration).getBackofficeIncomingMessages(text, category, extSource, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns list of all outgoing messages
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeOutgoingMessages(options?: any) {
      return BackOfficeForMessageCenterApiFp(configuration).getBackofficeOutgoingMessages(options)(fetch, basePath);
      },
      /**
      * If a value of channel is [YOURPASS], the message is sent only to YourPass city card, otherwise the message is sent to mobile applications [PUSH].
        * @summary Sends a new message to users
        * @param {CzYsP14KcNotificationApiDtoNotificationMessageCreationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      sendBackofficeMessage(body: CzYsP14KcNotificationApiDtoNotificationMessageCreationObject, options?: any) {
      return BackOfficeForMessageCenterApiFp(configuration).sendBackofficeMessage(body, options)(fetch, basePath);
      },
    };
    };

    /**
    * BackOfficeForMessageCenterApi - object-oriented interface
    * @export
    * @class BackOfficeForMessageCenterApi
    * @extends {BaseAPI}
    */
      export class BackOfficeForMessageCenterApi extends BaseAPI {
      /**
      * 
        * @summary Returns list of all incoming messages (operational information)
        * @param {string} [text] 
        * @param {Array<string>} [category] 
        * @param {string} [extSource] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForMessageCenterApi
      */
      public getBackofficeIncomingMessages(text?: string, category?: Array<string>, extSource?: string, options?: any) {
      return BackOfficeForMessageCenterApiFp(this.configuration).getBackofficeIncomingMessages(text, category, extSource, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns list of all outgoing messages
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForMessageCenterApi
      */
      public getBackofficeOutgoingMessages(options?: any) {
      return BackOfficeForMessageCenterApiFp(this.configuration).getBackofficeOutgoingMessages(options)(this.fetch, this.basePath);
      }

      /**
      * If a value of channel is [YOURPASS], the message is sent only to YourPass city card, otherwise the message is sent to mobile applications [PUSH].
        * @summary Sends a new message to users
        * @param {CzYsP14KcNotificationApiDtoNotificationMessageCreationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForMessageCenterApi
      */
      public sendBackofficeMessage(body: CzYsP14KcNotificationApiDtoNotificationMessageCreationObject, options?: any) {
      return BackOfficeForMessageCenterApiFp(this.configuration).sendBackofficeMessage(body, options)(this.fetch, this.basePath);
      }

    }
    /**
    * BackOfficeForOperationalInfoApi - fetch parameter creator
    * @export
    */
    export const BackOfficeForOperationalInfoApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Creates an operational info record
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      createOperationalInfo(body: CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling createOperationalInfo.');
          }
      const localVarPath = `/backoffice/operational-info`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Deletes an operational info record
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      deleteOperationalInfo(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOperationalInfo.');
          }
      const localVarPath = `/backoffice/operational-info/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Updates an operational info record
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      updateOperationalInfo(body: CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling updateOperationalInfo.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling updateOperationalInfo.');
          }
      const localVarPath = `/backoffice/operational-info/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * BackOfficeForOperationalInfoApi - functional programming interface
    * @export
    */
    export const BackOfficeForOperationalInfoApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Creates an operational info record
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      createOperationalInfo(body: CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
      const localVarFetchArgs = BackOfficeForOperationalInfoApiFetchParamCreator(configuration).createOperationalInfo(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Deletes an operational info record
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      deleteOperationalInfo(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForOperationalInfoApiFetchParamCreator(configuration).deleteOperationalInfo(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Updates an operational info record
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateOperationalInfo(body: CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForOperationalInfoApiFetchParamCreator(configuration).updateOperationalInfo(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * BackOfficeForOperationalInfoApi - factory interface
    * @export
    */
    export const BackOfficeForOperationalInfoApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Creates an operational info record
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      createOperationalInfo(body: CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject, options?: any) {
      return BackOfficeForOperationalInfoApiFp(configuration).createOperationalInfo(body, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Deletes an operational info record
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      deleteOperationalInfo(id: string, options?: any) {
      return BackOfficeForOperationalInfoApiFp(configuration).deleteOperationalInfo(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Updates an operational info record
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateOperationalInfo(body: CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject, id: string, options?: any) {
      return BackOfficeForOperationalInfoApiFp(configuration).updateOperationalInfo(body, id, options)(fetch, basePath);
      },
    };
    };

    /**
    * BackOfficeForOperationalInfoApi - object-oriented interface
    * @export
    * @class BackOfficeForOperationalInfoApi
    * @extends {BaseAPI}
    */
      export class BackOfficeForOperationalInfoApi extends BaseAPI {
      /**
      * 
        * @summary Creates an operational info record
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForOperationalInfoApi
      */
      public createOperationalInfo(body: CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject, options?: any) {
      return BackOfficeForOperationalInfoApiFp(this.configuration).createOperationalInfo(body, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Deletes an operational info record
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForOperationalInfoApi
      */
      public deleteOperationalInfo(id: string, options?: any) {
      return BackOfficeForOperationalInfoApiFp(this.configuration).deleteOperationalInfo(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Updates an operational info record
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForOperationalInfoApi
      */
      public updateOperationalInfo(body: CzYsP14KcBackofficeApiDtoBackOfficeOperationalInfoObject, id: string, options?: any) {
      return BackOfficeForOperationalInfoApiFp(this.configuration).updateOperationalInfo(body, id, options)(this.fetch, this.basePath);
      }

    }
    /**
    * BackOfficeForRUIANApi - fetch parameter creator
    * @export
    */
    export const BackOfficeForRUIANApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns list of streets
        * @param {string} [name] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      findStreets(name?: string, page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/ruian/street`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (name !== undefined) {
              localVarQueryParameter['name'] = name;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Imports addresses from RUIAN csv file
        * @param {Body1} [body] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      importRuian(body?: Body1, options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/ruian/import`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"Body1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * BackOfficeForRUIANApi - functional programming interface
    * @export
    */
    export const BackOfficeForRUIANApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns list of streets
        * @param {string} [name] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      findStreets(name?: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeStreetObject> {
      const localVarFetchArgs = BackOfficeForRUIANApiFetchParamCreator(configuration).findStreets(name, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Imports addresses from RUIAN csv file
        * @param {Body1} [body] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      importRuian(body?: Body1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForRUIANApiFetchParamCreator(configuration).importRuian(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * BackOfficeForRUIANApi - factory interface
    * @export
    */
    export const BackOfficeForRUIANApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns list of streets
        * @param {string} [name] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      findStreets(name?: string, page?: number, size?: number, options?: any) {
      return BackOfficeForRUIANApiFp(configuration).findStreets(name, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Imports addresses from RUIAN csv file
        * @param {Body1} [body] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      importRuian(body?: Body1, options?: any) {
      return BackOfficeForRUIANApiFp(configuration).importRuian(body, options)(fetch, basePath);
      },
    };
    };

    /**
    * BackOfficeForRUIANApi - object-oriented interface
    * @export
    * @class BackOfficeForRUIANApi
    * @extends {BaseAPI}
    */
      export class BackOfficeForRUIANApi extends BaseAPI {
      /**
      * 
        * @summary Returns list of streets
        * @param {string} [name] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForRUIANApi
      */
      public findStreets(name?: string, page?: number, size?: number, options?: any) {
      return BackOfficeForRUIANApiFp(this.configuration).findStreets(name, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Imports addresses from RUIAN csv file
        * @param {Body1} [body] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForRUIANApi
      */
      public importRuian(body?: Body1, options?: any) {
      return BackOfficeForRUIANApiFp(this.configuration).importRuian(body, options)(this.fetch, this.basePath);
      }

    }
    /**
    * BackOfficeForToxicityApi - fetch parameter creator
    * @export
    */
    export const BackOfficeForToxicityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns paged list of all comments
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getCommentsWithToxicity(communityId?: string, toxic?: boolean, options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/toxic-content/comments`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (communityId !== undefined) {
              localVarQueryParameter['communityId'] = communityId;
          }

          if (toxic !== undefined) {
              localVarQueryParameter['toxic'] = toxic;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns paged list of all events
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getEventsWithToxicity(communityId?: string, toxic?: boolean, options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/toxic-content/events`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (communityId !== undefined) {
              localVarQueryParameter['communityId'] = communityId;
          }

          if (toxic !== undefined) {
              localVarQueryParameter['toxic'] = toxic;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns paged list of all posts
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getPostsWithToxicity(communityId?: string, toxic?: boolean, options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/toxic-content/posts`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (communityId !== undefined) {
              localVarQueryParameter['communityId'] = communityId;
          }

          if (toxic !== undefined) {
              localVarQueryParameter['toxic'] = toxic;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns paged list of all surveys
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getSurveysWithToxicity(communityId?: string, toxic?: boolean, options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/toxic-content/surveys`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (communityId !== undefined) {
              localVarQueryParameter['communityId'] = communityId;
          }

          if (toxic !== undefined) {
              localVarQueryParameter['toxic'] = toxic;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Update toxicity status of comment
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      updateCommentToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling updateCommentToxicityStatus.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling updateCommentToxicityStatus.');
          }
      const localVarPath = `/backoffice/toxic-content/comments/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Update toxicity status of event
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      updateEventToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling updateEventToxicityStatus.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling updateEventToxicityStatus.');
          }
      const localVarPath = `/backoffice/toxic-content/events/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Update toxicity status of post
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      updatePostToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling updatePostToxicityStatus.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling updatePostToxicityStatus.');
          }
      const localVarPath = `/backoffice/toxic-content/posts/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Update toxicity status of survey
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      updateSurveyToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling updateSurveyToxicityStatus.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling updateSurveyToxicityStatus.');
          }
      const localVarPath = `/backoffice/toxic-content/surveys/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * BackOfficeForToxicityApi - functional programming interface
    * @export
    */
    export const BackOfficeForToxicityApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns paged list of all comments
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getCommentsWithToxicity(communityId?: string, toxic?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeCommentObject> {
      const localVarFetchArgs = BackOfficeForToxicityApiFetchParamCreator(configuration).getCommentsWithToxicity(communityId, toxic, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns paged list of all events
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getEventsWithToxicity(communityId?: string, toxic?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeEventObject> {
      const localVarFetchArgs = BackOfficeForToxicityApiFetchParamCreator(configuration).getEventsWithToxicity(communityId, toxic, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns paged list of all posts
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getPostsWithToxicity(communityId?: string, toxic?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficePostObject> {
      const localVarFetchArgs = BackOfficeForToxicityApiFetchParamCreator(configuration).getPostsWithToxicity(communityId, toxic, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns paged list of all surveys
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getSurveysWithToxicity(communityId?: string, toxic?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeSurveyObject> {
      const localVarFetchArgs = BackOfficeForToxicityApiFetchParamCreator(configuration).getSurveysWithToxicity(communityId, toxic, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Update toxicity status of comment
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateCommentToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForToxicityApiFetchParamCreator(configuration).updateCommentToxicityStatus(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Update toxicity status of event
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateEventToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForToxicityApiFetchParamCreator(configuration).updateEventToxicityStatus(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Update toxicity status of post
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updatePostToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForToxicityApiFetchParamCreator(configuration).updatePostToxicityStatus(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Update toxicity status of survey
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateSurveyToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForToxicityApiFetchParamCreator(configuration).updateSurveyToxicityStatus(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * BackOfficeForToxicityApi - factory interface
    * @export
    */
    export const BackOfficeForToxicityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns paged list of all comments
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getCommentsWithToxicity(communityId?: string, toxic?: boolean, options?: any) {
      return BackOfficeForToxicityApiFp(configuration).getCommentsWithToxicity(communityId, toxic, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns paged list of all events
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getEventsWithToxicity(communityId?: string, toxic?: boolean, options?: any) {
      return BackOfficeForToxicityApiFp(configuration).getEventsWithToxicity(communityId, toxic, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns paged list of all posts
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getPostsWithToxicity(communityId?: string, toxic?: boolean, options?: any) {
      return BackOfficeForToxicityApiFp(configuration).getPostsWithToxicity(communityId, toxic, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns paged list of all surveys
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getSurveysWithToxicity(communityId?: string, toxic?: boolean, options?: any) {
      return BackOfficeForToxicityApiFp(configuration).getSurveysWithToxicity(communityId, toxic, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Update toxicity status of comment
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateCommentToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options?: any) {
      return BackOfficeForToxicityApiFp(configuration).updateCommentToxicityStatus(body, id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Update toxicity status of event
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateEventToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options?: any) {
      return BackOfficeForToxicityApiFp(configuration).updateEventToxicityStatus(body, id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Update toxicity status of post
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updatePostToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options?: any) {
      return BackOfficeForToxicityApiFp(configuration).updatePostToxicityStatus(body, id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Update toxicity status of survey
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateSurveyToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options?: any) {
      return BackOfficeForToxicityApiFp(configuration).updateSurveyToxicityStatus(body, id, options)(fetch, basePath);
      },
    };
    };

    /**
    * BackOfficeForToxicityApi - object-oriented interface
    * @export
    * @class BackOfficeForToxicityApi
    * @extends {BaseAPI}
    */
      export class BackOfficeForToxicityApi extends BaseAPI {
      /**
      * 
        * @summary Returns paged list of all comments
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForToxicityApi
      */
      public getCommentsWithToxicity(communityId?: string, toxic?: boolean, options?: any) {
      return BackOfficeForToxicityApiFp(this.configuration).getCommentsWithToxicity(communityId, toxic, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns paged list of all events
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForToxicityApi
      */
      public getEventsWithToxicity(communityId?: string, toxic?: boolean, options?: any) {
      return BackOfficeForToxicityApiFp(this.configuration).getEventsWithToxicity(communityId, toxic, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns paged list of all posts
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForToxicityApi
      */
      public getPostsWithToxicity(communityId?: string, toxic?: boolean, options?: any) {
      return BackOfficeForToxicityApiFp(this.configuration).getPostsWithToxicity(communityId, toxic, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns paged list of all surveys
        * @param {string} [communityId] 
        * @param {boolean} [toxic] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForToxicityApi
      */
      public getSurveysWithToxicity(communityId?: string, toxic?: boolean, options?: any) {
      return BackOfficeForToxicityApiFp(this.configuration).getSurveysWithToxicity(communityId, toxic, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Update toxicity status of comment
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForToxicityApi
      */
      public updateCommentToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options?: any) {
      return BackOfficeForToxicityApiFp(this.configuration).updateCommentToxicityStatus(body, id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Update toxicity status of event
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForToxicityApi
      */
      public updateEventToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options?: any) {
      return BackOfficeForToxicityApiFp(this.configuration).updateEventToxicityStatus(body, id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Update toxicity status of post
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForToxicityApi
      */
      public updatePostToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options?: any) {
      return BackOfficeForToxicityApiFp(this.configuration).updatePostToxicityStatus(body, id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Update toxicity status of survey
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForToxicityApi
      */
      public updateSurveyToxicityStatus(body: CzYsP14KcBackofficeApiDtoBackOfficeToxicityObject, id: string, options?: any) {
      return BackOfficeForToxicityApiFp(this.configuration).updateSurveyToxicityStatus(body, id, options)(this.fetch, this.basePath);
      }

    }
    /**
    * BackOfficeForUsersApi - fetch parameter creator
    * @export
    */
    export const BackOfficeForUsersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Clears all personal information for the user
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      anonymizeUser(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling anonymizeUser.');
          }
      const localVarPath = `/backoffice/user/{id}/anonym`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Provides detail information about the user
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeUser(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeUser.');
          }
      const localVarPath = `/backoffice/user/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns user's comments
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeUserComments(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeUserComments.');
          }
      const localVarPath = `/backoffice/user/{id}/comments`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns user's posts, comments, events, likes count
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeUserCounts(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeUserCounts.');
          }
      const localVarPath = `/backoffice/user/{id}/counts`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns user's events
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeUserEvents(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeUserEvents.');
          }
      const localVarPath = `/backoffice/user/{id}/events`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns user's likes
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeUserLikes(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeUserLikes.');
          }
      const localVarPath = `/backoffice/user/{id}/likes`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns user's posts
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeUserPosts(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getBackofficeUserPosts.');
          }
      const localVarPath = `/backoffice/user/{id}/posts`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Lists all users
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getBackofficeUsers(options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/user`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Collects content generated by user
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getUserGdprReport(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getUserGdprReport.');
          }
      const localVarPath = `/backoffice/user/{id}/gdpr`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Collects content generated by user and creates PDF report
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getUserGdprReportPdf(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getUserGdprReportPdf.');
          }
      const localVarPath = `/backoffice/user/{id}/gdpr/pdf`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Updates an user
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      updateBackofficeUser(body: CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling updateBackofficeUser.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling updateBackofficeUser.');
          }
      const localVarPath = `/backoffice/user/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * BackOfficeForUsersApi - functional programming interface
    * @export
    */
    export const BackOfficeForUsersApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Clears all personal information for the user
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      anonymizeUser(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForUsersApiFetchParamCreator(configuration).anonymizeUser(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Provides detail information about the user
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUser(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcBackofficeApiDtoBackOfficeUserObject> {
      const localVarFetchArgs = BackOfficeForUsersApiFetchParamCreator(configuration).getBackofficeUser(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns user's comments
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUserComments(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcBackofficeApiDtoBackOfficeCommentObject>> {
      const localVarFetchArgs = BackOfficeForUsersApiFetchParamCreator(configuration).getBackofficeUserComments(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns user's posts, comments, events, likes count
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUserCounts(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcBackofficeApiDtoBackOfficeUserCountObject> {
      const localVarFetchArgs = BackOfficeForUsersApiFetchParamCreator(configuration).getBackofficeUserCounts(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns user's events
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUserEvents(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcBackofficeApiDtoBackOfficeEventObject>> {
      const localVarFetchArgs = BackOfficeForUsersApiFetchParamCreator(configuration).getBackofficeUserEvents(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns user's likes
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUserLikes(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObjectLike>> {
      const localVarFetchArgs = BackOfficeForUsersApiFetchParamCreator(configuration).getBackofficeUserLikes(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns user's posts
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUserPosts(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcBackofficeApiDtoBackOfficePostObject>> {
      const localVarFetchArgs = BackOfficeForUsersApiFetchParamCreator(configuration).getBackofficeUserPosts(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Lists all users
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUsers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcBackofficeApiDtoBackOfficeUserObject> {
      const localVarFetchArgs = BackOfficeForUsersApiFetchParamCreator(configuration).getBackofficeUsers(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Collects content generated by user
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getUserGdprReport(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcBackofficeApiDtoBackOfficeUserGdprObject> {
      const localVarFetchArgs = BackOfficeForUsersApiFetchParamCreator(configuration).getUserGdprReport(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Collects content generated by user and creates PDF report
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getUserGdprReportPdf(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
      const localVarFetchArgs = BackOfficeForUsersApiFetchParamCreator(configuration).getUserGdprReportPdf(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Updates an user
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateBackofficeUser(body: CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForUsersApiFetchParamCreator(configuration).updateBackofficeUser(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * BackOfficeForUsersApi - factory interface
    * @export
    */
    export const BackOfficeForUsersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Clears all personal information for the user
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      anonymizeUser(id: string, options?: any) {
      return BackOfficeForUsersApiFp(configuration).anonymizeUser(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Provides detail information about the user
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUser(id: string, options?: any) {
      return BackOfficeForUsersApiFp(configuration).getBackofficeUser(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns user's comments
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUserComments(id: string, options?: any) {
      return BackOfficeForUsersApiFp(configuration).getBackofficeUserComments(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns user's posts, comments, events, likes count
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUserCounts(id: string, options?: any) {
      return BackOfficeForUsersApiFp(configuration).getBackofficeUserCounts(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns user's events
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUserEvents(id: string, options?: any) {
      return BackOfficeForUsersApiFp(configuration).getBackofficeUserEvents(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns user's likes
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUserLikes(id: string, options?: any) {
      return BackOfficeForUsersApiFp(configuration).getBackofficeUserLikes(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns user's posts
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUserPosts(id: string, options?: any) {
      return BackOfficeForUsersApiFp(configuration).getBackofficeUserPosts(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Lists all users
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getBackofficeUsers(options?: any) {
      return BackOfficeForUsersApiFp(configuration).getBackofficeUsers(options)(fetch, basePath);
      },
      /**
      * 
        * @summary Collects content generated by user
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getUserGdprReport(id: string, options?: any) {
      return BackOfficeForUsersApiFp(configuration).getUserGdprReport(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Collects content generated by user and creates PDF report
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getUserGdprReportPdf(id: string, options?: any) {
      return BackOfficeForUsersApiFp(configuration).getUserGdprReportPdf(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Updates an user
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateBackofficeUser(body: CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject, id: string, options?: any) {
      return BackOfficeForUsersApiFp(configuration).updateBackofficeUser(body, id, options)(fetch, basePath);
      },
    };
    };

    /**
    * BackOfficeForUsersApi - object-oriented interface
    * @export
    * @class BackOfficeForUsersApi
    * @extends {BaseAPI}
    */
      export class BackOfficeForUsersApi extends BaseAPI {
      /**
      * 
        * @summary Clears all personal information for the user
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForUsersApi
      */
      public anonymizeUser(id: string, options?: any) {
      return BackOfficeForUsersApiFp(this.configuration).anonymizeUser(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Provides detail information about the user
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForUsersApi
      */
      public getBackofficeUser(id: string, options?: any) {
      return BackOfficeForUsersApiFp(this.configuration).getBackofficeUser(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns user's comments
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForUsersApi
      */
      public getBackofficeUserComments(id: string, options?: any) {
      return BackOfficeForUsersApiFp(this.configuration).getBackofficeUserComments(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns user's posts, comments, events, likes count
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForUsersApi
      */
      public getBackofficeUserCounts(id: string, options?: any) {
      return BackOfficeForUsersApiFp(this.configuration).getBackofficeUserCounts(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns user's events
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForUsersApi
      */
      public getBackofficeUserEvents(id: string, options?: any) {
      return BackOfficeForUsersApiFp(this.configuration).getBackofficeUserEvents(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns user's likes
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForUsersApi
      */
      public getBackofficeUserLikes(id: string, options?: any) {
      return BackOfficeForUsersApiFp(this.configuration).getBackofficeUserLikes(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns user's posts
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForUsersApi
      */
      public getBackofficeUserPosts(id: string, options?: any) {
      return BackOfficeForUsersApiFp(this.configuration).getBackofficeUserPosts(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Lists all users
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForUsersApi
      */
      public getBackofficeUsers(options?: any) {
      return BackOfficeForUsersApiFp(this.configuration).getBackofficeUsers(options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Collects content generated by user
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForUsersApi
      */
      public getUserGdprReport(id: string, options?: any) {
      return BackOfficeForUsersApiFp(this.configuration).getUserGdprReport(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Collects content generated by user and creates PDF report
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForUsersApi
      */
      public getUserGdprReportPdf(id: string, options?: any) {
      return BackOfficeForUsersApiFp(this.configuration).getUserGdprReportPdf(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Updates an user
        * @param {CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForUsersApi
      */
      public updateBackofficeUser(body: CzYsP14KcBackofficeApiDtoBackOfficeUserUpdateObject, id: string, options?: any) {
      return BackOfficeForUsersApiFp(this.configuration).updateBackofficeUser(body, id, options)(this.fetch, this.basePath);
      }

    }
    /**
    * BackOfficeForWidgetApi - fetch parameter creator
    * @export
    */
    export const BackOfficeForWidgetApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Saves or delete a section widget of user according to a field value of 'active'
        * @param {CzYsP14KcBiApiDtoWidgetObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      createOrDeleteWidget(body: CzYsP14KcBiApiDtoWidgetObject, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling createOrDeleteWidget.');
          }
      const localVarPath = `/backoffice/widget`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcBiApiDtoWidgetObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary List of visuals that user selected as widgets for one section
        * @param {string} section 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getUserSectionVisuals(section: string, options: any = {}): FetchArgs {
          // verify required parameter 'section' is not null or undefined
          if (section === null || section === undefined) {
          throw new RequiredError('section','Required parameter section was null or undefined when calling getUserSectionVisuals.');
          }
      const localVarPath = `/backoffice/widget`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (section !== undefined) {
              localVarQueryParameter['section'] = section;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * BackOfficeForWidgetApi - functional programming interface
    * @export
    */
    export const BackOfficeForWidgetApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Saves or delete a section widget of user according to a field value of 'active'
        * @param {CzYsP14KcBiApiDtoWidgetObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      createOrDeleteWidget(body: CzYsP14KcBiApiDtoWidgetObject, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BackOfficeForWidgetApiFetchParamCreator(configuration).createOrDeleteWidget(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary List of visuals that user selected as widgets for one section
        * @param {string} section 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getUserSectionVisuals(section: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcBiApiDtoVisualObject>> {
      const localVarFetchArgs = BackOfficeForWidgetApiFetchParamCreator(configuration).getUserSectionVisuals(section, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * BackOfficeForWidgetApi - factory interface
    * @export
    */
    export const BackOfficeForWidgetApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Saves or delete a section widget of user according to a field value of 'active'
        * @param {CzYsP14KcBiApiDtoWidgetObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      createOrDeleteWidget(body: CzYsP14KcBiApiDtoWidgetObject, options?: any) {
      return BackOfficeForWidgetApiFp(configuration).createOrDeleteWidget(body, options)(fetch, basePath);
      },
      /**
      * 
        * @summary List of visuals that user selected as widgets for one section
        * @param {string} section 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getUserSectionVisuals(section: string, options?: any) {
      return BackOfficeForWidgetApiFp(configuration).getUserSectionVisuals(section, options)(fetch, basePath);
      },
    };
    };

    /**
    * BackOfficeForWidgetApi - object-oriented interface
    * @export
    * @class BackOfficeForWidgetApi
    * @extends {BaseAPI}
    */
      export class BackOfficeForWidgetApi extends BaseAPI {
      /**
      * 
        * @summary Saves or delete a section widget of user according to a field value of 'active'
        * @param {CzYsP14KcBiApiDtoWidgetObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForWidgetApi
      */
      public createOrDeleteWidget(body: CzYsP14KcBiApiDtoWidgetObject, options?: any) {
      return BackOfficeForWidgetApiFp(this.configuration).createOrDeleteWidget(body, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary List of visuals that user selected as widgets for one section
        * @param {string} section 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForWidgetApi
      */
      public getUserSectionVisuals(section: string, options?: any) {
      return BackOfficeForWidgetApiFp(this.configuration).getUserSectionVisuals(section, options)(this.fetch, this.basePath);
      }

    }
    /**
    * BackOfficeForWordPressAuthorsApi - fetch parameter creator
    * @export
    */
    export const BackOfficeForWordPressAuthorsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary List all wordpress authors
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getUsers(options: any = {}): FetchArgs {
      const localVarPath = `/backoffice/wp-authors`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * BackOfficeForWordPressAuthorsApi - functional programming interface
    * @export
    */
    export const BackOfficeForWordPressAuthorsApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary List all wordpress authors
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getUsers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<{ [key: string]: any; }>> {
      const localVarFetchArgs = BackOfficeForWordPressAuthorsApiFetchParamCreator(configuration).getUsers(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * BackOfficeForWordPressAuthorsApi - factory interface
    * @export
    */
    export const BackOfficeForWordPressAuthorsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary List all wordpress authors
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getUsers(options?: any) {
      return BackOfficeForWordPressAuthorsApiFp(configuration).getUsers(options)(fetch, basePath);
      },
    };
    };

    /**
    * BackOfficeForWordPressAuthorsApi - object-oriented interface
    * @export
    * @class BackOfficeForWordPressAuthorsApi
    * @extends {BaseAPI}
    */
      export class BackOfficeForWordPressAuthorsApi extends BaseAPI {
      /**
      * 
        * @summary List all wordpress authors
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof BackOfficeForWordPressAuthorsApi
      */
      public getUsers(options?: any) {
      return BackOfficeForWordPressAuthorsApiFp(this.configuration).getUsers(options)(this.fetch, this.basePath);
      }

    }
    /**
    * CityBlocksApi - fetch parameter creator
    * @export
    */
    export const CityBlocksApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns list of city blocks
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listCityBlocks(options: any = {}): FetchArgs {
      const localVarPath = `/city-block`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * CityBlocksApi - functional programming interface
    * @export
    */
    export const CityBlocksApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns list of city blocks
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listCityBlocks(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcCityblockApiDtoCityBlockObject>> {
      const localVarFetchArgs = CityBlocksApiFetchParamCreator(configuration).listCityBlocks(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * CityBlocksApi - factory interface
    * @export
    */
    export const CityBlocksApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns list of city blocks
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listCityBlocks(options?: any) {
      return CityBlocksApiFp(configuration).listCityBlocks(options)(fetch, basePath);
      },
    };
    };

    /**
    * CityBlocksApi - object-oriented interface
    * @export
    * @class CityBlocksApi
    * @extends {BaseAPI}
    */
      export class CityBlocksApi extends BaseAPI {
      /**
      * 
        * @summary Returns list of city blocks
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CityBlocksApi
      */
      public listCityBlocks(options?: any) {
      return CityBlocksApiFp(this.configuration).listCityBlocks(options)(this.fetch, this.basePath);
      }

    }
    /**
    * CommentsApi - fetch parameter creator
    * @export
    */
    export const CommentsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns comments for given entity
        * @param {string} type 
        * @param {string} parentId 
        * @param {string} [fromDate] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getComments(type: string, parentId: string, fromDate?: string, page?: number, size?: number, options: any = {}): FetchArgs {
          // verify required parameter 'type' is not null or undefined
          if (type === null || type === undefined) {
          throw new RequiredError('type','Required parameter type was null or undefined when calling getComments.');
          }
          // verify required parameter 'parentId' is not null or undefined
          if (parentId === null || parentId === undefined) {
          throw new RequiredError('parentId','Required parameter parentId was null or undefined when calling getComments.');
          }
      const localVarPath = `/comment/{type}/{parentId}`
      .replace(`{${"type"}}`, encodeURIComponent(String(type)))
      .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (fromDate !== undefined) {
            localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ? (fromDate as any).toISOString() : fromDate;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Adds new comment for given entity
        * @param {CzYsP14KcCommentApiDtoCommentSubmissionObject} body 
        * @param {string} type 
        * @param {string} parentId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      submitComment(body: CzYsP14KcCommentApiDtoCommentSubmissionObject, type: string, parentId: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling submitComment.');
          }
          // verify required parameter 'type' is not null or undefined
          if (type === null || type === undefined) {
          throw new RequiredError('type','Required parameter type was null or undefined when calling submitComment.');
          }
          // verify required parameter 'parentId' is not null or undefined
          if (parentId === null || parentId === undefined) {
          throw new RequiredError('parentId','Required parameter parentId was null or undefined when calling submitComment.');
          }
      const localVarPath = `/comment/{type}/{parentId}`
      .replace(`{${"type"}}`, encodeURIComponent(String(type)))
      .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcCommentApiDtoCommentSubmissionObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Current user reports comment as toxic
        * @param {string} commentId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      toxicComment(commentId: string, options: any = {}): FetchArgs {
          // verify required parameter 'commentId' is not null or undefined
          if (commentId === null || commentId === undefined) {
          throw new RequiredError('commentId','Required parameter commentId was null or undefined when calling toxicComment.');
          }
      const localVarPath = `/comment/{commentId}/toxic`
      .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * CommentsApi - functional programming interface
    * @export
    */
    export const CommentsApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns comments for given entity
        * @param {string} type 
        * @param {string} parentId 
        * @param {string} [fromDate] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getComments(type: string, parentId: string, fromDate?: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcCommentApiDtoCommentObject> {
      const localVarFetchArgs = CommentsApiFetchParamCreator(configuration).getComments(type, parentId, fromDate, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Adds new comment for given entity
        * @param {CzYsP14KcCommentApiDtoCommentSubmissionObject} body 
        * @param {string} type 
        * @param {string} parentId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      submitComment(body: CzYsP14KcCommentApiDtoCommentSubmissionObject, type: string, parentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
      const localVarFetchArgs = CommentsApiFetchParamCreator(configuration).submitComment(body, type, parentId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Current user reports comment as toxic
        * @param {string} commentId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      toxicComment(commentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CommentsApiFetchParamCreator(configuration).toxicComment(commentId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * CommentsApi - factory interface
    * @export
    */
    export const CommentsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns comments for given entity
        * @param {string} type 
        * @param {string} parentId 
        * @param {string} [fromDate] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getComments(type: string, parentId: string, fromDate?: string, page?: number, size?: number, options?: any) {
      return CommentsApiFp(configuration).getComments(type, parentId, fromDate, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Adds new comment for given entity
        * @param {CzYsP14KcCommentApiDtoCommentSubmissionObject} body 
        * @param {string} type 
        * @param {string} parentId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      submitComment(body: CzYsP14KcCommentApiDtoCommentSubmissionObject, type: string, parentId: string, options?: any) {
      return CommentsApiFp(configuration).submitComment(body, type, parentId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Current user reports comment as toxic
        * @param {string} commentId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      toxicComment(commentId: string, options?: any) {
      return CommentsApiFp(configuration).toxicComment(commentId, options)(fetch, basePath);
      },
    };
    };

    /**
    * CommentsApi - object-oriented interface
    * @export
    * @class CommentsApi
    * @extends {BaseAPI}
    */
      export class CommentsApi extends BaseAPI {
      /**
      * 
        * @summary Returns comments for given entity
        * @param {string} type 
        * @param {string} parentId 
        * @param {string} [fromDate] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommentsApi
      */
      public getComments(type: string, parentId: string, fromDate?: string, page?: number, size?: number, options?: any) {
      return CommentsApiFp(this.configuration).getComments(type, parentId, fromDate, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Adds new comment for given entity
        * @param {CzYsP14KcCommentApiDtoCommentSubmissionObject} body 
        * @param {string} type 
        * @param {string} parentId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommentsApi
      */
      public submitComment(body: CzYsP14KcCommentApiDtoCommentSubmissionObject, type: string, parentId: string, options?: any) {
      return CommentsApiFp(this.configuration).submitComment(body, type, parentId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Current user reports comment as toxic
        * @param {string} commentId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommentsApi
      */
      public toxicComment(commentId: string, options?: any) {
      return CommentsApiFp(this.configuration).toxicComment(commentId, options)(this.fetch, this.basePath);
      }

    }
    /**
    * CommunitiesApi - fetch parameter creator
    * @export
    */
    export const CommunitiesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns list of all communities for displaying in a map
        * @param {string} [kind] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      communitiesForMap(kind?: string, options: any = {}): FetchArgs {
      const localVarPath = `/community/map`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (kind !== undefined) {
              localVarQueryParameter['kind'] = kind;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Creates new community. Current user becomes the owner of the community
        * @param {Body} body One of possible community kind
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      createCommunity(body: Body, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling createCommunity.');
          }
      const localVarPath = `/community`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"Body" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Creates new event for a community
        * @param {CzYsP14KcEventApiDtoEventCreationObject} body 
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      createEvent(body: CzYsP14KcEventApiDtoEventCreationObject, communityId: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling createEvent.');
          }
          // verify required parameter 'communityId' is not null or undefined
          if (communityId === null || communityId === undefined) {
          throw new RequiredError('communityId','Required parameter communityId was null or undefined when calling createEvent.');
          }
      const localVarPath = `/community/{communityId}/event`
      .replace(`{${"communityId"}}`, encodeURIComponent(String(communityId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcEventApiDtoEventCreationObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Creates new survey to community
        * @param {CzYsP14KcSurveyApiDtoSurveyCreationObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      createSurvey(body: CzYsP14KcSurveyApiDtoSurveyCreationObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling createSurvey.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling createSurvey.');
          }
      const localVarPath = `/community/{id}/survey`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcSurveyApiDtoSurveyCreationObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns community detail
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getCommunity(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getCommunity.');
          }
      const localVarPath = `/community/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns feeds related to community
        * @param {string} id 
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getCommunityFeed(id: string, interest?: Array<string>, text?: string, page?: number, size?: number, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getCommunityFeed.');
          }
      const localVarPath = `/community/{id}/feed`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (interest) {
            localVarQueryParameter['interest'] = interest;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns list of feeds for the current user and its communities
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getFeed(interest?: Array<string>, text?: string, page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/community/feed`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (interest) {
            localVarQueryParameter['interest'] = interest;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns current community events
        * @param {string} communityId 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listCurrentEventsForCommunity(communityId: string, page?: number, size?: number, options: any = {}): FetchArgs {
          // verify required parameter 'communityId' is not null or undefined
          if (communityId === null || communityId === undefined) {
          throw new RequiredError('communityId','Required parameter communityId was null or undefined when calling listCurrentEventsForCommunity.');
          }
      const localVarPath = `/community/{communityId}/event/current`
      .replace(`{${"communityId"}}`, encodeURIComponent(String(communityId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns events from communities, in which a current user is member
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listEventsForCommunities(text?: string, page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/community/event`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns community events as daily list
        * @param {string} communityId 
        * @param {string} [dateFrom] 
        * @param {string} [dateTo] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listEventsForCommunity(communityId: string, dateFrom?: string, dateTo?: string, text?: string, flags?: Array<string>, interest?: Array<string>, page?: number, size?: number, options: any = {}): FetchArgs {
          // verify required parameter 'communityId' is not null or undefined
          if (communityId === null || communityId === undefined) {
          throw new RequiredError('communityId','Required parameter communityId was null or undefined when calling listEventsForCommunity.');
          }
      const localVarPath = `/community/{communityId}/event`
      .replace(`{${"communityId"}}`, encodeURIComponent(String(communityId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (dateFrom !== undefined) {
              localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ? (dateFrom as any).toISOString().substr(0,10) : dateFrom;
          }

          if (dateTo !== undefined) {
              localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ? (dateTo as any).toISOString().substr(0,10) : dateTo;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (flags) {
            localVarQueryParameter['flags'] = flags;
          }

          if (interest) {
            localVarQueryParameter['interest'] = interest;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns a list of communities in which the current user is a member
        * @param {string} [kind] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listMyCommunities(kind?: string, options: any = {}): FetchArgs {
      const localVarPath = `/community/my`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (kind !== undefined) {
              localVarQueryParameter['kind'] = kind;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns posts from communities, in which a current user is member
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listPostsForCommunities(text?: string, page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/community/post`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns community posts, current user must be member of community
        * @param {string} communityId 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listPostsForCommunity(communityId: string, text?: string, page?: number, size?: number, options: any = {}): FetchArgs {
          // verify required parameter 'communityId' is not null or undefined
          if (communityId === null || communityId === undefined) {
          throw new RequiredError('communityId','Required parameter communityId was null or undefined when calling listPostsForCommunity.');
          }
      const localVarPath = `/community/{communityId}/post`
      .replace(`{${"communityId"}}`, encodeURIComponent(String(communityId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Creates new post to community
        * @param {CzYsP14KcPostApiDtoPostCreationObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      submitPost(body: CzYsP14KcPostApiDtoPostCreationObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling submitPost.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling submitPost.');
          }
      const localVarPath = `/community/{id}/post`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcPostApiDtoPostCreationObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Test uniqueness of community name
        * @param {string} name 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      testByName(name: string, options: any = {}): FetchArgs {
          // verify required parameter 'name' is not null or undefined
          if (name === null || name === undefined) {
          throw new RequiredError('name','Required parameter name was null or undefined when calling testByName.');
          }
      const localVarPath = `/community/test`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (name !== undefined) {
              localVarQueryParameter['name'] = name;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns list of undiscovered communities ordered by score for current user
        * @param {string} [text] 
        * @param {Array<string>} [interest] 
        * @param {string} [kind] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      undiscoveredCommunities(text?: string, interest?: Array<string>, kind?: string, options: any = {}): FetchArgs {
      const localVarPath = `/community/undiscovered`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (interest) {
            localVarQueryParameter['interest'] = interest;
          }

          if (kind !== undefined) {
              localVarQueryParameter['kind'] = kind;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Updates community
        * @param {Body2} body One of possible community kind
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      updateCommunity(body: Body2, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling updateCommunity.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling updateCommunity.');
          }
      const localVarPath = `/community/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"Body2" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * CommunitiesApi - functional programming interface
    * @export
    */
    export const CommunitiesApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns list of all communities for displaying in a map
        * @param {string} [kind] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      communitiesForMap(kind?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcCommunityApiDtoCommunityMapObject>> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).communitiesForMap(kind, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Creates new community. Current user becomes the owner of the community
        * @param {Body} body One of possible community kind
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      createCommunity(body: Body, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).createCommunity(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Creates new event for a community
        * @param {CzYsP14KcEventApiDtoEventCreationObject} body 
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      createEvent(body: CzYsP14KcEventApiDtoEventCreationObject, communityId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).createEvent(body, communityId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Creates new survey to community
        * @param {CzYsP14KcSurveyApiDtoSurveyCreationObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      createSurvey(body: CzYsP14KcSurveyApiDtoSurveyCreationObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).createSurvey(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns community detail
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getCommunity(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcCommunityApiDtoCommunityDetailObject> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).getCommunity(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns feeds related to community
        * @param {string} id 
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getCommunityFeed(id: string, interest?: Array<string>, text?: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).getCommunityFeed(id, interest, text, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns list of feeds for the current user and its communities
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getFeed(interest?: Array<string>, text?: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).getFeed(interest, text, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns current community events
        * @param {string} communityId 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listCurrentEventsForCommunity(communityId: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).listCurrentEventsForCommunity(communityId, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns events from communities, in which a current user is member
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listEventsForCommunities(text?: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).listEventsForCommunities(text, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns community events as daily list
        * @param {string} communityId 
        * @param {string} [dateFrom] 
        * @param {string} [dateTo] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listEventsForCommunity(communityId: string, dateFrom?: string, dateTo?: string, text?: string, flags?: Array<string>, interest?: Array<string>, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).listEventsForCommunity(communityId, dateFrom, dateTo, text, flags, interest, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns a list of communities in which the current user is a member
        * @param {string} [kind] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listMyCommunities(kind?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).listMyCommunities(kind, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns posts from communities, in which a current user is member
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listPostsForCommunities(text?: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).listPostsForCommunities(text, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns community posts, current user must be member of community
        * @param {string} communityId 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listPostsForCommunity(communityId: string, text?: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).listPostsForCommunity(communityId, text, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Creates new post to community
        * @param {CzYsP14KcPostApiDtoPostCreationObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      submitPost(body: CzYsP14KcPostApiDtoPostCreationObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).submitPost(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Test uniqueness of community name
        * @param {string} name 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      testByName(name: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).testByName(name, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns list of undiscovered communities ordered by score for current user
        * @param {string} [text] 
        * @param {Array<string>} [interest] 
        * @param {string} [kind] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      undiscoveredCommunities(text?: string, interest?: Array<string>, kind?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcCommunityApiDtoCommunityListDetailObject> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).undiscoveredCommunities(text, interest, kind, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Updates community
        * @param {Body2} body One of possible community kind
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateCommunity(body: Body2, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CommunitiesApiFetchParamCreator(configuration).updateCommunity(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * CommunitiesApi - factory interface
    * @export
    */
    export const CommunitiesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns list of all communities for displaying in a map
        * @param {string} [kind] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      communitiesForMap(kind?: string, options?: any) {
      return CommunitiesApiFp(configuration).communitiesForMap(kind, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Creates new community. Current user becomes the owner of the community
        * @param {Body} body One of possible community kind
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      createCommunity(body: Body, options?: any) {
      return CommunitiesApiFp(configuration).createCommunity(body, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Creates new event for a community
        * @param {CzYsP14KcEventApiDtoEventCreationObject} body 
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      createEvent(body: CzYsP14KcEventApiDtoEventCreationObject, communityId: string, options?: any) {
      return CommunitiesApiFp(configuration).createEvent(body, communityId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Creates new survey to community
        * @param {CzYsP14KcSurveyApiDtoSurveyCreationObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      createSurvey(body: CzYsP14KcSurveyApiDtoSurveyCreationObject, id: string, options?: any) {
      return CommunitiesApiFp(configuration).createSurvey(body, id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns community detail
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getCommunity(id: string, options?: any) {
      return CommunitiesApiFp(configuration).getCommunity(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns feeds related to community
        * @param {string} id 
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getCommunityFeed(id: string, interest?: Array<string>, text?: string, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(configuration).getCommunityFeed(id, interest, text, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns list of feeds for the current user and its communities
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getFeed(interest?: Array<string>, text?: string, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(configuration).getFeed(interest, text, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns current community events
        * @param {string} communityId 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listCurrentEventsForCommunity(communityId: string, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(configuration).listCurrentEventsForCommunity(communityId, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns events from communities, in which a current user is member
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listEventsForCommunities(text?: string, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(configuration).listEventsForCommunities(text, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns community events as daily list
        * @param {string} communityId 
        * @param {string} [dateFrom] 
        * @param {string} [dateTo] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listEventsForCommunity(communityId: string, dateFrom?: string, dateTo?: string, text?: string, flags?: Array<string>, interest?: Array<string>, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(configuration).listEventsForCommunity(communityId, dateFrom, dateTo, text, flags, interest, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns a list of communities in which the current user is a member
        * @param {string} [kind] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listMyCommunities(kind?: string, options?: any) {
      return CommunitiesApiFp(configuration).listMyCommunities(kind, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns posts from communities, in which a current user is member
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listPostsForCommunities(text?: string, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(configuration).listPostsForCommunities(text, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns community posts, current user must be member of community
        * @param {string} communityId 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listPostsForCommunity(communityId: string, text?: string, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(configuration).listPostsForCommunity(communityId, text, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Creates new post to community
        * @param {CzYsP14KcPostApiDtoPostCreationObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      submitPost(body: CzYsP14KcPostApiDtoPostCreationObject, id: string, options?: any) {
      return CommunitiesApiFp(configuration).submitPost(body, id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Test uniqueness of community name
        * @param {string} name 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      testByName(name: string, options?: any) {
      return CommunitiesApiFp(configuration).testByName(name, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns list of undiscovered communities ordered by score for current user
        * @param {string} [text] 
        * @param {Array<string>} [interest] 
        * @param {string} [kind] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      undiscoveredCommunities(text?: string, interest?: Array<string>, kind?: string, options?: any) {
      return CommunitiesApiFp(configuration).undiscoveredCommunities(text, interest, kind, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Updates community
        * @param {Body2} body One of possible community kind
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateCommunity(body: Body2, id: string, options?: any) {
      return CommunitiesApiFp(configuration).updateCommunity(body, id, options)(fetch, basePath);
      },
    };
    };

    /**
    * CommunitiesApi - object-oriented interface
    * @export
    * @class CommunitiesApi
    * @extends {BaseAPI}
    */
      export class CommunitiesApi extends BaseAPI {
      /**
      * 
        * @summary Returns list of all communities for displaying in a map
        * @param {string} [kind] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public communitiesForMap(kind?: string, options?: any) {
      return CommunitiesApiFp(this.configuration).communitiesForMap(kind, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Creates new community. Current user becomes the owner of the community
        * @param {Body} body One of possible community kind
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public createCommunity(body: Body, options?: any) {
      return CommunitiesApiFp(this.configuration).createCommunity(body, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Creates new event for a community
        * @param {CzYsP14KcEventApiDtoEventCreationObject} body 
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public createEvent(body: CzYsP14KcEventApiDtoEventCreationObject, communityId: string, options?: any) {
      return CommunitiesApiFp(this.configuration).createEvent(body, communityId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Creates new survey to community
        * @param {CzYsP14KcSurveyApiDtoSurveyCreationObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public createSurvey(body: CzYsP14KcSurveyApiDtoSurveyCreationObject, id: string, options?: any) {
      return CommunitiesApiFp(this.configuration).createSurvey(body, id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns community detail
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public getCommunity(id: string, options?: any) {
      return CommunitiesApiFp(this.configuration).getCommunity(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns feeds related to community
        * @param {string} id 
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public getCommunityFeed(id: string, interest?: Array<string>, text?: string, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(this.configuration).getCommunityFeed(id, interest, text, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns list of feeds for the current user and its communities
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public getFeed(interest?: Array<string>, text?: string, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(this.configuration).getFeed(interest, text, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns current community events
        * @param {string} communityId 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public listCurrentEventsForCommunity(communityId: string, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(this.configuration).listCurrentEventsForCommunity(communityId, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns events from communities, in which a current user is member
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public listEventsForCommunities(text?: string, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(this.configuration).listEventsForCommunities(text, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns community events as daily list
        * @param {string} communityId 
        * @param {string} [dateFrom] 
        * @param {string} [dateTo] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public listEventsForCommunity(communityId: string, dateFrom?: string, dateTo?: string, text?: string, flags?: Array<string>, interest?: Array<string>, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(this.configuration).listEventsForCommunity(communityId, dateFrom, dateTo, text, flags, interest, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns a list of communities in which the current user is a member
        * @param {string} [kind] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public listMyCommunities(kind?: string, options?: any) {
      return CommunitiesApiFp(this.configuration).listMyCommunities(kind, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns posts from communities, in which a current user is member
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public listPostsForCommunities(text?: string, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(this.configuration).listPostsForCommunities(text, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns community posts, current user must be member of community
        * @param {string} communityId 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public listPostsForCommunity(communityId: string, text?: string, page?: number, size?: number, options?: any) {
      return CommunitiesApiFp(this.configuration).listPostsForCommunity(communityId, text, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Creates new post to community
        * @param {CzYsP14KcPostApiDtoPostCreationObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public submitPost(body: CzYsP14KcPostApiDtoPostCreationObject, id: string, options?: any) {
      return CommunitiesApiFp(this.configuration).submitPost(body, id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Test uniqueness of community name
        * @param {string} name 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public testByName(name: string, options?: any) {
      return CommunitiesApiFp(this.configuration).testByName(name, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns list of undiscovered communities ordered by score for current user
        * @param {string} [text] 
        * @param {Array<string>} [interest] 
        * @param {string} [kind] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public undiscoveredCommunities(text?: string, interest?: Array<string>, kind?: string, options?: any) {
      return CommunitiesApiFp(this.configuration).undiscoveredCommunities(text, interest, kind, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Updates community
        * @param {Body2} body One of possible community kind
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunitiesApi
      */
      public updateCommunity(body: Body2, id: string, options?: any) {
      return CommunitiesApiFp(this.configuration).updateCommunity(body, id, options)(this.fetch, this.basePath);
      }

    }
    /**
    * CommunityMembershipsApi - fetch parameter creator
    * @export
    */
    export const CommunityMembershipsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary The current user becomes a new member of the public, official community or applies for membership in a private community
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      addCurrentUserAsMember(communityId: string, options: any = {}): FetchArgs {
          // verify required parameter 'communityId' is not null or undefined
          if (communityId === null || communityId === undefined) {
          throw new RequiredError('communityId','Required parameter communityId was null or undefined when calling addCurrentUserAsMember.');
          }
      const localVarPath = `/community/{communityId}/membership`
      .replace(`{${"communityId"}}`, encodeURIComponent(String(communityId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary The current user removes their membership
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      deleteCurrentUserMembership(communityId: string, options: any = {}): FetchArgs {
          // verify required parameter 'communityId' is not null or undefined
          if (communityId === null || communityId === undefined) {
          throw new RequiredError('communityId','Required parameter communityId was null or undefined when calling deleteCurrentUserMembership.');
          }
      const localVarPath = `/community/{communityId}/membership`
      .replace(`{${"communityId"}}`, encodeURIComponent(String(communityId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary The owner, deputy of community removes member from the community
        * @param {string} communityId 
        * @param {string} userId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      deleteMember(communityId: string, userId: string, options: any = {}): FetchArgs {
          // verify required parameter 'communityId' is not null or undefined
          if (communityId === null || communityId === undefined) {
          throw new RequiredError('communityId','Required parameter communityId was null or undefined when calling deleteMember.');
          }
          // verify required parameter 'userId' is not null or undefined
          if (userId === null || userId === undefined) {
          throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteMember.');
          }
      const localVarPath = `/community/{communityId}/member/{userId}`
      .replace(`{${"communityId"}}`, encodeURIComponent(String(communityId)))
      .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Finds community members
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getMembers(communityId: string, options: any = {}): FetchArgs {
          // verify required parameter 'communityId' is not null or undefined
          if (communityId === null || communityId === undefined) {
          throw new RequiredError('communityId','Required parameter communityId was null or undefined when calling getMembers.');
          }
      const localVarPath = `/community/{communityId}/member`
      .replace(`{${"communityId"}}`, encodeURIComponent(String(communityId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary The owner of community invites a new users into community
        * @param {CzYsP14KcInvitationDtoInvitationEmails} body 
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      inviteMembers(body: CzYsP14KcInvitationDtoInvitationEmails, communityId: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling inviteMembers.');
          }
          // verify required parameter 'communityId' is not null or undefined
          if (communityId === null || communityId === undefined) {
          throw new RequiredError('communityId','Required parameter communityId was null or undefined when calling inviteMembers.');
          }
      const localVarPath = `/community/{communityId}/member`
      .replace(`{${"communityId"}}`, encodeURIComponent(String(communityId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcInvitationDtoInvitationEmails" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary The owner changes a member's membership type. If changed to OWNER, the caller loses ownership
        * @param {CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject} body 
        * @param {string} communityId 
        * @param {string} userId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      modifyMembers(body: CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject, communityId: string, userId: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling modifyMembers.');
          }
          // verify required parameter 'communityId' is not null or undefined
          if (communityId === null || communityId === undefined) {
          throw new RequiredError('communityId','Required parameter communityId was null or undefined when calling modifyMembers.');
          }
          // verify required parameter 'userId' is not null or undefined
          if (userId === null || userId === undefined) {
          throw new RequiredError('userId','Required parameter userId was null or undefined when calling modifyMembers.');
          }
      const localVarPath = `/community/{communityId}/member/{userId}`
      .replace(`{${"communityId"}}`, encodeURIComponent(String(communityId)))
      .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary User rejects invitation into community
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      rejectInvitation(communityId: string, options: any = {}): FetchArgs {
          // verify required parameter 'communityId' is not null or undefined
          if (communityId === null || communityId === undefined) {
          throw new RequiredError('communityId','Required parameter communityId was null or undefined when calling rejectInvitation.');
          }
      const localVarPath = `/community/{communityId}/invitation`
      .replace(`{${"communityId"}}`, encodeURIComponent(String(communityId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * CommunityMembershipsApi - functional programming interface
    * @export
    */
    export const CommunityMembershipsApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary The current user becomes a new member of the public, official community or applies for membership in a private community
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      addCurrentUserAsMember(communityId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CommunityMembershipsApiFetchParamCreator(configuration).addCurrentUserAsMember(communityId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary The current user removes their membership
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      deleteCurrentUserMembership(communityId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CommunityMembershipsApiFetchParamCreator(configuration).deleteCurrentUserMembership(communityId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary The owner, deputy of community removes member from the community
        * @param {string} communityId 
        * @param {string} userId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      deleteMember(communityId: string, userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CommunityMembershipsApiFetchParamCreator(configuration).deleteMember(communityId, userId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Finds community members
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getMembers(communityId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcCommunityApiDtoCommunityMembershipObject>> {
      const localVarFetchArgs = CommunityMembershipsApiFetchParamCreator(configuration).getMembers(communityId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary The owner of community invites a new users into community
        * @param {CzYsP14KcInvitationDtoInvitationEmails} body 
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      inviteMembers(body: CzYsP14KcInvitationDtoInvitationEmails, communityId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcInvitationDtoInvitationEmailsResultObject> {
      const localVarFetchArgs = CommunityMembershipsApiFetchParamCreator(configuration).inviteMembers(body, communityId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary The owner changes a member's membership type. If changed to OWNER, the caller loses ownership
        * @param {CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject} body 
        * @param {string} communityId 
        * @param {string} userId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      modifyMembers(body: CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject, communityId: string, userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CommunityMembershipsApiFetchParamCreator(configuration).modifyMembers(body, communityId, userId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary User rejects invitation into community
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      rejectInvitation(communityId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CommunityMembershipsApiFetchParamCreator(configuration).rejectInvitation(communityId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * CommunityMembershipsApi - factory interface
    * @export
    */
    export const CommunityMembershipsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary The current user becomes a new member of the public, official community or applies for membership in a private community
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      addCurrentUserAsMember(communityId: string, options?: any) {
      return CommunityMembershipsApiFp(configuration).addCurrentUserAsMember(communityId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary The current user removes their membership
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      deleteCurrentUserMembership(communityId: string, options?: any) {
      return CommunityMembershipsApiFp(configuration).deleteCurrentUserMembership(communityId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary The owner, deputy of community removes member from the community
        * @param {string} communityId 
        * @param {string} userId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      deleteMember(communityId: string, userId: string, options?: any) {
      return CommunityMembershipsApiFp(configuration).deleteMember(communityId, userId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Finds community members
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getMembers(communityId: string, options?: any) {
      return CommunityMembershipsApiFp(configuration).getMembers(communityId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary The owner of community invites a new users into community
        * @param {CzYsP14KcInvitationDtoInvitationEmails} body 
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      inviteMembers(body: CzYsP14KcInvitationDtoInvitationEmails, communityId: string, options?: any) {
      return CommunityMembershipsApiFp(configuration).inviteMembers(body, communityId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary The owner changes a member's membership type. If changed to OWNER, the caller loses ownership
        * @param {CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject} body 
        * @param {string} communityId 
        * @param {string} userId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      modifyMembers(body: CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject, communityId: string, userId: string, options?: any) {
      return CommunityMembershipsApiFp(configuration).modifyMembers(body, communityId, userId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary User rejects invitation into community
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      rejectInvitation(communityId: string, options?: any) {
      return CommunityMembershipsApiFp(configuration).rejectInvitation(communityId, options)(fetch, basePath);
      },
    };
    };

    /**
    * CommunityMembershipsApi - object-oriented interface
    * @export
    * @class CommunityMembershipsApi
    * @extends {BaseAPI}
    */
      export class CommunityMembershipsApi extends BaseAPI {
      /**
      * 
        * @summary The current user becomes a new member of the public, official community or applies for membership in a private community
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunityMembershipsApi
      */
      public addCurrentUserAsMember(communityId: string, options?: any) {
      return CommunityMembershipsApiFp(this.configuration).addCurrentUserAsMember(communityId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary The current user removes their membership
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunityMembershipsApi
      */
      public deleteCurrentUserMembership(communityId: string, options?: any) {
      return CommunityMembershipsApiFp(this.configuration).deleteCurrentUserMembership(communityId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary The owner, deputy of community removes member from the community
        * @param {string} communityId 
        * @param {string} userId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunityMembershipsApi
      */
      public deleteMember(communityId: string, userId: string, options?: any) {
      return CommunityMembershipsApiFp(this.configuration).deleteMember(communityId, userId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Finds community members
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunityMembershipsApi
      */
      public getMembers(communityId: string, options?: any) {
      return CommunityMembershipsApiFp(this.configuration).getMembers(communityId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary The owner of community invites a new users into community
        * @param {CzYsP14KcInvitationDtoInvitationEmails} body 
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunityMembershipsApi
      */
      public inviteMembers(body: CzYsP14KcInvitationDtoInvitationEmails, communityId: string, options?: any) {
      return CommunityMembershipsApiFp(this.configuration).inviteMembers(body, communityId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary The owner changes a member's membership type. If changed to OWNER, the caller loses ownership
        * @param {CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject} body 
        * @param {string} communityId 
        * @param {string} userId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunityMembershipsApi
      */
      public modifyMembers(body: CzYsP14KcCommunityApiDtoCommunityMembershipUpdateObject, communityId: string, userId: string, options?: any) {
      return CommunityMembershipsApiFp(this.configuration).modifyMembers(body, communityId, userId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary User rejects invitation into community
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof CommunityMembershipsApi
      */
      public rejectInvitation(communityId: string, options?: any) {
      return CommunityMembershipsApiFp(this.configuration).rejectInvitation(communityId, options)(this.fetch, this.basePath);
      }

    }
    /**
    * EventsApi - fetch parameter creator
    * @export
    */
    export const EventsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Deletes an event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      deleteEvent(eventId: string, options: any = {}): FetchArgs {
          // verify required parameter 'eventId' is not null or undefined
          if (eventId === null || eventId === undefined) {
          throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling deleteEvent.');
          }
      const localVarPath = `/event/{eventId}`
      .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns the detail of a whole event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getEventDetail(eventId: string, options: any = {}): FetchArgs {
          // verify required parameter 'eventId' is not null or undefined
          if (eventId === null || eventId === undefined) {
          throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling getEventDetail.');
          }
      const localVarPath = `/event/{eventId}`
      .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns the detail of a single event instance
        * @param {string} eventId 
        * @param {string} date 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getEventInstanceDetail(eventId: string, date: string, options: any = {}): FetchArgs {
          // verify required parameter 'eventId' is not null or undefined
          if (eventId === null || eventId === undefined) {
          throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling getEventInstanceDetail.');
          }
          // verify required parameter 'date' is not null or undefined
          if (date === null || date === undefined) {
          throw new RequiredError('date','Required parameter date was null or undefined when calling getEventInstanceDetail.');
          }
      const localVarPath = `/event/{eventId}/{date}`
      .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)))
      .replace(`{${"date"}}`, encodeURIComponent(String(date)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Current user adds their like to the event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      likeEvent(eventId: string, options: any = {}): FetchArgs {
          // verify required parameter 'eventId' is not null or undefined
          if (eventId === null || eventId === undefined) {
          throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling likeEvent.');
          }
      const localVarPath = `/event/{eventId}/like`
      .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns list of all current or future events
        * @param {string} [dateFrom] 
        * @param {string} [dateTo] 
        * @param {number} [wpAuthor] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listAllEvents(dateFrom?: string, dateTo?: string, wpAuthor?: number, text?: string, flags?: Array<string>, interest?: Array<string>, page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/event`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (dateFrom !== undefined) {
              localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ? (dateFrom as any).toISOString().substr(0,10) : dateFrom;
          }

          if (dateTo !== undefined) {
              localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ? (dateTo as any).toISOString().substr(0,10) : dateTo;
          }

          if (wpAuthor !== undefined) {
              localVarQueryParameter['wpAuthor'] = wpAuthor;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (flags) {
            localVarQueryParameter['flags'] = flags;
          }

          if (interest) {
            localVarQueryParameter['interest'] = interest;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns list of all current or future events
        * @param {string} [dateTo] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listAllEventsForMap(dateTo?: string, text?: string, flags?: Array<string>, interest?: Array<string>, options: any = {}): FetchArgs {
      const localVarPath = `/event/map`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (dateTo !== undefined) {
              localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ? (dateTo as any).toISOString().substr(0,10) : dateTo;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (flags) {
            localVarQueryParameter['flags'] = flags;
          }

          if (interest) {
            localVarQueryParameter['interest'] = interest;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns list of all current or future events without paging (suitable for calendar view)
        * @param {string} dateFrom 
        * @param {string} dateTo 
        * @param {number} [wpAuthor] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listAllEventsUnpaged(dateFrom: string, dateTo: string, wpAuthor?: number, text?: string, flags?: Array<string>, interest?: Array<string>, options: any = {}): FetchArgs {
          // verify required parameter 'dateFrom' is not null or undefined
          if (dateFrom === null || dateFrom === undefined) {
          throw new RequiredError('dateFrom','Required parameter dateFrom was null or undefined when calling listAllEventsUnpaged.');
          }
          // verify required parameter 'dateTo' is not null or undefined
          if (dateTo === null || dateTo === undefined) {
          throw new RequiredError('dateTo','Required parameter dateTo was null or undefined when calling listAllEventsUnpaged.');
          }
      const localVarPath = `/event/calendar`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (dateFrom !== undefined) {
              localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ? (dateFrom as any).toISOString().substr(0,10) : dateFrom;
          }

          if (dateTo !== undefined) {
              localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ? (dateTo as any).toISOString().substr(0,10) : dateTo;
          }

          if (wpAuthor !== undefined) {
              localVarQueryParameter['wpAuthor'] = wpAuthor;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (flags) {
            localVarQueryParameter['flags'] = flags;
          }

          if (interest) {
            localVarQueryParameter['interest'] = interest;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Promotes an event. The notification is sent everytime to all (also anonymous) users with mobile app.
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      promoEvent(eventId: string, options: any = {}): FetchArgs {
          // verify required parameter 'eventId' is not null or undefined
          if (eventId === null || eventId === undefined) {
          throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling promoEvent.');
          }
      const localVarPath = `/event/{eventId}/promo`
      .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Current user reports event as toxic
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      toxicEvent(eventId: string, options: any = {}): FetchArgs {
          // verify required parameter 'eventId' is not null or undefined
          if (eventId === null || eventId === undefined) {
          throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling toxicEvent.');
          }
      const localVarPath = `/event/{eventId}/toxic`
      .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Current user removes their like from the event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      unlikeEvent(eventId: string, options: any = {}): FetchArgs {
          // verify required parameter 'eventId' is not null or undefined
          if (eventId === null || eventId === undefined) {
          throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling unlikeEvent.');
          }
      const localVarPath = `/event/{eventId}/unlike`
      .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Updates an event
        * @param {CzYsP14KcEventApiDtoEventUpdateObject} body 
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      updateEvent(body: CzYsP14KcEventApiDtoEventUpdateObject, eventId: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling updateEvent.');
          }
          // verify required parameter 'eventId' is not null or undefined
          if (eventId === null || eventId === undefined) {
          throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling updateEvent.');
          }
      const localVarPath = `/event/{eventId}`
      .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcEventApiDtoEventUpdateObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * EventsApi - functional programming interface
    * @export
    */
    export const EventsApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Deletes an event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      deleteEvent(eventId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = EventsApiFetchParamCreator(configuration).deleteEvent(eventId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns the detail of a whole event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getEventDetail(eventId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcEventApiDtoEventDetailObject> {
      const localVarFetchArgs = EventsApiFetchParamCreator(configuration).getEventDetail(eventId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns the detail of a single event instance
        * @param {string} eventId 
        * @param {string} date 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getEventInstanceDetail(eventId: string, date: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcEventApiDtoEventInstanceDetailObject> {
      const localVarFetchArgs = EventsApiFetchParamCreator(configuration).getEventInstanceDetail(eventId, date, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Current user adds their like to the event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      likeEvent(eventId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = EventsApiFetchParamCreator(configuration).likeEvent(eventId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns list of all current or future events
        * @param {string} [dateFrom] 
        * @param {string} [dateTo] 
        * @param {number} [wpAuthor] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listAllEvents(dateFrom?: string, dateTo?: string, wpAuthor?: number, text?: string, flags?: Array<string>, interest?: Array<string>, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject> {
      const localVarFetchArgs = EventsApiFetchParamCreator(configuration).listAllEvents(dateFrom, dateTo, wpAuthor, text, flags, interest, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns list of all current or future events
        * @param {string} [dateTo] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listAllEventsForMap(dateTo?: string, text?: string, flags?: Array<string>, interest?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcEventApiDtoEventInstanceForMapObject>> {
      const localVarFetchArgs = EventsApiFetchParamCreator(configuration).listAllEventsForMap(dateTo, text, flags, interest, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns list of all current or future events without paging (suitable for calendar view)
        * @param {string} dateFrom 
        * @param {string} dateTo 
        * @param {number} [wpAuthor] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listAllEventsUnpaged(dateFrom: string, dateTo: string, wpAuthor?: number, text?: string, flags?: Array<string>, interest?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcEventApiDtoEventInstanceObject> {
      const localVarFetchArgs = EventsApiFetchParamCreator(configuration).listAllEventsUnpaged(dateFrom, dateTo, wpAuthor, text, flags, interest, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Promotes an event. The notification is sent everytime to all (also anonymous) users with mobile app.
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      promoEvent(eventId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = EventsApiFetchParamCreator(configuration).promoEvent(eventId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Current user reports event as toxic
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      toxicEvent(eventId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = EventsApiFetchParamCreator(configuration).toxicEvent(eventId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Current user removes their like from the event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      unlikeEvent(eventId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = EventsApiFetchParamCreator(configuration).unlikeEvent(eventId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Updates an event
        * @param {CzYsP14KcEventApiDtoEventUpdateObject} body 
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateEvent(body: CzYsP14KcEventApiDtoEventUpdateObject, eventId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = EventsApiFetchParamCreator(configuration).updateEvent(body, eventId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * EventsApi - factory interface
    * @export
    */
    export const EventsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Deletes an event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      deleteEvent(eventId: string, options?: any) {
      return EventsApiFp(configuration).deleteEvent(eventId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns the detail of a whole event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getEventDetail(eventId: string, options?: any) {
      return EventsApiFp(configuration).getEventDetail(eventId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns the detail of a single event instance
        * @param {string} eventId 
        * @param {string} date 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getEventInstanceDetail(eventId: string, date: string, options?: any) {
      return EventsApiFp(configuration).getEventInstanceDetail(eventId, date, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Current user adds their like to the event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      likeEvent(eventId: string, options?: any) {
      return EventsApiFp(configuration).likeEvent(eventId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns list of all current or future events
        * @param {string} [dateFrom] 
        * @param {string} [dateTo] 
        * @param {number} [wpAuthor] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listAllEvents(dateFrom?: string, dateTo?: string, wpAuthor?: number, text?: string, flags?: Array<string>, interest?: Array<string>, page?: number, size?: number, options?: any) {
      return EventsApiFp(configuration).listAllEvents(dateFrom, dateTo, wpAuthor, text, flags, interest, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns list of all current or future events
        * @param {string} [dateTo] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listAllEventsForMap(dateTo?: string, text?: string, flags?: Array<string>, interest?: Array<string>, options?: any) {
      return EventsApiFp(configuration).listAllEventsForMap(dateTo, text, flags, interest, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns list of all current or future events without paging (suitable for calendar view)
        * @param {string} dateFrom 
        * @param {string} dateTo 
        * @param {number} [wpAuthor] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listAllEventsUnpaged(dateFrom: string, dateTo: string, wpAuthor?: number, text?: string, flags?: Array<string>, interest?: Array<string>, options?: any) {
      return EventsApiFp(configuration).listAllEventsUnpaged(dateFrom, dateTo, wpAuthor, text, flags, interest, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Promotes an event. The notification is sent everytime to all (also anonymous) users with mobile app.
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      promoEvent(eventId: string, options?: any) {
      return EventsApiFp(configuration).promoEvent(eventId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Current user reports event as toxic
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      toxicEvent(eventId: string, options?: any) {
      return EventsApiFp(configuration).toxicEvent(eventId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Current user removes their like from the event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      unlikeEvent(eventId: string, options?: any) {
      return EventsApiFp(configuration).unlikeEvent(eventId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Updates an event
        * @param {CzYsP14KcEventApiDtoEventUpdateObject} body 
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateEvent(body: CzYsP14KcEventApiDtoEventUpdateObject, eventId: string, options?: any) {
      return EventsApiFp(configuration).updateEvent(body, eventId, options)(fetch, basePath);
      },
    };
    };

    /**
    * EventsApi - object-oriented interface
    * @export
    * @class EventsApi
    * @extends {BaseAPI}
    */
      export class EventsApi extends BaseAPI {
      /**
      * 
        * @summary Deletes an event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof EventsApi
      */
      public deleteEvent(eventId: string, options?: any) {
      return EventsApiFp(this.configuration).deleteEvent(eventId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns the detail of a whole event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof EventsApi
      */
      public getEventDetail(eventId: string, options?: any) {
      return EventsApiFp(this.configuration).getEventDetail(eventId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns the detail of a single event instance
        * @param {string} eventId 
        * @param {string} date 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof EventsApi
      */
      public getEventInstanceDetail(eventId: string, date: string, options?: any) {
      return EventsApiFp(this.configuration).getEventInstanceDetail(eventId, date, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Current user adds their like to the event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof EventsApi
      */
      public likeEvent(eventId: string, options?: any) {
      return EventsApiFp(this.configuration).likeEvent(eventId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns list of all current or future events
        * @param {string} [dateFrom] 
        * @param {string} [dateTo] 
        * @param {number} [wpAuthor] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof EventsApi
      */
      public listAllEvents(dateFrom?: string, dateTo?: string, wpAuthor?: number, text?: string, flags?: Array<string>, interest?: Array<string>, page?: number, size?: number, options?: any) {
      return EventsApiFp(this.configuration).listAllEvents(dateFrom, dateTo, wpAuthor, text, flags, interest, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns list of all current or future events
        * @param {string} [dateTo] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof EventsApi
      */
      public listAllEventsForMap(dateTo?: string, text?: string, flags?: Array<string>, interest?: Array<string>, options?: any) {
      return EventsApiFp(this.configuration).listAllEventsForMap(dateTo, text, flags, interest, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns list of all current or future events without paging (suitable for calendar view)
        * @param {string} dateFrom 
        * @param {string} dateTo 
        * @param {number} [wpAuthor] 
        * @param {string} [text] 
        * @param {Array<string>} [flags] 
        * @param {Array<string>} [interest] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof EventsApi
      */
      public listAllEventsUnpaged(dateFrom: string, dateTo: string, wpAuthor?: number, text?: string, flags?: Array<string>, interest?: Array<string>, options?: any) {
      return EventsApiFp(this.configuration).listAllEventsUnpaged(dateFrom, dateTo, wpAuthor, text, flags, interest, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Promotes an event. The notification is sent everytime to all (also anonymous) users with mobile app.
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof EventsApi
      */
      public promoEvent(eventId: string, options?: any) {
      return EventsApiFp(this.configuration).promoEvent(eventId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Current user reports event as toxic
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof EventsApi
      */
      public toxicEvent(eventId: string, options?: any) {
      return EventsApiFp(this.configuration).toxicEvent(eventId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Current user removes their like from the event
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof EventsApi
      */
      public unlikeEvent(eventId: string, options?: any) {
      return EventsApiFp(this.configuration).unlikeEvent(eventId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Updates an event
        * @param {CzYsP14KcEventApiDtoEventUpdateObject} body 
        * @param {string} eventId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof EventsApi
      */
      public updateEvent(body: CzYsP14KcEventApiDtoEventUpdateObject, eventId: string, options?: any) {
      return EventsApiFp(this.configuration).updateEvent(body, eventId, options)(this.fetch, this.basePath);
      }

    }
    /**
    * FeedApi - fetch parameter creator
    * @export
    */
    export const FeedApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns the list of all feed entries (posts, surveys).
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getAll(interest?: Array<string>, text?: string, page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/feed`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (interest) {
            localVarQueryParameter['interest'] = interest;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns a personalized list of all feed entries (posts, surveys). The first page can contain special items.
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getAllPersonal(interest?: Array<string>, text?: string, page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/feed/personal`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (interest) {
            localVarQueryParameter['interest'] = interest;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns all surveys of city district (default community)
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getCityDistrictSurvey(page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/feed/survey/city-district`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * FeedApi - functional programming interface
    * @export
    */
    export const FeedApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns the list of all feed entries (posts, surveys).
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getAll(interest?: Array<string>, text?: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject> {
      const localVarFetchArgs = FeedApiFetchParamCreator(configuration).getAll(interest, text, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns a personalized list of all feed entries (posts, surveys). The first page can contain special items.
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getAllPersonal(interest?: Array<string>, text?: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject> {
      const localVarFetchArgs = FeedApiFetchParamCreator(configuration).getAllPersonal(interest, text, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns all surveys of city district (default community)
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getCityDistrictSurvey(page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject> {
      const localVarFetchArgs = FeedApiFetchParamCreator(configuration).getCityDistrictSurvey(page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * FeedApi - factory interface
    * @export
    */
    export const FeedApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns the list of all feed entries (posts, surveys).
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getAll(interest?: Array<string>, text?: string, page?: number, size?: number, options?: any) {
      return FeedApiFp(configuration).getAll(interest, text, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns a personalized list of all feed entries (posts, surveys). The first page can contain special items.
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getAllPersonal(interest?: Array<string>, text?: string, page?: number, size?: number, options?: any) {
      return FeedApiFp(configuration).getAllPersonal(interest, text, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns all surveys of city district (default community)
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getCityDistrictSurvey(page?: number, size?: number, options?: any) {
      return FeedApiFp(configuration).getCityDistrictSurvey(page, size, options)(fetch, basePath);
      },
    };
    };

    /**
    * FeedApi - object-oriented interface
    * @export
    * @class FeedApi
    * @extends {BaseAPI}
    */
      export class FeedApi extends BaseAPI {
      /**
      * 
        * @summary Returns the list of all feed entries (posts, surveys).
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof FeedApi
      */
      public getAll(interest?: Array<string>, text?: string, page?: number, size?: number, options?: any) {
      return FeedApiFp(this.configuration).getAll(interest, text, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns a personalized list of all feed entries (posts, surveys). The first page can contain special items.
        * @param {Array<string>} [interest] 
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof FeedApi
      */
      public getAllPersonal(interest?: Array<string>, text?: string, page?: number, size?: number, options?: any) {
      return FeedApiFp(this.configuration).getAllPersonal(interest, text, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns all surveys of city district (default community)
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof FeedApi
      */
      public getCityDistrictSurvey(page?: number, size?: number, options?: any) {
      return FeedApiFp(this.configuration).getCityDistrictSurvey(page, size, options)(this.fetch, this.basePath);
      }

    }
    /**
    * FirebaseCloudMessagingApi - fetch parameter creator
    * @export
    */
    export const FirebaseCloudMessagingApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Deletes an user device token
        * @param {CzYsP14KcFcmApiDtoFcmTokenCreationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      deleteToken(body: CzYsP14KcFcmApiDtoFcmTokenCreationObject, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling deleteToken.');
          }
      const localVarPath = `/fcm/token`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcFcmApiDtoFcmTokenCreationObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Registers an user device token
        * @param {CzYsP14KcFcmApiDtoFcmTokenCreationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      postToken(body: CzYsP14KcFcmApiDtoFcmTokenCreationObject, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling postToken.');
          }
      const localVarPath = `/fcm/token`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcFcmApiDtoFcmTokenCreationObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * FirebaseCloudMessagingApi - functional programming interface
    * @export
    */
    export const FirebaseCloudMessagingApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Deletes an user device token
        * @param {CzYsP14KcFcmApiDtoFcmTokenCreationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      deleteToken(body: CzYsP14KcFcmApiDtoFcmTokenCreationObject, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = FirebaseCloudMessagingApiFetchParamCreator(configuration).deleteToken(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Registers an user device token
        * @param {CzYsP14KcFcmApiDtoFcmTokenCreationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      postToken(body: CzYsP14KcFcmApiDtoFcmTokenCreationObject, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = FirebaseCloudMessagingApiFetchParamCreator(configuration).postToken(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * FirebaseCloudMessagingApi - factory interface
    * @export
    */
    export const FirebaseCloudMessagingApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Deletes an user device token
        * @param {CzYsP14KcFcmApiDtoFcmTokenCreationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      deleteToken(body: CzYsP14KcFcmApiDtoFcmTokenCreationObject, options?: any) {
      return FirebaseCloudMessagingApiFp(configuration).deleteToken(body, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Registers an user device token
        * @param {CzYsP14KcFcmApiDtoFcmTokenCreationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      postToken(body: CzYsP14KcFcmApiDtoFcmTokenCreationObject, options?: any) {
      return FirebaseCloudMessagingApiFp(configuration).postToken(body, options)(fetch, basePath);
      },
    };
    };

    /**
    * FirebaseCloudMessagingApi - object-oriented interface
    * @export
    * @class FirebaseCloudMessagingApi
    * @extends {BaseAPI}
    */
      export class FirebaseCloudMessagingApi extends BaseAPI {
      /**
      * 
        * @summary Deletes an user device token
        * @param {CzYsP14KcFcmApiDtoFcmTokenCreationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof FirebaseCloudMessagingApi
      */
      public deleteToken(body: CzYsP14KcFcmApiDtoFcmTokenCreationObject, options?: any) {
      return FirebaseCloudMessagingApiFp(this.configuration).deleteToken(body, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Registers an user device token
        * @param {CzYsP14KcFcmApiDtoFcmTokenCreationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof FirebaseCloudMessagingApi
      */
      public postToken(body: CzYsP14KcFcmApiDtoFcmTokenCreationObject, options?: any) {
      return FirebaseCloudMessagingApiFp(this.configuration).postToken(body, options)(this.fetch, this.basePath);
      }

    }
    /**
    * HelpDeskApi - fetch parameter creator
    * @export
    */
    export const HelpDeskApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary List current user's help desk tickets
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getMyTickets(options: any = {}): FetchArgs {
      const localVarPath = `/help-desk/ticket/my`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Get the detail of a ticket
        * @param {string} id ticket ID
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getTicketDetail(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getTicketDetail.');
          }
      const localVarPath = `/help-desk/ticket/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary List help desk tickets
        * @param {string} [from] only applies to resolved tickets
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getTickets(from?: string, page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/help-desk/ticket`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

          if (from !== undefined) {
            localVarQueryParameter['from'] = (from as any instanceof Date) ? (from as any).toISOString() : from;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Submit a new help desk ticket
        * @param {CzYsP14KcHelpdeskApiDtoTicketCreateObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      submitTicket(body: CzYsP14KcHelpdeskApiDtoTicketCreateObject, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling submitTicket.');
          }
      const localVarPath = `/help-desk/ticket`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcHelpdeskApiDtoTicketCreateObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * HelpDeskApi - functional programming interface
    * @export
    */
    export const HelpDeskApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary List current user's help desk tickets
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getMyTickets(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = HelpDeskApiFetchParamCreator(configuration).getMyTickets(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Get the detail of a ticket
        * @param {string} id ticket ID
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getTicketDetail(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = HelpDeskApiFetchParamCreator(configuration).getTicketDetail(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary List help desk tickets
        * @param {string} [from] only applies to resolved tickets
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getTickets(from?: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcHelpdeskApiDtoTicketItemObject>> {
      const localVarFetchArgs = HelpDeskApiFetchParamCreator(configuration).getTickets(from, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Submit a new help desk ticket
        * @param {CzYsP14KcHelpdeskApiDtoTicketCreateObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      submitTicket(body: CzYsP14KcHelpdeskApiDtoTicketCreateObject, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
      const localVarFetchArgs = HelpDeskApiFetchParamCreator(configuration).submitTicket(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * HelpDeskApi - factory interface
    * @export
    */
    export const HelpDeskApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary List current user's help desk tickets
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getMyTickets(options?: any) {
      return HelpDeskApiFp(configuration).getMyTickets(options)(fetch, basePath);
      },
      /**
      * 
        * @summary Get the detail of a ticket
        * @param {string} id ticket ID
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getTicketDetail(id: string, options?: any) {
      return HelpDeskApiFp(configuration).getTicketDetail(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary List help desk tickets
        * @param {string} [from] only applies to resolved tickets
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getTickets(from?: string, page?: number, size?: number, options?: any) {
      return HelpDeskApiFp(configuration).getTickets(from, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Submit a new help desk ticket
        * @param {CzYsP14KcHelpdeskApiDtoTicketCreateObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      submitTicket(body: CzYsP14KcHelpdeskApiDtoTicketCreateObject, options?: any) {
      return HelpDeskApiFp(configuration).submitTicket(body, options)(fetch, basePath);
      },
    };
    };

    /**
    * HelpDeskApi - object-oriented interface
    * @export
    * @class HelpDeskApi
    * @extends {BaseAPI}
    */
      export class HelpDeskApi extends BaseAPI {
      /**
      * 
        * @summary List current user's help desk tickets
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof HelpDeskApi
      */
      public getMyTickets(options?: any) {
      return HelpDeskApiFp(this.configuration).getMyTickets(options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Get the detail of a ticket
        * @param {string} id ticket ID
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof HelpDeskApi
      */
      public getTicketDetail(id: string, options?: any) {
      return HelpDeskApiFp(this.configuration).getTicketDetail(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary List help desk tickets
        * @param {string} [from] only applies to resolved tickets
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof HelpDeskApi
      */
      public getTickets(from?: string, page?: number, size?: number, options?: any) {
      return HelpDeskApiFp(this.configuration).getTickets(from, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Submit a new help desk ticket
        * @param {CzYsP14KcHelpdeskApiDtoTicketCreateObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof HelpDeskApi
      */
      public submitTicket(body: CzYsP14KcHelpdeskApiDtoTicketCreateObject, options?: any) {
      return HelpDeskApiFp(this.configuration).submitTicket(body, options)(this.fetch, this.basePath);
      }

    }
    /**
    * InvitationsApi - fetch parameter creator
    * @export
    */
    export const InvitationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Process personal community invitation link
        * @param {string} invitationId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      acceptInvitationIntoCommunity(invitationId: string, options: any = {}): FetchArgs {
          // verify required parameter 'invitationId' is not null or undefined
          if (invitationId === null || invitationId === undefined) {
          throw new RequiredError('invitationId','Required parameter invitationId was null or undefined when calling acceptInvitationIntoCommunity.');
          }
      const localVarPath = `/invitation/{invitationId}`
      .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * InvitationsApi - functional programming interface
    * @export
    */
    export const InvitationsApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Process personal community invitation link
        * @param {string} invitationId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      acceptInvitationIntoCommunity(invitationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
      const localVarFetchArgs = InvitationsApiFetchParamCreator(configuration).acceptInvitationIntoCommunity(invitationId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * InvitationsApi - factory interface
    * @export
    */
    export const InvitationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Process personal community invitation link
        * @param {string} invitationId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      acceptInvitationIntoCommunity(invitationId: string, options?: any) {
      return InvitationsApiFp(configuration).acceptInvitationIntoCommunity(invitationId, options)(fetch, basePath);
      },
    };
    };

    /**
    * InvitationsApi - object-oriented interface
    * @export
    * @class InvitationsApi
    * @extends {BaseAPI}
    */
      export class InvitationsApi extends BaseAPI {
      /**
      * 
        * @summary Process personal community invitation link
        * @param {string} invitationId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof InvitationsApi
      */
      public acceptInvitationIntoCommunity(invitationId: string, options?: any) {
      return InvitationsApiFp(this.configuration).acceptInvitationIntoCommunity(invitationId, options)(this.fetch, this.basePath);
      }

    }
    /**
    * MessagesApi - fetch parameter creator
    * @export
    */
    export const MessagesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Detail of notification message
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getNotificationMessage(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getNotificationMessage.');
          }
      const localVarPath = `/message/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * MessagesApi - functional programming interface
    * @export
    */
    export const MessagesApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Detail of notification message
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getNotificationMessage(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcNotificationApiDtoNotificationMessageObject> {
      const localVarFetchArgs = MessagesApiFetchParamCreator(configuration).getNotificationMessage(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * MessagesApi - factory interface
    * @export
    */
    export const MessagesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Detail of notification message
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getNotificationMessage(id: string, options?: any) {
      return MessagesApiFp(configuration).getNotificationMessage(id, options)(fetch, basePath);
      },
    };
    };

    /**
    * MessagesApi - object-oriented interface
    * @export
    * @class MessagesApi
    * @extends {BaseAPI}
    */
      export class MessagesApi extends BaseAPI {
      /**
      * 
        * @summary Detail of notification message
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof MessagesApi
      */
      public getNotificationMessage(id: string, options?: any) {
      return MessagesApiFp(this.configuration).getNotificationMessage(id, options)(this.fetch, this.basePath);
      }

    }
    /**
    * NotificationsApi - fetch parameter creator
    * @export
    */
    export const NotificationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns number of unread notifications for the current user
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      countUnread(options: any = {}): FetchArgs {
      const localVarPath = `/notification/unread/count`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Lists notification for the current user no older than 30 days
        * @param {boolean} [read] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getNotificationsForUser(read?: boolean, page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/notification`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (read !== undefined) {
              localVarQueryParameter['read'] = read;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Marks all user notifications as read
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      markAllAsRead(options: any = {}): FetchArgs {
      const localVarPath = `/notification/markAllAsRead`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Marks user's notification as read by notification group id
        * @param {string} groupId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      markNotificationReadByGroupId(groupId: string, options: any = {}): FetchArgs {
          // verify required parameter 'groupId' is not null or undefined
          if (groupId === null || groupId === undefined) {
          throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling markNotificationReadByGroupId.');
          }
      const localVarPath = `/notification/group-id/{groupId}`
      .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Marks user's notification as read
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      markNotificationReadById(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling markNotificationReadById.');
          }
      const localVarPath = `/notification/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * NotificationsApi - functional programming interface
    * @export
    */
    export const NotificationsApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns number of unread notifications for the current user
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      countUnread(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
      const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).countUnread(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Lists notification for the current user no older than 30 days
        * @param {boolean} [read] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getNotificationsForUser(read?: boolean, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcNotificationApiDtoNotificationObject> {
      const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).getNotificationsForUser(read, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Marks all user notifications as read
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      markAllAsRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).markAllAsRead(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Marks user's notification as read by notification group id
        * @param {string} groupId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      markNotificationReadByGroupId(groupId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).markNotificationReadByGroupId(groupId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Marks user's notification as read
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      markNotificationReadById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).markNotificationReadById(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * NotificationsApi - factory interface
    * @export
    */
    export const NotificationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns number of unread notifications for the current user
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      countUnread(options?: any) {
      return NotificationsApiFp(configuration).countUnread(options)(fetch, basePath);
      },
      /**
      * 
        * @summary Lists notification for the current user no older than 30 days
        * @param {boolean} [read] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getNotificationsForUser(read?: boolean, page?: number, size?: number, options?: any) {
      return NotificationsApiFp(configuration).getNotificationsForUser(read, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Marks all user notifications as read
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      markAllAsRead(options?: any) {
      return NotificationsApiFp(configuration).markAllAsRead(options)(fetch, basePath);
      },
      /**
      * 
        * @summary Marks user's notification as read by notification group id
        * @param {string} groupId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      markNotificationReadByGroupId(groupId: string, options?: any) {
      return NotificationsApiFp(configuration).markNotificationReadByGroupId(groupId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Marks user's notification as read
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      markNotificationReadById(id: string, options?: any) {
      return NotificationsApiFp(configuration).markNotificationReadById(id, options)(fetch, basePath);
      },
    };
    };

    /**
    * NotificationsApi - object-oriented interface
    * @export
    * @class NotificationsApi
    * @extends {BaseAPI}
    */
      export class NotificationsApi extends BaseAPI {
      /**
      * 
        * @summary Returns number of unread notifications for the current user
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof NotificationsApi
      */
      public countUnread(options?: any) {
      return NotificationsApiFp(this.configuration).countUnread(options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Lists notification for the current user no older than 30 days
        * @param {boolean} [read] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof NotificationsApi
      */
      public getNotificationsForUser(read?: boolean, page?: number, size?: number, options?: any) {
      return NotificationsApiFp(this.configuration).getNotificationsForUser(read, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Marks all user notifications as read
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof NotificationsApi
      */
      public markAllAsRead(options?: any) {
      return NotificationsApiFp(this.configuration).markAllAsRead(options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Marks user's notification as read by notification group id
        * @param {string} groupId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof NotificationsApi
      */
      public markNotificationReadByGroupId(groupId: string, options?: any) {
      return NotificationsApiFp(this.configuration).markNotificationReadByGroupId(groupId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Marks user's notification as read
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof NotificationsApi
      */
      public markNotificationReadById(id: string, options?: any) {
      return NotificationsApiFp(this.configuration).markNotificationReadById(id, options)(this.fetch, this.basePath);
      }

    }
    /**
    * OperationalInformationApi - fetch parameter creator
    * @export
    */
    export const OperationalInformationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Lists active, today and future operational information
        * @param {string} [range] 
        * @param {string} [text] 
        * @param {Array<string>} [category] 
        * @param {string} [extSource] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      findActiveTodayAndFutureOperationalInfo(range?: string, text?: string, category?: Array<string>, extSource?: string, page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/operational`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

          if (range !== undefined) {
              localVarQueryParameter['range'] = range;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (category) {
            localVarQueryParameter['category'] = category;
          }

          if (extSource !== undefined) {
              localVarQueryParameter['extSource'] = extSource;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns all valid operational info categories
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getOperationalCategories(options: any = {}): FetchArgs {
      const localVarPath = `/operational/categories`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns details of operational info categories with number of actual, new ones and all entries
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getOperationalCategoriesInfo(options: any = {}): FetchArgs {
      const localVarPath = `/operational/categories/info`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns operational information
        * @param {string} id 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getOperationalInfo(id: string, page?: number, size?: number, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getOperationalInfo.');
          }
      const localVarPath = `/operational/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * OperationalInformationApi - functional programming interface
    * @export
    */
    export const OperationalInformationApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Lists active, today and future operational information
        * @param {string} [range] 
        * @param {string} [text] 
        * @param {Array<string>} [category] 
        * @param {string} [extSource] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      findActiveTodayAndFutureOperationalInfo(range?: string, text?: string, category?: Array<string>, extSource?: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcOperationalApiDtoOperationalInfoObject> {
      const localVarFetchArgs = OperationalInformationApiFetchParamCreator(configuration).findActiveTodayAndFutureOperationalInfo(range, text, category, extSource, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns all valid operational info categories
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getOperationalCategories(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
      const localVarFetchArgs = OperationalInformationApiFetchParamCreator(configuration).getOperationalCategories(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns details of operational info categories with number of actual, new ones and all entries
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getOperationalCategoriesInfo(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcOperationalApiDtoOperationalInfoCategoryObject>> {
      const localVarFetchArgs = OperationalInformationApiFetchParamCreator(configuration).getOperationalCategoriesInfo(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns operational information
        * @param {string} id 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getOperationalInfo(id: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcOperationalApiDtoOperationalInfoObject> {
      const localVarFetchArgs = OperationalInformationApiFetchParamCreator(configuration).getOperationalInfo(id, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * OperationalInformationApi - factory interface
    * @export
    */
    export const OperationalInformationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Lists active, today and future operational information
        * @param {string} [range] 
        * @param {string} [text] 
        * @param {Array<string>} [category] 
        * @param {string} [extSource] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      findActiveTodayAndFutureOperationalInfo(range?: string, text?: string, category?: Array<string>, extSource?: string, page?: number, size?: number, options?: any) {
      return OperationalInformationApiFp(configuration).findActiveTodayAndFutureOperationalInfo(range, text, category, extSource, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns all valid operational info categories
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getOperationalCategories(options?: any) {
      return OperationalInformationApiFp(configuration).getOperationalCategories(options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns details of operational info categories with number of actual, new ones and all entries
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getOperationalCategoriesInfo(options?: any) {
      return OperationalInformationApiFp(configuration).getOperationalCategoriesInfo(options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns operational information
        * @param {string} id 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getOperationalInfo(id: string, page?: number, size?: number, options?: any) {
      return OperationalInformationApiFp(configuration).getOperationalInfo(id, page, size, options)(fetch, basePath);
      },
    };
    };

    /**
    * OperationalInformationApi - object-oriented interface
    * @export
    * @class OperationalInformationApi
    * @extends {BaseAPI}
    */
      export class OperationalInformationApi extends BaseAPI {
      /**
      * 
        * @summary Lists active, today and future operational information
        * @param {string} [range] 
        * @param {string} [text] 
        * @param {Array<string>} [category] 
        * @param {string} [extSource] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof OperationalInformationApi
      */
      public findActiveTodayAndFutureOperationalInfo(range?: string, text?: string, category?: Array<string>, extSource?: string, page?: number, size?: number, options?: any) {
      return OperationalInformationApiFp(this.configuration).findActiveTodayAndFutureOperationalInfo(range, text, category, extSource, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns all valid operational info categories
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof OperationalInformationApi
      */
      public getOperationalCategories(options?: any) {
      return OperationalInformationApiFp(this.configuration).getOperationalCategories(options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns details of operational info categories with number of actual, new ones and all entries
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof OperationalInformationApi
      */
      public getOperationalCategoriesInfo(options?: any) {
      return OperationalInformationApiFp(this.configuration).getOperationalCategoriesInfo(options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns operational information
        * @param {string} id 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof OperationalInformationApi
      */
      public getOperationalInfo(id: string, page?: number, size?: number, options?: any) {
      return OperationalInformationApiFp(this.configuration).getOperationalInfo(id, page, size, options)(this.fetch, this.basePath);
      }

    }
    /**
    * PlacesApi - fetch parameter creator
    * @export
    */
    export const PlacesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Lists all places starting with the provided name for one community
        * @param {string} name 
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      findCommunityPlace(name: string, communityId: string, options: any = {}): FetchArgs {
          // verify required parameter 'name' is not null or undefined
          if (name === null || name === undefined) {
          throw new RequiredError('name','Required parameter name was null or undefined when calling findCommunityPlace.');
          }
          // verify required parameter 'communityId' is not null or undefined
          if (communityId === null || communityId === undefined) {
          throw new RequiredError('communityId','Required parameter communityId was null or undefined when calling findCommunityPlace.');
          }
      const localVarPath = `/community/{communityId}/place`
      .replace(`{${"communityId"}}`, encodeURIComponent(String(communityId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (name !== undefined) {
              localVarQueryParameter['name'] = name;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * PlacesApi - functional programming interface
    * @export
    */
    export const PlacesApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Lists all places starting with the provided name for one community
        * @param {string} name 
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      findCommunityPlace(name: string, communityId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcPlaceApiDtoPlaceDetailObject>> {
      const localVarFetchArgs = PlacesApiFetchParamCreator(configuration).findCommunityPlace(name, communityId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * PlacesApi - factory interface
    * @export
    */
    export const PlacesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Lists all places starting with the provided name for one community
        * @param {string} name 
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      findCommunityPlace(name: string, communityId: string, options?: any) {
      return PlacesApiFp(configuration).findCommunityPlace(name, communityId, options)(fetch, basePath);
      },
    };
    };

    /**
    * PlacesApi - object-oriented interface
    * @export
    * @class PlacesApi
    * @extends {BaseAPI}
    */
      export class PlacesApi extends BaseAPI {
      /**
      * 
        * @summary Lists all places starting with the provided name for one community
        * @param {string} name 
        * @param {string} communityId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof PlacesApi
      */
      public findCommunityPlace(name: string, communityId: string, options?: any) {
      return PlacesApiFp(this.configuration).findCommunityPlace(name, communityId, options)(this.fetch, this.basePath);
      }

    }
    /**
    * PostsApi - fetch parameter creator
    * @export
    */
    export const PostsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns post
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getPost(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getPost.');
          }
      const localVarPath = `/post/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Current user adds their like to the post
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      likePost(postId: string, options: any = {}): FetchArgs {
          // verify required parameter 'postId' is not null or undefined
          if (postId === null || postId === undefined) {
          throw new RequiredError('postId','Required parameter postId was null or undefined when calling likePost.');
          }
      const localVarPath = `/post/{postId}/like`
      .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns list of all posts
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listAllPosts(text?: string, page?: number, size?: number, options: any = {}): FetchArgs {
      const localVarPath = `/post`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          if (text !== undefined) {
              localVarQueryParameter['text'] = text;
          }

          if (page !== undefined) {
              localVarQueryParameter['page'] = page;
          }

          if (size !== undefined) {
              localVarQueryParameter['size'] = size;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Promotes a post. The notification is everytime sent to all (also anonymous) users with mobile app.
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      promoPost(postId: string, options: any = {}): FetchArgs {
          // verify required parameter 'postId' is not null or undefined
          if (postId === null || postId === undefined) {
          throw new RequiredError('postId','Required parameter postId was null or undefined when calling promoPost.');
          }
      const localVarPath = `/post/{postId}/promo`
      .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Current user reports post as toxic
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      toxicPost(postId: string, options: any = {}): FetchArgs {
          // verify required parameter 'postId' is not null or undefined
          if (postId === null || postId === undefined) {
          throw new RequiredError('postId','Required parameter postId was null or undefined when calling toxicPost.');
          }
      const localVarPath = `/post/{postId}/toxic`
      .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Current user removes their like from the post
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      unlikePost(postId: string, options: any = {}): FetchArgs {
          // verify required parameter 'postId' is not null or undefined
          if (postId === null || postId === undefined) {
          throw new RequiredError('postId','Required parameter postId was null or undefined when calling unlikePost.');
          }
      const localVarPath = `/post/{postId}/unlike`
      .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Updates a post
        * @param {CzYsP14KcPostApiDtoPostUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      updatePost(body: CzYsP14KcPostApiDtoPostUpdateObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling updatePost.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling updatePost.');
          }
      const localVarPath = `/post/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcPostApiDtoPostUpdateObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * PostsApi - functional programming interface
    * @export
    */
    export const PostsApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns post
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getPost(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcPostApiDtoPostObject> {
      const localVarFetchArgs = PostsApiFetchParamCreator(configuration).getPost(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Current user adds their like to the post
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      likePost(postId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = PostsApiFetchParamCreator(configuration).likePost(postId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns list of all posts
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listAllPosts(text?: string, page?: number, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoPostItemObject> {
      const localVarFetchArgs = PostsApiFetchParamCreator(configuration).listAllPosts(text, page, size, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Promotes a post. The notification is everytime sent to all (also anonymous) users with mobile app.
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      promoPost(postId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = PostsApiFetchParamCreator(configuration).promoPost(postId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Current user reports post as toxic
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      toxicPost(postId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = PostsApiFetchParamCreator(configuration).toxicPost(postId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Current user removes their like from the post
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      unlikePost(postId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = PostsApiFetchParamCreator(configuration).unlikePost(postId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Updates a post
        * @param {CzYsP14KcPostApiDtoPostUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updatePost(body: CzYsP14KcPostApiDtoPostUpdateObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = PostsApiFetchParamCreator(configuration).updatePost(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * PostsApi - factory interface
    * @export
    */
    export const PostsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns post
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getPost(id: string, options?: any) {
      return PostsApiFp(configuration).getPost(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Current user adds their like to the post
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      likePost(postId: string, options?: any) {
      return PostsApiFp(configuration).likePost(postId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns list of all posts
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listAllPosts(text?: string, page?: number, size?: number, options?: any) {
      return PostsApiFp(configuration).listAllPosts(text, page, size, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Promotes a post. The notification is everytime sent to all (also anonymous) users with mobile app.
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      promoPost(postId: string, options?: any) {
      return PostsApiFp(configuration).promoPost(postId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Current user reports post as toxic
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      toxicPost(postId: string, options?: any) {
      return PostsApiFp(configuration).toxicPost(postId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Current user removes their like from the post
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      unlikePost(postId: string, options?: any) {
      return PostsApiFp(configuration).unlikePost(postId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Updates a post
        * @param {CzYsP14KcPostApiDtoPostUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updatePost(body: CzYsP14KcPostApiDtoPostUpdateObject, id: string, options?: any) {
      return PostsApiFp(configuration).updatePost(body, id, options)(fetch, basePath);
      },
    };
    };

    /**
    * PostsApi - object-oriented interface
    * @export
    * @class PostsApi
    * @extends {BaseAPI}
    */
      export class PostsApi extends BaseAPI {
      /**
      * 
        * @summary Returns post
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof PostsApi
      */
      public getPost(id: string, options?: any) {
      return PostsApiFp(this.configuration).getPost(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Current user adds their like to the post
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof PostsApi
      */
      public likePost(postId: string, options?: any) {
      return PostsApiFp(this.configuration).likePost(postId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns list of all posts
        * @param {string} [text] 
        * @param {number} [page] Page you want to retrieve (0..N)
        * @param {number} [size] Number of records per page.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof PostsApi
      */
      public listAllPosts(text?: string, page?: number, size?: number, options?: any) {
      return PostsApiFp(this.configuration).listAllPosts(text, page, size, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Promotes a post. The notification is everytime sent to all (also anonymous) users with mobile app.
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof PostsApi
      */
      public promoPost(postId: string, options?: any) {
      return PostsApiFp(this.configuration).promoPost(postId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Current user reports post as toxic
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof PostsApi
      */
      public toxicPost(postId: string, options?: any) {
      return PostsApiFp(this.configuration).toxicPost(postId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Current user removes their like from the post
        * @param {string} postId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof PostsApi
      */
      public unlikePost(postId: string, options?: any) {
      return PostsApiFp(this.configuration).unlikePost(postId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Updates a post
        * @param {CzYsP14KcPostApiDtoPostUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof PostsApi
      */
      public updatePost(body: CzYsP14KcPostApiDtoPostUpdateObject, id: string, options?: any) {
      return PostsApiFp(this.configuration).updatePost(body, id, options)(this.fetch, this.basePath);
      }

    }
    /**
    * RIANApi - fetch parameter creator
    * @export
    */
    export const RIANApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Lists all city parts
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listCityParts(options: any = {}): FetchArgs {
      const localVarPath = `/city-part`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Lists all streets starting with the provided name
        * @param {string} [name] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      listStreets(name?: string, options: any = {}): FetchArgs {
      const localVarPath = `/street`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

          if (name !== undefined) {
              localVarQueryParameter['name'] = name;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * RIANApi - functional programming interface
    * @export
    */
    export const RIANApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Lists all city parts
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listCityParts(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcRuianApiDtoRuianCityPartObject>> {
      const localVarFetchArgs = RIANApiFetchParamCreator(configuration).listCityParts(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Lists all streets starting with the provided name
        * @param {string} [name] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listStreets(name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcRuianApiDtoRuianAddressObject>> {
      const localVarFetchArgs = RIANApiFetchParamCreator(configuration).listStreets(name, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * RIANApi - factory interface
    * @export
    */
    export const RIANApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Lists all city parts
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listCityParts(options?: any) {
      return RIANApiFp(configuration).listCityParts(options)(fetch, basePath);
      },
      /**
      * 
        * @summary Lists all streets starting with the provided name
        * @param {string} [name] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      listStreets(name?: string, options?: any) {
      return RIANApiFp(configuration).listStreets(name, options)(fetch, basePath);
      },
    };
    };

    /**
    * RIANApi - object-oriented interface
    * @export
    * @class RIANApi
    * @extends {BaseAPI}
    */
      export class RIANApi extends BaseAPI {
      /**
      * 
        * @summary Lists all city parts
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof RIANApi
      */
      public listCityParts(options?: any) {
      return RIANApiFp(this.configuration).listCityParts(options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Lists all streets starting with the provided name
        * @param {string} [name] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof RIANApi
      */
      public listStreets(name?: string, options?: any) {
      return RIANApiFp(this.configuration).listStreets(name, options)(this.fetch, this.basePath);
      }

    }
    /**
    * SurveysApi - fetch parameter creator
    * @export
    */
    export const SurveysApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns survey
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getSurvey(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getSurvey.');
          }
      const localVarPath = `/survey/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Current user adds their like to the survey
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      likeSurvey(surveyId: string, options: any = {}): FetchArgs {
          // verify required parameter 'surveyId' is not null or undefined
          if (surveyId === null || surveyId === undefined) {
          throw new RequiredError('surveyId','Required parameter surveyId was null or undefined when calling likeSurvey.');
          }
      const localVarPath = `/survey/{surveyId}/like`
      .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Promotes a survey. The notification is sent everytime to all (also anonymous) users with mobile app.
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      promoSurvey(surveyId: string, options: any = {}): FetchArgs {
          // verify required parameter 'surveyId' is not null or undefined
          if (surveyId === null || surveyId === undefined) {
          throw new RequiredError('surveyId','Required parameter surveyId was null or undefined when calling promoSurvey.');
          }
      const localVarPath = `/survey/{surveyId}/promo`
      .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Current user reports survey as toxic
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      toxicSurvey(surveyId: string, options: any = {}): FetchArgs {
          // verify required parameter 'surveyId' is not null or undefined
          if (surveyId === null || surveyId === undefined) {
          throw new RequiredError('surveyId','Required parameter surveyId was null or undefined when calling toxicSurvey.');
          }
      const localVarPath = `/survey/{surveyId}/toxic`
      .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Current user removes their like from the survey
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      unlikeSurvey(surveyId: string, options: any = {}): FetchArgs {
          // verify required parameter 'surveyId' is not null or undefined
          if (surveyId === null || surveyId === undefined) {
          throw new RequiredError('surveyId','Required parameter surveyId was null or undefined when calling unlikeSurvey.');
          }
      const localVarPath = `/survey/{surveyId}/unlike`
      .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Adds user's vote for the given option in the survey
        * @param {string} surveyId 
        * @param {string} optionId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      voteInSurvey(surveyId: string, optionId: string, options: any = {}): FetchArgs {
          // verify required parameter 'surveyId' is not null or undefined
          if (surveyId === null || surveyId === undefined) {
          throw new RequiredError('surveyId','Required parameter surveyId was null or undefined when calling voteInSurvey.');
          }
          // verify required parameter 'optionId' is not null or undefined
          if (optionId === null || optionId === undefined) {
          throw new RequiredError('optionId','Required parameter optionId was null or undefined when calling voteInSurvey.');
          }
      const localVarPath = `/survey/{surveyId}/vote/{optionId}`
      .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)))
      .replace(`{${"optionId"}}`, encodeURIComponent(String(optionId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * SurveysApi - functional programming interface
    * @export
    */
    export const SurveysApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns survey
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getSurvey(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcSurveyApiDtoSurveyObject> {
      const localVarFetchArgs = SurveysApiFetchParamCreator(configuration).getSurvey(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Current user adds their like to the survey
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      likeSurvey(surveyId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = SurveysApiFetchParamCreator(configuration).likeSurvey(surveyId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Promotes a survey. The notification is sent everytime to all (also anonymous) users with mobile app.
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      promoSurvey(surveyId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = SurveysApiFetchParamCreator(configuration).promoSurvey(surveyId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Current user reports survey as toxic
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      toxicSurvey(surveyId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = SurveysApiFetchParamCreator(configuration).toxicSurvey(surveyId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Current user removes their like from the survey
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      unlikeSurvey(surveyId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = SurveysApiFetchParamCreator(configuration).unlikeSurvey(surveyId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Adds user's vote for the given option in the survey
        * @param {string} surveyId 
        * @param {string} optionId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      voteInSurvey(surveyId: string, optionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcSurveyApiDtoSurveyObject> {
      const localVarFetchArgs = SurveysApiFetchParamCreator(configuration).voteInSurvey(surveyId, optionId, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * SurveysApi - factory interface
    * @export
    */
    export const SurveysApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns survey
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getSurvey(id: string, options?: any) {
      return SurveysApiFp(configuration).getSurvey(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Current user adds their like to the survey
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      likeSurvey(surveyId: string, options?: any) {
      return SurveysApiFp(configuration).likeSurvey(surveyId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Promotes a survey. The notification is sent everytime to all (also anonymous) users with mobile app.
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      promoSurvey(surveyId: string, options?: any) {
      return SurveysApiFp(configuration).promoSurvey(surveyId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Current user reports survey as toxic
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      toxicSurvey(surveyId: string, options?: any) {
      return SurveysApiFp(configuration).toxicSurvey(surveyId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Current user removes their like from the survey
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      unlikeSurvey(surveyId: string, options?: any) {
      return SurveysApiFp(configuration).unlikeSurvey(surveyId, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Adds user's vote for the given option in the survey
        * @param {string} surveyId 
        * @param {string} optionId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      voteInSurvey(surveyId: string, optionId: string, options?: any) {
      return SurveysApiFp(configuration).voteInSurvey(surveyId, optionId, options)(fetch, basePath);
      },
    };
    };

    /**
    * SurveysApi - object-oriented interface
    * @export
    * @class SurveysApi
    * @extends {BaseAPI}
    */
      export class SurveysApi extends BaseAPI {
      /**
      * 
        * @summary Returns survey
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof SurveysApi
      */
      public getSurvey(id: string, options?: any) {
      return SurveysApiFp(this.configuration).getSurvey(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Current user adds their like to the survey
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof SurveysApi
      */
      public likeSurvey(surveyId: string, options?: any) {
      return SurveysApiFp(this.configuration).likeSurvey(surveyId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Promotes a survey. The notification is sent everytime to all (also anonymous) users with mobile app.
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof SurveysApi
      */
      public promoSurvey(surveyId: string, options?: any) {
      return SurveysApiFp(this.configuration).promoSurvey(surveyId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Current user reports survey as toxic
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof SurveysApi
      */
      public toxicSurvey(surveyId: string, options?: any) {
      return SurveysApiFp(this.configuration).toxicSurvey(surveyId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Current user removes their like from the survey
        * @param {string} surveyId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof SurveysApi
      */
      public unlikeSurvey(surveyId: string, options?: any) {
      return SurveysApiFp(this.configuration).unlikeSurvey(surveyId, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Adds user's vote for the given option in the survey
        * @param {string} surveyId 
        * @param {string} optionId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof SurveysApi
      */
      public voteInSurvey(surveyId: string, optionId: string, options?: any) {
      return SurveysApiFp(this.configuration).voteInSurvey(surveyId, optionId, options)(this.fetch, this.basePath);
      }

    }
    /**
    * UsersApi - fetch parameter creator
    * @export
    */
    export const UsersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns current user's suggested areas of interest
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getAreaOfInterestSuggestions(options: any = {}): FetchArgs {
      const localVarPath = `/user/me/areas-of-interest/suggested`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns all information about user (user, detail, notification settings, gdpr)
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getMe(options: any = {}): FetchArgs {
      const localVarPath = `/user/me`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Stores actual date time as last user activity
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getPing(options: any = {}): FetchArgs {
      const localVarPath = `/user/ping`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Returns user by id
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      getUser(id: string, options: any = {}): FetchArgs {
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling getUser.');
          }
      const localVarPath = `/user/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Accept or reject an area of interest suggestion
        * @param {CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      postAreaOfInterestSuggestions(body: CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling postAreaOfInterestSuggestions.');
          }
      const localVarPath = `/user/me/areas-of-interest/suggested`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Registers a new user
        * @param {CzYsP14KcUserApiDtoUserRegistrationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      registerUser(body: CzYsP14KcUserApiDtoUserRegistrationObject, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling registerUser.');
          }
      const localVarPath = `/user`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcUserApiDtoUserRegistrationObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
      /**
      * 
        * @summary Modifies user
        * @param {CzYsP14KcUserApiDtoUserUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      updateUser(body: CzYsP14KcUserApiDtoUserUpdateObject, id: string, options: any = {}): FetchArgs {
          // verify required parameter 'body' is not null or undefined
          if (body === null || body === undefined) {
          throw new RequiredError('body','Required parameter body was null or undefined when calling updateUser.');
          }
          // verify required parameter 'id' is not null or undefined
          if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling updateUser.');
          }
      const localVarPath = `/user/{id}`
      .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

          localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"CzYsP14KcUserApiDtoUserUpdateObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * UsersApi - functional programming interface
    * @export
    */
    export const UsersApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary Returns current user's suggested areas of interest
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getAreaOfInterestSuggestions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
      const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getAreaOfInterestSuggestions(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns all information about user (user, detail, notification settings, gdpr)
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getMe(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcUserApiDtoUserObject> {
      const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getMe(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Stores actual date time as last user activity
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getPing(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getPing(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Returns user by id
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getUser(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CzYsP14KcUserApiDtoUserObject> {
      const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getUser(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Accept or reject an area of interest suggestion
        * @param {CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      postAreaOfInterestSuggestions(body: CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = UsersApiFetchParamCreator(configuration).postAreaOfInterestSuggestions(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Registers a new user
        * @param {CzYsP14KcUserApiDtoUserRegistrationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      registerUser(body: CzYsP14KcUserApiDtoUserRegistrationObject, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
      const localVarFetchArgs = UsersApiFetchParamCreator(configuration).registerUser(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
      /**
      * 
        * @summary Modifies user
        * @param {CzYsP14KcUserApiDtoUserUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateUser(body: CzYsP14KcUserApiDtoUserUpdateObject, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = UsersApiFetchParamCreator(configuration).updateUser(body, id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response;
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * UsersApi - factory interface
    * @export
    */
    export const UsersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary Returns current user's suggested areas of interest
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getAreaOfInterestSuggestions(options?: any) {
      return UsersApiFp(configuration).getAreaOfInterestSuggestions(options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns all information about user (user, detail, notification settings, gdpr)
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getMe(options?: any) {
      return UsersApiFp(configuration).getMe(options)(fetch, basePath);
      },
      /**
      * 
        * @summary Stores actual date time as last user activity
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getPing(options?: any) {
      return UsersApiFp(configuration).getPing(options)(fetch, basePath);
      },
      /**
      * 
        * @summary Returns user by id
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      getUser(id: string, options?: any) {
      return UsersApiFp(configuration).getUser(id, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Accept or reject an area of interest suggestion
        * @param {CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      postAreaOfInterestSuggestions(body: CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject, options?: any) {
      return UsersApiFp(configuration).postAreaOfInterestSuggestions(body, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Registers a new user
        * @param {CzYsP14KcUserApiDtoUserRegistrationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      registerUser(body: CzYsP14KcUserApiDtoUserRegistrationObject, options?: any) {
      return UsersApiFp(configuration).registerUser(body, options)(fetch, basePath);
      },
      /**
      * 
        * @summary Modifies user
        * @param {CzYsP14KcUserApiDtoUserUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      updateUser(body: CzYsP14KcUserApiDtoUserUpdateObject, id: string, options?: any) {
      return UsersApiFp(configuration).updateUser(body, id, options)(fetch, basePath);
      },
    };
    };

    /**
    * UsersApi - object-oriented interface
    * @export
    * @class UsersApi
    * @extends {BaseAPI}
    */
      export class UsersApi extends BaseAPI {
      /**
      * 
        * @summary Returns current user's suggested areas of interest
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof UsersApi
      */
      public getAreaOfInterestSuggestions(options?: any) {
      return UsersApiFp(this.configuration).getAreaOfInterestSuggestions(options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns all information about user (user, detail, notification settings, gdpr)
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof UsersApi
      */
      public getMe(options?: any) {
      return UsersApiFp(this.configuration).getMe(options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Stores actual date time as last user activity
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof UsersApi
      */
      public getPing(options?: any) {
      return UsersApiFp(this.configuration).getPing(options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Returns user by id
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof UsersApi
      */
      public getUser(id: string, options?: any) {
      return UsersApiFp(this.configuration).getUser(id, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Accept or reject an area of interest suggestion
        * @param {CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof UsersApi
      */
      public postAreaOfInterestSuggestions(body: CzYsP14KcUserApiDtoUserAreaOfInterestSuggestionResponseObject, options?: any) {
      return UsersApiFp(this.configuration).postAreaOfInterestSuggestions(body, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Registers a new user
        * @param {CzYsP14KcUserApiDtoUserRegistrationObject} body 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof UsersApi
      */
      public registerUser(body: CzYsP14KcUserApiDtoUserRegistrationObject, options?: any) {
      return UsersApiFp(this.configuration).registerUser(body, options)(this.fetch, this.basePath);
      }

      /**
      * 
        * @summary Modifies user
        * @param {CzYsP14KcUserApiDtoUserUpdateObject} body 
        * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof UsersApi
      */
      public updateUser(body: CzYsP14KcUserApiDtoUserUpdateObject, id: string, options?: any) {
      return UsersApiFp(this.configuration).updateUser(body, id, options)(this.fetch, this.basePath);
      }

    }
    /**
    * WordPressAuthorsApi - fetch parameter creator
    * @export
    */
    export const WordPressAuthorsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
      /**
      * 
        * @summary List of WordPress authors for current user
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
      findWordpressAuthors(options: any = {}): FetchArgs {
      const localVarPath = `/wp-author`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

        // authentication bearer-key required
          // oauth required
          if (configuration && configuration.accessToken) {
          const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? configuration.accessToken("bearer-key", [""])
          : configuration.accessToken;
          localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
          }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
      url: url.format(localVarUrlObj),
      options: localVarRequestOptions,
      };
      },
    }
    };

    /**
    * WordPressAuthorsApi - functional programming interface
    * @export
    */
    export const WordPressAuthorsApiFp = function(configuration?: Configuration) {
    return {
      /**
      * 
        * @summary List of WordPress authors for current user
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      findWordpressAuthors(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CzYsP14KcWordpressApiDtoWordpressAuthorObject>> {
      const localVarFetchArgs = WordPressAuthorsApiFetchParamCreator(configuration).findWordpressAuthors(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
      return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      return response.json();
      } else {
      throw response;
      }
      });
      };
      },
    }
    };

    /**
    * WordPressAuthorsApi - factory interface
    * @export
    */
    export const WordPressAuthorsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
      /**
      * 
        * @summary List of WordPress authors for current user
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      */
      findWordpressAuthors(options?: any) {
      return WordPressAuthorsApiFp(configuration).findWordpressAuthors(options)(fetch, basePath);
      },
    };
    };

    /**
    * WordPressAuthorsApi - object-oriented interface
    * @export
    * @class WordPressAuthorsApi
    * @extends {BaseAPI}
    */
      export class WordPressAuthorsApi extends BaseAPI {
      /**
      * 
        * @summary List of WordPress authors for current user
      * @param {*} [options] Override http request option.
      * @throws {RequiredError|Response}
      * @memberof WordPressAuthorsApi
      */
      public findWordpressAuthors(options?: any) {
      return WordPressAuthorsApiFp(this.configuration).findWordpressAuthors(options)(this.fetch, this.basePath);
      }

    }
