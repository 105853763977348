import {
  baseMediaUrl,
  getImageSizeSuffix,
  ImageSize,
  imageVersion,
} from '~/utils/media';

export default function(userId: string, size?: ImageSize): string {
  return `${baseMediaUrl}/user/${userId}${
    size ? getImageSizeSuffix(size) : ''
  }#${imageVersion()}`;
}
