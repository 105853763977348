import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';

import ForgottenDialogLocalization from './forgottenDialog';
import LoginLocalization from './login';
import ProfileLocalization from './profile';
import RegisterDialogLocalization from './registerDialog';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  ForgottenDialogLocalization,
  LoginLocalization,
  ProfileLocalization,
  RegisterDialogLocalization,
]) as LocaleMessages;

export default mergedLocalizations;
