import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator';
import { ValueProp, VueComponent } from '~/utils/vue-component';

import style from './MapPicker.scss';
import { P14_LAT, P14_LONG, ZOOM } from '~/utils/googleMaps/defaultSettings';
import { loadGoogleMaps, MapsApi, reverseGeoCode } from '~/utils/googleMaps';

const rootClass = 'ys-map-selector';

export interface MapLocation {
  lat: number;
  lng: number;
}

type SelectEvent = MapLocation;

type GeoCodingEvent = boolean;

interface GeoCodedAddressEvent {
  address: string;
}

type Value = MapLocation | null;

interface MapPickerInterface extends ValueProp<Value> {
  onGeoCodedAddress?: (data: GeoCodedAddressEvent) => any;
  onGeoCoding?: (data: GeoCodingEvent) => any;
}

@Component({
  style,
})
export default class MapPicker extends VueComponent<MapPickerInterface>
  implements MapPickerInterface {
  @Prop({ required: true })
  public value!: MapLocation | null;

  protected mapsApi: MapsApi = {
    failed: false,
    loaded: false,
    loading: false,
    map: null,
    marker: null,
  };

  @Ref()
  protected readonly mapsApiBlock?: HTMLElement;

  @Watch('value', { deep: true, immediate: true })
  public setMapPin() {
    if (this.value && this.mapsApi.map && this.value.lat && this.value.lng) {
      if (!this.mapsApi.marker) {
        this.mapsApi.marker = new google.maps.Marker({
          position: {
            lat: this.value.lat,
            lng: this.value.lng,
          },
          map: this.mapsApi.map,
        });
      } else {
        this.mapsApi.marker.setPosition({
          lat: this.value.lat,
          lng: this.value.lng,
        });
      }
      this.mapsApi.map.setCenter({
        lat: this.value.lat,
        lng: this.value.lng,
      });
    } else {
      this.mapsApi.marker?.unbindAll();
      this.mapsApi.marker?.setMap(null);
      this.mapsApi.marker = null;
    }
  }

  public beforeMount() {
    this.mapsApi.loading = true;
    loadGoogleMaps()
      .then(() => {
        this.mapsApi.loaded = true;
        this.initializeMap();
      })
      .catch((err) => {
        this.mapsApi.failed = true;
      })
      .finally(() => {
        this.mapsApi.loading = false;
      });
  }

  public mounted() {
    this.initializeMap();
  }

  public beforeUpdate() {
    this.destroyMap();
  }

  public updated() {
    this.initializeMap();
  }

  public beforeDestroy() {
    this.destroyMap();
  }

  public render() {
    return (
      <div class={`${rootClass} mb-3`}>
        {/* Cannot wrap the map with skeleton loader, because it initializes with an incorrect size */}
        {this.mapsApi.loading && (
          <v-skeleton-loader
            type='image'
            elevation={2}
            loading={this.mapsApi.loading}
            width='100%'
          />
        )}
        <div class={`${rootClass}__map google-map`} ref='mapsApiBlock' />
      </div>
    );
  }

  protected initializeMap() {
    if (!this.mapsApi.loaded || this.mapsApi.map || !this.mapsApiBlock) {
      return;
    }

    this.mapsApi.map = new google.maps.Map(this.mapsApiBlock, {
      center: {
        lat: this.value?.lat && this.value?.lng ? this.value.lat : P14_LAT,
        lng: this.value?.lat && this.value?.lng ? this.value.lng : P14_LONG,
      },
      zoom: ZOOM,
    });

    this.mapsApi.map.addListener('click', (e) => {
      this.emitInput(e.latLng.lat(), e.latLng.lng());
      this.emitGeocoding(true);

      reverseGeoCode(e.latLng.lat(), e.latLng.lng(), this.$i18n.locale)
        .then((result) => {
          this.emitAddress(result.address);
        })
        .finally(() => {
          this.emitGeocoding(false);
        });
    });

    this.setMapPin();
  }

  protected destroyMap() {
    if (this.mapsApi.map) {
      this.mapsApi.map.unbindAll();
      this.mapsApi.map = null;
    }
    if (this.mapsApi.marker) {
      this.mapsApi.marker.unbindAll();
      this.mapsApi.marker.setMap(null);
      this.mapsApi.marker = null;
    }
  }

  @Emit('geoCodedAddress')
  protected emitAddress(address: string): GeoCodedAddressEvent {
    return {
      address,
    };
  }

  @Emit('geoCoding')
  protected emitGeocoding(data: GeoCodingEvent): GeoCodingEvent {
    return data;
  }

  @Emit('input')
  protected emitInput(lat: number, lng: number): Value {
    return {
      lat,
      lng,
    };
  }
}
