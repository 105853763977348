import '~/app/core/publicPath';
import '~/app/core/classComponent';
import '~/styles/main.scss';

import Vue from 'vue';
// Vuetify must be imported from node modules directly
// @ts-ignore
import Vuetify from '~/../node_modules/vuetify/lib';
import { getModule } from 'vuex-module-decorators';
import { sync } from 'vuex-router-sync';
import '~/app/core/firebase';

import App from '~/app/App';
import VuetifyConfig from '~/app/core/vuetify';
import { createRouter, createStore } from '~/app/core';
import { modules } from '~/app/core/store/modules';
import { defaultLocale } from '~/app/localization';
import createI18n from '~/app/localization/plugin';
import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { APIClient } from '~/app/core/apiClient';

const mergeMountedStrategy = Vue.config.optionMergeStrategies.mounted;
/**
 * When these are fixed, we can use optionMergeStrategies to push the mounted hook from the mixin to the end
 * @link https://github.com/vuejs/vue/issues/9623
 * @link https://stackoverflow.com/questions/52988704/vue-js-scoping-custom-option-merge-strategies-instead-going-global
 */
Vue.config.optionMergeStrategies.mounted = (
  toVal: any,
  fromVal: any,
  vm: any
) => {
  if (vm && vm.mountedBeforeMixins) {
    if (typeof fromVal === 'function') {
      fromVal = [fromVal];
    }
    if (typeof toVal === 'function') {
      toVal = [toVal];
    }
    return [...fromVal, ...toVal];
  }
  return mergeMountedStrategy(toVal, fromVal, vm);
};

declare module 'vue/types/vue' {
  export interface Vue {
    $api: APIClient;
  }
}

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    apiClient?: APIClient;
  }
}

Vue.mixin({
  beforeCreate() {
    if (this.$options.apiClient) {
      this.$api = this.$options.apiClient;
    } else if (this.$options.parent && this.$options.parent.$api) {
      this.$api = this.$options.parent.$api;
    }
  },
});

// export a factory function for creating fresh app, router and store
// instances
export function createApp(context: any = null) {
  const router = createRouter();
  const store = createStore();
  const apiClient = new APIClient(store);
  const i18n = createI18n();
  const vuetify = new Vuetify({
    lang: {
      t: (key: string, ...params: (string | number)[]) =>
        i18n.t(key, params).toString(),
      current: defaultLocale,
    },
    ...VuetifyConfig,
  });

  sync(store, router);

  router.beforeEach((to, from, next) => {
    let locale = defaultLocale;
    for (const matched of to.matched) {
      if (matched.meta.locale) {
        locale = matched.meta.locale;
        break;
      }
    }
    // Set locale from the router
    i18n.locale = locale;
    // It might be necessary to change vuetify locale here, but i18n should take over
    next();
  });

  const app = new Vue({
    apiClient,
    router,
    store,
    vuetify,
    i18n,
    beforeCreate: () => {
      // Set apollo to the store modules
      for (const moduleName in modules) {
        if (!modules.hasOwnProperty(moduleName)) {
          continue;
        }

        const module = (getModule(
          modules[moduleName] as any,
          store
        ) as unknown) as AbstractModule;
        module.$api = apiClient;
      }
    },
    // the root instance simply renders the App component.
    render: (h) => h(App),
  });
  return { app, router, store };
}
