import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import AbstractModule from './AbstractModule';

import {
  CzYsP14KcCommunityApiDtoCommunityDetailObject,
  isApiFetchResponse,
} from '~/app/core/apiClient/api';
import { isPromise, itemIsNotNull } from '~/utils/typeguards';
import { Error } from '~/components/templates/common';
import { GroupItem } from '~/utils/group';
import createGroupItem from '~/utils/group/createGroupItem';
import Vue from 'vue';
import MembershipTypeEnum = CzYsP14KcCommunityApiDtoCommunityDetailObject.MembershipTypeEnum;
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';

interface InitItemsInput {
  forceReload: boolean;
}

interface LoadInput {
  groupId: GroupItem['id'];
}

interface LoadUncachedInput {
  requestedIds: GroupItem['id'][];
}

interface SaveItemsCommit {
  items: GroupItem[];
}

type CacheGroupsCommit = GroupItem[];

interface JoiningCommit {
  id: GroupItem['id'];
  joining: boolean;
}

interface JoinStatusCommit {
  id: GroupItem['id'];
  state: boolean;
}

enum MembershipErrors {
  ALREADY_A_MEMBER = 'Already a member',
  ALREADY_LEFT = 'There are no members for removal',
}

/**
 * Sort groups by membership first, name second
 * @param groupA
 * @param groupB
 */
export function sortGroups(groupA: GroupItem, groupB: GroupItem): number {
  if (groupA.membershipType === groupB.membershipType) {
    return groupA.name.localeCompare(groupB.name);
  }

  // Owned groups first
  if (groupA.membershipType === MembershipTypeEnum.OWNER) {
    return -1;
  } else if (groupB.membershipType === MembershipTypeEnum.OWNER) {
    return 1;
  }

  // Managed groups second
  if (groupA.membershipType === MembershipTypeEnum.DEPUTY) {
    return -1;
  } else if (groupB.membershipType === MembershipTypeEnum.DEPUTY) {
    return 1;
  }

  return 0;
}

function getGroupKey(id: string, items: GroupItem[]): number | null {
  for (let key = 0; key < items.length; key++) {
    if (items[key].id === id) {
      return key;
    }
  }
  return null;
}

function getUncachedGroupIds(
  cachedGroups: GroupItem[],
  needed: GroupItem['id'][]
): GroupItem['id'][] {
  const missingGroupIds: GroupItem['id'][] = [];

  needed.forEach((neededId) => {
    for (const groupItem of cachedGroups) {
      if (neededId === groupItem.id) {
        return;
      }
    }

    if (missingGroupIds.indexOf(neededId) > -1) {
      return;
    }

    missingGroupIds.push(neededId);
  });

  return missingGroupIds;
}

@Module({
  name: 'GroupModule',
  stateFactory: true,
  namespaced: true,
})
export default class GroupModule extends AbstractModule {
  public groupCache: GroupItem[] = [];

  protected groups: GroupItem[] = [];

  protected loadingPromise: Promise<SaveItemsCommit> | null = null;

  get items() {
    return this.groups;
  }

  get sortedItems() {
    return [...this.groups].sort(sortGroups);
  }

  @Action({ commit: 'saveItems', rawError: true })
  public initItems(data?: InitItemsInput): Promise<SaveItemsCommit> {
    if (isPromise(this.loadingPromise) && (!data || !data.forceReload)) {
      return this.loadingPromise;
    }

    const promise = this.$api.communities.listMyCommunities().then((result) => {
      return {
        // TODO: Add areas of interest to groups
        // TODO: Do not add these items to group cache until this is solved
        items: result.content
          ? result.content
              .map((item) => createGroupItem(item, []))
              .filter(itemIsNotNull)
          : [],
      };
    });

    this.setLoadingPromise(promise);
    return promise;
  }

  @Action({ commit: 'cacheGroups', rawError: true })
  public load(data: LoadInput): Promise<CacheGroupsCommit> {
    return getModule(AreasOfInterestModule, this.store)
      .loadData()
      .then((areas) => {
        return this.$api.communities
          .getCommunity(data.groupId)
          .then((result) => {
            const group = createGroupItem(result, areas.allItems);
            if (group === null) {
              return [];
            }

            return [group];
          });
      });
  }

  @Action({ commit: 'cacheGroups', rawError: true })
  public loadUncachedIds(data: LoadUncachedInput): Promise<CacheGroupsCommit> {
    return getModule(AreasOfInterestModule, this.store)
      .loadData()
      .then((areas) => {
        const items: GroupItem[] = [];
        const promises: Promise<any>[] = [];

        getUncachedGroupIds(this.groupCache, data.requestedIds).forEach(
          (groupId) => {
            const promise = this.$api.communities.getCommunity(groupId);

            promise.then((result) => {
              const group = createGroupItem(result, areas.allItems);
              if (group !== null) {
                items.push(group);
              }
            });

            promises.push(promise);
          }
        );

        return Promise.all(promises).then(() => {
          return items;
        });
      });
  }

  @Action({ commit: 'setJoinStatus', rawError: true })
  public joinGroup(id: string) {
    this.setJoining({ id, joining: true });
    return this.$api.communityMembership
      .addCurrentUserAsMember(id)
      .catch((err) => {
        if (isApiFetchResponse(err)) {
          return err.json().then((response) => {
            if (
              response.message &&
              response.message === MembershipErrors.ALREADY_A_MEMBER
            ) {
              return;
            }
            throw new Error();
          });
        }

        throw new Error();
      })
      .then(() => {
        return this.initItems({ forceReload: true }).then(() => {
          return {
            id,
            state: true,
          };
        });
      })
      .finally(() => {
        this.setJoining({ id, joining: false });
      });
  }

  @Action({ commit: 'setRequestStatus', rawError: true })
  public requestMembership(id: string) {
    return this.joinGroup(id);
  }

  @Action({ commit: 'setJoinStatus', rawError: true })
  public leaveGroup(id: string): Promise<JoinStatusCommit> {
    this.setJoining({ id, joining: true });
    return this.$api.communityMembership
      .deleteCurrentUserMembership(id)
      .catch((err) => {
        if (isApiFetchResponse(err)) {
          return err.json().then((response) => {
            if (
              response.message &&
              response.message === MembershipErrors.ALREADY_LEFT
            ) {
              return;
            }
            throw new Error();
          });
        }

        throw new Error();
      })
      .then(() => {
        return this.initItems({ forceReload: true }).then(() => {
          return {
            id,
            state: false,
          };
        });
      })
      .finally(() => {
        this.setJoining({ id, joining: false });
      });
  }

  @Mutation
  public cacheGroups(groups: CacheGroupsCommit) {
    groups.forEach((group) => {
      const key = getGroupKey(group.id, this.groupCache);
      if (key !== null) {
        Vue.set(this.groupCache, key, group);
      } else {
        this.groupCache.push(group);
      }
    });
  }

  @Mutation
  protected setJoining(data: JoiningCommit) {
    const key = getGroupKey(data.id, this.groups);
    if (key !== null) {
      this.groups[key].state.joining = data.joining;
      Vue.set(this.groups, key, this.groups[key]);
    }

    const cacheKey = getGroupKey(data.id, this.groupCache);
    if (cacheKey !== null) {
      this.groupCache[cacheKey].state.joining = data.joining;
      Vue.set(this.groupCache, cacheKey, this.groupCache[cacheKey]);
    }
  }

  @Mutation
  protected setJoinStatus(data: JoinStatusCommit) {
    const key = getGroupKey(data.id, this.groups);
    if (key !== null) {
      this.groups[key].membershipType = MembershipTypeEnum.MEMBER;
      Vue.set(this.groups, key, this.groups[key]);
    }

    const cacheKey = getGroupKey(data.id, this.groupCache);
    if (cacheKey !== null) {
      this.groupCache[cacheKey].membershipType = data.state
        ? MembershipTypeEnum.MEMBER
        : null;
      Vue.set(this.groupCache, cacheKey, this.groupCache[cacheKey]);
    }
  }

  @Mutation
  protected setRequestStatus(data: JoinStatusCommit) {
    const key = getGroupKey(data.id, this.groups);
    if (key !== null) {
      this.groups[key].membershipType = data.state
        ? MembershipTypeEnum.APPLICANT
        : null;
      Vue.set(this.groups, key, this.groups[key]);
    }

    const cacheKey = getGroupKey(data.id, this.groupCache);
    if (cacheKey !== null) {
      this.groupCache[cacheKey].membershipType = data.state
        ? MembershipTypeEnum.APPLICANT
        : null;
      Vue.set(this.groupCache, cacheKey, this.groupCache[cacheKey]);
    }
  }

  @Mutation
  public saveItems(data: SaveItemsCommit) {
    this.groups = data.items;
  }

  @Mutation
  protected setLoadingPromise(promise: Promise<SaveItemsCommit>) {
    this.loadingPromise = promise;
  }
}
