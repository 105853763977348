import { Component, Prop } from 'vue-property-decorator';

import { ImageSettings } from '~/utils/image';
import { ImageSize } from '~/utils/media';
import { User } from '~/utils/users';
import getUserImageUrl from '~/utils/user/getUserImageUrl';
import { VueComponent } from '~/utils/vue-component';

export enum UserImageContext {
  HEAD,
  TILE,
  AVATAR,
}

interface UserImageInterface {
  context: UserImageContext;
  user: User;
}

@Component
export default class UserImage extends VueComponent<UserImageInterface>
  implements UserImageInterface {
  @Prop({ required: true })
  public context!: UserImageContext;

  @Prop({ required: true })
  public user!: User;

  protected get settings(): ImageSettings {
    switch (this.context) {
      case UserImageContext.HEAD:
        return {
          placeholderImage: 'mdi-account',
          height: '360px',
          size: ImageSize.SMALL,
        };
      case UserImageContext.AVATAR:
        return {
          placeholderImage: 'mdi-account',
          ratio: 1,
          size: ImageSize.THUMBNAIL,
        };
      default:
        return {
          placeholderImage: 'mdi-account',
          ratio: 3 / 2,
        };
    }
  }

  public render() {
    return (
      <v-img
        alt={this.user.name}
        aspect-ratio={this.settings.height ? undefined : this.settings.ratio}
        height={this.settings.height ? this.settings.height : undefined}
        max-height={this.settings.height ? this.settings.height : undefined}
        src={getUserImageUrl(this.user.id, this.settings.size)}
        key={`user-image-${this.user.id}`}
      >
        <v-icon large slot='placeholder'>
          {this.settings.placeholderImage}
        </v-icon>
        {this.$slots.default}
      </v-img>
    );
  }
}
