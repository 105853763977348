import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { getModule } from 'vuex-module-decorators';

import UserModule, {
  LoginWithEmailData,
} from '~/app/core/store/modules/UserModule';
import { TranslateResult } from 'vue-i18n';
import { ExternalLogin } from '~/components/molecules';

import style from './LoginForm.scss';

const rootClass = 'ys-login-form';

interface LoginFormInterface {
  onOpenForgottenDialog?(): void;
  onOpenRegisterDialog?(): void;
}

@Component({
  style,
})
export default class LoginForm extends VueComponent<LoginFormInterface> {
  protected email: string = '';
  protected password: string = '';
  protected errorMessage: TranslateResult | string | null = null;
  protected loading: boolean = false;

  public render() {
    return (
      <v-card class={[rootClass, 'pa-4'].join(' ')} elevation={10}>
        <div class={`${rootClass}__email`}>
          <v-form onSubmit={this.submitLoginByEmail}>
            <h2 class='text-center mb-3'>{this.$t('app.login.title')}</h2>
            <label class='font-weight-bold'>{this.$t('app.login.email')}</label>
            <v-text-field
              class={`${rootClass}__input mb-3`}
              v-model={this.email}
              filled
              rounded
              clearable
              tabindex='1'
            />
            <label class='font-weight-bold'>
              {this.$t('app.login.password')}
            </label>
            <v-text-field
              class={`${rootClass}__input`}
              v-model={this.password}
              type='password'
              filled
              rounded
              clearable
              tabindex='1'
            />
            <v-btn
              class={`${rootClass}__forgotten`}
              text
              small
              onClick={() => {
                this.$emit('openForgottenDialog');
              }}
            >
              {this.$t('app.login.forgottenPassword')}
            </v-btn>
            <v-alert
              type='error'
              value={!!this.errorMessage}
              transition='scale-transition'
              dense
              outlined
              text
            >
              {this.errorMessage}
            </v-alert>
            <div class='text-center my-3 px-7'>
              <v-btn
                class={`${rootClass}__submit`}
                color='secondary'
                block
                type='submit'
                tabindex='1'
                loading={this.loading}
                rounded
              >
                {this.$t('app.login.submit')}
              </v-btn>
            </div>
          </v-form>
        </div>
        <div class={`${rootClass}__or`}>
          <span>{this.$t('app.login.or')}</span>
        </div>
        <div class='my-3'>
          <ExternalLogin />
        </div>
        <div class='text-center'>
          <v-btn
            class={`${rootClass}__register`}
            text
            small
            onClick={() => {
              this.$emit('openRegisterDialog');
            }}
          >
            {this.$t('app.login.register')}
          </v-btn>
        </div>
      </v-card>
    );
  }

  protected submitLoginByEmail(ev: Event) {
    ev.preventDefault();
    this.errorMessage = null;

    this.email = this.email.trim();
    if (!this.email) {
      this.errorMessage = this.$t('app.login.emptyEmail');
      return;
    }
    if (!this.password) {
      this.errorMessage = this.$t('app.login.emptyPassword');
      return;
    }

    this.loading = true;
    const userModule = getModule(UserModule, this.$store);
    const payload: LoginWithEmailData = {
      email: this.email,
      password: this.password,
    };
    userModule
      .loginWithEmail(payload)
      .catch((err: any) => {
        if (!err.code) {
          this.errorMessage = this.$t('app.login.genericError');
          return;
        }
        switch (err.code) {
          case 'auth/invalid-email':
            this.errorMessage = this.$t('app.login.invalidEmail');
            break;
          case 'auth/user-not-found':
            this.errorMessage = this.$t('app.login.userNotFound');
            break;
          case 'auth/wrong-password':
            this.errorMessage = this.$t('app.login.wrongPassword');
            break;
          default:
            this.errorMessage = this.$t('app.login.genericError');
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
