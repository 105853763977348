import { Component } from 'vue-property-decorator';
import BaseButton from '~/components/atoms/baseButton/BaseButton';
import { SpreadAttributes } from '~/utils/vue-component';
import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';

@Component
export default class FindGroupsButton extends BaseButton<{}> {
  protected get attributes(): SpreadAttributes {
    return {
      attrs: {
        rounded: true,
        ...this.baseAttributes.attrs,
      },
    };
  }

  public render() {
    return (
      <v-btn
        {...this.attributes}
        color='secondary'
        to={getLocalizedLocation(routes.findGroups, this.$router, {})}
      >
        <v-icon left>mdi-magnify</v-icon>
        {this.$slots.default || this.$t('app.communities.find')}
      </v-btn>
    );
  }
}
