import { RouteConfig } from 'vue-router';

import Events from '~/views/Events';

const config: RouteConfig = {
  component: Events,
  path: 'events',
};

export default config;
