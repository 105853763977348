import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import GroupModule from '~/app/core/store/modules/GroupModule';
import MutationModalsModule from '~/app/core/store/modules/MutationModalsModule';
import { BannerButton } from '~/components/atoms';
import { icons as eventIcons } from '~/utils/event';
import {
  icons as groupIcons,
  userCanCreateEvent,
  userCanCreatePost,
  userCanCreateSurvey,
} from '~/utils/group';
import { icons as postIcons } from '~/utils/post';
import { icons as surveyIcons } from '~/utils/survey';
import { VueComponent } from '~/utils/vue-component';

import style from './SpeedDial.scss';

const rootClass = 'ys-speed-dial';

@Component({ style })
export default class SpeedDial extends VueComponent<{}> {
  protected open: boolean = false;

  public get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  public get modalStore() {
    return getModule(MutationModalsModule, this.$store);
  }

  protected get canCreateEvents(): boolean {
    return this.groupStore.items.filter(userCanCreateEvent).length > 0;
  }

  protected get canCreatePosts(): boolean {
    return this.groupStore.items.filter(userCanCreatePost).length > 0;
  }

  protected get canCreateSurveys(): boolean {
    return this.groupStore.items.filter(userCanCreateSurvey).length > 0;
  }

  public render() {
    return (
      <v-speed-dial
        class={rootClass}
        v-model={this.open}
        bottom
        right
        fixed
        direction='top'
        transition='slide-y-reverse-transition'
      >
        <v-btn v-model={this.open} color='secondary' dark fab slot='activator'>
          {(this.open && <v-icon>mdi-close</v-icon>) || (
            <v-icon>mdi-plus</v-icon>
          )}
        </v-btn>
        <BannerButton
          class={`${rootClass}__btn`}
          onClick={() => {
            this.modalStore.setNewGroupModalIsOpen(true);
          }}
        >
          <v-icon slot='icon'>{groupIcons.create}</v-icon>
          {this.$t('app.communities.create')}
          <span slot='subtitle'>
            {this.$t('app.communities.createSubtitle')}
          </span>
        </BannerButton>
        {this.canCreateEvents && (
          <BannerButton
            class={`${rootClass}__btn`}
            onClick={() => {
              this.modalStore.setNewEventModalIsOpen(true);
            }}
          >
            <v-icon slot='icon'>{eventIcons.create}</v-icon>
            {this.$t('app.events.create')}
            <span slot='subtitle'>{this.$t('app.events.buttonSubtitle')}</span>
          </BannerButton>
        )}
        {this.canCreateSurveys && (
          <BannerButton
            class={`${rootClass}__btn`}
            onClick={() => {
              this.modalStore.setNewSurveyModalIsOpen(true);
            }}
          >
            <v-icon slot='icon'>{surveyIcons.create}</v-icon>
            {this.$t('app.surveys.create')}
            <span slot='subtitle'>{this.$t('app.surveys.buttonSubtitle')}</span>
          </BannerButton>
        )}
        {this.canCreatePosts && (
          <BannerButton
            class={`${rootClass}__btn`}
            onClick={() => {
              this.modalStore.setNewPostModalIsOpen(true);
            }}
          >
            <v-icon slot='icon'>{postIcons.create}</v-icon>
            {this.$t('app.posts.create')}
            <span slot='subtitle'>{this.$t('app.posts.buttonSubtitle')}</span>
          </BannerButton>
        )}
        <v-overlay z-index={-1} />
      </v-speed-dial>
    );
  }
}
