import { TranslateResult } from 'vue-i18n';
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { VuetifyTypes } from '~/@types/vuetify-types';
import { CzYsP14KcEventApiDtoEventCreationObject } from '~/app/core/apiClient/api';
import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import EventsModule from '~/app/core/store/modules/EventsModule';
import GroupModule from '~/app/core/store/modules/GroupModule';
import groupPlacehodler from '~/assets/images/placeholder/survey-group_350x180.png';
import { CloseButton, FormActionButton } from '~/components/atoms';
import { FormActionIcon } from '~/components/atoms/formActionButton/FormActionButton';
import {
  ErrorSnack,
  GroupCard,
  ImageUploadPlaceholder,
  SelectGroupItem,
  SelectPlaceField,
} from '~/components/molecules';
import { ImageSelection } from '~/components/organisms';
import { ImageSelectionEvent } from '~/components/organisms/imageSelection/ImageSelection';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { createAreaOfInterestSelectItems } from '~/utils/areasOfInterest';
import { GroupItem, Kind, userCanCreateEvent } from '~/utils/group';
import {
  createDefaultDaySelection,
  DaySelection,
  EventItem,
  findFirstInstanceDate,
} from '~/utils/event';
import getEventImageUrl from '~/utils/event/getEventImageUrl';
import { componentOffset } from '~/utils/spacing';
import { VueComponentMixin } from '~/utils/vue-component';
import { format, parse } from '~/utils/date-fns';
import EventDateSelector, {
  createDefaultErrorFields as createDefaultDateSelectorErrors,
  ErrorFields as DateSelectorErrors,
  InputMode,
} from '~/components/organisms/eventDateSelector/EventDateSelector';
import EventMutationModule, {
  isDateSelectionValid,
  ValidationCommit,
} from '~/app/core/store/modules/EventMutationModule';
import EventDetailModule from '~/app/core/store/modules/EventDetailModule';
import {
  createEmptyGeoLocation,
  GeoLocation,
  isLocationEmpty,
  isLocationValid,
} from '~/utils/location';
import { SelectPlace } from '~/components/templates';
import { imageVersion } from '~/utils/media';
import VisibilityEnum = CzYsP14KcEventApiDtoEventCreationObject.VisibilityEnum;

export enum FormStep {
  GROUP_CHOICE,
  BASIC_INFO,
  EVENT_INFO,
  META_INFO,
  LOCATION,
}

interface EditEventFormInterface {
  eventId: EventItem['id'] | null;
  groupId: GroupItem['id'] | null;
  onCancel?: () => void;
}

interface VuetifyButton extends Vue {
  $el: HTMLButtonElement;
}

export interface FormValues {
  annotation: string;
  areasOfInterest: string[];
  capacity: number | null;
  content: EventItem['content'];
  date: {
    daysOfWeek: DaySelection;
    end: string | null;
    inputMode: InputMode;
    start: string;
    timeEnd: string | null;
    timeStart: string | null;
  };
  flags: {
    barrierFree: boolean;
    dogsAllowed: boolean;
  };
  image: string;
  link: string;
  location: GeoLocation;
  price: string | null;
  title: EventItem['title'];
  visibility: CzYsP14KcEventApiDtoEventCreationObject.VisibilityEnum;
}

function createDefaultFormValues(): FormValues {
  return {
    annotation: '',
    areasOfInterest: [],
    capacity: null,
    content: '',
    date: {
      daysOfWeek: createDefaultDaySelection(),
      end: null,
      inputMode: InputMode.SINGLEDAY,
      start: format(new Date(), 'YYYY-MM-DD'),
      timeEnd: null,
      timeStart: null,
    },
    flags: {
      barrierFree: false,
      dogsAllowed: false,
    },
    image: '',
    link: '',
    location: {
      address: '',
      ruianId: null,
      name: '',
      lat: null,
      lng: null,
    },
    price: null,
    title: '',
    visibility: VisibilityEnum.PUBLIC,
  };
}

function createFormValuesFromEventItem(
  item: EventItem,
  group: GroupItem | null
): FormValues {
  let inputMode: InputMode;

  // Determine input mode
  if (group && group.kind === Kind.PROJECT) {
    inputMode = InputMode.DATE_ONLY;
  } else if (item.dateStart === item.dateEnd) {
    inputMode = InputMode.SINGLEDAY;
  } else {
    inputMode = InputMode.MULTIDAY;

    for (const day in item.daySelection) {
      if (item.daySelection.hasOwnProperty(day)) {
        if (item.daySelection[day] === true) {
          inputMode = InputMode.REPEATING;
          break;
        }
      }
    }
  }

  return {
    annotation: item.annotation,
    areasOfInterest: item.areasOfInterest.map((area) => area.id),
    capacity: item.capacity,
    content: item.content,
    date: {
      daysOfWeek: { ...item.daySelection },
      end: item.dateEnd,
      inputMode,
      start: format(parse(item.dateStart), 'YYYY-MM-DD'),
      timeEnd: item.timeEnd,
      timeStart: item.timeStart,
    },
    flags: {
      barrierFree: item.flags.barrierFree,
      dogsAllowed: item.flags.dogsAllowed,
    },
    image: '',
    link: item.link,
    location: { ...item.location },
    price: item.price,
    title: item.title,
    visibility: item.visibility,
  };
}

interface SnackErrors {
  groupNotFound: boolean;
  navigationFailed: boolean;
  eventNotCreated: boolean;
  eventNotUpdated: boolean;
}

@Component
export default class EditEventForm
  extends VueComponentMixin<EditEventFormInterface, PrefetchComponent>(Prefetch)
  implements EditEventFormInterface {
  @Prop({ default: null })
  public eventId!: EventItem['id'] | null;

  @Prop({ default: null })
  public groupId!: GroupItem['id'] | null;

  @Ref('submitButton')
  protected readonly submitButton?: VuetifyButton;

  @Ref('form')
  protected readonly form?: VuetifyTypes.Form;

  protected loadAreasOfInterestFromAi: boolean = true;

  protected areasOfInterestSearchTerm: string = '';

  protected dateInputError: DateSelectorErrors = createDefaultDateSelectorErrors();

  protected errors: SnackErrors = {
    groupNotFound: false,
    navigationFailed: false,
    eventNotCreated: false,
    eventNotUpdated: false,
  };

  protected formStep: FormStep = FormStep.GROUP_CHOICE;

  protected formValues: FormValues = createDefaultFormValues();

  /**
   * Used for loading event data before mutation happens
   * @protected
   */
  protected immutableFormValues: FormValues = createDefaultFormValues();

  protected initializing: boolean = false;

  protected isMetaStepDisabled: boolean = true;

  protected loading: boolean = false;

  protected loadingNextStep: boolean = false;

  protected locationIsSameAsCommunity: boolean = false;

  protected locationWasUpdated: boolean = false;

  protected selectedGroupId: GroupItem['id'] | null = null;

  protected validationErrors: ValidationCommit | null = null;

  protected get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  public get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  public get eventDetailStore() {
    return getModule(EventDetailModule, this.$store);
  }

  public get eventsStore() {
    return getModule(EventsModule, this.$store);
  }

  public get eventMutationStore() {
    return getModule(EventMutationModule, this.$store);
  }

  protected get areasOfInterestLabel() {
    if (this.groupKind === Kind.GROUP) {
      return `${this.$t('app.event.areasOfInterest')}*`;
    }

    return this.$t('app.event.areasOfInterest');
  }

  protected get areasOfInterestSelectItems() {
    return createAreaOfInterestSelectItems(
      this.areasOfInterestStore.categorisedInterests
    );
  }

  protected get allErrorMessages() {
    const messages: TranslateResult[] = [];
    if (!this.validationErrors) {
      return messages;
    }

    if (this.validationErrors.dateSelection.messages.length > 0) {
      this.validationErrors.dateSelection.messages.forEach((message) => {
        messages.push(this.$t(message));
      });
    }

    if (this.validationErrors.api.length > 0) {
      this.validationErrors.api.forEach((message) => {
        messages.push(this.$t(message));
      });
    }

    if (this.validationErrors.content.length > 0) {
      this.validationErrors.content.forEach((message) => {
        messages.push(this.$t(message));
      });
    }

    if (this.validationErrors.location.length > 0) {
      this.validationErrors.location.forEach((message) => {
        messages.push(this.$t(message));
      });
    }

    if (this.validationErrors.title.length > 0) {
      this.validationErrors.title.forEach((message) => {
        messages.push(this.$t(message));
      });
    }

    return messages;
  }

  protected get backwardLabel() {
    if (this.isFirstStep) {
      return this.$t('app.common.cancel');
    }

    if (this.formStep === FormStep.LOCATION) {
      return this.$t('app.place.reset');
    }

    return this.$t('app.common.back');
  }

  protected get changedDate(): boolean {
    switch (true) {
      case this.immutableFormValues.date.start !== this.formValues.date.start:
      case this.immutableFormValues.date.end !== this.formValues.date.end:
      case this.immutableFormValues.date.daysOfWeek.monday !==
        this.formValues.date.daysOfWeek.monday:
      case this.immutableFormValues.date.daysOfWeek.tuesday !==
        this.formValues.date.daysOfWeek.tuesday:
      case this.immutableFormValues.date.daysOfWeek.wednesday !==
        this.formValues.date.daysOfWeek.wednesday:
      case this.immutableFormValues.date.daysOfWeek.thursday !==
        this.formValues.date.daysOfWeek.thursday:
      case this.immutableFormValues.date.daysOfWeek.friday !==
        this.formValues.date.daysOfWeek.friday:
      case this.immutableFormValues.date.daysOfWeek.saturday !==
        this.formValues.date.daysOfWeek.saturday:
      case this.immutableFormValues.date.daysOfWeek.sunday !==
        this.formValues.date.daysOfWeek.sunday:
        return true;
    }

    return false;
  }

  protected get dateStart(): Date {
    const parsedDate = parse(this.formValues.date.start);
    if (isNaN(parsedDate.getTime())) {
      return new Date();
    }

    return parsedDate;
  }

  protected get dateEnd(): Date | null {
    if (!this.formValues.date.end) {
      return null;
    }

    const parsedDate = parse(this.formValues.date.end);
    if (isNaN(parsedDate.getTime())) {
      return null;
    }

    return parsedDate;
  }

  protected get forwardLabel() {
    if (this.isFinalStep) {
      return this.$t('app.common.finalize');
    }

    if (this.formStep === FormStep.LOCATION) {
      return this.$t('app.place.submit');
    }

    return this.$t('app.common.next');
  }

  protected get group(): GroupItem | null {
    if (this.selectedGroupId) {
      for (const groupItem of this.groupStore.groupCache) {
        if (groupItem.id === this.selectedGroupId) {
          return groupItem;
        }
      }
    }

    return null;
  }

  protected get groupKind(): Kind {
    if (!this.group) {
      return Kind.GROUP;
    }

    return this.group.kind;
  }

  protected get communityHasLocation(): boolean {
    return !!(
      this.group &&
      this.group.location &&
      !isLocationEmpty(this.group.location)
    );
  }

  protected get communityIsProject(): boolean {
    return !!(
      this.selectedGroupId &&
      this.group &&
      this.group.kind === Kind.PROJECT
    );
  }

  protected get hasImage(): boolean {
    // TODO: API needs to inform us whether the image exists on the blob storage
    return !!this.formValues.image;
  }

  protected get imageUrl(): string {
    if (this.formValues.image) {
      return this.formValues.image;
    }

    if (this.eventId) {
      return getEventImageUrl(this.eventId, imageVersion());
    }

    return '';
  }

  protected get isBasicStepDisabled() {
    // Group must be chosen
    return !this.selectedGroupId;
  }

  protected get isFinalStep() {
    return this.formStep === FormStep.META_INFO;
  }

  protected get isFirstStep() {
    if (this.eventId) {
      // We already have a event, so we cannot show the group choice
      return this.formStep === FormStep.BASIC_INFO;
    }

    return this.formStep === FormStep.GROUP_CHOICE;
  }

  protected get isLocationValid() {
    return (
      !isLocationEmpty(this.formValues.location) &&
      isLocationValid(this.formValues.location)
    );
  }

  protected get isEventInfoStepDisabled() {
    if (this.isBasicStepDisabled) {
      return true;
    }

    if (
      this.formValues.title.trim() === '' ||
      this.formValues.content.trim() === ''
    ) {
      // Content and title must not be empty
      return true;
    }
  }

  protected get isNextStepActive(): boolean {
    if (this.formStep === FormStep.GROUP_CHOICE && this.isBasicStepDisabled) {
      return false;
    }

    if (this.formStep === FormStep.BASIC_INFO && this.isEventInfoStepDisabled) {
      return false;
    }

    if (this.formStep === FormStep.EVENT_INFO && this.isMetaStepDisabled) {
      return false;
    }

    if (this.formStep === FormStep.META_INFO && this.isSubmissionDisabled) {
      return false;
    }

    if (this.formStep === FormStep.LOCATION && !this.isLocationValid) {
      return false;
    }

    return true;
  }

  protected get isSubmissionDisabled() {
    if (this.isMetaStepDisabled) {
      return true;
    }

    if (this.group && this.group.kind === Kind.GROUP) {
      // At least one area of interest must be selected
      return this.formValues.areasOfInterest.length < 1;
    }

    return false;
  }

  protected get managedGroups(): GroupItem[] {
    return this.groupStore.items.filter(userCanCreateEvent);
  }

  protected get tabStep() {
    if (this.formStep === FormStep.LOCATION) {
      return FormStep.EVENT_INFO;
    }

    return this.formStep;
  }

  protected get title() {
    if (this.eventId) {
      return this.$t('app.events.update');
    }

    return this.$t('app.events.create');
  }
  public created() {
    this.setInitialData();
  }

  public prefetch() {
    return this.areasOfInterestStore.loadData().catch(() => {
      // not much to do here
    });
  }

  public render() {
    return (
      <v-card loading={this.initializing} disabled={this.initializing}>
        <v-card-title>
          {this.title}
          <v-spacer />
          <CloseButton onClose={this.cancel} />
        </v-card-title>
        <v-card-subtitle class='py-2'>
          {this.group && <GroupCard group={this.group} disableLink={true} />}
        </v-card-subtitle>
        <v-tabs value={this.tabStep} fixed-tabs>
          <v-tab
            class={this.eventId ? 'd-none' : ''}
            onClick={this.goToGroupSelection}
          >
            {this.$t('app.communities.chooseCommunityStep')}
          </v-tab>
          <v-tab
            disabled={this.isBasicStepDisabled}
            onClick={this.goToBasicInfo}
          >
            {this.$t('app.events.basicDataStep')}
          </v-tab>
          <v-tab
            disabled={this.isEventInfoStepDisabled}
            onClick={this.goToEventInfo}
          >
            {this.$t('app.events.eventInfoStep')}
          </v-tab>
          <v-tab disabled={this.isMetaStepDisabled} onClick={this.goToMeta}>
            {this.$t('app.events.metaDataStep')}
          </v-tab>
        </v-tabs>

        <v-divider />

        <v-card-text class={`py-${componentOffset}`}>
          <v-form
            ref='form'
            onSubmit={this.eventId ? this.submitUpdate : this.submitCreate}
            transition='scale-transition'
          >
            <v-skeleton-loader loading={this.initializing} type='card'>
              <v-tabs-items value={this.formStep}>
                <v-tab-item value={FormStep.GROUP_CHOICE}>
                  <v-row justify='center' no-gutters>
                    <v-img src={groupPlacehodler} max-width={200} />
                  </v-row>
                  <div class='text-center'>
                    {this.$t('app.events.chooseGroup')}
                  </div>
                  {this.managedGroups.map((group) => {
                    return (
                      <SelectGroupItem
                        name={group.name}
                        onSelected={() => {
                          this.selectGroup(group.id);
                        }}
                      />
                    );
                  })}
                  {this.managedGroups.length < 1 && (
                    <v-alert type='warning'>
                      {this.$t('app.error.event.noGroupsToCreateEvent')}
                    </v-alert>
                  )}
                </v-tab-item>
                <v-tab-item value={FormStep.BASIC_INFO}>
                  <v-img
                    src={this.imageUrl}
                    class={`mx-auto mb-${componentOffset}`}
                    aspect-ratio={5 / 2}
                    max-width={660}
                    key={`edit-event-form-image-${
                      this.hasImage ? 'filled-in' : 'empty'
                    }`}
                  >
                    <ImageUploadPlaceholder slot='placeholder' />
                    <ImageSelection
                      onSelected={this.setMainPhoto}
                      absolute={true}
                    >
                      {this.$t(
                        this.hasImage
                          ? 'app.common.updatePhoto'
                          : 'app.common.addPhoto'
                      )}
                    </ImageSelection>
                  </v-img>
                  <v-row no-gutters justify='center' class='py-2' />
                  <v-text-field
                    filled
                    label={this.$t('app.event.title') + '*'}
                    required
                    rounded
                    rules={[this.titleIsRequiredRule]}
                    v-model={this.formValues.title}
                  />
                  <v-textarea
                    filled
                    label={this.$t('app.event.content') + '*'}
                    required
                    rounded
                    rules={[this.contentIsRequiredRule]}
                    v-model={this.formValues.content}
                  />
                </v-tab-item>
                <v-tab-item value={FormStep.EVENT_INFO}>
                  <SelectPlaceField
                    label={`${this.$t('app.event.place')}*`}
                    onChooseLocation={this.goToLocation}
                    v-model={this.formValues.location}
                  />
                  {this.communityHasLocation && !this.communityIsProject && (
                    <v-checkbox
                      class='mt-0'
                      false-value={false}
                      input-value={this.locationIsSameAsCommunity}
                      label={this.$t('app.event.locationIsSameAsGroup')}
                      onChange={(value: boolean) => {
                        this.locationWasUpdated = false;
                        if (!value) {
                          this.formValues.location = createEmptyGeoLocation();
                        } else if (this.group && this.group.location) {
                          this.formValues.location = { ...this.group.location };
                        }
                      }}
                      true-value={true}
                    />
                  )}
                  {this.locationWasUpdated && !this.isLocationValid && (
                    <v-alert type='warning'>
                      {this.$t('app.error.event.fixLocation')}
                    </v-alert>
                  )}
                  <h4 class='mb-2'>
                    {this.$t(
                      `app.event.timeInput.${this.groupKind.toLowerCase()}.title`
                    )}
                    *
                  </h4>
                  <EventDateSelector
                    canChoosePastDates={this.groupKind === Kind.PROJECT}
                    start={this.dateStart}
                    end={this.dateEnd}
                    errorFields={this.dateInputError}
                    inputMode={this.formValues.date.inputMode}
                    timeStart={this.formValues.date.timeStart}
                    timeEnd={this.formValues.date.timeEnd}
                    daysOfWeek={this.formValues.date.daysOfWeek}
                    onUpdatedDaySelection={(daySelection) => {
                      this.formValues.date.daysOfWeek = daySelection;
                      this.dateInputError.daySelection = false;
                    }}
                    onUpdateEnd={(date) => {
                      this.dateInputError.end = false;
                      this.formValues.date.end = date
                        ? format(date, 'YYYY-MM-DD')
                        : null;
                      this.validateDateInput();
                    }}
                    onUpdateStart={(date) => {
                      if (date) {
                        this.dateInputError.start = false;
                        this.formValues.date.start = format(date, 'YYYY-MM-DD');
                        this.validateDateInput();
                        if (
                          this.formValues.date.inputMode === InputMode.DATE_ONLY
                        ) {
                          this.dateInputError.end = false;
                          this.formValues.date.end = format(date, 'YYYY-MM-DD');
                        }
                      }
                    }}
                    onUpdateTimeEnd={(time) => {
                      this.dateInputError.timeEnd = false;
                      this.formValues.date.timeEnd = time;
                      this.validateDateInput();
                    }}
                    onUpdateTimeStart={(time) => {
                      this.dateInputError.timeStart = false;
                      this.formValues.date.timeStart = time;
                      this.validateDateInput();
                    }}
                    onUpdateInputMode={this.updateInputMode}
                  />
                </v-tab-item>
                <v-tab-item value={FormStep.LOCATION}>
                  <SelectPlace
                    groupId={this.selectedGroupId || undefined}
                    v-model={this.formValues.location}
                    onInput={() => {
                      this.locationWasUpdated = true;
                    }}
                  />
                </v-tab-item>
                <v-tab-item value={FormStep.META_INFO}>
                  <v-autocomplete
                    autocomplete='off'
                    chips
                    deletable-chips
                    items={this.areasOfInterestSelectItems}
                    label={this.areasOfInterestLabel}
                    multiple
                    no-data-text={this.$t('app.common.noDataText')}
                    outlined
                    rounded
                    rules={[this.areasAreRequiredRule]}
                    search-input={this.areasOfInterestSearchTerm}
                    small-chips
                    v-model={this.formValues.areasOfInterest}
                    {...{
                      on: {
                        input: () => {
                          // Once areas are changed, do not load interests anymore
                          this.loadAreasOfInterestFromAi = false;
                          this.areasOfInterestSearchTerm = '';
                        },
                        'update:search-input': (value: string) => {
                          this.areasOfInterestSearchTerm = value;
                        },
                      },
                    }}
                  />
                  <h4>{this.$t('app.event.visibility')}*</h4>
                  <v-radio-group v-model={this.formValues.visibility}>
                    <v-radio
                      label={this.$t('app.event.public')}
                      value={VisibilityEnum.PUBLIC}
                      class='mb-0'
                    />
                    <div class='pl-5 mb-2'>
                      {this.$t('app.event.publicHint')}
                    </div>
                    <v-radio
                      label={this.$t('app.event.private')}
                      value={VisibilityEnum.PRIVATE}
                      class='mb-0'
                    />
                    <div class='pl-5 mb-2'>
                      {this.$t('app.event.privateHint')}
                    </div>
                  </v-radio-group>

                  <v-checkbox
                    v-model={this.formValues.flags.barrierFree}
                    label={this.$t('app.event.barrierFree')}
                    class='mt-0'
                  />
                  <v-checkbox
                    v-model={this.formValues.flags.dogsAllowed}
                    label={this.$t('app.event.dogsAllowed')}
                    class='mt-0'
                  />

                  <v-expansion-panels class='mt-3 mb-7'>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <h4>{this.$t('app.event.other')}</h4>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols='6'>
                            <v-text-field
                              v-model={this.formValues.price}
                              label={`${this.$t('app.event.price')}`}
                              filled
                              rounded
                            />
                            <v-text-field
                              value={this.formValues.capacity}
                              onInput={(value: string) => {
                                const numericalValue = parseInt(value, 10);
                                if (isNaN(numericalValue)) {
                                  return;
                                }
                                this.formValues.capacity = numericalValue;
                              }}
                              label={`${this.$t('app.event.capacity')}`}
                              filled
                              rounded
                              type='number'
                            />
                          </v-col>
                          <v-col cols='6'>
                            <v-text-field
                              v-model={this.formValues.link}
                              label={`${this.$t('app.event.link')}`}
                              filled
                              rounded
                            />
                          </v-col>
                        </v-row>
                        <v-textarea
                          v-model={this.formValues.annotation}
                          label={`${this.$t('app.event.annotation')}`}
                          filled
                          rounded
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tab-item>
              </v-tabs-items>
            </v-skeleton-loader>
            <v-btn ref='submitButton' class='d-none' type='submit' />
          </v-form>
        </v-card-text>
        <v-divider />
        {this.allErrorMessages.map((message) => (
          <v-alert type='error'>{message}</v-alert>
        ))}
        <v-card-actions>
          <FormActionButton
            disabled={this.loadingNextStep}
            icon={this.isFirstStep ? undefined : FormActionIcon.LEFT}
            onClick={this.goBack}
          >
            {this.backwardLabel}
          </FormActionButton>
          <v-spacer />
          <FormActionButton
            disabled={!this.isNextStepActive}
            icon={this.isFinalStep ? undefined : FormActionIcon.RIGHT}
            loading={this.loadingNextStep || this.loading}
            onClick={this.goForward}
          >
            {this.forwardLabel}
          </FormActionButton>
        </v-card-actions>
        <ErrorSnack v-model={this.errors.groupNotFound}>
          {this.$t('app.error.group.notFound')}
        </ErrorSnack>
        <ErrorSnack v-model={this.errors.navigationFailed}>
          {this.$t('app.error.generic.navigationFailure')}
        </ErrorSnack>
        <ErrorSnack v-model={this.errors.eventNotCreated}>
          {this.$t('app.error.event.notCreated')}
        </ErrorSnack>
        <ErrorSnack v-model={this.errors.eventNotUpdated}>
          {this.$t('app.error.event.notUpdated')}
        </ErrorSnack>
      </v-card>
    );
  }

  protected submitCreate(e: Event) {
    e.preventDefault();

    if (!this.selectedGroupId) {
      return;
    }

    if (this.isSubmissionDisabled) {
      if (!this.isFinalStep) {
        this.goForward();
      }

      return;
    }

    this.loading = true;

    this.eventMutationStore
      .create({
        data: this.formValues,
        groupId: this.selectedGroupId,
      })
      .catch((error: ValidationCommit) => {
        this.dateInputError = error.dateSelection.errors;
        this.validationErrors = error;
        throw new Error('Not created');
      })
      .then((event) => {
        return this.$router
          .push(
            getLocalizedLocation(routes.eventDetail, this.$router, {
              guid: event.id,
              date: findFirstInstanceDate(event),
            })
          )
          .catch(() => {
            this.errors.navigationFailed = true;
          })
          .then(() => {
            this.cancel();
          });
      })
      .catch(() => {
        this.errors.eventNotCreated = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  protected submitUpdate(e: Event) {
    e.preventDefault();

    if (!this.selectedGroupId || !this.eventId) {
      return;
    }

    if (this.isSubmissionDisabled) {
      if (!this.isFinalStep) {
        this.goForward();
      }

      return;
    }

    this.loading = true;

    const eventId = this.eventId;
    this.eventMutationStore
      .update({
        data: this.formValues,
        eventId,
        groupId: this.selectedGroupId,
      })
      .catch((error: ValidationCommit) => {
        this.dateInputError = error.dateSelection.errors;
        this.validationErrors = error;
        throw new Error('Not updated');
      })
      .then(findFirstInstanceDate)
      .then((date) => {
        if (this.formValues.image) {
          // Hack to force refresh the image
          window.location.reload();
          return;
        }

        return this.eventDetailStore
          .loadData({
            date: parse(date),
            eventId,
          })
          .then(() => {
            if (this.changedDate) {
              // Once we have the date, route to the event detail
              return this.$router
                .push(
                  getLocalizedLocation(routes.eventDetail, this.$router, {
                    guid: eventId,
                    date,
                  })
                )
                .then(() => {
                  this.cancel();
                })
                .catch(() => {
                  // Error can happen if the first date instance is the same even
                  // after changing the date
                  this.cancel();
                });
            } else {
              this.cancel();
            }
          });
      })
      .catch(() => {
        this.errors.eventNotUpdated = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @Watch('isEventInfoStepDisabled')
  @Watch('formValues.date', { deep: true })
  @Watch('formValues.location', { deep: true })
  protected setMetaStepState() {
    if (this.isEventInfoStepDisabled) {
      this.isMetaStepDisabled = true;
      return;
    }

    if (
      this.formValues.location.lat === null ||
      this.formValues.location.lng === null
    ) {
      this.isMetaStepDisabled = true;
      return;
    }

    this.eventMutationStore
      .validateDateInput({ values: this.formValues, group: this.group })
      .then((errors) => {
        this.isMetaStepDisabled = !isDateSelectionValid(errors);
      });
  }

  @Watch('formValues.location', { deep: true })
  protected setLocationRelativeToGroupLocation() {
    this.locationIsSameAsCommunity = false;

    if (this.group && this.group.location && this.formValues.location) {
      const groupLocation = this.group.location;
      const selectedLocation = this.formValues.location;
      if (
        groupLocation.address === selectedLocation.address &&
        groupLocation.lat === selectedLocation.lat &&
        groupLocation.lng === selectedLocation.lng &&
        groupLocation.ruianId === selectedLocation.ruianId &&
        groupLocation.name === selectedLocation.name
      ) {
        this.locationIsSameAsCommunity = true;
      }
    }
  }

  @Emit('cancel')
  protected cancel() {
    this.setInitialData();
    return;
  }

  protected findFirstInstanceDate(eventId: string) {
    return this.eventsStore.get({ id: eventId }).then(findFirstInstanceDate);
  }

  protected goBack() {
    if (this.isFirstStep) {
      return this.cancel();
    }

    switch (this.formStep) {
      case FormStep.META_INFO:
        this.goToEventInfo();
        break;
      case FormStep.EVENT_INFO:
        this.goToBasicInfo();
        break;
      case FormStep.LOCATION:
        // Reset location
        this.formValues.location = { ...this.immutableFormValues.location };
        this.goToEventInfo();
        break;
      case FormStep.BASIC_INFO:
        this.goToGroupSelection();
        break;
    }
  }

  protected goForward() {
    if (this.isFinalStep) {
      if (!this.submitButton) {
        return;
      }

      return this.submitButton.$el.click();
    }

    switch (this.formStep) {
      case FormStep.GROUP_CHOICE:
        if (!this.isBasicStepDisabled) {
          this.goToBasicInfo();
        }
        break;
      case FormStep.LOCATION:
      case FormStep.BASIC_INFO:
        if (!this.isEventInfoStepDisabled) {
          this.goToEventInfo();
        }
        break;
      case FormStep.EVENT_INFO:
        if (!this.isMetaStepDisabled) {
          this.goToMeta();
        }
        break;
    }
  }

  protected goToBasicInfo() {
    this.formStep = FormStep.BASIC_INFO;
  }

  protected goToEventInfo() {
    this.formStep = FormStep.EVENT_INFO;
  }

  protected goToGroupSelection() {
    this.formStep = FormStep.GROUP_CHOICE;
  }

  protected goToLocation() {
    this.formStep = FormStep.LOCATION;
  }

  protected goToMeta() {
    if (this.formStep === FormStep.META_INFO) {
      return;
    }

    if (!this.loadAreasOfInterestFromAi) {
      this.formStep = FormStep.META_INFO;
      return;
    }

    this.loadingNextStep = true;
    let aiContentRequest = '';

    if (this.formValues.title) {
      aiContentRequest += `${this.formValues.title}. `;
    }

    if (this.formValues.content) {
      aiContentRequest += `${this.formValues.content}`;
    }

    this.$api.ai
      .areas(aiContentRequest)
      .then((result) => {
        this.formValues.areasOfInterest = result;
      })
      .finally(() => {
        this.loadingNextStep = false;
        this.formStep = FormStep.META_INFO;
      });
  }

  @Watch('eventId')
  @Watch('groupId')
  protected setInitialData() {
    this.initializing = true;
    this.formStep = FormStep.GROUP_CHOICE;
    this.formValues = createDefaultFormValues();
    this.immutableFormValues = createDefaultFormValues();
    this.dateInputError = createDefaultDateSelectorErrors();
    this.locationWasUpdated = false;
    // Do not load interests if we are editing an event
    this.loadAreasOfInterestFromAi = !this.eventId;
    if (this.form) {
      this.form.resetValidation();
    }

    this.selectGroup(this.groupId)
      .then(() => {
        if (this.eventId) {
          return this.eventsStore.get({ id: this.eventId }).then((data) => {
            this.formValues = createFormValuesFromEventItem(data, this.group);
            this.immutableFormValues = createFormValuesFromEventItem(
              data,
              this.group
            );
          });
        }
      })
      .finally(() => {
        this.initializing = false;
      });
  }

  protected selectGroup(groupId: GroupItem['id'] | null) {
    if (!groupId) {
      this.selectedGroupId = null;
      return Promise.resolve();
    }

    // TODO: these groups are not part of the cache, so we need to fetch it if it does not exist

    for (const groupItem of this.groupStore.groupCache) {
      if (groupItem.id === groupId) {
        this.selectedGroupId = groupId;
        this.setInitialProjectGroupData();
        this.$nextTick(this.goToBasicInfo);
        return Promise.resolve();
      }
    }

    this.loadingNextStep = true;
    return this.groupStore
      .load({ groupId })
      .then(() => {
        this.selectedGroupId = groupId;
        this.setInitialProjectGroupData();
        this.$nextTick(this.goToBasicInfo);
      })
      .catch(() => {
        this.errors.groupNotFound = true;
      })
      .finally(() => {
        this.loadingNextStep = false;
      });
  }

  protected setMainPhoto(data: ImageSelectionEvent) {
    if (data.firstImage) {
      this.formValues.image = data.firstImage;
    }
  }

  protected updateInputMode(mode: InputMode) {
    if (this.group && this.group.kind === Kind.PROJECT) {
      this.setDefaultProjectDateInput();
      return;
    }

    if (mode === InputMode.SINGLEDAY) {
      this.formValues.date.end = null;
    } else if (
      (mode === InputMode.MULTIDAY || mode === InputMode.REPEATING) &&
      this.immutableFormValues.date.end
    ) {
      this.formValues.date.end = this.immutableFormValues.date.end;
    }

    if (
      mode === InputMode.REPEATING &&
      this.immutableFormValues.date.daysOfWeek
    ) {
      this.formValues.date.daysOfWeek = {
        ...this.immutableFormValues.date.daysOfWeek,
      };
    } else {
      for (const daysOfWeekKey in this.formValues.date.daysOfWeek) {
        if (this.formValues.date.daysOfWeek.hasOwnProperty(daysOfWeekKey)) {
          this.formValues.date.daysOfWeek[daysOfWeekKey] = false;
        }
      }
    }

    this.formValues.date.inputMode = mode;
    this.validateDateInput();
  }

  protected titleIsRequiredRule(input: string): boolean | TranslateResult {
    if (input.trim() === '') {
      return this.$t('app.error.event.titleIsRequired');
    }

    return true;
  }

  protected contentIsRequiredRule(input: string): boolean | TranslateResult {
    if (input.trim() === '') {
      return this.$t('app.error.event.contentIsRequired');
    }

    return true;
  }

  protected areasAreRequiredRule(input: any): boolean | TranslateResult {
    if (this.groupKind === Kind.PROJECT) {
      return true;
    }

    if (input.length < 1) {
      return this.$t('app.error.event.areasAreRequired');
    }

    return true;
  }

  protected setDefaultProjectDateInput() {
    this.formValues.date.inputMode = InputMode.DATE_ONLY;
    this.formValues.date.end = null;
    this.formValues.date.timeStart = '12:00';
    this.formValues.date.timeEnd = '12:01';
    for (const daysOfWeekKey in this.formValues.date.daysOfWeek) {
      if (this.formValues.date.daysOfWeek.hasOwnProperty(daysOfWeekKey)) {
        this.formValues.date.daysOfWeek[daysOfWeekKey] = false;
      }
    }
  }

  protected setInitialProjectGroupData() {
    if (this.eventId) {
      return;
    }

    if (this.group && this.group.kind === Kind.PROJECT) {
      this.setDefaultProjectDateInput();
      if (this.group.location) {
        this.formValues.location = { ...this.group.location };
        this.immutableFormValues.location = { ...this.group.location };
      }
    } else {
      this.formValues.date = createDefaultFormValues().date;
      this.formValues.location = createDefaultFormValues().location;
      this.immutableFormValues.date = createDefaultFormValues().date;
      this.immutableFormValues.location = createDefaultFormValues().location;
    }
  }

  protected validateDateInput() {
    this.dateInputError.isInPast = false;
    this.dateInputError.endBeforeStart = false;

    if (!this.formValues.date.start) {
      return;
    }

    if (this.formValues.date.timeStart && this.groupKind === Kind.GROUP) {
      const start = parse(
        `${this.formValues.date.start} ${this.formValues.date.timeStart}`
      );
      if (!start.getTime() || start.getTime() <= Date.now()) {
        this.dateInputError.isInPast = true;
      }
    }

    if (this.formValues.date.inputMode === InputMode.SINGLEDAY) {
      if (this.formValues.date.timeStart && this.formValues.date.timeEnd) {
        const start = parse(
          `${this.formValues.date.start} ${this.formValues.date.timeStart}`
        );
        const end = parse(
          `${this.formValues.date.start} ${this.formValues.date.timeEnd}`
        );
        if (start > end) {
          this.dateInputError.endBeforeStart = true;
        }
      }

      return;
    }

    if (this.formValues.date.inputMode === InputMode.MULTIDAY) {
      if (this.formValues.date.end) {
        const start = parse(`${this.formValues.date.start} 00:00`);
        const end = parse(`${this.formValues.date.end} 00:00`);
        if (start > end) {
          this.dateInputError.endBeforeStart = true;
        }
      }
    }
  }
}
