export default {
  app: {
    events: {
      basicDataStep: 'Základní informace',
      buttonSubtitle: 'Čas, místo, popis události',
      chooseGroup: 'Vyberte skupinu, pro kterou chcete založit událost',
      create: 'Založit událost',
      deleted: 'Událost byla smazána',
      detailLink: 'Přečíst více',
      edit: 'Upravit událost',
      eventInfoStep: 'Upřesnění události',
      metaDataStep: 'Zaměření události',
      noItemsMessage: 'Nenalezeny žádné události',
      reported: 'Událost byla nahlášena',
      title: 'Události',
      update: 'Upravit událost',
    },
  },
};
