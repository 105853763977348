import { Component, Emit, Prop } from 'vue-property-decorator';
import { ValueProp, VueComponent } from '~/utils/vue-component';
import { successColor } from '~/utils/theme/colors';

export interface SucccessSnackInterface extends ValueProp<boolean> {
  multiLine?: boolean;
  timeout?: number;
}

@Component
export default class SucccessSnack extends VueComponent<SucccessSnackInterface>
  implements SucccessSnackInterface {
  @Prop({ required: true, type: Boolean })
  public value!: boolean;

  @Prop({ default: false, type: Boolean })
  public multiLine!: boolean;

  @Prop({ default: 4000, type: Number })
  public timeout!: number;

  public render() {
    return (
      <v-snackbar
        centered
        color={successColor}
        multi-line={this.multiLine}
        onInput={this.emitInput}
        timeout={this.timeout}
        value={this.value}
      >
        {this.$slots.default}
        <span slot='action'>
          <v-btn text onClick={this.close}>
            {this.$t('app.common.close')}
          </v-btn>
        </span>
      </v-snackbar>
    );
  }

  @Emit('input')
  protected emitInput(value: boolean) {
    return value;
  }

  @Emit('input')
  protected close() {
    return false;
  }
}
