import { Store } from 'vuex';
import { VuexModule } from 'vuex-module-decorators';

import { APIClient } from '~/app/core/apiClient';
import VueI18n from 'vue-i18n';

export default class AbstractModule extends VuexModule {
  public $api!: APIClient;

  protected store!: Store<any>;
}
