import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { EventSearchFormData } from '../eventSearchForm/EventsSearchForm';
import { FilterChip } from '~/components/molecules';
import { EventAuthor } from '~/app/core/store/modules/EventsModule';
import { parse, format } from '~/utils/date-fns';
import { defaultDateFormat } from '~/utils/dateTime';
import { AreaOfInterestItem } from '~/utils/areasOfInterest';

type RemoveSearchTextEvent = void;
interface RemoveInterestEvent {
  value: string;
}
type RemoveAuthorEvent = void;
type RemoveBarrierFreeEvent = void;
type RemoveDogsAllowedEvent = void;
type RemoveDatesEvent = void;

interface EventSearchFormChipsInterface {
  formData: EventSearchFormData;
  interests: AreaOfInterestItem[];
  authors: EventAuthor[];
  onRemoveSearchText?: (data: RemoveSearchTextEvent) => any;
  onRemoveInterest?: (data: RemoveInterestEvent) => any;
  onRemoveAuthor?: (data: RemoveAuthorEvent) => any;
  onRemoveBarrierFree?: (data: RemoveBarrierFreeEvent) => any;
  onRemoveDogsAllowed?: (data: RemoveDogsAllowedEvent) => any;
  onRemoveDates?: (data: RemoveDatesEvent) => any;
}

@Component
export default class EventSearchFormChips
  extends VueComponent<EventSearchFormChipsInterface>
  implements EventSearchFormChipsInterface {
  @Prop({ required: true })
  public authors!: EventAuthor[];

  @Prop({ required: true })
  public formData!: EventSearchFormData;

  @Prop({ required: true })
  public interests!: AreaOfInterestItem[];

  protected get searchIsEmpty(): boolean {
    return this.formData.searchText.trim() === '';
  }

  public render() {
    return (
      <div>
        <FilterChip
          label={this.formData.searchText}
          onRemove={this.emitRemoveSearchText}
          visible={!this.searchIsEmpty}
        />
        {this.interests.map((interest) => {
          return (
            <FilterChip
              label={interest.title}
              onRemove={this.emitRemoveInterest}
              value={interest.id}
              visible={this.formData.interests.indexOf(interest.id) > -1}
            />
          );
        })}
        {this.renderAuthorChip()}
        <FilterChip
          label={this.$t('app.eventsSearchForm.barrierFreeLabel').toString()}
          onRemove={this.emitRemoveBarrierFree}
          visible={this.formData.barrierFree}
        />
        <FilterChip
          label={this.$t('app.eventsSearchForm.dogsAllowedLabel').toString()}
          onRemove={this.emitRemoveDogsAllowed}
          visible={this.formData.dogsAllowed}
        />
        {this.renderDates()}
      </div>
    );
  }

  protected renderAuthorChip() {
    if (!this.formData.author) {
      return;
    }

    for (const author of this.authors) {
      if (this.formData.author === author.id) {
        return (
          <FilterChip
            label={author.name}
            value={this.formData.author.toString()}
            onRemove={this.emitRemoveAuthor}
          />
        );
      }
    }
  }
  protected renderDates() {
    const labels: string[] = [];

    if (this.formData.dateFrom) {
      labels.push(
        this.$t('app.eventsSearchForm.dateFromLabel') +
          ' ' +
          format(parse(this.formData.dateFrom), defaultDateFormat)
      );
    }

    if (this.formData.dateTo) {
      labels.push(
        this.$t('app.eventsSearchForm.dateToLabel') +
          ' ' +
          format(parse(this.formData.dateTo), defaultDateFormat)
      );
    }

    return (
      <FilterChip
        label={labels.join(' - ')}
        onRemove={this.emitRemoveDates}
        visible={labels.length > 0}
      />
    );
  }

  @Emit('removeSearchText')
  protected emitRemoveSearchText(): RemoveSearchTextEvent {
    return;
  }

  @Emit('removeInterest')
  protected emitRemoveInterest(data: RemoveInterestEvent): RemoveInterestEvent {
    return data;
  }

  @Emit('removeAuthor')
  protected emitRemoveAuthor(): RemoveAuthorEvent {
    return;
  }

  @Emit('removeBarrierFree')
  protected emitRemoveBarrierFree(): RemoveBarrierFreeEvent {
    return;
  }

  @Emit('removeDogsAllowed')
  protected emitRemoveDogsAllowed(): RemoveDogsAllowedEvent {
    return;
  }

  @Emit('removeDates')
  protected emitRemoveDates(): RemoveDatesEvent {
    return;
  }
}
