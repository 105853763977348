export default {
  app: {
    surveys: {
      addOption: 'Přidat další volbu',
      basicDataStep: 'Základní informace',
      buttonSubtitle: 'Nechte hlasovat o zajímavých věcech',
      chooseGroup: 'Vyberte skupinu, pro kterou chcete vytvořit anketu',
      create: 'Přidat anketu',
      metaDataStep: 'Zaměření ankety',
      optionNumber: '{number}. volba',
      optionsStep: 'Volby ankety',
      reported: 'Anketa byla nahlášena',
    },
  },
};
