import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';

import Feeds from './feeds';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  Feeds,
]) as LocaleMessages;

export default mergedLocalizations;
