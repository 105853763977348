import { Component, Emit, Prop } from 'vue-property-decorator';

import typography from '~/utils/typography';
import { VueComponent } from '~/utils/vue-component';

export interface BannerButtonInterface {
  active?: boolean;
}

@Component
export default class BannerButton extends VueComponent<BannerButtonInterface> {
  @Prop({ default: true, type: Boolean })
  public active!: boolean;

  public render() {
    if (!this.active) {
      return;
    }

    return (
      <v-card class='px-2' onClick={this.emitClick}>
        <v-row no-gutters class='flex-nowrap' align='center'>
          <v-avatar class='ml-1 mr-3' size='48'>
            {this.$slots.icon}
          </v-avatar>
          <div class='py-2'>
            <div class={`${typography.subtitle} text-no-wrap`}>
              {this.$slots.default}
            </div>
            <div class={`${typography.caption} text-no-wrap`}>
              {this.$slots.subtitle}
            </div>
          </div>
        </v-row>
      </v-card>
    );
  }

  @Emit('click')
  protected emitClick() {
    return;
  }
}
