import { Component, Emit, Prop } from 'vue-property-decorator';
import ReportButton, {
  ReportEvent,
} from '~/components/atoms/reportButton/ReportButton';
import DeleteButton, {
  DeleteEvent,
} from '~/components/atoms/deleteButton/DeleteButton';
import EditButton, {
  EditEvent,
} from '~/components/atoms/editButton/EditButton';
import { VueComponent } from '~/utils/vue-component';
import Submenu from '../submenu/Submenu';
import { TranslateResult } from 'vue-i18n';
import ShareButton, {
  ShareEvent,
} from '~/components/atoms/shareButton/ShareButton';

export interface ToolbarButtonProps {
  disabled: boolean;
  loading: boolean;
}

export interface DeleteButtonProps extends ToolbarButtonProps {
  confirmationTitle?: string | TranslateResult;
}

export interface ShareButtonProps extends ToolbarButtonProps {
  uri?: string;
}

interface ToolbarSubmenuInterface {
  delete?: DeleteButtonProps;
  edit?: ToolbarButtonProps;
  report?: ToolbarButtonProps;
  share?: ShareButtonProps;
  onEdit?: (data: EditEvent) => void;
  onDelete?: (data: DeleteEvent) => void;
  onReport?: (data: ReportEvent) => void;
  onShare?: (data: ShareEvent) => void;
}

@Component
export default class ToolbarSubmenu
  extends VueComponent<ToolbarSubmenuInterface>
  implements ToolbarSubmenuInterface {
  @Prop()
  public delete?: DeleteButtonProps;

  @Prop()
  public edit?: ToolbarButtonProps;

  @Prop()
  public report?: ToolbarButtonProps;

  @Prop()
  public share?: ShareButtonProps;

  protected get active(): boolean {
    return (
      !!this.delete ||
      !!this.edit ||
      !!this.report ||
      !!this.share ||
      !!this.$slots.prependButton ||
      !!this.$slots.appendButton
    );
  }

  public render() {
    if (!this.active) {
      return;
    }
    return (
      <Submenu class='ml-1'>
        {this.$slots.prependButton &&
          this.$slots.prependButton.map((button) => {
            return (
              <v-list-item>
                <v-list-item-title>{button}</v-list-item-title>
              </v-list-item>
            );
          })}
        {this.share && this.share.uri && (
          <v-list-item>
            <v-list-item-title>
              <ShareButton
                block
                class='justify-start'
                disabled={this.share.disabled}
                loading={this.share.loading}
                uri={this.share.uri}
                onShare={this.emitShare}
              />
            </v-list-item-title>
          </v-list-item>
        )}
        {this.edit && (
          <v-list-item>
            <v-list-item-title>
              <EditButton
                block
                class='justify-start'
                disabled={this.edit.disabled}
                loading={this.edit.loading}
                onEdit={this.emitEdit}
              />
            </v-list-item-title>
          </v-list-item>
        )}
        {this.delete && (
          <v-list-item>
            <v-list-item-title>
              <DeleteButton
                block
                class='justify-start'
                disabled={this.delete.disabled}
                loading={this.delete.loading}
                onDelete={this.emitDelete}
              >
                {this.delete.confirmationTitle && (
                  <span slot='deleteConfirmationTitle'>
                    {this.delete.confirmationTitle}
                  </span>
                )}
              </DeleteButton>
            </v-list-item-title>
          </v-list-item>
        )}
        {this.report && (
          <v-list-item>
            <v-list-item-title>
              <ReportButton
                block
                class='justify-start'
                loading={this.report.loading}
                reported={this.report.disabled}
                onReport={this.emitReport}
              />
            </v-list-item-title>
          </v-list-item>
        )}
        {this.$slots.appendButton &&
          this.$slots.appendButton.map((button) => {
            return (
              <v-list-item>
                <v-list-item-title>{button}</v-list-item-title>
              </v-list-item>
            );
          })}
      </Submenu>
    );
  }

  @Emit('edit')
  protected emitEdit(): EditEvent {
    return;
  }

  @Emit('delete')
  protected emitDelete(): DeleteEvent {
    return;
  }

  @Emit('report')
  protected emitReport(): ReportEvent {
    return;
  }

  @Emit('share')
  protected emitShare(): ShareEvent {
    return;
  }
}
