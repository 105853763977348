import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseButton from '~/components/atoms/baseButton/BaseButton';

export type ShowCommentsEvent = undefined;

interface CommentsButtonInterface {
  active?: boolean;
  commentsCount?: number | null;
  onShowComments?: (data: ShowCommentsEvent) => void;
}

@Component
export default class CommentsButton extends BaseButton<CommentsButtonInterface>
  implements CommentsButtonInterface {
  @Prop({ default: true })
  public active!: boolean;

  @Prop({ required: true })
  public commentsCount!: number | null;

  public render() {
    if (!this.active) {
      return;
    }

    return (
      <v-btn onClick={this.emitShowComments} text {...this.baseAttributes}>
        <v-icon left>mdi-comment-outline</v-icon>
        {this.$t('app.comment.comments')}
        {this.commentsCount !== null ? ` (${this.commentsCount})` : ''}
      </v-btn>
    );
  }

  @Emit('showComments')
  protected emitShowComments(): ShowCommentsEvent {
    return;
  }
}
