import { Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import { GeoLocation } from '~/utils/location';

export interface LocationCommit {
  lat: number | null;
  lng: number | null;
  id: string | null;
  name?: string;
  address?: string;
}

interface GeoLocationCommit {
  lat: number | null;
  lng: number | null;
}

type LocationNameCommit = string;

type LocationAddressCommit = string;

@Module({
  name: 'LocationPickerModule',
  stateFactory: true,
  namespaced: true,
})
export default class LocationPickerModule extends AbstractModule {
  protected lat: number | null = null;
  protected lng: number | null = null;
  protected name: string = '';
  protected address: string = '';
  protected id: string | null = null;

  public get geolocation(): GeoLocation {
    return {
      address: this.address,
      ruianId: null,
      lat: this.lat,
      lng: this.lng,
      name: this.name,
    };
  }

  public get isLocationEmpty(): boolean {
    return !this.lat && !this.lng && !this.name && !this.address;
  }

  @Mutation
  public setLocation(location: LocationCommit) {
    this.lat = location.lat;
    this.lng = location.lng;
    this.id = location.id;
    this.name = location.name || '';
    this.address = location.address || '';
  }

  @Mutation
  public resetLocation() {
    this.address = '';
    this.id = null;
    this.lat = null;
    this.lng = null;
    this.name = '';
  }

  @Mutation
  public setGeoLocation(data: GeoLocationCommit) {
    this.lat = data.lat;
    this.lng = data.lng;
  }

  @Mutation
  public setName(data: LocationNameCommit) {
    this.name = data;
  }

  @Mutation
  public setAddress(data: LocationAddressCommit) {
    this.address = data;
  }
}
