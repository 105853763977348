import { Component, Emit, Prop } from 'vue-property-decorator';

import { icons } from '~/utils/survey';
import BaseButton from '~/components/atoms/baseButton/BaseButton';

export interface CreateSurveyButtonInterface {
  active?: boolean;
  onCreate?: () => void;
}

@Component
export default class CreateSurveyButton extends BaseButton<
  CreateSurveyButtonInterface
> {
  @Prop({ default: true, type: Boolean })
  public active!: boolean;

  public render() {
    if (!this.active) {
      return;
    }

    return (
      <v-btn
        color='secondary'
        onClick={this.emitCreate}
        rounded
        {...this.baseAttributes}
      >
        <v-icon left>{icons.create}</v-icon>
        {this.$slots.default || this.$t('app.surveys.create')}
      </v-btn>
    );
  }

  @Emit('create')
  protected emitCreate() {
    return;
  }
}
