import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { GroupTile } from '~/components/molecules';
import { GroupItem } from '~/utils/group';

export interface GroupTilesInterface {
  dense?: boolean;
  items: GroupItem[];
  scopedSlots?: {
    tile?: (item: GroupItem) => JSX.Element | JSX.Element[] | void;
  };
}

@Component
export default class GroupTiles extends VueComponent<GroupTilesInterface>
  implements GroupTilesInterface {
  @Prop({ default: false })
  public dense!: boolean;

  @Prop({ required: true })
  public items!: GroupItem[];

  public render() {
    if (this.items.length < 1) {
      // TODO: Information for the user that he has joined every group already
      return;
    }
    return (
      <v-row justify='space-around' class='flex-wrap' dense={this.dense}>
        {this.items.map((item) => {
          return (
            <v-col cols='12' sm='6' md='4' lg={this.dense ? 3 : 4}>
              <GroupTile
                key={`group-tile-${item.id}`}
                class='fill-height'
                group={item}
              >
                {this.$scopedSlots.tile ? this.$scopedSlots.tile(item) : null}
              </GroupTile>
            </v-col>
          );
        })}
      </v-row>
    );
  }
}
