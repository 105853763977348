import { Component, Prop } from 'vue-property-decorator';

import { VueComponent } from '~/utils/vue-component';
import { FutureEvent } from '~/utils/event';
import { format, parse } from '~/utils/date-fns';
import { defaultDateFormat } from '~/utils/dateTime';
import { componentOffset } from '~/utils/spacing';

import style from './EventPlan.scss';

interface EventPlanInterface {
  plan: FutureEvent[];
}

const rootClass = 'ys-event-plan';

const previewSize = 2;

@Component({ style })
export default class EventPlan extends VueComponent<EventPlanInterface>
  implements EventPlanInterface {
  @Prop({ required: true, type: Array })
  public plan!: FutureEvent[];

  protected showPlan: boolean = false;

  protected get planPreview(): string {
    const planItems = this.plan.slice(0, previewSize).map((plan) => {
      return format(parse(plan.start), `dddd ${defaultDateFormat}`);
    });

    return planItems.join(', ') + (this.plan.length > previewSize ? '...' : '');
  }

  public render() {
    if (this.plan.length < 1) {
      return;
    }

    return (
      <div class={`${rootClass} py-${componentOffset}`}>
        <div>
          <span class='font-weight-bold'>
            {this.$t('app.event.futurePlan')}:
          </span>{' '}
          <span>{this.planPreview}</span>
        </div>
        {this.plan.length > previewSize && [
          <v-btn
            color='secondary'
            text
            onClick={() => {
              this.showPlan = !this.showPlan;
            }}
          >
            {this.$t('app.event.showFuturePlan')}
            <v-icon right>
              {this.showPlan ? 'mdi-chevron-down' : 'mdi-chevron-right'}
            </v-icon>
          </v-btn>,
          <v-expansion-panels flat value={this.showPlan ? 0 : -1}>
            <v-expansion-panel>
              <v-expansion-panel-content flat color='transparent'>
                <v-simple-table class={`${rootClass}__table`}>
                  <tbody>
                    {this.plan.map((item) => {
                      return (
                        <tr class='text-capitalize'>
                          <td>{format(parse(item.start), 'dddd')}</td>
                          <td>
                            {format(parse(item.start), defaultDateFormat)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>,
        ]}
      </div>
    );
  }
}
