import { Component, Prop } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import { CloseButton, FormActionButton, JoinButton } from '~/components/atoms';
import { GroupItem, Kind } from '~/utils/group';
import VDialogCloseHandler, {
  VDialogCloseHandlerInterface,
} from '~/mixins/VDialogCloseHandler';
import { JoinStatus } from '~/components/atoms/joinButton/JoinButton';
import { ErrorSnack, SuccessSnack } from '~/components/molecules';
import { getModule } from 'vuex-module-decorators';
import GroupModule from '~/app/core/store/modules/GroupModule';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

interface RequestMembershipDialogInterface
  extends VDialogCloseHandlerInterface {
  group: GroupItem | null;
}

interface Snacks {
  membershipRequestFailed: boolean;
  requestedMembership: boolean;
}

@Component
export default class RequestMembershipDialog
  extends VueComponentMixin<
    RequestMembershipDialogInterface,
    VDialogCloseHandlerInterface
  >(VDialogCloseHandler)
  implements RequestMembershipDialogInterface {
  @Prop({ required: true })
  public group!: GroupItem | null;

  protected snacks: Snacks = {
    membershipRequestFailed: false,
    requestedMembership: false,
  };

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  public render() {
    return (
      <div>
        <v-dialog {...this.vDialogAttributes} max-width={600}>
          {this.group && (
            <v-form>
              <v-card>
                <v-card-title>
                  {this.group.name}
                  <v-spacer />
                  <CloseButton onClose={this.vDialogClose} />
                </v-card-title>
                <v-card-text>
                  <p>
                    {this.$t(
                      `app.${this.group.kind.toLowerCase()}.joinPrivateHint`
                    )}
                  </p>
                </v-card-text>
                <v-card-actions>
                  <FormActionButton
                    disabled={this.group.state.joining}
                    onClick={this.vDialogClose}
                  >
                    {this.$t('app.common.cancel')}
                  </FormActionButton>

                  <v-spacer />

                  <JoinButton
                    loading={this.group.state.joining}
                    onJoin={this.requestMembership}
                    status={JoinStatus.NONE}
                    text
                  >
                    {this.$t('app.communities.requestMembership')}
                  </JoinButton>
                </v-card-actions>
              </v-card>
            </v-form>
          )}
        </v-dialog>
        <SuccessSnack v-model={this.snacks.requestedMembership}>
          {this.$t('app.communities.requestMembershipSent')}
        </SuccessSnack>
        <ErrorSnack v-model={this.snacks.membershipRequestFailed}>
          {this.$t('app.communities.requestMembershipFailed')}
        </ErrorSnack>
      </div>
    );
  }

  protected requestMembership() {
    if (!this.group) {
      return Promise.reject();
    }

    return this.groupStore
      .requestMembership(this.group.id)
      .then(() => {
        this.snacks.requestedMembership = true;
        if (this.vDialogClose) {
          this.vDialogClose();
        }
      })
      .catch(() => {
        this.snacks.membershipRequestFailed = true;
      });
  }
}
