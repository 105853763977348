import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { VueComponentMixin } from '~/utils/vue-component';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import HeadManagement from '~/mixins/HeadManagement';
import createSeoTags, { TYPE } from '~/utils/views/createSeoTags';
import { getLocaleFromRouter, getLocalizedLocation } from '~/app/core/router';
import { EventList, FeedList, GroupList } from '~/components/organisms';
import GroupModule from '~/app/core/store/modules/GroupModule';
import { ButtonSize } from '~/components/atoms';
import ShowAllButton from '~/components/atoms/showAllButton/ShowAllButton';
import { routes } from '~/app/core/router/routes';

enum DisplayedTab {
  PERSONALIZED_FEED,
  FEED,
  EVENTS,
}

@Component
export default class Homepage extends VueComponentMixin<{}, PrefetchComponent>(
  Prefetch,
  HeadManagement
) {
  protected displayedTab: DisplayedTab = DisplayedTab.PERSONALIZED_FEED;

  public title() {
    return this.$t('app.common.appName');
  }

  public headTags(): string {
    return createSeoTags(
      this.$t('app.common.appName'),
      this.$t('app.common.appDescription'),
      `/${getLocaleFromRouter(this.$router)}`,
      TYPE.WEBSITE
    );
  }

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  protected get groups() {
    return this.groupStore.items.length > 4
      ? this.groupStore.items.slice(0, 4)
      : this.groupStore.items;
  }

  public prefetch() {
    if (this.$ssrContext || this.groupStore.items.length > 0) {
      Promise.resolve();
    }
    return this.groupStore.initItems().catch(() => {
      // not much to do
    });
  }

  public render() {
    return (
      <v-container key={'unique-id-homepage'}>
        <GroupList
          class='pb-0 mt-2 mb-8'
          title={this.$t('app.group.myGroups')}
          items={this.groups}
        >
          <ShowAllButton
            size={ButtonSize.NORMAL}
            slot='bottomRightActions'
            onShowAll={() => {
              this.$router.push(
                getLocalizedLocation(routes.groups, this.$router, {})
              );
            }}
          />
        </GroupList>
        <div class='ys-inner-space'>
          <v-tabs
            v-model={this.displayedTab}
            fixed-tabs
            class='v-tabs--forcehide-arrows'
          >
            <v-tab>{this.$t('app.common.personalized')}</v-tab>
            <v-tab>{this.$t('app.common.all')}</v-tab>
            <v-tab>{this.$t('app.event.soonest')}</v-tab>
          </v-tabs>
          <v-row justify='center'>
            <v-col cols='12' md='10' lg='9'>
              <v-tabs-items v-model={this.displayedTab}>
                <v-tab-item value={DisplayedTab.PERSONALIZED_FEED}>
                  <FeedList personalized={true} />
                </v-tab-item>
                <v-tab-item value={DisplayedTab.FEED}>
                  <FeedList personalized={false} />
                </v-tab-item>
                <v-tab-item value={DisplayedTab.EVENTS}>
                  <EventList />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </div>
      </v-container>
    );
  }
}
