import {
  CzYsP14KcCommunityApiDtoCommunityDetailObject,
  CzYsP14KcCommunityApiDtoCommunityListDetailObject,
  CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity,
} from '~/app/core/apiClient/api';
import {
  CommunityItem,
  GroupItem,
  Kind,
  MCP14_GROUP_ID,
  ProjectPriority,
  ProjectState,
  userCanEditGroup,
  Visibility,
} from './';
import {
  AreaOfInterestItem,
  mapAreasOfInterest,
} from '~/utils/areasOfInterest';
import isEventInstanceGroupItem from './isEventInstanceGroupItem';
import { createGeoLocation } from '~/utils/googleMaps';
import getGroupImageUrl from '~/utils/group/getGroupImageUrl';
import { ImageSize, imageVersion } from '~/utils/media';
import MembershipTypeEnum = CzYsP14KcCommunityApiDtoCommunityDetailObject.MembershipTypeEnum;
import GroupColorEnum = CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity.ColorEnum;
import VisibilityEnum = CzYsP14KcCommunityApiDtoCommunityListDetailObject.VisibilityEnum;

type Inputs =
  | CzYsP14KcCommunityApiDtoCommunityDetailObject
  | CzYsP14KcCommunityApiDtoCommunityListDetailObject
  | CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity;

function isCommunityObject(
  data: any
): data is
  | CzYsP14KcCommunityApiDtoCommunityDetailObject
  | CzYsP14KcCommunityApiDtoCommunityListDetailObject {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.description === 'string' &&
    typeof data.visibility === 'string'
  );
}

function isCommunityDetail(
  data: any
): data is CzYsP14KcCommunityApiDtoCommunityDetailObject {
  return (
    isCommunityObject(data) &&
    typeof (data as any).badge !== 'undefined' &&
    typeof (data as any).canJoin !== 'undefined'
  );
}

export default function(
  data: Inputs,
  areasOfInterest: AreaOfInterestItem[]
): CommunityItem | null {
  if (!data.id || !data.name) {
    return null;
  }

  let areas: AreaOfInterestItem['id'][] = [];
  let description = '';
  let editable = false;
  let members: number | null = null;
  let membershipType: GroupItem['membershipType'] = null;
  let visibility: Visibility = Visibility.PRIVATE;
  let location = createGeoLocation();

  if (isEventInstanceGroupItem(data)) {
    if (data.color) {
      membershipType = getMembershipTypeFromColor(data.color);
    }
  } else {
    if (data.areasOfInterest) {
      areas = data.areasOfInterest;
    }

    if (data.membershipType) {
      membershipType = data.membershipType;
    }
  }

  if (membershipType) {
    editable = userCanEditGroup(membershipType);
  }

  if (isCommunityObject(data)) {
    description = data.description;
    switch (data.visibility) {
      case VisibilityEnum.OFFICIAL:
        visibility = Visibility.OFFICIAL;
        break;
      case VisibilityEnum.PUBLIC:
        visibility = Visibility.PUBLIC;
        break;
      case VisibilityEnum.PRIVATE:
        visibility = Visibility.PRIVATE;
        break;
    }
  }

  let kind: Kind = Kind.GROUP;

  switch (data.kind) {
    case CzYsP14KcCommunityApiDtoCommunityDetailObject.KindEnum.GROUP:
    case CzYsP14KcCommunityApiDtoCommunityListDetailObject.KindEnum.GROUP:
    case CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity.KindEnum.GROUP:
      kind = Kind.GROUP;
      break;
    case CzYsP14KcCommunityApiDtoCommunityDetailObject.KindEnum.PROJECT:
    case CzYsP14KcCommunityApiDtoCommunityListDetailObject.KindEnum.PROJECT:
    case CzYsP14KcEventApiDtoEventInstanceForMapObjectCommunity.KindEnum
      .PROJECT:
      kind = Kind.PROJECT;
      break;
  }

  // It is possible that place is null, however there is a bug in
  // swagger code gen, which does not allow for definition of nullable $ref fields
  // https://github.com/swagger-api/swagger-core/issues/3518
  // https://github.com/springdoc/springdoc-openapi/issues/573
  // Therefore we must check for presence of data.place
  if (isCommunityDetail(data) && data.place !== null) {
    location = createGeoLocation(data.place);
    members = data.members;
  }

  if (data.id === MCP14_GROUP_ID) {
    visibility = Visibility.OFFICIAL;
  }

  const mediaVersion = imageVersion();

  let communityItem: CommunityItem = {
    areasOfInterest: mapAreasOfInterest(areas || [], areasOfInterest),
    description,
    id: data.id,
    image: {
      thumbnail: {
        src: getGroupImageUrl(data.id, mediaVersion, ImageSize.THUMBNAIL),
      },
      small: {
        src: getGroupImageUrl(data.id, mediaVersion, ImageSize.SMALL),
      },
      original: {
        src: getGroupImageUrl(data.id, mediaVersion, ImageSize.ORIGINAL),
      },
    },
    kind,
    location,
    members,
    membershipType: membershipType || null,
    name: data.name,
    state: {
      editable,
      joining: false,
      loading: false,
    },
    visibility,
  };

  if (communityItem.kind === Kind.PROJECT) {
    // TODO: After API is updated, remove defaults and ts ignore
    communityItem = {
      ...communityItem,
      // @ts-ignore
      projectPriority: data.data ? data.data.priority : ProjectPriority.A,
      // @ts-ignore
      projectState: data.data ? data.data.state : ProjectState.INTENT,
    };
  }

  return communityItem;
}

function getMembershipTypeFromColor(
  color: GroupColorEnum
): MembershipTypeEnum | null {
  // TODO: add color for MCP14(?), add DEPUTY when implemented
  switch (color) {
    case GroupColorEnum.MCP14:
      return MembershipTypeEnum.MEMBER;
    case GroupColorEnum.MEMBER:
      return MembershipTypeEnum.MEMBER;
    case GroupColorEnum.OWNER:
      return MembershipTypeEnum.OWNER;
    default:
      return null;
  }
}
