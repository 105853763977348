import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { format, parse } from '~/utils/date-fns';
import { getModule } from 'vuex-module-decorators';
import { dateTimeFormatWithDays } from '~/utils/dateTime';

import style from './PostTile.scss';
import { Content, PostKindLabel } from '~/components/atoms';
import { PostItem, PostSource } from '~/utils/post';
import PostsModule from '~/app/core/store/modules/PostsModule';
import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';

import { HtmlRenderer } from '~/components/templates';
import {
  BottomToolbar,
  ErrorSnack,
  GroupCard,
  PostImage,
  SuccessSnack,
} from '~/components/molecules';
import { PostImageContext } from '~/components/molecules/postImage/PostImage';
import { GroupItem } from '~/utils/group';
import { ShareEvent } from '~/components/atoms/shareButton/ShareButton';
import ToolbarSubmenu, {
  ShareButtonProps,
  ToolbarButtonProps,
} from '~/components/molecules/toolbarSubmenu/ToolbarSubmenu';
import { createShareUri } from '~/utils/http/links';
import UserModule from '~/app/core/store/modules/UserModule';
import { commentsFragment } from '~/utils/comments';
import GroupModule from '~/app/core/store/modules/GroupModule';

const rootClass = 'ys-post-tile';

export interface PostTileInterface {
  post: PostItem;
}

interface PostListItemSnacks {
  copied: boolean;
  reported: boolean;
  reportFailed: boolean;
}

@Component({ style })
export default class PostTile extends VueComponent<PostTileInterface>
  implements PostTileInterface {
  @Prop({ required: true })
  public post!: PostItem;

  @Prop()
  public share?: ShareButtonProps;

  protected postActions: PostListItemSnacks = {
    copied: false,
    reported: false,
    reportFailed: false,
  };

  protected get dateTime() {
    return format(parse(this.post.created), dateTimeFormatWithDays);
  }

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  protected get postsStore() {
    return getModule(PostsModule, this.$store);
  }

  protected get userStore() {
    return getModule(UserModule, this.$store);
  }

  protected get route() {
    if (this.$route.meta.public) {
      return getLocalizedLocation(routes.postPreview, this.$router, {
        guid: this.post.id,
      });
    }
    return getLocalizedLocation(routes.postDetail, this.$router, {
      guid: this.post.id,
    });
  }

  protected get reportButton(): ToolbarButtonProps | undefined {
    if (!this.userStore.user) {
      return;
    }

    return {
      disabled: this.post.reported,
      loading: this.post.state.reporting,
    };
  }

  protected get shareButton(): ShareButtonProps | undefined {
    return {
      disabled: false,
      loading: false,
      uri: createShareUri(
        this.$router.resolve(
          getLocalizedLocation(routes.postPreview, this.$router, {
            guid: this.post.id,
          })
        ).href
      ),
    };
  }

  protected get groupItem(): GroupItem | null {
    for (const group of this.groupStore.groupCache) {
      if (group.id === this.post.group.id) {
        return group;
      }
    }

    return null;
  }

  public render() {
    return (
      <v-card class={rootClass}>
        <v-card-title class='py-4 flex-nowrap'>
          {this.groupItem && (
            <GroupCard group={this.groupItem}>
              <div slot='caption'>{this.dateTime}</div>
            </GroupCard>
          )}

          <v-spacer />

          <PostKindLabel post={this.post} class='mr-2 flex-shrink-0' />

          <ToolbarSubmenu
            class='flex-shrink-0'
            report={this.reportButton}
            share={this.shareButton}
            onReport={this.reportPost}
            onShare={() => {
              this.postActions.copied = true;
            }}
          />
        </v-card-title>

        <v-divider />

        <v-container>
          <v-row>
            {this.post.image && (
              <v-col cols={3} class='pr-0 flex-grow-0'>
                <v-card to={this.route}>
                  <PostImage context={PostImageContext.TILE} post={this.post} />
                </v-card>
              </v-col>
            )}
            <v-col>
              <h2 class='text-truncate pb-1 flex-shrink-1'>
                <router-link class='text-decoration-none' to={this.route}>
                  {this.post.title}
                </router-link>
              </h2>
              <div>
                {this.post.source === PostSource.WORDPRESS ? (
                  <HtmlRenderer
                    key={'post-tile-excerpt' + this.post.excerpt}
                    content={this.post.excerpt}
                  />
                ) : (
                  <Content text={this.post.excerpt} />
                )}{' '}
                <router-link
                  class='font-weight-bold text-no-wrap secondary--text'
                  to={this.route}
                >
                  {this.$t('app.post.readMore')}
                </router-link>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <BottomToolbar
          commentsCount={this.post.comments}
          liked={this.post.liked}
          likes={this.post.likes}
          loadingLike={this.post.state.liking}
          onLike={this.likePost}
          onUnlike={this.unlikePost}
          onShowComments={() => {
            this.$router.push({
              ...this.route,
              hash: commentsFragment,
            });
          }}
        />
        <ErrorSnack v-model={this.postActions.reportFailed}>
          {this.$t('app.error.post.reportFailed')}
        </ErrorSnack>

        <SuccessSnack v-model={this.postActions.reported}>
          {this.$t('app.posts.reported')}
        </SuccessSnack>
        <SuccessSnack v-model={this.postActions.copied}>
          {this.$t('app.share.success')}
        </SuccessSnack>
      </v-card>
    );
  }

  protected likePost() {
    this.postsStore.like({ id: this.post.id });
  }

  protected unlikePost() {
    this.postsStore.unlike({ id: this.post.id });
  }

  protected reportPost() {
    this.postsStore
      .report({ id: this.post.id })
      .then(() => {
        this.postActions.reported = true;
      })
      .catch(() => {
        this.postActions.reportFailed = true;
      });
  }

  @Emit('share')
  protected emitShare(): ShareEvent {
    return;
  }
}
