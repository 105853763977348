import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { getLocalizedLocation } from '~/app/core/router';
import { routes } from '~/app/core/router/routes';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import UserModule from '~/app/core/store/modules/UserModule';
import SurveysModule, {
  SurveyError,
} from '~/app/core/store/modules/SurveysModule';
import { SurveyKindLabel } from '~/components/atoms';
import {
  BottomToolbar,
  ErrorSnack,
  GroupCard,
  SuccessSnack,
  SurveyOptions,
  SurveyTime,
} from '~/components/molecules';
import { SelectEvent } from '~/components/molecules/surveyOptions/SurveyOptions';
import ToolbarSubmenu, {
  ShareButtonProps,
  ToolbarButtonProps,
} from '~/components/molecules/toolbarSubmenu/ToolbarSubmenu';
import { parse, format } from '~/utils/date-fns';
import { dateTimeFormatWithDays } from '~/utils/dateTime';
import { GroupItem } from '~/utils/group';
import createGroupItem from '~/utils/group/createGroupItem';
import { createShareUri } from '~/utils/http/links';
import { componentOffset } from '~/utils/spacing';
import { SurveyItem } from '~/utils/survey';
import { VueComponent } from '~/utils/vue-component';

import style from './SurveyTile.scss';
import { commentsFragment } from '~/utils/comments';
import GroupModule from '~/app/core/store/modules/GroupModule';

const rootClass = 'ys-survey-tile';

export interface SurveyTileInterface {
  survey: SurveyItem;
}

interface SurveySnacks {
  copied: boolean;
  expired: boolean;
  reported: boolean;
  reportFailed: boolean;
  voteFailed: boolean;
}

@Component({ style })
export default class SurveyTile extends VueComponent<SurveyTileInterface>
  implements SurveyTileInterface {
  @Prop({ required: true })
  public survey!: SurveyItem;

  protected surveySnacks: SurveySnacks = {
    copied: false,
    expired: false,
    reported: false,
    reportFailed: false,
    voteFailed: false,
  };

  protected get date() {
    return format(parse(this.survey.created), dateTimeFormatWithDays);
  }

  protected get end() {
    return format(parse(this.survey.end), dateTimeFormatWithDays);
  }

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  protected get surveysStore() {
    return getModule(SurveysModule, this.$store);
  }

  protected get userStore() {
    return getModule(UserModule, this.$store);
  }

  protected get reportButton(): ToolbarButtonProps | undefined {
    if (!this.userStore.user) {
      return;
    }

    return {
      disabled: this.survey.reported,
      loading: this.survey.state.reporting,
    };
  }

  protected get shareButton(): ShareButtonProps | undefined {
    return {
      disabled: false,
      loading: false,
      uri: createShareUri(
        this.$router.resolve(
          getLocalizedLocation(routes.surveyPreview, this.$router, {
            guid: this.survey.id,
          })
        ).href
      ),
    };
  }

  protected get groupItem(): GroupItem | null {
    for (const group of this.groupStore.groupCache) {
      if (group.id === this.survey.group.id) {
        return group;
      }
    }

    return null;
  }

  protected get route() {
    if (this.$route.meta.public) {
      return getLocalizedLocation(routes.surveyPreview, this.$router, {
        guid: this.survey.id,
      });
    }

    return getLocalizedLocation(routes.surveyDetail, this.$router, {
      guid: this.survey.id,
    });
  }

  public render() {
    return (
      <v-card class={rootClass}>
        <v-card-title class='py-4 flex-nowrap'>
          {this.groupItem && (
            <GroupCard group={this.groupItem}>
              <div slot='caption'>{this.date}</div>
            </GroupCard>
          )}

          <v-spacer />

          <SurveyKindLabel class='mr-1 flex-shrink-0' />

          <ToolbarSubmenu
            class='flex-shrink-0'
            report={this.reportButton}
            share={this.shareButton}
            onReport={this.reportSurvey}
            onShare={() => {
              this.surveySnacks.copied = true;
            }}
          />
        </v-card-title>

        <v-divider />

        <v-container>
          <h2 class='text-truncate pb-1 flex-shrink-1'>
            <router-link class='text-decoration-none' to={this.route}>
              {this.survey.title}
            </router-link>
          </h2>

          <p>{this.survey.excerpt}</p>
          <SurveyTime survey={this.survey} class={`mb-${componentOffset}`} />
          <SurveyOptions
            active={
              this.survey.state.active &&
              !this.survey.state.voting &&
              !!this.userStore.user
            }
            class={`mb-${componentOffset}`}
            options={this.survey.options}
            onSelected={this.selected}
          />
        </v-container>

        <BottomToolbar
          commentsCount={this.survey.comments}
          liked={this.survey.liked}
          likes={this.survey.likes}
          loadingLike={this.survey.state.liking}
          onLike={this.like}
          onUnlike={this.unlike}
          onShowComments={() => {
            this.$router.push({
              ...this.route,
              hash: commentsFragment,
            });
          }}
        />

        <ErrorSnack v-model={this.surveySnacks.expired}>
          {this.$t('app.error.survey.expired')}
        </ErrorSnack>
        <ErrorSnack v-model={this.surveySnacks.voteFailed}>
          {this.$t('app.error.survey.voteFailed')}
        </ErrorSnack>
        <ErrorSnack v-model={this.surveySnacks.reportFailed}>
          {this.$t('app.error.survey.reportFailed')}
        </ErrorSnack>

        <SuccessSnack v-model={this.surveySnacks.reported}>
          {this.$t('app.surveys.reported')}
        </SuccessSnack>
        <SuccessSnack v-model={this.surveySnacks.copied}>
          {this.$t('app.share.success')}
        </SuccessSnack>
      </v-card>
    );
  }

  protected like() {
    this.surveysStore.like({ id: this.survey.id });
  }

  protected unlike() {
    this.surveysStore.unlike({ id: this.survey.id });
  }

  protected reportSurvey() {
    this.surveysStore
      .report({ id: this.survey.id })
      .then(() => {
        this.surveySnacks.reported = true;
      })
      .catch(() => {
        this.surveySnacks.reportFailed = true;
      });
  }

  protected selected(data: SelectEvent) {
    if (!data.optionId || !this.survey.state.active) {
      return;
    }

    this.surveysStore
      .vote({
        id: this.survey.id,
        optionId: data.optionId,
      })
      .catch((err) => {
        if (err.message && err.message === SurveyError.TIME_EXPIRED) {
          this.surveySnacks.expired = true;
        } else {
          this.surveySnacks.voteFailed = true;
        }
      });
  }
}
