import {
  CzYsP14KcFeedApiDtoPostItemObject,
  CzYsP14KcPostApiDtoPostObject,
} from '~/app/core/apiClient/api';
import { format } from '~/utils/date-fns';
import getPostImageUrl, {
  getAttachedImageUrl,
} from '~/utils/post/getPostImageUrl';
import { PostItem, PostSource } from './';
import createImage from '~/utils/media/createImage';
import { ImageItem, ImageSize, imageVersion } from '~/utils/media';
import {
  AreaOfInterestItem,
  mapAreasOfInterest,
} from '~/utils/areasOfInterest';
import { FeedType } from '~/utils/feed';
import VisibilityEnum = CzYsP14KcPostApiDtoPostObject.VisibilityEnum;
import SourceEnum = CzYsP14KcFeedApiDtoPostItemObject.SourceEnum;
import { createPostAttachedImages } from '~/utils/media/postItemImages';

type Input = CzYsP14KcFeedApiDtoPostItemObject | CzYsP14KcPostApiDtoPostObject;

// TODO: Really bad typeguard, but sufficient for the below logic
function isPostDto(data: any): data is CzYsP14KcPostApiDtoPostObject {
  return (
    typeof data.imageIds !== 'undefined' &&
    typeof data.imageUrls !== 'undefined' &&
    typeof data.visibility !== 'undefined'
  );
}

export default function(
  data: Input,
  areas: AreaOfInterestItem[]
): PostItem | null {
  if (!data.id || !data.community) {
    return null;
  }

  const contentArray = data.content
    ? data.content.replace(/<[^>]+>/g, ' ').split(/\s+/)
    : [];
  let excerpt = contentArray.slice(0, 50).join(' ');
  if (contentArray.length > 50) {
    excerpt += '...';
  }

  let images: ImageItem[] = [];

  let visibility: VisibilityEnum = VisibilityEnum.PUBLIC;

  let areasOfInterest: AreaOfInterestItem[] = [];

  const mediaVersion = imageVersion();

  let contentOriginal: string = '';

  let editable: boolean = false;

  if (isPostDto(data)) {
    if (data.imageIds) {
      images = createPostAttachedImages(data, mediaVersion);
    }

    if (data.areasOfInterest) {
      areasOfInterest = mapAreasOfInterest(data.areasOfInterest, areas);
    }
    visibility = data.visibility;

    if (data.user && data.user.editable) {
      editable = true;
    }

    if (data.contentOriginal) {
      contentOriginal = data.contentOriginal;
    }
  }

  let source: PostSource = PostSource.SELF;
  switch (data.source) {
    case SourceEnum.FACEBOOK:
      source = PostSource.FACEBOOK;
      break;
    case SourceEnum.INSTAGRAM:
      source = PostSource.INSTAGRAM;
      break;
    case SourceEnum.WORDPRESS:
      source = PostSource.WORDPRESS;
      break;
  }

  return {
    areasOfInterest,
    comments: data.commentCount || 0,
    content: data.content || '',
    contentOriginal,
    created: data.created || format(new Date()),
    editable,
    excerpt,
    group: {
      id: data.community.id || '',
      name: data.community.name || '',
    },
    id: data.id,
    image: {
      thumbnail: {
        src: getPostImageUrl(data.id, mediaVersion, ImageSize.THUMBNAIL),
      },
      small: {
        src: getPostImageUrl(data.id, mediaVersion, ImageSize.SMALL),
      },
      original: {
        src: getPostImageUrl(data.id, mediaVersion, ImageSize.ORIGINAL),
      },
    },
    images,
    liked: data.user?.liked || false,
    likes: data.likeCount || 0,
    // TODO: Missing from API
    reported: false,
    source,
    state: {
      liking: false,
      loading: false,
      reporting: false,
    },
    title: data.title
      ? data.title.replace(/<[^>]+>/g, ' ').replace(/\s\s+/, ' ')
      : '',
    type: FeedType.POST,
    visibility,
  };
}
