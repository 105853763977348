import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseButton from '~/components/atoms/baseButton/BaseButton';
import { SpreadAttributes } from '~/utils/vue-component';
import { icons } from '~/utils/post';

type CreateEvent = void;

export interface CreatePostButtonInterface {
  active?: boolean;
  onCreate?: (data: CreateEvent) => void;
}

@Component
export default class CreatePostButton extends BaseButton<
  CreatePostButtonInterface
> {
  @Prop({ default: true, type: Boolean })
  public active!: boolean;

  protected get attributes(): SpreadAttributes {
    return {
      attrs: {
        rounded: true,
        ...this.baseAttributes.attrs,
      },
    };
  }

  public render() {
    if (!this.active) {
      return;
    }

    return (
      <v-btn color='secondary' onClick={this.emitCreate} {...this.attributes}>
        <v-icon left>{icons.create}</v-icon>
        {this.$slots.default || this.$t('app.posts.create')}
      </v-btn>
    );
  }

  @Emit('create')
  protected emitCreate(): CreateEvent {
    return;
  }
}
