import { RouteConfig } from 'vue-router';

import parameters from './parameters';
import SurveyDetail from '~/views/SurveyDetail';

const config: RouteConfig = {
  component: SurveyDetail,
  path: `public/survey/${parameters.guidPath}`,
  meta: {
    public: true,
  },
};

export default config;
