import { routes } from '~/app/core/router/routes';
import routeParameters from '~/app/core/router/routes/parameters';
import { encodeRoutePath } from '~/app/localization';

export default {
  app: {
    router: {
      [encodeRoutePath(routes.homepage.path)]: {
        // You should not localize path, as the path is empty
        alias: 'domaci-stranka',
      },
      [encodeRoutePath(routes.events.path)]: {
        path: 'udalosti',
      },
      [encodeRoutePath(routes.eventDetail.path)]: {
        path: `udalost/${routeParameters.guidPath}/${routeParameters.datePath}`,
      },
      [encodeRoutePath(routes.eventPreview.path)]: {
        path: `verejne/udalost/${routeParameters.guidPath}/${routeParameters.datePath}`,
      },
      [encodeRoutePath(routes.findGroups.path)]: {
        path: 'hledat-skupiny',
      },
      [encodeRoutePath(routes.groups.path)]: {
        path: 'skupiny',
      },
      [encodeRoutePath(routes.groupDetail.path)]: {
        path: `skupina/${routeParameters.guidPath}`,
      },
      [encodeRoutePath(routes.groupPreview.path)]: {
        path: `verejne/skupina/${routeParameters.guidPath}`,
      },
      [encodeRoutePath(routes.postDetail.path)]: {
        path: `clanek/${routeParameters.guidPath}`,
      },
      [encodeRoutePath(routes.postPreview.path)]: {
        path: `verejne/clanek/${routeParameters.guidPath}`,
      },
      [encodeRoutePath(routes.surveyDetail.path)]: {
        path: `anketa/${routeParameters.guidPath}`,
      },
      [encodeRoutePath(routes.surveyPreview.path)]: {
        path: `verejne/anketa/${routeParameters.guidPath}`,
      },
    },
  },
};
