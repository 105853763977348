import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { rootClass } from '~/components/atoms/icons/Icon';

@Component
export default class MenuIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class={`${rootClass}--common ${rootClass}--menu`}>
        <svg
          viewBox='0 0 34 36'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            d='M30.224 35.98c2.268 0 3.48-1.236 3.48-3.48v-5.22h-1.608v5.088c0 1.776-.624 2.328-1.86 2.328-1.272 0-1.776-.648-1.776-2.22V27.28h-1.608v5.316c0 2.208 1.224 3.384 3.372 3.384zM.296 35.86h1.44v-6.48l2.604 6.48h.516l2.556-6.48v6.48h1.596v-8.58H6.896l-2.208 5.856-2.256-5.856H.296v8.58zm10.68 0h5.568V34.6h-3.972v-2.508h3.024v-1.2h-3.024V28.54h3.768v-1.26h-5.364v8.58zm7.008 0h1.452v-6.576L23.3 35.86h1.656v-8.58h-1.452v6.204l-3.612-6.204h-1.908v8.58zM30.047 14h-26v2h26v-2zm0-7h-26v2h26V7zm0-7h-26v2h26V0z'
            fill='currentColor'
            fill-rule='nonzero'
          />
        </svg>
      </i>
    );
  }
}
