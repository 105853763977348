import { Component, Prop, Watch } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';

import { getModule } from 'vuex-module-decorators';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { ButtonSize, LoadMoreButton } from '~/components/atoms';
import AreasOfInterestModule from '~/app/core/store/modules/AreasOfInterestModule';
import PostsModule from '~/app/core/store/modules/PostsModule';
import SurveysModule from '~/app/core/store/modules/SurveysModule';
import { FeedItem, FeedType } from '~/utils/feed';
import {
  CzYsP14KcFeedApiDtoFeedWrapperObject,
  OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject,
} from '~/app/core/apiClient/api';
import { createPostItem, isPostItem, PostItem } from '~/utils/post';
import { createSurveyItem, isSurveyItem, SurveyItem } from '~/utils/survey';
import TypeEnum = CzYsP14KcFeedApiDtoFeedWrapperObject.TypeEnum;
import { PostTile, SurveyTile } from '~/components/organisms';
import { listItemOffset } from '~/utils/spacing';
import GroupModule from '~/app/core/store/modules/GroupModule';
import { GroupItem } from '~/utils/group';

interface FeedListInterface {
  groupId?: string;
  personalized?: boolean;
}

interface FeedListItem {
  id: FeedItem['id'];
  type: FeedType;
}

const pageSize = 15;

@Component
export default class FeedList
  extends VueComponentMixin<FeedListInterface, PrefetchComponent>(Prefetch)
  implements FeedListInterface {
  @Prop({ type: String })
  public groupId?: string;

  @Prop({ type: Boolean, default: false })
  public personalized!: boolean;

  protected feedListItems: FeedListItem[] = [];

  protected loading: boolean = false;

  protected page: number = 0;

  protected lastPage: boolean = true;

  protected get areasOfInterestStore() {
    return getModule(AreasOfInterestModule, this.$store);
  }

  protected get groupStore() {
    return getModule(GroupModule, this.$store);
  }

  protected get postsStore() {
    return getModule(PostsModule, this.$store);
  }

  protected get surveysStore() {
    return getModule(SurveysModule, this.$store);
  }

  protected get items(): FeedItem[] {
    const items: FeedItem[] = [];

    for (const item of this.feedListItems) {
      if (item.type === FeedType.POST) {
        // TODO: Bad performance, can't use id-key map because it's not reactive
        // TODO: Figure out a way to use MAP to retrieve posts by key, instead of looping
        for (const post of this.postsStore.cache) {
          if (post.id === item.id) {
            items.push(post);
            break;
          }
        }
      } else if (item.type === FeedType.SURVEY) {
        // TODO: Bad performance, can't use id-key map because it's not reactive
        // TODO: Figure out a way to use MAP to retrieve posts by key, instead of looping
        for (const survey of this.surveysStore.cache) {
          if (survey.id === item.id) {
            items.push(survey);
            break;
          }
        }
      }
    }

    return items;
  }

  public prefetch() {
    if (this.$ssrContext) {
      return Promise.resolve();
    }

    return this.areasOfInterestStore.loadData().then(() => {
      return this.loadFeed().catch(() => {
        // Not much to do here
      });
    });
  }

  public render() {
    return (
      <v-skeleton-loader
        type='list-item-avatar-three-line'
        transition='scale-transition'
        tile={true}
        loading={this.loading && this.items.length < 1}
      >
        <div>
          {this.items.map((item) => {
            if (isPostItem(item)) {
              return (
                <PostTile
                  class={`mb-${listItemOffset}`}
                  post={item}
                  key={'feed-item-' + item.type + item.id}
                />
              );
            } else if (isSurveyItem(item)) {
              return (
                <SurveyTile
                  class={`mb-${listItemOffset}`}
                  survey={item}
                  key={'feed-item-' + item.type + item.id}
                />
              );
            }
          })}
          {!this.loading && this.items.length < 1 && (
            <p class='mt-2'>{this.$t('app.feeds.noItems')}</p>
          )}
          {!this.lastPage && (
            <div class='text-center mt-3'>
              <LoadMoreButton
                loading={this.loading}
                onLoadMore={this.loadFeed}
                size={ButtonSize.LARGE}
              />
            </div>
          )}
        </div>
      </v-skeleton-loader>
    );
  }

  @Watch('groupId')
  @Watch('personalized')
  protected setFeedType() {
    this.page = 0;
    this.lastPage = true;
    this.feedListItems = [];
    this.loadFeed();
  }

  protected loadFeed() {
    this.loading = true;

    let promise: Promise<any>;

    if (!this.groupId) {
      if (this.personalized) {
        promise = this.$api.feeds
          .getAllPersonal(undefined, undefined, this.page, pageSize)
          .then(this.setFeedItems);
      } else {
        promise = this.$api.feeds
          .getAll(undefined, undefined, this.page, pageSize)
          .then(this.setFeedItems);
      }
    } else {
      promise = this.$api.communities
        .getCommunityFeed(
          this.groupId,
          undefined,
          undefined,
          this.page,
          pageSize
        )
        .then(this.setFeedItems);
    }

    return promise
      .then(() => {
        this.page = this.page + 1;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  protected setFeedItems(
    data: OrgSpringframeworkDataDomainPageCzYsP14KcFeedApiDtoFeedWrapperObject
  ) {
    if (!data.content) {
      return;
    }

    const postsToCache: PostItem[] = [];
    const surveysToCache: SurveyItem[] = [];
    const groupIds: GroupItem['id'][] = [];
    const feedItems: FeedListItem[] = [];

    this.lastPage = !!data.last;

    data.content.forEach((item) => {
      let feedItem: FeedListItem | null = null;

      if (item.type === TypeEnum.POST) {
        const post = createPostItem(
          item.content,
          this.areasOfInterestStore.interests
        );
        if (post) {
          groupIds.push(post.group.id);
          postsToCache.push(post);
          feedItem = {
            id: post.id,
            type: post.type,
          };
        }
      } else if (item.type === TypeEnum.SURVEY) {
        const survey = createSurveyItem(
          item.content,
          this.areasOfInterestStore.interests
        );
        if (survey) {
          groupIds.push(survey.group.id);
          surveysToCache.push(survey);
          feedItem = {
            id: survey.id,
            type: survey.type,
          };
        }
      }

      if (feedItem) {
        feedItems.push(feedItem);
      }
    });

    if (feedItems.length > 0) {
      this.groupStore.loadUncachedIds({ requestedIds: groupIds }).then(() => {
        this.postsStore.cachePosts(postsToCache);
        this.surveysStore.cacheSurveys(surveysToCache);
        feedItems.forEach((item) => {
          this.feedListItems.push(item);
        });
      });
    }
  }
}
