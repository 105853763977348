export default function equals({
  array1,
  array2,
}:
  | { array1: string[]; array2: string[] }
  | { array1: number[]; array2: number[] }): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  return (
    JSON.stringify([...array1].sort()) === JSON.stringify([...array2].sort())
  );
}
