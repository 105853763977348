import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import ToolbarSubmenu, {
  DeleteButtonProps,
  ShareButtonProps,
  ToolbarButtonProps,
} from '~/components/molecules/toolbarSubmenu/ToolbarSubmenu';
import { EditEvent } from '~/components/atoms/editButton/EditButton';
import { DeleteEvent } from '~/components/atoms/deleteButton/DeleteButton';
import { ReportEvent } from '~/components/atoms/reportButton/ReportButton';
import { ShareEvent } from '~/components/atoms/shareButton/ShareButton';

interface DetailToolbarInterface {
  delete?: DeleteButtonProps;
  edit?: ToolbarButtonProps;
  report?: ToolbarButtonProps;
  share?: ShareButtonProps;
  onEdit?: (data: EditEvent) => void;
  onDelete?: (data: DeleteEvent) => void;
  onReport?: (data: ReportEvent) => void;
  onShare?: (data: ShareEvent) => void;
}

@Component
export default class DetailToolbar extends VueComponent<DetailToolbarInterface>
  implements DetailToolbarInterface {
  @Prop()
  public delete?: DeleteButtonProps;

  @Prop()
  public edit?: ToolbarButtonProps;

  @Prop()
  public report?: ToolbarButtonProps;

  @Prop()
  public share?: ShareButtonProps;

  public render() {
    return (
      <v-row justify='center' align='center' no-gutters>
        {this.$slots.left}
        <v-spacer />
        {this.$slots.right}
        <ToolbarSubmenu
          delete={this.delete}
          edit={this.edit}
          report={this.report}
          share={this.share}
          onDelete={this.emitDelete}
          onEdit={this.emitEdit}
          onReport={this.emitReport}
          onShare={this.emitShare}
        >
          {this.$slots.prependButton &&
            this.$slots.prependButton.map((button) => {
              return <div slot='prependButton'>{button}</div>;
            })}
          {this.$slots.appendButton &&
            this.$slots.appendButton.map((button) => {
              return <div slot='appendButton'>{button}</div>;
            })}
        </ToolbarSubmenu>
      </v-row>
    );
  }

  @Emit('edit')
  protected emitEdit(): EditEvent {
    return;
  }

  @Emit('delete')
  protected emitDelete(): DeleteEvent {
    return;
  }

  @Emit('report')
  protected emitReport(): ReportEvent {
    return;
  }

  @Emit('share')
  protected emitShare(): ShareEvent {
    return;
  }
}
