export default {
  app: {
    eventsSearchForm: {
      searchLabel: 'Hledat',
      interestsLabel: 'Oblasti zájmu',
      authorLabel: 'Pořadatel',
      dateFromLabel: 'Od',
      dateToLabel: 'Do',
      dogsAllowedLabel: 'Vstup se psy',
      barrierFreeLabel: 'Bezbariérový přístup',
    },
  },
};
