import dateFnsCs from 'date-fns/locale/cs';

const defaultLocale = 'cs';

const supportedLocales = ['cs'];

const dateFnsLocalizations: { [key: string]: any } = {
  cs: dateFnsCs,
};
export { dateFnsLocalizations, defaultLocale, supportedLocales };

export function encodeRoutePath(path: string): string {
  if (path === '') {
    return '__EMPTY__';
  }

  return path.replace('.', '_');
}
