import appEnv from '~/app/core/appEnv';

interface GeoCodedAddress {
  address: string;
}

const latitudeReplaceString = '{latitude}';
const longitudeReplaceString = '{longitude}';
const reverseGeoCodingUrl = `${appEnv.GEOCODING_API_URL}/reverse?lat=${latitudeReplaceString}&lng=${longitudeReplaceString}`;

interface ReverseGeoCodingResponse {
  label: string;
}

export function reverseGeoCode(
  lat: number,
  lng: number,
  locale?: string
): Promise<GeoCodedAddress> {
  const requestUrl = reverseGeoCodingUrl
    .replace(latitudeReplaceString, lat.toString())
    .replace(longitudeReplaceString, lng.toString());

  if (locale) {
    // Currently not supported
  }

  return fetch(requestUrl).then((response) => {
    if (response.status === 404) {
      return {
        address: '',
      };
    }

    if (response.status < 200 || response.status > 299) {
      throw new Error('Invalid request');
    }

    return response
      .json()
      .then((result: ReverseGeoCodingResponse) => {
        return {
          address: result.label,
        };
      })
      .catch(() => {
        throw new Error('GeoCoding response not supported');
      });
  });
}
